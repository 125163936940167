import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, navigate } from '@reach/router';
import './assets/styles/auth.scss';
import logoBlack from './assets/images/logo/dark-alice-logo-full.svg';
import AuthHeader from './components/AuthHeader';
import ForgetPasswordFormSection from './components/ForgetPasswordFormSection';
import { isValidEmail } from '../../utils';
import { toaster } from 'evergreen-ui';
import { authData } from './../../content';

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      loading: false,
    };
  }
  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  handleResetRequestSubmit = () => {
    if (!isValidEmail(this.state.email)) {
      toaster.danger('Invalid Email.');
      return;
    }
    this.setState({ loading: true });
    this.props
      .resetRequest({
        email: this.state.email,
      })
      .then((success) => {
        if (success) {
          toaster.success('Please check your email for further instruction!');
          this.setState({ email: '', loading: false });
          navigate(`/reset-verify`);
        } else {
          this.setState({ loading: false });
        }
      });
  };

  render() {
    const isAuthenticated = this.props.auth.access !== '';
    return isAuthenticated ? (
      <Redirect from='/' to='/dashboard' noThrow />
    ) : (
      <div className='auth-container'>
        <div className='header-container'>
          <div>
            <a href='/'>
              <img className='logo-dark' src={logoBlack} alt='alice logo' />
            </a>
          </div>
          <div>
            <AuthHeader
              linkInfo={authData.contactInfo.text}
              link={authData.contactInfo.link}
              linkText={authData.contactInfo.linkTitle}
            />
          </div>
        </div>
        <ForgetPasswordFormSection
          handleChange={this.handleChange}
          email={this.state.email}
          loading={this.state.loading}
          handleSubmit={() => this.handleResetRequestSubmit()}
          content={authData}
        />
      </div>
    );
  }
}

const mapState = (state) => ({
  auth: state.auth,
});

const mapDispatch = (dispatch) => ({
  resetRequest: (payload) => dispatch.auth.resetRequest(payload),
});

const ForgetPasswordContainer = connect(mapState, mapDispatch)(ForgetPassword);

export default ForgetPasswordContainer;
