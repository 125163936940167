import React from 'react';
import {Button, IconButton, Label, Textarea, TextInput, toaster} from 'evergreen-ui';
import ChannelDropdown from './../../../components/elements/ChannelDropdown';
import UnsupportedBlockTag from './Fragments/UnsupportedBlockTag';
import cloneFailed from './../assets/images/cloneFailedImage.svg';
import {allPlatformList} from './../../../utils';

interface props {
  close: any;
  currentCloneChannel: any;
  channels: any[];
  fetchChannelCloneBlocks: (cloneType: string) => any;
    cloneChannel: (cloneType:string,name:string,description:string) => any;
}

const CloneChannelModal: React.FC<props> = ({
  close,
  currentCloneChannel,
  channels,
  fetchChannelCloneBlocks,
   cloneChannel,
}) => {
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [isInvalid, setIsInvalid] = React.useState({
    name: false,
    description: false,
  });
  const [showWarning, setShowWarning] = React.useState(0);
  const [disallowedBlocks, setDisallowedBlocks] = React.useState([]);
  const [currentChangedChannel, setCurrentChangedChannel] = React.useState(
    channels.filter((channel: any) => channel.id === currentCloneChannel)[0]
  );

  return (
    <div>
      {showWarning === 0 && (
        <>
          <div className='clone-channel__container'>
            <div className='clone-channel__header'>
              <p>Clone Channel</p>
              <IconButton appearance='minimal' icon={'cross'} onClick={close} />
            </div>
            <br />
            <div>
              <ChannelDropdown
                isFullWidth={true}
                channels={allPlatformList.filter((channel)=>channel.main_type === currentChangedChannel?.main_type)}
                currentChannel={currentChangedChannel}
                onChange={async (channel: any) => {
                  const response = await fetchChannelCloneBlocks(channel.type);
                  setCurrentChangedChannel(channel);
                  setDisallowedBlocks(!!response ? response : []);
                }}
              />
              <br />
              <Label>Name*</Label>
              <br />
              <TextInput
                isInvalid={isInvalid.name}
                className='custom-input-style w-100p'
                placeholder='Type here...'
                value={name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setName(event.target.value)
                }
              />
              <br />
              <br />
              <Label>Description</Label>
              <br />
              <Textarea
                isInvalid={isInvalid.description}
                className='custom-input-style w-100p'
                placeholder='Type here...'
                value={description}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setDescription(event.target.value)
                }
              />
              {disallowedBlocks.length > 0 && (
                <>
                  <div className='clone-channel__info-box'>
                    <div className='clone-channel__info-box__icon'>
                      <svg
                        width='14'
                        height='14'
                        viewBox='0 0 10 10'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M5.49993 6.6V6.1H4.49993V6.6H5.49993ZM4.49993 6.60533V7.10533H5.49993V6.60533H4.49993ZM4.49993 3.13333V5.26666H5.49993V3.13333H4.49993ZM4.49993 6.6V6.60533H5.49993V6.6H4.49993ZM4.99993 8.23333C3.21421 8.23333 1.7666 6.78572 1.7666 5H0.766602C0.766602 7.338 2.66193 9.23333 4.99993 9.23333V8.23333ZM8.23327 5C8.23327 6.78572 6.78566 8.23333 4.99993 8.23333V9.23333C7.33794 9.23333 9.23327 7.338 9.23327 5H8.23327ZM4.99993 1.76666C6.78566 1.76666 8.23327 3.21428 8.23327 5H9.23327C9.23327 2.66199 7.33794 0.766663 4.99993 0.766663V1.76666ZM4.99993 0.766663C2.66193 0.766663 0.766602 2.66199 0.766602 5H1.7666C1.7666 3.21428 3.21421 1.76666 4.99993 1.76666V0.766663Z'
                          fill='#FF0800'
                        />
                      </svg>
                    </div>
                    <p className='clone-channel__info-box__text'>
                      Some sequence blocks will be disabled due to changed
                      platform limitation.
                    </p>
                  </div>
                  <div className='clone-channel__info-box__component-container'>
                    {disallowedBlocks.map((elem: string, i: number) => (
                      <UnsupportedBlockTag key={i} title={`${elem} Block`} />
                    ))}
                  </div>
                </>
              )}
                {disallowedBlocks.length === 0 &&  <br />}
                <br />
              <Button
                className='button-black w-100p'
                onClick={() => {
                  if (!!name) {
                    setIsInvalid({ name: false, description: false });
                    setShowWarning(1);
                  } else {
                    if (!name) {
                      setIsInvalid({ ...isInvalid, name: true });
                    }
                  }
                }}
              >
                Finish
              </Button>
            </div>
          </div>
        </>
      )}
      {showWarning === 1 && (
        <div className='clone-channel__warning-container'>
          <p className='clone-channel__warning__title'>
            Are you sure you want to clone this channel?
          </p>
          <div className='clone-channel__info-box justify-center align-center'>
            <svg
              width='14'
              height='14'
              viewBox='0 0 10 10'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M5.49993 6.6V6.1H4.49993V6.6H5.49993ZM4.49993 6.60533V7.10533H5.49993V6.60533H4.49993ZM4.49993 3.13333V5.26666H5.49993V3.13333H4.49993ZM4.49993 6.6V6.60533H5.49993V6.6H4.49993ZM4.99993 8.23333C3.21421 8.23333 1.7666 6.78572 1.7666 5H0.766602C0.766602 7.338 2.66193 9.23333 4.99993 9.23333V8.23333ZM8.23327 5C8.23327 6.78572 6.78566 8.23333 4.99993 8.23333V9.23333C7.33794 9.23333 9.23327 7.338 9.23327 5H8.23327ZM4.99993 1.76666C6.78566 1.76666 8.23327 3.21428 8.23327 5H9.23327C9.23327 2.66199 7.33794 0.766663 4.99993 0.766663V1.76666ZM4.99993 0.766663C2.66193 0.766663 0.766602 2.66199 0.766602 5H1.7666C1.7666 3.21428 3.21421 1.76666 4.99993 1.76666V0.766663Z'
                fill='#FF0800'
              />
            </svg>
            <p className='clone-channel__info-box__text fs-12px'>
              Cloned channel connections are disabled by default.
            </p>
          </div>
          <div className='flex align-center justify-center mt-20px mb-20px'>
            <Button className='button-black' onClick={async ()=>{
                const res = await cloneChannel(currentChangedChannel.type, name,description);
                if(res) {
                    toaster.success('Cloned Successfully');
                    close();
                }else {
                    setShowWarning(2);
                }
            }
            }>Yes, Do it</Button> &nbsp; &nbsp;
            <Button
              className='button-danger'
              onClick={() => setShowWarning(0)}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
        {showWarning === 2 && <div className='clone-channel__warning-container'>

            <div className='clone-channel__warning__title'>
                <img src={cloneFailed} width={40} alt='clone-failed' /><p>Failed to create a clone. Want to try again?</p>
            </div>
            <div className='flex align-center justify-center mt-20px mb-20px'>
                <Button className='button-black' onClick={async ()=>{
                    setShowWarning(0);
                }
                }>Sure</Button> &nbsp; &nbsp;
                <Button
                    className='button-danger'
                    onClick={() => close()}
                >
                    Cancel
                </Button>
            </div>
        </div>}
    </div>
  );
};

export default CloneChannelModal;
