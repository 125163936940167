import React from 'react';
import {
  Tooltip as ToolTipEvergreen,
  Icon,
  toaster,
  Spinner,
} from 'evergreen-ui';
import ReactWordcloud from 'react-wordcloud';

const ReportingWordCloud = ({ title, toolTipInfo, data, loader }) => {
  const callbacks = {
    getWordTooltip: (word) => '',
    onWordClick: (word) =>
      toaster.notify(`${word.text}`, {
        description: `Value: ${word.value}`,
      }),
  };
  const options = {
    rotations: 0,
    rotationAngles: [0, 0],
  };
  return (
    <div className='reporting-container__user-card-container-elem'>
      <div className='reporting-container__user-card-container-elem-data'>
        <div className='reporting-title-container'>
          <span>{title}</span>&nbsp;
          <ToolTipEvergreen content={toolTipInfo}>
            <Icon color='success' icon={'info-sign'} size={16} />
          </ToolTipEvergreen>
        </div>
        <br />
        {!loader &&
          (data.length !== 0 ? (
            <div className='flex align-center space-evenly'>
              <ReactWordcloud
                words={data}
                size={[window.innerWidth / 2 - 200, 300]}
                callbacks={callbacks}
                options={options}
              />
            </div>
          ) : (
            <div className='flex align-center h-300px justify-center'>
              No Data Available
            </div>
          ))}
        {loader && (
          <div className='flex align-center h-300px justify-center'>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};
export default ReportingWordCloud;
