import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'evergreen-ui';
import AuthFormHeader from './AuthFormHeader';
import MaterialInput from '../../../components/elements/MaterialInput';
import AuthFooter from './AuthFooter';
import PasswordStrengthBar from 'react-password-strength-bar';

const ForgetPasswordVerify = ({
  verifyCode,
  content,
  password,
  confirmPassword,
  handleChange,
  loading,
  handleResendMail,
  handleSubmit,
}) => {
  const [timeLeft, setTimeLeft] = React.useState(60);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return (
    <div className='form-container'>
      <div className='form-container__content'>
        <AuthFormHeader
          title={content.verify.title}
          subtitle={content.verify.subtitle}
          linkText={content.loginInfo.linkTitle}
          link={content.loginInfo.link}
          linkInfo={content.loginInfo.text}
        />
        <br />
        <br />
        <div className='resend-verification'>
          <MaterialInput
            label='Verification Code'
            type='text'
            name='verifyCode'
            value={verifyCode}
            onKeyPress={(e) => (e.key === 'Enter' ? handleSubmit() : null)}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
          <Button
            isLoading={loading}
            className={
              timeLeft > 0 ? 'resend-timer-button' : 'resend-link-button'
            }
            disabled={timeLeft > 0}
            onClick={() => {
              handleResendMail();
              setTimeLeft(60);
            }}
            onKeyPress={(e) => (e.key === 'Enter' ? handleResendMail() : null)}
          >
            {timeLeft > 0 ? `00:${timeLeft}` : 'Resend'}
          </Button>
        </div>
        <br />
        <MaterialInput
          label='New Password'
          type='password'
          name='password'
          value={password}
          onKeyPress={(e) => (e.key === 'Enter' ? handleSubmit() : null)}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
        <br />
        <br />
        <MaterialInput
          label='Confirm Password'
          type='password'
          name='confirmPassword'
          value={confirmPassword}
          onKeyPress={(e) => (e.key === 'Enter' ? handleSubmit() : null)}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
        <br />
        <br />
        <PasswordStrengthBar
          password={password}
          minLength={5}
          scoreWordClassName='passwordScore'
        />
        <br />
        <Button
          isLoading={loading}
          onClick={() => handleSubmit()}
          className='auth-login-button'
          iconBefore={loading ? '' : content.forget.forgetButtonIcon}
          height={40}
        >
          {content.forget.savePassword}
        </Button>
        <br />
        <br />
        <AuthFooter />
      </div>
    </div>
  );
};
ForgetPasswordVerify.propTypes = {
  email: PropTypes.string,
  content: PropTypes.any,
  loading: PropTypes.bool,
  handleChange: PropTypes.func,
  handleResendMail: PropTypes.func,
  handleSubmit: PropTypes.func,
};
export default ForgetPasswordVerify;
