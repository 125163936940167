import React from 'react';
import BannerImage from '../../../assets/images/banner.svg';

interface props {
  connectCount: number | null;
}

const Banner: React.FC<props> = ({ connectCount }) => {
  return (
    <div className='p-relative dashboard-banner'>
      <img
        className='dashboard-channel-list_banner'
        src={BannerImage}
        width={'100%'}
        alt={'banner'}
      />
      <p className='dashboard-channel-list__title'>Welcome Back!</p>
      <p className='dashboard-channel-list__description'>
        You have <span className='primary-text bold'>{connectCount}</span>{' '}
        Channels Connected! <br />
        Keep up the good work!
      </p>
      <p className='dashboard-channel-list__description-2'>
        Hey, we have a new update! See <br />
        <a
          rel='noopener noreferrer'
          href='https://docs.getalice.ai/release-notes/'
          target='_blank'
        >
          Whats New!
        </a>
      </p>
    </div>
  );
};

export default Banner;
