import React from 'react';
import facebookLogo from './../../../assets/icons/facebook_logo.svg';
import FacebookLogin from 'react-facebook-login';
import { Avatar, Button, toaster } from 'evergreen-ui';
import { connect } from 'react-redux';
import config from './../../../../../config';

const FacebookMessengerFeed = ({
  hasWriteAccess,
  fetchFacebookPages,
  connectionLoading,
  connectChannel,
  connectableChannel,
  setShowConnectModal,
  type,
}) => {
  const [showPagesList, setShowPagesList] = React.useState(false);
  const [pageList, setPageList] = React.useState([]);

  const processFacebookResponse = async (response) => {
    if (response.status !== 'unknown') {
      const data = await fetchFacebookPages(
        response.id,
        response.accessToken,
        type
      );
      if (!!data.dataSource) {
        setPageList(data.dataSource);
      }
    } else {
      toaster.warning("Couldn't Connect facebook", {
        description:
          "We Couldn't Connect to faccebook to update page information ! Please Try Again",
      });
    }
    setShowPagesList(true);
  };
  return (
    <>
      {!showPagesList && (
        <div>
          <img
            src={facebookLogo}
            className='channel-settings__card-channel-create-banner'
            alt={'facebook logo'}
          />
          <p className='channel-settings__card-channel-create-title'>
            Connect With Facebook!
          </p>
          <p className='channel-settings__card-channel-create-description'>
            Connect your facebook account, you can edit the details later.
            Please provide us the required permission to get started!
          </p>
          <br />
          <FacebookLogin
            appId={config.facebookAppId}
            isDisabled={!hasWriteAccess}
            autoLoad={false}
            fields='email'
            scope='manage_pages,pages_show_list,publish_pages,pages_messaging,public_profile'
            callback={processFacebookResponse}
            cssClass='facebook-button-connect'
            icon='fa-facebook'
            textButton='Connect With Facebook'
          />
        </div>
      )}
      {!!showPagesList && (
        <div>
          <div className='channel-connect-fb__modal-container'>
            <img
              src={facebookLogo}
              className='channel-connect-fb__modal-container-image'
              alt={'facebook logo'}
            />
            <div className='channel-connect-fb__modal-container-content'>
              <p className='channel-connect-fb__modal-container-content-title'>
                Connect With Facebook!
              </p>
              <p className='channel-connect-fb__modal-container-content-subtitle'>
                Select your facebook page.
              </p>
            </div>
          </div>
          <div className='channel-connect-fb__modal-container-page-list'>
            {pageList.map((page, index) => (
              <div
                key={index}
                className='channel-connect-fb__modal-container-page-list-item'
              >
                <div className='flex space-between'>
                  <div className='flex center-flex'>
                    <Avatar
                      src={
                        page.avatar || 'https://api.adorable.io/avatars/alice'
                      }
                      name={page?.name}
                      size={30}
                      marginRight={20}
                    />
                    <a
                      href={'https://google.com'}
                      className='no-underline'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {page?.name}
                    </a>
                  </div>
                </div>
                <div>
                  <Button
                    className={'alice-connect-style facebook'}
                    iconBefore={
                      !connectionLoading &&
                      (page.is_connected ? 'link' : 'tick-circle')
                    }
                    isLoading={connectionLoading}
                    height={26}
                    disabled={page.is_connected || !hasWriteAccess}
                    onClick={async () => {
                      const res = await connectChannel(
                        connectableChannel.id,
                        page
                      );
                      if (res) {
                        setPageList([]);
                        setShowConnectModal(false);
                      }
                    }}
                  >
                    {page.is_connected ? 'Connected' : 'Select'}
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const mapState = (state) => ({
  connectionLoading: state.loading.effects.channel.connectChannel,
});

const mapDispatch = (dispatch) => ({
  fetchFacebookPages: (id, token, type) =>
    dispatch.channel.fetchFacebookPages({ id, token, type }),
  connectChannel: (id, data) => dispatch.channel.connectChannel({ id, data }),
});

const FacebookMessengerFeedContainer = connect(
  mapState,
  mapDispatch
)(FacebookMessengerFeed);

export default FacebookMessengerFeedContainer;
