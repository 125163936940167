import React from 'react';
import {connect} from 'react-redux';
import {CreatableSelect} from '@atlaskit/select';
import {Icon, Pane, TagInput} from "evergreen-ui";

interface props {
    tags: any;
    currentTicketTag: any;
    createTicketTag: (body: object) => any;
    assignTicketTag: (payload: object) => any;
}


const TagTicket: React.FC<props> = ({createTicketTag, assignTicketTag, tags, currentTicketTag}) => {
    const currentTicketTagItems = (currentTicketData = currentTicketTag) => {
        let ticketTags: any[] = []
        if (currentTicketData.tags !== 0) {
            currentTicketData.tags.map((field: any) => ticketTags.push(field.name.toUpperCase()))
        }
        return ticketTags
    }

    const handleInputTag = (
        inputValue: any,
        allTag = tags,
        assignTicketTagFunction = assignTicketTag,
        createTicketTagFunction = createTicketTag
    ) => {
        let isDuplicate = false
        let payloadData = {}
        allTag.forEach((field: any) => {
            if (inputValue.value === field.id) isDuplicate = true;
        })
        if (isDuplicate) {
            payloadData = {"body": {action: 'add', name: inputValue.label, id: inputValue.value}}
            assignTicketTagFunction(payloadData)
        } else {
            payloadData = {value: inputValue.value}
            createTicketTagFunction(payloadData)
        }
    }

    const removeTag = (
        tagName: any,
        assignTicketTagFunction = assignTicketTag,
        currentTicketData = currentTicketTag) =>
    {
        let payload = {}
        let returnValue = void
        currentTicketData.tags.forEach((fields: any) => {
            if (tagName.toLowerCase() === fields.name.toLowerCase()) {
                payload['body'] = {action: 'remove', name: fields.name, id: fields.id}
            }
        })
        if (payload) {
            assignTicketTagFunction(payload)
        }
        return returnValue;
    }

    return (
        <Pane height={200}>
            <div className='crm-ticket-tag__search-bar'>
                <CreatableSelect
                    onChange={(value: any) => handleInputTag(value)}
                    openMenuOnFocus={false}
                    placeholder={'search and hit enter to create new tag'}
                    components={{DropdownIndicator: () => null}}
                    options={tags.length !== 0
                        ? tags.map((field: any) => ({
                            label: field.name,
                            value: field.id,
                        }))
                        : []
                    }
                    value=''
                />
                <div className='crm-ticket-tag__search-icon'>
                    <Icon icon='search' size={16} />
                </div>
            </div>
            <TagInput
                style={{boxShadow: 'None', margin: '10px auto 10px'}}
                tagProps={{height: '25px'}}
                width="100%"
                values={currentTicketTagItems()}
                onRemove={(value, index) => {
                    removeTag(value)
                }}
            />
        </Pane>
    );
};

const mapState = (state: any) => ({
    tags: state.crm.allTicketTags,
    currentTicketTag: state.crm.currentTicket,
});

const mapDispatch = (dispatch: any) => ({
    createTicketTag: (body: object) => dispatch.crm.createTicketTag(body),
    assignTicketTag: (payload: object) => dispatch.crm.assignTicketTag(payload)

});
const TicketTag = connect(mapState, mapDispatch)(TagTicket);
export default TicketTag;
