import axios from '../../../httpClient';
import config from '../../../config';
import { subDays } from 'date-fns';
import { getReportingVariableNameFromEndpoint } from './../../../utils';
import _ from 'lodash';
import dayjs from 'dayjs';
import { toaster } from 'evergreen-ui';
import { exportCSV } from './../../../utils';

const initialState = {
  data: null,
  currentTab: {
    id: 0,
    name: 'User Metrics',
  },
  dateRange: [
    {
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: 'selection',
    },
  ],
  platformData: {
    id: null,
    type: 'all',
    title: 'All Channels',
  },
  userCount: null,
  interactiveUserCount: null,
  referralGrowthData: null,
  intentGrowthData: null,
  languageData: null,
  timezoneData: null,
  countryData: null,
  heatmapData: null,
  genderData: null,
  postbackVTextData: null,
  triggeredMsgReferralnKeyword: null,
  triggeredMsgIntentnPostback: null,
  ticketResolvedCount: null,
  avgTicketPerUser: null,
  avgTicketPerOverallUser: null,
  ticketCreatedCount: null,
  textualVsAutomation: [{ textual_message: 0, automated: 0 }],
  ticketTagCloud: [],
  ticketListTable: [],
  agentList: [],
  averageResolveTime: null,
  averageQueueTime: null,
  agentStats: [],
  conversationLogs: [],
  intentList: [],
};

export const reporting = {
  state: {
    ...initialState,
  },
  reducers: {
    updateStateData(state, payload) {
      //key value
      return { ...state, [payload.key]: payload.value };
    },
    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    //user metrics
    async fetchUserReportData(payload, rootState) {
      /*
      payload= {projectId: num, endpoint }
       */
      try {
        const res = await axios.get(
          `${config.analytics}/${payload.projectId}/${payload.endpoint}`,
          {
            params: {
              platform: rootState.reporting.platformData.id,
              start: dayjs(rootState.reporting.dateRange[0].startDate).format(
                'YYYY-MM-DD'
              ),
              end: dayjs(rootState.reporting.dateRange[0].endDate).format(
                'YYYY-MM-DD'
              ),
            },
          }
        );

        if (res.data.success) {
          const dataLocalGrowth = parseFloat(
            res.data.dataSource.growth.split('%')[0]
          );
          let dataLocal = {
            ...res.data.dataSource,
            growthType: dataLocalGrowth < 0 ? 'down' : 'up',
          };
          dispatch.reporting.updateStateData({
            key: getReportingVariableNameFromEndpoint(payload.endpoint),
            value: dataLocal,
          });
        } else {
          dispatch.reporting.updateStateData({
            key: getReportingVariableNameFromEndpoint(payload.endpoint),
            value: null,
          });
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async fetchUserReportDataDefaultAppend(payload, rootState) {
      /*
      payload= {projectId: num, endpoint }
       */
      try {
        const res = await axios.get(
          `${config.analytics}/${payload.projectId}/${payload.endpoint}`,
          {
            params: {
              platform: rootState.reporting.platformData.id,
              start: dayjs(rootState.reporting.dateRange[0].startDate).format(
                'YYYY-MM-DD'
              ),
              end: dayjs(rootState.reporting.dateRange[0].endDate).format(
                'YYYY-MM-DD'
              ),
            },
          }
        );

        if (res.data.success) {
          dispatch.reporting.updateStateData({
            key: getReportingVariableNameFromEndpoint(payload.endpoint),
            value: res.data.dataSource,
          });
        } else {
          dispatch.reporting.updateStateData({
            key: getReportingVariableNameFromEndpoint(payload.endpoint),
            value: null,
          });
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async fetchUserReportDataUserMetricsLineGraph(payload, rootState) {
      /*
      payload= {projectId: num, endpoint }
       */
      try {
        const res = await axios.get(
          `${config.analytics}/${payload.projectId}/${payload.endpoint}`,
          {
            params: {
              platform: rootState.reporting.platformData.id,
              start: dayjs(rootState.reporting.dateRange[0].startDate).format(
                'YYYY-MM-DD'
              ),
              end: dayjs(rootState.reporting.dateRange[0].endDate).format(
                'YYYY-MM-DD'
              ),
            },
          }
        );
        const graphList = [
          'get_language_metrics',
          'get_timezone_metrics',
          'get_country_metrics',
          'get_ticket_avg_queue_time',
          'get_ticket_avg_resolve_time',
        ];

        if (res.data.success) {
          if (graphList.indexOf(payload.endpoint) !== -1) {
            let dataFinal = { ...res.data.dataSource };
            if (_.isEmpty(res.data.dataSource.max)) {
              dataFinal.max = {
                name: 'Not Available',
                value: 0,
                growth: '+0.00%',
              };
            }
            const dataLocalGrowth = parseFloat(
              dataFinal.max.growth.split('%')[0]
            );
            dataFinal['growthType'] = dataLocalGrowth < 0 ? 'down' : 'up';
            dispatch.reporting.updateStateData({
              key: getReportingVariableNameFromEndpoint(payload.endpoint),
              value: dataFinal,
            });
          }
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async fetchUserReportHeatmap(projectId, rootState) {
      /*
      payload= {projectId: num, endpoint }
       */
      try {
        const res = await axios.get(
          `${config.analytics}/${projectId}/conversation_heatmap`,
          {
            params: {
              platform: rootState.reporting.platformData.id,
              start: dayjs(rootState.reporting.dateRange[0].startDate).format(
                'YYYY-MM-DD'
              ),
              end: dayjs(rootState.reporting.dateRange[0].endDate).format(
                'YYYY-MM-DD'
              ),
            },
          }
        );
        if (res.data.success) {
          let defaultHeatmapData = Array(7)
            .fill(0)
            .map((x) => new Array(24).fill(0));
          const payloadLocal = res.data.dataSource
            .filter((e) => e.day < 7)
            .filter((e) => e.hour < 24);
          payloadLocal.forEach((elem) => {
            defaultHeatmapData[elem.day][elem.hour] = elem.count;
          });
          dispatch.reporting.updateStateData({
            key: 'heatmapData',
            value: defaultHeatmapData,
          });
        } else {
          dispatch.reporting.updateStateData({
            key: 'heatmapData',
            value: null,
          });
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async fetchAutomationTicketTable(payload, rootState) {
      /*
      payload= {projectId: num, groupId: number || null }
       */
      try {
        const res = await axios.get(
          `${config.analytics}/${payload.projectId}/get_ticket_list`,
          {
            params: {
              platform: rootState.reporting.platformData.id,
              start: dayjs(rootState.reporting.dateRange[0].startDate).format(
                'YYYY-MM-DD'
              ),
              end: dayjs(rootState.reporting.dateRange[0].endDate).format(
                'YYYY-MM-DD'
              ),
              agent_id: payload.groupId,
            },
          }
        );
        if (res.data.success) {
          dispatch.reporting.updateStateData({
            key: 'ticketListTable',
            value: res.data.dataSource,
          });
        } else {
          dispatch.reporting.updateStateData({
            key: 'ticketListTable',
            value: [],
          });
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async fetchUserReportDataOnTabClick(payload, rootState) {
      /*
      payload= {projectId: num, endpoint }
       */
      try {
        const res = await axios.get(
          `${config.analytics}/${payload.projectId}/${payload.endpoint}`,
          {
            params: {
              platform: rootState.reporting.platformData.id,
              start: dayjs(rootState.reporting.dateRange[0].startDate).format(
                'YYYY-MM-DD'
              ),
              end: dayjs(rootState.reporting.dateRange[0].endDate).format(
                'YYYY-MM-DD'
              ),
            },
          }
        );
        if (res.data.success) {
          dispatch.reporting.updateStateData({
            key: getReportingVariableNameFromEndpoint(payload.endpoint),
            value: res.data.dataSource,
          });
        } else {
          dispatch.reporting.updateStateData({
            key: getReportingVariableNameFromEndpoint(payload.endpoint),
            value: null,
          });
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async fetchAgentList(teamId) {
      /*
      payload= {projectId: num, endpoint }
       */
      try {
        const res = await axios.get(`${config.crm}/${teamId}/agents`);
        if (res.data.success) {
          dispatch.reporting.updateStateData({
            key: 'agentList',
            value: res.data.dataSource,
          });
        } else {
          dispatch.reporting.updateStateData({
            key: 'agentList',
            value: null,
          });
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async exportCustomerTicket(payload, rootState) {
      /*
      payload= {projectId: num, endpoint }
       */
      try {
        const res = await axios.get(
          `${config.analytics}/${payload.projectId}/export_ticket_list`,
          {
            params: {
              platform: rootState.reporting.platformData.id,
              start: dayjs(rootState.reporting.dateRange[0].startDate).format(
                'YYYY-MM-DD'
              ),
              end: dayjs(rootState.reporting.dateRange[0].endDate).format(
                'YYYY-MM-DD'
              ),
              agent_id: payload.groupId,
            },
          }
        );
        if (res.status === 200) {
          exportCSV(
            res.data,
            `ticket-list_${rootState.dashboard.selectedProject.name}-${dayjs(
              rootState.reporting.dateRange[0].startDate
            ).format('YYYY-MM-DD')} to ${dayjs(
              rootState.reporting.dateRange[0].endDate
            ).format('YYYY-MM-DD')}`
          );
        } else {
          toaster.warning('Failed', { description: 'Failed to Export Data' });
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async exportAgentDetails(projectId, rootState) {
      /*
      payload= {projectId: num, endpoint }
       */
      try {
        const res = await axios.get(
          `${config.analytics}/${projectId}/export_agent_stats`,
          {
            params: {
              platform: rootState.reporting.platformData.id,
              start: dayjs(rootState.reporting.dateRange[0].startDate).format(
                'YYYY-MM-DD'
              ),
              end: dayjs(rootState.reporting.dateRange[0].endDate).format(
                'YYYY-MM-DD'
              ),
            },
          }
        );
        if (res.status === 200) {
          exportCSV(
            res.data,
            `agent-details_${rootState.dashboard.selectedProject.name}-${dayjs(
              rootState.reporting.dateRange[0].startDate
            ).format('YYYY-MM-DD')} to ${dayjs(
              rootState.reporting.dateRange[0].endDate
            ).format('YYYY-MM-DD')}`
          );
        } else {
          toaster.warning('Failed', { description: 'Failed to Export Data' });
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async fetchConversationLog(payload, rootState) {
      /*
      payload= {projectId: num, agentId: num, intent: string, limit, offset }
       */
      try {
        const res = await axios.get(
          `${config.analytics}/${payload.projectId}/get_conversation_logs`,
          {
            params: {
              platform: rootState.reporting.platformData.id,
              start: dayjs(rootState.reporting.dateRange[0].startDate).format(
                'YYYY-MM-DD'
              ),
              end: dayjs(rootState.reporting.dateRange[0].endDate).format(
                'YYYY-MM-DD'
              ),
              agent_id: payload.agentId,
              intent: payload.intent,
              limit: payload.limit,
              offset: payload.offset,
            },
          }
        );
        if (res.data.success) {
          dispatch.reporting.updateStateData({
            key: 'conversationLogs',
            value: res.data.dataSource,
          });
        } else {
          dispatch.reporting.updateStateData({
            key: 'conversationLogs',
            value: null,
          });
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async fetchIntentReporting(payload) {
      /*
      payload = {
        projectId : num
        }
       */
      try {
        const res = await axios.get(`${config.projects}/${payload}/intents`);
        if (res.data.success) {
          dispatch.reporting.updateStateData({
            key: 'intentList',
            value: res.data.dataSource,
          });
        } else {
          dispatch.reporting.updateStateData({
            key: 'intentList',
            value: [],
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
  }),
};
