import React from 'react';
import CopyText from "../../../../../components/elements/redesign/CopyText";

interface Prop {
  icon: string;
  title: string;
  description: string
}

const OrderCardSummaryList: React.FC<Prop> = ({icon, title,description}) => {

  return (
    <div className='flex align-flex-start mb-10px'>
      <img src={icon} width={26} alt='bill' />
      <div className='flex flex-column ml-5px'>
        <p className='order-right-bar__order-card__header-title'>{title}&nbsp;<CopyText height={24} textToCopy={description}/></p>
        <p className='order-right-bar__order-card__header-subtitle'>{description}</p>
      </div>
    </div>
  );
};

export default OrderCardSummaryList;
