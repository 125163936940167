import React from 'react';
import UserInformationSection from './RightSideFragments/UserInformationSection';
import {connect} from 'react-redux';
import Collapse from "../../../components/elements/redesign/Collapse";
import RightBarEComActionBar from "./RightSideFragments/eCommerceFragments/RightBarEComActionBar";
import OrderHistoryRightbarContainer from "./RightSideFragments/eCommerceOrderFragments/OrderHistoryRightbarContainer";


interface props {
  marketPlaceData: any;
  getEcommerceCustomerList: (teamId: number, search: string) => void;
  projectId: number;
  ecommerceCollectionReference: any;
  getEcommerceCustomerListLoader: boolean;
  customerList: any[];
  currentTicket: any;
  linkCustomerWithCRM: (teamId: number, customerId: number, ecommerceId: number) => Promise<boolean>;
  unlinkCustomerWithCRM: (teamId: number, customerId: number) => Promise<boolean>;
  linkLoader: boolean;
  eComOrderList: any[];
  eComOrderListLoader: boolean;
  fetchCRMCustomerOrder: (teamId: number, customerId: number, orderId: string) => void;
  customerMeta: any;
}

const CRMRightSection: React.FC<props> = (
  {
    marketPlaceData,
    getEcommerceCustomerList,
    projectId,
    ecommerceCollectionReference,
    getEcommerceCustomerListLoader,
    customerList,
    currentTicket,
    linkCustomerWithCRM,
    unlinkCustomerWithCRM,
    linkLoader,
    eComOrderList,
    eComOrderListLoader,
    fetchCRMCustomerOrder,
    customerMeta,
  }) => {

  return (
    <div className='right-side-info-bar'>
      {!!marketPlaceData?.is_verified &&
      <RightBarEComActionBar
        fetchEcommerceCustomerList={(search: string) => getEcommerceCustomerList(projectId, search)}
        fetchEcommerceCustomerListLoader={getEcommerceCustomerListLoader}
        marketPlaceData={marketPlaceData}
        ecommerceCollectionReference={ecommerceCollectionReference}
        customerList={customerList}
        currentTicket={currentTicket}
        linkCustomerWithCRM={async (ecommerceId: number) => {
          return await linkCustomerWithCRM(projectId, currentTicket.customer.id, ecommerceId)
        }}
        unlinkCustomerWithCRM={async () => {
          return await unlinkCustomerWithCRM(projectId, currentTicket.customer.id)
        }}
        linkLoader={linkLoader}
      />}
      <Collapse header='Client Information' isOpen={true}>
        <UserInformationSection/>
      </Collapse>
      <div className='mt-5px'/>
      {marketPlaceData.is_verified
      && marketPlaceData.ecommerce_type === 'woocommerce'
      && !!customerMeta?.ecommerce_collection_reference &&
      <Collapse header='Order History' isOpen={false}>
        <OrderHistoryRightbarContainer
          eComOrderList={eComOrderList}
          loader={eComOrderListLoader}
          fetchCRMCustomerOrder={(search: string) => fetchCRMCustomerOrder(projectId, currentTicket.customer.id, search)}
        />
      </Collapse>
      }
    </div>
  );
};

const mapState = (state: any) => ({
  marketPlaceData: state.marketplace.marketPlaceData,
  customerMeta: state.crm.customerMeta,
  projectId: state.dashboard?.selectedProject?.id || null,
  ecommerceCollectionReference: state.crm.customerMeta?.ecommerce_collection_reference || null,
  getEcommerceCustomerListLoader: state.loading.effects.marketplace.getEcommerceCustomerList,
  customerList: state.marketplace.customerList,
  currentTicket: state.crm.currentTicket,
  linkLoader: state.loading.effects.marketplace.linkCustomerWithCRM,
  eComOrderList: state.crm.eComOrderList,
  eComOrderListLoader: state.loading.effects.crm.fetchCRMCustomerOrder,
});

const mapDispatch = (dispatch: any) => ({
  getEcommerceCustomerList: (teamId: number, search: string) => dispatch.marketplace.getEcommerceCustomerList({
    teamId,
    search
  }),
  linkCustomerWithCRM: (teamId: number, customerId: number, ecommerceId: number) => dispatch.marketplace.linkCustomerWithCRM({
    teamId,
    customerId,
    ecommerceId
  }),
  unlinkCustomerWithCRM: (teamId: number, customerId: number) => dispatch.marketplace.unlinkCustomerWithCRM({
    teamId,
    customerId
  }),
  fetchCRMCustomerOrder: (teamId: number, customerId: number, orderId: string) => dispatch.crm.fetchCRMCustomerOrder({
    teamId,
    customerId,
    orderId
  }),
});

const CRMRightSectionContainer = connect(
  mapState,
  mapDispatch
)(CRMRightSection);
export default CRMRightSectionContainer;
