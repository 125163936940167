import React, { useState } from 'react';
import { Button, Dialog, toaster } from 'evergreen-ui';
import { navigate } from '@reach/router';

interface Props {
  planId: number;
  name: string;
  users: number;
  date: string;
  price: string;
  isActive: boolean;
  isTrial: boolean;
  hasCard: boolean;
  currentPlanPrice: string;
  createSubscription: Function;
  handleSubscriptionUpdate: Function;
  selectedProject: any;
}

const PlanCard: React.FC<Props> = ({
  planId,
  name,
  users,
  date,
  price,
  isActive,
  isTrial,
  hasCard,
  currentPlanPrice,
  createSubscription,
  handleSubscriptionUpdate,
  selectedProject,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [isPaymentShown, setIsPaymentShown] = useState(false);

  const planPrice = parseInt(price);
  const currentPrice = parseInt(currentPlanPrice);
  const action = planPrice > currentPrice ? 'upgrade' : 'downgrade';

  return (
    <div className={`plan-card-container ${isActive && 'active'}`}>
      {isActive && <p>ACTIVE SUBSCRIPTION</p>}
      {isTrial && isActive && <p>You are now enjoying trial</p>}
      <div className='plan-card'>
        <h2 className='plan-card__title'>{name}</h2>
        <div className='plan-card__info'>
          <div className='plan-card__info-item'>
            <h4>Users Monthly</h4>
            <p>{name === 'Enterprise' ? 'Unlimited' : users}</p>
          </div>
          <div className='plan-card__info-item'>
            <h4>Billing Date</h4>
            <p>{date}</p>
          </div>
          <div className='plan-card__info-item'>
            <h4>Price Estimate</h4>
            <p>{name === 'Enterprise' ? 'Custom' : `$${price}`}</p>
          </div>
        </div>
        <div className='plan-card__action'>
          <Button
            className='alice-btn-alt'
            is='a'
            href='https://getalice.ai/pricing'
            target='_blank'
          >
            Details
          </Button>
          {name === 'Enterprise' && !isActive ? (
            <Button
              is='a'
              href='https://airtable.com/shrvMCwEUGQU7TvRR'
              target='_blank'
              className='alice-btn-primary'
            >
              Contact Us
            </Button>
          ) : (
            <Button
              className='alice-btn-primary'
              disabled={isActive}
              onClick={() => {
                if (hasCard) {
                  setIsShown(true);
                } else {
                  setIsPaymentShown(true);
                }
              }}
            >
              {isActive ? 'Activated' : 'Activate'}
            </Button>
          )}
        </div>
      </div>
      <Dialog
        isShown={isShown}
        onCloseComplete={() => setIsShown(false)}
        hasFooter={false}
        hasHeader={false}
      >
        <div className='plan-card__dialogue'>
          <p className='mb-50px plan-card__dialogue-message'>
            Are you sure want to {action} to {name}?
          </p>
          <p>
            You have requested a {action} from your current package. Please be
            sure before your confirmation.
          </p>
          <br />
          <br />

          <div className='flex flex-end'>
            <Button
              marginLeft={30}
              className='alice-btn-alt mr-10px'
              onClick={() => setIsShown(false)}
            >
              Cancel
            </Button>
            <Button
              className='alice-btn-primary'
              isLoading={isLoading}
              onClick={async () => {
                setIsLoading(true);

                const data = {
                  plan_id: planId,
                  action: action,
                  project_id: selectedProject.id
                };
                const success = await createSubscription(data);
                if (success) {
                  toaster.success('Congratulations!', {
                    description: `You have been ${action}d to ${name}.`,
                    duration: 3,
                  });
                  await handleSubscriptionUpdate();
                  setIsLoading(false);
                  setIsShown(false);
                } else {
                  toaster.danger('Sorry!', {
                    description: 'Could not process the request.',
                    duration: 2,
                  });
                  setIsLoading(false);
                  setIsShown(false);
                }
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        isShown={isPaymentShown}
        onCloseComplete={() => setIsPaymentShown(false)}
        hasFooter={false}
        hasHeader={false}
      >
        <div className='plan-card__dialogue'>
          <p className='mb-50px plan-card__dialogue-message'>
            You do not have any payment method yet.
          </p>
          <p>
            You have requested a {action} from your current package. But you did
            not add any payment method yet. To continue, please add a payment
            method first.
          </p>
          <br />
          <br />

          <div className='flex flex-end'>
            <Button
              marginLeft={30}
              className='alice-btn-alt mr-10px'
              onClick={() => setIsPaymentShown(false)}
            >
              Cancel
            </Button>
            <Button
              className='alice-btn-primary'
              onClick={() => {
                navigate('/settings/payment-method');
              }}
            >
              Add Card
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default PlanCard;
