import React, { Fragment, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Table,
  Avatar,
  Text,
  InlineAlert,
  Badge,
  SideSheet,
  Pane,
  Heading,
  Card,
} from 'evergreen-ui';
import LoaderLine from '../../../components/loaders/LoaderLine';

const AudienceTable = ({ handleNameSearch, loading, mockAudienceData }) => {
  const [isShown, setIsShown] = useState(false);
  const [metaData, setMetaData] = useState(null);

  const genderColor = { male: 'green', female: 'blue' };

  return (
    <Fragment>
      <Table className='audience-table'>
        <Table.Head>
          <Table.SearchHeaderCell
            flexBasis={150}
            placeholder='Search Name Here...'
            onChange={(value) => handleNameSearch(value)}
          />
          <Table.TextHeaderCell>Phone</Table.TextHeaderCell>
          <Table.TextHeaderCell>Email</Table.TextHeaderCell>
          <Table.TextHeaderCell>Last Message Time</Table.TextHeaderCell>
          <Table.TextHeaderCell>Created At</Table.TextHeaderCell>
          <Table.TextHeaderCell>Attribute</Table.TextHeaderCell>
        </Table.Head>
        {loading ? (
          <LoaderLine />
        ) : (
          mockAudienceData.length === 0 && (
            <InlineAlert
              className='no-data-alert'
              intent='warning'
              marginBottom={16}
            >
              No Data Available
            </InlineAlert>
          )
        )}
        {!loading && (
          <Table.VirtualBody height={window.innerHeight - 200}>
            {mockAudienceData.length > 0 &&
              mockAudienceData.map((customer, i) => (
                <Table.Row key={i} isSelectable>
                  <Table.Cell
                    display='flex'
                    alignItems='center'
                    flexBasis={150}
                  >
                    <Avatar
                      src={customer.avatar}
                      name={customer.full_name ? customer.full_name : 'Unknown'}
                    />
                    <Text marginLeft={8} size={300} fontWeight={500}>
                      {customer.full_name ? customer.full_name : 'Unknown User'}{' '}
                      {!!customer.gender ? (
                        <Badge color={genderColor[customer.gender]}>
                          {customer.gender}
                        </Badge>
                      ) : (
                        ''
                      )}
                    </Text>
                  </Table.Cell>
                  <Table.Cell>
                    {!!customer.phone ? (
                      customer.phone
                    ) : (
                      <Badge color='red'>N/A</Badge>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {!!customer.email ? (
                      customer.email
                    ) : (
                      <Badge color='red'>N/A</Badge>
                    )}
                  </Table.Cell>
                  <Table.TextCell>
                    {!!customer.last_message_time ? (
                      moment(
                        parseFloat(customer.last_message_time) * 1000
                      ).fromNow()
                    ) : (
                      <Badge color='red'>N/A</Badge>
                    )}
                  </Table.TextCell>
                  <Table.TextCell>
                    {!!customer.created_at ? (
                      moment(parseFloat(customer.created_at) * 1000).fromNow()
                    ) : (
                      <Badge color='red'>N/A</Badge>
                    )}
                  </Table.TextCell>
                  <Table.TextCell>
                    <Badge
                      isSolid={true}
                      style={{ cursor: 'pointer' }}
                      color='green'
                      onClick={() => {
                        setIsShown(true);
                        setMetaData(customer.meta);
                      }}
                    >
                      See Details
                    </Badge>
                  </Table.TextCell>
                </Table.Row>
              ))}
          </Table.VirtualBody>
        )}
      </Table>
      <SideSheet
        isShown={isShown}
        width={'40vw'}
        onCloseComplete={() => setIsShown(false)}
        containerProps={{
          display: 'flex',
          flex: '1',
          flexDirection: 'column',
        }}
      >
        <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor='white'>
          <Pane padding={16}>
            <Heading size={600}>Attribute Details</Heading>
          </Pane>
        </Pane>
        <Pane flex='1' overflowY='scroll' background='tint1' padding={16}>
          <Card backgroundColor='white' elevation={0}>
            <Table>
              <Table.Head>
                <Table.TextHeaderCell>key</Table.TextHeaderCell>
                <Table.TextHeaderCell>value</Table.TextHeaderCell>
              </Table.Head>
              <Table.Body>
                {!!metaData && Object.keys(metaData).length === 0 && (
                  <InlineAlert
                    className='no-data-alert'
                    intent='warning'
                    marginBottom={16}
                  >
                    No Data Available
                  </InlineAlert>
                )}
                {!!metaData &&
                  Object.keys(metaData).map((metaField, i) => (
                    <Table.Row key={i} height='auto' paddingY={12}>
                      <Table.TextCell>{metaField}</Table.TextCell>
                      <Table.TextCell className='text-wrap__child'>
                        {!!Object.values(metaData)[i] &&
                          (typeof Object.values(metaData)[i] === 'object'
                            ? JSON.stringify(Object.values(metaData)[i])
                            : Object.values(metaData)[i])}
                      </Table.TextCell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Card>
        </Pane>
      </SideSheet>
    </Fragment>
  );
};

AudienceTable.propTypes = {
  handleNameSearch: PropTypes.func,
  loading: PropTypes.bool,
  mockAudienceData: PropTypes.array,
};

AudienceTable.defaultProps = {
  loading: false,
  mockAudienceData: [],
  handleNameSearch: () =>
    console.log('provide handleNameSearch as prop from the component'),
};
export default AudienceTable;
