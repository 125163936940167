import React from 'react';
import {
  getUpdatedPlatformIconOutlined,
  getPlatformInfoTitle,
  reportingTab,
} from '../../../utils';
import { Pane, Popover, Position, Menu, Button, Icon } from 'evergreen-ui';
import dayjs from 'dayjs';
import { DateRangePicker } from 'react-date-range';

interface tabProps {
  id: number;
  name: string;
}
interface props {
  selectedProject: any;
  selectedPlatformLocal: any;
  setSelectedPlatformLocal: (channel: any) => void;
  dateRange: any;
  setDateRange: (val: any) => void;
  finalDateRange: any;
  onDateFilter: () => void;
  currentTab: tabProps;
  updateStateData: (key: string, value: tabProps) => void;
  fetchAnalyticsData: () => void;
}

const ReportingNav: React.FC<props> = ({
  selectedProject,
  setSelectedPlatformLocal,
  selectedPlatformLocal,
  dateRange,
  setDateRange,
  finalDateRange,
  onDateFilter,
  currentTab,
  updateStateData,
  fetchAnalyticsData,
}) => {
  return (
    <div className='reporting-container__nav'>
      <ul>
        {reportingTab.map((tab: tabProps) => (
          <li
            key={tab.id}
            onClick={() => {
              updateStateData('currentTab', tab);
            }}
            className={tab.id === currentTab.id ? 'active' : ''}
          >
            {tab.name}
          </li>
        ))}
      </ul>
      <Pane
        display='flex'
        justifyContent='flex-end'
        alignItems='center'
        padding={0}
        marginBottom={16}
        marginLeft={10}
        borderRadius={4}
      >
        <div className='mr-15px'>
          <Popover
            position={Position.BOTTOM_RIGHT}
            content={({ close }) => (
              <Menu>
                <Menu.Group>
                  <Menu.Item
                    key={-1}
                    onSelect={() => {
                      setSelectedPlatformLocal({
                        id: null,
                        type: 'all',
                        title: 'All Channels',
                      });
                      setTimeout(() => {
                        fetchAnalyticsData();
                      }, 500);
                      close();
                    }}
                  >
                    <div className='flex align-center'>
                      <img
                        src={getUpdatedPlatformIconOutlined('all')}
                        alt={'lorem'}
                        width={12}
                      />
                      &nbsp;&nbsp; ALL
                    </div>
                  </Menu.Item>
                  {selectedProject.platforms.map(
                    (channel: any, key: number) => (
                      <Menu.Item
                        key={key}
                        onSelect={() => {
                          setSelectedPlatformLocal(channel);
                          setTimeout(() => {
                            fetchAnalyticsData();
                          }, 500);
                          close();
                        }}
                      >
                        <div className='flex align-center'>
                          <img
                            src={getUpdatedPlatformIconOutlined(
                              !!channel.type ? channel.type : 'all'
                            )}
                            alt={'lorem'}
                            width={20}
                          />
                          &nbsp;&nbsp; {getPlatformInfoTitle(channel)}
                        </div>
                      </Menu.Item>
                    )
                  )}
                </Menu.Group>
              </Menu>
            )}
          >
            <Button
              className='menu-icon'
              icon='more'
              height={32}
              style={{ background: 'white' }}
            >
              <div className='flex align-center'>
                {
                  <img
                    src={getUpdatedPlatformIconOutlined(
                      !!selectedPlatformLocal.type
                        ? selectedPlatformLocal.type
                        : 'all'
                    )}
                    alt={'lorem'}
                    width={selectedPlatformLocal.type === 'all' ? 12 : 20}
                  />
                }{' '}
                &nbsp;&nbsp; {getPlatformInfoTitle(selectedPlatformLocal)}{' '}
                &nbsp;
                <Icon icon={'caret-down'} size={16} />
              </div>
            </Button>
          </Popover>
        </div>
        <div>
          <Popover
            content={({ close }) => (
              <Pane
                display='flex'
                alignItems='flex-start'
                flexDirection='column'
              >
                <DateRangePicker
                  onChange={(item: any) => setDateRange([item.selection])}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  rangeColors={['#007b65', '#005762']}
                  months={1}
                  ranges={dateRange}
                  direction='horizontal'
                />
                <br />
                <div className='flex w-100p mb-20px justify-center align-center'>
                  <Button
                    className='button-alice__gradient'
                    marginRight={10}
                    iconBefore={'search'}
                    onClick={() => {
                      onDateFilter();
                      close();
                    }}
                  >
                    Filter
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    className='button-alice__secondary_2'
                    onClick={() => close()}
                    iconBefore={'cross'}
                  >
                    Cancel
                  </Button>
                </div>
              </Pane>
            )}
          >
            <Button
              className='button-alice__white btn-green-icon'
              iconBefore='calendar'
              marginRight={25}
            >
                {dayjs(finalDateRange[0].startDate).format('DD-MM-YYYY')}&nbsp; to &nbsp;
                {dayjs(finalDateRange[0].endDate).format('DD-MM-YYYY')}
            </Button>
          </Popover>
        </div>
      </Pane>
    </div>
  );
};

export default ReportingNav;
