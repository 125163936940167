import React, { ReactNode } from 'react';
import { findAlertInfo } from '../../../utils';

interface Prop {
  intent: string; //'warning' | 'success' | 'info' | 'danger';
  title: string;
  text?: string | number;
  action?: ReactNode;
}

const Message: React.FC<Prop> = ({ intent, title, text, action }) => {
  return (
    <div
      className={`alice-message-component ${findAlertInfo(intent).className}`}
    >
      <img src={findAlertInfo(intent).icon} alt={'alert-icon'} />
      <div className='alice-message-component__content'>
        <h4>{title}</h4>
        <p>{text}</p>

        {action}
      </div>
    </div>
  );
};

export default Message;
