import React from 'react';
import PropTypes from 'prop-types';
import { TextInput, Textarea, SelectMenu, Button } from 'evergreen-ui';
import TimePicker from 'rc-time-picker';
import DateTime from 'react-datetime';
import ImageUploadField from '../../../components/ImageUploadField';
import FileUploadField from '../../../components/FileUploadField';
import { handleImageUpload, handleFileUpload } from '../../../utils';
import moment from 'moment';
import SelectSearch from 'react-select-search';
import '../../../assets/styles/react-select-search.css';
import 'rc-time-picker/assets/index.css';
import 'react-datetime/css/react-datetime.css';
import TextCharInfo from '../../../components/elements/TextCharInfo';

const getInputComponentFromType = (type, props) => {
  switch (type) {
    case 'text':
      return (
        <React.Fragment>
          <TextInput
            value={!!props.value ? props.value : ''}
            placeholder={props.placeholder}
            onChange={(e) => {
              if (props.max_length) {
                if (e.target.value.length <= props.max_length) {
                  props.handleChange(e.target.value);
                }
              } else {
                props.handleChange(e.target.value);
              }
            }}
          />
          {props.max_length && (
            <TextCharInfo data={props.value} count={props.max_length} />
          )}
        </React.Fragment>
      );
    case 'number':
      return (
        <React.Fragment>
          <TextInput
            type='number'
            placeholder={props.placeholder}
            value={!!props.value ? props.value : ''}
            onChange={(e) => {
              if (props.max_length) {
                if (e.target.value.length <= props.max_length) {
                  props.handleChange(e.target.value);
                }
              } else {
                props.handleChange(e.target.value);
              }
            }}
          />
          {props.max_length && (
            <TextCharInfo data={props.value} count={props.max_length} />
          )}
        </React.Fragment>
      );
    case 'textarea':
      return (
        <React.Fragment>
          <Textarea
            value={!!props.value ? props.value : ''}
            placeholder={props.placeholder}
            onChange={(e) => {
              if (props.max_length) {
                if (e.target.value.length <= props.max_length) {
                  props.handleChange(e.target.value);
                }
              } else {
                props.handleChange(e.target.value);
              }
            }}
          />
          {props.max_length && (
            <TextCharInfo data={props.value} count={props.max_length} />
          )}
        </React.Fragment>
      );
    case 'singleselect':
      return (
        <SelectSearch
          key='name'
          value={props.value}
          options={props.options.map((label) => ({
            name: label,
            value: label,
          }))}
          onChange={(item) => props.handleChange(item)}
          search
          placeholder='Select One Value'
        />
      );
    case 'multiselect':
      return (
        <SelectMenu
          isMultiSelect
          hasTitle={true}
          title={'Select Items'}
          height={props.options.length * 32 + 50}
          hasFilter={false}
          options={props.options.map((label) => ({ label, value: label }))}
          selected={props.value}
          onSelect={(item) => {
            let selected;
            if (props.value) {
              selected = [...props.value, item.value];
            } else {
              selected = [item.value];
            }
            props.handleChange(selected);
          }}
          onDeselect={(item) => {
            const deselectedItemIndex = props.value.indexOf(item.value);
            const selectedItems = props.value.filter(
              (_item, i) => i !== deselectedItemIndex
            );
            props.handleChange(selectedItems);
          }}
        >
          <Button>
            {(props.value &&
              props.value.length > 0 &&
              `${props.value.length} selected...`) ||
              'Select multiple...'}
          </Button>
        </SelectMenu>
      );
    case 'time':
      return (
        <TimePicker
          value={props.value ? moment(props.value, 'HH:mm') : moment()}
          allowEmpty={false}
          showSecond={false}
          use12Hours={true}
          onChange={(val) => props.handleChange(moment(val).format('HH:mm'))}
        />
      );
    case 'date':
      return (
        <DateTime
          value={props.value ? moment(props.value) : moment()}
          dateFormat={'DD/MM/YYYY'}
          onChange={(val) => props.handleChange(moment(val).toString())}
          timeFormat={false}
        />
      );
    case 'datetime':
      return (
        <DateTime
          value={props.value ? moment(props.value) : moment()}
          dateFormat={'DD/MM/YYYY'}
          onChange={(val) => props.handleChange(moment(val).toString())}
        />
      );
    case 'file':
      const handleFileDrop = (file) => {
        const callback = (file) => props.handleChange(file);
        handleFileUpload(file, callback);
      };
      const handleFileClear = () => {
        props.handleChange('');
      };
      return (
        <FileUploadField
          file={props.value}
          handleDrop={handleFileDrop}
          handleClear={handleFileClear}
        />
      );
    case 'image':
      const handleImageDrop = (image) => {
        const callback = (image) => props.handleChange(image);
        handleImageUpload(image, callback);
      };
      const handleImageClear = () => {
        props.handleChange('');
      };

      return (
        <ImageUploadField
          image={props.value}
          handleDrop={handleImageDrop}
          handleClear={handleImageClear}
        />
      );
    default:
      return (
        <TextInput
          value={props.value}
          placeholder={props.placeholder}
          onChange={(e) => props.handleChange(e.target.value)}
        />
      );
  }
};

const SmartInput = ({ type, ...props }) => {
  return (
    <div className='smart-input'>
      <label>
        {props.name}
        {props.is_required ? (
          <span style={{ color: '#EC4C47', fontSize: '1.1rem' }}>*</span>
        ) : null}
      </label>
      {getInputComponentFromType(type, props)}
    </div>
  );
};

SmartInput.propTypes = {
  type: PropTypes.string.isRequired,
};

export default SmartInput;
