import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Avatar,
  Table,
  IconButton,
  Button,
  Dialog,
  SelectMenu,
  TextInput,
  Pane,
  Spinner,
  toaster,
} from 'evergreen-ui';
import HasPermission from '../../../components/HasPermission';
import { isValidEmail, checkPermission } from '../../../utils';

class TeamUserSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAddShown: false,
      isDeleteShown: false,
      isUpdateShown: false,
      selected: null,
      userId: null,
      email: '',
      loading: false,
      writeAccess: false,
      dataLoading: false,
    };
  }

  clearState = () => {
    this.setState({
      isAddShown: false,
      isDeleteShown: false,
      isUpdateShown: false,
      selected: null,
      userId: null,
      email: '',
      loading: false,
      dataLoading: false,
    });
  };

  async componentDidMount() {
    this.setState({ dataLoading: true });
    await this.props.fetchProject();
    // always needs to update information on project settings mount

    if (!this.props.previousProject && this.props.selectedProject === null) {
      this.props.setSelectedProject(this.props.projects[0]);
      await this.props.fetchPermissionRole(this.props.projects[0].id);
      await this.props.fetchProjectAccess(this.props.projects[0].id);
    }

    if (this.props.previousProject) {
      const selProject = this.props.projects.filter(
        (e) => e.id === this.props.previousProject.id
      )[0];
      this.props.setSelectedProject(selProject);
      await this.props.fetchPermissionRole(selProject.id);
      await this.props.fetchProjectAccess(selProject.id);
    }

    const writeAccess = checkPermission(
      this.props.permissionList,
      'write:settings'
    );

    this.setState({ writeAccess });
    this.setState({ dataLoading: false });
  }

  render() {
    return (
      <HasPermission shortCode={'read:settings'} isSettingsView>
        <div className='team-user-settings'>
          <div className='team-user-settings__info'>
            <h1>User Management</h1>
            <Dialog
              isShown={this.state.isAddShown}
              title='Add New User'
              onCloseComplete={() => this.clearState()}
              hasFooter={false}
            >
              <div className='team-user-form'>
                <div className='team-user-form__item'>
                  <p>Email</p>
                  <TextInput
                    onChange={(e) => this.setState({ email: e.target.value })}
                    value={this.state.email}
                  />
                </div>
                <br />
                <div className='team-user-form__item'>
                  <p>Role</p>
                  <div className='team-user-form__item-submit'>
                    <SelectMenu
                      hasTitle={false}
                      hasFilter={false}
                      title='Select Role'
                      options={this.props.userRolesType.map((role) => ({
                        label: role.name,
                        value: role.id,
                      }))}
                      selected={this.state.selected?.value}
                      onSelect={(item) => this.setState({ selected: item })}
                    >
                      <Button>
                        {this.state.selected?.label || 'Select role...'}
                      </Button>
                    </SelectMenu>
                    <Button
                      className='button-alice__gradient'
                      appearance='primary'
                      isLoading={this.state.loading}
                      onClick={async () => {
                        if (
                          this.state.email.length > 0 &&
                          isValidEmail(this.state.email) &&
                          !!this.state.selected
                        ) {
                          this.setState({ loading: true });
                          await this.props.sendTeamInvitation(
                            this.props.selectedProject.id,
                            this.state.email,
                            this.state.selected.value
                          );
                          this.clearState();
                        } else {
                          toaster.danger('Valid Email and Role are required', {
                            duration: 2,
                          });
                        }
                      }}
                    >
                      Invite Now
                    </Button>
                  </div>
                </div>
              </div>
            </Dialog>
            <Button
              className='button-alice__gradient'
              appearance='primary'
              onClick={() => this.setState({ isAddShown: true })}
            >
              Add User
            </Button>
          </div>
          <div className='team-user-settings__table'>
            {this.state.loading ? (
              <Pane
                display='flex'
                alignItems='center'
                justifyContent='center'
                height={200}
              >
                <Spinner />
              </Pane>
            ) : (
              <Table>
                <Table.Head>
                  <Table.TextHeaderCell>Name</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Email</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Role</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Groups</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Remove</Table.TextHeaderCell>
                </Table.Head>
                <Table.Body>
                  {this.props.projectRolesData.map((user, i) => (
                    <Table.Row key={i} height={60}>
                      <Table.TextCell>
                        <div className='team-user-settings__table-name'>
                          <Avatar
                            src={user.admin.avatar}
                            name={user.admin.full_name}
                            size={32}
                          />
                          {user.admin.full_name}
                        </div>
                      </Table.TextCell>
                      <Table.TextCell>{user.admin.email}</Table.TextCell>
                      <Table.TextCell className='team-user-settings__table-role'>
                        <p>{user.role.name}</p>
                        {this.state.writeAccess &&
                          this.props.userEmail !== user.admin.email && (
                            <SelectMenu
                              hasTitle={true}
                              hasFilter={true}
                              title='Select Role'
                              options={this.props.userRolesType.map((role) => ({
                                label: role.name,
                                value: role.id,
                              }))}
                              selected={this.state.selected?.value}
                              closeOnSelect={true}
                              onSelect={(item) =>
                                this.setState({
                                  userId: user.admin.id,
                                  selected: item,
                                  isUpdateShown: true,
                                })
                              }
                            >
                              <IconButton
                                icon='edit'
                                intent='success'
                                appearance='minimal'
                                height={20}
                              />
                            </SelectMenu>
                          )}
                      </Table.TextCell>
                      <Table.TextCell>
                        <div className='team-user-settings__table-group'>
                          {user.groups.length > 2 ? (
                            <>
                              {user.groups.slice(0, 2).map((group) => (
                                <Avatar
                                  key={group.id}
                                  src={group.image}
                                  name={group.name}
                                  size={32}
                                />
                              ))}
                              <Avatar
                                isSolid
                                color='green'
                                name={`+ ${user.groups.length - 2}`}
                                size={32}
                              />
                            </>
                          ) : (
                            user.groups.map((group) => (
                              <Avatar
                                key={group.id}
                                src={group.image}
                                name={group.name}
                                size={32}
                              />
                            ))
                          )}
                        </div>
                      </Table.TextCell>
                      <Table.TextCell>
                        {
                          <IconButton
                            icon='trash'
                            intent='danger'
                            appearance='minimal'
                            disabled={
                              !this.state.writeAccess ||
                              !user.is_deletable ||
                              this.props.userEmail === user.admin.email
                            }
                            onClick={() =>
                              this.setState({
                                isDeleteShown: true,
                                userId: user.admin.id,
                              })
                            }
                          />
                        }
                      </Table.TextCell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            )}

            <Dialog
              isShown={this.state.isUpdateShown}
              onCloseComplete={() => this.clearState()}
              hasFooter={false}
              hasHeader={false}
            >
              <p className='channel-settings__card-disable-warning'>
                Are you sure want to update role of the user?
              </p>
              <div className='flex justify-center mb-20px'>
                <Button
                  className='button-alice__gradient'
                  onClick={async () => {
                    if (this.state.userId && this.state.selected?.value) {
                      await this.props.updateUserFromTeam(
                        this.props.selectedProject.id,
                        this.state.userId,
                        this.state.selected.value
                      );

                      await this.props.fetchProjectAccess(
                        this.props.selectedProject.id
                      );
                      this.clearState();
                    }
                  }}
                >
                  Yes Do It!
                </Button>
                <Button
                  marginLeft={30}
                  className='btn-outline-danger'
                  onClick={() => this.setState({ isUpdateShown: false })}
                >
                  Cancel
                </Button>
              </div>
            </Dialog>

            <Dialog
              isShown={this.state.isDeleteShown}
              onCloseComplete={() => this.clearState()}
              hasFooter={false}
              hasHeader={false}
            >
              <p className='channel-settings__card-disable-warning'>
                Are you sure want to remove the user?
              </p>
              <div className='flex justify-center mb-20px'>
                <Button
                  className='button-alice__gradient'
                  onClick={async () => {
                    if (this.state.userId) {
                      await this.props.deleteUserFromTeam(
                        this.props.selectedProject.id,
                        this.state.userId
                      );

                      await this.props.fetchProjectAccess(
                        this.props.selectedProject.id
                      );
                      this.clearState();
                    }
                  }}
                >
                  Yes Do It!
                </Button>
                <Button
                  marginLeft={30}
                  className='btn-outline-danger'
                  onClick={() => this.setState({ isDeleteShown: false })}
                >
                  Cancel
                </Button>
              </div>
            </Dialog>
          </div>
        </div>
      </HasPermission>
    );
  }
}

const mapState = (state) => ({
  projects: state.dashboard.projects,
  userEmail: state.auth.email,
  selectedProject: state.settings.selectedProject,
  previousProject: state.dashboard.selectedProject,
  infoLoader: state.loading.effects.dashboard.fetchProject,
  permissionList: state.dashboard.permission.list || [],
  projectRolesData: state.settings.currentProjectRoles,
  userRolesType: state.settings.userRolesType,
});

const mapDispatch = (dispatch) => ({
  fetchProject: () => dispatch.dashboard.fetchProject(),
  fetchProjectAccess: (projectId) =>
    dispatch.settings.fetchProjectAccess(projectId),
  sendTeamInvitation: (teamId, email, roleId) =>
    dispatch.settings.sendTeamInvitation({ teamId, email, roleId }),
  updateUserFromTeam: (teamId, userId, roleId) =>
    dispatch.settings.updateUserFromTeam({ teamId, userId, roleId }),
  deleteUserFromTeam: (teamId, userId) =>
    dispatch.settings.deleteUserFromTeam({ teamId, userId }),
  setSelectedProject: (payload) =>
    dispatch.settings.setSelectedProject(payload),
  fetchPermissionRole: (projectId) =>
    dispatch.settings.fetchPermissionRole(projectId),
});

const TeamUserSettingsContainer = connect(
  mapState,
  mapDispatch
)(TeamUserSettings);

export default TeamUserSettingsContainer;
