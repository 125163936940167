import React, {useEffect} from 'react';
import {Button} from "evergreen-ui";
import {CopyBlock, atomOneDark} from 'react-code-blocks';

interface Prop {
  marketPlaceData: any;
  handlePluginKeyCheck: () => void;
}

const WooCommerceModalPluginKey: React.FC<Prop> = (
  {
    marketPlaceData,
    handlePluginKeyCheck,
  }) => {

  const [timeLeft, setTimeLeft] = React.useState<any>(null);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null)
    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return (
    <>
      <div className='woocommerce-modal__content flex flex-column justify-center'>
        <p className='woocommerce-modal__content-title'>
          Plugin Key
        </p>
        <p className='woocommerce-modal__content-subtitle'>
          Once the plugin is installed, please enter this key to verify the plugin in your website. Click on 'Check' to monitor the verification process.
        </p>
        <br/>
        <CopyBlock
          text={`${marketPlaceData.api_token || 'Not Available'}`}
          language='text'
          theme={atomOneDark}
          codeBlock
        />
      </div>
      {!marketPlaceData.has_plugin_connected && <div className='w-100p flex flex-end mt--70px'>
        <Button
          className='alice-btn__secondary'
          disabled={timeLeft > 0}
          onClick={async () => {
            await handlePluginKeyCheck();
            if (!marketPlaceData.has_plugin_connected) {
              setTimeLeft(5);
            }
          }}>Check {timeLeft > 0 && `(${timeLeft})`}</Button>
      </div>}
    </>
  );
};

export default WooCommerceModalPluginKey;
