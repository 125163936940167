import React from 'react';
import {Spinner} from 'evergreen-ui';
import NoTicketImage from './../assets/images/crmMiddleBarNoData.svg';
import defaultAvatar from './../../../assets/images/defaultAvatar.svg';
import botIcon from './../../../assets/images/robot.svg';

import {
  TextMessage,
  NoteMessage,
  QuickReplyMessage,
  ButtonMessage,
  ImageMessage,
  GalleryMessage,
} from 'inconnect-chat-ui';

import moment from 'moment';

interface props {
  lineCount: number;
  ticketsAvailable: boolean;
  chatData: any;
  loading: boolean;
  isLoading: boolean;
  currentTicket: any;
}

const CRMChat: React.FC<props> = (
  {
    lineCount,
    ticketsAvailable,
    chatData,
    loading,
    isLoading,
    currentTicket,
  }) => {
  let content: any = null;
  let supportsScrollBehavior: any = false;

  const setContentRef = (element: any) => {
    content = element;
  };

  const onNodeInserted = (event: any) => {
    const {currentTarget: target} = event;

    if (supportsScrollBehavior) {
      target.scroll({
        top: target.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
    } else {
      target.scrollTop = target?.scrollHeight;
    }
  };

  const onResize = () => {
    if (content) {
      content.scrollTop = content?.scrollHeight;
    }
  };

  React.useEffect(() => {
    // eslint-disable-next-line
    supportsScrollBehavior = 'scrollBehavior' in document.documentElement.style;
    // eslint-disable-next-line
    if (content) {
      content.addEventListener('DOMNodeInserted', onNodeInserted);
      window.addEventListener('resize', onResize);
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    return () => {
      if (content) {
        content.removeEventListener('DOMNodeInserted', onNodeInserted);
        window.removeEventListener('resize', onResize);
      }
    };
  });

  const SmartChatData = (data: any, i: number) => {
    switch (data.data.type) {
      case 'text':
        let showInfo = true;
        if (i < chatData.length - 1) {
          if (chatData[i].source === chatData[i + 1].source) {
            showInfo = false;
          }
        }

        return (
          <TextMessage
            key={i}
            avatar={data.source === 'customer' ? (currentTicket.customer.avatar || defaultAvatar) : data.source === 'bot' ? botIcon : data?.admin_info?.avatar || defaultAvatar}
            consumer='admin'
            msgTime={moment(data.timestamp).fromNow()}
            showRepliedBy={true}
            repliedBy={data.source !== 'customer' ? data.source === 'bot' ? 'Bot' : data.source === 'admin' && data?.admin_info?.full_name : currentTicket.customer.full_name}
            showInfo={showInfo}
            showMsgStatus={true}
            msgStatus={data.source === 'customer' ? 'sent' : data.success === null ? 'pending' : !!data.success ? 'sent' : 'failed'}
            text={
              data.source === 'customer'
                ? !!data.data.text
                ? data.data.text
                : 'Text Unavailable'
                : !!data.data.data.text
                ? data.data.data.text
                : 'Text Unavailable'
            }
            userType={data.source === 'customer' ? 'user' : 'admin'}
          />
        );
      case 'note':
        return (
          <NoteMessage
            msgTime={moment(data.timestamp).fromNow()}
            key={i}
            note={data.data.data.text}
            showMsgStatus={true}
            msgStatus={data.source === 'customer' ? 'sent' : data.success === null ? 'pending' : !!data.success ? 'sent' : 'failed'}
            takenBy={data.source === 'admin' ? data?.admin_info?.full_name : ''}
          />
        );
      case 'action':
        switch (data.data.data.sub_type) {
          case 'note':
            return (
              <NoteMessage
                msgTime={moment(data.timestamp).fromNow()}
                key={i}
                note={data.data.data.text}
                showMsgStatus={true}
                intent='lime'
                msgStatus={data.source === 'customer' ? 'sent' : data.success === null ? 'pending' : !!data.success ? 'sent' : 'failed'}
                takenBy={
                  data.source === 'admin' ? data?.admin_info?.full_name : data.source === 'bot' ? 'Bot' : ''
                }
              />
            );
          case 'create-ticket':
            return (
              <NoteMessage
                msgTime={moment(data.timestamp).fromNow()}
                key={i}
                note={data.data.data.text}
                showMsgStatus={true}
                intent='lime'
                msgStatus={data.source === 'customer' ? 'sent' : data.success === null ? 'pending' : !!data.success ? 'sent' : 'failed'}
                takenBy={
                  data.source === 'admin' ? data?.admin_info?.full_name : data.source === 'bot' ? 'Bot' : ''
                }
              />
            );
          default:
            return (
              <NoteMessage
                msgTime={moment(data.timestamp).fromNow()}
                key={i}
                note={data.data.data.text}
                showMsgStatus={true}
                intent='lime'
                msgStatus={data.source === 'customer' ? 'sent' : data.success === null ? 'pending' : !!data.success ? 'sent' : 'failed'}
                takenBy={
                  data.source === 'admin' ? data?.admin_info?.full_name : data.source === 'bot' ? 'Bot' : ''
                }
              />
            );
        }
      case 'quick_reply':
        return (
          <QuickReplyMessage
            key={i}
            buttonData={data.data.data.buttons.map((btn: any) => ({
              methodType: btn.type === 'sequence' ? 'function' : 'url',
              title: btn.title,
              isDisabled: true,
            }))}
            avatar={data.source === 'customer' ? (currentTicket.customer.avatar || defaultAvatar) : data.source === 'bot' ? botIcon : data?.admin_info?.avatar || defaultAvatar}
            consumer='admin'
            elementStyle={{
              fontSize: '0.88rem',
            }}
            msgTime={moment(data.timestamp).fromNow()}
            showRepliedBy={true}
            repliedBy={data.source !== 'customer' ? data.source === 'bot' ? 'Bot' : currentTicket.agents.length > 0 && currentTicket.agents[0].full_name : currentTicket.customer.full_name}
            showMsgStatus={true}
            msgStatus={data.source === 'customer' ? 'sent' : data.success === null ? 'pending' : !!data.success ? 'sent' : 'failed'}
            text={data.data.data.text}
          />
        );
      case 'button':
        return (
          <ButtonMessage
            key={i}
            buttonData={data.data.data.buttons.map((btn: any) => ({
              methodType: btn.type === 'sequence' ? 'function' : 'url',
              title: btn.title,
              isDisabled: true,
            }))}
            avatar={data.source === 'customer' ? (currentTicket.customer.avatar || defaultAvatar) : data.source === 'bot' ? botIcon : data?.admin_info?.avatar || defaultAvatar}
            consumer='admin'
            showMsgStatus={true}
            msgStatus={data.source === 'customer' ? 'sent' : data.success === null ? 'pending' : !!data.success ? 'sent' : 'failed'}
            elementStyle={{
              fontSize: '0.88rem',
            }}
            msgTime={moment(data.timestamp).fromNow()}
            showRepliedBy={true}
            repliedBy={data.source !== 'customer' ? data.source === 'bot' ? 'Bot' : currentTicket.agents.length > 0 && currentTicket.agents[0].full_name : currentTicket.customer.full_name}
            text={data.data.data.text}
          />
        );
      case 'attachment':
        const conditionalType =
          data.source === 'customer'
            ? data.data.attachment.type
            : data.data.data.sub_type;
        switch (conditionalType) {
          case 'image':
            return (
              <ImageMessage
                key={i}
                avatar={data.source === 'customer' ? (currentTicket.customer.avatar || defaultAvatar) : data.source === 'bot' ? botIcon : data?.admin_info?.avatar || defaultAvatar}
                consumer='admin'
                showMsgStatus={true}
                msgStatus={data.source === 'customer' ? 'sent' : data.success === null ? 'pending' : !!data.success ? 'sent' : 'failed'}
                images={
                  data.source === 'customer'
                    ? data.data.attachment.urls
                    : data.data.data.urls
                }
                msgTime={moment(data.timestamp).fromNow()}
                showPreview
                showRepliedBy={true}
                repliedBy={data.source !== 'customer' ? data.source === 'bot' ? 'Bot' : currentTicket.agents.length > 0 && currentTicket.agents[0].full_name : currentTicket.customer.full_name}
                text={data.data?.tex || ''}
                elementStyle={{fontSize: '0.88rem'}}
                userType={data.source === 'customer' ? 'user' : data.source}
              />
            );
          case 'audio':
            return (
              <div key={i} className='audio-player__crm'>
                {console.log(data?.data?.attachment?.urls[0])}
                {data?.data?.attachment?.urls[0] ?
                  <audio src={data?.data?.attachment?.urls[0]} controls>
                    <p>Your browser does not support HTML5 audio, but you can still
                      <a href={data?.data?.attachment?.urls[0]}>download the music</a>.
                    </p>
                  </audio>
                  :
                  <TextMessage
                    key={i}
                    avatar={data.source === 'customer' ? (currentTicket.customer.avatar || defaultAvatar) : data.source === 'bot' ? botIcon : data?.admin_info?.avatar || defaultAvatar}
                    consumer='admin'
                    msgTime={moment(data.timestamp).fromNow()}
                    showRepliedBy={true}
                    repliedBy={data.source !== 'customer' ? data.source === 'bot' ? 'Bot' : data.source === 'admin' && data?.admin_info?.full_name : currentTicket.customer.full_name}
                    showInfo={false}
                    showMsgStatus={true}
                    msgStatus={data.source === 'customer' ? 'sent' : data.success === null ? 'pending' : !!data.success ? 'sent' : 'failed'}
                    text={`${conditionalType.toUpperCase()} Url Is Not Valid`}
                    userType={data.source === 'customer' ? 'user' : 'admin'}
                  />
                }
              </div>
            );
          default:
            return <TextMessage
              key={i}
              avatar={data.source === 'customer' ? (currentTicket.customer.avatar || defaultAvatar) : data.source === 'bot' ? botIcon : data?.admin_info?.avatar || defaultAvatar}
              consumer='admin'
              msgTime={moment(data.timestamp).fromNow()}
              showRepliedBy={true}
              repliedBy={data.source !== 'customer' ? data.source === 'bot' ? 'Bot' : data.source === 'admin' && data?.admin_info?.full_name : currentTicket.customer.full_name}
              showInfo={false}
              showMsgStatus={true}
              msgStatus={data.source === 'customer' ? 'sent' : data.success === null ? 'pending' : !!data.success ? 'sent' : 'failed'}
              text={`${conditionalType.toUpperCase()} Not Implemented`}
              userType={data.source === 'customer' ? 'user' : 'admin'}
            />
        }
      case 'gallery':
        return (
          <GalleryMessage
            key={i}
            showMsgStatus={true}
            avatar={data.source === 'customer' ? (currentTicket.customer.avatar || defaultAvatar) : data.source === 'bot' ? botIcon : data?.admin_info?.avatar || defaultAvatar}
            msgStatus={data.source === 'customer' ? 'sent' : data.success === null ? 'pending' : !!data.success ? 'sent' : 'failed'}
            elementStyle={
              data.source === 'user'
                ? {fontSize: '0.88rem', background: '#e5e9ee'}
                : {fontSize: '0.88rem', background: '#184D47', color: '#fff'}
            }
            carouselHeight='425px'
            carouselWidth='525px'
            cellSpacing={25}
            galleryItemClassName='gallery-item-style-fix'
            consumer={data.source === 'user' ? data.source : 'admin'}
            galleryData={data.data.data.elements.map((galleryItem: any) => {
              return {
                id: galleryItem.id,
                image: galleryItem.image,
                subtitle: galleryItem.subtitle,
                title: galleryItem.title,
                buttons: galleryItem.buttons.map((btn: any) => ({
                  methodType: btn.type === 'sequence' ? 'function' : 'url',
                  title: btn.title,
                  isDisabled: true,
                })),
              };
            })}
            hasTitle={false}
            msgTime={moment(data.timestamp).fromNow()}
            showRepliedBy={true}
            repliedBy={data.source !== 'customer' ? data.source === 'bot' ? 'Bot' : currentTicket.agents.length > 0 && currentTicket.agents[0].full_name : currentTicket.customer.full_name}
            slideToShow={2}
          />
        );
      case 'input' :
        return (
          <TextMessage
            key={i}
            showMsgStatus={true}
            msgStatus={data.source === 'customer' ? 'sent' : data.success === null ? 'pending' : !!data.success ? 'sent' : 'failed'}
            consumer='admin'
            msgTime={moment(data.timestamp).fromNow()}
            avatar={data.source === 'customer' ? (currentTicket.customer.avatar || defaultAvatar) : data.source === 'bot' ? botIcon : data?.admin_info?.avatar || defaultAvatar}
            showRepliedBy={true}
            repliedBy={data.source !== 'customer' ? data.source === 'bot' ? 'Bot' : currentTicket.agents.length > 0 && currentTicket.agents[0].full_name : currentTicket.customer.full_name}
            showInfo={false}
            text={data?.data?.data?.text || `${data.data.type} Was Sent`}
            userType={data.source === 'customer' ? 'user' : 'admin'}
          />
        );
      default:
        return (
          <TextMessage
            key={i}
            showMsgStatus={true}
            msgStatus={data.source === 'customer' ? 'sent' : data.success === null ? 'pending' : !!data.success ? 'sent' : 'failed'}
            consumer='admin'
            msgTime={moment(data.timestamp).fromNow()}
            avatar={data.source === 'customer' ? (currentTicket.customer.avatar || defaultAvatar) : data.source === 'bot' ? botIcon : data?.admin_info?.avatar || defaultAvatar}
            showRepliedBy={true}
            repliedBy={data.source !== 'customer' ? data.source === 'bot' ? 'Bot' : currentTicket.agents.length > 0 && currentTicket.agents[0].full_name : currentTicket.customer.full_name}
            showInfo={false}
            text={`${data.data.type} Was Sent`}
            userType={data.source === 'customer' ? 'user' : 'admin'}
          />
        );
    }
  };
  return (
    <div
      className={
        chatData?.platform_info?.type === 'facebook_feed'
          ? 'feed-container  customer-chat__container'
          : 'customer-chat__container'
      }
      ref={setContentRef}
      style={{
        height: `calc( 100vh - ${210 + (lineCount - 1) * 16}px )`,
      }}
    >
      {!ticketsAvailable && !loading && !isLoading && (
        <div className='flex align-center justify-center h-100p flex-column'>
          <img src={NoTicketImage} alt={'no data'} width={300}/>
          <p className='grey-text'>Your Inbox is Empty </p>
          <p className='grey-text'>Your Bots are doing a great job</p>
        </div>
      )}

      {(loading || isLoading) && (
        <div className='flex justify-center align-center h-100p'>
          <Spinner/>
        </div>
      )}
      {!loading && (!chatData || chatData.length === 0) && ticketsAvailable && !isLoading && (
        <div className='flex align-center justify-center h-100p flex-column'>
          <img src={NoTicketImage} alt={'no data'} width={300}/>

          <p className='grey-text'>Kinda Empty Here. Did you connect your channels?</p>
        </div>
      )}
      {!loading &&
      !!chatData &&
      ticketsAvailable &&
      chatData?.platform_info?.type !== 'facebook_feed' && (
        <>
          {chatData.map((elem: any, i: number) =>
            SmartChatData(elem, i)
          )}
        </>
      )}
    </div>
  );
};

export default CRMChat;
