import React from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Tooltip, Cell } from 'recharts';
import { Icon, Tooltip as ToolTipEvergreen, Spinner } from 'evergreen-ui';
import { getSum } from './../../../utils';

const COLORS = ['#00C49F', '#FFBB28', '#0088FE', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill='white'
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline='central'
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const ReportingPieGraph = ({ data, color, title, toolTipInfo, loader }) => {
  return (
    <div className='reporting-container__user-card-container-elem'>
      <div className='reporting-container__user-card-container-elem-data'>
        <div className='reporting-title-container'>
          <span>{title}&nbsp;</span>
          <ToolTipEvergreen content={toolTipInfo}>
            <Icon color='success' icon={'info-sign'} size={16} />
          </ToolTipEvergreen>
        </div>
        {!loader && (
          <div className='flex align-center space-evenly'>
            {getSum(data, 'value') > 0 ? (
              <PieChart width={280} height={280}>
                <Pie
                  data={data}
                  cx={140}
                  cy={140}
                  labelLine={false}
                  label={!!data && renderCustomizedLabel}
                  outerRadius={120}
                  fill={color}
                  dataKey='value'
                >
                  {!!data &&
                    data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                </Pie>
                <Tooltip />
              </PieChart>
            ) : (
              <div className='flex align-center justify-center h-300px'>
                Not Available
              </div>
            )}
            <div>
              {!!data &&
                data.map((entry, index) => (
                  <div className='flex align-center' key={`cell-${index}`}>
                    <div
                      style={{
                        background: COLORS[index % COLORS.length],
                        height: 16,
                        width: 16,
                        borderRadius: 50,
                      }}
                    />{' '}
                    &nbsp; {entry.name}{' '}
                  </div>
                ))}
            </div>
          </div>
        )}
        {loader && (
          <div className='flex align-center justify-center h-200px'>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

ReportingPieGraph.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};

ReportingPieGraph.defaultProps = {
  data: [],
};

export default ReportingPieGraph;
