import React, { Component, Fragment } from 'react';
import './assets/styles/style.scss';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  Spinner,
  toaster,
  Popover,
  Pane,
  Pill,
} from 'evergreen-ui';
import BaseTable, { Column } from 'react-base-table';
import 'react-base-table/styles.css';
import SmartInput from './components/SmartInput';
import CellAction from './components/CellAction';
import CellDetailsView from './components/CellDetailsView';
import Pagination from '../../components/Pagination';
import {
  checkPermission,
  validateInputFields,
  checkInputLength,
} from '../../utils';
import DataLabExport from './components/DatalabExport';
import DataLabImport from './components/DataLabImport';
import AdvanceFilter from './components/AdvanceFilter';
import moment from 'moment';
import ProUser from '../../components/ProUser';
import HasPermission from '../../components/HasPermission';
import { DateRangePicker } from 'react-date-range';
import { subDays } from 'date-fns';

class DataLabDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInsertShown: false,
      isEditShown: false,
      isDetailsShown: false,
      editingID: '',
      viewerID: null,
      loading: true,
      offset: 0,
      limit: 15,
      currentPage: 1,
      inputs: {},
      copyStatus: false,
      filterConditions: [],
      showProModal: false,
      readAccess: 'read:data_lab',
      writeAccess: 'write:data_lab',
      width: window.innerWidth - 220,
      dateRange: [
        {
          startDate: subDays(new Date(), 30),
          endDate: new Date(),
          key: 'selection',
        },
      ],
      finalDateRange: [
        {
          startDate: subDays(new Date(), 30),
          endDate: new Date(),
          key: 'selection',
        },
      ],
    };

    this.colWidth = 400;
    this.rowEventHandlers = {
      onDoubleClick: (e) => {
        this.setState({ viewerID: e.rowData.id, isDetailsShown: true });
      },
    };
  }

  treeShakeFilterData = () =>
    new Promise((resolve, reject) => {
      try {
        let filterConditionsLocal = this.state.filterConditions.filter(
          (condition) => !!condition.value
        );
        this.setState(
          {
            filterConditions: filterConditionsLocal,
          },
          () => {
            resolve();
          }
        );
      } catch (e) {
        reject();
      }
    });

  resetFilter = () => {
    this.setState(
      {
        filterConditions: [],
        offset: 0,
        currentPage: 1,
      },
      () => {
        this.fetchData();
      }
    );
  };

  fetchData = async (isAdvanceFiltered = false) => {
    this.setState({
      loading: true,
    });
    await this.treeShakeFilterData();
    let simplifiedFilterCondition = '';

    const filterConditionsLocal = [...this.state.filterConditions];

    filterConditionsLocal.forEach((condition, i) => {
      if (i === 0) {
        simplifiedFilterCondition = `${simplifiedFilterCondition}&operation=contains`;
      }
      switch (condition.type) {
        case 'text':
        case 'textarea':
        case 'number':
        case 'time':
        case 'singleselect':
          simplifiedFilterCondition = `${simplifiedFilterCondition}&${condition.name}=${condition.value}`;
          return;
        case 'date':
        case 'datetime':
          simplifiedFilterCondition = `${simplifiedFilterCondition}&${
            condition.name
          }=${moment(condition.value).unix()}`;
          return;
        case 'multiselect':
          if (!!condition.value && condition.value.length > 0) {
            condition.value.forEach((answer) => {
              simplifiedFilterCondition = `${simplifiedFilterCondition}&${condition.name}=${answer}`;
            });
          }
          return;

        default:
          return;
      }
    });

    if (!!this.props.data.dataLab && this.props.dataLab.lab_fields.length > 0) {
      this.colWidth =
        (window.innerWidth - 200) / this.props.dataLab.lab_fields.length;
    }
    if (isAdvanceFiltered) {
      this.setState(
        {
          offset: 0,
          currentPage: 1,
        },
        async () => {
          await this.props.fetchData(
            this.props.labId,
            this.state.limit,
            this.state.offset,
            simplifiedFilterCondition,
            {
              startDate: moment(this.state.finalDateRange[0].startDate).format(
                'YYYY-MM-DD'
              ),
              endDate: moment(this.state.finalDateRange[0].endDate).format(
                'YYYY-MM-DD'
              ),
            },
            isAdvanceFiltered
          );
        }
      );
    } else {
      await this.props.fetchData(
        this.props.labId,
        this.state.limit,
        this.state.offset,
        simplifiedFilterCondition,
        {
          startDate: moment(this.state.finalDateRange[0].startDate).format(
            'YYYY-MM-DD'
          ),
          endDate: moment(this.state.finalDateRange[0].endDate).format(
            'YYYY-MM-DD'
          ),
        },
        isAdvanceFiltered
      );
    }

    this.setState({
      loading: false,
    });
  };

  handlePageChange = (currentPage) => {
    this.setState(
      {
        currentPage: currentPage,
        offset: (currentPage - 1) * this.state.limit,
        loading: true,
      },
      async () => {
        // await this.props.fetchData(
        //   this.props.labId,
        //   this.state.limit,
        //   this.state.offset,
        //
        // );
        this.fetchData();
      }
    );
  };

  renderOverlay = () => {
    const { loading } = this.state;
    if (loading)
      return (
        <div className='data-lab-details__overlay-container'>
          <Spinner />
        </div>
      );

    return null;
  };
  renderEmpty = () => {
    if (this.state.loading) return null;
    return (
      <div className='data-lab-details__overlay-container'>
        No data available
      </div>
    );
  };

  handleFilterConditionAdd = () => {
    this.setState({
      filterConditions: [
        ...this.state.filterConditions,
        { name: '', value: '', type: '', options: [] },
      ],
    });
  };
  handleConditionDelete = (index) => {
    const { filterConditions } = this.state;

    let updatedCondition = [];

    if (filterConditions.length > 1) {
      updatedCondition = [
        ...filterConditions.slice(0, index),
        ...filterConditions.slice(index + 1),
      ];
    }
    this.setState({
      filterConditions: updatedCondition,
    });
  };

  handleConditionFieldUpdate = (item, key) => {
    const localFilteredCondition = [...this.state.filterConditions];

    if (key === 'name') {
      localFilteredCondition[item.index].name = item.value;
      localFilteredCondition[item.index].type = item.type;
      localFilteredCondition[item.index].options = item.options;
      localFilteredCondition[item.index].label = item.label;
    } else {
      localFilteredCondition[item.index].value = item.value;
    }
    this.setState({
      filterConditions: localFilteredCondition,
    });
  };

  async componentDidMount() {
    const hasPermission = checkPermission(
      this.props.permissionList,
      this.state.readAccess
    );

    if (hasPermission) {
      await this.props.fetchDataLabKeyInfo(
        this.props.projectId,
        this.props.labId
      );
      await this.fetchData();
      this.setState({
        loading: false,
      });
    }
  }

  handleInputChange = (id, val) => {
    this.setState({ inputs: { ...this.state.inputs, [id]: val } });
  };

  handleEntryCreateSubmit = async () => {
    if (validateInputFields(this.state.inputs, this.props.dataLab.lab_fields)) {
      this.setState(
        {
          offset: 0,
          currentPage: 1,
        },
        async () => {
          await this.fetchData();
        }
      );
      await this.props.createEntry(this.props.labId, this.state.inputs);
      return true;
    }
    return false;
  };

  handleEntryUpdate = async (entryId) => {
    const data = this.props.data.filter((d) => d.id === entryId)[0];
    this.setState({
      isEditShown: true,
      editingID: entryId,
      inputs: { ...data },
    });
  };

  handleEntryUpdateSubmit = async () => {
    if (validateInputFields(this.state.inputs, this.props.dataLab.lab_fields)) {
      await this.props.editEntry(
        this.props.labId,
        this.state.editingID,
        this.state.inputs
      );
      return true;
    }
    return false;
  };

  handleEntryDelete = async (entryId) => {
    await this.props.deleteEntry(this.props.labId, entryId);
  };

  handleProModalCancel = () => {
    this.setState({ showProModal: false });
  };

  handleInterval = () => {
    this.setState(
      {
        finalDateRange: this.state.dateRange,
      },
      () => {
        this.fetchData();
      }
    );
  };

  render() {
    const height = window.innerHeight - 150;
    let { dataLab, data } = this.props;
    const hasWriteAccess = checkPermission(
      this.props.permissionList,
      this.state.writeAccess
    );

    if (hasWriteAccess) {
      data = data.map((elem) => ({
        ...elem,
        action: (
          <CellAction
            handleEdit={() => this.handleEntryUpdate(elem.id)}
            handleDelete={() => this.handleEntryDelete(elem.id)}
          />
        ),
        date: moment(elem.mongo_timestamp * 1000).format('lll'),
      }));
    }

    return (
      <HasPermission shortCode={this.state.readAccess}>
        <Fragment>
          <div className='data-lab-details__container'>
            <div className='data-lab-details__header'>
              {hasWriteAccess && (
                <Button
                  onClick={() => this.setState({ isInsertShown: true })}
                  className='button-alice__gradient'
                  height={36}
                  marginRight={10}
                  iconBefore={'add'}
                >
                  Insert New Data
                </Button>
              )}
              <div className='flex align-center'>
                {hasWriteAccess && (
                  <Dialog
                    isShown={this.state.isInsertShown || this.state.isEditShown}
                    title={
                      this.state.isEditShown ? 'Update Data' : 'Insert New Data'
                    }
                    onCloseComplete={() => {
                      this.setState({
                        isInsertShown: false,
                        isEditShown: false,
                        editingID: '',
                        inputs: {},
                      });
                    }}
                    onConfirm={async (close) => {
                      let success;
                      let valid = checkInputLength(
                        dataLab.lab_fields,
                        this.state.inputs
                      );
                      if (
                        this.state.isEditShown &&
                        this.state.editingID !== ''
                      ) {
                        if (valid) {
                          success = await this.handleEntryUpdateSubmit();
                        }
                      }
                      if (this.state.isInsertShown) {
                        if (valid) {
                          success = await this.handleEntryCreateSubmit();
                        }
                      }
                      if (success) {
                        close();
                      } else {
                        toaster.danger('Please fill all required fields');
                      }
                    }}
                    confirmLabel={this.state.isEditShown ? 'Update' : 'Insert'}
                    intent='success'
                  >
                    {!!dataLab &&
                      dataLab.lab_fields.map((item, i) => (
                        <SmartInput
                          key={i}
                          {...item}
                          value={this.state.inputs[item.id]}
                          handleChange={(val) =>
                            this.handleInputChange(item.id, val)
                          }
                        />
                      ))}
                  </Dialog>
                )}

                {this.state.showProModal && (
                  <ProUser handleCancel={() => this.handleProModalCancel()} />
                )}
                <DataLabImport
                  title={'Import Data Lab'}
                  loader={this.props.importLoader}
                  handleImport={this.props.importDataLab}
                  labId={this.props.labId}
                  isProUser={this.props.isPro}
                  setShowProModal={() =>
                    this.setState({ showProModal: !this.state.showProModal })
                  }
                  fetchData={this.fetchData}
                />
                <DataLabExport
                  loader={this.props.loader}
                  title='Export Data Lab'
                  handleExport={async () => {
                    await this.treeShakeFilterData();
                    let simplifiedFilterCondition = '';

                    const filterConditionsLocal = [
                      ...this.state.filterConditions,
                    ];

                    filterConditionsLocal.forEach((condition, i) => {
                      if (i === 0) {
                        simplifiedFilterCondition = `${simplifiedFilterCondition}&operation=contains`;
                      }
                      switch (condition.type) {
                        case 'text':
                        case 'textarea':
                        case 'number':
                        case 'time':
                        case 'singleselect':
                          simplifiedFilterCondition = `${simplifiedFilterCondition}&${condition.name}=${condition.value}`;
                          return;
                        case 'date':
                        case 'datetime':
                          simplifiedFilterCondition = `${simplifiedFilterCondition}&${
                            condition.name
                          }=${moment(condition.value).unix()}`;
                          return;
                        case 'multiselect':
                          if (!!condition.value && condition.value.length > 0) {
                            condition.value.forEach((answer) => {
                              simplifiedFilterCondition = `${simplifiedFilterCondition}&${condition.name}=${answer}`;
                            });
                          }
                          return;

                        default:
                          return;
                      }
                    });
                    if (this.props.isPro) {
                      this.props.exportDataLab(
                        this.props.labId,
                        moment(this.state.finalDateRange[0].startDate).format(
                          'YYYY-MM-DD'
                        ),
                        moment(this.state.finalDateRange[0].endDate).format(
                          'YYYY-MM-DD'
                        ),
                        simplifiedFilterCondition
                      );
                    } else {
                      this.setState({ showProModal: true });
                    }
                  }}
                  isProUser={this.props.isPro}
                />

                <AdvanceFilter
                  title='Filter Options'
                  conditions={this.state.filterConditions}
                  handleAdd={this.handleFilterConditionAdd}
                  handleDelete={this.handleConditionDelete}
                  resetFilter={() => this.resetFilter()}
                  handleConditionFieldUpdate={this.handleConditionFieldUpdate}
                  fetchData={this.fetchData}
                  labFields={
                    !!dataLab
                      ? dataLab.lab_fields
                          .map((field) => ({
                            label: field.name + '',
                            value: field.id + '',
                            type: field.type,
                            options: field.options,
                          }))
                          .filter(
                            (field) =>
                              field.type !== 'image' &&
                              field.type !== 'date' &&
                              field.type !== 'datetime' &&
                              field.type !== 'time'
                          )
                      : []
                  }
                />
                <Popover
                  content={({ close }) => (
                    <Pane
                      display='flex'
                      alignItems='flex-start'
                      flexDirection='column'
                    >
                      <DateRangePicker
                        onChange={(item) =>
                          this.setState({ dateRange: [item.selection] })
                        }
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        rangeColors={['#007b65', '#005762']}
                        months={1}
                        ranges={this.state.dateRange}
                        direction='horizontal'
                      />
                      <br />
                      <div className='flex w-100p mb-20px justify-center align-center'>
                        <Button
                          className='button-alice__gradient'
                          marginRight={10}
                          iconBefore={'search'}
                          onClick={() => {
                            this.handleInterval();
                            close();
                          }}
                        >
                          Filter
                        </Button>
                        &nbsp; &nbsp;
                        <Button
                          className='button-alice__secondary_2'
                          onClick={() => close()}
                          iconBefore={'cross'}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Pane>
                  )}
                >
                  <Button
                    className='button-alice__white'
                    iconBefore='filter-list'
                    marginRight={10}
                  >
                    {' '}
                    {moment(this.state.finalDateRange[0].startDate).format(
                      'YYYY-MM-DD'
                    )}{' '}
                    -{' '}
                    {moment(this.state.finalDateRange[0].endDate).format(
                      'YYYY-MM-DD'
                    )}
                  </Button>
                </Popover>
              </div>
            </div>
            <div className='data-lab-details__body' id='container-table'>
              <BaseTable
                data={data}
                width={
                  this.props.navIsOpen
                    ? this.state.width
                    : this.state.width + 27
                } //5rem  vs 16rem 80-206 = 126px
                height={height}
                disabled={this.state.loading}
                overlayRenderer={this.renderOverlay}
                emptyRenderer={this.renderEmpty}
                rowEventHandlers={this.rowEventHandlers}
                fixed
                className='data-lab-details__table'
                headerClassName='data-lab-details__table-header'
                rowClassName='data-lab-details__table-row'
              >
                <Column
                  key={'date'}
                  resizable
                  dataKey='date'
                  title='ENTRY DATE'
                  width={160}
                />
                {!!dataLab &&
                  dataLab.lab_fields.map((col, i) => (
                    <Column
                      key={i}
                      resizable
                      dataKey={col.id.toString()}
                      title={col.name.toUpperCase()}
                      width={this.colWidth > 400 ? this.colWidth : 300}
                    />
                  ))}
                {hasWriteAccess && (
                  <Column
                    key={'action'}
                    dataKey='action'
                    title='ACTION'
                    width={120}
                    frozen='right'
                  />
                )}
              </BaseTable>
              <div className='data-lab-details__pagination'>
                <div className='mt-5px'>
                  Showing{' '}
                  <Pill display='inline-flex' margin={8}>
                    {data.length}
                  </Pill>{' '}
                  of{' '}
                  <Pill display='inline-flex' isSolid={true} margin={8}>
                    {this.props.total || 0}
                  </Pill>{' '}
                  Entries
                </div>
                {this.props.total > this.state.limit && (
                  <Pagination
                    currentPage={this.state.currentPage}
                    totalPages={Math.ceil(this.props.total / this.state.limit)}
                    changeCurrentPage={this.handlePageChange}
                  />
                )}
              </div>
            </div>
            <Dialog
              isShown={this.state.isDetailsShown}
              title='Details'
              hasFooter={false}
              onCloseComplete={() => this.setState({ isDetailsShown: false })}
            >
              <CellDetailsView
                data={this.props.data.filter(
                  (e) => e.id === this.state.viewerID
                )}
                fields={
                  !!this.props.dataLab ? this.props.dataLab.lab_fields : []
                }
              />
            </Dialog>
          </div>
        </Fragment>
      </HasPermission>
    );
  }
}

const mapState = (state) => ({
  isPro: state.dashboard.selectedProject.is_pro,
  dataLab: state.dataLab.currentEditingLabData,
  data: state.labDetails.data,
  total: state.labDetails.total,
  loader: state.loading.effects.labDetails.exportDataLab,
  permissionList: state.dashboard.permission?.list || [],
  importLoader: state.loading.effects.labDetails.importDataLab,
  navIsOpen: state.dashboard.navIsOpen,
});

const mapDispatch = (dispatch) => ({
  fetchDataLabKeyInfo: (projectId, labId) =>
    dispatch.dataLab.fetchDataLabKeyInfo({ projectId, labId }),
  fetchData: (labId, limit, offset, conditions, dateRange, filter) =>
    dispatch.labDetails.fetchData({
      labId,
      limit,
      offset,
      conditions,
      dateRange,
      filter,
    }),
  createEntry: (labId, data) =>
    dispatch.labDetails.createEntry({ labId, data }),
  editEntry: (labId, entryId, data) =>
    dispatch.labDetails.editEntry({ labId, entryId, data }),
  deleteEntry: (labId, entryId) =>
    dispatch.labDetails.deleteEntry({ labId, entryId }),
  importDataLab: (labId, file) =>
    dispatch.labDetails.importDataLab({ labId, file }),
  exportDataLab: (labId, startDate, endDate, conditions) =>
    dispatch.labDetails.exportDataLab({
      labId,
      startDate,
      endDate,
      conditions,
    }),
});

const DataLabDetailsContainer = connect(mapState, mapDispatch)(DataLabDetails);

export default DataLabDetailsContainer;
