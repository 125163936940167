import React from 'react';
import RibbonIcon from './assets/images/ribbon.svg';
import GearIcon from './assets/images/gear.svg';
import ToolboxIcon from './assets/images/toolbox.svg';
import SilhouetteIcon from './assets/images/silhouette.svg';

export const authData = {
  login: {
    title: 'Log In to Your Account',
    subtitle: 'Provide your registered email and password',
    loginButtonTitle: 'Login',
    loginButtonIcon: 'key',
    hook: {
      title: 'What’s new?',
      description: (
        <ul>
          <li style={{ margin: 10 }}>
            Build chatbot for free (no credit card required)
          </li>
          <li style={{ margin: 10 }}>Connect to Messenger and Viber</li>
          <li style={{ margin: 10 }}>Connect to Wit.ai for NLP</li>
        </ul>
      ),
      button: {
        title: 'Know More',
        link: 'https://www.getalice.ai/',
      },
    },
  },
  register: {
    title: 'Create a New Account',
    subtitle: 'Provide your valid email address',
    registerButtonTitle: 'Sign Up',
    resendButtonTitle: 'Resend Link',
    newsletterText: 'Subscribe to news and updates from Alice Labs',
    // registerButtonIcon: 'user',
    hook: {
      title: 'Why Alice Labs?',
      description: (
        <ul>
          <li style={{ margin: 10 }}>Create a chatbot for free!</li>
          <li style={{ margin: 10 }}>Connect it to any messaging channel!</li>
          <li style={{ margin: 10 }}>
            Power your chatbot with Artificial Intelligence!
          </li>
        </ul>
      ),
      button: {
        title: 'Know More',
        link: 'https://www.getalice.ai/',
      },
    },
  },
  verify: {
    title: 'Add Your New Password',
    subtitle: 'Previously used passwords are not recommended',
    forgetButtonTitle: 'Verify Now',
    forgetButtonIcon: 'endorsed',
    hook: {
      title: 'You are Almost There',
      description:
        'Provide the code sent to your mail and update your password !! Dead Simple',
      button: {
        title: 'Sign In',
        link: '/',
      },
    },
  },
  resendRegisterLink: {
    timeoutTitle: 'Verification Timed Out',
    timeoutSubTitle:
      'The verification link we sent to you has been expired. Click below to resend.',
  },
  verifyRegister: {
    title: 'Verify Your Account',
    submitButtonTitle: 'Verify Now',
    submitButtonIcon: 'endorsed',
    alreadyVerifiedTitle: 'Already Verified!',
    alreadyVerifiedSubTitle:
      'Your email is already verified! Please click below to redirect to dashboard.',
    alreadyVerifiedButtonTitle: 'Go to Dashboard',
    hook: {
      title: 'You are Almost There',
      description:
        'Provide the code sent to your mail and verify your account !! Dead Simple',
      button: {
        title: 'Sign In',
        link: '/',
      },
    },
  },
  forget: {
    title: 'Forgot your Password?',
    subtitle:
      'Please provide the email address you registered with. We will send you a verification code.',
    forgetButtonTitle: 'Send Code',
    savePassword: 'Save Password',
    // forgetButtonIcon: 'tick-circle',
    hook: {
      title: 'Forgot Password?',
      description:
        'It can happen. Relax! Just send us the mail and we will send you further instructions over there.',
      button: {
        title: 'Contact Us',
        link: 'https://getalice.ai/contact',
      },
    },
  },
  forgetInfo: {
    text: '',
    linkTitle: 'Forgot Password?',
    link: '/reset',
  },
  contactInfo: {
    text: 'Facing an issue?',
    linkTitle: 'Contact us',
    link: 'https://airtable.com/shrvMCwEUGQU7TvRR',
  },
  registerInfo: {
    text: 'Don’t have an account?',
    linkTitle: 'Sign Up',
    link: '/register',
  },
  loginInfo: {
    text: 'Already Have an Account?',
    linkTitle: 'Sign In',
    link: '/',
  },
  signupConfirmationInfo: {
    text: 'By signing up you confirm that, you have read and agree with our',
    linkTitle: 'Terms & Conditions',
    link: 'https://getalice.ai/terms',
  },
};
export const footerData = {
  text: 'All rights reserved.',
  company: 'Alice Labs Pte. Ltd.',
  tos: {
    title: 'Terms of Services',
    link: 'https://getalice.ai/terms',
  },
  privacy: {
    title: 'Privacy Policy',
    link: 'https://getalice.ai/privacy',
  },
};

export const proFeatures = [
  {
    icon: RibbonIcon,
    text: 'Expand Monthly User Limit to 500',
  },
  {
    icon: SilhouetteIcon,
    text: 'Export your Data from Audience',
  },
  {
    icon: ToolboxIcon,
    text: 'Export or Import your data in Datalab',
  },
  {
    icon: GearIcon,
    text:
      'Integrate your API to provide users with information stored on your server!',
  },
];

export const pricingPlans = [
  {
    name: 'Free',
    price: 0,
    features: [
      'Upto 1000 Subscribers',
      'Connection to Datalab',
      'Connection to facebook feed and messages',
      'Connection to Viber',
      'Visual flow builder',
      'Connection to NLP',
      'Alice Branding',
    ],
  },
  {
    name: 'Pro',
    price: 10,
    features: [
      'Unlimited Subscribers *',
      'All features in Free package',
      'No Alice Branding',
      'API connection to any system',
      'Team management',
      'Export audience for ads',
    ],
  },
  {
    name: 'Enterprise',
    price: -1,
    features: [
      'Unlimited Subscribers',
      'All features in Pro',
      '24*7 Dedicated Support Team',
      'Marketing support to build conversation flow',
      'API middleware development for any internal system integration',
      'On premise deployment of API server',
      'NLP training team',
    ],
  },
];
