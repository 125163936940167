import React from 'react';
import webChatImage from './../../../assets/icons/marketplace/webChatImage.svg';
import {Button} from "evergreen-ui";

interface Prop {
  handleClose: () => void;
  handleConfirm: () => void;
}

const WooCommerceModalPlugin: React.FC<Prop> = ({handleClose,handleConfirm}) => {

  return (
    <>
      <div className='woocommerce-modal__content flex flex-column justify-center'>
        <p className='woocommerce-modal__content-title'>
          Do you want to create a webchat plugin on your site now?
        </p>
        <img src={webChatImage} style={{margin: '0 auto'}} alt='web-chat' width={230}/>
      </div>

      <div className='w-100p flex flex-end mt--40px'>
        <Button className='alice-btn__default' marginRight={10} onClick={()=> handleClose()}>Do It Later</Button>
        <Button className='alice-btn__secondary' onClick={()=>handleConfirm()}>Yes,Please</Button>
      </div>
      </>
  );
};

export default WooCommerceModalPlugin;
