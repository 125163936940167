import React from 'react';
import { IconButton, Label, Switch, Textarea } from 'evergreen-ui';
import InputLabel from './InputLabel';
import DropdownCombobox from './DropdownCombobox';
import InputVariableField from '../elements/InputVariableField';

interface props {
  onDelete: (question: any) => void;
  question: any;
  hasWriteAccess: boolean;
  attributeList: any;
  handleStorySegmentEdit: (
    storyId: number,
    dataLocal: any,
    key: string
  ) => void;
  length: number;
  entities: any[];
}

const StoryBlockQuestionSegment: React.FC<props> = ({
  question,
  onDelete,
  hasWriteAccess,
  attributeList,
  handleStorySegmentEdit,
  length,
  entities,
}) => {
  const [showAttributeField, setShowAttributeField] = React.useState(false);

  const currentEntity: any = entities.filter(
    (entity: any) => entity.name === question.primary_entity
  )[0];
  return (
    <div className='story-block-question__container'>
      <div className='flex space-between align-center mb-10px'>
        <Label>New Story Element</Label>
        {length > 1 && (
          <IconButton
            onClick={() => onDelete(question)}
            icon='cross'
            appearance='minimal'
          />
        )}
      </div>
      {!question.is_optional && (
        <>
          <Textarea
            className='custom-input-style'
            placeholder='Write your text here...'
            value={question?.main_text}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleStorySegmentEdit(question.id, e.target.value, 'main_text')
            }
          />
          <br />
        </>
      )}
      <br />
      <div className='flex align-center '>
        <Switch
          className='switch-rd'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleStorySegmentEdit(question.id, e.target.checked, 'is_optional')
          }
          checked={question.is_optional}
          hasCheckIcon={false}
        />
        <Label className='ml-10px'>Optional</Label>
      </div>
      {!question.is_optional && (
        <>
          <br />
          <InputLabel
            value={question?.fail_text}
            handleChange={(value) =>
              handleStorySegmentEdit(question.id, value, 'fail_text')
            }
            title={'Failed Text'}
            tooltip='Type here...'
          />
          <br />
        </>
      )}
      <br />
      <div className='flex'>
        <div className='flex-1 mr-5px'>
          <DropdownCombobox
            title='Entity'
            tooltip={'tooltip info for entity'}
            value={
              !!currentEntity
                ? { ...currentEntity, title: currentEntity.name }
                : null
            }
            options={entities.map((entity) => ({
              ...entity,
              title: entity.name,
            }))}
            handleChange={(value: any) => {
              handleStorySegmentEdit(
                question.id,
                value.name.toString(),
                'primary_entity'
              );
              // handleStorySegmentEdit(
              //   question.id,
              //   value.id,
              //   'primary_entity_id'
              //   'primary_entity_id'
              // );
              // updateBlockValue(value.id, 'fail_sequence_id');
            }}
          />
        </div>
        <div className='flex-1 ml-5px'>
          <InputLabel
            value={question?.role}
            handleChange={(value) =>
              handleStorySegmentEdit(question.id, value, 'role')
            }
            title='Role'
            tooltip='Role of Story'
          />
        </div>
      </div>
      <br />
      <InputVariableField
        hasWriteAccess={hasWriteAccess}
        value={question?.attribute}
        onChange={(value) =>
          handleStorySegmentEdit(question.id, value, 'attribute')
        }
        attributeList={attributeList}
        showAttribute={showAttributeField}
        setShowAttribute={setShowAttributeField}
        handleSelect={(value) => console.log(value)}
        placeHolder={'stored variable name example: f.name'}
      />
    </div>
  );
};

export default StoryBlockQuestionSegment;
