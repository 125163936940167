import React from 'react';
import { Pane, Button } from 'evergreen-ui';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

const DataLabCreateButton = ({ redirectUrl, title }) => {
  return (
    <Pane onClick={() => navigate(redirectUrl)}>
      <Button
        className='data-lab__action-button'
        style={{ background: '#4CAF50' }}
      >
        {title}
      </Button>
    </Pane>
  );
};
DataLabCreateButton.propTypes = {
  title: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string,
};

export default DataLabCreateButton;
