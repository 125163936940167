import React from 'react';
import {
  Table,
  Badge,
  IconButton,
  Button,
  Avatar,
  Popover,
  Menu,
} from 'evergreen-ui';

interface project {
  id: number;
  name: string;
  slug: string;
  image: string;
}

interface permission {
  id: number;
  short_code: string;
  verbose: string;
}

interface role {
  id: number;
  name: string;
  permissions: permission[];
}

interface pendingproject {
  id: number;
  project: project;
  role: role;
}

interface props {
  data: pendingproject[] | undefined;
  updatePermission: (id: number, accept: boolean) => void;
}

const PendingProjectTable: React.FC<props> = ({ data, updatePermission }) => {
  return (
    <div className='mt-30px'>
      <Table>
        <Table.Head>
          <Table.TextHeaderCell flexBasis={'20%'}>
            Project Name
          </Table.TextHeaderCell>
          <Table.TextHeaderCell>Role</Table.TextHeaderCell>
          <Table.TextHeaderCell>Permission</Table.TextHeaderCell>
          <Table.TextHeaderCell>Action</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body height={'calc( 100vh - 330px)'}>
          {data?.map((pendingProject, index) => (
            <Table.Row key={index}>
              <Table.TextCell flexBasis='20%' className='avatar-cell'>
                <Avatar
                  src={pendingProject.project.image}
                  name={pendingProject.project.name}
                  className='mr-10px'
                />
                <p>{pendingProject.project.name || 'Project'}</p>
              </Table.TextCell>
              <Table.TextCell>
                <Badge color='neutral'>{pendingProject.role.name}</Badge>
              </Table.TextCell>
              <Table.Cell>
                <Popover
                  content={
                    <Menu>
                      <Menu.Group title='Permission List'>
                        {pendingProject?.role?.permissions?.map(
                          (permission, i) => (
                            <Menu.Item
                              className='pending__permission-details'
                              key={i}
                              icon={
                                permission.short_code.startsWith('write')
                                  ? 'highlight'
                                  : 'book'
                              }
                            >
                              {permission.verbose}
                            </Menu.Item>
                          )
                        )}
                      </Menu.Group>
                    </Menu>
                  }
                >
                  <IconButton icon={'expand-all'} appearance='minimal' />
                </Popover>
              </Table.Cell>
              <Table.Cell display={'flex'}>
                <Button
                  height={26}
                  className='button-alice__gradient-small-button'
                  iconBefore={'endorsed'}
                  intent='success'
                  marginRight={10}
                  onClick={() =>
                    updatePermission(pendingProject.project?.id, true)
                  }
                >
                  Accept
                </Button>
                <IconButton
                  icon='cross'
                  appearance='minimal'
                  intent='danger'
                  onClick={() =>
                    updatePermission(pendingProject.project?.id, false)
                  }
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default PendingProjectTable;
