import axios from '../../../httpClient';
import config from '../../../config';
import { toaster } from 'evergreen-ui';

const initialState = {
  channelList: [],
};

export const channel = {
  state: {
    ...initialState,
  },
  reducers: {
    updateChannelList(state, payload) {
      return { ...state, channelList: payload };
    },
    appendChannelList(state, payload) {
      return { ...state, channelList: [...state.channelList, payload] };
    },
    replaceChannelList(state, payload) {
      const localChannelList = state.channelList.map((channel) => {
        if (channel.id === payload.id) {
          return payload;
        }
        return channel;
      });
      return { ...state, channelList: localChannelList };
    },
    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async fetchChannel(projectId) {
      try {
        const res = await axios.get(
          `${config.projects}/${projectId}/platforms/list`
        );
        if (res.data.success) {
          dispatch.channel.updateChannelList(res.data.dataSource);
        }
      } catch (err) {
        dispatch.channel.updateChannelList([]);
        console.log(err.response);
      }
    },
    async channelInit(payload) {
      // payload = {
      //   projectId: number
      //   data : {
      //     title: string,
      //     description: string,
      //     type: string,
      //   }
      // }
      try {
        const res = await axios.post(
          `${config.projects}/${payload.projectId}/platforms/initialize`,
          payload.body
        );
        if (res.status === 200 && res.data.success) {
          dispatch.channel.appendChannelList(res.data.dataSource);
          return true;
        }
        return false;
      } catch (err) {
        console.log(err.response);
        return false;
      }
    },
    async fetchFacebookPages(payload) {
      /*
        payload = {
          id: num // fb userID
          token: string //temp accessToken
        }
       */
      try {
        const res = await axios.get(
          `${config.fbConnect}/${payload.id}/pages?token=${payload.token}&platform_type=${payload.type}`
        );
        return res.data;
      } catch (err) {
        toaster.danger(err.response.data.error);
      }
    },
    async connectChannel(payload) {
      /*
        payload = {
          id: num // project id
          type: string // facebook || viber
          data: object // page information
        }
       */
      try {
        const res = await axios.post(
          `${config.platform}/${payload.id}/connect`,
          payload.data
        );
        if (res.status === 200 && res.data.success) {
          dispatch.channel.replaceChannelList(res.data.dataSource);
          toaster.success('Platform Connected Successfully!');
          return true;
        } else {
          toaster.danger('Failed to Connect!');
          return false;
        }
      } catch (err) {
        toaster.danger(err.response.data.error);
        return false;
      }
    },
    async disconnectChannel(payload) {
      /*
        payload = {
          id: num // project id
          type: string // facebook || viber
          data: object // page information
        }
       */
      try {
        const res = await axios.post(
          `${config.platform}/${payload}/disconnect`
        );
        if (res.status === 200 && res.data.success) {
          dispatch.channel.replaceChannelList(res.data.dataSource);
          toaster.success('Platform Disconnected Successfully!');
          return true;
        }
        return false;
      } catch (err) {
        toaster.danger(err.response.data.error);
        return false;
      }
    },
    async connectHandoverProtocol(payload) {
      /*
      payload =
          channelId: number
          appId: string
       */
      try {
        const res = await axios.post(
          `${config.platform}/${payload.channelId}/secondary-receiver`,
          {
            app_id: payload.appId,
          }
        );
        if (res.data.success) {
          toaster.success('Handover Protocol Updated', {
            description: 'Congrats! Handover Protocol Has been Updated',
          });
          return true;
        }
        return false;
      } catch (err) {
        !!err.response && toaster.danger(err.response.data.error);
      }
    },
    async updateChannelData(payload) {
      /*
      payload =
          channelId: number
          data: channel all edited data
       */
      try {
        const {
          primary_color,
          persistent_menu,
          nlp_engine,
          nlp_info,
          nlp_confidence,
          title,
          description,
          username,
          url,
          avatar,
          name,
          cover,
          whitelisted_domains,
        } = payload.data;

        let persistentMenu = persistent_menu.filter(
          (elem) => !!elem.title && !!elem.type && !!elem.value
        );

        const res = await axios.post(
          `${config.platform}/${payload.channelId}/update`,
          {
            primary_color: primary_color,
            persistent_menu: persistentMenu,
            nlp_engine: nlp_engine, // may add more later
            nlp_info: nlp_info,
            nlp_confidence: nlp_confidence,
            title: title,
            description: description,
            username: username,
            name: name,
            url: url,
            avatar: avatar,
            cover: cover,
            whitelisted_domains: whitelisted_domains,
          }
        );
        if (res.data.success) {
          dispatch.channel.replaceChannelList(res.data.dataSource);
          toaster.success('Success', {
            description: 'Updated Successfully',
          });
          return true;
        }
        return false;
      } catch (err) {
        !!err.response && toaster.danger(err.response.data.error);
      }
    },
  }),
};
