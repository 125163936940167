import React from 'react';
import {IconButton, Button} from "evergreen-ui";
import imageIcon from './../../../../assets/images/imageIconGallery.svg';

interface Prop {
  handleClear: ()=> void;
  preview: string;
  hasWriteAccess: boolean;
  closeModal: () => void;
  previewFile: any;
  handleUpload: () => void;
}

const CRMUploadPreview: React.FC<Prop> = ({handleClear,preview,previewFile,hasWriteAccess,closeModal,handleUpload}) => {


  const [loading,setLoading] = React.useState(false);
  return (
    <div className='crm-image-gallery__preview'>
          {hasWriteAccess && (
           <div className='flex align-center space-between'>
             <IconButton
               icon='arrow-left'
               appearance='minimal'
               onClick={() => handleClear()}
             />
             <IconButton
               icon='cross'
               appearance='minimal'
               onClick={() => closeModal()}
             />
             </div>
          )}
          <div className='crm-image-gallery__preview-container' style={{background: `url(${preview}`}} />
          <div className='crm-image-gallery__preview-info'>
            <div>
              <img src={imageIcon}  alt='gallery'/> &nbsp;
              {!!previewFile && previewFile?.name.length > 40 ? previewFile?.name.substring(0,38)+'...': previewFile?.name}
            </div>
            <Button className='alice-btn__secondary' isLoading={loading} height={22} onClick={async()=>{
              setLoading(true);
              await handleUpload();
             setTimeout(()=>{
               setLoading(false);
             },1500)
            }}>
              Upload
            </Button>
          </div>
    </div>
  );
};

export default CRMUploadPreview;
