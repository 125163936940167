import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Combobox } from 'evergreen-ui';

const PopupBlockContent = ({ items, inputVal, handleChange }) => {
  return (
    <Fragment>
      <h5>Redirect to Sequence</h5>
      <Combobox
        openOnFocus
        width='100%'
        initialSelectedItem={items.filter((itm) => itm.id === inputVal)[0]}
        items={items}
        itemToString={(item) => (item ? item.title : '')}
        onChange={(selected) => selected && handleChange(selected.id)}
        placeholder='Sequence'
      />
      <p className='button-popup__hint'>
        <strong>Hint: </strong>
        <span>
          Selected sequence will get triggered after clicking this button.
        </span>
      </p>
    </Fragment>
  );
};

PopupBlockContent.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  inputVal: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default PopupBlockContent;
