import React from 'react';
import {connect} from "react-redux";
import {getUpdatedPlatformIconOutlined, getPlatformInfoTitle} from "../../../utils";

interface props {
  name: string;
  platform: any;
  id: number;
  currentTicket: any;
  region: string;
  country: string;
}

const CRMRightInfoBar: React.FC<props> = ({id, name, platform, currentTicket, region, country}) => {
  return <div className='customer-info__action-bar customer-info__right-topbar'>

    <div className='right-side-info-bar__header__user-info'>
      <div>
        {!!currentTicket && <p>{name || "Anonymous User"}</p>}
      </div>
      <div>
        {!!currentTicket && <p className="right-side-info-bar__header__user-info-location">
          {region ? region : ''}{region && country ? ', ' : ''} {country ? country : ''}
        </p>}
      </div>
    </div>
    {!!currentTicket &&
    <div className='right-side-info-bar__header__user-platform'>
      <img
        src={getUpdatedPlatformIconOutlined(
          platform?.type || 'webchat'
        )}
        width={12}
        height={12}
        loading='lazy'
        alt='channel'
      />
      &nbsp;
      <p
        className='ticket-card__content-left-info-subtitle'>
        {(getPlatformInfoTitle(platform) || 'Webchat')}
      </p>
    </div>
    }
  </div>;
};


const mapState = (state: any) => ({
  id: state.crm.currentTicket?.id,
  name: state.crm.customerAttributes?.fixed.full_name,
  platform: state.crm.customerMeta?.platform,
  currentTicket: state.crm.currentTicket,
  region: state.crm.customerAttributes?.variable.region || '',
  country: state.crm.customerAttributes?.variable.country || ''
});


const CRMRightInfoBarContainer = connect(mapState)(CRMRightInfoBar);
export default CRMRightInfoBarContainer;
