import React from 'react';
import {
  Tooltip,
  Button,
  Table,
  Spinner,
  Avatar,
  SearchInput,
  Icon,
} from 'evergreen-ui';

interface props {
  title: string;
  toolTipInfo: string;
  tableData: any[];
  loader: boolean;
  query: string;
  setQuery: (val: string) => void;
  handleExport: () => void;
  exportLoader: boolean;
}

const AgentDetailsTable: React.FC<props> = ({
  title,
  toolTipInfo,
  tableData,
  loader,
  query,
  setQuery,
  exportLoader,
  handleExport,
}) => {
  return (
    <div className='reporting-container__user-row-card flex-column pdb-20px'>
      <div className='reporting-title-container'>
        <span>{title}</span>&nbsp;
        <Tooltip content={toolTipInfo}>
            <Icon color={'success'} size={14} icon={'info-sign'} />
        </Tooltip>
      </div>
      <br />
      <div className='reporting-container__ticket-table-header flex-end'>
        <div className='flex'>
          <div>
            <SearchInput
              placeholder='Search Here...'
              value={query}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setQuery(e.target.value)
              }
            />
          </div>
          <div>
            <Button
              marginLeft={10}
              className='primary-tinted-button'
              isLoading={exportLoader}
              onClick={() => {
                handleExport();
              }}
            >
              {!exportLoader && (
                <>
                  <Icon icon={'cloud-download'} size={16} />
                  &nbsp;
                </>
              )}&nbsp;
              Export CSV
            </Button>
          </div>
        </div>
      </div>
      <br />
      <div className='reporting-container__ticket-table'>
        <Table>
          <Table.Head>
            <Table.TextHeaderCell>Name</Table.TextHeaderCell>
            <Table.TextHeaderCell>Ticket Resolved</Table.TextHeaderCell>
            <Table.TextHeaderCell>Ticket Assigned</Table.TextHeaderCell>
            <Table.TextHeaderCell>Avg. Resolve Time</Table.TextHeaderCell>
            <Table.TextHeaderCell>Avg. Queue Time</Table.TextHeaderCell>
          </Table.Head>
          {!loader && (
            <Table.Body height={240}>
              {tableData.map((details: any, index: number) => (
                <Table.Row key={index} height={60}>
                  <Table.TextCell>
                    <div className='flex align-center hightlighted-bg'>
                      <Avatar src={details.avatar} name={details.agent_name} /> &nbsp;&nbsp;
                      {details.agent_name}
                    </div>
                  </Table.TextCell>
                  <Table.TextCell>{details.resolve_count}</Table.TextCell>
                  <Table.TextCell>{details.assign_count}</Table.TextCell>
                  <Table.TextCell>{details.resolve_time} min</Table.TextCell>
                  <Table.TextCell>{details.queue_time} min</Table.TextCell>
                </Table.Row>
              ))}
              {tableData.length === 0 && (
                <Table.Row className={'flex align-center justify-center'}>
                  No Data Available
                </Table.Row>
              )}
            </Table.Body>
          )}
          {loader && (
            <Table.Body height={240}>
              <div className='flex align-center justify-center h-200px'>
                <Spinner />
              </div>
            </Table.Body>
          )}
        </Table>
      </div>
    </div>
  );
};

export default AgentDetailsTable;
