import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Dialog } from 'evergreen-ui';

const CellAction = ({ handleEdit, handleDelete }) => {
  const [deleteShown, setDeleteShown] = useState(false);
  return (
    <div className='data-lab-details__table-action'>
      <IconButton appearance='minimal' icon='edit' onClick={handleEdit} />
      <IconButton
        appearance='minimal'
        icon='trash'
        intent='danger'
        onClick={() => setDeleteShown(true)}
      />
      <Dialog
        isShown={deleteShown}
        title='Delete Entry'
        intent='danger'
        onCloseComplete={() => {
          setDeleteShown(false);
        }}
        onConfirm={() => {
          handleDelete();
          setDeleteShown(false);
        }}
        confirmLabel={'Confirm'}
      >
        <p>Are You Sure You Want to Delete This Entry?</p>
      </Dialog>
    </div>
  );
};

CellAction.propTypes = {
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default CellAction;
