import React from 'react';
import PropTypes from 'prop-types';

const SettingsHeader = ({ title, subtitle }) => {
  return (
    <div className='settings__header'>
      <p className='settings__header-title'>{title}</p>
      <p className='settings__header-subtitle'>{subtitle}</p>
    </div>
  );
};

SettingsHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default SettingsHeader;
