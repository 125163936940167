import React from 'react';
import { SelectMenu, Button, Pane, TextInput, IconButton } from 'evergreen-ui';
import moment from 'moment';
import DateTime from 'react-datetime';
import TimePicker from 'rc-time-picker';

interface props {
  condition: conditionProps;
  index: number;
  handleDelete(index: number): void;
  labFields: labFieldsProps[];
  handleConditionFieldUpdate(item: object, key: 'name' | 'value'): void;
}

interface conditionProps {
  name: string;
  value: any;
  type: string;
  options: string[];
  label: string;
}
interface labFieldsProps {
  label: string;
  value: number | string;
  type: string;
  options: string[];
}

const FilterCard: React.FC<props> = ({
  condition,
  index,
  handleDelete,
  labFields,
  handleConditionFieldUpdate,
}) => {
  const smartFilterValueBlock = (type: string) => {
    switch (type) {
      case 'multiselect':
        return (
          <SelectMenu
            isMultiSelect
            hasTitle={true}
            title={'Select Items'}
            hasFilter={false}
            options={condition.options.map((label) => ({
              label,
              value: label,
            }))}
            selected={condition.value}
            onSelect={(item) => {
              let selected: string | string[] = '';
              if (condition.value) {
                selected = [...condition.value, item.value+''];
              } else {
                selected = [item.value + ''];
              }
              handleConditionFieldUpdate(
                {
                  value: selected,
                  index: index,
                },
                'value'
              );
            }}
            onDeselect={(item) => {
              const deselectedItem = condition.value.filter(
                (elem: string) => elem !== item.value
              );

              handleConditionFieldUpdate(
                {
                  value: deselectedItem,
                  index: index,
                },
                'value'
              );
            }}
          >
            <Button className='select-picker-advance-filter'>
              {(condition.value &&
                condition.value.length > 0 &&
                `${condition.value.length} selected...`) ||
                'Select multiple...'}
            </Button>
          </SelectMenu>
        );

      case 'singleselect':
        return (
          <SelectMenu
            hasTitle={true}
            title={'Select item'}
            hasFilter={false}
            options={condition.options.map((label) => ({
              label,
              value: label,
            }))}
            selected={condition.value + ''}
            onSelect={(item) =>
              handleConditionFieldUpdate(
                {
                  value: item.value,
                  index: index,
                },
                'value'
              )
            }
            closeOnSelect={true}
          >
            <Button
              className='select-picker-advance-filter'
              iconAfter='caret-down'
            >
              {condition.value || 'Select...'}
            </Button>
          </SelectMenu>
        );

      case 'time':
        return (
          <TimePicker
            value={
              condition.value ? moment(condition.value, 'HH:mm') : undefined
            }
            className='timepicker-datalab-filter__fix'
            allowEmpty={true}
            showSecond={false}
            use12Hours={true}
            onChange={(val) =>
              handleConditionFieldUpdate(
                {
                  value: moment(val)
                    .format('HH:mm')
                    .toString(),
                  index: index,
                },
                'value'
              )
            }
          />
        );

      case 'date':
      case 'datetime':
        return (
          <div style={{ flex: 1 }}>
            <DateTime
              className='w-90p'
              value={condition.value ? moment(condition.value) : moment()}
              onChange={(val) =>
                handleConditionFieldUpdate(
                  { value: moment(val).toString(), index: index },
                  'value'
                )
              }
              timeFormat={condition.type === 'datetime'}
            />
          </div>
        );

      default:
        return (
          <TextInput
            style={{ flex: 1 }}
            type={condition.type === 'number' ? 'number' : 'text'}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleConditionFieldUpdate(
                { value: event.target.value, index: index },
                'value'
              );
            }}
            marginRight={15}
            value={condition.value}
            height={30}
            placeholder='Provide Value'
          />
        );
    }
  };

  return (
    <div className='data-lab-advance-filter__card'>
      {!!condition && (
        <Pane marginRight={15} flex={1}>
          <SelectMenu
            title='Select Field'
            closeOnSelect={true}
            options={labFields}
            selected={condition.name}
            onSelect={(item) =>
              handleConditionFieldUpdate({ ...item, index }, 'name')
            }
          >
            <Button
              height={30}
              iconAfter='caret-down'
              className='audience-filter__dropdown uppercase'
            >
              {condition.label || 'Select Field...'}
            </Button>
          </SelectMenu>
        </Pane>
      )}
      {smartFilterValueBlock(condition.type)}
      <IconButton
        className='data-lab-advance-filter__card__remove'
        onClick={() => handleDelete(index)}
        appearance='primary'
        intent='danger'
        icon='cross'
      />
    </div>
  );
};

export default FilterCard;
