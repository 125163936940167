import React, { useState } from 'react';
import BlockContainer from './BlockContainer';
import { Textarea } from 'evergreen-ui';
import PropTypes from 'prop-types';
import InputVariableField from '../elements/InputVariableField';
import InputValidationFragment from '../elements/InputValidationFragment';
import AttributeAPIContainer from '../elements/AttributeAPIContainer';
import {
  updateOnChangeText,
  updateOnChangeAttribute,
  updateOnSelectAttribute,
} from './../../utils';
import inputIcon from './../../pages/builder/assets/images/icons/userinput.svg';
import TextCharInfo from '../elements/TextCharInfo';
import ErrorCounterInput from '../elements/ErrorCounterInput';
import FailTextInput from '../elements/FailTextInput';
import SequenceSelectInput from '../elements/SequenceSelectInput';

const UserInputBlock = ({
  id,
  save,
  saveLoader,
  saveBlock,
  text,
  setText,
  inputValidation,
  attributeList,
  apiList,
  updateInputValidation,
  variable,
  sequences,
  setVariable,
  deleteBlock,
  copyBlock,
  moveBlock,
  errorCounter,
  failText,
  failedSequenceId,
  updateBlockValue,

  hasWriteAccess,
}) => {
  const [showAttribute, setShowAttribute] = useState(false);
  const [showAttributeField, setShowAttributeField] = useState(false);
  const [showAPI, setShowAPI] = useState(false);
  const [maxCount] = useState(320);

  return (
    <BlockContainer
      id={id}
      title='User Variable Input'
      subtitle='Add A Input Variable Block'
      save={save}
      isLoading={saveLoader}
      icon={inputIcon}
      handleSaveClick={() => saveBlock(id)}
      handleDelete={() => deleteBlock(id)}
      sequences={sequences}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <React.Fragment>
        <AttributeAPIContainer
          attributeList={attributeList}
          showAttribute={showAttribute}
          setShowAttribute={setShowAttribute}
          apiList={apiList.filter((api) => api.return_type === 'text')}
          showAPI={showAPI}
          setShowAPI={setShowAPI}
          handleSelect={(value) =>
            (text + value).length + 2 <= maxCount &&
            updateOnSelectAttribute(
              value,
              showAttribute,
              showAPI,
              setShowAttribute,
              setShowAPI,
              setText,
              text,
              apiList
            )
          }
        >
          <Textarea
            required={true}
            grammarly={true}
            disabled={!hasWriteAccess}
            spellCheck={true}
            value={text}
            className='custom-input-style'
            onChange={(e) =>
              e.target.value.length <= maxCount &&
              updateOnChangeText(
                e.target.value,
                setShowAttribute,
                setShowAPI,
                setText
              )
            }
            placeholder='Your text block for User Input'
          />
        </AttributeAPIContainer>
        <TextCharInfo data={text} count={maxCount} />
        <InputValidationFragment
          hasWriteAccess={hasWriteAccess}
          value={inputValidation}
          onChange={(item) => updateInputValidation(item)}
        />
        <br />
        <br />
        <FailTextInput
          value={failText}
          handleChange={(value) => updateBlockValue(value, 'fail_text')}
        />
        <br />
        <br />
        <div className='flex'>
          <div className='flex-1 mr-5px'>
            <ErrorCounterInput
              value={errorCounter}
              handleChange={(value) => updateBlockValue(value, 'error_counter')}
            />
          </div>
          <div className='flex-1 ml-5px'>
            <SequenceSelectInput
              value={failedSequenceId}
              options={sequences}
              handleChange={(value) => {
                updateBlockValue(value, 'fail_sequence');
                updateBlockValue(value.id, 'fail_sequence_id');
              }}
            />
          </div>
        </div>
        <br />
        <InputVariableField
          hasWriteAccess={hasWriteAccess}
          value={variable}
          onChange={(value) =>
            updateOnChangeAttribute(value, setShowAttributeField, setVariable)
          }
          attributeList={attributeList}
          showAttribute={showAttributeField}
          setShowAttribute={setShowAttributeField}
          handleSelect={(value) =>
            updateOnChangeAttribute(value, setShowAttributeField, setVariable)
          }
          placeHolder={'stored variable name example: f.name'}
        />
      </React.Fragment>
    </BlockContainer>
  );
};

UserInputBlock.propTypes = {
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  setText: PropTypes.func.isRequired,
  inputValidation: PropTypes.string.isRequired,
  updateInputValidation: PropTypes.func.isRequired,
  variable: PropTypes.string.isRequired,
  setVariable: PropTypes.func.isRequired,
  save: PropTypes.bool.isRequired,
  saveBlock: PropTypes.func.isRequired,
  attributeList: PropTypes.arrayOf(PropTypes.string),
  apiList: PropTypes.arrayOf(PropTypes.object),
  saveLoader: PropTypes.bool.isRequired,
  hasWriteAccess: PropTypes.bool,
  errorCounter: PropTypes.number,
  failText: PropTypes.string,
  failedSequenceId: PropTypes.any,
  updateBlockValue: PropTypes.func,
};

UserInputBlock.defaultProps = {
  setVariable: (e) => console.log(e),
  hasWriteAccess: false,
};

export default UserInputBlock;
