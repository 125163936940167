import React, { useState } from 'react';
import { Table } from 'evergreen-ui';
import PermissionProjectRow from './PermissionProjectRow';
import PermissionProjectShare from './PermissionProjectShare';

interface props {
  role: any[];
  userRolesType: { id: number; name: string }[];
  shareProject: any;
  shareProjectLoader: boolean;
  hasWriteAccess: boolean;
  deleteAdminRole: (adminId: number) => void;
  adminGroup: any;
  assignAdminGroup: (adminId: number, tags: any) => void;
}

const PermissionProjectTable: React.FC<props> = ({
  role,
  userRolesType,
  shareProject,
  shareProjectLoader,
  hasWriteAccess,
  deleteAdminRole,
  adminGroup,
  assignAdminGroup,
}) => {
  const [shareEmail, setShareEmail] = useState('');
  const [adminRole, setAdminRole] = useState({
    label: userRolesType[0]?.name || 'Select',
    value: userRolesType[0]?.id || -1,
  });
  return (
    <div className='mt-30px' style={{ height: '95%' }}>
      <Table>
        <Table.Head>
          <Table.TextHeaderCell flexBasis={'20%'}>User</Table.TextHeaderCell>
          <Table.TextHeaderCell flexBasis={'20%'}>Email</Table.TextHeaderCell>
          <Table.TextHeaderCell flexBasis={'20%'}>Role</Table.TextHeaderCell>
          <Table.TextHeaderCell flexBasis={'20%'}>
            Agent Group
          </Table.TextHeaderCell>
          <Table.TextHeaderCell flexBasis={'5%'} />
        </Table.Head>
        <Table.Body height={'fit-content'}>
          {role?.map((roleUser, index) => (
            <PermissionProjectRow
              key={index}
              roleUser={roleUser}
              deleteAdminRole={deleteAdminRole}
              adminGroup={adminGroup}
              assignAdminGroup={assignAdminGroup}
            />
          ))}
          {hasWriteAccess && (
            <PermissionProjectShare
              userRolesType={userRolesType}
              shareEmail={shareEmail}
              updateEmail={setShareEmail}
              adminRole={adminRole}
              shareProject={shareProject}
              shareProjectLoader={shareProjectLoader}
              setAdminRole={setAdminRole}
              resetFields={() => {
                setShareEmail('');
                setAdminRole({
                  label: userRolesType[0]?.name || 'Select',
                  value: userRolesType[0]?.id || -1,
                });
              }}
            />
          )}
        </Table.Body>
      </Table>
    </div>
  );
};

export default PermissionProjectTable;
