import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from '@reach/router';

const Protected = (props) => {
  const { authenticated, forceLogout, logout, children } = props;

  if (forceLogout) {
    logout();
    return <Redirect from='' to='/' noThrow />;
  }

  return authenticated ? children : <Redirect from='' to='/' noThrow />;
};

const mapState = (state) => {
  const accessToken = state.auth.access;

  return {
    authenticated: accessToken !== '',
    forceLogout: state.auth.forceLogout,
  };
};

const mapDispatch = (dispatch) => ({
  logout: () => dispatch.auth.logout(),
});

export default connect(mapState, mapDispatch)(Protected);
