import React, { useState } from 'react';
import { Button } from 'evergreen-ui';
import FacebookMessengerEditModal from './FacebookMessengerEditModal';
import FacebookFeedEditModal from './FacebookFeedEditModal';
import ViberEditModal from './ViberEditModal';
import WebChatEditModal from './WebChatEditModal';
import AppEditModal from './AppEditModal';
import WhatsappEditModal from './WhatsappEditModal';

import { connect } from 'react-redux';

interface props {
  type: string;
  close: () => void;
  editModalInfo: any;
  updateEditModalInfo: (key: string, value: any) => void;
  isHandoverLoading: boolean;
  connectHandoverProtocol: (channelId: number, appId: any) => void;
  updateChannelData: (channelId: number, data: any) => void;
}

const ChannelEditModal: React.FC<props> = ({
  type,
  close,
  editModalInfo,
  updateEditModalInfo,
  isHandoverLoading,
  connectHandoverProtocol,
  updateChannelData,
}) => {
  const [protocolMethod, setProtocolMethod] = useState('Facebook Page Inbox');
  const [protocolAppId, setProtocolAppId] = useState('263902037430900');
  const [isChanged, setIsChanged] = useState(false);

  const fetchName = (type: string) => {
    switch (type) {
      case 'webchat':
        return 'Website Channel';
      case 'facebook_messenger':
        return 'Messenger';
      case 'facebook_feed':
        return 'Facebook Feed';
      case 'viber_messenger':
        return 'Viber Channel';
      case 'app_messenger':
        return 'App Channel';
      default:
        return 'Channel';
    }
  };

  const handleSave = async () => {
    if(isChanged){
      await connectHandoverProtocol(editModalInfo.id, protocolAppId);
    }
    await updateChannelData(editModalInfo.id, editModalInfo);
    close();
  };

  return (
    <div className='channel-edit-modal__container'>
      <div className='channel-edit-modal__header'>
        <div className='channel-edit-modal__header-content'>
          <p className='channel-edit-modal__header-content-title'>
            {fetchName(type)}
          </p>
          <p className='channel-edit-modal__header-content-subtitle'>
            Change the settings of your connected platform
          </p>
        </div>
        <div className='channel-edit-modal__header-actions'>
          <Button
            className='button-alice__gradient'
            isLoading={isHandoverLoading}
            onClick={() => handleSave()}
          >
            Save Changes
          </Button>
          <Button
            marginLeft={16}
            className='btn-outline-danger'
            onClick={() => close()}
          >
            Cancel
          </Button>
        </div>
      </div>
      <br />
      <div className='primary-hr' />
      {type === 'facebook_messenger' && (
        <FacebookMessengerEditModal
          data={editModalInfo}
          updateEditModalInfo={updateEditModalInfo}
          protocolAppId={protocolAppId}
          protocolMethod={protocolMethod}
          setProtocolMethod={setProtocolMethod}
          setProtocolAppId={setProtocolAppId}
          setIsChanged={setIsChanged}
        />
      )}
      {type === 'facebook_feed' && (
        <FacebookFeedEditModal
          data={editModalInfo}
          updateEditModalInfo={updateEditModalInfo}
        />
      )}
      {type === 'viber_messenger' && (
        <ViberEditModal
          data={editModalInfo}
          updateEditModalInfo={updateEditModalInfo}
        />
      )}
      {type === 'webchat' && (
        <WebChatEditModal
          data={editModalInfo}
          updateEditModalInfo={updateEditModalInfo}
        />
      )}
      {type === 'app_messenger' && (
        <AppEditModal
          data={editModalInfo}
          updateEditModalInfo={updateEditModalInfo}
        />
      )}
        {type === 'whatsapp_messenger' && (
            <WhatsappEditModal
                data={editModalInfo}
                updateEditModalInfo={updateEditModalInfo}
            />
        )}
    </div>
  );
};

const mapState = (state: any) => ({
  isHandoverLoading: state.loading.effects.channel.connectHandoverProtocol,
});

const mapDispatch = (dispatch: any) => ({
  connectHandoverProtocol: (channelId: number, appId: any) =>
    dispatch.channel.connectHandoverProtocol({ channelId, appId }),
  updateChannelData: (channelId: number, data: any) =>
    dispatch.channel.updateChannelData({ channelId, data }),
});

const ChannelEditModalContainer = connect(
  mapState,
  mapDispatch
)(ChannelEditModal);

export default ChannelEditModalContainer;
