import React, { Component } from 'react';
import { toaster } from 'evergreen-ui';
import { navigate, Redirect } from '@reach/router';
import './assets/styles/auth.scss';
import { connect } from 'react-redux';
import logoBlack from './assets/images/logo/dark-alice-logo-full.svg';
import AuthHeader from './components/AuthHeader';
import RegisterFormSection from './components/RegisterFormSection';
import { isValidEmail } from '../../utils';
import { authData } from './../../content';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      duplicateEmail: false,
      loading: false,
    };
  }
  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };
  checkDuplicateEmail = () => {
    this.props
      .checkDuplication({
        email: this.state.email,
      })
      .then((success) => {
        if (success) {
          this.setState({
            loading: false,
          });
        } else {
          this.setState({
            duplicate_email: true,
            email: '',
            password: '',
            loading: false,
          });
        }
      });
  };
  validateRegisterData = () => {
    const { email } = this.state;
    if (isValidEmail(email)) {
      return true;
    } else {
      toaster.danger('Please fill all required fields with valid data');
      return false;
    }
  };

  handleRegisterSubmit = () => {
    if (!this.validateRegisterData()) return;

    this.setState({ loading: true });
    this.props
      .register({
        email: this.state.email,
        username: this.state.email,
        password: this.state.password,
      })
      .then((success) => {
        if (success) {
          toaster.success('Please check your email for further instruction!');
          this.setState({
            email: '',
            password: '',
            loading: false,
          });
          navigate(`/register-resend-mail`);
        } else {
          this.setState({ loading: false, duplicateEmail: true });
        }
      });
  };

  render() {
    const isAuthenticated = this.props.auth.access !== '';
    return isAuthenticated ? (
      <Redirect from='/' to='/dashboard' noThrow />
    ) : (
      <div className='auth-container'>
        <div className='header-container'>
          <div>
            <a href='/'>
              <img className='logo-dark' src={logoBlack} alt='alice logo' />
            </a>
          </div>
          <div>
            <AuthHeader
              linkInfo={authData.loginInfo.text}
              link={authData.loginInfo.link}
              linkText={authData.loginInfo.linkTitle}
            />
          </div>
        </div>
        <RegisterFormSection
          {...this.state}
          handleChange={this.handleChange}
          loading={this.state.loading}
          handleSubmit={this.handleRegisterSubmit}
          content={authData}
        />
      </div>
    );
  }
}

const mapState = (state) => ({
  auth: state.auth,
});

const mapDispatch = (dispatch) => ({
  register: (payload) => dispatch.auth.register(payload),
  checkDuplication: (payload) => dispatch.auth.checkDuplicateEmail(payload),
});

const RegisterContainer = connect(mapState, mapDispatch)(Register);

export default RegisterContainer;
