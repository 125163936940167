import React, { useState } from 'react';
import typing from './../../pages/builder/assets/images/icons/typing.svg';
import PropTypes from 'prop-types';
import {
  Card,
  Popover,
  Position,
  Menu,
  IconButton,
  Button,
  Dialog,
  Combobox,
  Icon,
} from 'evergreen-ui';
import '../../assets/styles/blocks/container.scss';

const BlockContainer = ({
  title,
  id,
  subtitle,
  icon,
  sequences,
  handleCopy,
  handleMove,
  handleDelete,
  children,
  save,
  isLoading,
  handleSaveClick,
  hasWriteAccess,
}) => {
  const [deleteShown, setDeleteShown] = useState(false);
  const [copyShown, setCopyShown] = useState(false);
  const [moveShown, setMoveShown] = useState(false);
  const [selectedSequence, setSelectedSequence] = useState(0);

  return (
    <Card className='block-container'>
      <div className='block-container__dnd'>
        <Icon icon='drag-handle-vertical' size={13} />
      </div>
      <div className={'block-container__flex'}>
        <img src={icon} alt={'icon'} />
        <div>
          <h5>{title}</h5>
          <p>{subtitle}</p>
        </div>
      </div>

      {hasWriteAccess && !save && (
        <Button
          onClick={() => handleSaveClick()}
          isLoading={isLoading}
          className='block-container__save_button'
          iconBefore={isLoading ? '' : 'endorsed'}
          height={20}
          appearance='primary'
          intent={'success'}
        >
          Save
        </Button>
      )}
      {children}
      {hasWriteAccess && (
        <Popover
          position={Position.BOTTOM_RIGHT}
          content={({ close: popoverClose }) => (
            <Menu>
              <Menu.Group>
                <Menu.Item
                  onSelect={() => {
                    popoverClose();
                    setCopyShown(true);
                  }}
                  icon='clipboard'
                >
                  Copy
                </Menu.Item>
                <Menu.Item
                  onSelect={() => {
                    popoverClose();
                    setMoveShown(true);
                  }}
                  icon='circle-arrow-right'
                >
                  Move
                </Menu.Item>
              </Menu.Group>
              <Menu.Divider />
              <Menu.Group>
                <Menu.Item
                  onSelect={() => {
                    popoverClose();
                    setDeleteShown(true);
                  }}
                  icon='trash'
                  intent='danger'
                >
                  Delete
                </Menu.Item>
              </Menu.Group>
            </Menu>
          )}
        >
          <IconButton
            appearance='minimal'
            className='menu-icon'
            icon='more'
            height={28}
          />
        </Popover>
      )}
      <Dialog
        isShown={deleteShown}
        title='Delete Block'
        intent='danger'
        onCloseComplete={() => {
          setDeleteShown(false);
        }}
        onConfirm={() => {
          handleDelete();
          setDeleteShown(false);
        }}
        confirmLabel={'Confirm'}
      >
        <p>Are You Sure You Want to Delete This Block?</p>
      </Dialog>
      <Dialog
        isShown={copyShown}
        title='Select a sequence to copy this block'
        onCloseComplete={() => setCopyShown(false)}
        confirmLabel='Confirm'
        onConfirm={() => {
          handleCopy(id, selectedSequence);
          setCopyShown(false);
        }}
      >
        <Combobox
          openOnFocus
          width='100%'
          items={sequences}
          itemToString={(item) => (item ? item.title : '')}
          onChange={(selected) => selected && setSelectedSequence(selected.id)}
          placeholder='Sequence'
        />
      </Dialog>
      <Dialog
        isShown={moveShown}
        title='Select a sequence to move this block'
        onCloseComplete={() => setMoveShown(false)}
        confirmLabel='Confirm'
        onConfirm={() => {
          handleMove(id, selectedSequence);
          setMoveShown(false);
        }}
      >
        <Combobox
          openOnFocus
          width='100%'
          items={sequences}
          itemToString={(item) => (item ? item.title : '')}
          onChange={(selected) => selected && setSelectedSequence(selected.id)}
          placeholder='Sequence'
        />
      </Dialog>
    </Card>
  );
};

BlockContainer.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  handleCopy: PropTypes.func,
  handleMove: PropTypes.func,
  handleDelete: PropTypes.func,
  children: PropTypes.element,
  save: PropTypes.bool,
  handleSaveClick: PropTypes.func,
  icon: PropTypes.string,
  hasWriteAccess: PropTypes.bool,
};

BlockContainer.defaultProps = {
  save: false,
  handleSaveClick: () => console.log('clicked on block container save button'),
  handleDelete: () =>
    console.log('delete clicked from block container default props'),
  icon: typing,
  hasWriteAccess: false,
};

export default BlockContainer;
