import React, { useState } from 'react';
import { SideSheet } from 'evergreen-ui';
import StartTrialFlow from '../../../components/StartTrialFlow';

interface Prop {
  trialAvailed: boolean;
  isCreator: boolean;
  isEnterprise: boolean;
}

const MessageAction: React.FC<Prop> = ({ trialAvailed, isCreator, isEnterprise }) => {
  const [isShown, setShown] = useState(false);

  return (
    <div className='alice-message-action'>
      {isCreator && (
          isEnterprise ? (
              <a href='https://getalice.ai/contact/' target='_blank' rel='noopener noreferrer'>Contact Us</a>
          ) : (
              <a href='/settings/billing'>Upgrade</a>
          )
      )}
      {isCreator && !trialAvailed && (
        <a
          href='/'
          onClick={(e) => {
            e.preventDefault();
            setShown(true);
          }}
        >
          Start Trial
        </a>
      )}

      <SideSheet
        isShown={isShown}
        width={480}
        onCloseComplete={() => {
          setShown(false);
        }}
        preventBodyScrolling
      >
        <StartTrialFlow />
      </SideSheet>
    </div>
  );
};

export default MessageAction;
