import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toaster } from 'evergreen-ui';
import TeamInfoSettingsForm from '../component/TeamInfoSettingsForm';
import { checkPermission, handleImageUpload } from '../../../utils';
import HasPermission from '../../../components/HasPermission';

class TeamInfoSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isInfoChanged: false,
      facebookPageList: [],
      writeAccess: 'write:settings',
    };
  }

  handleSave = async () => {
    if (!!this.props.selectedProject) {
      await this.props.editProjectInfo(this.props.selectedProject.id);
      this.setState({ isInfoChanged: false });
      await this.props.fetchProject();
    } else {
      toaster.warning('Invalid', {
        description: `Project is not selected`,
        duration: 1,
      });
    }
  };

  handleCancel = () => {
    this.setState({ isInfoChanged: false });
    this.props.setSelectedProject(
      this.props.projects.filter(
        (p) => this.props.selectedProject.id === p.id
      )[0]
    );
  };

  handleImageChange = (image) => {
    this.setState({ isInfoChanged: true });
    handleImageUpload(image, (img) => this.handleChange('image', img));
  };

  handleImageClear = () => {
    this.setState({ isInfoChanged: true });
    this.handleChange('image', '');
  };

  handleChange = (name, value) => {
    this.setState({ isInfoChanged: true });
    this.props.setSelectedProject({
      ...this.props.selectedProject,
      [name]: value,
    });
  };

  async componentDidMount() {
    await this.props.fetchProject();
    // always needs to update information on project settings mount

    if (!this.props.previousProject && this.props.selectedProject === null) {
      this.props.setSelectedProject(this.props.projects[0]);
    }

    if (this.props.previousProject) {
      this.props.setSelectedProject(
        this.props.projects.filter(
          (e) => e.id === this.props.previousProject.id
        )[0]
      );
      //filter because I've already fetched new data now just update setSelected project with new data rather previous project
    }
  }

  render() {
    const hasWriteAccess = checkPermission(
      this.props.permissionList,
      this.state.writeAccess
    );
    return (
      <HasPermission shortCode={'read:settings'} isSettingsView>
        <div className='project-settings'>
          <TeamInfoSettingsForm
            title='Team Information'
            hasWriteAccess={hasWriteAccess}
            description='Team Information like name, avatar change etc.'
            infoLoader={this.props.infoLoader}
            infoUpdateLoader={this.props.infoUpdateLoader}
            isInfoChanged={this.state.isInfoChanged}
            projectInfo={this.props.selectedProject}
            handleSave={this.handleSave}
            handleCancel={this.handleCancel}
            handleDescChange={(val) => this.handleChange('description', val)}
            handleNameChange={(val) => this.handleChange('name', val)}
            handleImageChange={this.handleImageChange}
            handleImageClear={this.handleImageClear}
          />
        </div>
      </HasPermission>
    );
  }
}

const mapState = (state) => ({
  projects: state.dashboard.projects,
  selectedProject: state.settings.selectedProject,
  previousProject: state.dashboard.selectedProject,
  infoLoader: state.loading.effects.dashboard.fetchProject,
  infoUpdateLoader: state.loading.effects.settings.editProjectInfo,
  permissionList: state.dashboard.permission.list || [],
});

const mapDispatch = (dispatch) => ({
  fetchProject: () => dispatch.dashboard.fetchProject(),
  editProjectInfo: (projectId) => dispatch.settings.editProjectInfo(projectId),
  setSelectedProject: (payload) =>
    dispatch.settings.setSelectedProject(payload),
});

const TeamInfoSettingsContainer = connect(
  mapState,
  mapDispatch
)(TeamInfoSettings);

export default TeamInfoSettingsContainer;
