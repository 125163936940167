import React from 'react';
import WebIcon from './../../../assets/icons/website.svg';
import AppIcon from './../../../assets/icons/app.svg';
import { TextInputField, Button, Label } from 'evergreen-ui';
import { connect } from 'react-redux';
import { ChromePicker } from 'react-color';
import {channelInfo} from "../../../../../utils";

interface props {
  connectChannel: (id: number, data: any) => Promise<boolean>;
  connectionLoading: boolean;
  connectableChannel: any;
  setShowConnectModal: (val: boolean) => void;
  type: string;
}

const WebChatApp: React.FC<props> = ({
  connectionLoading,
  connectChannel,
  connectableChannel,
  setShowConnectModal,
  type,
}) => {
  const [name, setName] = React.useState('');
  const [url, setUrl] = React.useState('');
  const [color, setColor] = React.useState('#184d47');
  const [isInvalidName, setIsInvalidName] = React.useState(false);
  const [isInvalidUrl, setIsInvalidUrl] = React.useState(false);

  const {title} = channelInfo(type);
  return (
    <div className='channel-settings__card-channel-create'>
      <img
        src={type === 'app_messenger' ? AppIcon : WebIcon}
        alt={'channel modal'}
        className='channel-settings__card-channel-create-banner'
      />
      <p className='channel-settings__card-channel-create-title'>
        Connect With {title}!
      </p>
      <br />
      <TextInputField
        label='Name'
        tabIndex={0}
        required
        isInvalid={isInvalidName}
        placeholder='Write Here...'
        value={name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setName(e.target.value)
        }
      />
      {type === 'webchat' && (
        <TextInputField
          label='Website Url'
          required
          isInvalid={isInvalidUrl}
          placeholder='Write Here...'
          value={url}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setUrl(e.target.value)
          }
        />
      )}
      <Label>Primary Color *</Label>
      <ChromePicker
        className='primary-color-picker'
        color={color}
        disableAlpha={true}
        onChange={(clr: any) => {
          setColor(clr.hex);
        }}
      />

      <div className='flex justify-center mb-20px mt-30px'>
        <Button
          className='button-alice__gradient'
          isLoading={connectionLoading}
          onClick={async () => {
            if (!!name && !!color) {
              if(type === 'webchat' && !url) {
                  setIsInvalidUrl(true);
              }else {
                  setIsInvalidName(false);
                  setIsInvalidUrl(false);
                  const res = await connectChannel(connectableChannel.id, {
                      name: name,
                      url: type === 'webchat' ? url : undefined,
                      primary_color: color,
                  });
                  if (res) {
                      setShowConnectModal(false);
                  }
              }
            } else {
              if (!name) {
                setIsInvalidName(true);
              }
              if (!url) {
                setIsInvalidUrl(true);
              }
            }
          }}
        >
          Connect Now
        </Button>
      </div>
    </div>
  );
};

const mapState = (state: any) => ({
  connectionLoading: state.loading.effects.channel.connectChannel,
});

const mapDispatch = (dispatch: any) => ({
  connectChannel: (id: number, data: any) =>
    dispatch.channel.connectChannel({ id, data }),
});

const WebChatAppContainer = connect(mapState, mapDispatch)(WebChatApp);

export default WebChatAppContainer;
