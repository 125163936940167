import React from 'react';

interface Prop {
}

const SuccessMarketPlaceIcon: React.FC<Prop> = () => {

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" fill="white" fillOpacity="0.01"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M14.6666 8C14.6666 11.6819 11.6819 14.6667 7.99998 14.6667C4.31808 14.6667 1.33331 11.6819 1.33331 8C1.33331 4.3181 4.31808 1.33333 7.99998 1.33333C11.6819 1.33333 14.6666 4.3181 14.6666 8ZM6.25491 7.37928C6.33625 7.41422 6.40981 7.46501 6.47131 7.52868L7.33331 8.39068L9.52864 6.19535C9.59014 6.13167 9.66371 6.08088 9.74504 6.04594C9.82638 6.01101 9.91386 5.99261 10.0024 5.99185C10.0909 5.99108 10.1787 6.00794 10.2606 6.04146C10.3425 6.07499 10.417 6.12449 10.4796 6.18708C10.5422 6.24968 10.5917 6.32411 10.6252 6.40604C10.6587 6.48797 10.6756 6.57576 10.6748 6.66428C10.674 6.7528 10.6557 6.84028 10.6207 6.92162C10.5858 7.00295 10.535 7.07651 10.4713 7.13801L7.80464 9.80468C7.67963 9.92966 7.51009 9.99987 7.33331 9.99987C7.15654 9.99987 6.987 9.92966 6.86198 9.80468L5.52864 8.47135C5.46497 8.40985 5.41418 8.33628 5.37924 8.25495C5.3443 8.17361 5.32591 8.08613 5.32514 7.99761C5.32437 7.90909 5.34124 7.82131 5.37476 7.73938C5.40828 7.65745 5.45779 7.58301 5.52038 7.52042C5.58298 7.45782 5.65741 7.40832 5.73934 7.3748C5.82127 7.34128 5.90906 7.32441 5.99758 7.32518C6.0861 7.32595 6.17358 7.34434 6.25491 7.37928Z" fill="#007B65"/>
    </svg>

  );
};

export default SuccessMarketPlaceIcon;
