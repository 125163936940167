import React from 'react';
import PropTypes from 'prop-types';
import { Table, TextInput } from 'evergreen-ui';
import APIContentContainer from './APIContentContainer';
import KeyValuePairTableRow from './KeyValuePairTableRow';

const TableHeader = ({ header1, header2 }) => {
  return (
    <Table.Head className='mt-15px'>
      <Table.TextHeaderCell>{header1}</Table.TextHeaderCell>
      <Table.TextHeaderCell>{header2}</Table.TextHeaderCell>
      <Table.TextHeaderCell flexBasis={60} flexShrink={0} flexGrow={0}>
        &nbsp;
      </Table.TextHeaderCell>
    </Table.Head>
  );
};

const ClickAbleRow = ({ handleCreatePair }) => {
  return (
    <Table.Row className='key-value-pair'>
      <Table.TextCell
        onClick={handleCreatePair}
        className='key-value-pair__item'
      >
        <TextInput
          disabled={true}
          className='key-value-pair__inputs'
          placeholder='Create New Key'
        />
      </Table.TextCell>
      <Table.TextCell
        onClick={handleCreatePair}
        className='key-value-pair__item'
      >
        <TextInput
          disabled={true}
          className='key-value-pair__inputs'
          placeholder='Create New Value'
        />
      </Table.TextCell>
      <Table.TextCell
        onClick={handleCreatePair}
        flexBasis={60}
        flexShrink={0}
        flexGrow={0}
        className='key-value-pair'
      >
        &nbsp;
      </Table.TextCell>
    </Table.Row>
  );
};

const APIKeyValuePair = ({
  title,
  subtitle,
  data,
  handleCreatePair,
  attributeList,
  handleChangeKeyValuePair,
  handleDeleteKeyValuePair,
  hasWriteAccess,
}) => {
  const handleKeyValCreate = () => {
    handleCreatePair();
    setTimeout(() => {
      const id = `#api-fuse-section-${title.toLowerCase()}`;
      const selector = `${id} .key-value-pair:nth-child(${data.length +
        4}) .key-value-pair__item:first-child input`;
      document.querySelector(selector).focus();
    }, 0);
  };

  return (
    <APIContentContainer title={title} subtitle={subtitle}>
      <TableHeader header1={'Key'} header2={'Value'} />
      {data.length > 0 &&
        data.map((pair, i) => (
          <KeyValuePairTableRow
            key={i}
            index={i}
            attributeList={attributeList}
            handleChangeKeyValuePair={handleChangeKeyValuePair}
            handleDeleteKeyValuePair={handleDeleteKeyValuePair}
            keyElem={pair.key}
            value={pair.value}
            hasWriteAccess={hasWriteAccess}
          />
        ))}
      {hasWriteAccess && <ClickAbleRow handleCreatePair={handleKeyValCreate} />}
    </APIContentContainer>
  );
};

APIKeyValuePair.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  data: PropTypes.array,
  handleCreatePair: PropTypes.func,
  handleChangeKeyValuePair: PropTypes.func,
  handleDeleteKeyValuePair: PropTypes.func,
  attributeList: PropTypes.arrayOf(PropTypes.string),
  hasWriteAccess: PropTypes.bool,
};

APIKeyValuePair.defaultProps = {
  title: 'Default',
  subtitle: 'provide a long valid description what it do',
  hasWriteAccess: false,
  handleCreatePair: () => console.log('pass a props as onAdd to have a action'),
};
export default APIKeyValuePair;
