import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CloneDeep from 'lodash/cloneDeep';
import { Badge, Button } from 'evergreen-ui';
import moment from 'moment';

const CellDetailsView = ({ data, fields }) => {
  const [IgnoreList] = useState([
    'id',
    'lab_id',
    'creator_id',
    'source',
    'mongo_timestamp',
  ]);
  const [modifiedField, setModifiedField] = useState(null);
  const [modifiedData, setModifiedData] = useState(null);

  useEffect(() => {
    // modify fields
    const fieldLocal = fields.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: { name: cur.name, type: cur.type, options: cur.options },
      }),
      {}
    );

    setModifiedField(fieldLocal);
    // remove not Mappable Data
    let modifiedDataLocal = CloneDeep(data[0]);
    IgnoreList.forEach((key) => {
      delete modifiedDataLocal[key];
    });
    setModifiedData(modifiedDataLocal);
  }, [IgnoreList, fields, data]);

  const typeBasedReturnData = (elem, type, options) => {
    switch (type) {
      case 'image':
        return (
          <img
            src={elem}
            alt={elem}
            style={{ width: '100%', borderRadius: 5 }}
          />
        );
      case 'file':
        return (
          <Button
            is='a'
            target='_blank'
            href={elem}
            appearance='minimal'
            intent='success'
          >
            Download File
          </Button>
        );
      case 'singleselect':
        return options.length === 0 ? (
          elem
        ) : (
          <div>
            {options.map((option, i) => (
              <Badge
                key={i}
                isSolid={option === elem}
                marginRight={10}
                color={option === elem ? 'green' : 'red'}
              >
                {option}
              </Badge>
            ))}
          </div>
        );
      case 'multiselect':
        return options.length === 0 ? (
          elem
        ) : (
          <div>
            {options.map((option, i) => (
              <Badge
                key={i}
                isSolid={elem.includes(option)}
                marginRight={10}
                color={elem.includes(option) ? 'green' : 'red'}
              >
                {option}
              </Badge>
            ))}
          </div>
        );
      case 'date':
        return <div>{moment(elem).format('ll')}</div>;
      case 'datetime':
        return <div>{moment(elem).format('lll')}</div>;
      default:
        return elem;
    }
  };

  return (
    <div className='data-lab-details__cell-details'>
      <div className='data-lab-details__cell-details-info'>
        {IgnoreList.map((items, i) => (
          <div key={i}>
            <Badge color='neutral'>
              {items === 'mongo_timestamp' ? 'Entry Time' : items}{' '}
            </Badge>
            &nbsp;
            <span>
              {!!data[0][items] &&
                (items === 'mongo_timestamp'
                  ? moment(data[0][items] * 1000).format('lll')
                  : data[0][items])}
            </span>
          </div>
        ))}
      </div>
      {!!modifiedData &&
        Object.keys(modifiedData).map(
          (elem, i) =>
            Object.keys(modifiedField).filter((key) => key === elem).length !==
              0 && (
              <div className='data-lab-details__cell-details-item' key={i}>
                <div className='data-lab-details__cell-details-item-type'>
                  <Badge color='green'>{modifiedField[elem].type}</Badge>
                </div>
                <p className='data-lab-details__cell-details-item-title'>
                  {modifiedField[elem].name}
                </p>
                <div>
                  {typeBasedReturnData(
                    modifiedData[elem],
                    modifiedField[elem].type,
                    modifiedField[elem].options
                  )}
                </div>
              </div>
            )
        )}
    </div>
  );
};

CellDetailsView.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  fields: PropTypes.arrayOf(PropTypes.object),
};

export default CellDetailsView;
