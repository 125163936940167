import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './assets/styles/style.scss';
import WebFont from 'webfontloader';
import dotenv from 'dotenv';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { env } from './config';
import { initAmplitude } from './utilities/amplitude';

dotenv.config();

if (env === ('production' || 'staging') && !!process.env.REACT_APP_DSN_SENTRY) {
  Sentry.init({
    dsn: process.env.REACT_APP_DSN_SENTRY,
    integrations: [new Integrations.BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

// Amplitude init
initAmplitude();

WebFont.load({
  google: {
    families: [
      'Poppins:300,400,500,600,700',
      'sans-serif',
      'Roboto: 300,400,500,600,700',
    ],
  },
});

if (window.navigator.platform === 'Win32') {
  document.body.classList.add('windows');
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
