import React from 'react';
import {Button, InlineAlert, TextInput} from "evergreen-ui";

interface Prop {
  storeInvalid: string;
  storeURL: string;
  setStoreURL: (val: string) => void;
  handleStoreChange: (val: string) => void;
}

const compName: React.FC<Prop> = ({storeInvalid, storeURL, setStoreURL,handleStoreChange}) => {

  return (
    <>
      <div className='woocommerce-modal__content'>
        <p className='woocommerce-modal__content-title'>
          Store URL
        </p>
        <p className='woocommerce-modal__content-subtitle'>
          Please provide your WooCommerce store/website URL below to start the integration process.
        </p>
        <TextInput
          className='rd-input-style mt-10px w-100p'
          placeholder='Paste or type the link here'
          isInvalid={!!storeInvalid}
          value={storeURL}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setStoreURL(e.target.value)}
        />
        {!!storeInvalid &&
        <div className='mt-5px'>
          <InlineAlert intent="danger">
            {storeInvalid}
          </InlineAlert>
        </div>
        }
      </div>

      <div className='w-100p flex flex-end'>
        <Button className='mt--70px alice-btn__secondary' onClick={handleStoreChange}>Next</Button>
      </div>
    </>
  );
};

export default compName;
