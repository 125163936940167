import React from 'react';
import InfoMarketPlaceIcon from "../../assets/icons/marketplace/InfoMarketPlaceIcon";
import SuccessMarketPlaceIcon from "../../assets/icons/marketplace/SuccessMarketPlaceIcon";
import DefaultMarketPlaceIcon from "../../assets/icons/marketplace/DefaultMarketPlaceIcon";


interface Prop {
  text: string;
  intent?: 'success' | 'info' | 'default'
}

const MarketPlaceTag: React.FC<Prop> = (
  {
    text,
    intent,
  }) => {

  return (
    <div
      className={`alice-tag default-bg flex align-center`}
      >
      {intent === 'success'?<SuccessMarketPlaceIcon /> : intent === 'info' ? <InfoMarketPlaceIcon /> : intent === 'default' ? <DefaultMarketPlaceIcon /> : ''}
      &nbsp;<p className={`marketplace-text-${intent}`}>{text || 'N/A'}</p>
    </div>
  )
};

export default MarketPlaceTag;
