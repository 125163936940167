import React from 'react';
import {
  Table,
  Avatar,
  Button,
  TextInput,
  SelectMenu,
  Icon,
} from 'evergreen-ui';
import defaultAvatar from './../../../assets/images/defaultAvatar.svg';

interface RoleProps {
  label: string;
  value: number;
}

interface props {
  userRolesType: { id: number; name: string }[];
  shareEmail: string;
  updateEmail: (value: string) => void;
  adminRole: RoleProps;
  setAdminRole: (role: RoleProps) => void;
  shareProject: any;
  shareProjectLoader: boolean;
  resetFields: () => void;
}

const PermissionProjectShare: React.FC<props> = ({
  userRolesType,
  shareEmail,
  updateEmail,
  adminRole,
  setAdminRole,
  shareProject,
  shareProjectLoader,
  resetFields,
}) => {
  return (
    <Table.Row display={'flex'}>
      <Table.TextCell className='avatar-cell' flexBasis={'45%'}>
        <Avatar src={defaultAvatar} name={'New User'} className='mr-10px' />
        <TextInput
          style={{ boxShadow: 'none', width: '100%' }}
          value={shareEmail}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            updateEmail(event.target.value)
          }
          placeholder='Provide Email to add a New Admin'
        />
      </Table.TextCell>
      <Table.TextCell flexBasis={'30%'}>
        <div className='select-menu__default'>
          <SelectMenu
            height={140}
            width={200}
            closeOnSelect={true}
            title='Select Role'
            hasFilter={false}
            options={userRolesType.map((type) => ({
              label: type.name,
              value: type.id,
            }))}
            selected={adminRole?.toString()}
            onSelect={(item: RoleProps) => setAdminRole(item)}
          >
            <Button height={26} className='role-button'>
              {adminRole.label || 'Select Role...'}&nbsp;&nbsp;
              <Icon icon='chevron-down' size={16} />
            </Button>
          </SelectMenu>
        </div>
      </Table.TextCell>
      <Table.Cell flexBasis={'25%'}>
        <Button
          isLoading={shareProjectLoader}
          onClick={async () => {
            const result = await shareProject(shareEmail, adminRole.value);
            if(result)resetFields();
          }}
          iconBefore={shareProjectLoader ? null : 'share'}
          height={26}
          className='button-alice__gradient flex justify-center w-100p'
        >
          Share
        </Button>
      </Table.Cell>
    </Table.Row>
  );
};

export default PermissionProjectShare;
