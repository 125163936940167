import React from 'react';
import BlockContainer from './BlockContainer';
import icon from './../../pages/builder/assets/images/icons/ticket.svg';
import Select, {components} from "react-select";
import TitleHolderBuilder from "../elements/TitleHolderBuilder";
import {Checkbox, Textarea} from "evergreen-ui";
import {updateOnChangeText, updateOnSelectAttribute} from '../../utils';
import AttributeAPIContainer from '../elements/AttributeAPIContainer';
import TextCharInfo from '../elements/TextCharInfo';

interface props {
  id: number;
  save: boolean;
  type: string;
  saveBlock: any;
  attributeList: any;
  apiList: any;
  deleteBlock: any;
  sequences: any;
  copyBlock: any;
  moveBlock: any;
  saveLoader: boolean;
  hasWriteAccess: boolean;
  agentList: any;
  agentGroupList: any;
  agents: any;
  updateAgent: (value: any) => void;
  agentTag: any;
  updateAgentTag: (value: any) => void;
  priority: number | string;
  updatePriority: (value: any) => void;
  note: string;
  updateNote: (value: any) => void;
  agentIds: any;
  resolveSequence: any;
  updateResolveSequence: (value: any) => void;
  updateNoAgentGroup: () => void;
  disabledBot: boolean;
  updateDisabledBot: (checked: boolean) => void;
}


const Group = (props: any) => (
  <div>
    <components.Group {...props} />
  </div>
);


const TicketBlock: React.FC<props> = (
  {
    id,
    save,
    type,
    saveBlock,
    attributeList,
    apiList,
    deleteBlock,
    sequences,
    copyBlock,
    moveBlock,
    saveLoader,
    hasWriteAccess,
    agentList,
    agentGroupList,
    agents,
    updateAgent,
    agentTag,
    updateAgentTag,
    updateNoAgentGroup,
    disabledBot,
    updateDisabledBot,
    priority,
    updatePriority,
    note,
    updateNote,
    resolveSequence,
    updateResolveSequence,
  }) => {
  const [showAttribute, setShowAttribute] = React.useState(false);
  const [showAPI, setShowAPI] = React.useState(false);
  const maxCount = 620;

  const getAgentOrGroups = () => {
    let agentsListLocal: any = [];
    let groupsListLocal: any = [];


    if (!!agentList) {
      agentList.forEach((elem: any) => {
        agentsListLocal = [...agentsListLocal, {
          ...elem,
          value: elem.admin.id,
          label: elem.admin.full_name,
          type: 'agent'
        }]
      })
    }
    if (!!agentGroupList) {
      agentGroupList.forEach((group: any) => {
        groupsListLocal = [...groupsListLocal, {...group, value: group.id, label: group.name, type: 'group'}]
      })
    }
    let data = [
      {
        label: "General",
        options: [{value: null, label: 'Unassigned', type: 'general'}]
      },
      {
        label: 'Agents',
        options: agentsListLocal,
      },
      {
        label: 'Groups',
        options: groupsListLocal,
      },
    ]
    return data;
  }

  const getValueOfAgentGroup = () => {
    let localData: any = null;
    if (agentTag.length > 0) {
      localData = [{value: agentTag[0].id, label: agentTag[0].name}];
    } else if (agents.length > 0) {
      localData = [{value: agents[0].id, label: agents[0].full_name}];
    }
    return localData;
  }

  return (
    <BlockContainer
      id={id}
      title='Ticket'
      subtitle='Ticket Block Allows to create a ticket to CRM'
      save={save}
      isLoading={saveLoader}
      icon={icon}
      handleSaveClick={() => saveBlock(id)}
      handleDelete={() => deleteBlock(id)}
      sequences={sequences}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <div>
        <div className='flex'>
          <div className='flex-1'>
            <div className='flex align-center space-between'>
              <TitleHolderBuilder
                title={'SELECT AGENT/GROUP'}
                tooltipInfo={'Select the Admin or Groups you want to assign'}/>
              <div className='flex align-center mb-10px'>
                <Checkbox
                  checked={!disabledBot}
                  className='rd-input-style-checkbox'
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    updateDisabledBot(!event.target.checked)
                  }}/> &nbsp;<span style={{fontSize: '0.75rem', fontWeight: 500}}>Turn on bot?</span>
              </div>
            </div>
            <Select
              className='rules-select mt--15px'
              isSearchable={true}
              value={getValueOfAgentGroup()}
              classNamePrefix='rules-select'
              components={{Group}}
              onChange={(option: any) => {
                if (option.type === 'agent') {
                  updateAgent(option);
                } else if (option.type === 'group') {
                  updateAgentTag(option);
                } else {
                  updateNoAgentGroup();
                }
              }}
              placeholder={'Select Agents/Group'}
              options={getAgentOrGroups()}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#eebb4d4d',
                  primary: '#eebb4d',
                  primary50: '#eebb4d'
                },
              })}
            />
          </div>
        </div>
        <div className='flex mt-20px'>
          <div className='flex-1'>
            <div className="mr-10px">
              <TitleHolderBuilder title={'SET PRIORITY'} tooltipInfo={'Set the priority of ticket'}/>
              <Select
                className='rules-select'
                isSearchable={true}
                value={priority === 0 ? {label: 'Low', value: 0} : priority === 1 ? {
                  label: 'Medium',
                  value: 1
                } : priority === 2 ? {label: 'High', value: 2} : null}
                classNamePrefix='rules-select'
                onChange={(option: any) => {
                  updatePriority(option);
                }}
                placeholder={'Select Agents/Group'}
                options={[
                  {label: 'Low', value: 0},
                  {label: 'Medium', value: 1},
                  {label: 'High', value: 2},
                ]}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#eebb4d4d',
                    primary: '#eebb4d',
                    primary50: '#eebb4d'
                  },
                })}
              />
            </div>
          </div>
          <div className='flex-1'>
            <TitleHolderBuilder title={'Sequence'} tooltipInfo={'Set Resolved Sequence'}/>
            <Select
              className='rules-select'
              isSearchable={true}
              value={!!resolveSequence ? {label: resolveSequence.title, value: resolveSequence.id} : null}
              classNamePrefix='rules-select'
              onChange={(option: any) => {
                updateResolveSequence({id: option.value, title: option.label})
              }}
              placeholder={'Select Sequence'}
              options={sequences.map((seq: any) => ({
                label: seq.title,
                value: seq.id.toString(),
              }))}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#eebb4d4d',
                  primary: '#eebb4d',
                  primary50: '#eebb4d'
                },
              })}
            />
          </div>
        </div>
        <div className="flex flex-column mt-20px">
          <TitleHolderBuilder title={'Notes'} tooltipInfo={'Provide Notes for this ticket'}/>
          <AttributeAPIContainer
            attributeList={attributeList}
            showAttribute={showAttribute}
            setShowAttribute={setShowAttribute}
            apiList={apiList.filter((api: any) => api.return_type === type)}
            showAPI={showAPI}
            setShowAPI={setShowAPI}
            handleSelect={(value) => {
              (note + value).length + 2 <= maxCount &&
              updateOnSelectAttribute(
                value,
                showAttribute,
                showAPI,
                setShowAttribute,
                setShowAPI,
                updateNote,
                note,
                apiList
              );
            }}
          >
            <Textarea
              required={true}
              grammarly={true}
              spellCheck={true}
              className='custom-input-style'
              value={note}
              disabled={!hasWriteAccess}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                e.target.value.length <= maxCount &&
                updateOnChangeText(
                  e.target.value,
                  setShowAttribute,
                  setShowAPI,
                  updateNote
                )
              }
              placeholder='Write Notes Here...'
            />
          </AttributeAPIContainer>
          <TextCharInfo data={note} count={maxCount}/>
          <br/>
        </div>

      </div>
    </BlockContainer>
  );
};

TicketBlock.defaultProps = {
  deleteBlock: (blockId: number, SequenceId: number) =>
    console.log('delete from default props', blockId, SequenceId),
};

export default TicketBlock;
