import React from 'react';
import { Tooltip as ToolTipEvergreen, Icon, Spinner } from 'evergreen-ui';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import PropTypes from 'prop-types';

const ReportingLineChart = ({ title, toolTipInfo, data, loader }) => {
  return (
    <div className='reporting-container__user-card-container-elem'>
      <div className='reporting-container__user-card-container-elem-data'>
        <div className='reporting-title-container'>
          <span>{title}</span>&nbsp;
          <ToolTipEvergreen content={toolTipInfo}>
            <Icon color='success' icon={'info-sign'} size={16} />
          </ToolTipEvergreen>
        </div>
        <br />
        {!loader && (
          <div className='flex align-center space-evenly'>
            <LineChart
              width={window.innerWidth / 2 - 120}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray='6 6' />
              <XAxis dataKey='name' />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type='monotone'
                dataKey='textual_message'
                stroke='#eebb4d'
                activeDot={{ r: 8 }}
              />
              <Line type='monotone' dataKey='automated' stroke='#82ca9d' />
            </LineChart>
          </div>
        )}
        {loader && (
          <div className='h-300px flex align-center justify-center'>
            {' '}
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};
ReportingLineChart.propTypes = {
  title: PropTypes.string,
  toolTipInfo: PropTypes.string,
  data: PropTypes.any,
  loader: PropTypes.bool,
};
export default ReportingLineChart;
