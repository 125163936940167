import React from 'react';
import Collapse from "../../../../../components/elements/redesign/Collapse";
import TagRedesign from "../../../../../components/elements/redesign/TagRedesign";
import CopyText from "../../../../../components/elements/redesign/CopyText";
import OrderCardSummary from "./OrderCardSummary";
import OrderCardProduct from "./OrderCardProduct";
import dayJs from "dayjs";

interface Prop {
  orderId: string | number;
  orderLink: string;
  orderDate: string;
  totalCost: string;
  paymentMethod: string;
  billingInfo: string;
  shippingInfo: string;
  totalTax: string;
  shippingCost: string;
  discount: string;
  products: any[];
  shippingMethod: string;
  isPaid: boolean;
  isCompleted: boolean;
  status:string;
  refund: string;
  coupon: string[];
}

const OrderCardRightbar: React.FC<Prop> = (
  {orderId,
    orderLink,
    orderDate,
    totalCost,
    paymentMethod,
    billingInfo,
    shippingInfo,
    totalTax,
    shippingCost,
    discount,
    products,
    shippingMethod,
    isPaid,
    isCompleted,
    status,
    refund,
    coupon,
  }) => {

  return (
    <div className='order-right-bar__order-card'>
      <div className='order-right-bar__order-card__header'>
        <div className='flex flex-column'>
            <a href={orderLink || window.location.href} target='_blank' rel="noreferrer noopener" className='order-right-bar__order-card__header-title link-color'>#ORDER-{orderId || 'N/A'}</a>
            <p className='order-right-bar__order-card__header-subtitle'>{dayJs(orderDate).format('DD MMM YYYY')}</p>
        </div>
       <div>
         <div className='order-right-bar__order-card__bill-amount'>${totalCost}</div>
         <div className='flex align-center'>
           <TagRedesign data={{value: isPaid? "paid": "unpaid", label: isPaid? "Paid": "Unpaid"}} size={12} intent='default' />
           <TagRedesign data={{value: isCompleted? "completed": status, label: isCompleted? "Completed": status}} size={12} intent='active' />
         </div>
       </div>
      </div>
      <div className='flex pd-10px pdt-0px align-center'>
        <p className="order-right-bar__order-card__content-title">Payment Method:</p>&nbsp;
        <p className='order-right-bar__order-card__content-info'>{paymentMethod}</p>
        &nbsp;&nbsp;<CopyText height={26} textToCopy={paymentMethod}/>
      </div>
      <Collapse
        header='Summary'
        isOpen={false}
        hasCollapseBorder={true}
        titleFontSize={12}
        hasSharpEdge={true}
      >
        <OrderCardSummary
          billingInfo={billingInfo}
          shippingInfo={shippingInfo}
          totalTax={totalTax}
          totalCost={totalCost}
          shippingCost={shippingCost}
          discount={discount}
          shippingMethod={shippingMethod}
          refund={refund}
          coupon={coupon}
        />
      </Collapse>
      <Collapse
        header='Products'
        isOpen={false}
        hasCollapseBorder={true}
        titleFontSize={12}
        hasSharpEdge={true}
      >
       <OrderCardProduct products={products} />
      </Collapse>

    </div>
  );
};

export default OrderCardRightbar;
