import React from 'react';
import {IconButton, Switch} from 'evergreen-ui';

interface props {
  channelList: any[];
  onConnectionChange: (status: boolean) => void;
  onEditClick: (channel: any) => void;
  setConnectableChannel: (val: any) => void;
  type: string;
}

const WebPluginChannel: React.FC<props> = (
  {
    channelList,
    onConnectionChange,
    onEditClick,
    setConnectableChannel,
    type,
  }) => {
  return (
    <div className='channel-settings__element'>
      {channelList.length !== 0 && (
        <div className='channel-settings__card-holder-elem bg-white'>
          <div className='channel-settings__card-holder-elem-item'>Title</div>
          <div className='channel-settings__card-holder-elem-item'>
            {type === 'webchat' ? 'Website' : 'Description'}
          </div>
          <div className='channel-settings__card-holder-elem-item'>
            Primary Color
          </div>
          <div className='channel-settings__card-holder-elem-item'>Edit</div>
          <div className='channel-settings__card-holder-elem-item'>
            Connection
          </div>
        </div>
      )}
      {channelList.map((channel: any, index: number) => (
        <div className='channel-settings__card-holder-elem' key={index}>
          <div className='channel-settings__card-holder-elem-item fs-12px'>
            {channel.title || 'Not Available'}
          </div>
          <div className='channel-settings__card-holder-elem-item fs-12px'>
            {type === 'webchat' && (
              <a
                className='link-settings'
                href={channel.url}
                target='_blank'
                rel='noopener noreferrer'
              >
                {channel.whitelisted_domains.length > 0
                  ? channel.whitelisted_domains[0]
                  : 'Not Available'}
              </a>
            )}
            {type === 'app_messenger' &&
            (channel.description || 'Not Available')}
          </div>
          <div className='channel-settings__card-holder-elem-item fs-12px'>
            <div
              style={{
                width: 20,
                height: 20,
                borderRadius: 20,
                border: '0.5px solid #ccc',
                background: `${channel.primary_color || '#ffffff00'}`,
              }}
            />
          </div>
          <div className='channel-settings__card-holder-elem-item fs-12px'>
            <IconButton
              className='icon-button__no-box-shadow'
              icon={'edit'}
              height={26}
              appearance='minimal'
              intent={'success'}
              onClick={() => onEditClick(channel)}
            />
          </div>
          <div className='channel-settings__card-holder-elem-item fs-12px'>
            <Switch
              className='active-switch-green'
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onConnectionChange(event.target.checked);
                setConnectableChannel(channel);
              }}
              hasCheckIcon={false}
              checked={channel.is_connected}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default WebPluginChannel;
