import React from 'react';
import {
  Tooltip,
  Button,
  Popover,
  Icon,
  Menu,
  Table,
  Spinner,
  SearchInput,
  Avatar, Badge, Label,
} from 'evergreen-ui';
import dayJs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import {getUpdatedPlatformIconOutlined} from './../../../utils';

dayJs.extend(LocalizedFormat);

interface props {
  title: string;
  toolTipInfo: string;
  tableData: any[];
  loader: boolean;
  group: any[];
  defaultGroup: any;
  onSelect: (group: any) => void;
  exportData: () => void;
  exportLoader: boolean;
}

const ReportingTicketTable: React.FC<props> = (
  {
    title,
    toolTipInfo,
    tableData,
    loader,
    group,
    defaultGroup,
    onSelect,
    exportData,
    exportLoader,
  }) => {
  const [searchTicket, setSearchTicket] = React.useState('');
  return (
    <div className='reporting-container__user-row-card flex-column pdb-20px'>
      <div className='reporting-title-container'>
        <span>{title}</span>&nbsp;
        <Tooltip content={toolTipInfo}>
          <Icon color='success' icon={'info-sign'} size={16}/>
        </Tooltip>
      </div>
      <br/>
      <div className='reporting-container__ticket-table-header'>
        <div className='reporting__tab-based-dropdown'>
          <div className='reporting__tab-based-dropdown__name'>Responder</div>
          {group.length > 0 && (
            <Popover
              content={({close}) => (
                <Menu>
                  <Menu.Group title='Role'>
                    <Menu.Item
                      onSelect={() => {
                        onSelect({
                          id: null,
                          email: '',
                          avatar: null,
                          full_name: 'All',
                        });
                        close();
                      }}
                      key={-1}
                    >
                      All
                    </Menu.Item>
                    {!!group &&
                    group.map((group: any) => (
                      <Menu.Item
                        onSelect={() => {
                          onSelect(group);
                          close();
                        }}
                        key={group.id}
                      >
                        {group.full_name}
                      </Menu.Item>
                    ))}
                  </Menu.Group>
                </Menu>
              )}
            >
              <Button className='reporting__tab-based-dropdown__button'>
                <div>{defaultGroup?.full_name || 'Not Available'}</div>
                <div>
                  <Icon icon='caret-down' size={16}/>
                </div>
              </Button>
            </Popover>
          )}
        </div>
        <div className='flex'>
          <div>
            <SearchInput
              placeholder='Search Here...'
              value={searchTicket}
              disabled={tableData.length === 0}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchTicket(e.target.value)
              }
            />
          </div>
          <div>
            <Button
              marginLeft={10}
              className='primary-tinted-button'
              isLoading={exportLoader}
              onClick={() => {
                exportData();
              }}
            >
              {!exportLoader && (
                <>
                  <Icon icon={'cloud-download'} size={16}/>
                  &nbsp;
                </>
              )}{' '}
              Export CSV
            </Button>
          </div>
        </div>
      </div>
      <div className='reporting-container__ticket-table'>
        <Table>
          <Table.Head>
            <Table.TextHeaderCell flexBasis={130} flexShrink={0} flexGrow={0}>Ticket ID</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={120} flexShrink={0} flexGrow={0}>
              Channel
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>Tags</Table.TextHeaderCell>
            <Table.TextHeaderCell>Customer</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={150} flexShrink={0} flexGrow={0}>Created Time</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={120} flexShrink={0} flexGrow={0}>Resolved Time</Table.TextHeaderCell>
            <Table.TextHeaderCell>Resolved By</Table.TextHeaderCell>
          </Table.Head>
          {!loader && (
            <Table.Body height={240}>
              {!!tableData &&
              tableData
                .filter(
                  (elem: any) =>
                    !!elem.id &&
                    elem.id
                      .toString()
                      .toLowerCase()
                      .includes(searchTicket.toLowerCase())
                )
                .map((ticket: any, index: number) => (
                  <Table.Row key={index}>
                    <Table.TextCell flexBasis={130} flexShrink={0} flexGrow={0}>
                      <div className='hightlighted-bg-blue'>
                        <b>{ticket.id}</b>
                      </div>
                    </Table.TextCell>
                    <Table.Cell flexBasis={100} flexShrink={0} flexGrow={0}>
                      <img src={getUpdatedPlatformIconOutlined(ticket?.customer?.platform?.type)} alt={'channel'}
                           width={20}/> &nbsp; <Tooltip content={ticket?.customer.platform?.name || 'Not Available'}><Label>{(ticket?.customer.platform?.name?.length > 10 ? ticket?.customer.platform?.name.substring(0,10)+"..." : ticket?.customer.platform?.name)  || 'Not Available'}</Label></Tooltip>
                    </Table.Cell>
                    <Table.Cell>
                      {ticket.tags.length === 0 && 'Not Available'}
                      {ticket.tags.length > 0 && <Badge color="green">{ticket.tags[0].name}</Badge>}&nbsp;
                      {ticket.tags.length > 1 && <Badge color='green' isSolid>{ticket.tags.length - 1}+</Badge>}
                    </Table.Cell>
                    <Table.TextCell>
                      <div className='flex align-center'>
                        <Avatar height={26} src={ticket.customer?.avatar}
                                name={ticket.customer?.full_name || 'Unknown User'}/> &nbsp;&nbsp;
                        {ticket.customer?.full_name || 'Unknown User'}
                      </div>
                    </Table.TextCell>
                    <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0}>
                      {dayJs(ticket.created_at * 1000).format('lll')}
                    </Table.TextCell>
                    <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0}>
                      {(!!ticket.resolved_time ? `${ticket.resolved_time} min` : 'N/A')}
                    </Table.TextCell>
                    <Table.TextCell>
                      {' '}
                      {!!ticket.resolved_by?.full_name ?
                        <div className='hightlighted-bg'>
                          {ticket.resolved_by.full_name}
                        </div> :
                        <div className='hightlighted-bg-danger'>
                          Not Resolved
                        </div>
                      }
                    </Table.TextCell>
                  </Table.Row>
                ))}
              {tableData.length === 0 && (
                <Table.Row className={'flex align-center justify-center'}>
                  No Data Available
                </Table.Row>
              )}
            </Table.Body>
          )}
          {loader && (
            <Table.Body height={240}>
              <div className='flex align-center justify-center h-200px'>
                <Spinner/>
              </div>
            </Table.Body>
          )}
        </Table>
      </div>
    </div>
  );
};

export default ReportingTicketTable;
