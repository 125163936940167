import React, { Component, Fragment } from 'react';
import ProUser from '../../components/ProUser';
import './asssets/style/style.scss';
import Pagination from '../../components/Pagination';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import AudienceTable from './components/AudienceTable';
import FilterTable from './components/FilterTable';
import HasPermission from '../../components/HasPermission';
import { checkPermission } from '../../utils';

class Audience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      limit: 20,
      offset: 0,
      loading: true,
      condition: [],
      mockAudienceData: [],
      showProModal: false,
      readAccess: 'read:audience',
    };
  }

  async initInitialData() {
    ReactGA.pageview(
      window.location.pathname + window.location.search + window.location.hash
    );
    await this.props.fetchProject();
    if (this.props.projects.length > 0) {
      const project = this.props.projects.filter(
        (p) => parseInt(this.props.projectId) === p.id
      )[0];
      await this.props.setSelectedProject(project);
      if (project.platforms.length > 0) {
        await this.props.setSelectedPlatform(project.platforms[0]);
      }
    }
    const hasPermission = checkPermission(
      this.props.permissionList,
      this.state.readAccess
    );

    if (hasPermission) {
      await this.props.fetchFilterInformation(
        this.props.selectedProject.id,
        this.props.selectedPlatform.id
      );
      this.props.updateSelectedNav(7);
      this.fetchAudienceInformation(
        this.state.limit,
        this.state.offset,
        this.state.condition
      );
    }
  }

  fetchAudienceInformation = async (limit, offset, condition) => {
    const body = {
      project_id: this.props.selectedProject.id,
      platform_id: this.props.selectedPlatform.id,
      conditions: condition,
      limit: limit,
      offset: offset,
    };
    await this.props.fetchAudience(body);
    this.setState({
      loading: false,
      offset: offset,
      mockAudienceData: this.props.AudienceData,
    });
  };
  handlePaginationChange = async (page) => {
    await this.setState({
      loading: true,
      currentPage: page,
    });
    this.fetchAudienceInformation(
      this.state.limit,
      (page - 1) * this.state.limit,
      this.state.condition
    );
  };

  addNewCondition = () => {
    this.setState({
      condition: [
        ...this.state.condition,
        { attribute: '', operator: '', value: '' },
      ],
    });
  };

  handleConditionOnChange = (value, type, index) => {
    let localCondition = [
      ...this.state.condition.slice(0, index),
      { ...this.state.condition[index], [type]: value },
      ...this.state.condition.slice(index + 1),
    ];

    this.setState({
      condition: localCondition,
    });
  };
  submitFilter = () => {
    const filteredData = this.state.condition.filter(
      (condition) => condition.value !== ''
    );
    this.setState(
      {
        condition: filteredData,
      },
      () => {
        this.fetchAudienceInformation(
          this.state.limit,
          this.state.offset,
          filteredData
        );
      }
    );
  };
  handleNameSearch = (name) => {
    this.setState(
      {
        condition: [
          { attribute: 'full_name', operator: 'contains', value: name },
        ],
        limit: 20,
        offset: 0,
      },
      () => {
        this.fetchAudienceInformation(
          this.state.limit,
          this.state.offset,
          this.state.condition
        );
      }
    );
  };

  handlePlatformChange = (value) => {
    console.log(value, this.props.selectedProject);
    this.setState({ loading: true, limit: 20, offset: 0 }, async () => {
      await this.props.setSelectedPlatform(value);
      this.fetchAudienceInformation(
        this.state.limit,
        this.state.offset,
        this.state.condition
      );
      await this.props.fetchFilterInformation(
        this.props.selectedProject.id,
        this.props.selectedPlatform.id
      );
    });
  };

  handleProModalCancel = () => {
    this.setState({ showProModal: false });
  };

  exportAudienceData = async () => {
    if (this.props.isPro) {
      const body = {
        project_id: this.props.selectedProject.id,
        platform_id: this.props.selectedPlatform.id,
        conditions: this.state.condition,
        export: true,
      };
      await this.props.exportAudience(body);
    } else {
      this.setState({ showProModal: true });
    }
  };

  componentDidMount() {
    this.initInitialData();
  }
  render() {
    return (
      <HasPermission shortCode={this.state.readAccess}>
        <Fragment>
          <div className='layout-body__no-scroll'>
            {this.state.showProModal && (
              <ProUser handleCancel={() => this.handleProModalCancel()} />
            )}
            <FilterTable
              condition={this.state.condition}
              filterInformation={this.props.filterInformation}
              setCondition={(data) => this.setState({ condition: data })}
              selectedProject={this.props.selectedProject}
              setSelectedPlatform={this.props.setSelectedPlatform}
              selectedPlatform={this.props.selectedPlatform}
              handlePlatformChange={this.handlePlatformChange}
              addCondition={this.addNewCondition}
              handleConditionOnChange={this.handleConditionOnChange}
              submitFilter={this.submitFilter}
              exportAudienceData={this.exportAudienceData}
              exportLoader={this.props.exportLoading}
              isProUser={this.props.isPro}
            />
            <div>
              <AudienceTable
                handleNameSearch={this.handleNameSearch}
                loading={this.state.loading}
                mockAudienceData={this.state.mockAudienceData}
              />
              {!this.state.loading && this.props.total > 19 && (
                <div className='mt-10px'>
                  <Pagination
                    align='left'
                    currentPage={this.state.currentPage}
                    totalPages={Math.ceil(this.props.total / this.state.limit)}
                    changeCurrentPage={(pageNo) =>
                      this.handlePaginationChange(pageNo)
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </Fragment>
      </HasPermission>
    );
  }
}

const mapState = (state) => ({
  isPro: state.dashboard.selectedProject.is_pro,
  projects: state.dashboard.projects,
  selectedProject: state.dashboard.selectedProject,
  selectedPlatform: state.dashboard.selectedPlatform,
  AudienceData: state.audience.AudienceData,
  total: state.audience.total,
  filterInformation: state.audience.filterInformation,
  exportLoading: state.loading.effects.audience.exportAudience,
  permissionList: state.dashboard.permission?.list || [],
});

const mapDispatch = (dispatch) => ({
  setSelectedProject: (project) =>
    dispatch.dashboard.setSelectedProject(project),
  setSelectedPlatform: (platform) =>
    dispatch.dashboard.setSelectedPlatform(platform),
  fetchAudience: (body) => dispatch.audience.fetchAudience(body),
  exportAudience: (body) => dispatch.audience.exportAudience(body),
  fetchFilterInformation: (projectId, platformId) =>
    dispatch.audience.fetchFilterInformation({ projectId, platformId }),
  updateSelectedNav: (navId) => dispatch.dashboard.updateSelectedNav(navId),
  fetchProject: () => dispatch.dashboard.fetchProject(),
});

const AudienceContainer = connect(mapState, mapDispatch)(Audience);

export default AudienceContainer;
