import React, { Fragment } from 'react';
import SettingsFormContainer from './SettingsFormContainer';
import { TextInputField, Textarea } from 'evergreen-ui';
import ImageUploadField from '../../../components/ImageUploadField';

const TeamInfoSettingsForm = ({
  title,
  description,
  infoLoader,
  infoUpdateLoader,
  isInfoChanged,
  handleSave,
  handleCancel,
  projectInfo,
  handleNameChange,
  handleDescChange,
  handleImageChange,
  handleImageClear,
  hasWriteAccess,
}) => {
  return (
    <SettingsFormContainer
      id='team-settings'
      title={title}
      description={description}
      infoLoader={infoLoader}
      hasData={!!projectInfo}
      infoUpdateLoader={infoUpdateLoader}
      isInfoChanged={isInfoChanged}
      handleSave={handleSave}
      handleCancel={handleCancel}
    >
      <Fragment>
        <TextInputField
          className='settings-input'
          label='Team Name'
          disabled={!hasWriteAccess}
          onChange={(e) => handleNameChange(e.target.value)}
          value={!!projectInfo ? projectInfo.name : ''}
          placeholder='Please provide name of the team...'
        />

        <p className='input-label'>Team Description</p>
        <Textarea
          className='settings-input'
          onChange={(e) => handleDescChange(e.target.value)}
          value={!!projectInfo ? projectInfo.description : ''}
          placeholder='write description here...'
        />
        <br />
        <br />

        <p className='input-label'>Team Avatar</p>
        <ImageUploadField
          hasWriteAccess={hasWriteAccess}
          image={!!projectInfo ? projectInfo.image : ''}
          handleDrop={(image) => handleImageChange(image)}
          handleClear={() => handleImageClear()}
        />
      </Fragment>
    </SettingsFormContainer>
  );
};

export default TeamInfoSettingsForm;
