import React, { Fragment } from 'react';
import { TextInput } from 'evergreen-ui';
import TitleHolderBuilder from './TitleHolderBuilder';

interface props {
  value: number;
    handleChange: (event: number | null) => void;
}

const ErrorCounterInput: React.FC<props> = ({ value, handleChange }) => {
  return (
    <Fragment>
        <TitleHolderBuilder title="Error Counter" tooltipInfo="Max wrong input bot can allow" />
      <TextInput
        type='number'
        value={!!value ? value : ''}
        width={'100%'}
        height={40}
        className="custom-input-style"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(parseInt(e.target.value))
        }
        placeholder='Provide value...'
      />
    </Fragment>
  );
};

export default ErrorCounterInput;
