import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'evergreen-ui';
import MaterialInput from '../../../components/elements/MaterialInput';
import AuthFormHeader from './AuthFormHeader';
import AuthFooter from './AuthFooter';
import SignUpConfirmation from './SignUpConfirmation';
import PasswordStrengthBar from 'react-password-strength-bar';
import Alert from '../../../components/elements/redesign/Alert';

const RegisterFormSection = ({
  email,
  password,
  duplicateEmail,
  content,
  handleChange,
  handleSubmit,
  loading,
}) => {
  return (
    <div className='form-container flex-1'>
      <div className='form-container__content'>
        <AuthFormHeader
          title={content.register.title}
          subtitle={content.register.subtitle}
        />
        <br />
        <div className='h-75px mb-5px'>
          <MaterialInput
            label='Email Address'
            type='text'
            name='email'
            value={email}
            onKeyPress={(e) => (e.key === 'Enter' ? handleSubmit() : null)}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
          {duplicateEmail ? (
            <Alert intent='danger' text='Email Already Exist' />
          ) : (
            ''
          )}
        </div>
        <MaterialInput
          label='Password'
          type='password'
          name='password'
          value={password}
          onKeyPress={(e) => (e.key === 'Enter' ? handleSubmit() : null)}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
        <PasswordStrengthBar
          password={password}
          minLength={5}
          scoreWordClassName='passwordScore'
        />
        <SignUpConfirmation
          linkInfo={content.signupConfirmationInfo.text}
          link={content.signupConfirmationInfo.link}
          linkText={content.signupConfirmationInfo.linkTitle}
        />
        <br />
        <Button
          isLoading={loading}
          onClick={() => handleSubmit()}
          className='auth-login-button'
          iconBefore={loading ? '' : content.register.registerButtonIcon}
          height={40}
        >
          {content.register.registerButtonTitle}
        </Button>
        <br />
        <br />
        <AuthFooter />
      </div>
    </div>
  );
};
RegisterFormSection.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  content: PropTypes.any,
  password: PropTypes.string,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
};
export default RegisterFormSection;
