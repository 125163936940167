import React, { useState } from 'react';
import { connect } from 'react-redux';
import { SideSheet } from 'evergreen-ui';
import StartTrialFlow from './StartTrialFlow';

const ProUser = (props) => {
  const { children, isProProject, handleCancel } = props;
  const [isShown, setIsShown] = useState(true);

  return isProProject ? (
    children
  ) : (
    <SideSheet
      isShown={isShown}
      width={480}
      onCloseComplete={() => {
        setIsShown(false);
        handleCancel();
      }}
      preventBodyScrolling
    >
      <StartTrialFlow />
    </SideSheet>
  );
};

const mapState = (state) => ({
  isProProject:
    state.dashboard.selectedProject.is_pro || state.auth.trial_remaining > 0,
});

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(ProUser);
