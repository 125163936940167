import React, { useState } from 'react';
import { TextInput, Textarea, TagInput, toaster, Pane } from 'evergreen-ui';
import PropTypes from 'prop-types';
import BlockContainer from './BlockContainer';
import AttributeAPIContainer from '../elements/AttributeAPIContainer';
import {
  updateOnSelectAttribute,
  updateOnChangeText,
  isValidEmail,
} from './../../utils';
import emailIcon from './../../pages/builder/assets/images/icons/email-icon.svg';

const EmailSendBlock = ({
  id,
  from,
  to,
  cc,
  subject,
  body,
  setFrom,
  setTo,
  setCC,
  setSubject,
  setBody,
  save,
  type,
  saveBlock,
  attributeList,
  apiList,
  deleteBlock,
  sequences,
  copyBlock,
  moveBlock,
  saveLoader,
  hasWriteAccess,
}) => {
  const [showFromAttribute, setShowFromAttribute] = useState(false);
  const [showToAttribute, setShowToAttribute] = useState(false);
  const [showCCAttribute, setShowCCAttribute] = useState(false);
  const [showSubAttribute, setShowSubAttribute] = useState(false);
  const [showBodyAttribute, setShowBodyAttribute] = useState(false);
  const [showAPI, setShowAPI] = useState(false);
  const senderNameRegex = new RegExp('^[a-zA-Z0-9_.+-]*$');

  return (
    <BlockContainer
      id={id}
      title='Send Email'
      subtitle='Send an email when user reaches this block'
      save={save}
      isLoading={saveLoader}
      icon={emailIcon}
      handleSaveClick={() => {
        if (to.length === 0) {
          toaster.danger('To field is required');
        } else {
          saveBlock(id);
        }
      }}
      handleDelete={() => deleteBlock(id)}
      sequences={sequences}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <div>
        <Pane flex={1} display='flex'>
          <AttributeAPIContainer
            attributeList={attributeList}
            showAttribute={showFromAttribute}
            setShowAttribute={setShowFromAttribute}
            apiList={apiList.filter((api) => api.return_type === type)}
            showAPI={showAPI}
            setShowAPI={setShowAPI}
            handleSelect={(value) => {
              updateOnSelectAttribute(
                value,
                showFromAttribute,
                showAPI,
                setShowFromAttribute,
                setShowAPI,
                setFrom,
                from,
                apiList
              );
            }}
          >
            <TextInput
              required={true}
              value={from}
              width='100%'
              style={{ marginRight: '15px' }}
              disabled={!hasWriteAccess}
              onChange={(e) => {
                if (senderNameRegex.test(e.target.value)) {
                  updateOnChangeText(
                    e.target.value,
                    setShowFromAttribute,
                    setShowAPI,
                    setFrom
                  );
                } else {
                  toaster.danger('Sender has to be a single word');
                }
              }}
              placeholder='From (Name)'
            />
          </AttributeAPIContainer>
          <AttributeAPIContainer
            attributeList={attributeList}
            showAttribute={showSubAttribute}
            setShowAttribute={setShowSubAttribute}
            apiList={apiList.filter((api) => api.return_type === type)}
            showAPI={showAPI}
            setShowAPI={setShowAPI}
            handleSelect={(value) => {
              updateOnSelectAttribute(
                value,
                showSubAttribute,
                showAPI,
                setShowSubAttribute,
                setShowAPI,
                setSubject,
                subject,
                apiList
              );
            }}
          >
            <TextInput
              required={true}
              width='100%'
              value={subject}
              disabled={!hasWriteAccess}
              onChange={(e) =>
                updateOnChangeText(
                  e.target.value,
                  setShowSubAttribute,
                  setShowAPI,
                  setSubject
                )
              }
              placeholder='Subject'
            />
          </AttributeAPIContainer>
        </Pane>
        <br />
        <AttributeAPIContainer
          attributeList={attributeList}
          showAttribute={showToAttribute}
          setShowAttribute={setShowToAttribute}
          apiList={apiList.filter((api) => api.return_type === type)}
          showAPI={showAPI}
          setShowAPI={setShowAPI}
          handleSelect={(value) => {
            updateOnSelectAttribute(
              value,
              showToAttribute,
              showAPI,
              setShowToAttribute,
              setShowAPI,
              setTo,
              to,
              apiList
            );
          }}
        >
          <TagInput
            inputProps={{
              placeholder: 'To emails...',
              id: 'email-sender-block-to',
            }}
            width='100%'
            values={to}
            disabled={!hasWriteAccess}
            onInputChange={(e) =>
              updateOnChangeText(
                e.target.value,
                setShowToAttribute,
                setShowAPI,
                (val) => {
                  if (val === '{{') {
                    if (to.length + cc.length === 50) {
                      toaster.danger('You can not add more than 50 emails', {
                        duration: 2,
                      });
                      return;
                    }
                    setTo([...to, val]);
                    setTimeout(
                      () =>
                        (document.getElementById(
                          'email-sender-block-to'
                        ).value = ''),
                      0
                    );
                  }
                }
              )
            }
            onAdd={(value) => {
              if (!isValidEmail(value[0])) {
                toaster.danger(
                  'Oops, you tried entering a invalid email. Try again.',
                  { duration: 2 }
                );
                return;
              }
              if (to.length + cc.length === 50) {
                toaster.danger('You can not add more than 50 emails', {
                  duration: 2,
                });
                return;
              }
              setTo([...to, value[0]]);
            }}
            onRemove={(_value, index) => {
              setTo(to.filter((_, i) => i !== index));
            }}
          />
        </AttributeAPIContainer>
        <br />
        <br />
        <AttributeAPIContainer
          attributeList={attributeList}
          showAttribute={showCCAttribute}
          setShowAttribute={setShowCCAttribute}
          apiList={apiList.filter((api) => api.return_type === type)}
          showAPI={showAPI}
          setShowAPI={setShowAPI}
          handleSelect={(value) => {
            updateOnSelectAttribute(
              value,
              showCCAttribute,
              showAPI,
              setShowCCAttribute,
              setShowAPI,
              setCC,
              cc,
              apiList
            );
          }}
        >
          <TagInput
            inputProps={{
              placeholder: 'CC emails...',
              id: 'email-sender-block-cc',
            }}
            width='100%'
            values={cc}
            disabled={!hasWriteAccess}
            onInputChange={(e) =>
              updateOnChangeText(
                e.target.value,
                setShowCCAttribute,
                setShowAPI,
                (val) => {
                  if (val === '{{') {
                    setCC([...cc, val]);
                    setTimeout(
                      () =>
                        (document.getElementById(
                          'email-sender-block-cc'
                        ).value = ''),
                      0
                    );
                  }
                }
              )
            }
            onAdd={(value) => {
              if (!isValidEmail(value[0])) {
                toaster.danger(
                  'Oops, you tried entering a invalid email. Try again.',
                  { duration: 2 }
                );
                return;
              }
              if (to.length + cc.length === 50) {
                toaster.danger('You can not add more than 50 emails', {
                  duration: 2,
                });
                return;
              }
              setCC([...cc, value[0]]);
            }}
            onRemove={(_value, index) => {
              setCC(cc.filter((_, i) => i !== index));
            }}
          />
        </AttributeAPIContainer>
        <br />
        <br />
        <AttributeAPIContainer
          attributeList={attributeList}
          showAttribute={showBodyAttribute}
          setShowAttribute={setShowBodyAttribute}
          apiList={apiList.filter((api) => api.return_type === type)}
          showAPI={showAPI}
          setShowAPI={setShowAPI}
          handleSelect={(value) => {
            updateOnSelectAttribute(
              value,
              showBodyAttribute,
              showAPI,
              setShowBodyAttribute,
              setShowAPI,
              setBody,
              body,
              apiList
            );
          }}
        >
          <Textarea
            required={true}
            grammarly={true}
            spellCheck={true}
            value={body}
            disabled={!hasWriteAccess}
            onChange={(e) =>
              updateOnChangeText(
                e.target.value,
                setShowBodyAttribute,
                setShowAPI,
                setBody
              )
            }
            placeholder='Body'
          />
        </AttributeAPIContainer>
      </div>
    </BlockContainer>
  );
};

EmailSendBlock.propTypes = {
  id: PropTypes.number.isRequired,
  save: PropTypes.bool.isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.array.isRequired,
  cc: PropTypes.array.isRequired,
  subject: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  setFrom: PropTypes.func.isRequired,
  setTo: PropTypes.func.isRequired,
  setCC: PropTypes.func.isRequired,
  setSubject: PropTypes.func.isRequired,
  setBody: PropTypes.func.isRequired,
  saveBlock: PropTypes.func.isRequired,
  attributeList: PropTypes.arrayOf(PropTypes.string),
  apiList: PropTypes.arrayOf(PropTypes.object),
  deleteBlock: PropTypes.func.isRequired,
  moveBlock: PropTypes.func.isRequired,
  copyBlock: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  saveLoader: PropTypes.bool.isRequired,
  hasWriteAccess: PropTypes.bool.isRequired,
};

EmailSendBlock.defaultState = {
  deleteBlock: (blockId, SequenceId) =>
    console.log('delete from default props', blockId, SequenceId),
};

export default EmailSendBlock;
