import React from 'react';
import './style.scss';
import PropTypes from 'prop-types';

const LoaderLine = ({ color, style }) => (
  <div className={'d-flex f-center h-100p'} style={style}>
    <div className={'loader'}>
      <div className='lds-ellipsis'>
        <div style={{ background: color }} />
        <div style={{ background: color }} />
        <div style={{ background: color }} />
        <div style={{ background: color }} />
      </div>
    </div>
  </div>
);

LoaderLine.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};

LoaderLine.defaultProps = {
  color: '#007B65',
  style: {},
};

export default LoaderLine;
