import React from 'react';
import {Button, IconButton, Spinner,} from "evergreen-ui";
import GalleryUpload from "./GalleryUpload";

interface Prop {
  closeModal: () => void;
  hasWriteAccess: boolean;
  handleDrop: (image: any) => void;
  previewImage: string;
  galleryImages: any[];
  galleryImageLoader: boolean;
  getMoreGalleryImages: () => void;
  galleryTotal: number;
  sendMessage: (message: any, image: any) => void;
  messageSendingLoader: boolean;
}

const CRMImageGallery: React.FC<Prop> = (
  {
    closeModal,
    hasWriteAccess,
    handleDrop,
    previewImage,
    galleryImages,
    galleryImageLoader,
    getMoreGalleryImages,
    galleryTotal,
    sendMessage,
    messageSendingLoader,
  }) => {


  return (
    <div className='crm-image-gallery'>
      {messageSendingLoader ? <div className='flex align-center justify-center w-100p h-150px flex-column'>
          <Spinner size={36} />
          <p className='text-center mt-15px'>Hold on a second! Your Image is being sent!</p>
      </div> :
        <>
          <div className='crm-image-gallery__close-button'>
            <IconButton
              icon='cross'
              appearance='minimal'
              onClick={() => closeModal()}
            />
          </div>
          <GalleryUpload
            handleDrop={handleDrop}
            image={previewImage}
            hasWriteAccess={hasWriteAccess}
          />
          <div className='crm-image-gallery__container'>
            {galleryImages.length === 0 &&
            <div style={{textAlign: 'center', margin: '30px auto'}}>No Image Uploaded yet</div>}
            {galleryImages.map((image: any, index: number) =>
              <div className="crm-image-gallery__item" key={index}>
                <div className="crm-image-gallery__item-thumb" tabIndex={1}>
                  <div onClick={async () => {
                      await sendMessage('', image.s3_url);
                      closeModal();
                  }}>
                    <img alt={`${index} gallery`} src={image.s3_url}/>
                  </div>
                </div>
              </div>
            )}
            <div className='w-100p mt-10px mb-10px flex justify-center align-center'>
              {galleryImages.length < galleryTotal &&
              <Button
                className='alice-btn__secondary-outlined'
                isLoading={galleryImageLoader}
                onClick={() => {
                  getMoreGalleryImages()
                }}>Load More
              </Button>}
            </div>
          </div>
        </>
      }
    </div>
  );
};

export default CRMImageGallery;
