import React from 'react';
import BillIcon from "../../../assets/icon/marketplace/BillIcon.svg";
import LocationIcon from "../../../assets/icon/marketplace/LocationIcon.svg";
import DeliveryIcon from "../../../assets/icon/marketplace/deliveryIcon.svg";
import OrderCardSummaryList from "./OrderCardSummaryList";
import OrderCardSummaryTableItem from "./OrderCardSummaryTableItem";
import CopyText from "../../../../../components/elements/redesign/CopyText";

interface Prop {
  billingInfo: string;
  shippingInfo: string;
  totalTax: string;
  totalCost: string;
  shippingCost: string;
  discount: string;
  shippingMethod: string;
  refund: string;
  coupon: string[];
}

const OrderCardSummary: React.FC<Prop> = (
  {
    billingInfo,
    shippingInfo,
    totalTax,
    totalCost,
    shippingCost,
    discount,
    shippingMethod,
    refund,
    coupon,
  }) => {


  const parseCoupon = (data:string[]) => {
    let result:string =  '';
    data.map((coupon:string)=> result = result+coupon+", ")
    return result;
  }

  return (
    <div className='order-right-bar__order-card__summary'>
      <OrderCardSummaryList
        icon={BillIcon}
        title="Billing Address"
        description={billingInfo}
      />
      <OrderCardSummaryList
        icon={LocationIcon}
        title="Shipping Address"
        description={shippingInfo}
      />
      <OrderCardSummaryList
        icon={DeliveryIcon}
        title="Shipping Method"
        description={shippingMethod}
      />
      <div className='alice-hr'/>
      <br/>
      <OrderCardSummaryTableItem title='Items Total' value={`$${(parseFloat(totalCost)+parseFloat(discount))-(parseFloat(totalTax)+parseFloat(shippingCost))}`}/>
      <OrderCardSummaryTableItem title='Taxes' value={`$${totalTax}`}/>
      <OrderCardSummaryTableItem title='Delivery Charges' value={`$${shippingCost}`}/>
      <OrderCardSummaryTableItem title='Discount' value={`$${discount}`}/>
      <OrderCardSummaryTableItem title='Total' value={`$${totalCost}`} isBold={true}/>
      <OrderCardSummaryTableItem title='Refunded' value={refund}/>
      <OrderCardSummaryTableItem title='Net Payment' value={`$${parseFloat(totalCost) - parseFloat(refund)}`} isBold={true}/>
      <br/>
      <div className='alice-hr'/>
      <div className='flex align-center mt-15px'>
        <p className='order-right-bar__order-card__summary__info-container-title'>Coupon:</p> &nbsp;
          <p className='order-right-bar__order-card__summary__info-container-subtitle'>{coupon.length > 0 ? parseCoupon(coupon) : 'Not Available'}</p> &nbsp;
        {coupon.length !== 0 && <CopyText textToCopy={parseCoupon(coupon)}/>}
      </div>
    </div>
  );
};

export default OrderCardSummary;
