import axios from '../../../httpClient';
import config from '../../../config';

const initialState = {
  publicKey: '',
  plans: [],
  histories: [],
};

export const billing = {
  state: {
    ...initialState,
  },
  reducers: {
    updatePlans(state, payload) {
      return { ...state, plans: payload };
    },
    updateHistories(state, payload) {
      return { ...state, histories: payload };
    },
    updatePublicKey(state, payload) {
      return { ...state, publicKey: payload };
    },
    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async fetchPublicKey() {
      try {
        const res = await axios.get(config.stripeKey);
        if (res.data.success) {
          dispatch.billing.updatePublicKey(res.data.dataSource.public_key);
        }
      } catch (err) {
        console.log(err.response);
      }
    },

    async fetchPlans() {
      try {
        const res = await axios.get(config.plans);
        if (res.data.success) {
          dispatch.billing.updatePlans(res.data.dataSource);
        }
      } catch (err) {
        console.log(err.response);
      }
    },

    async fetchHistories() {
      try {
        const res = await axios.get(config.histories);
        if (res.data.success) {
          dispatch.billing.updateHistories(res.data.dataSource);
        }
      } catch (err) {
        console.log(err.response);
      }
    },

    async createPaymentMethod(payload) {
      /**
       * payload = {
       *    payment_method: string,
       * }
       */

      try {
        const res = await axios.post(config.payment, payload);
        if (res.data.success) {
          dispatch.settings.fetchUserInfo();
          dispatch.auth.updateInfo();
        }
        return res.data.success;
      } catch (err) {
        console.log(err.response);
      }
    },

    async createSubscription(payload) {
      /**
       * payload = {
       *    plan_id: int,
       *    action: string
       * }
       */

      try {
        const res = await axios.post(config.subscriptions, payload);
        if (res.data.success) {
          dispatch.settings.fetchUserInfo();
          dispatch.auth.updateInfo();
        }
        return res.data.success;
      } catch (err) {
        console.log(err.response);
      }
    },

    async manageCard(payload) {
      /**
       * payload = {
       *    payment_method: string,
       *    action: string (attach, detach, default)
       * }
       */
      try {
        const res = await axios.post(config.cards, payload);
        if (res.data.success) {
          dispatch.settings.fetchUserInfo();
          dispatch.auth.updateInfo();
        }
        return res.data.success;
      } catch (err) {
        console.log(err.response);
      }
    },

    async requestRefund(payload) {
      /**
       * payload = {
       *    bill_id: int,
       *    reason: string
       * }
       */

      try {
        const res = await axios.post(config.refund, payload);
        if (res.data.success) {
          dispatch.billing.fetchHistories();
        }
        return res.data.success;
      } catch (err) {
        console.log(err.response);
      }
    },

    async handleTrialStart() {
      try {
        const res = await axios.post(config.trial);
        if (res.data.success) {
          dispatch.auth.updateInfo();
        }
        return res.data.success;
      } catch (err) {
        console.log(err.response);
      }
    },
  }),
};
