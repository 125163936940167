import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, IconButton, toaster, Spinner } from 'evergreen-ui';
import { useDropzone } from 'react-dropzone';

const ImageUploadField = ({
  image,
  handleDrop,
  handleClear,
  hasWriteAccess,
}) => {
  const [loading, setLoading] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (files) => {
      if (hasWriteAccess) {
        setLoading(true);
        handleDrop(files[0]);
        setTimeout(() => setLoading(false), 1500);
      }
    },
    onDropRejected: () => {
      toaster.danger('Failed', {
        description: 'Image too large. Maximum image size is 7MB',
        duration: 1,
      });
    },
    multiple: false,
    maxSize: 7340032,
  });

  const ImageStyle = { maxHeight: 200, borderRadius: 5 };

  return (
    <section className='image-upload'>
      <div {...getRootProps()} tabIndex={-1} className='image-upload__field'>
        {!image && !loading && (
          <Fragment>
            <input {...getInputProps()} />
            <Icon icon='camera' size={70} />
            <p>Drag 'n' Drop an Image</p>
          </Fragment>
        )}

        {loading && <Spinner />}

        {image && !loading && (
          <Fragment>
            {hasWriteAccess && (
              <IconButton
                icon='cross'
                appearance='minimal'
                intent={'danger'}
                onClick={() => handleClear()}
              />
            )}
            <img src={image} alt='Block' style={ImageStyle} />
          </Fragment>
        )}
      </div>
    </section>
  );
};

ImageUploadField.propTypes = {
  image: PropTypes.string,
  handleDrop: PropTypes.func,
  handleClear: PropTypes.func,
  hasWriteAccess: PropTypes.bool,
};
ImageUploadField.defaultProps = {
  hasWriteAccess: true,
};

export default ImageUploadField;
