import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import '../../assets/styles/elements/button.scss';
import { reorderList } from '../../utils';
import PropTypes from 'prop-types';
import ButtonFragment from './ButtonFragment';
import CreateButtonFragment from './CreateButtonFragment';

const ButtonGroup = ({
  blockId,
  sequences,
  maxButtonCount,
  buttonTitle,
  type,
  APIButtonTitle,
  buttonList,
  formsData,
  handleDropUpdate,
  handleDelete,
  handleCreate,
  handleUpdate,
  allowedTypes,
  dragDirection,
  hasAPIButton,
  apiButtonData,
  apiList,
  hasMessengerExtension,
  hasWebViewHeight,
  handleAPIButtonElementCreate,
  hasWriteAccess,
}) => {
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorderList(
      buttonList,
      result.source.index,
      result.destination.index
    );

    handleDropUpdate(blockId, items);
  };

  return (
    <div className='button-group'>
      <DragDropContext
        onDragEnd={(result) => hasWriteAccess && onDragEnd(result)}
      >
        <Droppable droppableId='droppable' direction={dragDirection}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className='button-group__droppable'
            >
              {!!buttonList &&
                buttonList.length > 0 &&
                buttonList.map((button, i) => (
                  <Draggable key={i} draggableId={`button-${i}`} index={i}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className='button-group__draggable'
                      >
                        <ButtonFragment
                          key={i}
                          id={button.id}
                          index={i}
                          sequences={sequences}
                          formsData={formsData}
                          title={button.title}
                          type={button.type}
                          value={button.value}
                          formSequence={button.form_sequence}
                          hasMessengerExtension={hasMessengerExtension}
                          messengerExtensions={button.messenger_extensions}
                          hasWebViewHeight={hasWebViewHeight}
                          webViewHeight={button.webview_height_ratio}
                          handleDelete={(index) => handleDelete(blockId, index)}
                          handleUpdate={(data, type) =>
                            handleUpdate(blockId, data, type)
                          }
                          allowedTypes={allowedTypes}
                          hasWriteAccess={hasWriteAccess}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {maxButtonCount > buttonList.length && hasWriteAccess && (
        <CreateButtonFragment
          type={type}
          hasAPIButton={hasAPIButton}
          APIButtonTitle={APIButtonTitle}
          buttonTitle={buttonTitle}
          buttonCount={buttonList.length}
          handleCreate={() => handleCreate(blockId)}
          apiButtonData={apiButtonData}
          apiList={apiList}
          handleAPIButtonElementCreate={handleAPIButtonElementCreate}
          blockId={blockId}
        />
      )}
    </div>
  );
};

ButtonGroup.propTypes = {
  maxButtonCount: PropTypes.number.isRequired,
  buttonList: PropTypes.array.isRequired,
  formsData: PropTypes.array,
  handleDelete: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  allowedTypes: PropTypes.arrayOf(PropTypes.string),
  hasAPIButton: PropTypes.bool,
  type: PropTypes.string.isRequired,
  hasMessengerExtension: PropTypes.bool,
  webViewHeight: PropTypes.bool,
  hasWriteAccess: PropTypes.bool,
};

ButtonGroup.defaultProps = {
  allowedTypes: ['Sequence', 'URL', 'Phone'],
  dragDirection: 'horizontal',
  hasAPIButton: false,
  hasMessengerExtension: false,
  hasWriteAccess: false,
};

export default ButtonGroup;
