import React, { useState } from 'react';
// import sendIcon from '../assets/images/send.svg';
import {
  Popover,
  Position,
  Pane,
  Table,
  Textarea,
  Icon,
} from 'evergreen-ui';
import CRMReplyOptions from './CRMReplyOptions';
import ReplyInputTopHeader from './ReplyInputTopHeader';

interface props {
  inputValue: any;
  lineCount: number;
  disable: boolean;
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyPress: (e: React.KeyboardEvent<any>) => void;
  currentTicket: any;
  sendMessage: (message: any, image: any) => void;
  resetInput: () => void;
  feedAction: string;
  updateFeedAction: (value: string) => void;
  sendNote: (message: any, image: any) => void;
  showNote: boolean;
  hasPrivateReply: boolean;
  messageSendingLoader: boolean;
  cannedResponses: any;
  hasWriteAccess: ()=> any;
  selectedProject: any;
  galleryImages: any[];
  updateGalleryImagesUpload: (data:any) => void;
  galleryImageLoader: boolean;
  getMoreGalleryImages: () => void;
  galleryTotal: number;
}

const CRMReplyInput: React.FC<props> = ({
  inputValue,
  lineCount,
  handleChange,
  onKeyPress,
  currentTicket,
  sendMessage,
  resetInput,
  feedAction,
  updateFeedAction,
  sendNote,
  showNote,
  disable,
  hasPrivateReply,
  messageSendingLoader,
  cannedResponses,
  hasWriteAccess,
  selectedProject,
  galleryImages,
  updateGalleryImagesUpload,
  galleryImageLoader,
  getMoreGalleryImages,
  galleryTotal,
}) => {
  const [isCannedShown, setCannedShown] = useState(false);
  const [popupShown, setPopupShown] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const [popupHeight, setPopupHeight] = useState(0);
  const [searchCanned,setSearchCanned] = useState('');


const filteredCannedResponse = cannedResponses.filter((cr:any)=> cr.title.toLowerCase().includes(searchCanned.toLowerCase()));

  return (
    <div className='customer-chat__replySection'>
      <ReplyInputTopHeader
        feedAction={feedAction}
        updateFeedAction={updateFeedAction}
        hasPrivateReply={hasPrivateReply}
        showReplyType={
          !!currentTicket &&
          currentTicket.customer.platform.type === 'facebook_feed'
        }
      />
      <div
        className='reply-inputs'
        style={{ height: 56 + (lineCount - 1) * 16 }}
      >
        <Popover
          statelessProps={{ className: 'overflow-visible' }}
          isShown={isCannedShown}
          position={Position.TOP_LEFT}
          content={() => (
            <Pane
              style={{ overflow: 'visible', position: 'relative' }}
            >
              <p
                className={`canned-response-reply__popup ${
                  popupShown ? 'show' : 'hide'
                }`}
                style={{ top: `${popupHeight}px` }}
              >
                {popupContent}
              </p>
              <div className='canned-response-reply__body' style={
                filteredCannedResponse.length > 6 ? {  overflowY: "auto", height: 300}: filteredCannedResponse.length === 0 ? {height: '100px'}: {overflowY: "auto", height: 'fit-content'}}>
                {filteredCannedResponse.length === 0 && <div className='flex align-center justify-center h-100p pd-20px'>
                  No Canned Response Available
                </div>}
                {filteredCannedResponse.map((cr: any, i: number) => {
                  return (
                    <Table.Row
                      key={i}
                      isSelectable
                      onSelect={() => {
                        const regex = /#[a-z]+/gi;
                        let lastHashValue = inputValue.match(regex);
                        if(!!lastHashValue) {
                          lastHashValue = lastHashValue[lastHashValue.length-1];
                          const value = inputValue.slice(0,inputValue.length-lastHashValue.length) + cr.text;
                          handleChange(value);
                          setCannedShown(false);
                        }else {
                          const value = inputValue.replace('#','') + cr.text;
                          handleChange(value);
                          setCannedShown(false);
                        };
                      }}
                    >
                      <Table.TextCell
                        className='canned-response-reply__row'
                        onMouseOver={(e: any) => {
                          setPopupContent(cr.text);
                          const sElem = document.getElementsByClassName(
                            'canned-response-reply__body'
                          )[0];
                          setPopupHeight(
                            e.currentTarget.offsetTop - sElem.scrollTop
                          );
                          setPopupShown(true);
                        }}
                        onMouseLeave={(e: any) => {
                          setPopupShown(false);
                        }}
                      >
                        <span>{`#${cr.title}`}</span>{' '}
                        <Icon icon='caret-right' size={16} />
                      </Table.TextCell>
                    </Table.Row>
                  );
                })}
              </div>
            </Pane>
          )}
        >
          <Textarea
            disabled={disable}
            value={inputValue}
            id='reply-input'
            onChange={(event: any) => {
              const value = event.target.value;
              const regex = /#[a-z]+/gi;
              const lastChar = value[value.length - 1];
              if(!event.target.value) {
                setCannedShown(false);
                setSearchCanned('');
              }
              if (lastChar === '#') {
                setCannedShown(true);
                setSearchCanned('');
              }
                const findLastCanned = value.match(regex);;
              if(!!findLastCanned && findLastCanned.length > 0) {
                if(event.target.value.match(/#/gi).length === findLastCanned.length) {
                  const search =  findLastCanned[findLastCanned.length - 1].split('#')[1];
                  setSearchCanned(search);
                }

              }
              if(lastChar === ' ') {
                setSearchCanned('');
              }
              if (isCannedShown && lastChar === ' ') {
                setCannedShown(false);
              }
              handleChange(value);
            }}
            onKeyPress={onKeyPress}
            placeholder='Type something here...'
          />
        </Popover>
      </div>
      <CRMReplyOptions
        sendNote={sendNote}
        showNote={showNote}
        handleEmojiChange={(inputValue: any) => handleChange(inputValue)}
        sendMessage={sendMessage}
        isTicketLoaded={!!currentTicket?.id}
        hasUploadImage={true}
        galleryImageLoader={galleryImageLoader}
        selectedProject={selectedProject}
        updateGalleryImagesUpload={updateGalleryImagesUpload}
        getMoreGalleryImages={getMoreGalleryImages}
        galleryTotal={galleryTotal}
        handleSend={async () => {
          await sendMessage(inputValue, null);
          resetInput();
        }}
        galleryImages={galleryImages}
        hasWriteAccess={hasWriteAccess()}
        messageSendingLoader={messageSendingLoader}
      />
    </div>
  );
};

export default CRMReplyInput;
