import React from 'react';
import PropTypes from 'prop-types';
import { Card, IconButton, Position, Menu, Popover, Icon } from 'evergreen-ui';

const SequenceCard = ({
  id,
  activeSequenceId,
  title,
  isEditable,
  handleDelete,
  handleActiveBlock,
  handleEditId,
  handleModal,
  handleEditTitle,
}) => {
  return (
    <Card
      className={
        activeSequenceId === id
          ? 'sequence-card  sequence-card__active'
          : 'sequence-card'
      }
      elevation={0}
    >
      {isEditable && (
        <Popover
          position={Position.BOTTOM_RIGHT}
          content={({ close }) => (
            <Menu>
              <Menu.Group>
                <Menu.Item
                  onSelect={() => {
                    handleEditId(id);
                    handleEditTitle(title);
                    handleModal(true);
                    close();
                  }}
                  icon='edit'
                >
                  Rename
                </Menu.Item>
              </Menu.Group>
              <Menu.Divider />
              <Menu.Group>
                <Menu.Item
                  onSelect={() => {
                    handleDelete(id);
                    close();
                  }}
                  icon='trash'
                  intent='danger'
                >
                  Delete
                </Menu.Item>
              </Menu.Group>
            </Menu>
          )}
        >
          <IconButton
            appearance='minimal'
            className={
              activeSequenceId === id
                ? 'edit-icon edit-icon__active'
                : 'edit-icon'
            }
            icon='more'
            height={28}
          />
        </Popover>
      )}

      {isEditable ? (
        <p
          onClick={() => handleActiveBlock(id)}
          className='sequence-card__title text-ellipsis width-90percent'
        >
          <Icon icon='heat-grid' marginRight={10} size={16} />
          <span>{title}</span>
        </p>
      ) : (
        <p
          onClick={() => handleActiveBlock(id)}
          className='sequence-card__title text-ellipsis width-100percent'
        >
          {' '}
          <Icon icon='diagram-tree' marginRight={10} size={16} />
          <span>{title}</span>
        </p>
      )}
    </Card>
  );
};

SequenceCard.propTypes = {
  id: PropTypes.number.isRequired,
  activeSequenceId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isMovable: PropTypes.bool.isRequired,
  handleRename: PropTypes.func,
  handleDelete: PropTypes.func,
  handleCopy: PropTypes.func,
  handleMove: PropTypes.func,
  handleActiveBlock: PropTypes.func.isRequired,
};

SequenceCard.defaultProps = {
  isEditable: false,
  isMovable: true,
};

export default SequenceCard;
