import React from 'react';
import {
  Button,
  Dialog,
  TextInputField,
  Label,
  Textarea,
  toaster,
} from 'evergreen-ui';
import {channelInfo} from './../../../../utils';
import channelModalIcon from './../../assets/icons/create-channel-icon.svg';
import FacebookChannel from './FacebookChannel';
import WebPluginChannel from './WebPluginChannel';
import ViberChannel from './ViberChannel';
import FacebookMessengerFeed from './ConnectionComponents/FacebookMessengerFeed';
import Viber from './ConnectionComponents/Viber';
import ChannelEditModal from './EditModals/ChannelEditModal';
import WebchatApp from './ConnectionComponents/WebchatApp';
import WhatsappChannel from './WhatsappChannel';
import {cloneDeep} from 'lodash';

interface props {
  type: string;
  channelList: any[];
  channelInit: (body: object) => Promise<boolean>;
  channelInitLoading: boolean;
  disconnectChannel: (channelId?: any) => Promise<boolean>;
}

const ChannelCard: React.FC<props> = ({
                                        type,
                                        channelList,
                                        channelInit,
                                        channelInitLoading,
                                        disconnectChannel,
                                      }) => {
  const [showConnectionDialog, setShowConnectionDialog] = React.useState(false);
  const [showChannelCreateDialog, setShowChannelCreateDialog] = React.useState(
    false
  );
  const [showConnectModal, setShowConnectModal] = React.useState(false);
  const [showEditModal, setShowEditModal] = React.useState(false);

  const [connectableChannel, setConnectableChannel] = React.useState<any>(null);

  //input
  const [createTitle, setCreateTitle] = React.useState('');
  const [description, setDescription] = React.useState('');

  //editModal Information of Edited Channel
  const [editModalInfo, setEditModalInfo] = React.useState(null);

  const initialize = async () => {
    if (!!createTitle) {
      const response = await channelInit({
        title: createTitle,
        description,
        type,
      });
      if (response) {
        setShowChannelCreateDialog(false);
      } else {
        toaster.warning('Failed', {
          description: 'Failed to Create a new Channel',
        });
      }
    } else {
      toaster.warning('Warning', {
        description: 'Title Is Required to Create a Channel',
      });
    }
  };

  const {title, image} = channelInfo(type);
  return (
    <div className='channel-settings__card'>
      <div className='channel-settings__card-header'>
        <div className='flex align-center'>
          <img src={image} alt={'main banner'} width={30}/> &nbsp;{' '}
          <span className='channel-settings__card-header-title'>{title}</span>
        </div>
        <Button
          className='alice_btn-white-primary'
          marginRight={20}
          onClick={() => {
            setShowChannelCreateDialog(true);
          }}
        >
          Add Channel
        </Button>
      </div>
      {type === 'facebook_messenger' && (
        <FacebookChannel
          channelList={channelList}
          onEditClick={(channel: any) => {
            setShowEditModal(true);
            setEditModalInfo(channel);
          }}
          setConnectableChannel={setConnectableChannel}
          onConnectionChange={(status: any) => {
            if (!status) {
              setShowConnectionDialog(true);
            } else {
              setShowConnectModal(true);
            }
          }}
        />
      )}
      {type === 'facebook_feed' && (
        <FacebookChannel
          channelList={channelList}
          onEditClick={(channel: any) => {
            setShowEditModal(true);
            setEditModalInfo(channel);
          }}
          setConnectableChannel={setConnectableChannel}
          onConnectionChange={(status: boolean) => {
            if (!status) {
              setShowConnectionDialog(true);
            } else {
              setShowConnectModal(true);
            }
          }}
        />
      )}
      {type === 'webchat' && (
        <WebPluginChannel
          channelList={channelList}
          type={type}
          onEditClick={(channel: any) => {
            setShowEditModal(true);
            setEditModalInfo(channel);
          }}
          setConnectableChannel={setConnectableChannel}
          onConnectionChange={(status: boolean) => {
            if (!status) {
              setShowConnectionDialog(true);
            } else {
              setShowConnectModal(true);
            }
          }}
        />
      )}
      {type === 'app_messenger' && (
        <WebPluginChannel
          channelList={channelList}
          type={type}
          onEditClick={(channel: any) => {
            setShowEditModal(true);
            setEditModalInfo(channel);
          }}
          setConnectableChannel={setConnectableChannel}
          onConnectionChange={(status: boolean) => {
            if (!status) {
              setShowConnectionDialog(true);
            } else {
              setShowConnectModal(true);
            }
          }}
        />
      )}
      {type === 'viber_messenger' && (
        <ViberChannel
          channelList={channelList}
          onEditClick={(channel: any) => {
            setShowEditModal(true);
            setEditModalInfo(channel);
          }}
          setConnectableChannel={setConnectableChannel}
          onConnectionChange={(status: boolean) => {
            if (!status) {
              setShowConnectionDialog(true);
            } else {
              setShowConnectModal(true);
            }
          }}
        />
      )}
      {type === 'whatsapp_messenger' && (
        <WhatsappChannel
          channelList={channelList}
          onEditClick={(channel: any) => {
            setShowEditModal(true);
            setEditModalInfo(channel);
          }}
          setConnectableChannel={setConnectableChannel}
          onConnectionChange={(status: boolean) => {
            if (!status) {
              setShowConnectionDialog(true);
            } else {
              setShowConnectModal(true);
            }
          }}
        />
      )}

      {/*disconnect*/}
      <Dialog
        isShown={showConnectionDialog}
        onCloseComplete={() => setShowConnectionDialog(false)}
        hasFooter={false}
        hasHeader={false}
      >
        <p className='channel-settings__card-disable-warning'>
          Are you sure to disable the connection?
        </p>
        <div className='flex justify-center mb-20px'>
          <Button
            className='button-alice__gradient'
            onClick={async () => {
              if (!!connectableChannel) {
                const res = await disconnectChannel(connectableChannel.id);
                if (res) {
                  setShowConnectionDialog(false);
                }
              }
            }}
          >
            Yes Do It!
          </Button>
          <Button
            marginLeft={30}
            className='btn-outline-danger'
            onClick={() => setShowConnectionDialog(false)}
          >
            Cancel
          </Button>
        </div>
      </Dialog>

      {/*create*/}
      <Dialog
        isShown={showChannelCreateDialog}
        onCloseComplete={() => {
          setCreateTitle('');
          setDescription('');
          setShowChannelCreateDialog(false);
        }}
        hasFooter={false}
        hasHeader={false}
      >
        <div className='channel-settings__card-channel-create'>
          <img
            src={channelModalIcon}
            alt={'channel modal'}
            className='channel-settings__card-channel-create-banner'
          />
          <p className='channel-settings__card-channel-create-title'>
            Create a new channel!
          </p>
          <p className='channel-settings__card-channel-create-description'>
            Just name it, give it a proper description and you are done!
          </p>
          <TextInputField
            label='TItle'
            required
            className='rd-input-style'
            placeholder='Your Channel Title'
            value={createTitle}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCreateTitle(e.target.value)
            }
          />
          <Label
            htmlFor='textarea_description'
            marginBottom={4}
            display='block'
          >
            Description
          </Label>
          <Textarea
            id='textarea_description'
            className='rd-input-style'
            placeholder='Type Here...'
            value={description}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDescription(e.target.value)
            }
          />
          <div className='flex justify-center mb-20px mt-30px'>
            <Button
              className='button-alice__gradient'
              onClick={() => initialize()}
              isLoading={channelInitLoading}
            >
              Create
            </Button>
            <Button
              marginLeft={30}
              className='btn-outline-danger'
              onClick={() => {
                setShowChannelCreateDialog(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>

      {/*connect modal*/}
      <Dialog
        isShown={showConnectModal}
        onCloseComplete={() => setShowConnectModal(false)}
        hasFooter={false}
        hasHeader={false}
      >
        {(type === 'facebook_messenger' || type === 'facebook_feed') && (
          <FacebookMessengerFeed
            connectableChannel={connectableChannel}
            hasWriteAccess={true}
            type={type}
            setShowConnectModal={setShowConnectModal}
          />
        )}
        {type === 'viber_messenger' && (
          <Viber
            connectableChannel={connectableChannel}
            setShowConnectModal={setShowConnectModal}
          />
        )}
        {(type === 'webchat' || type === 'app_messenger') && (
          <WebchatApp
            connectableChannel={connectableChannel}
            setShowConnectModal={setShowConnectModal}
            type={type}
          />
        )}
      </Dialog>
      {/*edit modal*/}
      <Dialog
        isShown={showEditModal}
        onCloseComplete={() => setShowEditModal(false)}
        hasFooter={false}
        hasHeader={false}
        width={'70vw'}
        shouldCloseOnEscapePress={false}
        shouldCloseOnOverlayClick={false}
      >
        <ChannelEditModal
          type={type}
          editModalInfo={editModalInfo}
          updateEditModalInfo={(key: string, value: any) => {
            let localData: any = cloneDeep(editModalInfo);
            localData[key] = value;
            setEditModalInfo(localData);
          }}
          close={() => setShowEditModal(false)}
        />
      </Dialog>
    </div>
  );
};

export default ChannelCard;
