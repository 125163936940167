import axios from '../../../httpClient';
import config from '../../../config';
import { toaster } from 'evergreen-ui';
import { cloneDeep } from 'lodash';
import { channelInfo, exportFile } from './../../../utils';

const initialState = {
  rulesData: [],
  blockRulesEditId: -1,
  rulesPermissionData: {
    sources: [],
    type: [],
  },
};

export const rules = {
  state: {
    ...initialState,
  },
  reducers: {
    updateBlockRulesList(state, payload) {
      return { ...state, rulesData: payload };
    },
    updateBlockRulesEditId(state, rulesId) {
      return { ...state, blockRulesEditId: rulesId };
    },
    updateDeleteBlockRules(state, ruleId) {
      return {
        ...state,
        rulesData: state.rulesData.filter((rule) => rule.id !== ruleId),
      };
    },
    updateRulesPermissionData(state, payload) {
      let dummyRulesPermission = { trigger: {}, patterns: payload.patterns };
      dummyRulesPermission['sources'] = payload?.sources.map((elem) => ({
        value: elem,
        label: channelInfo(elem).title,
      }));
      payload.sources.forEach((source) => {
        dummyRulesPermission.trigger[source] = payload.actions[
          source
        ].map((elem) => ({ value: elem, label: elem.toUpperCase() }));
      });
      return { ...state, rulesPermissionData: dummyRulesPermission };
    },
    updateBlockRulesChange(state, payload) {
      /* 
        payload = {
          rulesId: number,
          key: string,
          value: string,
        }
      */
      const updateRules = cloneDeep(state.rulesData).map((rules) => {
        if (rules.id === payload.rulesId) {
          rules[payload.key] = payload.value;
          if (payload.key === 'trigger') {
            rules.payload = '';
          }
          rules.save = false;
        }
        return rules;
      });
      return {
        ...state,
        rulesData: updateRules,
        blockRulesEditId: payload.rulesId,
      };
    },
    updateEditedBlockRules(state, { data, rulesId }) {
      /* payload = {
        data : {
        id: number,
        trigger: string,
        action: string,
        payload: string,
        sequence: { id: number, title: string },
        qr_code: string,
        },
        rulesId: rulesId ( can be -1 for create so need this explicitly)  (number)
      */
      let rulesDataLocal = [...state.rulesData];

      rulesDataLocal = rulesDataLocal.map((rule) => {
        if (rule.id === rulesId) {
          rule = { ...data, save: true };
        }
        return rule;
      });

      return { ...state, blockRulesEditId: -1, rulesData: rulesDataLocal };
    },
    createBlockRule(state, payload) {
      if (state.rulesData.filter((rule) => rule.id === -1).length === 0) {
        const data = {
          id: -1,
          trigger: '',
          payload: '',
          isSaved: false,
          source: payload.channelType,
          sequence: payload.selectedSequence,
          child_rules: [],
        };
        return { ...state, rulesData: [data, ...state.rulesData] };
      } else {
        toaster.warning('Save Before Creating One', {
          description:
            'Please Save current rules you created before creating another one',
        });
      }
      return { ...state };
    },
    initiateChildBlockRule(state, ruleId) {
      let ruleDataLocal = state.rulesData.map((rule) => {
        if (rule.id === ruleId) {
          rule.child_rules = [
            ...rule.child_rules,
            {
              id:
                rule.child_rules.length === 0
                  ? -1
                  : rule.child_rules[rule.child_rules.length - 1].id + 1,
              trigger: '',
              payload: '',
            },
          ];
        }
        return rule;
      });

      return { ...state, rulesData: ruleDataLocal };
    },
    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async fetchBlockRules(sequenceId) {
      try {
        const res = await axios.get(`${config.sequence}/${sequenceId}/rules`);
        if (res.status === 200 && res.data.success) {
          dispatch.rules.updateBlockRulesList(res.data.dataSource);
        } else {
          //if any error occured reset array
          dispatch.block.updateBlockRulesList([]);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchRulesPermissionData(source) {
      try {
        const res = await axios.get(`${config.rulesInfo}/${source}`);
        if (res.status === 200 && res.data.success) {
          dispatch.rules.updateRulesPermissionData(res.data.dataSource);
        } else {
          //if any error occurred reset array
          dispatch.rules.updateRulesPermissionData(null);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async deleteBlockRules({ sequenceId, rulesId }) {
      try {
        if (rulesId !== -1) {
          const res = await axios.delete(
            `${config.sequence}/${sequenceId}/rules/${rulesId}`
          );

          if (res.status === 200 && res.data.success) {
            dispatch.rules.updateDeleteBlockRules(rulesId);
            toaster.success('Deleted Successfully');
          } else {
            toaster.danger('Failed', {
              description: `Failed to Delete this Rules! Please Try Again`,
              duration: 1,
            });
          }
        } else {
          dispatch.rules.updateDeleteBlockRules(rulesId);
        }
      } catch (error) {
        const message =
          !!error?.response && !!error?.response?.data
            ? error?.response?.data?.message
            : 'Failed to Delete the Rule! Please Try Again';
        toaster.danger('Failed', {
          description: `${message}`,
          duration: 1,
        });
      }
    },
    async updateBlockRules({ sequenceId, rulesId }, rootState) {
      try {
        let blockRule = rootState.rules.rulesData.filter(
          (rule) => rule.id === rulesId
        );
        if (blockRule.length > 0) {
          blockRule = blockRule[0];
        }
        let res;
        if (rulesId === -1) {
          res = await axios.post(`${config.sequence}/${sequenceId}/rules`, {
            ...blockRule,
          });
        } else {
          //update
          res = await axios.put(
            `${config.sequence}/${sequenceId}/rules/${rulesId}`,
            blockRule
          );
        }
        if (res.status === 200 && res.data.success) {
          dispatch.rules.updateEditedBlockRules({
            data: res.data.dataSource,
            rulesId: rulesId,
          });
          toaster.success('Successfully Updated');
        } else {
          toaster.danger('Failed', {
            description: `Failed to Update the Rule`,
            duration: 1,
          });
        }
      } catch (error) {
        const message =
          !!error.response && !!error.response.data
            ? error.response.data.error
            : 'Failed to Update the rule! Please Try Again';
        toaster.danger('Failed', {
          description: `${message}`,
          duration: 1,
        });
      }
    },
    async generateRulesQrCode(ruleId) {
      try {
        const res = await axios.get(`${config.rule}/${ruleId}/qrcode`, {
          responseType: 'blob',
        });
        if (res.status === 200) {
          exportFile(res.data, `${ruleId}-qrcode`, 'png');
        } else {
          toaster.danger('Failed', {
            description: `We Failed to Fetch QR Code Image. Try again later`,
            duration: 1,
          });
        }
      } catch (error) {
        const message =
          !!error.response && !!error.response.data
            ? error.response.data.message
            : 'Failed to Fetch Image';
        toaster.danger('Failed', {
          description: `${message}`,
          duration: 1,
        });
      }
    },
  }),
};
