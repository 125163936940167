import React, { Component } from 'react';
import notFound from './assets/images/notFound.svg';
import PropTypes from 'prop-types';
import './assets/styles/style.scss';

class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      randomText: [
        'Opps! You are in the wrong path',
        'Hmm! Are you lost?',
        'Seems like you are lost!',
      ],
    };
  }

  getRandom = () => {
    const length =
      this.props.randomText.length > 0
        ? this.props.randomText.length
        : this.state.randomText.length;
    return Math.floor((Math.random() * 100) % length);
  };
  render() {
    return (
      <div
        className='nodata__container'
        style={
          this.props.navIsOpen
            ? this.props.isSettingsView
              ? { width: 'calc( 100vw - 38rem)', height: '100%' }
              : this.props.isDashboardView
              ? { width: 'calc( 100vw - 16rem)' }
              : {}
            : this.props.isSettingsView
            ? { width: 'calc( 100vw - 30rem)', height: '100%' }
            : this.props.isDashboardView
            ? { width: 'calc( 100vw - 5rem)' }
            : {}
        }
      >
        <img
          src={notFound}
          alt={'not found'}
          style={
            this.props.isSettingsView ? { width: '60%', marginTop: 100 } : {}
          }
        />
        <p>
          {this.props.randomText?.length > 0
            ? this.props.randomText[this.getRandom()]
            : this.state.randomText.length > 0 &&
              this.state.randomText[this.getRandom()]}
        </p>
      </div>
    );
  }
}
NotFound.propTypes = {
  isDashboardView: PropTypes.bool,
  navIsOpen: PropTypes.bool,
};
NotFound.defaultProps = {
  isDashboardView: false,
  navIsOpen: true,
  randomText: [],
};

export default NotFound;
