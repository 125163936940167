import React, { useState } from 'react';
import {
  IconButton,
  Menu,
  Popover,
  Dialog,
  TextInputField,
} from 'evergreen-ui';

interface props {
  group: any;
  deleteAdminGroup: (groupId: number) => void;
  editAdminGroup: (groupId: number,name: string) => void;
    hasWriteAccess: boolean;
}

const AdminGroupItem: React.FC<props> = ({ group, deleteAdminGroup, editAdminGroup,hasWriteAccess }) => {
  const [isModalShown, setIsModalShown] = useState(false);
  const [updatedName, setUpdatedName] = useState(group.name);
  const [action, setAction] = useState('');

  return (
    <div className='admin-access-tags__tag'>
      {group.name}
        {hasWriteAccess && <Popover
            content={
                <Menu>
                    <Menu.Group>
                        <Menu.Item
                            onSelect={() => {
                                setIsModalShown(true);
                                setAction('edit');
                            }}
                            icon='edit'
                        >
                            Edit
                        </Menu.Item>
                        <Menu.Item
                            onSelect={() => {
                                setIsModalShown(true);
                                setAction('delete');
                            }}
                            icon='trash'
                            intent='danger'
                        >
                            Delete
                        </Menu.Item>
                    </Menu.Group>
                </Menu>
            }
        >
            <IconButton
                height={20}
                icon={'more'}
                marginLeft={10}
                appearance='minimal'
            />
        </Popover>
        }
      <Dialog
        isShown={isModalShown}
        title={
          action === 'delete' ? 'Delete Admin Group' : 'Update Admin Group'
        }
        onCloseComplete={() => {
          setIsModalShown(false);
        }}
        confirmLabel={action === 'delete' ? 'Delete' : 'Update'}
        intent={action === 'delete' ? 'danger' : 'success'}
        onConfirm={() => {
          if (action === 'delete') {
            deleteAdminGroup(group.id);
          } else {
              editAdminGroup(group.id,updatedName);
          }
            setIsModalShown(false);
        }}
      >
        {action === 'delete' ? (
          <div>
            Click Confirm Below to Delete Admin Group{' '}
            <span className='text-danger bold'>{group.name}</span>
          </div>
        ) : (
          <div>
            <TextInputField
              label='Provide New Group Name'
              value={updatedName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setUpdatedName(e.target.value)
              }
            />
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default AdminGroupItem;
