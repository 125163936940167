import React from 'react';
import BlockContainer from './BlockContainer';
import attributeIcon from './../../pages/builder/assets/images/icons/set_attribute.svg';
import SetAttributeFields from '../elements/SetAttributeFields';

const SetAttributeBlock = ({
  id,
  save,
  saveLoader,
  sequences,
  saveBlock,
  copyBlock,
  moveBlock,
  deleteBlock,
  variable,
  value,
  updateAttribute,
  updateValue,
  hasWriteAccess,
}) => {
  return (
    <BlockContainer
      id={id}
      title='Set A Attribute'
      subtitle='Provide Attribute Name & Attribute Type'
      save={save}
      isLoading={saveLoader}
      icon={attributeIcon}
      handleSaveClick={() => saveBlock(id)}
      handleDelete={() => deleteBlock(id)}
      sequences={sequences}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <SetAttributeFields
        variable={variable}
        hasWriteAccess={hasWriteAccess}
        handleVariable={updateAttribute}
        handleValue={updateValue}
        value={value}
      />
    </BlockContainer>
  );
};

export default SetAttributeBlock;
