import React, { useState, useEffect } from 'react';
import { IconButton, TextInput, SelectMenu, Button } from 'evergreen-ui';
import { convertInputType } from '../../../utils';
import moment from 'moment';
import DateTime from 'react-datetime';

const ConditionCard = ({
  handleDelete,
  handleChange,
  filterInformation,
  attribute,
  operator,
  value,
}) => {
  const [operatorList, setOperatorList] = useState([]);
  const [valueList, setValueList] = useState([]);
  const [valueType, setValueType] = useState('text');

  const getValue = (value) => {
    return !!value
      ? filterInformation.filter((info) => info.attribute === value)[0].verbose
      : '';
  };
  useEffect(() => {
    if (operatorList.length === 0 && !!attribute) {
      const filterData = filterInformation.filter(
        (info) => info.attribute === attribute
      )[0];

      const operatorLocal = filterData.operators.map((operator) => ({
        label: operator,
        value: operator,
      }));
      setOperatorList(operatorLocal);
      setValueType(filterData.field);
    }
  }, [attribute, filterInformation, operatorList.length]);

  return (
    <div className='audience-filter__condition'>
      <SelectMenu
        title='Key Fields'
        options={filterInformation.map((info) => ({
          label: info.verbose,
          value: info.attribute,
        }))}
        selected={attribute}
        closeOnSelect={true}
        onSelect={(selected) => {
          handleChange(selected.value, 'attribute');
          const filteredData = filterInformation.filter(
            (info) => info.attribute === selected.value
          )[0];
          const filteredOperators = filteredData.operators.map((operator) => ({
            label: operator,
            value: operator,
          }));
          const filteredValue = filteredData.values.map((val) => ({
            label: val,
            value: val,
          }));
          setValueType(filteredData.field);
          setOperatorList(filteredOperators);
          setValueList(filteredValue);
        }}
      >
        <Button
          height={30}
          iconAfter='caret-down'
          className='audience-filter__dropdown'
        >
          {getValue(attribute) || 'Select name...'}
        </Button>
      </SelectMenu>

      {!!attribute && (
        <SelectMenu
          title='Operation'
          options={operatorList}
          selected={operator}
          closeOnSelect={true}
          onSelect={(selected) => handleChange(selected.value, 'operator')}
        >
          <Button
            height={30}
            iconAfter='caret-down'
            className='audience-filter__dropdown'
          >
            {operator || 'Select Operator...'}
          </Button>
        </SelectMenu>
      )}
      {!!attribute &&
        !!operator &&
        valueList.length === 0 &&
        (convertInputType(valueType) === 'date' ? (
          <DateTime
            inputProps={{ placeholder: 'Select Date' }}
            className='audience__datePicker'
            value={!!value ? moment(parseFloat(value) * 1000) : null}
            onChange={(val) => {
              handleChange(val.unix().toString(), 'value');
            }}
          />
        ) : (
          <TextInput
            onChange={(e) => {
              handleChange(e.target.value, 'value');
            }}
            type={convertInputType(valueType)}
            width={'95%'}
            marginRight={15}
            height={30}
            placeholder='Provide Value'
            value={value}
          />
        ))}
      {!!attribute && !!operator && valueList.length > 0 && (
        <SelectMenu
          title='Value'
          options={valueList}
          selected={value}
          closeOnSelect={true}
          onSelect={(selected) => handleChange(selected.value, 'value')}
        >
          <Button
            className='audience-filter__dropdown'
            iconAfter='caret-down'
            height={30}
          >
            {value || 'Select Value...'}
          </Button>
        </SelectMenu>
      )}
      <IconButton
        className='audience-filter__condition-remove'
        onClick={() => handleDelete()}
        appearance='primary'
        intent='danger'
        icon='cross'
      />
    </div>
  );
};
export default ConditionCard;
