import axios from '../../../httpClient';
import config from '../../../config';
import { toaster } from 'evergreen-ui';
import { exportCSV } from './../../../utils';
const initialState = {
  AudienceData: [],
  filterInformation: null,
  total: 0,
};

export const audience = {
  state: {
    ...initialState,
  },
  reducers: {
    updateFilterInformation(state, payload) {
      //payload: filter information
      return { ...state, filterInformation: payload };
    },
    updateCustomerData(state, payload) {
      //payload: filter information
      return {
        ...state,
        AudienceData: payload.AudienceData,
        total: payload.total,
      };
    },

    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async fetchFilterInformation(payload) {
      /*
      payload = {
        projectId : num
        platformId: num
      }
       */
      try {
        const res = await axios.get(
          `${config.customers}/filters?project_id=${payload.projectId}&platform_id=${payload.platformId}`
        );
        if (res.data.success) {
          dispatch.audience.updateFilterInformation(res.data.dataSource);
        }
      } catch (err) {}
    },
    async fetchAudience(payload) {
      /*
      payload = {
        projectId : num
        platformId: num
        condition: array of objects
        limit: num,
        offset: num,
      }
       */
      try {
        const res = await axios.post(`${config.customers}`, payload);
        if (res.data.success) {
          dispatch.audience.updateCustomerData({
            AudienceData: res.data.dataSource,
            total: res.data.total,
          });
        }
      } catch (err) {}
    },
    async exportAudience(payload) {
      /*
      payload = {
        projectId : num
        platformId: num
        condition: array of objects
        export: true
      }
       */

      try {
        const res = await axios.post(`${config.customers}`, payload);
        if (res.status === 200) {
          exportCSV(res.data, `export-${payload.platform_id}`);
        } else {
          toaster.danger('Error Occurred', {
            description: "We Couldn't Fetch Data for Export",
          });
        }
      } catch (err) {
        toaster.danger('Error Occurred', {
          description: "We Couldn't Fetch Data for Export",
        });
      }
    },
  }),
};
