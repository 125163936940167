import React, { Fragment } from 'react';
import {
  Popover,
  Pane,
  Position,
  Tooltip,
  IconButton,
  Button,
} from 'evergreen-ui';
import EmptyBucket from './../../audience/asssets/images/bucket.svg';
import FilterCard from './FilterCard';

interface Props {
  title: string;
  conditions: [];
  handleAdd(): void;
  resetFilter(): void;
  handleDelete(index: number): void;
  labFields: [];
  handleConditionFieldUpdate(item: object, key: 'name' | 'value'): void;
  fetchData(arg:boolean):void;
}

const AdvanceFilter: React.FC<Props> = ({
  title,
  conditions,
  handleAdd,
  resetFilter,
  handleDelete,
  labFields,
  handleConditionFieldUpdate,
  fetchData,
}) => {
  return (
    <Popover
      position={Position.BOTTOM}
      bringFocusInside={true}
      shouldCloseOnExternalClick={false}
      content={({ close }) => (
        <Pane
          width={500}
          height={400}
          paddingTop={0}
          paddingLeft={20}
          paddingRight={20}
          className='audience-filter__popover'
        >
          <p className='audience-filter__title'>{title}</p>
          {conditions.length === 0 && (
            <div className='audience-filter__no-data-body'>
              <img src={EmptyBucket} width={140} alt='no data' />
              <p className='no-filter__message'>No Filter added</p>
              <Button
                className='button-alice__gradient'
                onClick={() => handleAdd()}
              >
                Add Condition
              </Button>
              <br />
              <Button
                className='button-alice__secondary_2'
                onClick={() => {
                  resetFilter();
                  close();
                }}
              >
                Close
              </Button>
            </div>
          )}

          <div className='audience-filter__body'>
            {conditions.length > 0 &&
              conditions.map((conditionItem, i) => (
                <FilterCard
                  key={i}
                  condition={conditionItem}
                  index={i}
                  handleDelete={handleDelete}
                  labFields={labFields}
                  handleConditionFieldUpdate={handleConditionFieldUpdate}
                />
              ))}
            {conditions.length !== 0 && (
              <Fragment>
                <Button
                  className='button-alice__gradient'
                  onClick={() => handleAdd()}
                >
                  Add New Condition
                </Button>
                <div className='audience-filter__footer'>
                  <Button
                    className='button-alice__gradient pd-lr-6rem'
                    onClick={() => {
                      fetchData(true);
                      close();
                    }}
                  >
                    Apply
                  </Button>
                  <Button
                    className='button-alice__secondary_2 pd-lr-6rem'
                    onClick={() => {
                      resetFilter();
                      close();
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Fragment>
            )}
          </div>
        </Pane>
      )}
    >
      <Tooltip content='Advanced Filter'>
        <IconButton
          appearance='minimal'
          icon='filter-list'
          marginRight={15}
          iconSize={16}
        />
      </Tooltip>
    </Popover>
  );
};
export default AdvanceFilter;
