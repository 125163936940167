import React from 'react';
import PropTypes from 'prop-types';
import VisaIcon from '../../../../assets/images/visa.svg';
import MastercardIcon from '../../../../assets/images/mastercard.svg';
import AmexIcon from '../../../../assets/images/amex.svg';
import DiscoverIcon from '../../../../assets/images/discover.svg';
import DinersclubIcon from '../../../../assets/images/diners-club.svg';
import JcbIcon from '../../../../assets/images/jcb.svg';
import UnionpayIcon from '../../../../assets/images/unionpay.svg';

const getCardIcon = (brand) => {
  switch (brand) {
    case 'amex':
      return AmexIcon;
    case 'diners':
      return DinersclubIcon;
    case 'discover':
      return DiscoverIcon;
    case 'jcb':
      return JcbIcon;
    case 'mastercard':
      return MastercardIcon;
    case 'unionpay':
      return UnionpayIcon;
    case 'visa':
      return VisaIcon;
    default:
      return VisaIcon;
  }
};

const CreditCard = ({ brand, expire, last4 }) => {
  return (
    <div className='credit-card'>
      <img src={getCardIcon(brand)} alt={brand} />
      <p>**** **** **** {last4}</p>
      <p>{expire}</p>
    </div>
  );
};

CreditCard.propTypes = {
  brand: PropTypes.string,
  expire: PropTypes.string,
  last4: PropTypes.string,
};

export default CreditCard;
