import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const AuthFormHeader = ({ title, subtitle }) => {
  return (
    <Fragment>
      <p className='title'>{title}</p>
      <p className='additional-info w-400 text-center'>{subtitle}</p>
    </Fragment>
  );
};
AuthFormHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
export default AuthFormHeader;
