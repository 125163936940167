import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'evergreen-ui';
import './../../assets/styles/elements/materialInput.scss';

const MaterialInput = ({
  label,
  value,
  type,
  name,
  onChange,
  onKeyPress,
  required,
  disabled,
  width,
  onFocus,
  className,
  onBlur,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <label className='material-outline-input'>
      <input
        placeholder=' '
        name={name}
        type={showPassword ? 'text' : type}
        disabled={disabled}
        className={className}
        onFocus={onFocus}
        onBlur={onBlur}
        width={width}
        value={!!value ? value : ''}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
      <span>
        {label}
        {required && !disabled && '*'}
      </span>
      {type === 'password' && (
        <span className='visible-eye'>
          <Icon
            onClick={() => setShowPassword(!showPassword)}
            icon={showPassword ? 'eye-off' : 'eye-open'}
            size={16}
          />
        </span>
      )}
    </label>
  );
};

MaterialInput.propsTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  type: PropTypes.string,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  width: PropTypes.any,
  onFocus: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
};

MaterialInput.defaultProps = {
  label: 'Text field',
  type: 'text',
  name: 'textfield',
  onChange: () => console.log('Button Clicked but no onChange Method Found'),
  required: false,
  disabled: false,
  onBlur: () => {},
};

export default MaterialInput;
