import React, { useState } from 'react';
import { TextInputField, Icon, InlineAlert } from 'evergreen-ui';
const VisiblePasswordField = ({
  type,
  label,
  name,
  value,
  handleChange,
  placeHolder,
  passWordMatch,
}) => {
  const [typeLocal, setTypeLocal] = useState(type);
  return (
    <div style={{ position: 'relative' }}>
      <TextInputField
        className='settings-input'
        type={typeLocal}
        label={label}
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeHolder}
        hint={
          passWordMatch && (
            <InlineAlert intent='warning' marginTop={10}>
              Password needs to be same!
            </InlineAlert>
          )
        }
      />
      <Icon
        className='settings-password__visible-button'
        onClick={() => {
          if (typeLocal === 'password') {
            setTypeLocal('text');
          } else {
            setTypeLocal('password');
          }
        }}
        size={16}
        appearance='minimal'
        icon={typeLocal === 'password' ? 'eye-open' : 'eye-off'}
      />
    </div>
  );
};
export default VisiblePasswordField;
