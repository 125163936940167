import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Pane,
  Menu,
  IconButton,
  Popover,
  Position,
  Button,
  Tooltip,
  Spinner,
  Icon,
} from 'evergreen-ui';
import {
  getPlatformInfoTitle,
  getUpdatedPlatformIconOutlined,
} from '../../../utils';
import EmptyBucket from './../asssets/images/bucket.svg';
import ConditionCard from './../components/ConditionCard';

const FilterTable = ({
  condition,
  filterInformation,
  setCondition,
  selectedProject,
  isProUser,
  selectedPlatform,
  handlePlatformChange,
  addCondition,
  handleConditionOnChange,
  submitFilter,
  exportAudienceData,
  exportLoader,
}) => {
  return (
    <Pane
      display='flex'
      justifyContent='flex-end'
      alignItems='center'
      padding={0}
      marginBottom={16}
      borderRadius={3}
    >
      <div className='audience-filter'>
        {exportLoader ? (
          <Tooltip content='Exporting...'>
            <Pane display='flex' alignItems='center' marginRight={26}>
              <Spinner size={20} />
            </Pane>
          </Tooltip>
        ) : (
          <Tooltip content='Export Audience'>
            <IconButton
              onClick={exportAudienceData}
              appearance='minimal'
              icon='export'
              marginRight={15}
              iconSize={16}
              className={!isProUser ? 'pro-badge' : ''}
            />
          </Tooltip>
        )}
        <Popover
          position={Position.BOTTOM}
          bringFocusInside={true}
          shouldCloseOnExternalClick={false}
          content={({ close }) => (
            <Pane
              width={600}
              height={400}
              paddingTop={0}
              paddingLeft={20}
              paddingRight={20}
              className='audience-filter__popover'
            >
              <p className='audience-filter__title'>Advanced Filter</p>

              {condition.length === 0 && (
                <div className='audience-filter__no-data-body'>
                  <img src={EmptyBucket} width={140} alt='no data' />
                  <p className='no-filter__message'>No Filter added</p>
                  <Button
                    className='button-alice__gradient'
                    onClick={() => addCondition()}
                  >
                    Add Condition
                  </Button>
                  <br />
                  <Button
                    className='button-alice__secondary_2'
                    onClick={() => {
                      submitFilter();
                      close();
                    }}
                  >
                    Reset
                  </Button>
                </div>
              )}
              <div className='audience-filter__body'>
                {!!filterInformation &&
                  filterInformation.length > 0 &&
                  condition.length > 0 &&
                  condition.map((conditionItem, i) => (
                    <ConditionCard
                      filterInformation={filterInformation}
                      index={i}
                      key={i}
                      attribute={conditionItem.attribute}
                      operator={conditionItem.operator}
                      value={conditionItem.value}
                      handleChange={(selected, type) => {
                        handleConditionOnChange(selected, type, i);
                      }}
                      handleDelete={() => {
                        let updatedCondition = [];
                        if (condition.length > 1) {
                          updatedCondition = [
                            ...condition.slice(0, i),
                            ...condition.slice(i + 1),
                          ];
                        }
                        setCondition(updatedCondition);
                      }}
                    />
                  ))}
                {condition.length !== 0 && (
                  <Fragment>
                    <Button
                      className='button-alice__gradient'
                      onClick={() => addCondition()}
                    >
                      Add New Condition
                    </Button>
                    <div className='audience-filter__footer'>
                      <Button
                        className='button-alice__gradient pd-lr-6rem'
                        onClick={() => {
                          submitFilter();
                          close();
                        }}
                      >
                        Apply
                      </Button>
                      <Button
                        className='button-alice__secondary_2 pd-lr-6rem'
                        onClick={async () => {
                          await setCondition([]);
                          await submitFilter();
                          close();
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Fragment>
                )}
              </div>
            </Pane>
          )}
        >
          <Tooltip content='Advanced Filter'>
            <IconButton
              appearance='minimal'
              icon='filter-list'
              marginRight={15}
              iconSize={16}
            />
          </Tooltip>
        </Popover>
        <Popover
          position={Position.BOTTOM_RIGHT}
          content={({ close }) => (
            <Menu>
              <Menu.Group>
                {selectedProject.platforms.map((channel, key) => (
                  <Menu.Item
                    key={key}
                    onSelect={() => {
                      close();
                      handlePlatformChange(channel);
                    }}
                  >
                    <div className='flex align-center'>
                      {
                        <img
                          src={getUpdatedPlatformIconOutlined(
                            !!channel.type ? channel.type : 'all'
                          )}
                          alt={'lorem'}
                          width={20}
                        />
                      }{' '}
                      &nbsp;&nbsp;{' '}
                      {!!channel
                        ? getPlatformInfoTitle(channel)
                        : 'Not Available'}
                    </div>
                  </Menu.Item>
                ))}
              </Menu.Group>
            </Menu>
          )}
        >
          <Button
            className='menu-icon'
            icon='more'
            height={30}
            style={{ background: 'white' }}
          >
            <div className='flex align-center'>
              {
                <img
                  src={getUpdatedPlatformIconOutlined(
                    !!selectedPlatform ? selectedPlatform.type : 'all'
                  )}
                  alt={'lorem'}
                  width={20}
                />
              }{' '}
              &nbsp;&nbsp; {getPlatformInfoTitle(selectedPlatform)} &nbsp;
              <Icon icon={'caret-down'} size={16} />
            </div>
          </Button>
        </Popover>
      </div>
    </Pane>
  );
};

FilterTable.propTypes = {
  condition: PropTypes.array,
  exportLoader: PropTypes.bool,
};
export default FilterTable;
