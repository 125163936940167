import React from 'react';
import {IconButton, Switch} from 'evergreen-ui';

interface props {
  channelList: any[];
  onConnectionChange: (status: boolean) => void;
  onEditClick: (channel: any) => void;
  setConnectableChannel: (channel: any) => void;
}

const WhatsappChannel: React.FC<props> = (
  {
    channelList,
    onConnectionChange,
    onEditClick,
    setConnectableChannel,
  }) => {
  return (
    <div className='channel-settings__card-element'>
      {channelList.length !== 0 && (
        <div className='channel-settings__card-holder-elem bg-white'>
          <div className='channel-settings__card-holder-elem-item'>Title</div>
          <div className='channel-settings__card-holder-elem-item'>
            Description
          </div>
          <div className='channel-settings__card-holder-elem-item'>
            Account Name
          </div>
          <div className='channel-settings__card-holder-elem-item'>Edit</div>
          <div className='channel-settings__card-holder-elem-item'>
            Connection
          </div>
        </div>
      )}
      {channelList.map((channel: any, index: number) => (
        <div className='channel-settings__card-holder-elem' key={index}>
          <div className='channel-settings__card-holder-elem-item fs-12px'>
            {channel.title || 'Not Available'}
          </div>
          <div className='channel-settings__card-holder-elem-item  fs-12px'>
            {channel.description || 'Not Available'}
          </div>
          <div className='channel-settings__card-holder-elem-item fs-12px'>
            {channel?.name || 'Not Available'}
          </div>
          <div className='channel-settings__card-holder-elem-item fs-12px'>
            <IconButton
              className='icon-button__no-box-shadow'
              icon={'edit'}
              height={26}
              appearance='minimal'
              intent={'success'}
              onClick={() => onEditClick(channel)}
            />
          </div>
          <div className='channel-settings__card-holder-elem-item fs-12px'>
            <Switch
              disabled={true}
              className='active-switch-green'
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setConnectableChannel(channel);
                onConnectionChange(event.target.checked);
              }}
              checked={channel.is_connected}
              hasCheckIcon={false}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default WhatsappChannel;
