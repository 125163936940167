import React, { Component } from 'react';
import NavBar from '../../components/NavBar';
import './assets/styles/style.scss';
import { connect } from 'react-redux';

class Projects extends Component {
  componentDidMount() {
    document.title = 'Alice - Conversation AI Platform';
  }

  render() {
    return (
      <div className='projects-container'>
        <NavBar
          orientation='vertical'
          selectedProject={this.props.selectedProject}
          navIsOpen={this.props.navIsOpen}
          updateSelectedNav={this.props.updateSelectedNav}
          navActiveId={this.props.navActiveId}
          toggleNav={this.props.updateNavToggle}
          theme='dark'
          isProUser={this.props.isPro}
          permissionList={this.props.permissionList}
          selectedPlatform={this.props.selectedPlatform}
        />
        {this.props.children}
      </div>
    );
  }
}

const mapState = (state) => ({
  remaining: state.auth.trial_remaining,
  isPro:
    state.dashboard.selectedProject?.is_pro || state.auth.trial_remaining > 0,
  showTrial: state.auth.trial_remaining > 0,
  selectedProject: state.dashboard.selectedProject,
  selectedPlatform: state.dashboard.selectedPlatform,
  navIsOpen: state.dashboard.navIsOpen,
  navActiveId: state.dashboard.navActiveId,
  permissionList: state.dashboard.permission.list || [],
});

const mapDispatch = (dispatch) => ({
  updateSelectedNav: (navId) => dispatch.dashboard.updateSelectedNav(navId),
  updateNavToggle: () => dispatch.dashboard.updateNavToggle(),
});

const ProjectsContainer = connect(mapState, mapDispatch)(Projects);

export default ProjectsContainer;
