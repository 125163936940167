import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { checkPermission, reorderList } from '../../utils';
import SequenceCard from './components/SequenceCard';
import SequenceMeta from './components/SequenceMeta';
import './assets/styles/style.scss';
import { Button, Alert } from 'evergreen-ui';
import { connect } from 'react-redux';
import BlockDock from './components/BlockDock';
import LoaderLine from '../../components/loaders/LoaderLine';
import NoDataAvailable from './components/NoDataAvailable';
import SmartBlock from '../../components/blocks/SmartBlock';
import DropdownMenu2 from '../../components/elements/DropdownMenu2';
import { facebookTestUrl } from '../../config';
import ReactGA from 'react-ga';
import SearchSequence from './components/SearchSequence';
import ModifySequence from './components/ModifySequence';
import PlatformConnectAlert from './components/fragments/PlatformConnectAlert';
import HasPermission from '../../components/HasPermission';
import Rules from '../rules/Rules';

class Builder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchSequence: '',
      sequenceAcitivityLoader: false,
      sequenceAcitivityModal: false,
      editId: 0,
      editTitle: '',
      readAccess: 'read:bot_building',
      writeAccess: 'write:bot_building',
      loader: true,
    };
  }

  handleDelete = async (id) => {
    await this.props.deleteSequence(this.props.selectedPlatform.id, id);
    if (this.props.selectedSequence === id) {
      await this.props.setSelectedSequence(this.props.sequences[0].id);
    }
  };

  handleSequenceNameSubmit = (id, title) => {
    const data = {
      title: title,
    };
    const result = this.props.editSequence(
      this.props.selectedPlatform.id,
      id,
      data
    );
    return result;
  };

  handleSequenceAdd = async (title) => {
    const data = {
      title: title,
    };
    let res = await this.props.createSequence(
      this.props.selectedPlatform.id,
      data
    );
    return res;
  };

  handleActiveBlock = (id) => {
    this.props.setSelectedSequence(id);
    this.props.fetchBlock(id);
    this.props.fetchBlockRules(id);
  };

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorderList(
      this.props.blocks,
      result.source.index,
      result.destination.index
    );

    this.props.reorderBlocks(items);
  };

  handlePlatformOnSelect = async (selectedPlatform) => {
    this.props.setSelectedPlatform(selectedPlatform);
    // fetching sequence for that platform
    await this.props.fetchSequence(parseInt(selectedPlatform.id));
    // after getting value from fetch sequence we update default sequence which updates when fetch sequence called
    await this.props.setSelectedSequence(this.props.defaultSequence);
    // finally fetching all blocks for that selected sequence
    await this.props.fetchBlock(this.props.selectedSequence);
    await this.props.fetchIntent(selectedPlatform.id);
    await this.props.fetchBlockRules(this.props.selectedSequence);
    await this.props.fetchEntities(parseInt(selectedPlatform.id));
  };

  async componentDidMount() {
    ReactGA.pageview(
      window.location.pathname + window.location.search + window.location.hash
    );
    await this.props.fetchProject();
    const project = this.props.projects.filter(
      (p) => parseInt(this.props.projectId) === p.id
    )[0];
    let platformId = 0;
    if (
      !!this.props.selectedProject &&
      this.props.projectId !== this.props.selectedProject.id
    ) {
      await this.props.setSelectedProject(project);
      if (
        project.platforms.length > 0 &&
        project.platforms.filter(
          (platform) => platform.id === this.props.selectedPlatform?.id
        ).length === 0
      ) {
        this.props.setSelectedPlatform(project.platforms[0]);
        platformId = project.platforms[0].id;
      } else {
        this.props.setSelectedPlatform(
          project.platforms.filter(
            (platform) => platform.id === this.props.selectedPlatform.id
          )[0]
        );
        platformId = this.props.selectedPlatform.id;
      }
    }
    this.props.updateSelectedNav(1);

    //first we will check if current project's permission is in loading state?
    if (this.props.permissionLoader) {
      //if true set timeout to 1s and wait for permission to load
      setTimeout(() => {
        //permission loaded
        const hasPermission = checkPermission(
          this.props.permissionList,
          this.state.readAccess
        );

        if (hasPermission) {
          this.fetchAPIData(platformId);
        }
      }, 1000);
    } else {
      //permission loaded
      const hasPermission = checkPermission(
        this.props.permissionList,
        this.state.readAccess
      );

      if (hasPermission) {
        await this.fetchAPIData(platformId);
      }
    }
  }

  fetchAPIData = async (platformId) => {
    if (platformId !== 0) {
      // if there is platformId we fetch sequence and attributes
      await this.props.fetchSequence(platformId);
      await this.props.fetchAttributes(platformId);
      await this.props.fetchEntities(platformId);
      const sequenceIDs = this.props.sequences.map((s) => s.id);

      if (
        this.props.selectedSequence === 0 ||
        !sequenceIDs.includes(this.props.selectedSequence)
      ) {
        this.props.setSelectedSequence(sequenceIDs[0]);
      }
      // later fetching blocks and api
      await this.props.fetchBlock(this.props.selectedSequence);
      await this.props.fetchBlockRules(this.props.selectedSequence);
    }
    await this.props.fetchSubscribe(platformId);
    await this.props.fetchAPI(this.props.selectedProject.id);
    await this.props.fetchIntent(platformId);
    await this.props.fetchDataLabs(this.props.selectedProject.id);
    await this.props.fetchAgent(this.props.selectedProject.id);
    await this.props.fetchTeamGroups(this.props.selectedProject.id);
    this.setState({
      loader: false,
    });
  };

  render() {
    // used for loader
    const platformSettingsLink = '/settings/channel';

    const loadingState =
      this.props.loaders.effects.block.fetchBlock ||
      this.props.loaders.effects.builder.fetchSequence ||
      this.state.loader;

    let selectedSequenceRender = this.props.sequences.filter(
      (s) => s.id === this.props.selectedSequence
    );

    selectedSequenceRender =
      selectedSequenceRender.length > 0 ? selectedSequenceRender[0] : null;

    const isDockAvailable =
      !!selectedSequenceRender &&
      selectedSequenceRender.is_default &&
      (selectedSequenceRender.title.toLowerCase() === 'remove' ||
        selectedSequenceRender.title.toLowerCase() === 'hide');

    const hasWriteAccess = checkPermission(
      this.props.permissionList,
      this.state.writeAccess
    );

    return (
      <HasPermission shortCode={this.state.readAccess}>
        <main className='builder-main'>
          <ModifySequence
            isShown={this.state.sequenceAcitivityModal}
            isLoading={this.state.sequenceAcitivityLoader}
            updateState={(state, value) => this.setState({ [state]: value })}
            status={this.state.editId > 0 ? 'edit' : 'create'}
            handleSequenceAdd={this.handleSequenceAdd}
            editId={this.state.editId}
            handleRename={this.handleSequenceNameSubmit}
            editTitle={this.state.editTitle}
          />
          <div className='builder-main__wrapper'>
            <div className='builder-main__sequence'>
              <div className='mt-5px' />
              {!!this.props.selectedPlatform && (
                <DropdownMenu2
                  platformData={this.props.selectedProject.platforms}
                  handleSelect={this.handlePlatformOnSelect}
                  selectedPlatform={this.props.selectedPlatform}
                  title={'Select Channel'}
                />
              )}
              <SequenceMeta
                title='Default Sequence'
                subtitle='Your bot consists of sequence. Sequences are like individual pages on a website.'
              />
              {/*we are preventing welcome message block for whatsapp platform so this is for platforms which are not whatsapp */}
              {this.props.selectedPlatform?.type !== 'whatsapp_messenger' &&
                this.props.sequences.map((block, i) => {
                  return (
                    block.is_default && (
                      <SequenceCard
                        key={i}
                        id={block.id}
                        title={block.title}
                        activeSequenceId={this.props.selectedSequence}
                        isEditable={hasWriteAccess ? !block.is_default : false}
                        subtitle={block.subtitle}
                        handleActiveBlock={this.handleActiveBlock}
                      />
                    )
                  );
                })}
              {/*and this is platform which is whatsapp*/}
              {this.props.selectedPlatform?.type === 'whatsapp_messenger' &&
                this.props.sequences.map((block, i) => {
                  return (
                    block.is_default &&
                    block.title !== 'Welcome Message' && (
                      <SequenceCard
                        key={i}
                        id={block.id}
                        title={block.title}
                        activeSequenceId={this.props.selectedSequence}
                        isEditable={hasWriteAccess ? !block.is_default : false}
                        subtitle={block.subtitle}
                        handleActiveBlock={this.handleActiveBlock}
                      />
                    )
                  );
                })}

              <SequenceMeta
                title='Your Sequence'
                subtitle='Your bot consists of sequence. Sequences are like individual pages on a website.'
              />
              {hasWriteAccess && (
                <Button
                  className='btn-full btn-light'
                  iconBefore='add'
                  height={40}
                  onClick={() => {
                    this.setState({
                      sequenceAcitivityModal: true,
                      editId: 0,
                    });
                  }}
                >
                  Add New Sequence
                </Button>
              )}
              <SearchSequence
                handleFilter={(evt) =>
                  this.setState({ searchSequence: evt.target.value })
                }
                value={this.state.searchSequence}
              />
              {!!this.props.sequences &&
                this.props.sequences
                  .filter((elem) => {
                    const reg = new RegExp(this.state.searchSequence, 'i');
                    return reg.test(elem.title);
                  })
                  .map(
                    (block, i) =>
                      !block.is_default && (
                        <SequenceCard
                          key={i}
                          id={block.id}
                          title={block.title}
                          activeSequenceId={this.props.selectedSequence}
                          isEditable={
                            hasWriteAccess ? !block.is_default : false
                          }
                          handleDelete={this.handleDelete}
                          handleSequenceNameSubmit={
                            this.handleSequenceNameSubmit
                          }
                          handleActiveBlock={this.handleActiveBlock}
                          handleEditId={(value) =>
                            this.setState({ editId: value })
                          }
                          handleEditTitle={(value) =>
                            this.setState({ editTitle: value })
                          }
                          handleModal={(value) =>
                            this.setState({ sequenceAcitivityModal: value })
                          }
                        />
                      )
                  )}
            </div>

            <div className='builder-main__block-area'>
              {!!this.props.selectedPlatform &&
                this.props.selectedPlatform.type.startsWith('facebook') &&
                this.props.selectedPlatform.primary_id === '' && (
                  <div className='builder-main__alert'>
                    <PlatformConnectAlert
                      platformSettingsLink={platformSettingsLink}
                      facebookTestUrl={facebookTestUrl}
                      userId={this.props.userId}
                      selectedPlatformId={this.props.selectedPlatform.id}
                    />
                  </div>
                )}
              {!loadingState && (
                <Rules
                  selectedPlatform={this.props.selectedPlatform}
                  hasWriteAccess={hasWriteAccess}
                />
              )}
              {loadingState && (
                <LoaderLine style={{ height: `50vh` }} color={'#007B65'} />
              )}

              {!isDockAvailable &&
                !loadingState &&
                this.props.blocks.length === 0 && (
                  <NoDataAvailable
                    message={
                      'Click Any of the block from right to add a block!'
                    }
                    height={'35vh'}
                    width={'40%'}
                  />
                )}
              {isDockAvailable &&
                !loadingState &&
                this.props.blocks.length === 0 && (
                  <Alert
                    intent='none'
                    title='YOU CANNOT MODIFY THIS BLOCK'
                    marginBottom={32}
                  >
                    This block is not modifiable. It exist for specific
                    platforms which allows you to Hide or Delete interactions.
                  </Alert>
                )}
              <DragDropContext
                onDragEnd={(result) => hasWriteAccess && this.onDragEnd(result)}
              >
                <Droppable droppableId='droppable' direction='vertical'>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className='builder-blocks__droppable'
                    >
                      {!isDockAvailable &&
                        !loadingState &&
                        this.props.blocks.length > 0 &&
                        this.props.blocks.map((block, i) => (
                          <Draggable
                            key={i}
                            draggableId={`block-${i}`}
                            index={i}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className='builder-blocks__draggable'
                              >
                                <SmartBlock
                                  {...block}
                                  key={i}
                                  sequences={this.props.sequences}
                                  entities={this.props.entities}
                                  subscriptions={this.props.subscriptions}
                                  selectedSequence={this.props.selectedSequence}
                                  labsData={this.props.labsData}
                                  platformType={
                                    this.props.selectedPlatform.type
                                  }
                                  attributeList={this.props.attributes}
                                  apiList={this.props.apis}
                                  deleteBlock={this.props.deleteBlock}
                                  updateTextBlock={this.props.updateTextBlock}
                                  updateEmailSendBlock={
                                    this.props.updateEmailSendBlock
                                  }
                                  saveBlock={this.props.saveBlock}
                                  updateRedirectBlock={
                                    this.props.updateRedirectBlock
                                  }
                                  updateInputBlock={this.props.updateInputBlock}
                                  saveRedirectBlock={
                                    this.props.saveRedirectBlock
                                  }
                                  createButtonElement={
                                    this.props.createButtonElement
                                  }
                                  createGalleryButtonElement={
                                    this.props.createGalleryButtonElement
                                  }
                                  updateButtonsBlock={
                                    this.props.updateButtonsBlock
                                  }
                                  updateLiveChatButtonBlock={
                                    this.props.updateLiveChatButtonBlock
                                  }
                                  updateButtonElements={
                                    this.props.updateButtonElements
                                  }
                                  createSubscribeElement={
                                    this.props.createSubscribeElement
                                  }
                                  deleteSubscriberSequenceData={
                                    this.props.deleteSubscriberSequenceData
                                  }
                                  updateSubscribeSequenceData={
                                    this.props.updateSubscribeSequenceData
                                  }
                                  updateGalleryBlock={
                                    this.props.updateGalleryBlock
                                  }
                                  updateGalleryElements={
                                    this.props.updateGalleryElements
                                  }
                                  updateGalleryButtonElements={
                                    this.props.updateGalleryButtonElements
                                  }
                                  updateURLBlocks={this.props.updateURLBlocks}
                                  updateSubscribeBlockTitle={
                                    this.props.updateSubscribeBlockTitle
                                  }
                                  updateUnsubscribeBlock={
                                    this.props.updateUnsubscribeBlock
                                  }
                                  deleteButtonElement={
                                    this.props.deleteButtonElement
                                  }
                                  createGalleryElement={
                                    this.props.createGalleryElement
                                  }
                                  deleteGalleryItem={
                                    this.props.deleteGalleryItem
                                  }
                                  deleteGalleryButtonElement={
                                    this.props.deleteGalleryButtonElement
                                  }
                                  updateImageBlock={this.props.updateImageBlock}
                                  copyBlock={this.props.copyBlock}
                                  moveBlock={this.props.moveBlock}
                                  handleAPIButtonElementCreate={
                                    this.props.createAPIButtonElement
                                  }
                                  saveLoader={this.props.saveLoader}
                                  updateGalleryBlockData={
                                    this.props.updateGalleryBlockData
                                  }
                                  hasWriteAccess={hasWriteAccess}
                                  agentList={this.props.agentList}
                                  agentGroupList={this.props.teamGroups}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              {hasWriteAccess && !isDockAvailable && (
                <BlockDock
                  blocks={this.props.blocks}
                  selectedSequence={this.props.selectedSequence}
                  selectedPlatformType={
                    this.props.selectedPlatform
                      ? this.props.selectedPlatform.type
                      : null
                  }
                  defaultSequence={this.props.defaultSequence}
                  createBlock={this.props.createBlock}
                />
              )}
            </div>
          </div>
        </main>
      </HasPermission>
    );
  }
}

const mapState = (state) => ({
  userId: state.auth.id,
  projects: state.dashboard.projects,
  subscriptions: state.builder.subscriptions,
  labsData: state.dataLab.labsData,
  selectedProject: state.dashboard.selectedProject,
  selectedPlatform: state.dashboard.selectedPlatform,
  sequences: state.builder.sequences,
  selectedSequence: state.builder.selectedSequence,
  defaultSequence: state.builder.defaultSequence,
  blocks: state.block.blocks,
  attributes: state.builder.attributes,
  apis: state.api.apis,
  loaders: state.loading,
  saveLoader: state.loading.effects.block.saveBlock,
  blockRules: state.block.blockRules,
  blockRulesEditId: state.block.blockRulesEditId,
  intentList: state.dashboard.intentList,
  updateRulesLoader: state.loading.effects.block.updateBlockRules,
  deleteRulesLoader: state.loading.effects.block.deleteBlockRules,
  permissionList: state.dashboard.permission?.list || [],
  permissionLoader: state.loading.effects.dashboard.fetchRolePermission,
  agentList: state.block.agentList,
  teamGroups: state.settings.teamGroups,
  entities: state.builder.entities,
});

const mapDispatch = (dispatch) => ({
  fetchSequence: (platformId) => dispatch.builder.fetchSequence(platformId),
  fetchDataLabs: (projectId) => dispatch.dataLab.fetchDataLabs({ projectId }),
  fetchAttributes: (platformId) => dispatch.builder.fetchAttributes(platformId),
  fetchEntities: (platformId) => dispatch.builder.fetchEntities(platformId),
  createSequence: (platformId, data) =>
    dispatch.builder.createSequence({ platformId, data }),
  deleteSequence: (platformId, sequenceId) =>
    dispatch.builder.deleteSequence({ platformId, sequenceId }),
  renameSequence: (sequenceId, name) =>
    dispatch.builder.renameSequenceInState({ sequenceId, name }),
  editSequence: (platformId, sequenceId, data) =>
    dispatch.builder.editSequence({ platformId, sequenceId, data }),
  setSelectedProject: (project) =>
    dispatch.dashboard.setSelectedProject(project),
  setSelectedPlatform: (platform) =>
    dispatch.dashboard.setSelectedPlatform(platform),
  setSelectedSequence: (sequence) =>
    dispatch.builder.setSelectedSequence(sequence),
  fetchBlock: (sequenceId) => dispatch.block.fetchBlock(sequenceId),
  createBlock: (sequenceId, type, data) =>
    dispatch.block.createBlock({ sequenceId, type, data }),
  updateTextBlock: (data, id, changeKey) =>
    dispatch.block.updateTextBlock({ data, id, changeKey }),
  updateEmailSendBlock: (data, id, changeKey) =>
    dispatch.block.updateEmailSendBlock({ data, id, changeKey }),
  saveBlock: (id) => dispatch.block.saveBlock(id),
  updateRedirectBlock: (data, id, changeKey) =>
    dispatch.block.updateRedirectBlock({ data, id, changeKey }),
  updateInputBlock: (data, id, changeKey) =>
    dispatch.block.updateInputBlock({ data, id, changeKey }),
  saveRedirectBlock: (id, serial, data) =>
    dispatch.block.saveRedirectBlock({ id, serial, data }),
  createButtonElement: (id) => dispatch.block.createButtonElement(id),
  updateButtonsBlock: (id, data, changeKey) =>
    dispatch.block.updateButtonsBlock({ id, data, changeKey }),
  updateLiveChatButtonBlock: (id, data, changeKey) =>
    dispatch.block.updateLiveChatButtonBlock({ id, data, changeKey }),
  updateButtonElements: (blockId, data) =>
    dispatch.block.updateButtonElements({ blockId, data }),
  updateGalleryBlock: (id, galleryIndex, data, changeKey) =>
    dispatch.block.updateGalleryBlock({ id, data, galleryIndex, changeKey }),
  updateURLBlocks: (id, data, changeKey) =>
    dispatch.block.updateURLBlocks({ id, data, changeKey }),
  updateSubscribeBlockTitle: (id, data) =>
    dispatch.block.updateSubscribeBlockTitle({ id, data }),
  createSubscribeElement: (id) => dispatch.block.createSubscribeElement({ id }),
  updateSubscribeSequenceData: (id, subscriptionIndex, data, changeKey) =>
    dispatch.block.updateSubscribeSequenceData({
      id,
      subscriptionIndex,
      data,
      changeKey,
    }),
  updateUnsubscribeBlock: (id, data) =>
    dispatch.block.updateUnsubscribeBlock({ id, data }),
  deleteButtonElement: (blockId, buttonIndex) =>
    dispatch.block.deleteButtonElement({ blockId, buttonIndex }),
  createGalleryElement: (id) => dispatch.block.createGalleryElement(id),
  updateGalleryElements: (blockId, data) =>
    dispatch.block.updateGalleryElements({ blockId, data }),
  updateGalleryButtonElements: (blockId, galleryIndex, data) =>
    dispatch.block.updateGalleryButtonElements({ blockId, galleryIndex, data }),
  createGalleryButtonElement: (blockId, galleryIndex) =>
    dispatch.block.createGalleryButtonElement({ blockId, galleryIndex }),
  deleteGalleryItem: (blockId, galleryIndex) =>
    dispatch.block.deleteGalleryItem({ blockId, galleryIndex }),
  deleteGalleryButtonElement: (blockId, buttonIndex, galleryIndex) =>
    dispatch.block.deleteGalleryButtonElement({
      blockId,
      buttonIndex,
      galleryIndex,
    }),
  updateImageBlock: (id, data) => dispatch.block.updateImageBlock({ id, data }),
  deleteBlock: (blockId) => dispatch.block.deleteBlock({ blockId }),
  copyBlock: (id, sequenceToCopy) =>
    dispatch.block.copyBlock({ id, sequenceToCopy }),
  moveBlock: (id, sequenceToMove) =>
    dispatch.block.moveBlock({ id, sequenceToMove }),
  reorderBlocks: (blocks) => dispatch.block.reorderBlocks(blocks),
  //api
  fetchAPI: (projectId) => dispatch.api.fetchAPI(projectId),
  createAPIButtonElement: (blockId, api, isDeleted) =>
    dispatch.block.createAPIButtonElement({ blockId, api, isDeleted }),
  fetchSubscribe: (platformId) => dispatch.builder.fetchSubscribe(platformId),
  updateSelectedNav: (navId) => dispatch.dashboard.updateSelectedNav(navId),
  updateGalleryBlockData: (blockId, key, value) =>
    dispatch.block.updateGalleryBlockData({ blockId, key, value }),
  fetchBlockRules: (sequenceId) => dispatch.rules.fetchBlockRules(sequenceId),
  fetchIntent: (channleId) => dispatch.dashboard.fetchIntent(channleId),
  updateBlockRulesChange: (rulesId, key, value) =>
    dispatch.block.updateBlockRulesChange({ rulesId, key, value }),
  updateBlockRulesEditId: (rulesId) =>
    dispatch.block.updateBlockRulesEditId(rulesId),
  createBlockRule: (selectedSequence) =>
    dispatch.block.createBlockRule(selectedSequence),
  deleteBlockRules: (sequenceId, rulesId) =>
    dispatch.block.deleteBlockRules({ sequenceId, rulesId }),
  updateBlockRules: (sequenceId, rulesId) =>
    dispatch.block.updateBlockRules({ sequenceId, rulesId }),
  deleteSubscriberSequenceData: (sequenceId, subscribeId) =>
    dispatch.block.deleteSubscriberSequenceData({ sequenceId, subscribeId }),
  generateRulesQrCode: (rulesId) => dispatch.block.generateRulesQrCode(rulesId),
  fetchAgent: (projectId) => dispatch.block.fetchAgent(projectId),
  fetchProject: () => dispatch.dashboard.fetchProject(),
  fetchTeamGroups: (projectId) => dispatch.settings.fetchTeamGroups(projectId),
});

const BuilderContainer = connect(mapState, mapDispatch)(Builder);

export default React.memo(BuilderContainer);
