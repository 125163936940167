import React, { useState, FormEvent } from 'react';
import { IconButton, Table, Badge, Dialog, Textarea } from 'evergreen-ui';

interface Props {
  data: Array<any>;
  handleRefundRequest: Function;
}

const PaymentHistory: React.FC<Props> = ({ data, handleRefundRequest }) => {
  const [currentId, setCurrentId] = useState('');
  const [reason, setReason] = useState('');
  const [isShown, setIsShown] = useState(false);

  const statusColor = {
    succeeded: 'green',
    failed: 'red',
    canceled: 'neutral',
    processing: 'blue',
  };

  return (
    <div id='payment-history'>
      <Dialog
        isShown={isShown}
        title='Reason for Refund'
        onCloseComplete={() => {
          setReason('');
          setCurrentId('');
          setIsShown(false);
        }}
        onConfirm={() => {
          handleRefundRequest(currentId, reason);
          setIsShown(false);
        }}
        confirmLabel='Request'
      >
        <Textarea
          required={true}
          placeholder='write your reason here...'
          value={reason}
          onChange={(e: FormEvent<HTMLInputElement>) =>
            setReason(e.currentTarget.value)
          }
        />
      </Dialog>
      <div className='payment-table'>
        <div className='payment-table__container' style={{ maxWidth: '760px' }}>
          <Table>
            <Table.Head style={{ background: 'none' }}>
              <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                Start Date
              </Table.TextHeaderCell>
              <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                End Date
              </Table.TextHeaderCell>
              <Table.TextHeaderCell flexBasis={130} flexShrink={0} flexGrow={0}>
                Invoice Number
              </Table.TextHeaderCell>
              <Table.TextHeaderCell flexBasis={180} flexShrink={0} flexGrow={0}>
                Payment Method
              </Table.TextHeaderCell>
              <Table.TextHeaderCell flexBasis={80} flexShrink={0} flexGrow={0}>
                Amount
              </Table.TextHeaderCell>
              <Table.TextHeaderCell flexBasis={80} flexShrink={0} flexGrow={0}>
                Invoice
              </Table.TextHeaderCell>
              <Table.TextHeaderCell flexBasis={93} flexShrink={0} flexGrow={0}>
                Requests
              </Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              {data.map((history, i) => (
                <Table.Row key={i}>
                  <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    {history.start_date}
                  </Table.TextCell>
                  <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    {history.end_date}
                  </Table.TextCell>
                  <Table.TextCell flexBasis={130} flexShrink={0} flexGrow={0}>
                    <Badge color='neutral'>{history.id.split('-')[0]}</Badge>
                  </Table.TextCell>
                  <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0}>
                    Card ends with {history.card}
                  </Table.TextCell>
                  <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0}>
                    {history.amount}
                  </Table.TextCell>
                  <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0}>
                    <IconButton
                      appearance='minimal'
                      icon='link'
                      iconSize={18}
                      is='a'
                      target='_blank'
                      href={history.stripe_invoice_url}
                    />
                  </Table.TextCell>
                  <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    {history.refund && (
                      <Badge color={statusColor[history.refund.toLowerCase()]}>
                        {history.refund}
                      </Badge>
                    )}

                    {!history.refund && (
                      <Badge
                        color='blue'
                        isInteractive
                        onClick={() => {
                          setCurrentId(history.id);
                          setIsShown(true);
                        }}
                      >
                        Refund
                      </Badge>
                    )}
                  </Table.TextCell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default PaymentHistory;
