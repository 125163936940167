import React from 'react';
import { Tooltip, Pane, Spinner, IconButton } from 'evergreen-ui';

interface Props {
  loader: boolean;
  isProUser: boolean;
  title: string;
  handleExport(): void;
}

const DatalabExport: React.FC<Props> = ({
  loader,
  title,
  handleExport,
  isProUser,
}) => {
  return loader ? (
    <Tooltip content='Exporting...'>
      <Pane display='flex' alignItems='center' marginRight={26}>
        <Spinner size={20} />
      </Pane>
    </Tooltip>
  ) : (
    <Tooltip content={title}>
      <IconButton
        onClick={() => handleExport()}
        appearance='minimal'
        icon='export'
        marginRight={15}
        iconSize={16}
        className={!isProUser ? 'pro-badge' : ''}
      />
    </Tooltip>
  );
};

export default DatalabExport;
