import React, { Fragment } from 'react';
import ReportingRowCards from './../../component/ReportingRowCards';
import ReportingCard from './../../component/ReportingCard';
import UserMHeatmapGrid from './../../component/UserMHeatmapGrid';
import ReportingPieGraph from './../../component/ReportingPieGraph';
import ReportingTable from './../../component/ReportingTable';
import { connect } from 'react-redux';

interface props {
  userCount: any;
  loader: boolean;
  interactiveUserCount: any;
  intentGrowthData: any;
  referralGrowthData: any;
  heatmapData: any;
  heatmapDataLoader: boolean;
  genderData: any;
  postbackVTextData: any;
  languageData: any;
  timezoneData: any;
  countryData: any;
  triggeredMsgReferralnKeyword: any;
  currentRefIndex: any;
  currentIntentIndex: any;
  onTabChange: (tab: any, pointer: number) => void;
  triggeredMsgIntentnPostback: any;
  linegraphLoader: boolean;
  defaultDataLoader: boolean;
}
const UserMetrics: React.FC<props> = ({
  userCount,
  loader,
  interactiveUserCount,
  intentGrowthData,
  referralGrowthData,
  heatmapData,
  heatmapDataLoader,
  genderData,
  postbackVTextData,
  languageData,
  timezoneData,
  countryData,
  triggeredMsgReferralnKeyword,
  currentIntentIndex,
  currentRefIndex,
  onTabChange,
  triggeredMsgIntentnPostback,
  linegraphLoader,
  defaultDataLoader,
}) => {

    const [refSearch,setRefSearch] =  React.useState('');
    const [intentSearch,setIntentSearch] =  React.useState('');
    return (
    <Fragment>
      <div className='reporting-container__user-row-card'>
        <ReportingRowCards
          title={'New User'}
          tooltipInfo='Number of new users who have signed up in the platform during the selected timeframe.'
          data={userCount?.count}
          loading={loader}
          growth={userCount?.growth}
          growthType={userCount?.growthType}
          additionalGrowthText={`vs last ${userCount?.days_span} days`}
        />
        <ReportingRowCards
          title={'Interactive User'}
          tooltipInfo='Number of users (new/old) who have interacted with the platform during the selected timeframe.'
          data={interactiveUserCount?.count}
          loading={loader}
          growth={interactiveUserCount?.growth}
          growthType={interactiveUserCount?.growthType}
          additionalGrowthText={`vs last ${interactiveUserCount?.days_span} days`}
        />
        <ReportingRowCards
          title={'Referral'}
          tooltipInfo='Reach of users who have entered the platform using shared links.'
          data={referralGrowthData?.count}
          loading={loader}
          growth={referralGrowthData?.growth}
          growthType={referralGrowthData?.growthType}
          additionalGrowthText={`vs last ${referralGrowthData?.days_span} days`}
        />
        <ReportingRowCards
          title={'Intent'}
          tooltipInfo='Number of topics based on the queries being asked using the platform’s NLP module.'
          data={intentGrowthData?.count}
          loading={loader}
          growth={intentGrowthData?.growth}
          growthType={intentGrowthData?.growthType}
          additionalGrowthText={`vs last ${intentGrowthData?.days_span} days`}
        />
      </div>
      <div className='reporting-container__user-card-container'>
        <ReportingCard
          title={'Languages'}
          data={languageData?.max?.value || 0}
          additionalData={languageData?.max?.name || 'Not Available'}
          loader={linegraphLoader}
          growth={languageData?.max?.growth || '0.00%'}
          growthType={languageData?.growthType || 'up'}
          additionalGrowthText='vs last week'
          tooltipInfo='Highest used language and the comparison with other languages during the selected timeframe. '
          showLegend={false}
          isTimebasedCard={false}
          color={'#007462'}
          graphData={languageData?.data || []}
        />
        <ReportingCard
          title={'Timezone'}
          data={timezoneData?.max?.value || 0}
          additionalData={
            timezoneData?.max?.name > 0
              ? '+' + timezoneData?.max?.name + ' GMT'
              : timezoneData?.max?.name + ' GMT' || 'Not Available'
          }
          loader={linegraphLoader}
          growth={timezoneData?.max?.growth || '0.00%'}
          growthType={timezoneData?.growthType || 'up'}
          additionalGrowthText='vs last week'
          tooltipInfo='Timezone of the region from where the highest amount of users are interacting along with the comparison with other regions.'
          showLegend={false}
          isTimebasedCard={true}
          color={'#F3B52F'}
          graphData={timezoneData?.data || []}
        />
        <ReportingCard
          title={'Country'}
          data={countryData?.max?.value || 0}
          additionalData={countryData?.max?.name || 'Not Available'}
          loader={linegraphLoader}
          growth={countryData?.max?.growth || '0.00%'}
          growthType={countryData?.growthType || 'up'}
          additionalGrowthText='vs last week'
          tooltipInfo='Shows the country from where most users are situated and comparison of the usage with other countries'
          showLegend={false}
          isTimebasedCard={false}
          color={'#184d47'}
          graphData={countryData?.data || []}
        />
      </div>
      <UserMHeatmapGrid
        data={heatmapData}
        loader={heatmapDataLoader}
        tooltipInfo='Heatmap indicates how engaged the users were in different hours during the selected timeframe'
      />
      <div className='reporting-container__user-card-container'>
        <ReportingPieGraph
          color='#EF7711'
          loader={defaultDataLoader}
          title='Gender'
          toolTipInfo='Ratio between males, females and other categories of users.'
          data={genderData}
        />
        <ReportingPieGraph
          color='#EF7711'
          loader={defaultDataLoader}
          title='Textual message vs Postback'
          toolTipInfo='Ratio of messages which were sent manually by an agent against the automated messages from the bot.'
          data={postbackVTextData}
        />
      </div>
      <div className='reporting-container__user-card-container'>
        <ReportingTable
          tabData={[
            { id: 0, name: 'Referral Details' },
            { id: 1, name: 'Keyword Details' },
          ]}
          currentTab={currentRefIndex}
          onTabChange={(tab: any) => onTabChange(tab, 0)}
          setSearchKeyword={setRefSearch}
          searchKeyword={refSearch}
          tableData={!!triggeredMsgReferralnKeyword ? triggeredMsgReferralnKeyword.filter((elem:any)=> !!elem.title && elem.title.toLowerCase().includes(refSearch.toLowerCase())): []}
        />

        <ReportingTable
          tabData={[
            { id: 0, name: 'Intent Details' },
            { id: 1, name: 'Postback Buttons' },
          ]}
          currentTab={currentIntentIndex}
          onTabChange={(tab: any) => onTabChange(tab, 1)}
          setSearchKeyword={setIntentSearch}
          searchKeyword={intentSearch}
          tableData={!!triggeredMsgIntentnPostback  ? triggeredMsgIntentnPostback.filter((elem:any)=> !!elem.title && elem.title.toLowerCase().includes(intentSearch.toLowerCase())): []}
        />
      </div>
    </Fragment>
  );
};

const mapState = (state: any) => ({
  userCount: state.reporting.userCount,
  interactiveUserCount: state.reporting.interactiveUserCount,
  intentGrowthData: state.reporting.intentGrowthData,
  referralGrowthData: state.reporting.referralGrowthData,
  loader: state.loading.effects.reporting.fetchUserReportData,
  linegraphLoader:
    state.loading.effects.reporting.fetchUserReportDataUserMetricsLineGraph,
  defaultDataLoader:
    state.loading.effects.reporting.fetchUserReportDataDefaultAppend,
  heatmapData: state.reporting.heatmapData,
  heatmapDataLoader: state.loading.effects.reporting.fetchUserReportHeatmap,
  genderData: state.reporting.genderData,
  postbackVTextData: state.reporting.postbackVTextData,
  languageData: state.reporting.languageData,
  timezoneData: state.reporting.timezoneData,
  countryData: state.reporting.countryData,
  triggeredMsgReferralnKeyword: state.reporting.triggeredMsgReferralnKeyword,
  triggeredMsgIntentnPostback: state.reporting.triggeredMsgIntentnPostback,
});

const mapDispatch = (dispatch: any) => ({});

const UserMetricsContainer = connect(mapState, mapDispatch)(UserMetrics);

export default UserMetricsContainer;
