import React, { Component } from 'react';
import { connect } from 'react-redux';
import './assets/styles/style.scss';
import LoaderLine from '../../components/loaders/LoaderLine';
import { Button, Dialog, SideSheet, Table, Pill } from 'evergreen-ui';
import { Col, Row } from 'react-flexbox-grid';
import { navigate } from '@reach/router';
import ReactGA from 'react-ga';
import CreateChannelIcon from './assets/images/createChannelIcon.svg';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import DashboardChannelCard from './components/DashboardChannelCard';
import DashboardActivityCard from './components/DashboardActivityCard';
import sadEmoji from './../crm/assets/images/sademoji.svg';
import Banner from './components/Banner';
import { checkPermission, getUpdatedPlatformName } from '../../utils';
import CloneChannelModal from './components/CloneChannelModal';
import Pagination from 'rc-pagination';
import BillingMessage from './components/BillingMessage';
import TrialRemainingCard from '../../components/TrialRemainingCard';
import tickIcon from '../../assets/images/alertImages/success.svg';
import crossIcon from '../../assets/images/alertImages/cross.svg';
import 'rc-pagination/assets/index.css';
import {
  setAmplitudeUserGroup,
  setAmplitudeUserProperties,
} from './../../utilities/amplitude';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCloneChannel: null,
      showSubscriptionDetails: false,
      currentPage: 1,
      platformPage: [],
      platformList: [],
    };
  }

  async componentDidMount() {
    ReactGA.pageview(
      window.location.pathname + window.location.search + window.location.hash
    );
    await this.props.cleanProjectHistory();
    await this.props.fetchProject();
    await this.props.fetchPlans();
    const projects = this.props.dashboard.projects;
    if (projects.length === 0) {
      this.props.setSelectedProject(null);
      this.props.setSelectedPlatform(null);
    }
    if (projects.length > 0 && !this.props.dashboard.selectedProject) {
      await this.props.setSelectedProject(projects[0]);
      setAmplitudeUserGroup(projects[0].id);
      setAmplitudeUserProperties({
        billing_state: projects[0].billing_state,
        is_pro: projects[0].is_pro,
        is_creator: projects[0].is_creator,
      });
      if (
        this.props.dashboard.projects[0].platforms.length > 0 &&
        !this.props.dashboard.selectedPlatform
      ) {
        await this.props.setSelectedPlatform(projects[0].platforms[0]);
      }
      await this.props.fetchPlatformList(projects[0].id);
      await this.props.fetchDashboardActivity(projects[0].id);
      this.updatePlatforms(projects[0].platforms);
    } else {
      const updatedProject = projects.filter(
        (project) => project.id === this.props.dashboard.selectedProject.id
      );
      if (updatedProject.length > 0) {
        this.props.setSelectedProject(updatedProject[0]);
        this.updatePlatforms(updatedProject[0].platforms);
      }
      await this.props.fetchPlatformList(
        this.props.dashboard.selectedProject.id
      );
      await this.props.fetchDashboardActivity(
        this.props.dashboard.selectedProject.id
      );
    }

    this.props.updateSelectedNav(0);
  }

  updatePlatforms = (platforms) => {
    this.setState({
      platformList: platforms,
      platformPage: platforms.slice(0, 5),
    });
  };

  onPaginationChange = (page) => {
    const index = (page - 1) * 5;

    this.setState({
      currentPage: page,
      platformPage: this.state.platformList.slice(index, index + 5),
    });
  };

  render() {
    const projects = this.props.dashboard.projects;
    const selectedProject = this.props.dashboard.selectedProject;
    const isCreator = selectedProject?.is_creator;

    const mau = selectedProject
      ? selectedProject.platforms.reduce(
          (a, b) => ({
            monthly_active_users:
              a.monthly_active_users + b.monthly_active_users,
          }),
          { monthly_active_users: 0 }
        ).monthly_active_users
      : 0;

    return (
      <main className='dashboard'>
        <div
          className='project-list-container'
          style={
            projects.length > 8
              ? { height: `calc(100vh - 45px)`, overflow: 'auto' }
              : { height: 'inherit' }
          }
        >
          {this.props.loading && (
            <div style={{ width: '100%' }}>
              <LoaderLine
                style={{ height: `calc(100vh - 150px)` }}
                color={'#007B65'}
              />
            </div>
          )}
          {!this.props.loading && projects.length > 0 && (
            <div className='dashboard-container'>
              <div className='dashboard-channel-list'>
                <Row>
                  <Col xs={12} sm={8} md={9} lg={9}>
                    <Banner
                      connectCount={this.props.dashboard.platformList.length}
                    />
                  </Col>
                  <Col xs={12} sm={4} md={3} lg={3}>
                    <div className='dashboard-channel-list__mau'>
                      <p className='dashboard-channel-list__mau-plan'>
                        {selectedProject
                          ? selectedProject.subscription_plan?.name
                          : 'Free'}{' '}
                        Plan
                      </p>
                      <h4>Monthly Active Users</h4>
                      <CircularProgressbar
                        value={selectedProject ? mau : 0}
                        maxValue={
                          selectedProject
                            ? selectedProject.subscription_plan?.max_mau
                            : 100
                        }
                        text={`${mau}`}
                        styles={buildStyles({
                          textSize: '36px',
                          pathColor: '#11C392',
                          textColor: '#007B65',
                          trailColor: '#EFEFFF',
                        })}
                      />
                      <p className='dashboard-channel-list__mau-meta'>
                        As of Today
                      </p>
                      <Button
                        className='alice-btn-alt'
                        onClick={() =>
                          this.setState({ showSubscriptionDetails: true })
                        }
                      >
                        Details
                      </Button>
                    </div>
                  </Col>
                </Row>

                <br />
                <p className='dashboard-channel-list__header'>
                  Connected Platforms
                </p>
                <br />
                <Row>
                  <Col xs={12} sm={6} md={4} lg={4}>
                    <div className='dashboard-channel-list__card pd-30px justify-center align-center'>
                      <img
                        src={CreateChannelIcon}
                        alt={'create channel card'}
                        width={100}
                      />
                      <p className='dashboard-channel-list__card__title'>
                        Create A Channel
                      </p>
                      <p className='dashboard-channel-list__card__description'>
                        Create a channel and start serving your customers right
                        away! Share the channel with your team to streamline
                        support.
                      </p>
                      <Button
                        onClick={() => navigate('/settings/channel')}
                        className='alice_btn-white-primary'
                      >
                        Lets Go!
                      </Button>
                    </div>
                  </Col>
                  {!!selectedProject &&
                    this.props.dashboard.platformList.map((channel, index) => (
                      <Col xs={12} sm={6} md={4} lg={4} key={index}>
                        <DashboardChannelCard
                          channel={channel}
                          index={index}
                          setCloneChannel={(channelId) =>
                            this.setState({ currentCloneChannel: channelId })
                          }
                          isDisabled={
                            !checkPermission(
                              this.props.permissionList,
                              'read:settings'
                            )
                          }
                        />
                      </Col>
                    ))}
                </Row>
              </div>
              <div className='dashboard-other-section'>
                <div className='dashboard-subscription-message'>
                  <BillingMessage
                    plan={
                      selectedProject && selectedProject.subscription_plan
                        ? selectedProject.subscription_plan.plan_type
                        : 'Free'
                    }
                    state={
                      selectedProject
                        ? selectedProject.billing_state
                        : 'new_subscriber'
                    }
                    trialAvailed={this.props.auth.trial_availed}
                    isCreator={isCreator}
                  />
                </div>
                {selectedProject &&
                  isCreator &&
                  !selectedProject.is_pro &&
                  (!selectedProject ||
                    selectedProject?.subscription_plan?.plan_type === 'free') &&
                  this.props.auth.trial_availed && (
                    <div className='dashboard-trial-remaining'>
                      <TrialRemainingCard
                        trialRemaining={this.props.auth.trial_remaining}
                        trialAvailed={this.props.auth.trial_availed}
                      />
                    </div>
                  )}
                <br />
                <p className='dashboard-channel-list__header ml-20px'>
                  Activities
                </p>
                {this.props.dashboard.activityData.length === 0 && (
                  <div className='flex align-center mt-15px ml-20px'>
                    <img src={sadEmoji} alt='sad emoji' width={16} />
                    <p style={{ marginLeft: 10, color: 'gray' }}>No Activity</p>
                  </div>
                )}
                {this.props.dashboard.activityData.length > 0 &&
                  this.props.dashboard.activityData
                    .sort((a, b) => b.created_at - a.created_at)
                    .map((activity, index) => (
                      <DashboardActivityCard key={index} activity={activity} />
                    ))}
              </div>
            </div>
          )}
        </div>
        <Dialog
          isShown={!!this.state.currentCloneChannel}
          hasHeader={false}
          hasFooter={false}
          width={450}
          onCloseComplete={() => this.setState({ currentCloneChannel: null })}
        >
          {({ close }) => (
            <CloneChannelModal
              close={close}
              currentCloneChannel={this.state.currentCloneChannel}
              channels={this.props.dashboard.platformList}
              fetchChannelCloneBlocks={(cloneType) =>
                this.props.fetchChannelCloneBlocks(
                  this.state.currentCloneChannel,
                  cloneType
                )
              }
              cloneChannel={(cloneType, name, description) =>
                this.props.cloneChannel(
                  this.state.currentCloneChannel,
                  cloneType,
                  name,
                  description
                )
              }
            />
          )}
        </Dialog>
        <SideSheet
          isShown={this.state.showSubscriptionDetails}
          onCloseComplete={() =>
            this.setState({ showSubscriptionDetails: false })
          }
        >
          <div className='dashboard-subscription-details'>
            <div className='dashboard-subscription-details__header'>
              <h2>Subscription Details</h2>
              <p>Your Package & Usage Details</p>
            </div>
            <div className='dashboard-subscription-details__plan'>
              <h3>
                {selectedProject
                  ? selectedProject.subscription_plan?.name
                  : 'Free'}{' '}
                Plan
              </h3>
              <p>Subscription Started:</p>
            </div>
            <Table className='dashboard-subscription-details__table'>
              <Table.Head className='dashboard-subscription-details__table-head'>
                <Table.TextHeaderCell
                  flexBasis={300}
                  flexShrink={0}
                  flexGrow={0}
                >
                  Channels
                </Table.TextHeaderCell>
                <Table.TextHeaderCell
                  flexBasis={100}
                  flexShrink={0}
                  flexGrow={0}
                >
                  Connection
                </Table.TextHeaderCell>
                <Table.TextHeaderCell
                  flexBasis={100}
                  flexShrink={0}
                  flexGrow={0}
                >
                  Active Users
                </Table.TextHeaderCell>
              </Table.Head>
              <Table.Body>
                {this.state.platformPage.map((channel) => (
                  <Table.Row
                    key={channel.id}
                    className='dashboard-subscription-details__table-row'
                  >
                    <Table.TextCell flexBasis={300} flexShrink={0} flexGrow={0}>
                      <p>
                        <strong>{getUpdatedPlatformName(channel.type)}</strong>
                      </p>
                      <p>{channel.name}</p>
                    </Table.TextCell>
                    <Table.TextCell
                      flexBasis={100}
                      flexShrink={0}
                      flexGrow={0}
                      justifyContent='center'
                    >
                      {channel.is_connected ? (
                        <img
                          src={tickIcon}
                          width={25}
                          height={25}
                          alt='Connected'
                        />
                      ) : (
                        <img
                          src={crossIcon}
                          width={21}
                          height={21}
                          alt='Not Connected'
                        />
                      )}
                    </Table.TextCell>
                    <Table.TextCell
                      isNumber
                      flexBasis={50}
                      flexShrink={0}
                      flexGrow={0}
                      justifyContent='center'
                    >
                      <Pill>{channel.monthly_active_users}</Pill>
                    </Table.TextCell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            <Pagination
              className='alice-pagination'
              onChange={this.onPaginationChange}
              current={this.state.currentPage}
              pageSize={5}
              total={this.state.platformList.length}
            />
          </div>
        </SideSheet>
      </main>
    );
  }
}

const mapState = (state) => ({
  auth: state.auth,
  dashboard: state.dashboard,
  loading: state.loading.effects.dashboard.fetchProject,
  permissionList: state.dashboard.permission.list || [],
});

const mapDispatch = (dispatch) => ({
  fetchProject: () => dispatch.dashboard.fetchProject(),
  fetchPlans: () => dispatch.billing.fetchPlans(),
  setSelectedProject: (project) =>
    dispatch.dashboard.setSelectedProject(project),
  setSelectedPlatform: (platform) =>
    dispatch.dashboard.setSelectedPlatform(platform),
  cleanProjectHistory: () => dispatch.dashboard.cleanProjectHistory(),
  updateSelectedNav: (navId) => dispatch.dashboard.updateSelectedNav(navId),
  fetchPlatformList: (projectId) =>
    dispatch.dashboard.fetchPlatformList(projectId),
  fetchDashboardActivity: (projectId) =>
    dispatch.dashboard.fetchDashboardActivity(projectId),
  fetchChannelCloneBlocks: (channelId, cloneType) =>
    dispatch.dashboard.fetchChannelCloneBlocks({ channelId, cloneType }),
  cloneChannel: (channelId, cloneType, name, description) =>
    dispatch.dashboard.cloneChannel({
      channelId,
      cloneType,
      name,
      description,
    }),
  logout: () => dispatch.auth.logout(),
});

const DashboardContainer = connect(mapState, mapDispatch)(Dashboard);

export default DashboardContainer;
