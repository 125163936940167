import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import {
  Button,
  toaster,
  IconButton,
  Tooltip,
  Position,
  InlineAlert,
} from 'evergreen-ui';
import { Link } from '@reach/router';
import CreditCard from './CreditCard';
import CardSection from './CardSection';

const PaymentMethod = ({
  handlePaymentMethod,
  handleCardAction,
  userEmail,
  cardInfo,
  hasWriteAccess,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [disabled, setDisabled] = useState(false);
  const [defaultLoading, setDefaultLoading] = useState({});
  const [deleteLoading, setDeleteLoading] = useState({});

  const [showUpgradeMessage, setShowUpgradeMessage] = useState(false);

  const handleSubmit = async (type) => {
    if (!stripe || !elements) {
      return;
    }

    setDisabled(true);

    const cardElement = elements.getElement(CardElement);

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        email: userEmail,
      },
    });
    if (result.error) {
      toaster.danger(result.error.message, {
        duration: 1,
      });
      setDisabled(false);
      return;
    }
    let data = {
      payment_method: result.paymentMethod.id,
    };

    if (type === 'payment') {
      await handlePaymentMethod(data);
      setShowUpgradeMessage(true);
    } else {
      data = { ...data, action: 'attach' };
      await handleCardAction(data);
    }

    cardElement.clear();
    setDisabled(false);
  };

  return (
    <div className='mb-0'>
      {cardInfo &&
        cardInfo.map((card, i) => {
          return (
            <div key={i} className='card-container' id='payment-method'>
              <CreditCard
                brand={card.brand}
                last4={card.last4}
                expire={`${card.exp_month}/${card.exp_year}`}
              />
              <Tooltip content='Make Default' position={Position.TOP}>
                <IconButton
                  className='mr-5px'
                  icon='credit-card'
                  appearance='minimal'
                  marginLeft={16}
                  height={40}
                  isLoading={
                    defaultLoading.index === i ? defaultLoading.state : false
                  }
                  disabled={
                    card.default ||
                    (deleteLoading.index === i ? deleteLoading.state : false)
                  }
                  onClick={async () => {
                    setDefaultLoading({ index: i, state: true });
                    const data = {
                      payment_method: card.pm_id,
                      action: 'default',
                    };
                    await handleCardAction(data);
                    setDefaultLoading({ index: i, state: false });
                  }}
                />
              </Tooltip>
              <Tooltip content='Delete Card' position={Position.TOP}>
                <IconButton
                  icon='trash'
                  appearance='minimal'
                  intent='danger'
                  height={40}
                  isLoading={
                    deleteLoading.index === i ? deleteLoading.state : false
                  }
                  disabled={
                    card.default ||
                    (defaultLoading.index === i ? defaultLoading.state : false)
                  }
                  onClick={async () => {
                    setDeleteLoading({ index: i, state: true });
                    const data = {
                      payment_method: card.pm_id,
                      action: 'detach',
                    };
                    await handleCardAction(data);
                    setDeleteLoading({ index: i, state: false });
                  }}
                />
              </Tooltip>
            </div>
          );
        })}

      <div className='card-section-container'>
        <CardSection disabled={disabled || !hasWriteAccess} />
        {cardInfo && cardInfo.length > 0 && (
          <Tooltip content='Add Card' position={Position.TOP}>
            <IconButton
              icon='add'
              appearance='minimal'
              height={40}
              marginLeft={16}
              disabled={disabled || !hasWriteAccess}
              isLoading={disabled}
              onClick={() => handleSubmit('add')}
            />
          </Tooltip>
        )}
      </div>

      {(!cardInfo || cardInfo.length === 0) && (
        <Button
          onClick={() => handleSubmit('payment')}
          className='alice-btn-primary'
          disabled={disabled || !hasWriteAccess}
          isLoading={disabled}
        >
          Add Card
        </Button>
      )}

      {showUpgradeMessage && (
        <InlineAlert intent='success'>
          Card has been added as your payment method. You can now{' '}
          <Link to='/settings/billing'>Upgrade</Link> to your desired package.
        </InlineAlert>
      )}
    </div>
  );
};

PaymentMethod.propTypes = {
  handlePaymentMethod: PropTypes.func,
  handleCardAction: PropTypes.func,
  userEmail: PropTypes.string,
  planId: PropTypes.number,
  projectId: PropTypes.number,
  cardInfo: PropTypes.array,
  hasWriteAccess: PropTypes.bool,
};

export default PaymentMethod;
