import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Avatar,
  Table,
  IconButton,
  Button,
  Dialog,
  TextInput,
  SearchInput,
  Menu,
  Pane,
  Spinner,
  toaster,
} from 'evergreen-ui';
import HasPermission from '../../../components/HasPermission';
import ImageUploadField from '../../../components/ImageUploadField';
import { handleImageUpload } from '../../../utils';

class TeamGroupSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAddShown: false,
      isCreateShown: false,
      isDeleteShown: false,
      isEditing: false,
      groupId: null,
      users: [],
      name: '',
      member: '',
      image: '',
      loading: false,
    };
  }

  clearState = () => {
    this.setState({
      isAddShown: false,
      isCreateShown: false,
      isDeleteShown: false,
      isEditing: false,
      groupId: null,
      users: [],
      name: '',
      member: '',
      image: '',
      loading: false,
    });
  };

  async componentDidMount() {
    this.setState({ loading: true });
    await this.props.fetchProject();
    // always needs to update information on project settings mount

    if (!this.props.previousProject && this.props.selectedProject === null) {
      this.props.setSelectedProject(this.props.projects[0]);

      await this.props.fetchProjectAccess(this.props.projects[0].id);
      await this.props.fetchTeamGroups(this.props.projects[0].id);
    }

    if (this.props.previousProject) {
      const selProject = this.props.projects.filter(
        (e) => e.id === this.props.previousProject.id
      )[0];
      this.props.setSelectedProject(selProject);

      await this.props.fetchProjectAccess(selProject.id);
      await this.props.fetchTeamGroups(selProject.id);
    }
    this.setState({ loading: false });
  }

  getMembers = (id) => {
    return this.props.projectRolesData.length > 0
      ? this.props.projectRolesData
          .filter((role) => role.groups.map((r) => r.id).includes(id))
          .map((role) => role.admin)
      : [];
  };

  filterUsers = () => {
    return this.state.member.length > 2 &&
      this.props.projectRolesData.length > 0
      ? this.props.projectRolesData.filter((member) =>
          member.admin.full_name
            .toLowerCase()
            .includes(this.state.member.toLowerCase())
        )
      : [];
  };

  handleImageUpdate = (image) => this.setState({ image: image });

  handleImageChange = (image) => {
    handleImageUpload(image, (img) => this.handleImageUpdate(img));
  };

  handleImageClear = () => {
    this.setState({ image: '' });
  };

  render() {
    return (
      <HasPermission shortCode={'read:settings'} isSettingsView>
        <div className='team-user-settings'>
          <div className='team-user-settings__info'>
            <h1>Group Management</h1>
            <Dialog
              isShown={this.state.isCreateShown}
              title={this.state.isEditing ? 'Edit Group' : 'Create New Group'}
              onCloseComplete={() => this.clearState()}
              hasFooter={false}
            >
              <div className='team-user-form'>
                <div className='team-user-form__item'>
                  <p>Name</p>
                  <TextInput
                    onChange={(e) => this.setState({ name: e.target.value })}
                    value={this.state.name}
                  />
                </div>
                <br />
                <div className='team-user-form__item'>
                  <p>Image</p>
                  <ImageUploadField
                    hasWriteAccess={true}
                    image={this.state.image}
                    handleDrop={(image) => this.handleImageChange(image)}
                    handleClear={() => this.handleImageClear()}
                  />
                </div>

                <br />
                <div className='team-user-form__item'>
                  <Button
                    className='button-alice__gradient'
                    appearance='primary'
                    isLoading={this.state.loading}
                    onClick={async () => {
                      if (this.state.name.length > 0) {
                        this.setState({ loading: true });
                        if (this.state.isEditing && this.state.groupId) {
                          const success = await this.props.updateTeamGroup(
                            this.props.selectedProject.id,
                            this.state.groupId,
                            this.state.name,
                            this.state.image
                          );
                          if (success) {
                            toaster.success('Group Updated', {
                              duration: 2,
                            });
                          } else {
                            toaster.danger('Could Not Update Group', {
                              duration: 2,
                            });
                          }
                          await this.props.fetchTeamGroups(
                            this.props.selectedProject.id
                          );
                        } else {
                          const success = await this.props.createTeamGroup(
                            this.props.selectedProject.id,
                            this.state.name,
                            this.state.image
                          );
                          if (success) {
                            toaster.success('Group Created', {
                              duration: 2,
                            });
                          } else {
                            toaster.danger('Could Not Create Group', {
                              duration: 2,
                            });
                          }
                          await this.props.fetchTeamGroups(
                            this.props.selectedProject.id
                          );
                        }
                        this.clearState();
                      } else {
                        toaster.danger('Valid Name is required', {
                          duration: 2,
                        });
                      }
                    }}
                  >
                    {this.state.isEditing ? 'Save' : 'Create'}
                  </Button>
                </div>
              </div>
            </Dialog>
            <Button
              className='button-alice__gradient'
              appearance='primary'
              onClick={() =>
                this.setState({
                  isCreateShown: true,
                })
              }
            >
              Create New Group
            </Button>
          </div>
          <div className='team-user-settings__table'>
            {this.state.loading ? (
              <Pane
                display='flex'
                alignItems='center'
                justifyContent='center'
                height={200}
              >
                <Spinner />
              </Pane>
            ) : (
              <Table>
                <Table.Head>
                  <Table.TextHeaderCell>Name</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Creator</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Members</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Edit</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Add Members</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Remove</Table.TextHeaderCell>
                </Table.Head>
                <Table.Body>
                  {this.props.teamGroups.map((group, i) => (
                    <Table.Row key={i} height={60}>
                      <Table.TextCell>
                        <div className='team-user-settings__table-name'>
                          <Avatar
                            src={group.image}
                            name={group.name}
                            size={32}
                          />
                          {group.name}
                        </div>
                      </Table.TextCell>
                      <Table.TextCell>
                        {group.creator?.full_name}
                      </Table.TextCell>
                      <Table.TextCell>
                        <div className='team-user-settings__table-group'>
                          {this.getMembers(group.id).length > 2 ? (
                            <>
                              {this.getMembers(group.id)
                                .slice(0, 2)
                                .map((member) => (
                                  <Avatar
                                    key={member.id}
                                    src={member.avatar}
                                    name={member.full_name}
                                    size={32}
                                  />
                                ))}
                              <Avatar
                                isSolid
                                color='green'
                                name={`+ ${this.getMembers(group.id).length -
                                  2}`}
                                size={32}
                              />
                            </>
                          ) : (
                            this.getMembers(group.id).map((member) => (
                              <Avatar
                                key={member.id}
                                src={member.avatar}
                                name={member.full_name}
                                size={32}
                              />
                            ))
                          )}
                        </div>
                      </Table.TextCell>
                      <Table.TextCell>
                        {
                          <IconButton
                            icon='edit'
                            intent='success'
                            appearance='minimal'
                            onClick={() =>
                              this.setState({
                                isEditing: true,
                                isCreateShown: true,
                                groupId: group.id,
                                name: group.name,
                                image: group.image,
                              })
                            }
                          />
                        }
                      </Table.TextCell>
                      <Table.TextCell>
                        {
                          <IconButton
                            icon='add'
                            intent='success'
                            appearance='minimal'
                            onClick={() =>
                              this.setState({
                                isCreateShown: false,
                                isAddShown: true,
                                groupId: group.id,
                              })
                            }
                          />
                        }
                      </Table.TextCell>
                      <Table.TextCell>
                        {
                          <IconButton
                            icon='trash'
                            intent='danger'
                            appearance='minimal'
                            onClick={() =>
                              this.setState({
                                isDeleteShown: true,
                                groupId: group.id,
                              })
                            }
                          />
                        }
                      </Table.TextCell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            )}
            <Dialog
              isShown={this.state.isAddShown}
              title='Add Member'
              onCloseComplete={() => this.clearState()}
              hasFooter={false}
            >
              <div className='team-user-form'>
                <div className='team-user-form__item'>
                  <SearchInput
                    onChange={(e) => this.setState({ member: e.target.value })}
                    value={this.state.member}
                    placeholder='Search name...'
                    width='100%'
                    height={40}
                  />
                  {this.filterUsers().length > 0 ? (
                    <Menu>
                      <Menu.Group>
                        {this.filterUsers().map((user, i) => (
                          <Menu.Item
                            key={i}
                            onSelect={() => {
                              this.setState({
                                member: '',
                                users: [...this.state.users, user.admin],
                              });
                            }}
                          >
                            {user.admin.full_name}
                          </Menu.Item>
                        ))}
                      </Menu.Group>
                    </Menu>
                  ) : this.state.member.length > 2 ? (
                    'No user found'
                  ) : (
                    ''
                  )}

                  {this.state.users.length > 0 && (
                    <div className='user-tag'>
                      {this.state.users.map((user, key) => (
                        <div className='user-tag-item' key={key}>
                          {user.full_name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <br />
                <div className='team-user-form__item'>
                  <Button
                    className='button-alice__gradient'
                    appearance='primary'
                    isLoading={this.state.loading}
                    onClick={async () => {
                      if (this.state.users.length > 0) {
                        this.setState({ loading: true });

                        const users = this.state.users.map((usr) => usr.id);
                        this.props.projectRolesData.forEach((role) => {
                          if (
                            users.includes(role.admin.id) &&
                            this.state.groupId
                          ) {
                            this.props.addMemberToGroup(
                              this.props.selectedProject.id,
                              role.admin.id,
                              [
                                ...role.groups.map((g) => g.id),
                                this.state.groupId,
                              ]
                            );
                          }
                        });

                        await this.props.fetchTeamGroups(
                          this.props.selectedProject.id
                        );
                        await this.props.fetchProjectAccess(
                          this.props.selectedProject.id
                        );

                        this.setState({ loading: false });
                        this.clearState();
                      } else {
                        toaster.danger('At least one user is required', {
                          duration: 2,
                        });
                      }
                      this.setState({ loading: false });
                    }}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </Dialog>
            <Dialog
              isShown={this.state.isDeleteShown}
              onCloseComplete={() => this.clearState()}
              hasFooter={false}
              hasHeader={false}
            >
              <p className='channel-settings__card-disable-warning'>
                Are you sure want to remove the user?
              </p>
              <div className='flex justify-center mb-20px'>
                <Button
                  className='button-alice__gradient'
                  onClick={async () => {
                    if (this.state.groupId) {
                      const success = await this.props.deleteTeamGroup(
                        this.props.selectedProject.id,
                        this.state.groupId
                      );
                      if (success) {
                        toaster.success('Group Deleted', {
                          duration: 2,
                        });
                      } else {
                        toaster.danger('Could Not Delete Group', {
                          duration: 2,
                        });
                      }
                      await this.props.fetchTeamGroups(
                        this.props.selectedProject.id
                      );
                      this.clearState();
                    }
                  }}
                >
                  Yes Do It!
                </Button>
                <Button
                  marginLeft={30}
                  className='btn-outline-danger'
                  onClick={() => this.setState({ isDeleteShown: false })}
                >
                  Cancel
                </Button>
              </div>
            </Dialog>
          </div>
        </div>
      </HasPermission>
    );
  }
}

const mapState = (state) => ({
  projects: state.dashboard.projects,
  selectedProject: state.settings.selectedProject,
  previousProject: state.dashboard.selectedProject,
  teamGroups: state.settings.teamGroups,
  projectRolesData: state.settings.currentProjectRoles,
});

const mapDispatch = (dispatch) => ({
  fetchProject: () => dispatch.dashboard.fetchProject(),
  fetchProjectAccess: (projectId) =>
    dispatch.settings.fetchProjectAccess(projectId),
  fetchTeamGroups: (projectId) => dispatch.settings.fetchTeamGroups(projectId),
  createTeamGroup: (projectId, name, image) =>
    dispatch.settings.createTeamGroup({
      projectId,
      name,
      image,
    }),
  updateTeamGroup: (projectId, groupId, name, image) =>
    dispatch.settings.updateTeamGroup({ projectId, groupId, name, image }),
  deleteTeamGroup: (projectId, groupId) =>
    dispatch.settings.deleteTeamGroup({ projectId, groupId }),
  addMemberToGroup: (projectId, accessId, groupIds) =>
    dispatch.settings.addMemberToGroup({ projectId, accessId, groupIds }),
  setSelectedProject: (payload) =>
    dispatch.settings.setSelectedProject(payload),
});

const TeamGroupSettingsContainer = connect(
  mapState,
  mapDispatch
)(TeamGroupSettings);

export default TeamGroupSettingsContainer;
