import React from 'react';
import PropTypes from 'prop-types';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import 'rc-time-picker/assets/index.css';
import {
  TextInputField,
  Button,
  Combobox,
  SelectMenu,
  TextInput,
  Icon,
  IconButton,
} from 'evergreen-ui';
import BlockContainer from './BlockContainer';
import subscribeIcon from './../../pages/builder/assets/images/icons/subcribe.svg';
import './../../assets/styles/blocks/subscribe.scss';

const options = [
  { label: 'Time Based', value: 'time' },
  { label: 'Delay Based', value: 'delay' },
];

const SubscribeBlock = ({
  id,
  save,
  saveBlock,
  saveLoader,
  data,
  sequences,
  deleteBlock,
  updateSubscribeBlockTitle,
  updateSubscribeSequenceData,
  createSubscribeElement,
  copyBlock,
  moveBlock,
  handleSubscribeDelete,
  hasWriteAccess,
}) => {
  return (
    <BlockContainer
      id={id}
      title='Subscribe'
      subtitle='Click Add New to add a subscribe block'
      save={save}
      isLoading={saveLoader}
      icon={subscribeIcon}
      sequences={sequences}
      handleSaveClick={() => saveBlock(id)}
      handleDelete={() => deleteBlock(id)}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <div className='subscribe-container'>
        <TextInputField
          label='Provide a Unique Title for the subscription block'
          onChange={(e) => updateSubscribeBlockTitle(id, e.target.value)}
          placeholder='Ex. Offers'
          value={data.title}
          disabled={!hasWriteAccess}
        />
        {!!data.sequences &&
          data.sequences.length > 0 &&
          data.sequences.map((sequenceElem, i) => (
            <div key={i} className='subscribe-container__sequence-card'>
              <div className='w-100p'>
                <Combobox
                  openOnFocus
                  width={'100%'}
                  className='dropwdown-style__custom'
                  disabled={!hasWriteAccess}
                  inputProps={{ disabled: true }}
                  selectedItem={
                    sequenceElem.sequence !== null &&
                    sequences.filter(
                      (evt) => evt.id === sequenceElem.sequence
                    )[0]
                  }
                  items={sequences}
                  itemToString={(item) => (item ? item.title : '')}
                  onChange={(selected) =>
                    selected &&
                    updateSubscribeSequenceData(id, i, selected.id, 'sequence')
                  }
                  placeholder='Sequence'
                />
              </div>
              <div className='select-menu__default w-100p'>
                <SelectMenu
                  title='Type'
                  height={110}
                  width={200}
                  hasFilter={false}
                  options={options}
                  selected={sequenceElem.type}
                  closeOnSelect={true}
                  onSelect={(item) => {
                    updateSubscribeSequenceData(id, i, item.value, 'type');
                  }}
                >
                  <Button disabled={!hasWriteAccess} style={{ width: '95%' }}>
                    {(!!sequenceElem.type &&
                      options.filter(
                        (option) => option.value === sequenceElem.type
                      )[0].label) ||
                      'Select Delay...'}
                    <Icon icon='chevron-down' size={16} />
                  </Button>
                </SelectMenu>
              </div>
              {!!sequenceElem.type && sequenceElem.type === 'time' && (
                <div className='w-100p'>
                  <TimePicker
                    disabled={!hasWriteAccess}
                    value={
                      sequenceElem.value
                        ? moment.utc(sequenceElem.value, 'HH:mm').local()
                        : undefined
                    }
                    allowEmpty={false}
                    showSecond={false}
                    use12Hours={true}
                    onChange={(val) =>
                      updateSubscribeSequenceData(
                        id,
                        i,
                        moment.utc(val).format('HH:mm'),
                        'value'
                      )
                    }
                  />
                </div>
              )}
              {!!sequenceElem.type && sequenceElem.type === 'delay' && (
                <div className='w-100p'>
                  <TextInput
                    type={'number'}
                    disabled={!hasWriteAccess}
                    width='100%'
                    onChange={(e) =>
                      updateSubscribeSequenceData(
                        id,
                        i,
                        parseInt(e.target.value),
                        'value'
                      )
                    }
                    placeholder={'Provide Delay Time'}
                    value={sequenceElem.value}
                  />
                </div>
              )}

              <div>
                {hasWriteAccess && (
                  <IconButton
                    onClick={() => handleSubscribeDelete(id, i)}
                    appearance='primary'
                    intent='danger'
                    icon='cross'
                  />
                )}
              </div>
            </div>
          ))}
        {!!data.sequences && hasWriteAccess && (
          <Button
            onClick={() => createSubscribeElement(id)}
            marginRight={12}
            intent='success'
            appearance='primary'
            iconBefore='add'
          >
            Add A Subscription
          </Button>
        )}
      </div>
    </BlockContainer>
  );
};

SubscribeBlock.propTypes = {
  id: PropTypes.number.isRequired,
  save: PropTypes.bool.isRequired,
  saveBlock: PropTypes.func.isRequired,
  attributeList: PropTypes.arrayOf(PropTypes.string),
  deleteBlock: PropTypes.func.isRequired,
  saveLoader: PropTypes.bool.isRequired,
  handleSubscribeDelete: PropTypes.func.isRequired,
  hasWriteAccess: PropTypes.bool,
};

SubscribeBlock.defaultState = {
  deleteBlock: (blockId, SequenceId) =>
    console.log('delete from default props', blockId, SequenceId),
  hasWriteAccess: false,
};

export default SubscribeBlock;
