import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'evergreen-ui';

const CenterSpinner = ({ height, width }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: height,
        width: width,
      }}
    >
      <Spinner />
    </div>
  );
};
CenterSpinner.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};
CenterSpinner.defaultProps = {
  height: '200px',
  width: '100%',
};
export default CenterSpinner;
