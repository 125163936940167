import React, { useState } from 'react';
import { Textarea } from 'evergreen-ui';
import PropTypes from 'prop-types';
import BlockContainer from './BlockContainer';
import AttributeAPIContainer from '../elements/AttributeAPIContainer';
import { updateOnSelectAttribute, updateOnChangeText } from './../../utils';
import textIcon from './../../pages/builder/assets/images/icons/text.svg';
import TextCharInfo from '../elements/TextCharInfo';

const TextBlock = ({
  id,
  text,
  setText,
  save,
  type,
  saveBlock,
  attributeList,
  apiList,
  deleteBlock,
  sequences,
  copyBlock,
  moveBlock,
  saveLoader,
  hasWriteAccess,
}) => {
  const [showAttribute, setShowAttribute] = useState(false);
  const [showAPI, setShowAPI] = useState(false);
  const [maxCount] = useState(2000);

  return (
    <BlockContainer
      id={id}
      title='Text'
      subtitle='Type your content here'
      save={save}
      isLoading={saveLoader}
      icon={textIcon}
      handleSaveClick={() => saveBlock(id)}
      handleDelete={() => deleteBlock(id)}
      sequences={sequences}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <div>
        <AttributeAPIContainer
          attributeList={attributeList}
          showAttribute={showAttribute}
          setShowAttribute={setShowAttribute}
          apiList={apiList.filter((api) => api.return_type === type)}
          showAPI={showAPI}
          setShowAPI={setShowAPI}
          handleSelect={(value) => {
            (text + value).length + 2 <= maxCount &&
              updateOnSelectAttribute(
                value,
                showAttribute,
                showAPI,
                setShowAttribute,
                setShowAPI,
                setText,
                text,
                apiList
              );
          }}
        >
          <Textarea
            required={true}
            grammarly={true}
            spellCheck={true}
            value={text}
            disabled={!hasWriteAccess}
            onChange={(e) =>
              e.target.value.length <= maxCount &&
              updateOnChangeText(
                e.target.value,
                setShowAttribute,
                setShowAPI,
                setText
              )
            }
            placeholder='Your text block content'
          />
        </AttributeAPIContainer>
        <TextCharInfo data={text} count={maxCount} />
      </div>
    </BlockContainer>
  );
};

TextBlock.propTypes = {
  id: PropTypes.number.isRequired,
  save: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  setText: PropTypes.func.isRequired,
  saveBlock: PropTypes.func.isRequired,
  attributeList: PropTypes.arrayOf(PropTypes.string),
  apiList: PropTypes.arrayOf(PropTypes.object),
  deleteBlock: PropTypes.func.isRequired,
  moveBlock: PropTypes.func.isRequired,
  copyBlock: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  saveLoader: PropTypes.bool.isRequired,
  hasWriteAccess: PropTypes.bool.isRequired,
};

TextBlock.defaultState = {
  deleteBlock: (blockId, SequenceId) =>
    console.log('delete from default props', blockId, SequenceId),
};

export default TextBlock;
