import React from 'react';
import SettingsHeader from './SettingsHeader';
import CenterSpinner from '../../../components/elements/CenterSpinner';
import { Button, Alert } from 'evergreen-ui';

const SettingsFormContainer = ({
  id,
  title,
  description,
  hasData,
  infoLoader,
  infoUpdateLoader,
  isInfoChanged,
  handleSave,
  handleCancel,
  children,
}) => {
  return (
    <div className='settings-card' id={id}>
      <SettingsHeader title={title} subtitle={description} />
      {infoLoader ? (
        <CenterSpinner />
      ) : hasData ? (
        <div className='settings-card__container'>
          <br />
          {children}
          <br />
          {isInfoChanged && (
            <div className='flex'>
              <Button
                onClick={handleSave}
                isLoading={infoUpdateLoader}
                className='button-alice__gradient'
                marginRight={10}
                iconBefore={!infoUpdateLoader ? 'endorsed' : null}
              >
                Update
              </Button>
              <Button
                onClick={handleCancel}
                isLoading={infoLoader}
                className='button-alice__secondary_2'
                iconBefore={'cross'}
              >
                Cancel
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div>
          <br />
          <br />
          <Alert intent='danger' title={`We weren’t able to fetch ${title}`} />
        </div>
      )}
    </div>
  );
};

export default SettingsFormContainer;
