import axios from '../../../httpClient';
import config from '../../../config';

const initialState = {
  projects: [],
  selectedProject: null,
  selectedPlatform: null,
  intentList: [],
  navIsOpen: false,
  navActiveId: 0,
  permission: { id: null, list: [] },
  platformList: [],
  activityData: [],
};

export const dashboard = {
  state: {
    ...initialState,
  },
  reducers: {
    updateProject(state, payload) {
      /*
      payload = [{
        id: int,
        name: string,
        slug: string,
        image: string,
        platforms: [
          {
            id: int,
            name: string,
            type: string
          }
        ]
      }...]
      */
      return { ...state, projects: payload };
    },
    updateActivityData(state, payload) {
      return { ...state, activityData: payload };
    },
    updateNavToggle(state) {
      return { ...state, navIsOpen: !state.navIsOpen };
    },
    updateSelectedNav(state, navId) {
      return { ...state, navActiveId: navId };
    },
    updateIntents(state, payload) {
      /* payload = list of string for intents */
      return { ...state, intentList: payload };
    },
    updateSelectedProject(state, payload) {
      /*
      payload: object
      */

      return { ...state, selectedProject: payload };
    },
    updateSelectedProjectToPro(state) {
      return {
        ...state,
        selectedProject: { ...state.selectedProject, is_pro: true },
      };
    },
    updatePermission(state, payload) {
      //payload= list of permission
      return { ...state, permission: payload };
    },
    setSelectedPlatform(state, payload) {
      return { ...state, selectedPlatform: payload };
    },
    updatePlatformList(state, payload) {
      return { ...state, platformList: payload };
    },
    cleanHistory(state) {
      return { ...state, selectedProject: null, selectedPlatform: null };
    },
    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async fetchProject() {
      try {
        const res = await axios.get(config.projects);
        if (res.data.success) {
          dispatch.dashboard.updateProject(res.data.dataSource);
        } else {
          dispatch.dashboard.updateProject([]);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchIntent(payload) {
      /*
      payload = {
        platformId : num
        }
       */
      try {
        const res = await axios.get(`${config.platform}/${payload}/intents`);
        if (res.data.success) {
          dispatch.dashboard.updateIntents(res.data.dataSource);
        } else {
          dispatch.dashboard.updateIntents([]);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async setSelectedProject(payload, rootState) {
      /*
      payload: object of project
      */
      await dispatch.dashboard.updateSelectedProject(payload);
      if (!rootState.loading.effects.dashboard.fetchRolePermission) {
        if (rootState.dashboard.permission?.id !== payload?.id) {
          await dispatch.dashboard.fetchRolePermission(payload?.id);
        }
      }
    },
    async fetchRolePermission(id) {
      /*
      id : num // project Id
       */
      try {
        const res = await axios.get(`${config.projects}/${id}/permissions`);
        if (res.status === 200 && res.data.success) {
          dispatch.dashboard.updatePermission({
            id: id,
            list: res.data.dataSource,
          });
        } else {
          dispatch.dashboard.updatePermission({ id: id, list: [] });
        }
      } catch (err) {
        console.log(err?.response || err);
      }
    },
    async fetchPlatformList(id) {
      /*
      id : num // project Id
       */
      try {
        const res = await axios.get(`${config.projects}/${id}/platforms/list`);
        if (res.status === 200 && res.data.success) {
          dispatch.dashboard.updatePlatformList(res.data.dataSource);
        } else {
          dispatch.dashboard.updatePlatformList([]);
        }
      } catch (err) {
        console.log(err?.response || err);
        dispatch.dashboard.updatePlatformList([]);
      }
    },
    async fetchDashboardActivity(id) {
      /*
      id : num // project Id
       */
      try {
        const res = await axios.get(`${config.projects}/${id}/activity`);
        if (res.status === 200 && res.data.success) {
          dispatch.dashboard.updateActivityData(res.data.dataSource);
        } else {
          dispatch.dashboard.updateActivityData([]);
        }
      } catch (err) {
        dispatch.dashboard.updateActivityData([]);
      }
    },
    async fetchChannelCloneBlocks(payload) {
      /*
      payload: channelId, cloneType
       */
      try {
        const res = await axios.post(
          `${config.platform}/${payload.channelId}/disallowed-blocks`,
          {
            cloned_platform_type: payload.cloneType,
          }
        );
        if (res.status === 200 && res.data.success) {
          return res.data.dataSource;
        } else {
          return [];
        }
      } catch (err) {
        return [];
      }
    },
    async cloneChannel(payload, rootState) {
      /*
      payload: channelId, cloneType, name , description
       */
      try {
        const res = await axios.post(
          `${config.platform}/${payload.channelId}/clone`,
          {
            platform_type: payload.cloneType,
            platform_name: payload.name,
            description: payload.description,
          }
        );
        if (res.status === 200 && res.data.success) {
          await dispatch.dashboard.fetchProject();
          if (!!rootState.dashboard.selectedProject) {
            const project = rootState.dashboard.projects.filter(
              (project) => project.id === rootState.dashboard.selectedProject.id
            )[0];
            dispatch.dashboard.setSelectedProject(project);
            dispatch.dashboard.fetchPlatformList(project.id);
          } else {
            dispatch.dashboard.setSelectedProject(
              rootState.dashboard.projects[0]
            );
            dispatch.dashboard.fetchPlatformList(
              rootState.dashboard.projects[0].id
            );
          }
          return true;
        } else {
          return false;
        }
      } catch (err) {
        return false;
      }
    },
    async cleanProjectHistory() {
      dispatch.api.clearState();
      dispatch.builder.clearState();
      dispatch.block.clearState();
      dispatch.settings.clearState();
      dispatch.api.clearState();
      dispatch.dataLab.clearState();
      dispatch.labDetails.clearState();
      dispatch.audience.clearState();
      dispatch.reporting.clearState();
      dispatch.marketplace.clearState();
      dispatch.crm.clearState();
      dispatch.channel.clearState();
    },
  }),
};
