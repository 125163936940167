import axios from '../../../httpClient';
import config from '../../../config';
import { toaster } from 'evergreen-ui';
import CloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

const initialState = {
  activeSettings: 0,
  selectedProject: null,
  userInfo: null,
  pendingProjects: [],
  currentProjectRoles: [],
  userRolesType: [],
  adminTags: [],
  teamGroups: [],
};

export const settings = {
  state: {
    ...initialState,
  },
  reducers: {
    updateActiveSettings(state, payload) {
      // payload : num // id of the settings menu
      return { ...state, activeSettings: payload };
    },
    updateUserInfo(state, payload) {
      // payload: object // info about user
      return { ...state, userInfo: payload };
    },
    updateSingleUserInfo(state, payload) {
      // payload = {key: string, value: any }
      return {
        ...state,
        userInfo: { ...state.userInfo, [payload.key]: payload.value },
      };
    },
    updateFacebookMessengerSecondaryId(state, secondaryId) {
      /*
      secondaryId : number
       */
      let selectedProjectLocalPlatforms = CloneDeep(
        state.selectedProject.platforms
      );
      selectedProjectLocalPlatforms.map((platform) => {
        if (platform.type === 'facebook_messenger') {
          platform.secondary_receiver_id = secondaryId;
          return platform;
        }
        return platform;
      });
      return {
        ...state,
        selectedProject: {
          ...state.selectedProject,
          platforms: selectedProjectLocalPlatforms,
        },
      };
    },
    setSelectedProject(state, payload) {
      /*
      payload: int
      */
      return { ...state, selectedProject: payload };
    },
    updateNLPInfo(state, payload) {
      /*
        payload: {key: string, value: string}
       */
      let selectedProject = {
        ...state.selectedProject,
        [payload.key]: payload.value,
      };
      return { ...state, selectedProject };
    },
    updatePendingProjects(state, payload) {
      /* payload : array of pending projects */
      return { ...state, pendingProjects: payload };
    },
    updateRolesData(state, payload) {
      return { ...state, currentProjectRoles: payload };
    },
    updateUserRoles(state, payload) {
      return { ...state, userRolesType: payload };
    },
    updateAdminGroup(state, payload) {
      return { ...state, adminGroup: payload };
    },
    updateAdminGroupDelete(state, payload) {
      return {
        ...state,
        adminGroup: state.adminGroup.filter((group) => group.id !== payload),
      };
    },
    updateAdminGroupAdd(state, payload) {
      let updatedAdmin = state.adminGroup;
      updatedAdmin = updatedAdmin.filter((e) => e.id !== payload.id);

      return { ...state, adminGroup: [...updatedAdmin, payload] };
    },
    updateTeamGroups(state, payload) {
      return { ...state, teamGroups: payload };
    },
    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async fetchUserInfo() {
      try {
        const res = await axios.get(config.info);
        dispatch.settings.updateUserInfo(res.data.dataSource);
      } catch (err) {
        return false;
      }
    },
    async editUserInfo(payload) {
      try {
        const res = await axios.patch(config.info, payload);
        if (res.data.success) {
          dispatch.settings.updateUserInfo(res.data.dataSource);
          dispatch.auth.initLogin(res.data.dataSource);
          toaster.success('Update Info', {
            description: 'Information Updated Successfully',
            duration: 1,
          });
          return true;
        }
      } catch (err) {
        toaster.warning('Error', {
          description: err.response.data.error,
          duration: 1,
        });
        return false;
      }
    },

    async editProjectInfo(projectId, rootState) {
      /**
       * payload: int (projectId)
       */
      try {
        const res = await axios.put(`${config.projects}/${projectId}`, {
          name: rootState.settings.selectedProject.name,
          image: rootState.settings.selectedProject.image,
          description: rootState.settings.selectedProject.description,
        });
        if (res.data.success) {
          dispatch.settings.setSelectedProject(res.data.dataSource);
          dispatch.dashboard.setSelectedProject(res.data.dataSource);
          toaster.success('Update Info', {
            description: 'Information Updated Successfully',
            duration: 1,
          });
          return true;
        }
      } catch (err) {
        toaster.warning('Error', {
          description: err.response.data.error,
          duration: 1,
        });
        return false;
      }
    },

    async sendTeamInvitation(payload) {
      /*
      payload = {
        teamId: num,
        email: string,
        roleId : num,
      }
       */

      try {
        const res = await axios.post(
          `${config.projects}/${payload.teamId}/invite`,
          { email: payload.email, role_id: payload.roleId }
        );
        console.log('RES: ', res.data);
        if (res.data.success) {
          toaster.success('Invitation Sent Successfully');
        }
      } catch (err) {
        console.log('ERR: ', err.response);
        toaster.danger(err.response.data.error);
      }
    },

    async acceptTeamInvitation(payload) {
      /*
      payload = {
        token: string,
      }
       */

      try {
        const res = await axios.post(`${config.projects}/invitation-accept`, {
          token: payload.token,
        });
        if (res.data.success) {
          toaster.success('Invitation Accepted');
        }
      } catch (err) {
        toaster.danger(err.response.data.error);
      }
    },

    async deleteUserFromTeam(payload) {
      /*
      payload = {
        teamId: num,
        userId: num
      }
       */

      try {
        const res = await axios.delete(
          `${config.projects}/${payload.teamId}/access/${payload.userId}`
        );
        if (res.data.success) {
          toaster.success('User Removed Successfully');
        }
      } catch (err) {
        toaster.danger(err.response.data.error);
      }
    },

    async updateUserFromTeam(payload) {
      /*
      payload = {
        teamId: num,
        userId: num,
        roleId: num,
      }
       */

      try {
        const res = await axios.patch(
          `${config.projects}/${payload.teamId}/access/${payload.userId}`,
          { role_id: payload.roleId }
        );
        if (res.data.success) {
          toaster.success('User Updated Successfully');
        }
      } catch (err) {
        toaster.danger(err.response.data.error);
      }
    },

    async fetchTeamGroups(projectId) {
      /*
        payload = {
          projectId: num // Projects ID
        }
       */
      try {
        const res = await axios.get(`${config.projects}/${projectId}/groups`);
        if (res.data.success) {
          dispatch.settings.updateTeamGroups(res.data.dataSource);
        } else {
          dispatch.settings.updateTeamGroups([]);
        }
      } catch (err) {
        err?.response?.data?.error &&
          toaster.danger(err?.response?.data?.error);
      }
    },

    async createTeamGroup(payload) {
      /*
        payload = {
          projectId: num // Projects ID
          name: string
          image: string
        }
       */
      try {
        const res = await axios.post(
          `${config.projects}/${payload.projectId}/groups`,
          {
            name: payload.name,
            image: payload.image,
          }
        );
        return res.data.success;
      } catch (err) {
        toaster.danger(err.response.data.error);
      }
    },

    async updateTeamGroup(payload) {
      /*
        payload = {
          projectId: num // Projects ID
          groupId: num
          name: string
          image: string
        }
       */
      try {
        const res = await axios.patch(
          `${config.projects}/${payload.projectId}/groups/${payload.groupId}`,
          {
            name: payload.name,
            image: payload.image,
          }
        );
        return res.data.success;
      } catch (err) {
        toaster.danger(err.response.data.error);
      }
    },

    async deleteTeamGroup(payload) {
      /*
        payload = {
          projectId: num // Projects ID
          groupId: num
        }
       */
      try {
        const res = await axios.delete(
          `${config.projects}/${payload.projectId}/groups/${payload.groupId}`
        );
        return res.data.success;
      } catch (err) {
        toaster.danger(err.response.data.error);
      }
    },

    async addMemberToGroup(payload) {
      /*
        payload = {
          projectId: num // Projects ID
          accessId: num
          groupIds: [num, num...]
        }
       */
      try {
        const res = await axios.patch(
          `${config.projects}/${payload.projectId}/access/${payload.accessId}`,
          {
            group_ids: payload.groupIds,
          }
        );
        return res.data.success;
      } catch (err) {
        toaster.danger(err.response.data.error);
      }
    },

    async createPlatform(payload) {
      /*
      payload = {
        id : num,
        platforms: array
      }
       */
      try {
        const res = await axios.post(
          `${config.projects}/${payload.id}/platforms/initialize`,
          { platforms: payload.platforms }
        );
        if (res.data.success) {
          toaster.success('Platform Created Successfully');
          await dispatch.dashboard.fetchProject();
          await dispatch.dashboard.setSelectedProject(res.data.dataSource);
          await dispatch.settings.setSelectedProject(res.data.dataSource);
        }
      } catch (err) {
        toaster.danger(err.response.data.error);
      }
    },
    async fetchFacebookPages(payload) {
      /*
        payload = {
          id: num // fb userID
          token: string //temp accessToken
        }
       */
      try {
        const res = await axios.get(
          `${config.fbConnect}/${payload.id}/pages?token=${payload.token}`
        );
        return res.data;
      } catch (err) {
        toaster.danger(err.response.data.error);
      }
    },
    async connectPlatform(payload) {
      /*
        payload = {
          id: num // project id
          type: string // facebook || viber
          data: object // page information
        }
       */
      try {
        const res = await axios.post(
          `${config.projects}/${payload.id}/platforms/connect`,
          { type: payload.type, data: payload.data }
        );
        if (res.data.success) {
          await dispatch.settings.setSelectedProject(res.data.dataSource);
          await dispatch.dashboard.fetchProject();
          await dispatch.dashboard.setSelectedProject(res.data.dataSource);
          if (res.data.dataSource.platforms.length > 0) {
            await dispatch.dashboard.setSelectedPlatform(
              res.data.dataSource.platforms[0]
            );
          }
          toaster.success('Platform Connected Successfully!');
        }
      } catch (err) {
        toaster.danger(err.response.data.error);
      }
    },
    async disconnectPlatform(payload) {
      /*
       payload = {
         id: num // project id
         type: string // facebook || viber
       }
      */
      try {
        const res = await axios.post(
          `${config.projects}/${payload.id}/platforms/disconnect`,
          { type: payload.type }
        );
        if (res.data.success) {
          await dispatch.settings.setSelectedProject(res.data.dataSource);
          await dispatch.dashboard.fetchProject();
          await dispatch.dashboard.setSelectedProject(res.data.dataSource);
          if (res.data.dataSource.platforms.length > 0) {
            await dispatch.dashboard.setSelectedPlatform(
              res.data.dataSource.platforms[0]
            );
          }
        }
      } catch (err) {
        toaster.danger(err.response.data.error);
      }
    },
    async connectNLPEngine(payload, rootState) {
      /*
      payload = null
      */
      try {
        const nlp_engine = rootState.settings.selectedProject.nlp_engine;
        const nlp_info = rootState.settings.selectedProject.nlp_info;
        const nlp_confidence =
          rootState.settings.selectedProject.nlp_confidence;

        const projectId = rootState.settings.selectedProject.id;
        if (!!nlp_engine && !isEmpty(nlp_info) && !!projectId) {
          if (nlp_engine === 'wit' && !!nlp_info.token && !!nlp_info.app_id) {
            const res = await axios.patch(`${config.projects}/${projectId}`, {
              nlp_engine,
              nlp_info,
              nlp_confidence,
            });
            if (res.data.success) {
              toaster.success('Updated SuccessFully', {
                description: `Information Updated Successfully!`,
              });
              dispatch.settings.setSelectedProject(res.data.dataSource);
              dispatch.dashboard.fetchProject();
            }
          } else {
            toaster.warning('Data Missing', {
              description: 'Provide all required field',
            });
          }
        } else {
          toaster.warning('Data Missing', {
            description: 'Provide all required field',
          });
        }
      } catch (e) {
        toaster.danger('Failed to Connect (Exception)', {
          description: 'There is a Problem in Our System. Please re-try  again',
        });
      }
    },
    async connectHandoverProtocol(payload) {
      /*
      payload =
          platformId: number
          appId: string
       */
      try {
        const res = await axios.post(
          `${config.platform}/${payload.platformId}/secondary-receiver`,
          {
            app_id: payload.appId,
          }
        );
        if (res.data.success) {
          toaster.success('Handover Protocol Updated', {
            description: 'Congrats! Handover Protocol Has been Updated',
          });
          dispatch.settings.updateFacebookMessengerSecondaryId(payload.appId);
          return true;
        }
        return false;
      } catch (err) {
        !!err.response && toaster.danger(err.response.data.error);
      }
    },
    async editPersistentMenu(payload) {
      /* payload =
          platformId: number ,
          data: {
                  button: [
            {
                'id': num,
                'title': string,
                'type': string,
                'value': number/string,
            },
            ...
            ]
         }
     */

      try {
        const res = await axios.post(
          `${config.platform}/${payload.platformId}/persistent-menu`,
          payload.data
        );

        if (res.data.success) {
          dispatch.settings.setSelectedProject(res.data.dataSource);
        }
        return res.data.success;
      } catch (err) {
        console.log(err.response);
      }
    },
    async fetchPendingProjects() {
      try {
        const res = await axios.get(`${config.projects}/pending`);
        if (res.status === 200 && res.data.success) {
          dispatch.settings.updatePendingProjects(res.data.dataSource);
        } else {
          toaster.danger('Failed to Delete this Admin Group');
        }
      } catch (err) {
        console.log(err.response);
        toaster.danger('Failed to Delete this Admin Group');
      }
    },
    async editPendingProject(payload, rootState) {
      /* payload :
            project_id: number;
            accept: boolean;
       */
      try {
        const res = await axios.post(`${config.projects}/pending`, payload);
        if (res.status === 200 && res.data.success) {
          dispatch.settings.updatePendingProjects(
            rootState.settings.pendingProjects.filter(
              (pendingProject) =>
                pendingProject.project?.id !== payload.project_id
            )
          );
          dispatch.dashboard.fetchProject();
          toaster.success(res.data?.message || 'Project Added Successfully');
        } else {
          toaster.danger('Failed to update permission of this project');
        }
      } catch (err) {
        toaster.danger(
          err.response?.data?.error ||
            'Failed to update permission of this project'
        );
      }
    },
    async fetchProjectAccess(projectId) {
      try {
        const res = await axios.get(`${config.projects}/${projectId}/access`);
        if (res.status === 200 && res.data.success) {
          dispatch.settings.updateRolesData(res.data.dataSource);
        } else {
          dispatch.settings.updateRolesData([]);
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async fetchPermissionRole(projectId) {
      try {
        const res = await axios.get(`${config.projects}/${projectId}/roles`);
        if (res.status === 200 && res.data.success) {
          dispatch.settings.updateUserRoles(res.data.dataSource);
        } else {
          dispatch.settings.updateUserRoles([]);
        }
      } catch (err) {
        dispatch.settings.updateUserRoles([]);
        console.log(err.response);
      }
    },
    async shareProject(payload) {
      /*
      payload = {
        id : num,
        email: string,
        roleId: number //id of role
      }
       */
      try {
        const res = await axios.post(`${config.projects}/${payload.id}/share`, {
          email: payload.email,
          role_id: payload.roleId,
        });

        if (res.status === 200 && res.data.success) {
          toaster.success('Project Shared Successfully');
          return true;
        } else {
          toaster.danger('Failed to Share Project');
          return false;
        }
      } catch (err) {
        if (!!err.response && !!err.response.data) {
          toaster.danger(err.response.data.error);
        } else {
          toaster.danger('Failed to Share Project');
        }
        return false;
      }
    },
    async deleteAdminRole(payload) {
      /*
      payload = {
        id : num, //project id
        roleId: number //id of role
      }
       */
      try {
        const res = await axios.delete(
          `${config.projects}/${payload.id}/access/${payload.roleId}`
        );

        if (res.status === 200 && res.data.success) {
          toaster.success('Deleted Successfully');
          dispatch.settings.fetchProjectAccess(payload.id);
        } else {
          toaster.danger('Failed to Delete Admin');
        }
      } catch (err) {
        if (!!err.response && !!err.response.data) {
          toaster.danger(err.response.data.error);
        } else {
          toaster.danger('Failed to Delete Admin');
        }
      }
    },
    async fetchAdminGroup(payload) {
      /*
      payload = {
        id : num, //project id
      }
       */
      try {
        const res = await axios.get(
          `${config.projects}/${payload}/access-tags`
        );
        if (res.status === 200 && res.data.success) {
          dispatch.settings.updateAdminGroup(res.data.dataSource);
        } else {
          dispatch.settings.updateAdminGroup([]);
        }
      } catch (err) {
        console.log(err);
        dispatch.settings.updateAdminGroup([]);
      }
    },
    async deleteAdminGroup(payload) {
      /*
      payload = {
        projectId : num, //project id
        groupId: number
      }
       */
      try {
        const res = await axios.delete(
          `${config.projects}/${payload.projectId}/access-tags/${payload.groupId}`
        );
        if (res.status === 200 && res.data.success) {
          dispatch.settings.updateAdminGroupDelete(payload.groupId);
          toaster.success('Successful', {
            description: 'Successfully Deleted Admin Group',
            duration: 1,
          });
        } else {
          toaster.danger('Error', {
            description: 'Failed to Delete Admin Group ! Try Again',
            duration: 1,
          });
        }
      } catch (err) {
        console.log(err);
        toaster.danger('Error', {
          description: 'Failed to Delete Admin Group ! Try Again',
          duration: 1,
        });
      }
    },
    async createAdminGroup(payload) {
      /*
      payload = {
        projectId : num, //project id
        name: string
      }
       */
      try {
        const res = await axios.post(
          `${config.projects}/${payload.projectId}/access-tags`,
          { name: payload.name }
        );

        if (res.status === 200 && res.data.success) {
          dispatch.settings.updateAdminGroupAdd(res.data.dataSource);
          toaster.success('Successful', {
            description: 'Successfully Added Admin Group',
            duration: 1,
          });
        } else {
          toaster.danger('Error', {
            description: 'Failed to Add Admin Group ! Try Again',
            duration: 1,
          });
        }
      } catch (err) {
        console.log(err);
        toaster.danger('Error', {
          description: 'Failed to Add Admin Group ! Try Again',
          duration: 1,
        });
      }
    },
    async editAdminGroup(payload) {
      /*
      payload = {
        projectId : num, //project id
        groupId: num
        name: string
      }
       */
      try {
        const res = await axios.patch(
          `${config.projects}/${payload.projectId}/access-tags/${payload.groupId}`,
          { name: payload.name }
        );

        if (res.status === 200 && res.data.success) {
          dispatch.settings.updateAdminGroupAdd(res.data.dataSource);
          toaster.success('Successful', {
            description: 'Successfully Added Admin Group',
            duration: 1,
          });
        } else {
          toaster.danger('Error', {
            description: 'Failed to Add Admin Group ! Try Again',
            duration: 1,
          });
        }
      } catch (err) {
        console.log(err);
        toaster.danger('Error', {
          description: 'Failed to Add Admin Group ! Try Again',
          duration: 1,
        });
      }
    },
    async assignAdminGroup(payload) {
      /*
      payload = {
        projectId : num, //project id
        groupId: num
        tags: string[]
      }
       */
      try {
        const res = await axios.put(
          `${config.projects}/${payload.projectId}/access/${payload.adminId}`,
          { tag_ids: payload.tags }
        );

        if (res.status === 200 && res.data.success) {
          dispatch.settings.fetchProjectAccess(payload.projectId);
          toaster.success('Successful', {
            description: 'Successfully Assigned Admin Group',
            duration: 1,
          });
        } else {
          toaster.danger('Error', {
            description: 'Failed to Add Assign Admin Group ! Try Again',
            duration: 1,
          });
        }
      } catch (err) {
        console.log(err);
        toaster.danger('Error', {
          description: 'Failed to Assign Admin Group ! Try Again',
          duration: 1,
        });
      }
    },
  }),
};
