import React from 'react';
import WooCommerceHeader from "./WooCommerceHeader";
import {navigate} from "@reach/router";
import Confetti from './../../../assets/icons/marketplace/confetti.svg';
import {Button} from "evergreen-ui";

interface Prop {
  location: any;
  handleClose: ()=> void;
}

const WooCommerceConfirmModalContainer: React.FC<Prop> = ({location,handleClose}) => {


  return (
    <div className='woocommerce-modal'>
      <WooCommerceHeader
        handleClose={() => {
          navigate(location.pathname);
        }
        }/>
      <div className='woocommerce-modal__content flex flex-column justify-center'>
        <img src={Confetti} className='mt-20px' style={{margin: '0 auto'}} width={120} alt='confetti' />
        <br/>
        <p className='woocommerce-modal__content__success-title'>Integration Successful!</p>
        <p className='woocommerce-modal__content__success-subtitle'>Click Finish to proceed.</p>
        <div className='w-100p flex flex-end mt--40px'>
          <Button className='alice-btn__secondary' onClick={()=>handleClose()}>Finish</Button>
        </div>
      </div>
    </div>
  );
};

export default WooCommerceConfirmModalContainer;
