import React from 'react';
import { getGraphForReporting } from '../../../utils';
import {Icon, Position, Spinner, Tooltip} from 'evergreen-ui';

interface props {
  title: string;
  tooltipInfo: string;
  data: number | string;
  growth: string;
  additionalGrowthText: string;
  growthType: 'up' | 'down';
  loading: boolean
}

const ReportingRowCards: React.FC<props> = ({
  title,
  tooltipInfo,
  data,
  growth,
  additionalGrowthText,
    growthType,
    loading
}) => {
  return (
    <div className='reporting-container__user-row-card-elem'>
      <div>
        <p className='reporting-container__user-row-card-elem-title'>
          {title} &nbsp;
          <Tooltip
            content={tooltipInfo || 'Not Available'}
            position={Position.TOP}
          >
            <Icon color='success' size={14} icon={'info-sign'} />
          </Tooltip>
        </p>
          {!loading && <>
              <p className='reporting-container__user-row-card-elem-data'>{data || 0}</p>
              <p className='reporting-container__user-row-card-elem-info'>
                  <img
                      src={
                          growthType === 'up'
                              ? getGraphForReporting('up')
                              : getGraphForReporting('down')
                      }
                      width='14'
                      alt="growth-icon"
                  />
                  &nbsp;
                  <span className={growthType === 'up' ? 'success-report' : 'danger-report'}>
            {growth}
          </span>{' '}
                  {additionalGrowthText}
              </p>
          </>
          }
          {loading && <div className='reporting-row-card__loader'><Spinner/></div>}
      </div>
    </div>
  );
};

export default ReportingRowCards;
