import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import {
  Button,
  Pane,
  Heading,
  Paragraph,
  Dialog,
  toaster,
} from 'evergreen-ui';
import { proFeatures } from '../content';
import CrossClockIcon from '../assets/images/cross-clock.svg';
import ErrorIcon from '../assets/images/error.svg';

const StartTrialFlow = ({
  trialAvailed,
  trialRemaining,
  selectedProject,
  handleTrialStart,
}) => {
  const [isTrialLoading, setIsTrialLoading] = useState(false);
  const [isConfirmShown, setConfirmShown] = useState(false);

  const trialExpired = trialAvailed && trialRemaining === 0;

  return (
    <Fragment>
      <Pane className='trial-header'>
        <Heading size={700}>Start Your Trial</Heading>
        <Paragraph>Our trial comes with world of benefits</Paragraph>
      </Pane>

      {trialExpired && (
        <Pane
          display='flex'
          alignItems='center'
          flexDirection='column'
          marginBottom={50}
          marginTop={40}
        >
          <img
            src={CrossClockIcon}
            alt='Trial Expired'
            width='110'
            height='110'
          />
          <Heading size={500} marginTop={15} display='flex' alignItems='center'>
            <img src={ErrorIcon} alt='error' /> &nbsp; Your trial has been
            expired!
          </Heading>
        </Pane>
      )}

      <Pane padding={30} paddingTop={10}>
        <Heading size={600} marginBottom={30}>
          Benefits you are {trialExpired ? 'losing' : 'getting'}:
        </Heading>
        {proFeatures.map((feat, i) => (
          <Pane marginBottom={20} key={i} display='flex' alignItems='center'>
            <img src={feat.icon} alt='feature icon' />
            <Paragraph marginLeft={10}>{feat.text}</Paragraph>
          </Pane>
        ))}

        {selectedProject.is_creator && (
          <Pane marginTop={50}>
            <Button
              className='alice-btn-alt mr-10px'
              height={40}
              marginRight={16}
              onClick={() => {
                navigate('/settings/billing');
              }}
            >
              Go to billing
            </Button>

            {!trialAvailed && (
              <Button
                height={40}
                isLoading={isTrialLoading}
                className='alice-btn-primary'
                onClick={() => {
                  setConfirmShown(true);
                }}
              >
                Start Free Trial
              </Button>
            )}
          </Pane>
        )}
      </Pane>

      <Dialog
        isShown={isConfirmShown}
        onCloseComplete={() => setConfirmShown(false)}
        hasFooter={false}
        hasHeader={false}
      >
        <div className='plan-card__dialogue'>
          <p className='mb-50px plan-card__dialogue-message'>
            Are you sure want to start the trial?
          </p>
          <p>
            You have requested to start the trial. Please be sure before your
            confirmation.
          </p>
          <br />
          <br />

          <div className='flex flex-end'>
            <Button
              marginLeft={30}
              className='alice-btn-alt mr-10px'
              onClick={() => setConfirmShown(false)}
            >
              Cancel
            </Button>
            <Button
              className='alice-btn-primary'
              isLoading={isTrialLoading}
              onClick={async () => {
                setIsTrialLoading(true);
                const success = await handleTrialStart();
                if (success) {
                  toaster.success('Congratulations!', {
                    description:
                      'Your Trial has started. Enjoy the pro features of Alice.',
                    duration: 3,
                  });
                  setIsTrialLoading(false);
                  setConfirmShown(false);
                } else {
                  toaster.danger('Sorry!', {
                    description:
                      'Could not start the trial. You might already avail it.',
                    duration: 2,
                  });
                  setIsTrialLoading(false);
                  setConfirmShown(false);
                }
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

const mapState = (state) => ({
  trialAvailed: state.auth.trial_availed,
  trialRemaining: state.auth.trial_remaining,
  selectedProject: state.dashboard.selectedProject,
});

const mapDispatch = (dispatch) => ({
  handleTrialStart: () => dispatch.billing.handleTrialStart(),
});

export default connect(mapState, mapDispatch)(StartTrialFlow);
