import React, { useState } from 'react';
import IconCard from './IconCard';
import TextIcon from '../assets/images/icons/text.svg';
import TypingIcon from '../assets/images/icons/typing.svg';
import ImageIcon from '../assets/images/icons/image.svg';
import GalleryIcon from '../assets/images/icons/gallery.svg';
import ButtonsIcon from '../assets/images/icons/buttons.svg';
import AudioIcon from '../assets/images/icons/audio.svg';
import VideoIcon from '../assets/images/icons/video.svg';
import QuickRIcon from '../assets/images/icons/quickreply.svg';
import UserIcon from '../assets/images/icons/userinput.svg';
import PhoneIcon from '../assets/images/icons/phone.svg';
import EmailIcon from '../assets/images/icons/email.svg';
import EmailSendIcon from '../assets/images/icons/email-icon.svg';
import RedirectIcon from '../assets/images/icons/redirect.svg';
import MoreIcon from '../assets/images/icons/more_new.svg';
import SubscribeIcon from '../assets/images/icons/subcribe.svg';
import UnsubscribeIcon from '../assets/images/icons/unsubscribe.svg';
import LiveChatIcon from '../assets/images/icons/livechat.svg';
import AttributeIcon from '../assets/images/icons/set_attribute.svg';
import LocationIcon from '../assets/images/icons/location.svg';
import TicketsIcon from '../assets/images/icons/ticket.svg';
import StoryIcon from '../assets/images/icons/story.svg';
import { generateId } from '../../../utils';

import MoreBlockDialog from './MoreBlockDialog';

const BlockDock = ({
  blocks,
  selectedSequence,
  createBlock,
  defaultSequence,
  selectedPlatformType,
}) => {
  const [moreDialogVisible, setMoreDialogVisible] = useState(false);

  const addTextBlock = () => {
    const data = { text: '' };
    createBlock(selectedSequence, 'text', data);
    setMoreDialogVisible(false);
  };
  const addDelayBlock = () => {
    const data = {};
    createBlock(selectedSequence, 'delay', data);
    setMoreDialogVisible(false);
  };
  const addQuickReplyBlock = () => {
    const data = {
      text: '',
      save: false,
      attribute: 'default', //string
      buttons: [],
    };
    createBlock(selectedSequence, 'quick_reply', data);
    setMoreDialogVisible(false);
  };
  const addButtonBlock = () => {
    const data = {
      text: '',
      buttons: [],
    };
    createBlock(selectedSequence, 'button', data);
    setMoreDialogVisible(false);
  };
  const addRedirectBlock = () => {
    const data = { sequence: defaultSequence };
    createBlock(selectedSequence, 'redirect', data);
    setMoreDialogVisible(false);
  };
  const addImageBlock = () => {
    const data = {
      sub_type: 'image', //string
      urls: [''], // array
      api: null,
    }; // ;
    createBlock(selectedSequence, 'attachment', data);
    setMoreDialogVisible(false);
  };
  const addAudioBlock = () => {
    const data = {
      sub_type: 'audio', //string
      urls: [''], // array
    }; // ;
    createBlock(selectedSequence, 'attachment', data);
    setMoreDialogVisible(false);
  };
  const addVideoBlock = () => {
    const data = {
      sub_type: 'video', //string
      urls: [''], // array
    }; // ;
    createBlock(selectedSequence, 'attachment', data);
    setMoreDialogVisible(false);
  };
  const addGalleryBlock = () => {
    const data = {
      image_aspect_ratio: 'square',
      elements: [],
    };
    createBlock(selectedSequence, 'gallery', data);
    setMoreDialogVisible(false);
  };
  const addUserInputBlock = () => {
    const data = {
      sub_type: 'basic',
      text: '',
      attribute: 'default',
    };
    createBlock(selectedSequence, 'input', data);
    setMoreDialogVisible(false);
  };
  const addPhoneInputBlock = () => {
    const data = {
      sub_type: 'phone',
      text: '',
      attribute: 'default',
      phone_locale: [],
    };
    createBlock(selectedSequence, 'input', data);
    setMoreDialogVisible(false);
  };
  const addLocationBlock = () => {
    const data = {
      sub_type: 'location',
      text: '',
      attribute: 'default',
    };
    createBlock(selectedSequence, 'input', data);
    setMoreDialogVisible(false);
  };
  const addEmailInputBlock = () => {
    const data = {
      sub_type: 'email',
      text: '',
      attribute: 'default',
    };
    createBlock(selectedSequence, 'input', data);
    setMoreDialogVisible(false);
  };
  const addStoryBlock = () => {
    const data = {
      questions: [
        {
          id: 1,
          main_text: '', // string
          fail_text: '', // string
          primary_entity: '', // string
          role: '', // string (optional)
          is_optional: false, // string
          attribute: `${selectedSequence}-1-${generateId(6)}`, // string
        },
      ],
    };
    createBlock(selectedSequence, 'story', data);
    setMoreDialogVisible(false);
  };

  const addEmailSendBlock = () => {
    const data = {
      sender: '',
      to: [],
      cc: [],
      subject: '',
      body: '',
    };
    createBlock(selectedSequence, 'send_email', data);
    setMoreDialogVisible(false);
  };

  const addTicketBlock = () => {
    const data = {
      group_ids: [],
      agent_ids: [], // only during reading data
      priority: 0, // 0 or 1 or 2 for Low or Medium or High
      note: '',
      resolve_sequence_id: null,
    };
    createBlock(selectedSequence, 'ticket', data);
    setMoreDialogVisible(false);
  };
  const addSubscriptionBlock = () => {
    const data = {
      title: '',
      sequences: [],
    };
    createBlock(selectedSequence, 'subscribe', data);
    setMoreDialogVisible(false);
  };
  const addUnsubscribeBlock = () => {
    const data = {
      subscribe_block: null,
    };
    createBlock(selectedSequence, 'unsubscribe', data);
    setMoreDialogVisible(false);
  };
  const addLiveChatBlock = () => {
    const data = {
      text: '',
      button: {},
      timeout: 6,
    };
    createBlock(selectedSequence, 'live_chat', data);
    setMoreDialogVisible(false);
  };
  const addSetAttribute = () => {
    const data = {
      attribute: 'default',
      value: '',
    };
    createBlock(selectedSequence, 'set_attribute', data);
    setMoreDialogVisible(false);
  };

  const dataMessenger = [
    {
      name: 'Text',
      icon: TextIcon,
      handleClick: addTextBlock,
    },
    {
      name: 'Quick Reply',
      icon: QuickRIcon,
      handleClick: addQuickReplyBlock,
    },
    {
      name: 'Buttons',
      icon: ButtonsIcon,
      handleClick: addButtonBlock,
    },
    {
      name: 'Gallery',
      icon: GalleryIcon,
      handleClick: addGalleryBlock,
    },
    {
      name: 'Redirect',
      icon: RedirectIcon,
      handleClick: addRedirectBlock,
    },
    {
      name: 'More',
      icon: MoreIcon,
      handleClick: () => setMoreDialogVisible(true),
    },
  ];

  const dataFeed = [
    {
      name: 'Text',
      icon: TextIcon,
      handleClick: addTextBlock,
    },
    {
      name: 'Image',
      icon: ImageIcon,
      handleClick: addImageBlock,
    },
    {
      name: 'Send Email',
      icon: EmailSendIcon,
      handleClick: addEmailSendBlock,
    },
    {
      name: 'Ticket',
      icon: TicketsIcon,
      handleClick: addTicketBlock,
    },
  ];

  const dataViber = [
    {
      name: 'Text',
      icon: TextIcon,
      handleClick: addTextBlock,
    },
    {
      name: 'Image',
      icon: ImageIcon,
      handleClick: addImageBlock,
    },
    {
      name: 'Quick Reply',
      icon: QuickRIcon,
      handleClick: addQuickReplyBlock,
    },
    {
      name: 'Gallery',
      icon: GalleryIcon,
      handleClick: addGalleryBlock,
    },
    {
      name: 'Ticket',
      icon: TicketsIcon,
      handleClick: addTicketBlock,
    },
    {
      name: 'More',
      icon: MoreIcon,
      handleClick: () => setMoreDialogVisible(true),
    },
  ];

  const dataWebChat = [
    {
      name: 'Text',
      icon: TextIcon,
      handleClick: addTextBlock,
    },
    {
      name: 'Quick Reply',
      icon: QuickRIcon,
      handleClick: addQuickReplyBlock,
    },
    {
      name: 'Buttons',
      icon: ButtonsIcon,
      handleClick: addButtonBlock,
    },
    {
      name: 'Image/GIF',
      icon: ImageIcon,
      handleClick: addImageBlock,
    },
    {
      name: 'Gallery',
      icon: GalleryIcon,
      handleClick: addGalleryBlock,
    },
    {
      name: 'More',
      icon: MoreIcon,
      handleClick: () => setMoreDialogVisible(true),
    },
  ];

  const dataALLWebChat = [
    {
      title: 'Add and Send Content',
      data: [
        {
          name: 'Text',
          icon: TextIcon,
          handleClick: addTextBlock,
        },
        {
          name: 'Quick Reply',
          icon: QuickRIcon,
          handleClick: addQuickReplyBlock,
        },
        {
          name: 'Buttons',
          icon: ButtonsIcon,
          handleClick: addButtonBlock,
        },
        {
          name: 'Image/GIF',
          icon: ImageIcon,
          handleClick: addImageBlock,
        },
        {
          name: 'Send Email',
          icon: EmailSendIcon,
          handleClick: addEmailSendBlock,
        },
        {
          name: 'Gallery',
          icon: GalleryIcon,
          handleClick: addGalleryBlock,
        },
      ],
    },
    {
      title: 'Collect User Information',
      data: [
        {
          name: 'User Input',
          icon: UserIcon,
          handleClick: addUserInputBlock,
        },
        {
          name: 'Phone',
          icon: PhoneIcon,
          handleClick: addPhoneInputBlock,
        },
        {
          name: 'Email',
          icon: EmailIcon,
          handleClick: addEmailInputBlock,
        },
        {
          name: 'Story',
          icon: StoryIcon,
          handleClick: addStoryBlock,
        },
      ],
    },
    {
      title: 'Action',
      data: [
        {
          name: 'Redirect',
          icon: RedirectIcon,
          handleClick: addRedirectBlock,
        },
        {
          name: 'Set Attribute',
          icon: AttributeIcon,
          handleClick: addSetAttribute,
        },
        {
          name: 'Subscribe',
          icon: SubscribeIcon,
          handleClick: addSubscriptionBlock,
        },
        {
          name: 'Unsubscribe',
          icon: UnsubscribeIcon,
          handleClick: addUnsubscribeBlock,
        },
        {
          name: 'Ticket',
          icon: TicketsIcon,
          handleClick: addTicketBlock,
        },
      ],
    },
  ];

  const dataALLMessenger = [
    {
      title: 'Add and Send Content',
      data: [
        {
          name: 'Delay',
          icon: TypingIcon,
          handleClick: addDelayBlock,
        },
        {
          name: 'Text',
          icon: TextIcon,
          handleClick: addTextBlock,
        },
        {
          name: 'Image/GIF',
          icon: ImageIcon,
          handleClick: addImageBlock,
        },
        {
          name: 'Video',
          icon: VideoIcon,
          handleClick: addVideoBlock,
        },
        {
          name: 'Audio',
          icon: AudioIcon,
          handleClick: addAudioBlock,
        },
        {
          name: 'Buttons',
          icon: ButtonsIcon,
          handleClick: addButtonBlock,
        },
        {
          name: 'Quick Reply',
          icon: QuickRIcon,
          handleClick: addQuickReplyBlock,
        },
        {
          name: 'Gallery',
          icon: GalleryIcon,
          handleClick: addGalleryBlock,
        },
      ],
    },
    {
      title: 'Collect User Information',
      data: [
        {
          name: 'User Input',
          icon: UserIcon,
          handleClick: addUserInputBlock,
        },
        {
          name: 'Phone',
          icon: PhoneIcon,
          handleClick: addPhoneInputBlock,
        },
        {
          name: 'Email',
          icon: EmailIcon,
          handleClick: addEmailInputBlock,
        },
        {
          name: 'Story',
          icon: StoryIcon,
          handleClick: addStoryBlock,
        },
      ],
    },
    {
      title: 'Action',
      data: [
        {
          name: 'Redirect',
          icon: RedirectIcon,
          handleClick: addRedirectBlock,
        },
        {
          name: 'Set Attribute',
          icon: AttributeIcon,
          handleClick: addSetAttribute,
        },
        {
          name: 'Subscribe',
          icon: SubscribeIcon,
          handleClick: addSubscriptionBlock,
        },
        {
          name: 'Unsubscribe',
          icon: UnsubscribeIcon,
          handleClick: addUnsubscribeBlock,
        },
        {
          name: 'Send Email',
          icon: EmailSendIcon,
          handleClick: addEmailSendBlock,
        },
        {
          name: 'Ticket',
          icon: TicketsIcon,
          handleClick: addTicketBlock,
        },
        {
          name: 'Live Chat',
          icon: LiveChatIcon,
          handleClick: addLiveChatBlock,
        },
      ],
    },
  ];
  const dataALLViber = [
    {
      title: 'Add and Send Content',
      data: [
        {
          name: 'Text',
          icon: TextIcon,
          handleClick: addTextBlock,
        },
        {
          name: 'Image',
          icon: ImageIcon,
          handleClick: addImageBlock,
        },
        {
          name: 'Gallery',
          icon: GalleryIcon,
          handleClick: addGalleryBlock,
        },
        {
          name: 'Video',
          icon: VideoIcon,
          handleClick: addVideoBlock,
        },
        {
          name: 'Send Email',
          icon: EmailSendIcon,
          handleClick: addEmailSendBlock,
        },
      ],
    },
    {
      title: 'Collect User Data',
      data: [
        {
          name: 'Quick Reply',
          icon: QuickRIcon,
          handleClick: addQuickReplyBlock,
        },
        {
          name: 'User Input',
          icon: UserIcon,
          handleClick: addUserInputBlock,
        },
        {
          name: 'Phone',
          icon: PhoneIcon,
          handleClick: addPhoneInputBlock,
        },
        {
          name: 'Location',
          icon: LocationIcon,
          handleClick: addLocationBlock,
        },
        {
          name: 'Email',
          icon: EmailIcon,
          handleClick: addEmailInputBlock,
        },
        {
          name: 'Attribute',
          icon: AttributeIcon,
          handleClick: addSetAttribute,
        },
        {
          name: 'Story',
          icon: StoryIcon,
          handleClick: addStoryBlock,
        },
      ],
    },
    {
      title: 'Redirect User',
      data: [
        {
          name: 'Redirect',
          icon: RedirectIcon,
          handleClick: addRedirectBlock,
        },
        {
          name: 'Subscribe',
          icon: SubscribeIcon,
          handleClick: addSubscriptionBlock,
        },
        {
          name: 'Unsubscribe',
          icon: UnsubscribeIcon,
          handleClick: addUnsubscribeBlock,
        },
        {
          name: 'Ticket',
          icon: TicketsIcon,
          handleClick: addTicketBlock,
        },
      ],
    },
  ];
  const dataWhatsapp = [
    {
      name: 'Text',
      icon: TextIcon,
      handleClick: addTextBlock,
    },
    {
      name: 'Image/GIF',
      icon: ImageIcon,
      handleClick: addImageBlock,
    },
    {
      name: 'User Input',
      icon: UserIcon,
      handleClick: addUserInputBlock,
    },
    {
      name: 'Redirect',
      icon: RedirectIcon,
      handleClick: addRedirectBlock,
    },
    {
      name: 'Ticket',
      icon: TicketsIcon,
      handleClick: addTicketBlock,
    },
    {
      name: 'More',
      icon: MoreIcon,
      handleClick: () => setMoreDialogVisible(true),
    },
  ];
  const dataAllWhatsapp = [
    {
      title: 'Add and Send Content',
      data: [
        {
          name: 'Delay',
          icon: TypingIcon,
          handleClick: addDelayBlock,
        },
        {
          name: 'Text',
          icon: TextIcon,
          handleClick: addTextBlock,
        },
        {
          name: 'Image/GIF',
          icon: ImageIcon,
          handleClick: addImageBlock,
        },
        {
          name: 'Video',
          icon: VideoIcon,
          handleClick: addVideoBlock,
        },
        {
          name: 'Audio',
          icon: AudioIcon,
          handleClick: addAudioBlock,
        },
      ],
    },
    {
      title: 'Collect User Information',
      data: [
        {
          name: 'User Input',
          icon: UserIcon,
          handleClick: addUserInputBlock,
        },
        {
          name: 'Phone',
          icon: PhoneIcon,
          handleClick: addPhoneInputBlock,
        },
        {
          name: 'Location',
          icon: LocationIcon,
          handleClick: addLocationBlock,
        },
        {
          name: 'Email',
          icon: EmailIcon,
          handleClick: addEmailInputBlock,
        },
        {
          name: 'Story',
          icon: StoryIcon,
          handleClick: addStoryBlock,
        },
      ],
    },
    {
      title: 'Action',
      data: [
        {
          name: 'Redirect',
          icon: RedirectIcon,
          handleClick: addRedirectBlock,
        },
        {
          name: 'Set Attribute',
          icon: AttributeIcon,
          handleClick: addSetAttribute,
        },
        {
          name: 'Subscribe',
          icon: SubscribeIcon,
          handleClick: addSubscriptionBlock,
        },
        {
          name: 'Unsubscribe',
          icon: UnsubscribeIcon,
          handleClick: addUnsubscribeBlock,
        },
        {
          name: 'Send Email',
          icon: EmailSendIcon,
          handleClick: addEmailSendBlock,
        },
        {
          name: 'Ticket',
          icon: TicketsIcon,
          handleClick: addTicketBlock,
        },
        {
          name: 'Live Chat',
          icon: LiveChatIcon,
          handleClick: addLiveChatBlock,
        },
      ],
    },
  ];

  let data, allData;
  switch (selectedPlatformType) {
    case 'facebook_feed':
      data = dataFeed;
      break;
    case 'facebook_messenger':
      data = dataMessenger;
      allData = dataALLMessenger;
      break;
    case 'viber_messenger':
      data = dataViber;
      allData = dataALLViber;
      break;
    case 'webchat':
    case 'app_messenger':
      data = dataWebChat;
      allData = dataALLWebChat;
      break;
    case 'whatsapp_messenger':
      data = dataWhatsapp;
      allData = dataAllWhatsapp;
      break;
    default:
      data = dataMessenger;
  }

  return (
    <div className='builder-main__dock'>
      <h4>Blocks</h4>

      <div className='builder-main__dock-container'>
        {data.map((d, i) => (
          <IconCard {...d} key={i} />
        ))}
      </div>

      <MoreBlockDialog
        visible={moreDialogVisible}
        setVisible={setMoreDialogVisible}
        data={allData}
      />
    </div>
  );
};

export default BlockDock;
