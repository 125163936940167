import axios from '../../../httpClient';
import config from '../../../config';
import { toaster } from 'evergreen-ui';

const initialState = {
  sequences: [],
  selectedSequence: 0,
  subscriptions: [],
  entities: [],
};

const builder = {
  state: {
    ...initialState,
  },
  reducers: {
    updateSequence(state, payload) {
      /* 
      payload = [{
        id: int,
        title: string,
        is_default: bool
      }...]
      */
      return { ...state, sequences: payload };
    },
    updateEntities(state, payload) {
      /*
      payload = [{
        id: int,
        title: string,
        is_default: bool
      }...]
      */
      return { ...state, entities: payload };
    },
    updateSubscriptions(state, payload) {
      /* 
      payload = [{
        id: int,
        title: string,
        is_default: bool
      }...]
      */
      return { ...state, subscriptions: payload };
    },
    createNewSequence(state, payload) {
      /* 
      payload = {
        id: int,
        title: string,
        is_default: bool
      }
      */
      return {
        ...state,
        sequences: [...state.sequences, payload],
        selectedSequence: payload.id,
      };
    },
    deleteSequenceFromState(state, sequenceId) {
      /* 
      sequenceId: int,
      */
      const seq = state.sequences.filter((s) => s.id !== sequenceId);
      return { ...state, sequences: seq };
    },
    renameSequenceInState(state, payload) {
      /* 
      payload = {
        sequenceId: int,
        name: string
      }
      */
      const seq = state.sequences.map((s) => {
        let newSeq = { ...s };
        if (s.id === payload.sequenceId) {
          newSeq.title = payload.name;
        }
        return newSeq;
      });
      return { ...state, sequences: seq };
    },
    clearState() {
      return { ...initialState };
    },
    setSelectedSequence(state, payload) {
      /* 
      payload: int
      */
      return { ...state, selectedSequence: payload };
    },
    setDefaultSequence(state, payload) {
      /*
      payload: int
      */
      return { ...state, defaultSequence: payload };
    },
    updateAllAttribute(state, payload) {
      return { ...state, attributes: payload };
    },
  },
  effects: (dispatch) => ({
    async fetchSequence(platformId) {
      try {
        const res = await axios.get(
          `${config.platform}/${platformId}/sequences`
        );
        if (res.data.success) {
          dispatch.builder.updateSequence(res.data.dataSource);
          dispatch.builder.setDefaultSequence(
            res.data.dataSource.filter(
              (s) => s.title.toLowerCase() === 'default sequence'
            )[0].id
          );
        } else {
          dispatch.builder.updateSequence([]);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchEntities(platformId) {
      try {
        const res = await axios.get(
          `${config.platform}/${platformId}/entities`
        );
        if (res.data.success) {
          dispatch.builder.updateEntities(res.data.dataSource);
        } else {
          dispatch.builder.updateEntities([]);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchSubscribe(payload) {
      try {
        const res = await axios.get(
          `${config.platform}/${payload}/subscriptions`
        );
        if (
          res.status === 200 &&
          res.data.success &&
          res.data.dataSource.length > 0
        ) {
          const subscriptions = res.data.dataSource;
          dispatch.builder.updateSubscriptions(
            subscriptions.map((s) => ({ ...s.data, id: s.id }))
          );
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchAttributes(platformId) {
      try {
        const res = await axios.get(
          `${config.platform}/${platformId}/attributes`
        );
        if (res.status === 200 && res.data.success) {
          dispatch.builder.updateAllAttribute(res.data.dataSource);
        } else {
          dispatch.builder.updateAllAttribute([]);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async createSequence(payload) {
      /* 
      payload = {
        platformId: int,
        data: {
          title: string
        }
      }
      */
      try {
        const res = await axios.post(
          `${config.platform}/${payload.platformId}/sequences`,
          payload.data
        );
        if (res.data.success) {
          await dispatch.block.fetchBlock(res.data.dataSource.id);
          dispatch.builder.createNewSequence(res.data.dataSource);
          dispatch.rules.fetchBlockRules(res.data.dataSource.id);
          return true;
        } else {
          return false;
        }
      } catch (err) {
        if (!!err.response && !!err.response.data.error) {
          toaster.danger(err.response.data.error);
        }
        return false;
      }
    },

    async editSequence(payload) {
      /* 
      payload = {
        platformId: int,
        sequenceId: int,
        data: {
          title: string
        }
      }
      */
      try {
        const res = await axios.put(
          `${config.platform}/${payload.platformId}/sequences/${payload.sequenceId}`,
          payload.data
        );
        if (res.data.success) {
          const data = res.data.dataSource;
          toaster.success('Rename Successful', {
            duration: 1,
          });
          dispatch.builder.renameSequenceInState({
            sequenceId: data.id,
            name: data.title,
          });
          return true;
        } else {
          await dispatch.builder.fetchSequence(payload.platformId); //reminder: need to refactor this to modal
          toaster.danger('Rename Failed', {
            duration: 1,
          });
          return false;
        }
      } catch (err) {
        if (!!err.response && !!err.response.data) {
          await dispatch.builder.fetchSequence(payload.platformId); //reminder: need to refactor this to modal
          toaster.danger('Rename Failed', {
            description: err.response.data.error,
            duration: 1,
          });
          return false;
        } else {
          await dispatch.builder.fetchSequence(payload.platformId); //reminder: need to refactor this to modal
          toaster.danger('Rename Failed', {
            duration: 1,
          });
          return false;
        }
      }
    },

    async deleteSequence(payload, state) {
      /* 
      payload = {
        platformId: int,
        sequenceId: int
      }
      */
      try {
        const res = await axios.delete(
          `${config.platform}/${payload.platformId}/sequences/${payload.sequenceId}`
        );
        if (res.data.success) {
          dispatch.builder.deleteSequenceFromState(payload.sequenceId);
          if (state.builder.selectedSequence === payload.sequenceId) {
            dispatch.builder.setSelectedSequence(state.builder.defaultSequence);
            await dispatch.block.fetchBlock(state.builder.defaultSequence);
            dispatch.block.fetchBlockRules(state.builder.defaultSequence);
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
  }),
};

export default builder;
