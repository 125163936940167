import React, { Component } from 'react';
import { navigate, Redirect } from '@reach/router';
import { toaster } from 'evergreen-ui';
import './assets/styles/auth.scss';
import { connect } from 'react-redux';
import logoBlack from './assets/images/logo/dark-alice-logo-full.svg';
import LoginFormSection from './components/LoginFormSection';
import AuthHeader from './components/AuthHeader';
import { authData } from './../../content';
import {
  setAmplitudeUserId,
  setAmplitudeUserProperties,
  sendAmplitudeData,
} from './../../utilities/amplitude';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      loginFailed: false,
    };
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  handleSubmit = () => {
    if (!!this.state.email && !!this.state.password) {
      this.props
        .login({ username: this.state.email, password: this.state.password })
        .then(async (success) => {
          if (success) {
            if (this.props.auth.invitationToken.length > 0) {
              const invited = await this.props.acceptInvitation(
                this.props.auth.invitationToken
              );
              if (invited) {
                toaster.success('Invitation Accepted', {
                  duration: 2,
                });
              } else {
                toaster.danger('Could Not Accept Invitation', {
                  duration: 2,
                });
              }
              this.props.updateInvitationToken('');
            }
            setAmplitudeUserId(this.state.email);
            setAmplitudeUserProperties({
              is_verified: this.props.auth.is_verified,
              trial_availed: this.props.auth.trial_availed,
            });
            sendAmplitudeData('Successful Login');
            navigate(`/dashboard`);
          } else {
            this.setState({ loginFailed: true });
          }
        })
        .catch((err) => console.log(err));
    } else {
      toaster.warning('Provide Email and Password to login');
    }
  };

  render() {
    const isAuthenticated = this.props.auth.access !== '';
    return isAuthenticated ? (
      <Redirect from='/' to='/dashboard' noThrow />
    ) : (
      <div className='auth-container'>
        <div className='header-container'>
          <div>
            <a href='/'>
              <img className='logo-dark' src={logoBlack} alt='alice logo' />
            </a>
          </div>
          <div>
            <AuthHeader
              linkInfo={authData.registerInfo.text}
              link={authData.registerInfo.link}
              linkText={authData.registerInfo.linkTitle}
            />
          </div>
        </div>
        <LoginFormSection
          loginFailed={this.state.loginFailed}
          email={this.state.email}
          password={this.state.password}
          loading={this.props.loginLoading}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          content={authData}
        />
      </div>
    );
  }
}

const mapState = (state) => ({
  auth: state.auth,
  loginLoading: state.loading.effects.auth.login,
});

const mapDispatch = (dispatch) => ({
  login: (payload) => dispatch.auth.login(payload),
  acceptInvitation: (token) => dispatch.auth.acceptInvitation(token),
  updateInvitationToken: (token) => dispatch.auth.updateInvitationToken(token),
});

const AuthContainer = connect(mapState, mapDispatch)(Login);

export default AuthContainer;
