import React from 'react';
import {
  getUpdatedPlatformIconOutlined,
  getUpdatedPlatformName,
  getPlatformColor,
} from './../../../utils';
import connectIcon from './../assets/images/icons/connected.svg';
import disConnectIcon from './../assets/images/icons/disconnect.svg';
import { navigate } from '@reach/router';
import {Button, Tooltip} from 'evergreen-ui';
import UserIcon from './UserIcon';
import TicketIcon from './TicketIcon';

interface props {
  channel: any;
  index: number;
  isDisabled: boolean;
    setCloneChannel: (val:number) => void;
}

const DashboardChannelCard: React.FC<props> = ({
  channel,
  index,
  isDisabled,
    setCloneChannel
}) => {
  return (
    <div className='dashboard-channel-list__card'>
      <div className='flex space-between w-100p align-flex-start'>
        <div className='dashboard-channel-list__card--platform-info'>
          <img
            src={getUpdatedPlatformIconOutlined(channel.type)}
            alt={index + ''}
            width={20}
          />{' '}
          &nbsp; &nbsp;
          <span style={{ fontWeight: 500 }}>
            {getUpdatedPlatformName(channel.type)}
          </span>
        </div>
        <Tooltip content={'Clone this Channel'}>
            <Button appearance='minimal' style={{ margin: 0, padding: 10 }} onClick={()=>setCloneChannel(channel.id)}>
                <svg
                    width='13'
                    height='15'
                    viewBox='0 0 13 15'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <path
                        d='M9.5 0.5L9.77735 0.0839748L9.65139 0H9.5V0.5ZM12.5 2.5H13V2.23241L12.7774 2.08397L12.5 2.5ZM11.5 12H3.5V13H11.5V12ZM3 11.5V1.5H2V11.5H3ZM3.5 1H9.5V0H3.5V1ZM12 2.5V11.5H13V2.5H12ZM9.22265 0.916025L12.2226 2.91603L12.7774 2.08397L9.77735 0.0839748L9.22265 0.916025ZM3.5 12C3.22386 12 3 11.7761 3 11.5H2C2 12.3284 2.67157 13 3.5 13V12ZM11.5 13C12.3284 13 13 12.3284 13 11.5H12C12 11.7761 11.7761 12 11.5 12V13ZM3 1.5C3 1.22386 3.22386 1 3.5 1V0C2.67157 0 2 0.671573 2 1.5H3ZM0 3.5V13.5H1V3.5H0ZM1.5 15H9.5V14H1.5V15ZM1.5 3H2.5V2H1.5V3ZM11 13.5V12.5H10V13.5H11ZM9.5 15C10.3284 15 11 14.3284 11 13.5H10C10 13.7761 9.77614 14 9.5 14V15ZM0 13.5C0 14.3284 0.671573 15 1.5 15V14C1.22386 14 1 13.7761 1 13.5H0ZM1 3.5C1 3.22386 1.22386 3 1.5 3V2C0.671573 2 0 2.67157 0 3.5H1Z'
                        fill={getPlatformColor(channel.type)}
                    />
                </svg>
            </Button>
        </Tooltip>
      </div>
      <div className='dashboard-channel-list__card--title-card'>
        {channel.title || channel.name || 'Not Available'}{' '}
        <img
          src={channel.is_connected ? connectIcon : disConnectIcon}
          alt={'connection icon'}
        />
      </div>
      <div className='dashboard-channel-list__card--description-card'>
        {(channel.description.length > 200
          ? channel.description.substring(0, 200)
          : channel.description) || (
          <div style={{ marginBottom: 30 }}>No Description Provided</div>
        )}
      </div>
      <div className='flex w-100p'>
        <div className='flex flex-1'>
          <UserIcon fill={getPlatformColor(channel.type)} />
          &nbsp;
          <div>
            <p
              className='dashboard-channel-list__card--amount'
              style={{
                color: getPlatformColor(channel.type),
              }}
            >
              {channel.customer_count || 0}
            </p>
            <p className='dashboard-channel-list__card--amount-info'>
              Total Users
            </p>
          </div>
        </div>
        <div className='flex flex-1'>
          <TicketIcon fill={getPlatformColor(channel.type)} />
          &nbsp;
          <div>
            <p
              className='dashboard-channel-list__card--amount'
              style={{
                color: getPlatformColor(channel.type),
              }}
            >
              {channel.ticket_count || 0}
            </p>
            <p className='dashboard-channel-list__card--amount-info'>Tickets</p>
          </div>
        </div>
      </div>
      <Button
        disabled={isDisabled}
        style={{
          margin: '20px auto 0 auto',
          texAlign: 'center',
        }}
        onClick={() => navigate('/settings/channel')}
        className='alice_btn-white-primary'
      >
        Quick Edit
      </Button>
    </div>
  );
};

export default DashboardChannelCard;
