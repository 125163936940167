import React, {Fragment} from 'react';
import ReportingCardLine from './../../component/ReportingCardLine';
import {connect} from 'react-redux';
import AgentDetailsTable from '../AgentDetailsTable';

interface props {
  averageQueueTime: any;
  averageResolveTime: any;
  loader: boolean;
  agentStats: any;
  statLoader: boolean;
  exportAgentDetails: (projectId: number) => void;
  exportLoader: boolean;
  selectedProject: any;
}

const AgentMetrics: React.FC<props> = (
  {
    averageQueueTime,
    averageResolveTime,
    loader,
    agentStats,
    statLoader,
    exportLoader,
    exportAgentDetails,
    selectedProject,
  }) => {
  const [searchAgent, setSearchAgent] = React.useState('');
  return (
    <Fragment>
      <div className='reporting-container__user-card-container'>
        <ReportingCardLine
          title={'Avg Time In Queue'}
          data={averageQueueTime?.max?.value || 0}
          additionalData={averageQueueTime?.max?.name || 'Not Available'}
          loader={loader}
          growth={averageQueueTime?.max?.growth + ' New' || '0.00 New'}
          growthType={averageQueueTime?.growthType || 'up'}
          additionalGrowthText={` last ${averageQueueTime?.max?.days_span} days`}
          tooltipInfo='The average amount of minutes that ticket remains in an Agent’s inbox since being created. '
          showLegend={false}
          isTimebasedCard={false}
          color={'#007462'}
          graphData={averageQueueTime?.data || []}
        />
        <ReportingCardLine
          title={'Avg Time To Resolve'}
          data={averageResolveTime?.max?.value || 0}
          additionalData={averageResolveTime?.max?.name || 'Not Available'}
          loader={loader}
          growth={averageResolveTime?.max?.growth + ' New' || '0.00 New'}
          growthType={averageResolveTime?.growthType || 'up'}
          additionalGrowthText={` last ${averageResolveTime?.max?.days_span} days`}
          tooltipInfo='The average amount of minutes that an Agent requires to solve a ticket since being created.'
          showLegend={false}
          isTimebasedCard={true}
          color={'#F3B52F'}
          graphData={averageResolveTime?.data || []}
        />
      </div>
      <AgentDetailsTable
        title={'Agent Details'}
        loader={statLoader}
        toolTipInfo={
          'Necessary information about agents and their CRM activities. '
        }
        query={searchAgent}
        setQuery={setSearchAgent}
        exportLoader={exportLoader}
        handleExport={() => exportAgentDetails(selectedProject.id)}
        tableData={
          !!agentStats
            ? agentStats.filter(
            (elem: any) =>
              !!elem.agent_name &&
              elem.agent_name
                .toLowerCase()
                .includes(searchAgent.toLowerCase())
            )
            : []
        }
      />
    </Fragment>
  );
};

const mapState = (state: any) => ({
  averageQueueTime: state.reporting.averageQueueTime,
  averageResolveTime: state.reporting.averageResolveTime,
  agentStats: state.reporting.agentStats,
  selectedProject: state.dashboard.selectedProject,
  loader:
  state.loading.effects.reporting.fetchUserReportDataUserMetricsLineGraph,
  statLoader: state.loading.effects.reporting.fetchUserReportDataDefaultAppend,
  exportLoader: state.loading.effects.reporting.exportAgentDetails,
});

const mapDispatch = (dispatch: any) => ({
  exportAgentDetails: (projectId: number) =>
    dispatch.reporting.exportAgentDetails(projectId),
});

const AgentMetricsContainer = connect(mapState, mapDispatch)(AgentMetrics);

export default AgentMetricsContainer;
