import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'evergreen-ui';
import AuthFormHeader from './AuthFormHeader';
import MaterialInput from '../../../components/elements/MaterialInput';
import AuthFooter from './AuthFooter';

const ForgetPasswordFormSection = ({
  email,
  handleChange,
  content,
  loading,
  handleSubmit,
}) => {
  return (
    <div className='form-container'>
      <div className='form-container__content'>
        <AuthFormHeader
          title={content.forget.title}
          subtitle={content.forget.subtitle}
        />
        <br />
        <MaterialInput
          label='Email Address'
          type='email'
          name='email'
          value={email}
          onKeyPress={(e) => (e.key === 'Enter' ? handleSubmit() : null)}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
        <br />
        <br />
        <Button
          isLoading={loading}
          onClick={() => handleSubmit()}
          className='auth-login-button'
          height={40}
        >
          {content.forget.forgetButtonTitle}
        </Button>
        <br />
        <br />
        <AuthFooter />
      </div>
    </div>
  );
};
ForgetPasswordFormSection.propTypes = {
  email: PropTypes.string,
  content: PropTypes.any,
  loading: PropTypes.bool,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
};
export default ForgetPasswordFormSection;
