import React, { Fragment } from 'react';
import { TextInput} from 'evergreen-ui';
import TitleHolderBuilder from './TitleHolderBuilder';

interface props {
  value: string;
  handleChange: (event: string | null) => void;
}

const FailTextInput: React.FC<props> = ({ value, handleChange }) => {
  return (
    <Fragment>
      <TitleHolderBuilder title="Failed Text" tooltipInfo="What Bot Should Reply when Failed validation..." />
      <TextInput
        value={!!value ? value : ''}
        height={40}
        className='custom-input-style'
        width={'100%'}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChange(e.target.value)
        }
        placeholder='Provide Failed Text...'
      />
    </Fragment>
  );
};

export default FailTextInput;
