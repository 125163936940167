import axios from './httpClient';
import config from './config';
import Compressor from 'compressorjs';
import facebookFeed from './assets/images/facebook.svg';
import messengerIcon from './assets/images/messenger.svg';
import viberIcon from './assets/images/viber.svg';
import webchatIcon from './assets/images/inconnect.svg';
import whatappsIcon from './assets/images/whatsapp.svg';
import defaultPlatform from './assets/images/default_platform.svg';
import appIcon from './assets/images/appsIcon.svg';
import { toaster } from 'evergreen-ui';
import priorityHighIcon from './assets/images/priority_high.svg';
import priorityMedIcon from './assets/images/priority_med.svg';
import priorityLowIcon from './assets/images/priority_low.svg';
import inconnectLogo from './assets/images/inconnect_logo.svg';

import facebookMessengerChannelIcon from './pages/settings/assets/icons/facebook_messenger.svg';
import facebookFeedChannelIcon from './pages/settings/assets/icons/facebook_feed.svg';
import websiteChannelIcon from './pages/settings/assets/icons/website.svg';
import appChannelIcon from './pages/settings/assets/icons/app.svg';
import viberChannelIcon from './pages/settings/assets/icons/viber.svg';
import whatsappChannelIcon from './pages/settings/assets/icons/whatsapp_channel.svg';

//alert
import dangerIcon from './assets/images/alertImages/danger.svg';
import successIcon from './assets/images/alertImages/success.svg';
import warningIcon from './assets/images/alertImages/warning.svg';

//outlined set
import fbMessengerDashboardIcon from './pages/dashboard/assets/images/icons/fb-messenger.svg';
import fbFeedDashboardIcon from './pages/dashboard/assets/images/icons/fb-feed.svg';
import viberDashboardIcon from './pages/dashboard/assets/images/icons/viber.svg';
import webchatDashboardIcon from './pages/dashboard/assets/images/icons/webchat.svg';
import appDashboardIcon from './pages/dashboard/assets/images/icons/app.svg';
import whatsappDashboardIcon from './pages/dashboard/assets/images/icons/whatsapp.svg';

import upIcon from './pages/reporting/assets/images/reportingUp.svg';
import downIcon from './pages/reporting/assets/images/reportingDown.svg';
import defaultAvatar from './assets/images/defaultAvatar.svg';

import moment from 'moment';

//isRead Icon
import isReadIcon from './pages/crm/assets/icon/readIcon.svg';
import isUnreadIcon from './pages/crm/assets/icon/unreadIcon.svg';
import React, { useEffect } from 'react';

export const settingsNavData = [
  {
    title: 'User Settings',
    globalAccess: true,
    data: [
      {
        id: 0,
        title: 'User Information',
        link: '/settings/profile#user-settings',
        icon: 'user',
        itemGlobalAccess: true,
        isPro: false,
      },
      {
        id: 1,
        title: 'Change Password',
        link: '/settings/profile#password',
        icon: 'key',
        itemGlobalAccess: true,
        isPro: false,
      },
    ],
  },
  {
    title: 'Channel Settings',
    globalAccess: false,
    data: [
      {
        id: 13,
        title: 'Channel Settings',
        link: '/settings/channel',
        icon: 'panel-table',
        itemGlobalAccess: false,
        isPro: false,
      },
    ],
  },
  {
    title: 'Team Settings',
    globalAccess: false,
    data: [
      {
        id: 2,
        title: 'Team Information',
        link: '/settings/team-info',
        icon: 'projects',
        itemGlobalAccess: false,
        isPro: false,
      },
      {
        id: 3,
        title: 'User Management',
        link: '/settings/team-user',
        icon: 'user',
        itemGlobalAccess: false,
        isPro: false,
      },
      {
        id: 4,
        title: 'Group Management',
        link: '/settings/team-group',
        icon: 'group-objects',
        itemGlobalAccess: false,
        isPro: false,
      },
      {
        id: 14,
        title: 'Marketplace',
        link: '/settings/marketplace',
        icon: 'shop',
        itemGlobalAccess: false,
        isPro: true,
      },
    ],
  },
  {
    title: 'Billing Settings',
    globalAccess: false,
    data: [
      {
        id: 7,
        title: 'Manage Subscription',
        link: '/settings/billing',
        icon: 'applications',
        itemGlobalAccess: false,
        isPro: false,
      },
      {
        id: 8,
        title: 'Payment Method',
        link: '/settings/payment-method',
        icon: 'credit-card',
        itemGlobalAccess: false,
        isPro: false,
      },
      {
        id: 9,
        title: 'Payment History',
        link: '/settings/payment-histories',
        icon: 'history',
        itemGlobalAccess: false,
        isPro: false,
      },
    ],
  },
];

export const navMenu = [
  {
    id: 8,
    name: 'Inbox',
    icon: 'chat',
    link: 'chat',
    global: false,
    isPro: false,
    permission: {
      readAccess: 'read:conversation',
      writeAccess: 'write:conversation',
    },
  },
  {
    id: 1,
    name: 'Builder',
    icon: 'diagram-tree',
    link: 'builder',
    global: false,
    isPro: false,
    permission: {
      readAccess: 'read:bot_building',
      writeAccess: 'write:bot_building',
    },
  },
  {
    id: 6,
    name: 'Reporting',
    icon: 'timeline-line-chart',
    link: 'reporting',
    global: false,
    isPro: false,
    permission: {
      readAccess: 'read:analytics',
      writeAccess: 'write:analytics',
    },
  },
  {
    id: 7,
    name: 'Audience',
    icon: 'user',
    link: 'audience',
    global: false,
    isPro: false,
    permission: {
      readAccess: 'read:audience',
      writeAccess: 'write:audience',
    },
  },
  {
    id: 2,
    name: 'API Integration',
    icon: 'code-block',
    link: 'api',
    global: false,
    isPro: true,
    permission: {
      readAccess: 'read:custom_api',
      writeAccess: 'write:custom_api',
    },
  },
  // {
  //   id: 3,
  //   name: 'Rules',
  //   icon: 'layers',
  //   link: 'rules',
  //   global: false,
  // },
  {
    id: 4,
    name: 'Data Lab',
    icon: 'database',
    link: 'data-lab',
    global: false,
    isPro: false,
    permission: {
      readAccess: 'read:data_lab',
      writeAccess: 'write:data_lab',
    },
  },
  {
    id: 5,
    name: 'Settings',
    icon: 'cog',
    link: '/settings/profile',
    global: true,
    isPro: false,
    permission: {
      readAccess: 'read:settings',
      writeAccess: 'write:settings',
    },
  },
];
export const navMenuSettings = [
  {
    id: 5,
    name: 'Settings',
    icon: 'cog',
    link: '/settings/profile',
    global: true,
    isPro: false,
    permission: {
      readAccess: 'read:settings',
      writeAccess: 'write:settings',
    },
  },
];
const getUpdatedButtonElement = (buttons, data) => {
  const {
    id,
    buttonIndex,
    title,
    type,
    value,
    messenger_extensions,
    webview_height_ratio,
    form_sequence,
  } = data;
  const newData = {
    id,
    title,
    type,
    value,
    messenger_extensions,
    webview_height_ratio,
    form_sequence,
  };
  return buttons.map((button, i) => {
    if (buttonIndex === i) {
      return { ...button, ...newData };
    }
    return { ...button };
  });
};

export const getUpdatedBlocks = (blocks, payload, type) => {
  return blocks.map((block) => {
    if (block.id === payload.id) {
      const supportedKeys = ['title', 'subtitle', 'url', 'image'];

      if (type === 'buttons') {
        if (payload.changeKey === 'buttons') {
          const newButtons = getUpdatedButtonElement(
            block.data.buttons,
            payload.data
          );
          block.data.buttons = newButtons;
        } else if (payload.changeKey === 'text') {
          block.data[payload.changeKey] = payload.data;
        }
      } else if (type === 'gallery') {
        const newGalleries = block.data.elements.map((elem, i) => {
          if (i === payload.galleryIndex) {
            if (payload.changeKey === 'buttons') {
              const newButtons = getUpdatedButtonElement(
                elem.buttons,
                payload.data
              );
              return { ...elem, buttons: newButtons };
            } else if (supportedKeys.includes(payload.changeKey)) {
              elem[payload.changeKey] = payload.data;
              return { ...elem };
            }
          }
          return { ...elem };
        });
        block.data.elements = newGalleries;
      }
      block.save = false;
    }
    return block;
  });
};

export const handleImageUpload = (file, callback) => {
  if (file.type === 'image/gif') {
    const formData = new FormData();
    formData.append('file', file, file.name);
    axios
      .post(config.image, formData)
      .then((res) => {
        callback(res.data.url);
      })
      .catch((err) => console.log(err));
  } else {
    new Compressor(file, {
      quality: 0.7,
      maxHeight: 1000,
      success(result) {
        const formData = new FormData();
        formData.append('file', result, result.name);
        axios
          .post(config.image, formData)
          .then((res) => {
            callback(res.data.url);
          })
          .catch((err) => console.log(err));
      },
      error(err) {
        console.log(err.message);
      },
    });
  }
};
export const handleImageGalleryUpload = (file, projectId, callback) => {
  if (file.type === 'image/gif') {
    const formData = new FormData();
    formData.append('file', file, file.name);
    axios
      .post(`${config.crm}/${projectId}/images`, formData)
      .then((res) => {
        if (res.data.success) {
          callback(res.data.dataSource);
        } else {
          callback(null);
        }
      })
      .catch((err) => console.log(err));
  } else {
    new Compressor(file, {
      quality: 0.7,
      maxHeight: 1000,
      success(result) {
        const formData = new FormData();
        formData.append('file', result, result.name);
        axios
          .post(`${config.crm}/${projectId}/images`, formData)
          .then((res) => {
            if (res.data.success) {
              callback(res.data.dataSource);
            } else {
              callback(null);
            }
          })
          .catch((err) => console.log(err));
      },
      error(err) {
        console.log(err.message);
      },
    });
  }
};

export const handleFileUpload = (file, callback) => {
  const formData = new FormData();
  formData.append('file', file, file.name);
  axios
    .post(config.file, formData)
    .then((res) => {
      callback(res.data.url);
    })
    .catch((err) => console.log(err));
};

export const updateOnChangeText = (
  value,
  setShowAttribute,
  setShowAPI,
  setText
) => {
  let currentValue = value;
  let lastTwoChar = currentValue.slice(
    currentValue.length - 2,
    currentValue.length
  );
  if (lastTwoChar === '{{') {
    setShowAttribute(true);
  } else if (lastTwoChar === '<<') {
    !!setShowAPI && setShowAPI(true);
  } else {
    setShowAttribute(false);
    !!setShowAPI && setShowAPI(false);
  }
  setText(value);
};

export const updateOnChangeAttribute = (
  value,
  setShowAttributeField,
  setVariable
) => {
  if (value.length < 3) {
    setShowAttributeField(true);
  } else {
    setShowAttributeField(false);
  }
  setVariable(value);
};

export const updateOnSelectAttribute = (
  intent,
  showAttribute,
  showAPI,
  setShowAttribute,
  setShowAPI,
  setText,
  text,
  apiList
) => {
  let currentValue;
  if (Array.isArray(text)) {
    currentValue = text[text.length - 1];
  } else {
    currentValue = text;
  }
  if (showAttribute) {
    currentValue = currentValue + intent + '}}';
    setShowAttribute(false);
    if (Array.isArray(text)) {
      setText([...text.slice(0, text.length - 1), currentValue]);
    } else {
      setText(currentValue);
    }
  } else {
    let currentAPI = apiList.filter((apis) => apis.title === intent)[0];
    let currentValue = text + currentAPI.title + '|' + currentAPI.id + '>>';
    setShowAPI(false);
    setText(currentValue);
  }
};

export const reorderList = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getPlatformInfoTitle = (platform) => {
  return (
    platform?.title || platform?.name || getUpdatedPlatformName(platform?.type)
  );
};

export const getUpdatedPlatformName = (type) => {
  switch (type) {
    case 'facebook_messenger':
      return 'Messenger';
    case 'facebook_feed':
      return 'Facebook Feed';
    case 'viber_messenger':
      return 'Viber';
    case 'webchat':
      return 'WebChat';
    case 'app_messenger':
      return 'inApp';
    case 'whatsapp_messenger':
      return 'Whatsapp';
    case 'all':
      return 'ALL Channel';
    default:
      return type;
  }
};

export const getUpdatedPlatformIcon = (type) => {
  switch (type) {
    case 'facebook_messenger':
      return messengerIcon;
    case 'facebook_feed':
      return facebookFeed;
    case 'viber_messenger':
      return viberIcon;
    case 'all':
      return inconnectLogo;
    case 'webchat':
      return webchatIcon;
    case 'app_messenger':
      return appIcon;
    case 'whatsapp_messenger':
      return whatappsIcon;
    default:
      return defaultPlatform;
  }
};

export const getUpdatedPlatformIconOutlined = (type) => {
  switch (type) {
    case 'facebook_messenger':
      return fbMessengerDashboardIcon;
    case 'facebook_feed':
      return fbFeedDashboardIcon;
    case 'viber_messenger':
      return viberDashboardIcon;
    case 'all':
      return inconnectLogo;
    case 'webchat':
      return webchatDashboardIcon;
    case 'app_messenger':
      return appDashboardIcon;
    case 'whatsapp_messenger':
      return whatsappDashboardIcon;
    default:
      return defaultPlatform;
  }
};

export const validateInputFields = (data, fields) => {
  const requiredFields = fields.filter((f) => f.is_required).map((f) => f.id);
  const dataKeys = Object.keys(data).map((k) => parseInt(k));
  const validated = requiredFields.every((val) => {
    return dataKeys.includes(val);
  });

  const fieldsObj = fields.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.id]: { ...cur },
    }),
    {}
  );

  if (validated) {
    const ignore = ['id', 'lab_id', 'creator_id', 'source', 'mongo_timestamp'];
    const dataPair = Object.entries(data).filter((d) => !ignore.includes(d[0]));

    const isValid = dataPair.every(([key, value]) => {
      const field = fieldsObj[key];
      if (
        !!field &&
        field.is_required &&
        (value === '' || value === null || value === undefined)
      ) {
        return false;
      }
      return true;
    });

    return isValid;
  }

  return false;
};

export const copyClipboard = (text) => {
  let input = document.createElement('input');
  input.setAttribute('value', text);
  document.body.appendChild(input);
  input.select();
  let result = document.execCommand('copy');
  document.body.removeChild(input);
  return result;
};

export const isValidEmail = (text) => {
  const email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi;
  return email.test(text);
};

export const isValidUsername = (text) => {
  const username = /^[a-zA-Z0-9@.\-_]{5,50}$/gi;
  return username.test(text);
};

export const isValidUrl = (text) => {
  const url = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gi;
  if (!!text) {
    return url.test(text);
  }
  return true;
};

export const generateRandomString = (length) => {
  let result = '';
  let characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const shortenNumber = (num, digits = 1) => {
  const si = [
    { value: 1e18, symbol: 'QT' },
    { value: 1e15, symbol: 'QD' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'B' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'k' },
    { value: 0, symbol: '' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  function divideNum(divider) {
    return (num / (divider || 1)).toFixed(digits);
  }

  let i = si.findIndex(({ value }) => num >= value);
  if (+divideNum(si[i].value) >= 1e3 && si[i - 1]) {
    i -= 1;
  }
  const { value, symbol } = si[i];
  return divideNum(value).replace(rx, '$1') + symbol;
};

export const convertInputType = (value) => {
  switch (value) {
    case 'CharField':
      return 'text';
    case 'TextField':
      return 'text';
    case 'DateTimeField':
      return 'date';
    default:
      return 'text';
  }
};

export const freshChatInit = () => {
  function initFreshChat() {
    window.fcWidget.init({
      config: { headerProperty: { direction: 'rtl' } },
      token: process.env.REACT_APP_FRESH_CHAT_TOKEN,
      host: 'https://wchat.freshchat.com',
    });
  }

  function initialize(i, t) {
    if (i.getElementById(t)) {
      initFreshChat();
    } else {
      let e = i.createElement('script');
      e.id = t;
      e.async = !0;
      e.src = 'https://wchat.freshchat.com/js/widget.js';
      e.onload = initFreshChat;
      i.head.appendChild(e);
    }
  }
  function initiateCall() {
    if (!window.location.pathname.includes('public')) {
      initialize(document, 'freshchat-js-sdk');
    }
  }
  window.addEventListener
    ? window.addEventListener('load', initiateCall, !1)
    : window.attachEvent('load', initiateCall, !1);
};

export const exportCSV = (data, title) => {
  let link = document.createElement('a');
  link.setAttribute(
    'href',
    URL.createObjectURL(new Blob([data], { type: 'text/csv;charset=utf-8;' }))
  );
  link.setAttribute('download', `${title}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const exportFile = (data, title, format) => {
  try {
    let link = document.createElement('a');
    link.setAttribute('href', URL.createObjectURL(data));
    link.setAttribute('download', `${title}.${format}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (err) {
    console.log(err);
  }
};

export const checkPermission = (permissions, shortCode) => {
  return (
    permissions.filter((permission) => permission.short_code === shortCode)
      .length > 0
  );
};

export const checkInputLength = (labDetails, inputDetails) => {
  for (let i = 0; i < labDetails.length; i++) {
    let entryId = labDetails[i].id;
    if (
      labDetails[i].type === 'text' ||
      labDetails[i].type === 'number' ||
      labDetails[i].type === 'textarea'
    ) {
      if (inputDetails[entryId]) {
        if (inputDetails[entryId].length < labDetails[i].min_length) {
          toaster.danger(
            'Minimum length for' +
              ' ' +
              labDetails[i].name +
              ' is ' +
              labDetails[i].min_length
          );
          return false;
        }
      }
    }
  }
  return true;
};

export const fetchPriorityIcon = (payload) => {
  switch (payload) {
    case 'high':
      return priorityHighIcon;
    case 'medium':
      return priorityMedIcon;
    case 'low':
    default:
      return priorityLowIcon;
  }
};
export const processFeedPostData = (feedData) => {
  let type = null;
  let content = null;
  let contentItem = '';
  if (feedData?.type === 'text') {
    type = feedData.type;
    content = feedData.text;
  } else if (feedData.type === 'attachment') {
    if (feedData?.attachment?.type === 'video') {
      type = feedData?.attachment?.type;
      content = feedData?.text;
      contentItem = feedData?.attachment?.urls[0];
    } else if (feedData?.attachment?.type === 'image') {
      type = feedData?.attachment?.type;
      content = feedData?.text;
      contentItem = feedData?.attachment?.urls;
    } else {
      type = 'text';
      content = feedData?.text || 'attachment type content was sent';
      contentItem = '';
    }
  } else if (feedData.type === 'note') {
    type = feedData.type;
    content = feedData.data.text;
  } else if (feedData.type === 'action') {
    type = feedData?.data?.data?.type;
    content = feedData?.data?.data?.text;
  }
  return { type, content, contentItem };
};
export const processFeedData = (feedData) => {
  let type = null;
  let content = null;
  let image = '';
  if (feedData?.type === 'text') {
    type = feedData.type;
    content = feedData.text;
  } else if (feedData.type === 'attachment') {
    type = feedData?.attachment?.type;
    content = feedData.text;
    image = feedData?.attachment?.urls[0];
  } else if (feedData.type === 'note') {
    type = feedData.type;
    content = feedData.data.text;
  } else if (feedData.type === 'action') {
    type = feedData?.data?.data?.type;
    content = feedData?.data?.data?.text;
  }
  return { type, content, image };
};
export const processReplyData = (feedData) => {
  let type = null;
  let content = null;
  let image = '';
  if (feedData.type === 'text') {
    type = feedData.type;
    content = feedData.data.text;
  } else if (feedData.type === 'attachment') {
    type = feedData.data.sub_type;
    image = feedData?.data?.urls[0];
    content = '';
  } else if (feedData.type === 'note') {
    type = feedData.type;
    content = feedData.data.text;
  } else if (feedData.type === 'action') {
    type = 'text';
    content = feedData?.data?.text;
  }
  return { type, content, image };
};
export const processCustomerReplyData = (feedData) => {
  let type = null;
  let content = null;
  let image = '';
  if (feedData.type === 'text') {
    type = feedData.type;
    content = feedData.text;
  } else if (feedData.type === 'attachment') {
    type = feedData.data.type;
    image = feedData?.data?.urls[0];
    content = feedData.text;
  } else if (feedData.type === 'note') {
    type = feedData.type;
    content = feedData.data.text;
  } else if (feedData.type === 'action') {
    type = 'text';
    content = feedData?.data?.text;
  }
  return { type, content, image };
};

export const procedReplyData = (replies, platform) => {
  let repliesLocal = [];
  replies.forEach((reply) => {
    let replyLocal = {};
    if (reply.source === 'customer') {
      replyLocal = {
        avatar: reply?.customer_info?.avatar || defaultAvatar,
        content: processCustomerReplyData(reply.data).content,
        contentType: processCustomerReplyData(reply.data).type,
        image: processCustomerReplyData(reply.data).image,
        id: reply._id || reply?.pusher_id,
        link: reply?.permalink_url,
        name: reply?.customer_info?.full_name || 'Anonymous User',
        source: reply.source,
        time: `${moment(reply?.timestamp).fromNow()}`,
        messageType: reply?.type,
        isHighlighted: !!reply?.is_highlighted,
        status: reply?.status,
        conversation_id: reply.conversation_id,
        showMsgStatus: true,
        msgStatus:
          processCustomerReplyData(reply.data).type === 'note'
            ? 'sent'
            : !reply.status
            ? 'pending'
            : !!reply.status
            ? 'sent'
            : 'failed',
      };
      repliesLocal.push(replyLocal);
    } else {
      replyLocal = {
        avatar: platform?.avatar,
        content: processReplyData(reply.data).content,
        contentType: processReplyData(reply.data).type,
        image: processReplyData(reply.data).image,
        id: reply._id || reply?.pusher_id,
        link: reply?.permalink_url,
        name: platform?.name,
        source: reply.source,
        time: `${moment(reply?.timestamp).fromNow()}`,
        messageType: reply?.type,
        isHighlighted: !!reply?.is_highlighted,
        status: reply?.status,
        conversation_id: reply.conversation_id,
        showMsgStatus: true,
        msgStatus:
          processCustomerReplyData(reply.data).type === 'note'
            ? 'sent'
            : !reply.status
            ? 'pending'
            : !!reply.status
            ? 'sent'
            : 'failed',
      };
      repliesLocal.push(replyLocal);
    }
  });
  return repliesLocal;
};

export const copyReferralValue = (platformId, value) => {
  let link = 'https://m.me/' + platformId + '?ref=' + value;
  let input = document.createElement('input');
  input.setAttribute('value', link);
  document.body.appendChild(input);
  input.select();
  document.execCommand('copy');
  document.body.removeChild(input);
  toaster.success('Link Copied', { duration: 1 });
};

export const channelInfo = (type) => {
  switch (type) {
    case 'facebook_messenger':
      return {
        title: 'Messenger',
        image: facebookMessengerChannelIcon,
      };
    case 'facebook_feed':
      return { title: 'Facebook Feed', image: facebookFeedChannelIcon };
    case 'webchat':
      return { title: 'Websites', image: websiteChannelIcon };
    case 'app_messenger':
      return { title: 'Apps', image: appChannelIcon };
    case 'viber_messenger':
      return { title: 'Viber', image: viberChannelIcon };
    case 'whatsapp_messenger':
      return { title: 'Whatsapp', image: whatsappChannelIcon };
    default:
      return { title: 'Not Available', image: inconnectLogo };
  }
};

export const getPlatformColor = (type) => {
  switch (type) {
    case 'facebook_messenger':
      return '#0078FF';
    case 'facebook_feed':
      return '#3b5998';
    case 'webchat':
      return '#F46275';
    case 'app_messenger':
      return '#184d47';
    case 'viber_messenger':
      return '#665CAC';
    default:
      return '#000';
  }
};

export const reportingTab = [
  {
    id: 0,
    name: 'User Metrics',
  },
  {
    id: 1,
    name: 'Automation Metrics',
  },
  {
    id: 2,
    name: 'Agent Metrics',
  },
  // {
  //   id: 3,
  //   name: 'Conversation Logs',
  // },
];

export const getGraphForReporting = (value) => {
  switch (value) {
    case 'up':
      return upIcon;
    case 'down':
      return downIcon;
    default:
      return upIcon;
  }
};

export const getReportingVariableNameFromEndpoint = (endpoint) => {
  switch (endpoint) {
    case 'get_new_customer_count':
      return 'userCount';
    case 'get_interactive_customer_count':
      return 'interactiveUserCount';
    case 'get_triggered_message_count/referral':
      return 'referralGrowthData';
    case 'get_triggered_message_count/intent':
      return 'intentGrowthData';
    case 'get_language_metrics':
      return 'languageData';
    case 'get_timezone_metrics':
      return 'timezoneData';
    case 'get_country_metrics':
      return 'countryData';
    case 'conversation_heatmap':
      return 'heatmapData';

    case 'get_gender_metrics':
      return 'genderData';

    case 'get_postback_and_text_message_count':
      return 'postbackVTextData';

    case 'get_triggered_message_list/referral':
      return 'triggeredMsgReferralnKeyword';

    case 'get_triggered_message_list/keyword':
      return 'triggeredMsgReferralnKeyword';

    case 'get_triggered_message_list/intent':
      return 'triggeredMsgIntentnPostback';

    case 'get_triggered_message_list/postback':
      return 'triggeredMsgIntentnPostback';

    case 'get_avg_ticket_per_user':
      //2
      return 'avgTicketPerUser';
    case 'get_avg_ticket_per_overall_user':
      //3
      return 'avgTicketPerOverallUser';
    case 'get_ticket_created_count':
      //4
      return 'ticketCreatedCount';

    case 'get_ticket_resolved_count':
      return 'ticketResolvedCount';

    case 'get_textual_message_vs_automation':
      return 'textualVsAutomation';

    case 'get_tag_cloud':
      return 'ticketTagCloud';

    case 'get_ticket_list':
      return 'ticketListTable';

    case 'get_ticket_avg_queue_time':
      return 'averageQueueTime';

    case 'get_ticket_avg_resolve_time':
      return 'averageResolveTime';

    case 'get_agent_stats':
      return 'agentStats';

    default:
      return 'error';
  }
};

export const getSum = (array, key) => {
  if (!array || array.length === 0) {
    return 0;
  } else if (Object.keys(array[0]).includes(key)) {
    const sum = array.reduce(function(sum, current) {
      return sum + (current[key] || 0);
    }, 0);
    return sum;
  } else {
    return 0;
  }
};

export const allPlatformList = [
  {
    title: 'Messenger',
    type: 'facebook_messenger',
    main_type: 'messenger',
  },
  { title: 'Facebook Feed', type: 'facebook_feed', main_type: 'feed' },
  { title: 'Webchat', type: 'webchat', main_type: 'messenger' },
  { title: 'App', type: 'app_messenger', main_type: 'messenger' },
  { title: 'Viber', type: 'viber_messenger', main_type: 'messenger' },
  { title: 'Whatsapp', type: 'whatsapp_messenger', main_type: 'messenger' },
];

export const getBlockRulesSeedData = (destination) => {
  const pattern = {
    keyword: ['post'],
    intent: ['post'],
    referral: [],
    post: ['keyword', 'intent'],
    url: [],
  };
  let data = null;
  switch (destination) {
    case 'facebook_messenger':
      data = {
        sources: ['facebook_messenger', 'facebook_feed'],
        actions: {
          facebook_messenger: ['keyword', 'intent', 'referral'],
          facebook_feed: ['keyword', 'intent', 'post'],
        },
        pattern,
      };
      return data;
    case 'facebook_feed':
      data = {
        sources: ['facebook_feed'],
        actions: {
          facebook_feed: ['keyword', 'intent', 'post'],
        },
        pattern,
      };
      return data;
    case 'whatsapp_messenger':
      data = {
        sources: ['whatsapp_messenger'],
        actions: {
          whatsapp_messenger: ['keyword', 'intent'],
        },
        pattern,
      };
      return data;
    case 'viber_messenger':
      data = {
        sources: ['viber_messenger'],
        actions: {
          viber_messenger: ['keyword', 'intent', 'referral'],
        },
        pattern,
      };
      return data;
    case 'webchat':
      data = {
        sources: ['webchat'],
        actions: {
          webchat: ['keyword', 'intent', 'referral', 'url'],
        },
        pattern,
      };
      return data;
    case 'app_messenger':
      data = {
        sources: ['app_messenger'],
        actions: {
          app_messenger: ['keyword', 'intent', 'referral'],
        },
        pattern,
      };
      return data;
    default:
      return data;
  }
};

export const findAlertInfo = (intent) => {
  switch (intent) {
    case 'success':
      return { icon: successIcon, className: 'text-success' };
    case 'danger':
      return { icon: dangerIcon, className: 'text-danger' };
    case 'warning':
      return { icon: warningIcon, className: 'text-warning' };
    default:
      return { icon: successIcon, className: 'text-success' };
  }
};

export const generateId = (length) => {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const grantNotificationAccess = () => {
  if (window.Notification && Notification.permission === 'default') {
    Notification.requestPermission(() => {
      if (Notification.permission === 'granted') {
        // status is "granted", if accepted by user
        toaster.success('Success', {
          description: 'Successfully Activated Browser Notification',
        });
      }
    });
  }
};

export const initiateNotification = (title, body) => {
  if (window.Notification && Notification.permission === 'granted') {
    const notification = new Notification(title, {
      body: body,
    });
    setTimeout(() => {
      notification.close();
    }, 5000); //5s closing timeout
  } else {
    grantNotificationAccess();
  }
};

export const getIsReadIcon = (value) => {
  switch (value) {
    case true:
      return isReadIcon;
    case false:
      return isUnreadIcon;
    default:
      return isReadIcon;
  }
};

export const getShortNumber = (number) => {
  if (isNaN(number)) return number;

  if (number < 9999) {
    return number;
  }

  if (number < 1000000) {
    return Math.round(number / 1000) + 'K';
  }
  if (number < 10000000) {
    return (number / 1000000).toFixed(2) + 'M';
  }

  if (number < 1000000000) {
    return Math.round(number / 1000000) + 'M';
  }

  if (number < 1000000000000) {
    return Math.round(number / 1000000000) + 'B';
  }

  return '1T+';
};

export const openInSelfTab = (url) => {
  if (!url.startsWith('http')) {
    url = 'https://' + url;
  }
  const newWindow = window.open(url, '_self', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const useTimer = (timeSpan) => {
  const [timeLeft, setTimeLeft] = React.useState(timeSpan);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return timeLeft;
};
