import React, { Fragment } from 'react';
import { SelectMenu, Button, Icon } from 'evergreen-ui';
import PropTypes from 'prop-types';
import TitleHolderBuilder from './TitleHolderBuilder';

const InputValidationFragment = ({
  value,
  onChange,
  options,
  hasWriteAccess,
}) => {
  return (
    <Fragment>
      <TitleHolderBuilder
        title='User Input Validation'
        tooltipInfo='You can validate input like email or phone'
      />
      <SelectMenu
        height={100}
        width={180}
        hasTitle={false}
        hasFilter={false}
        title='Select name'
        options={options}
        selected={value}
        onSelect={(item) => onChange(item.value)}
      >
        <Button
          disabled={!hasWriteAccess}
          height={40}
          style={{ minWidth: 200 }}
          className='custom-input-style bg-white flex space-between'
        >
          {options.filter((e) => e.value === value)[0].label ||
            'No Validation Selected'}
          <Icon icon='caret-down' size={16} />
        </Button>
      </SelectMenu>
    </Fragment>
  );
};

InputValidationFragment.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
};
InputValidationFragment.defaultProps = {
  options: [
    { label: 'Basic Validation', value: 'basic' },
    { label: 'Number Validation', value: 'number' },
  ],
};

export default InputValidationFragment;
