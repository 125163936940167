import axios from 'axios';
import AXIOS from '../../../httpClient';
import { navigate } from '@reach/router';
import { toaster } from 'evergreen-ui';
import config from '../../../config';
import { sendAmplitudeData } from './../../../utilities/amplitude';

const initialState = {
  username: '',
  access: '',
  refresh: '',
  avatar: '',
  full_name: '',
  email: '',
  is_pro: false,
  already_verified: false,
  invitationToken: '',
  forceLogout: true,
  // version: process.env.REACT_APP_VERSION,
};

export const auth = {
  state: {
    ...initialState,
  },
  reducers: {
    initLogin(state, payload) {
      return { ...state, ...payload };
    },
    updateInvitationToken(state, payload) {
      return { ...state, invitationToken: payload };
    },
    updateEmail(state, payload) {
      return { ...state, email: payload };
    },
    updateForceLogout(state, payload) {
      return { ...state, forceLogout: payload };
    },
    updateAlreadyVerified(state, payload) {
      return { ...state, already_verified: payload };
    },
    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async login(payload) {
      try {
        const res = await axios.post(config.login, payload);
        dispatch.auth.initLogin(res.data);
        dispatch.auth.updateForceLogout(false);
        this.updateInfo(res.data);
        return res.data.success;
      } catch (err) {
        return false;
      }
    },
    async checkDuplicateEmail(payload) {
      try {
        await axios.post(config.checkDuplicateEmail, payload);
        dispatch.auth.updateForceLogout(false);
        return true;
      } catch (err) {
        return false;
      }
    },
    async register(payload) {
      try {
        const res = await axios.post(config.register, payload);
        this.updateEmail(payload.email);
        dispatch.auth.updateForceLogout(false);
        return res.data.success;
      } catch (err) {
        return false;
      }
    },
    async resendRegisterVerifyLink(payload) {
      try {
        const res = await axios.post(config.resendRegisterVerifyLink, payload);
        if (res.data.status === 201) {
          dispatch.auth.updateAlreadyVerified(true);
        }
        return true;
      } catch (err) {
        !!err.response && toaster.danger(err.response.data.error);
        return false;
      }
    },
    async registerVerify(payload) {
      try {
        const res = await axios.post(config.registerVerify, payload);
        return res.data.success;
      } catch (err) {
        !!err.response && toaster.danger(err.response.data.error);
        return false;
      }
    },
    async resetRequest(payload) {
      try {
        const res = await axios.post(config.resetRequest, payload);
        this.updateEmail(payload.email);
        return res.data.success;
      } catch (err) {
        !!err.response && toaster.danger(err.response.data.error);
        return false;
      }
    },
    async resetVerify(payload) {
      try {
        const res = await axios.post(config.resetVerify, payload);
        return res.data.success;
      } catch (err) {
        !!err.response && toaster.danger(err.response.data.error);
        return false;
      }
    },
    async refreshLogin(payload) {
      try {
        const res = await axios.post(config.refresh, payload);
        dispatch.auth.initLogin(res.data);
        return res.status;
      } catch (err) {
        dispatch.auth.logout();
        return err.response.status;
      }
    },
    async updateInfo() {
      try {
        const res = await AXIOS.get(config.info);
        dispatch.auth.initLogin(res.data.dataSource);
        return res.data.success;
      } catch (err) {
        return false;
      }
    },
    async acceptInvitation(token) {
      try {
        const res = await AXIOS.post(`${config.projects}/invitation-accept`, {
          token: token,
        });
        return res.data.success;
      } catch (err) {
        return err.response.status;
      }
    },
    logout() {
      dispatch.auth.clearState();
      dispatch.dashboard.clearState();
      dispatch.builder.clearState();
      dispatch.block.clearState();
      dispatch.settings.clearState();
      dispatch.billing.clearState();
      dispatch.audience.clearState();
      dispatch.api.clearState();
      dispatch.rules.clearState();
      dispatch.dataLab.clearState();
      dispatch.labDetails.clearState();
      dispatch.crm.clearState();
      dispatch.reporting.clearState();
      dispatch.channel.clearState();
      dispatch.marketplace.clearState();
      sendAmplitudeData('Logout');
      navigate(`/`);
    },
  }),
};
