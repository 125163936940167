import React from 'react';
import { TextInputField, Label, Textarea, Combobox } from 'evergreen-ui';
import EditModalSmallHeader from './EditModalSmallHeader';
import FacebookMessengerPersistantMenuModal from './FacebookMessengerPersistantMenuModal';
import { connect } from 'react-redux';
import SliderNLP from "./SliderNLP";

interface props {
  data: any;
  updateEditModalInfo: (key: string, value: any) => void;
  protocolAppId: any;
  protocolMethod: string;
  setProtocolMethod: (val: any) => void;
  setProtocolAppId: (val: any) => void;
  sequences: any[];
  fetchSequence: (id: number) => void;
  setIsChanged: (val: boolean) => void;
}

const FacebookMessengerEditModal: React.FC<props> = ({
  data,
  updateEditModalInfo,
  protocolMethod,
  protocolAppId,
  setProtocolAppId,
  setProtocolMethod,
  sequences,
  fetchSequence,
  setIsChanged,
}) => {
  React.useEffect(() => {
    fetchSequence(data.id);
    if (data.persistent_menu.length < 3) {
      updateEditModalInfo('persistent_menu', [
        ...data.persistent_menu,
        {
          id:
            data.persistent_menu.length !== 0
              ? data.persistent_menu[data.persistent_menu.length - 1].id + 1
              : data.persistent_menu.length,
          title: '',
          type: 'url',
          value: '',
        },
      ]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className='channel-edit-modal__container-platform'>
      <div className='flex flex-child-1'>
        <TextInputField
          label='Title'
          tabIndex={1}
          required
          className='rd-input-style'
          placeholder='Your Channel Title'
          inputHeight={40}
          value={data.title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateEditModalInfo('title', e.target.value)
          }
        />
        <TextInputField
          label='Link'
          required
          marginLeft={20}
          className='rd-input-style'
          placeholder='Your Channel Link'
          value={data.url}
          inputHeight={40}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateEditModalInfo('url', e.target.value)
          }
        />
      </div>
      <div className='flex flex-child-1'>
        <TextInputField
          label='Page Name'
          required
          className='rd-input-style'
          placeholder='Page Name...'
          value={data.name}
          inputHeight={40}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateEditModalInfo('name', e.target.value)
          }
        />
        <div className='ml-20px'>
          <Label marginBottom={4} display='block'>
            Description
          </Label>
          <Textarea
            className='rd-input-style'
            placeholder='Type Here...'
            width={'100%'}
            value={data.description}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateEditModalInfo('description', e.target.value)
            }
          />
        </div>
      </div>
      <br />
      <br />
      <div className='primary-hr' />
      <EditModalSmallHeader
        title='Persistent Menu'
        subtitle='Create persistent menu for the selected platform. (3 Max)'
      />
      {data.persistent_menu.map((menu: any, i: number) => (
        <FacebookMessengerPersistantMenuModal
          key={i}
          data={menu}
          sequences={sequences}
          onDataChange={(key: string, value: any) => {
            updateEditModalInfo(
              'persistent_menu',
              data.persistent_menu.map((data: any) => {
                if (data.id === menu.id) {
                  data[key] = value;
                }
                return data;
              })
            );
          }}
          onDelete={(id: number) => {
            updateEditModalInfo(
              'persistent_menu',
              data.persistent_menu.filter((e: any) => e.id !== id)
            );
          }}
        />
      ))}
      {data.persistent_menu.length < 3 && (
        <div
          role='button'
          className='channel-edit-modal__container-platform__add-more-button'
          onClick={() => {
            updateEditModalInfo('persistent_menu', [
              ...data.persistent_menu,
              {
                id:
                  data.persistent_menu.length !== 0
                    ? data.persistent_menu[data.persistent_menu.length - 1].id +
                      1
                    : data.persistent_menu.length,
                title: '',
                type: 'url',
                value: '',
              },
            ]);
          }}
        >
          Add More+
        </div>
      )}
      <br /> <br />
      <div className='primary-hr' />
      <EditModalSmallHeader
        title='Handover Protocol'
        subtitle='Connect to Handover Protocol only if you want.'
        link={{
          url:
            'https://developers.facebook.com/docs/messenger-platform/handover-protocol/assign-app-roles',
          text: 'Know More',
        }}
      />
      <div className='flex flex-child-1'>
        <div>
          <p className='input-label'>Select Handover Type</p>
          <Combobox
            selectedItem={protocolMethod}
            items={['Facebook Page Inbox', 'Your App']}
            disabled={false}
            inputProps={{ disabled: true }}
            width={'100%'}
            height={40}
            className={'rd-input-style-combobox'}
            onChange={(selected) => {
              setProtocolMethod(selected);
                setIsChanged(true);
              if (selected === 'Facebook Page Inbox') {
                setProtocolAppId('263902037430900');
              } else {
                setProtocolAppId('');
              }
            }}
          />
        </div>

        <TextInputField
          label='App ID'
          required
          marginTop={5}
          isInvalid={!protocolAppId}
          marginLeft={20}
          className='rd-input-style'
          placeholder='Write here...'
          inputHeight={40}
          value={protocolAppId}
          disabled={protocolMethod === 'Facebook Page Inbox'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setProtocolAppId(e.target.value);
              setIsChanged(true);
          }}
        />
      </div>
      <br />
      <div className='primary-hr' />
      <EditModalSmallHeader
        title='NLP Settings'
        subtitle='Provide Natural Language Processing for rules training'
        link={{ url: 'https://docs.getalice.ai/', text: 'Know More' }}
      />
      <div className='flex flex-child-1'>
        <Combobox
          selectedItem={data.nlp_engine}
          items={['wit']}
          disabled={false}
          inputProps={{ disabled: true }}
          width={'100%'}
          height={40}
          className={'rd-input-style-combobox'}
          onChange={(selected) => {
            updateEditModalInfo('nlp_engine', selected);
          }}
        />
      </div>
      <br />
      <div className='flex flex-child-1'>
        <TextInputField
          label='App ID'
          required
          className='rd-input-style'
          placeholder='Write here...'
          inputHeight={40}
          value={data?.nlp_info?.app_id || ''}
          onFocus={() => updateEditModalInfo('nlp_engine', 'wit')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            updateEditModalInfo('nlp_info', {
              ...data.nlp_info,
              app_id: e.target.value,
            });
          }}
        />
        <TextInputField
          label='Token'
          required
          marginLeft={20}
          onFocus={() => updateEditModalInfo('nlp_engine', 'wit')}
          className='rd-input-style'
          placeholder='Write here...'
          inputHeight={40}
          value={data?.nlp_info?.token || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            updateEditModalInfo('nlp_info', {
              ...data.nlp_info,
              token: e.target.value,
            });
          }}
        />
      </div>
      <br />
      <div className='w-100p'>
          <SliderNLP data={data} updateEditModalInfo={updateEditModalInfo}/>
      </div>
    </div>
  );
};

const mapState = (state: any) => ({
  sequences: state.builder.sequences,
});

const mapDispatch = (dispatch: any) => ({
  fetchSequence: (channelId: number) =>
    dispatch.builder.fetchSequence(channelId),
});

const FacebookMessengerEditModalContainer = connect(
  mapState,
  mapDispatch
)(FacebookMessengerEditModal);

export default FacebookMessengerEditModalContainer;
