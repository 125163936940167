import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const DashboardHeader = ({ title, projects }) => {
  return (
    <div className='layout-container'>
      <div
        className={
          projects.length > 0
            ? 'layout__header flex-4'
            : 'layout__header flex-10'
        }
      >
        <div className='layout__header-info'>
          <p className='layout__header-info-title'>{title}</p>
        </div>
      </div>
      {/*<div className='layout__header layout-right-side flex-1'>*/}
      {/*  <Popover*/}
      {/*    position={Position.BOTTOM_LEFT}*/}
      {/*    content={*/}
      {/*      <Menu>*/}
      {/*        <Menu.Group*/}
      {/*          title={*/}
      {/*            !!selectedProject && selectedProject.id*/}
      {/*              ? selectedProject.name*/}
      {/*              : !!userName*/}
      {/*              ? userName*/}
      {/*              : 'Project'*/}
      {/*          }*/}
      {/*        >*/}
      {/*          <a*/}
      {/*            className='no-underline'*/}
      {/*            target='_blank'*/}
      {/*            rel='noopener noreferrer'*/}
      {/*            href='https://docs.getalice.ai/'*/}
      {/*          >*/}
      {/*            <Menu.Item icon='book'>Documentation</Menu.Item>*/}
      {/*          </a>*/}
      {/*          <Menu.Divider />*/}
      {/*          <Menu.Item*/}
      {/*            icon='log-out'*/}
      {/*            intent='danger'*/}
      {/*            onSelect={() => {*/}
      {/*              logout();*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            Logout*/}
      {/*          </Menu.Item>*/}
      {/*        </Menu.Group>*/}
      {/*      </Menu>*/}
      {/*    }*/}
      {/*  >*/}
      {/*    <Button height={26} className='round-caret__buttonstyle'>*/}
      {/*      {userEmail || userName || 'Not Available'}*/}
      {/*      <Icon icon={'caret-down'} marginLeft={10} />*/}
      {/*    </Button>*/}
      {/*  </Popover>*/}
      {/*</div>*/}
    </div>
  );
};

DashboardHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
};

const mapState = (state) => ({
  userName: state.auth.full_name,
  userEmail: state.auth.email,
  selectedProject: state.dashboard.selectedProject,
  projects: state.dashboard.projects,
  loading: state.loading.effects.dashboard.fetchProject,
  sequences: state.builder.sequences,
  selectedSequence: state.builder.selectedSequence,
});

const mapDispatch = (dispatch) => ({
  fetchProject: () => dispatch.dashboard.fetchProject(),
  setSelectedProject: (project) =>
    dispatch.dashboard.setSelectedProject(project),
  setSelectedPlatform: (platform) =>
    dispatch.dashboard.setSelectedPlatform(platform),
  setSelectedProjectSettings: (project) =>
    dispatch.settings.setSelectedProject(project),
  updateSelectedNav: (navId) => dispatch.dashboard.updateSelectedNav(navId),
  fetchProjectAccess: (projectId) =>
    dispatch.settings.fetchProjectAccess(projectId),
  logout: () => dispatch.auth.logout(),
});

const DashboardHeaderContainer = connect(
  mapState,
  mapDispatch
)(DashboardHeader);

export default DashboardHeaderContainer;
