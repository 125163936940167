import axios from '../../../httpClient';
import config from '../../../config';
import querystring from 'querystring';
import { openInSelfTab } from '../../../utils';
import { toaster } from 'evergreen-ui';

const initialState = {
  marketPlaceData: {
    project_id: -1,
    store_url: '',
    is_verified: false,
    has_plugin_connected: false,
    is_deleted: false,
    id: -1,
  },
  customerList: [],
};

export const marketplace = {
  state: {
    ...initialState,
  },
  reducers: {
    updateMarketPlaceData(state, payload) {
      return { ...state, marketPlaceData: payload };
    },
    updateCustomerList(state, payload) {
      return { ...state, customerList: [...payload] };
    },
    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async fetchMarketPlaceData(teamId) {
      try {
        const res = await axios.get(
          `${config.marketplace}/projects/${teamId}/get-ecommerce-data`
        );
        if (res.data.success) {
          if (!!res.data.dataSource) {
            dispatch.marketplace.updateMarketPlaceData(res.data.dataSource);
          } else {
            const localData = {
              project_id: teamId,
              store_url: '',
              is_verified: false,
              has_plugin_connected: false,
              is_deleted: false,
              id: -1,
            };
            dispatch.marketplace.updateMarketPlaceData(localData);
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async initializeMarketPlaceData({ teamId, storeURL, returnURL }) {
      try {
        const res = await axios.post(
          `${config.marketplace}/projects/${teamId}/initialize-woocommerce-integration`,
          { store_url: storeURL }
        );
        if (res.data.success) {
          dispatch.marketplace.updateMarketPlaceData(res.data.dataSource);
          const store_url = storeURL;
          const endpoint = '/wc-auth/v1/authorize';
          const params = {
            app_name: 'Alice',
            scope: 'read_write',
            user_id: teamId,
            return_url: returnURL,
            callback_url: `${config.marketplace}/woocommerce-callback-url`,
          };
          const query_string = querystring
            .stringify(params)
            .replace(/%20/g, '+');

          openInSelfTab(store_url + endpoint + '?' + query_string);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async disconnectEcommerceIntegration(teamId) {
      try {
        const res = await axios.post(
          `${config.marketplace}/projects/${teamId}/remove-ecommerce-integration`,
          {}
        );
        if (res.data.success) {
          dispatch.marketplace.fetchMarketPlaceData(teamId);
          toaster.success('Success', {
            description: 'Disconnected Successfully',
          });
        } else {
          toaster.danger('Failed', {
            description: 'Failed to Disconnect from Team',
          });
        }
      } catch (err) {
        console.log(err);
        toaster.danger('Failed', {
          description: 'Failed to Disconnect from Team',
        });
      }
    },
    async disconnectEcommercePluginIntegration(teamId) {
      try {
        const res = await axios.post(
          `${config.marketplace}/projects/${teamId}/remove-plugin-connection`,
          {}
        );
        if (res.data.success) {
          dispatch.marketplace.fetchMarketPlaceData(teamId);
          toaster.success('Success', {
            description: 'Plugin Disconnected Successfully',
          });
        } else {
          toaster.danger('Failed', {
            description: 'Failed to Disconnect Plugin from Marketplace',
          });
        }
      } catch (err) {
        console.log(err);
        toaster.danger('Failed', {
          description: 'Failed to Disconnect Plugin from Marketplace',
        });
      }
    },
    async getEcommerceCustomerList(payload) {
      //payload = teamId,search
      try {
        const res = await axios.get(
          `${config.marketplace}/projects/${payload.teamId}/customer-list`,
          { params: { search: payload.search } }
        );
        if (res.data.success) {
          dispatch.marketplace.updateCustomerList(res.data.dataSource);
        } else {
          dispatch.marketplace.updateCustomerList([]);
        }
      } catch (err) {
        console.log(err);
        dispatch.marketplace.updateCustomerList([]);
      }
    },
    async linkCustomerWithCRM(payload) {
      // payload = {teamId, customerId,ecommerceId}
      try {
        const res = await axios.post(
          `${config.marketplace}/projects/${payload.teamId}/link-customer`,
          {
            alice_customer_id: payload.customerId,
            ecommerce_account_id: payload.ecommerceId,
          }
        );
        if (res.data.success) {
          dispatch.crm.updateCustomerMetaEcomReference(
            res.data.customer_ecommerce_reference
          );
          dispatch.crm.fetchCRMCustomerOrder(
            payload.teamId,
            payload.customerId,
            ''
          );
          return true;
        } else {
          dispatch.crm.updateCustomerMetaEcomReference(null);
          return false;
        }
      } catch (err) {
        console.log(err);
        dispatch.crm.updateCustomerMetaEcomReference(null);
        return false;
      }
    },
    async unlinkCustomerWithCRM(payload) {
      // payload = {teamId, customerId}
      try {
        const res = await axios.post(
          `${config.marketplace}/projects/${payload.teamId}/unlink-customer`,
          {
            alice_customer_id: payload.customerId,
          }
        );
        if (res.data.success) {
          dispatch.crm.updateCustomerMetaEcomReference(null);
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    },
  }),
};
