import React from 'react';
import { Position, Popover, Menu, Icon, Button } from 'evergreen-ui';
import {
  getPlatformInfoTitle,
  getUpdatedPlatformIconOutlined,
} from '../../utils';

interface props {
  platformData: any[];
  handleSelect: (channel: any) => void;
  selectedPlatform: any;
  title: string;
}

const DropdownMenu2: React.FC<props> = ({
  platformData,
  handleSelect,
  selectedPlatform,
    title,
}) => {
  return (
    <Popover
      position={Position.BOTTOM_LEFT}
      content={({ close }) => (
        <Menu>
          <Menu.Group title={title}>
            {platformData.map((channel: any, key: number) => (
              <Menu.Item
                key={key}
                onSelect={() => {
                  handleSelect(channel);
                  close();
                }}
              >
                <div className='flex align-center'>
                  <img
                    src={getUpdatedPlatformIconOutlined(
                      !!channel.type ? channel.type : 'all'
                    )}
                    alt={channel.type}
                    width={20}
                  />
                  &nbsp;&nbsp; {getPlatformInfoTitle(channel)}
                </div>
              </Menu.Item>
            ))}
          </Menu.Group>
        </Menu>
      )}
    >
        <Button className='dropdown-menu-button'>
        <div className='flex align-center'>
          {
            <img
              src={getUpdatedPlatformIconOutlined(
                !!selectedPlatform.type ? selectedPlatform.type : 'all'
              )}
              alt={'lorem'}
              width={selectedPlatform.type === 'all' ? 12 : 20}
            />
          }{' '}
          &nbsp;&nbsp; {getPlatformInfoTitle(selectedPlatform)} &nbsp;
          <Icon icon={'caret-down'} size={16} />
        </div>
      </Button>
    </Popover>
  );
};

export default DropdownMenu2;
