import React from 'react';
import { Position, Popover, Menu, Button, Icon } from 'evergreen-ui';

interface props {
  showReplyType: boolean;
  feedAction: string;
  updateFeedAction: (value: string) => void;
  hasPrivateReply: boolean;
}
interface platformProps {
  name: string;
  value: string;
}

const ReplyInputTopHeader: React.FC<props> = ({
  showReplyType,
  feedAction,
  updateFeedAction,
  hasPrivateReply,
}) => {
  const [platformData, setPlatformData] = React.useState([
    {
      name: 'Reply In Comments',
      value: 'public_reply',
    },
  ]);

  React.useEffect(() => {
    if (hasPrivateReply) {
      setPlatformData([
        {
          name: 'Reply In Comments',
          value: 'public_reply',
        },
        {
          name: 'Private Message',
          value: 'direct_message',
        },
      ]);
    }else {
        setPlatformData([
            {
                name: 'Reply In Comments',
                value: 'public_reply',
            }
        ]);
    }
  }, [hasPrivateReply]);

  return (
    <div className='customer-chat__reply-topHeader'>
      {showReplyType && (
        <>
          <p className='label-info'>REPLY IN:</p>
          <div className='flex'>
            <Popover
              position={Position.BOTTOM_LEFT}
              content={({ close }) => (
                <Menu>
                  <Menu.Group title='Reply Type'>
                    {platformData.map((platformItem, i) => (
                      <Menu.Item
                        key={i}
                        style={{ padding: 20 }}
                        onSelect={() => {
                          updateFeedAction(platformItem.value);
                          close();
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {platformItem.name}
                        </div>
                      </Menu.Item>
                    ))}
                  </Menu.Group>
                </Menu>
              )}
            >
              <Button height={18} appearance='minimal' style={{color: '#8d99ae', fontWeight: 'bold'}}>
                <div className='flex align-center'>
                  {platformData.filter(
                    (platform: platformProps) => platform.value === feedAction
                  )[0]?.name || 'N/A'}
                </div>
                <Icon icon='chevron-down' marginLeft={10} size={16} />
              </Button>
            </Popover>
          </div>
        </>
      )}
    </div>
  );
};

export default ReplyInputTopHeader;
