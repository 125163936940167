import React from 'react';
import { Pane, Icon } from 'evergreen-ui';
import PropTypes from 'prop-types';

const FetchFromAPIButtonSection = ({
  blockId,
  apiButtonData,
  handleDelete,
  hasWriteAccess,
}) => {
  return (
    <div className='button-group-create-container'>
      <Pane className='button-group__child custom-api-button__field'>
        <div className='button-group__child-title'>
          {'<<' + apiButtonData.title + '>>'}
        </div>
        <div className='button-group__child-subtitle'>
          This Button Section Will Be Fetched from this API
        </div>
        {hasWriteAccess && (
          <div className='button-group__child__absolute'>
            <Icon
              onClick={() =>
                handleDelete(
                  blockId,
                  { label: apiButtonData.title, value: apiButtonData.id },
                  true
                )
              }
              icon='trash'
              size={12}
            />
          </div>
        )}
      </Pane>
    </div>
  );
};

FetchFromAPIButtonSection.propTypes = {
  blockId: PropTypes.number.isRequired,
  apiButtonData: PropTypes.object.isRequired,
  handleDelete: PropTypes.func,
  hasWriteAccess: PropTypes.bool,
};

FetchFromAPIButtonSection.defaultProps = {
  apiButtonData: {},
  handleDelete: () =>
    console.log('delete function executed from default props'),
  hasWriteAccess: false,
};
export default FetchFromAPIButtonSection;
