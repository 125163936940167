import React from 'react';
import {Icon,Avatar} from 'evergreen-ui';
import defaultAvatar from './../../../assets/images/defaultAvatar.svg';

interface tagProps {
  value: any;
  label: string;
  avatar?: string;
}

interface Prop {
  data: tagProps;
  intent?: 'default' | 'success' | 'danger' | 'active';
  size?:  number;
  isClosable?: boolean;
  handleOnClose?: (tag: any) => void;
  hasAvatar?: boolean;
  isBold?: boolean;
}

const TagRedesign: React.FC<Prop> = (
  {
    data,
    intent = 'default',
    size = 14,
    isClosable = false,
    handleOnClose,
    hasAvatar=false,
    isBold = false
  }) => {

  return (
    <div
      className={`alice-tag alice-tag-${intent}`}
      style={isBold ? {fontSize: size, fontWeight: 500} : {fontSize: size}}>
      {hasAvatar && <><Avatar size={size + 2} src={data?.avatar || defaultAvatar}/> &nbsp;</>}
      {data.label}
      {isClosable &&
      <Icon
        marginLeft={3}
        onClick={
          () => {
            !!handleOnClose && handleOnClose(data)
          }
        }
        icon='cross'
      />}
    </div>
  )
};

export default TagRedesign;
