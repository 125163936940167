import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'evergreen-ui';
import AuthFormHeader from './AuthFormHeader';
import MaterialInput from '../../../components/elements/MaterialInput';
import AuthFooter from './AuthFooter';

const AccountVerifyForm = ({
  verifyCode,
  content,
  handleChange,
  loading,
  handleSubmit,
}) => {
  return (
    <div className='form-container'>
      <div className='form-container__content'>
        <AuthFormHeader
          title={content.verifyRegister.title}
          linkText={content.loginInfo.linkTitle}
          link={content.loginInfo.link}
          linkInfo={content.loginInfo.text}
        />
        <br />
        <MaterialInput
          label='Verification Code'
          type='text'
          name='verifyCode'
          value={verifyCode}
          onKeyPress={(e) => (e.key === 'Enter' ? handleSubmit() : null)}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
        <br />
        <br />
        <Button
          isLoading={loading}
          onClick={() => handleSubmit()}
          className='auth-login-button'
          height={40}
        >
          {content.verifyRegister.submitButtonTitle}
        </Button>
        <br />
        <br />
        <AuthFooter />
      </div>
    </div>
  );
};

AccountVerifyForm.propTypes = {
  verifyCode: PropTypes.string,
  content: PropTypes.any,
  loading: PropTypes.bool,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default AccountVerifyForm;
