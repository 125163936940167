import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, TextInput, IconButton } from 'evergreen-ui';
import AttributeAPIContainer from '../../../components/elements/AttributeAPIContainer';
import { updateOnSelectAttribute, updateOnChangeText } from './../../../utils';

const KeyValuePairTableRow = ({
  index,
  keyElem,
  value,
  attributeList,
  handleChangeKeyValuePair,
  handleDeleteKeyValuePair,
  hasWriteAccess,
}) => {
  const [showAttribute, setShowAttribute] = useState(false);

  function setValue(value) {
    handleChangeKeyValuePair('value', value, index);
  }

  return (
    <Table.Row className='key-value-pair'>
      <Table.TextCell className='key-value-pair__item'>
        <TextInput
          className='key-value-pair__inputs'
          placeholder='Provide Key'
          value={keyElem}
          disabled={!hasWriteAccess}
          onChange={(e) =>
            handleChangeKeyValuePair('key', e.target.value, index)
          }
        />
      </Table.TextCell>
      <Table.TextCell className='key-value-pair__item'>
        <AttributeAPIContainer
          attributeList={attributeList === undefined ? [] : attributeList}
          showAttribute={showAttribute}
          setShowAttribute={setShowAttribute}
          handleSelect={(selectedValue) => {
            // selectedValue - on select current attribute value
            // showAttribute - flag for showing attribute popup
            // showAPI - not needed in this case so false always
            // setShowAttribute - set function for showing attributes popup
            // setShowAPI -  not required so null in this case
            // setValue - sets value to redux store
            // value - current value of text input
            // apiList = empty array as not require in this case
            updateOnSelectAttribute(
              selectedValue,
              showAttribute,
              false,
              setShowAttribute,
              null,
              setValue,
              value,
              []
            );
          }}
        >
          <TextInput
            className='key-value-pair__inputs'
            placeholder='Provide Value'
            value={value}
            disabled={!hasWriteAccess}
            onChange={(e) => {
              // e - event fired on onChange function
              // setShowAttribute - set function for showing attributes popup
              // setValue - sets value to redux store
              updateOnChangeText(
                e.target.value,
                setShowAttribute,
                null,
                setValue
              );
            }}
          />
        </AttributeAPIContainer>
      </Table.TextCell>
      <Table.TextCell
        flexBasis={60}
        flexShrink={0}
        flexGrow={0}
        className='key-value-pair__item'
      >
        <IconButton
          className='key-value-pair__iconButton'
          onClick={() => handleDeleteKeyValuePair(index)}
          marginBottom={16}
          disabled={!hasWriteAccess}
          appearance='minimal'
          height={24}
          icon='trash'
        />
      </Table.TextCell>
    </Table.Row>
  );
};

KeyValuePairTableRow.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  hasWriteAccess: PropTypes.bool,
};

KeyValuePairTableRow.defaultProps = {
  title: 'Default',
  subtitle: 'provide a long valid description what it do',
  hasWriteAccess: false,
};
export default KeyValuePairTableRow;
