import React from 'react';
import {IconButton} from "evergreen-ui";

interface Prop {
  handleClose: () => void;
}

const WooCommerceHeader: React.FC<Prop> = ({handleClose}) => {

  return (
      <div className='woocommerce-modal__header'>
        <div>
          <p className='woocommerce-modal__title'>WooCommerce Integration</p>
          <p className='woocommerce-modal__subtitle'>Complete all the steps for a successful Integration</p>
        </div>
        <IconButton icon='cross' appearance='minimal' onClick={handleClose}/>
      </div>
  );
};

export default WooCommerceHeader;
