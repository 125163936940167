import React from 'react';
import { Table, SearchInput } from 'evergreen-ui';

interface TabProps {
  id: number;
  name: string;
}
interface props {
  tableData: any[];
  tabData: TabProps[];
  currentTab: TabProps;
  onTabChange: (val: TabProps) => void;
    setSearchKeyword: (val:any) => void;
    searchKeyword: any;
}

const ReportingTable: React.FC<props> = ({
  tableData,
  tabData,
  currentTab,
  onTabChange,
    setSearchKeyword,
    searchKeyword,
}) => {
  return (
    <div className='reporting-container__user-card-container-elem'>
      <div className='reporting-container__user-card-container-elem-data'>
        <div className='flex space-between align-center'>
          <div className='reporting-div-tab'>
            {tabData.map((tab: TabProps) => (
              <div
                key={tab.id}
                onClick={() => {
                  if (tab.id !== currentTab.id) {
                    onTabChange(tab);
                  }
                }}
                className={
                  tab.id === currentTab.id
                    ? 'reporting-div-tab__elem active'
                    : 'reporting-div-tab__elem'
                }
              >
                {tab.name}
              </div>
            ))}
          </div>
          <div>
            <SearchInput placeholder='Search here...' value={searchKeyword} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setSearchKeyword(e.target.value)} width={'100%'} />
          </div>
        </div>
        <br />
        <Table>
          <Table.Head>
            <Table.TextHeaderCell>TITLE</Table.TextHeaderCell>
            <Table.TextHeaderCell>CLICK</Table.TextHeaderCell>
            <Table.TextHeaderCell>UNIQUE CUSTOMER</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body height={240}>
            {!!tableData &&
              tableData.map((rowItem: any, index: number) => (
                <Table.Row key={index}>
                  <Table.TextCell>{rowItem.title}</Table.TextCell>
                  <Table.TextCell>{rowItem.webhook_count}</Table.TextCell>
                  <Table.TextCell>{rowItem.customer_count}</Table.TextCell>
                </Table.Row>
              ))}
            {(!tableData || (!!tableData && tableData.length === 0)) && (
              <Table.Row className='flex align-center justify-center'>
                No Data Available
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

export default ReportingTable;
