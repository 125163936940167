import React from 'react';
import CreateGroupField from './group/CreateGroupField';
import AdminGroupItem from './group/AdminGroupItem';

interface props {
  adminGroup: any[];
  deleteAdminGroup: (groupId: number) => void;
  createAdminGroup: (name: string) => void;
  createAdminGroupLoader: boolean;
  editAdminGroup: (groupId: number, name: string) => void;
  hasWriteAccess: boolean;
}

const SettingsAdminGroups: React.FC<props> = ({
  adminGroup,
  deleteAdminGroup,
  createAdminGroup,
  createAdminGroupLoader,
  editAdminGroup,
  hasWriteAccess,
}) => {
  return (
    <div className='admin-access-tags__container'>
      {adminGroup.length > 0 && (
        <div className='admin-access-tags__tag-container'>
          {adminGroup.map((group, i) => (
            <AdminGroupItem
              key={i}
              group={group}
              deleteAdminGroup={deleteAdminGroup}
              editAdminGroup={editAdminGroup}
              hasWriteAccess={hasWriteAccess}
            />
          ))}
        </div>
      )}
      {hasWriteAccess && (
        <CreateGroupField
          createAdminGroup={createAdminGroup}
          createAdminGroupLoader={createAdminGroupLoader}
        />
      )}
    </div>
  );
};

export default SettingsAdminGroups;
