import React, { Component, Fragment } from 'react';
import { checkPermission, settingsNavData } from '../../utils';
import { Menu } from 'evergreen-ui';
import { navigate } from '@reach/router';
import { connect } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ReactGA from 'react-ga';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.currentLink = '';
    this.readAccess = 'read:settings';
  }

  getElement = () => {
    return document.getElementById(this.props.location.hash.replace('#', ''));
  };

  updateCurrentActiveSettings = () => {
    const url = this.props.location.href;
    settingsNavData.forEach((group) => {
      group.data.forEach((elem) => {
        if (url.endsWith(elem.link)) {
          this.props.updateActiveSettings(elem.id);
        }
      });
    });
  };

  componentDidUpdate(prevState, prevProps, snapShot) {
    if (this.currentLink !== this.props.location.href) {
      this.currentLink = this.props.location.href;
      this.updateCurrentActiveSettings();
    }
    if (!!this.getElement()) {
      ReactGA.pageview(
        window.location.pathname + window.location.search + window.location.hash
      );
      this.getElement().scrollIntoView({ behavior: 'smooth' });
    }
  }

  async componentDidMount() {
    await this.props.fetchPublicKey();

    ReactGA.pageview(
      window.location.pathname + window.location.search + window.location.hash
    );
    this.currentLink = this.props.location.href;
    this.updateCurrentActiveSettings();
    this.props.updateSelectedNav(5);
    !!this.getElement() &&
      this.getElement().scrollIntoView({
        behavior: 'smooth',
      });
  }

  render() {
    const {
      activeSettings,
      updateActiveSettings,
      selectedProject,
    } = this.props;
    const hasPermission = selectedProject ? selectedProject.is_creator : false;

    const isProUser = selectedProject
      ? selectedProject.is_pro || this.props.trialRemaining > 0
      : false;

    return (
      <main className='dashboard'>
        <div className='layout__wrapper'>
          <div className='layout__sidebar'>
            <Menu>
              {settingsNavData
                .filter((navData) => {
                  if (navData.title.includes('Billing')) {
                    return hasPermission;
                  }
                  return true;
                })
                .map(
                  (groupedSettings, i) =>
                    (groupedSettings.globalAccess ||
                      checkPermission(
                        this.props.permissionList,
                        this.readAccess
                      )) && (
                      <Fragment key={i}>
                        <Menu.Group title={groupedSettings.title}>
                          {groupedSettings.data.map(
                            (singleSettings, j) =>
                              (singleSettings.itemGlobalAccess ||
                                checkPermission(
                                  this.props.permissionList,
                                  this.readAccess
                                )) && (
                                <Menu.Item
                                  key={j}
                                  icon={singleSettings.icon}
                                  onSelect={() => {
                                    navigate(singleSettings.link);
                                    updateActiveSettings(singleSettings.id);
                                  }}
                                  className={`${singleSettings.isPro &&
                                    !isProUser &&
                                    'pro-badge'} ${
                                    activeSettings === singleSettings.id
                                      ? 'menu-elem menu-active'
                                      : 'menu-elem'
                                  }`}
                                >
                                  {singleSettings.title}
                                </Menu.Item>
                              )
                          )}
                        </Menu.Group>
                        {i !== settingsNavData.length - 1 && <Menu.Divider />}
                      </Fragment>
                    )
                )}
            </Menu>
          </div>
          {!!this.props.publicKey && (
            <Elements stripe={loadStripe(this.props.publicKey)}>
              <div className='layout__content'>{this.props.children}</div>
            </Elements>
          )}
        </div>
      </main>
    );
  }
}

const mapState = (state) => ({
  publicKey: state.billing.publicKey,
  selectedProject: state.dashboard.selectedProject,
  trialRemaining: state.auth.trial_remaining,
  activeSettings: state.settings.activeSettings,
  permissionList: state.dashboard.permission.list || [],
});

const mapDispatch = (dispatch) => ({
  fetchPublicKey: () => dispatch.billing.fetchPublicKey(),
  updateActiveSettings: (settingsId) =>
    dispatch.settings.updateActiveSettings(settingsId),
  updateSelectedNav: (navId) => dispatch.dashboard.updateSelectedNav(navId),
});

const SettingsContainer = connect(mapState, mapDispatch)(Settings);

export default SettingsContainer;
