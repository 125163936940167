import React from 'react';
import { Button } from 'evergreen-ui';
import AuthFormHeader from './AuthFormHeader';
import AuthFooter from './AuthFooter';
import verifiedEmailIcon from '../assets/images/logo/verified-email-icon.svg';
import { authData } from '../../../content';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';

const AlreadyVerifiedMail = ({ clearAlreadyVerifiedProps }) => {
  return (
    <div className='form-container'>
      <div className='form-container__content'>
        <div className='t-center'>
          <img
            className='padlockIcon'
            src={verifiedEmailIcon}
            alt='alice logo'
          />
        </div>
        <AuthFormHeader
          title={authData.verifyRegister.alreadyVerifiedTitle}
          subtitle={authData.verifyRegister.alreadyVerifiedSubTitle}
        />
        <br />
        <Button
          onClick={() => {
            clearAlreadyVerifiedProps();
            navigate(`/dashboard`);
          }}
          className='auth-login-button'
          height={40}
        >
          {authData.verifyRegister.alreadyVerifiedButtonTitle}
        </Button>
        <br />
        <br />
        <AuthFooter />
      </div>
    </div>
  );
};
AlreadyVerifiedMail.propTypes = {
  clearAlreadyVerifiedProps: PropTypes.func,
};
export default AlreadyVerifiedMail;
