import React from 'react';
import EditModalSmallHeader from './EditModalSmallHeader';
import { TextInputField, Label, Textarea, Combobox } from 'evergreen-ui';
import ImageUploadField from '../../../../../components/ImageUploadField';
import { CopyBlock, nord } from 'react-code-blocks';
import { ChromePicker } from 'react-color';
import { handleImageUpload } from '../../../../../utils';
import SliderNLP from "./SliderNLP";

interface props {
  data: any;
  updateEditModalInfo: (key: string, value: any) => void;
}
const AppEditModal: React.FC<props> = ({ data, updateEditModalInfo }) => {
  return (
    <div className='channel-edit-modal__container-platform'>
      <EditModalSmallHeader
        title='General Settings'
        subtitle='Change platform name and appearance'
      />
      <div className='flex flex-child-1'>
        <div>
          <ImageUploadField
            hasWriteAccess={true}
            image={data.avatar || ''}
            handleDrop={(image) =>
              handleImageUpload(image, (imageurl: string) =>
                updateEditModalInfo('avatar', imageurl)
              )
            }
            handleClear={() => updateEditModalInfo('avatar', '')}
          />
          <br />
          <br />
          <TextInputField
            label='Title'
            required
            className='rd-input-style'
            placeholder='Your Channel Title'
            inputHeight={40}
            value={data.title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateEditModalInfo('title', e.target.value)
            }
          />
          <TextInputField
            label='Name'
            required
            className='rd-input-style'
            placeholder='Your Channel Name'
            inputHeight={40}
            value={data.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateEditModalInfo('name', e.target.value)
            }
          />
          <Label marginBottom={4} display='block'>
            Description
          </Label>
          <Textarea
            className='rd-input-style'
            placeholder='Type Here...'
            width={'100%'}
            value={data.description}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateEditModalInfo('description', e.target.value)
            }
          />
        </div>
        <div className='primary-color'>
          <Label marginBottom={10} display='block'>
            Color
          </Label>
          <ChromePicker
            className='primary-color-picker'
            color={data?.primary_color || '#000000'}
            disableAlpha={true}
            onChange={(clr: any) => {
              updateEditModalInfo('primary_color', clr.hex);
            }}
          />
        </div>
      </div>
      <br />
      <div className='primary-hr' />
      <EditModalSmallHeader
        title='NLP Settings'
        subtitle='Provide Natural Language Processing for rules training'
        link={{ url: 'www.google.com', text: 'Know More' }}
      />
      <div className='flex flex-child-1'>
        <Combobox
          selectedItem={data.nlp_engine}
          items={['wit']}
          disabled={false}
          inputProps={{ disabled: true }}
          width={'100%'}
          height={40}
          className={'rd-input-style-combobox'}
          onChange={(selected) => {
            updateEditModalInfo('nlp_engine', selected);
          }}
        />
      </div>
      <br />
      <div className='flex flex-child-1'>
        <TextInputField
          label='App ID'
          required
          className='rd-input-style'
          placeholder='Write here...'
          inputHeight={40}
          value={data.nlp_info.app_id || ''}
          onFocus={() => updateEditModalInfo('nlp_engine', 'wit')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            updateEditModalInfo('nlp_info', {
              ...data.nlp_info,
              app_id: e.target.value,
            });
          }}
        />
        <TextInputField
          label='Token'
          required
          marginLeft={20}
          onFocus={() => updateEditModalInfo('nlp_engine', 'wit')}
          className='rd-input-style'
          placeholder='Write here...'
          inputHeight={40}
          value={data.nlp_info.token || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            updateEditModalInfo('nlp_info', {
              ...data.nlp_info,
              token: e.target.value,
            });
          }}
        />
      </div>
      <br />
      <div className='w-100p'>
       <SliderNLP data={data} updateEditModalInfo={updateEditModalInfo}/>
      </div>
      <br />
      <div className='primary-hr' />
      <EditModalSmallHeader
        title='API Key'
        subtitle='Get the api key from here'
      />
      <CopyBlock
        text={data?.access_token || ''}
        language='javascript'
        theme={nord}
        codeBlock
      />
    </div>
  );
};

export default AppEditModal;
