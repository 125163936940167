import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, TextInput, Pane, Tablist, Tab, IconButton } from 'evergreen-ui';
import PopupBlockContent from './PopupBlockContent';
import PopupUrlContent from './PopupUrlContent';
import PopupPhoneContent from './PopupPhoneContent';
import PopupBasicContent from './PopupBasicContent';
import PopupFormContent from './PopupFormContent';
import '../../assets/styles/elements/button-popup.scss';
import TextCharInfo from './TextCharInfo';

const getContentComponent = (
  buttonType,
  items,
  forms,
  value,
  formSequence,
  handleChange,
  messengerExtensions,
  handleMessengerExtensionChange,
  hasMessengerExtension,
  hasWebViewHeight,
  webViewHeight,
  handleWebViewHeightChange
) => {
  switch (buttonType.toLowerCase()) {
    case 'sequence':
      return (
        <PopupBlockContent
          items={items}
          inputVal={value}
          handleChange={(value) =>
            handleChange('sequence', value, formSequence)
          }
        />
      );
    case 'form':
      return (
        <PopupFormContent
          items={forms}
          inputVal={value}
          handleChange={(value) => handleChange('form', value, formSequence)}
          sequences={items}
          sequenceVal={formSequence}
          handleSequenceChange={(val) => handleChange('form', value, val)}
        />
      );
    case 'url':
      return (
        <PopupUrlContent
          inputVal={value.toString()}
          handleChange={(value) => handleChange('url', value, formSequence)}
          handleWebViewHeightChange={handleWebViewHeightChange}
          messengerExtensions={messengerExtensions}
          handleMessengerExtensionChange={handleMessengerExtensionChange}
          hasMessengerExtension={hasMessengerExtension}
          hasWebViewHeight={hasWebViewHeight}
          webViewHeight={webViewHeight}
        />
      );
    case 'phone':
      return (
        <PopupPhoneContent
          inputVal={value}
          handleChange={(value) => handleChange('phone', value, formSequence)}
        />
      );
    case 'basic':
      return <PopupBasicContent />;
    default:
      return (
        <PopupBlockContent
          items={items}
          inputVal={value}
          handleChange={(value) =>
            handleChange('sequence', value, formSequence)
          }
        />
      );
  }
};

const ButtonPopup = ({
  id,
  index,
  title,
  sequences,
  formsData,
  hasMessengerExtension,
  messengerExtensions,
  hasWebViewHeight,
  webViewHeight,
  allowedTypes,
  selectedType,
  closePopover,
  value,
  formSequence,
  handleChange,
}) => {
  const [selectedTab, setSelectedTab] = useState(selectedType);
  const [maxCount] = useState(20);

  const handleTitleChange = (val) => {
    val.length <= maxCount &&
      handleChange(
        {
          id,
          buttonIndex: index,
          value,
          type: selectedTab,
          title: val,
          webview_height_ratio: webViewHeight,
          messenger_extensions: messengerExtensions,
          form_sequence: formSequence,
        },
        'buttons'
      );
  };
  const handleOtherChange = (type, changedItem, formSeq) => {
    handleChange(
      {
        id,
        buttonIndex: index,
        value: changedItem,
        type: type,
        title,
        webview_height_ratio: webViewHeight,
        messenger_extensions: messengerExtensions,
        form_sequence: formSeq,
      },
      'buttons'
    );
  };
  const handleMessengerExtensionChange = (val) => {
    handleChange(
      {
        id,
        buttonIndex: index,
        value: value,
        type: selectedTab,
        title,
        webview_height_ratio: webViewHeight,
        messenger_extensions: val,
        form_sequence: formSequence,
      },
      'buttons'
    );
  };

  const handleWebViewHeightChange = (val) => {
    handleChange(
      {
        id,
        buttonIndex: index,
        value: value,
        type: selectedTab,
        title,
        webview_height_ratio: val,
        messenger_extensions: messengerExtensions,
        form_sequence: formSequence,
      },
      'buttons'
    );
  };

  return (
    <Card className='button-popup'>
      <h4>If User Clicks</h4>
      <div className='button-popup__absolute_-right'>
        <IconButton
          onClick={() => closePopover()}
          marginBottom={16}
          appearance='minimal'
          icon='cross'
        />
      </div>
      <TextInput
        value={title}
        onChange={(e) => {
          handleTitleChange(e.target.value);
        }}
      />
      <TextCharInfo data={title} count={maxCount} />
      <Pane className='button-popup__tab'>
        <Tablist>
          {allowedTypes.map((tab) => {
            const val = tab.toLowerCase();
            return (
              <Tab
                key={val}
                id={val}
                onSelect={() => {
                  setSelectedTab(val);
                  if (val === 'basic') {
                    handleOtherChange('basic', '', formSequence);
                  }
                }}
                isSelected={val === selectedTab}
                aria-controls={`panel-${tab}`}
                className={`button-popup__tab-item ${
                  val === selectedTab ? 'tab-selected' : ''
                }`}
              >
                {tab}
              </Tab>
            );
          })}
        </Tablist>
        <Pane>
          {allowedTypes.map((tab) => {
            const val = tab.toLowerCase();
            return (
              <Pane
                key={val}
                id={`panel-${val}`}
                role='tabpanel'
                aria-labelledby={val}
                aria-hidden={val !== selectedTab}
                display={val === selectedTab ? 'sequence' : 'none'}
                className='button-popup__tab-content'
              >
                {getContentComponent(
                  selectedTab,
                  sequences,
                  formsData,
                  value,
                  formSequence,
                  handleOtherChange,
                  messengerExtensions,
                  handleMessengerExtensionChange,
                  hasMessengerExtension,
                  hasWebViewHeight,
                  webViewHeight,
                  handleWebViewHeightChange
                )}
              </Pane>
            );
          })}
        </Pane>
      </Pane>
    </Card>
  );
};

ButtonPopup.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string.isRequired,
  allowedTypes: PropTypes.arrayOf(PropTypes.string),
  selectedTab: PropTypes.string,
  hasMessengerExtension: PropTypes.bool.isRequired,
  messengerExtensions: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formSequence: PropTypes.number,
  handleChange: PropTypes.func,
  closePopover: PropTypes.func,
  index: PropTypes.number,
  sequences: PropTypes.array,
  formsData: PropTypes.array,
  selectedType: PropTypes.string,
  hasWebViewHeight: PropTypes.bool,
  webViewHeight: PropTypes.string,
};

ButtonPopup.defaultProps = {
  allowedTypes: ['Block', 'URL', 'Phone', 'Form'],
  hasWebViewHeight: false,
};

export default ButtonPopup;
