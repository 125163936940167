import React from 'react';
import WooCommerceHeader from "./WooCommerceHeader";
import {navigate} from "@reach/router";
import WooCommerceModalPlugin from "./WooCommerceModalPlugin";
import WooCommerceModalPluginKey from "./WooCommerceModalPluginKey";

interface Prop {
  location: any;
  marketPlaceData: any;
  fetchMarketPlaceData: () => void;
}

const WooCommerceConfirmModalContainer: React.FC<Prop> = (
  {
    location,
    marketPlaceData,
    fetchMarketPlaceData,
  }) => {

  const [previewState,setPreviewState] = React.useState(0);


  return (
    <div className='woocommerce-modal'>
      <WooCommerceHeader
        handleClose={() => {
          navigate(location.pathname);
        }
        }/>
      {previewState === 0 &&
      <WooCommerceModalPlugin
        handleClose={() => {
          navigate(location.pathname);
        }}
        handleConfirm={() => {
          setPreviewState(1);
        }}
      />}
      {previewState === 1 &&
      <WooCommerceModalPluginKey
        marketPlaceData={marketPlaceData}
        handlePluginKeyCheck={async () => {
          await fetchMarketPlaceData();
        }
        }
      />
      }
    </div>
  );
};

export default WooCommerceConfirmModalContainer;
