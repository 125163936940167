import React, { useState } from 'react';
import BlockContainer from './BlockContainer';
import { Textarea } from 'evergreen-ui';
import ButtonGroup from './../elements/ButtonGroup';
import PropTypes from 'prop-types';
import AttributeAPIContainer from '../elements/AttributeAPIContainer';
import { updateOnChangeText, updateOnSelectAttribute } from '../../utils';
import buttonIcon from './../../pages/builder/assets/images/icons/buttons.svg';
import FetchFromAPIButtonSection from '../elements/FetchFromAPIButtonSection';
import TextCharInfo from '../elements/TextCharInfo';

const ButtonBlock = ({
  id,
  data,
  save,
  saveLoader,
  sequences,
  saveBlock,
  copyBlock,
  moveBlock,
  type,
  attributeList,
  labsData,
  apiList,
  updateButtonsBlock,
  updateButtonElements,
  setText,
  createButtonElement,
  deleteButtonElement,
  maxButtonCount,
  deleteBlock,
  handleAPIButtonElementCreate,
  hasWriteAccess,
  platformType,
}) => {
  const [showAttribute, setShowAttribute] = useState(false);
  const [showAPI, setShowAPI] = useState(false);
  const [maxCount] = useState(620);
  return (
    <BlockContainer
      id={id}
      title='Button'
      subtitle='This will add buttons to block'
      save={save}
      isLoading={saveLoader}
      icon={buttonIcon}
      handleSaveClick={() => saveBlock(id)}
      handleDelete={() => deleteBlock(id)}
      sequences={sequences}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <React.Fragment>
        <AttributeAPIContainer
          attributeList={attributeList}
          showAttribute={showAttribute}
          setShowAttribute={setShowAttribute}
          apiList={apiList.filter((api) => api.return_type === 'text')}
          showAPI={showAPI}
          setShowAPI={setShowAPI}
          handleSelect={(value) =>
            (data.text + value).length + 2 <= maxCount &&
            updateOnSelectAttribute(
              value,
              showAttribute,
              showAPI,
              setShowAttribute,
              setShowAPI,
              setText,
              data.text,
              apiList
            )
          }
        >
          <Textarea
            required={true}
            grammarly={true}
            disabled={!hasWriteAccess}
            spellCheck={true}
            name='text-block'
            placeholder='Your text block for Button Group'
            value={data.text}
            onChange={(e) =>
              e.target.value.length <= maxCount &&
              updateOnChangeText(
                e.target.value,
                setShowAttribute,
                setShowAPI,
                setText
              )
            }
          />
        </AttributeAPIContainer>
        <TextCharInfo data={data.text} count={maxCount} />
        {!!data.api ? (
          <FetchFromAPIButtonSection
            blockId={id}
            hasWriteAccess={hasWriteAccess}
            apiButtonData={data.api}
            handleDelete={handleAPIButtonElementCreate}
          />
        ) : (
          <ButtonGroup
            blockId={id}
            type={type}
            hasMessengerExtension={true}
            hasWebViewHeight={true}
            sequences={sequences}
            buttonTitle='Create a Button'
            APIButtonTitle='Create a API Button'
            allowedTypes={
              platformType === 'webchat' || platformType === 'app_messenger'
                ? ['Sequence', 'URL', 'Form']
                : ['Sequence', 'URL', 'Phone', 'Form']
            }
            maxButtonCount={maxButtonCount}
            formsData={labsData && labsData.filter((l) => l.type === 'form')}
            buttonList={data.buttons}
            handleDropUpdate={updateButtonElements}
            handleCreate={createButtonElement}
            handleDelete={deleteButtonElement}
            handleUpdate={updateButtonsBlock}
            hasAPIButton={true}
            apiButtonData={data.api}
            apiList={apiList}
            hasWriteAccess={hasWriteAccess}
            handleAPIButtonElementCreate={handleAPIButtonElementCreate}
          />
        )}
      </React.Fragment>
    </BlockContainer>
  );
};

ButtonBlock.propTypes = {
  id: PropTypes.number.isRequired,
  save: PropTypes.bool.isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  saveBlock: PropTypes.func.isRequired,
  sequences: PropTypes.array.isRequired,
  updateButtonsBlock: PropTypes.func.isRequired,
  setText: PropTypes.func.isRequired,
  createButtonElement: PropTypes.func.isRequired,
  deleteButtonElement: PropTypes.func.isRequired,
  maxButtonCount: PropTypes.number.isRequired,
  attributeList: PropTypes.arrayOf(PropTypes.string),
  labsData: PropTypes.arrayOf(PropTypes.object),
  apiList: PropTypes.arrayOf(PropTypes.object),
  deleteBlock: PropTypes.func,
  handleAPIButtonElementCreate: PropTypes.func,
  saveLoader: PropTypes.bool.isRequired,
  type: PropTypes.string,
  hasWriteAccess: PropTypes.bool.isRequired,
};
ButtonBlock.defaultProps = {
  maxButtonCount: 3,
  buttonList: [],
  type: null,
};

export default ButtonBlock;
