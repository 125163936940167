import React from 'react';
import {
  TextInput,
  Textarea,
  Switch,
  Button,
  Icon,
  Spinner,
  toaster,
} from 'evergreen-ui';
import ImageUploadField from '../../../components/ImageUploadField';
import { handleImageUpload, isValidUrl } from '../../../utils';
import ColorPicker from '../../../components/elements/ColorPicker';
import PropTypes from 'prop-types';
import TextCharInfo from '../../../components/elements/TextCharInfo';

const RequiredStar = () => <span style={{ color: 'red' }}>*</span>;
const DataLabMaxLength = 150;
const DataLabForm = ({
  title,
  handleTitle,
  description,
  handleDescription,
  logo,
  handleLogo,
  type,
  handleType,
  fontColor,
  handleFontColor,
  buttonColor,
  handleButtonColor,
  buttonText,
  handleButtonText,
  buttonLoading,
  handleCreate,
  isEditing,
  webHookUrl,
  handleWebHookUrl,
}) => {
  return (
    <div className='data-lab__form-container'>
      <div className='data-lab__form-container-item'>
        <label>
          Title
          <RequiredStar />
        </label>
        <TextInput
          placeholder='Lab Title'
          value={title}
          onChange={(e) => handleTitle(e.target.value)}
        />
      </div>
      <div className='data-lab__form-container-item'>
        <label>Description (Optional)</label>
        <Textarea
          value={description}
          placeholder={'Provide A valid Description'}
          onChange={(e) => {
            if (e.target.value.length <= DataLabMaxLength) {
              handleDescription(e.target.value);
            }
          }}
        />
        <TextCharInfo data={description} count={DataLabMaxLength} />
      </div>
      <div className='data-lab__form-container-item'>
        <label>Image (Optional)</label>
        <ImageUploadField
          image={logo}
          handleDrop={(image) => handleImageUpload(image, handleLogo)}
          handleClear={() => handleLogo('')}
        />
      </div>
      <div className='flex-wrapper-3'>
        <div className='data-lab__form-container-item'>
          <label>Form Type?</label>
          <Switch
            marginTop={10}
            marginBottom={10}
            height={24}
            checked={type}
            onChange={(e) => {
              handleType(e.target.checked);
              handleButtonColor('#007b65');
              handleFontColor('#eeeeee');
            }}
          />
        </div>
        {type && (
          <div className='data-lab__form-container-item'>
            <label>Button Text Color?</label>
            <ColorPicker
              color={fontColor}
              handleChange={(color) => handleFontColor(color.hex)}
            />
          </div>
        )}
        {type && (
          <div className='data-lab__form-container-item'>
            <label>Button Background Color?</label>
            <ColorPicker
              color={buttonColor}
              handleChange={(color) => handleButtonColor(color.hex)}
            />
          </div>
        )}
      </div>
      {type && (
        <div className='data-lab__form-container-item'>
          <label>
            Enter Button Text
            <RequiredStar />
          </label>
          <TextInput
            placeholder='Button Text'
            value={buttonText}
            onChange={(e) => handleButtonText(e.target.value)}
          />
        </div>
      )}
      {type && (
        <div className='data-lab__form-container-item'>
          <label>Enter Web hook Url (Optional)</label>
          <TextInput
            placeholder='Web hook Url'
            value={webHookUrl}
            onChange={(e) => handleWebHookUrl(e.target.value)}
          />
          {!isValidUrl(webHookUrl) && !!webHookUrl && (
            <p className='data-lab__hint'>
              <Icon
                icon='warning-sign'
                color='warning'
                size={12}
                marginRight={5}
              />{' '}
              Warning! This is Not Valid Website url
            </p>
          )}
        </div>
      )}
      <Button
        onClick={() => {
          if (!isValidUrl(webHookUrl)) {
            toaster.warning('Invalid Url', {
              description: 'Webhook Url Is Invalid',
              duration: 1,
            });
          } else {
            handleCreate();
          }
        }}
        disabled={buttonLoading}
        className='button-alice__gradient data-lab__custom-next-button'
        height={46}
      >
        {buttonLoading ? (
          <span className='button-loader'>
            <Spinner size={24} />
          </span>
        ) : (
          <span>
            {isEditing ? 'Update' : 'Create'} Data Lab
            <Icon
              icon={'circle-arrow-right'}
              color='disabled'
              marginLeft={16}
            />
          </span>
        )}
      </Button>
    </div>
  );
};

DataLabForm.propTypes = {
  title: PropTypes.string,
  handleTitle: PropTypes.func,
  description: PropTypes.string,
  handleDescription: PropTypes.func,
  logo: PropTypes.string,
  handleLogo: PropTypes.func,
  type: PropTypes.bool,
  handleType: PropTypes.func,
  fontColor: PropTypes.string,
  handleFontColor: PropTypes.func,
  buttonColor: PropTypes.string,
  handleButtonColor: PropTypes.func,
  buttonText: PropTypes.string,
  handleButtonText: PropTypes.func,
  buttonLoading: PropTypes.bool,
  handleCreate: PropTypes.func,
  isEditing: PropTypes.bool,
  webHookUrl: PropTypes.string,
  handleWebHookUrl: PropTypes.func,
};
export default DataLabForm;
