import React from 'react';
import { Combobox } from 'evergreen-ui';
import BlockContainer from './BlockContainer';
import PropTypes from 'prop-types';
import unsubscribeIcon from './../../pages/builder/assets/images/icons/unsubscribe.svg';

const UnsubscribeBlock = ({
  id,
  data,
  sequences,
  subscriptions,
  handleUpdate,
  save,
  saveBlock,
  saveLoader,
  deleteBlock,
  copyBlock,
  moveBlock,
  hasWriteAccess,
}) => {
  return (
    <BlockContainer
      id={id}
      title='Unsubscribe Block'
      subtitle='Put This Block at the end of your sequence and it can only exist in once in a sequence'
      save={save}
      isLoading={saveLoader}
      icon={unsubscribeIcon}
      sequences={sequences}
      handleSaveClick={() => saveBlock(id)}
      handleDelete={() => deleteBlock(id)}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <Combobox
        openOnFocus
        width='100%'
        selectedItem={
          subscriptions.filter((s) => s.id === data.subscribe_block)[0]
        }
        items={subscriptions}
        disabled={!hasWriteAccess}
        itemToString={(item) =>
          item && item.title
            ? item.title
            : item
            ? `No Title for Subscribe ID: ${item.id}`
            : 'Unknown Title'
        }
        onChange={(selected) => selected && handleUpdate(id, selected.id)}
        placeholder='Subscriptions'
      />
    </BlockContainer>
  );
};

UnsubscribeBlock.propTypes = {
  id: PropTypes.number.isRequired,
  save: PropTypes.bool.isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  saveBlock: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  saveLoader: PropTypes.bool.isRequired,
  hasWriteAccess: PropTypes.bool,
};

UnsubscribeBlock.defaultBlock = {
  hasWriteAccess: false,
};

export default UnsubscribeBlock;
