import React from 'react';
import {Dialog, Button, TextInput} from "evergreen-ui";
import {findAlertInfo} from "../../../../../utils";

interface Prop {
  isShown: boolean;
  setShown: (data: boolean) => void;
  intent: ('warning' | 'success' | 'info' | 'danger');
  title?: (string | number);
  description: string;
  onCancelCallback?: any;
  onDeleteCallback?: any;
  hasConfirmText?: boolean;
}

const DisconnectModal: React.FC<Prop> =
  ({
     isShown,
     setShown, intent,
     title,
     description,
     onCancelCallback,
     onDeleteCallback,
     hasConfirmText= false,
   }) => {

  const [confirmCheck,setConfirmCheck] = React.useState('');
  const [showError,setShowError] = React.useState(false);

    return (
      <div>
        <Dialog
          isShown={isShown}
          onCloseComplete={() => setShown(false)}
          hasFooter={false}
          hasHeader={false}
          containerProps={{className: 'rd-modal-style'}}
        >
          <div className='flex align-start-item'>
            <img src={findAlertInfo(intent).icon} width={30} alt={'modal icon'} /> &nbsp;
            <p className='rd-modal-style__title'>&nbsp;{title}</p>
          </div>
          <p className='rd-modal-style__description fs-12px'>{description}</p>
          <br/>
          {hasConfirmText && <>
            <TextInput
              className='rd-input-style'
              isInvalid={showError}
              value={confirmCheck}
              placeholder="Type 'DISCONNECT' Here"
              onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setConfirmCheck(e.target.value)}/>
            <br/>
            <br/>
          </>
          }
          <div className='flex flex-end'>
            <Button className='alice-btn__minimal-black'  marginRight={20} onClick={()=>{
              if(!!onCancelCallback){
                onCancelCallback();
              }
              setShown(false);
            }} >Cancel</Button>
            <Button className='alice-btn__warning' onClick={()=>{
              if(hasConfirmText) {
                if(confirmCheck?.toLowerCase() === 'disconnect'){
                  if(!!onDeleteCallback){
                    setShowError(false);
                    onDeleteCallback();
                    setShown(false);
                  }
                }else {
                  setShowError(true);
                }
              }else {
                if(!!onDeleteCallback){
                  onDeleteCallback();
                  setShown(false);
                }
              }
            }} >Disconnect</Button>
          </div>
        </Dialog>
      </div>
    );
  };

export default  DisconnectModal;
