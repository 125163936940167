import React from 'react';
import PropTypes from 'prop-types';
import { TextInputField, TextInput, SelectMenu, Button } from 'evergreen-ui';
import APIContentContainer from './APIContentContainer';

const APIResource = ({
  title,
  subtitle,
  apiTitle,
  handleChangeApiData,
  httpMethod,
  url,
  hasWriteAccess,
}) => {
  return (
    <APIContentContainer title={title} subtitle={subtitle}>
      <div className='api-resource__container'>
        <TextInputField
          className='api-resource__container-title'
          value={apiTitle}
          disabled={!hasWriteAccess}
          onChange={(e) => handleChangeApiData('title', e.target.value)}
          label='Provide a Title For this API'
          placeholder='Write here...'
        />
        <p className='api-resource__container-label'>Method Type</p>
        <div className={'d-flex'}>
          <SelectMenu
            height={65}
            width={180}
            hasTitle={false}
            hasFilter={false}
            options={[
              { label: 'Get Method', value: 'GET' },
              { label: 'Post Method', value: 'POST' },
            ]}
            selected={httpMethod}
            onSelect={(item) => {
              handleChangeApiData('http_method', item.value);
            }}
          >
            <Button className='no-wrap' disabled={!hasWriteAccess}>
              {httpMethod === 'GET'
                ? 'GET Method'
                : 'POST Method' || 'Select Data Type'}
            </Button>
          </SelectMenu>
          <TextInput
            className='api-resource__container-url-input'
            placeholder='https://'
            disabled={!hasWriteAccess}
            value={url}
            onChange={(e) => handleChangeApiData('url', e.target.value)}
          />
        </div>
      </div>
    </APIContentContainer>
  );
};

APIResource.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  hasWriteAccess: PropTypes.bool,
};

APIResource.defaultProps = {
  title: 'Default',
  subtitle: 'provide a long valid description what it do',
  hasWriteAccess: false,
};
export default APIResource;
