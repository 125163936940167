import React, { Component, Fragment } from 'react';
import './assets/styles/style.scss';
import { connect } from 'react-redux';
import { Dialog, Pane } from 'evergreen-ui';
import LoaderLine from '../../components/loaders/LoaderLine';
import DataLabCreateButton from './components/DataLabCreateButton';
import DataLabCard from './components/DataLabCard';
import { navigate } from '@reach/router';
import ReactGA from 'react-ga';
import HasPermission from '../../components/HasPermission';
import { checkPermission } from '../../utils';
import SearchDataLab from './components/SearchDataLab';

class DataLab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchDataLab: '',
      isDeleteDialogueShown: false,
      loading: true,
      deleteLabId: 0,
      readAccess: 'read:data_lab',
      writeAccess: 'write:data_lab',
    };
  }

  async fetchDataLabs() {
    await this.props.fetchDataLabs(this.props.selectedProject.id);
    this.setState({
      loading: false,
    });
  }
  async componentDidMount() {
    ReactGA.pageview(
      window.location.pathname + window.location.search + window.location.hash
    );
    if (this.props.projects.length > 0) {
      const project = this.props.projects.filter(
        (p) => parseInt(this.props.projectId) === p.id
      )[0];
      await this.props.setSelectedProject(project);
    }
    const hasPermission = checkPermission(
      this.props.permissionList,
      this.state.readAccess
    );

    if (hasPermission) {
      await this.fetchDataLabs();
      this.props.updateSelectedNav(4);
    }
  }

  render() {
    const hasWriteAccess = checkPermission(
      this.props.permissionList,
      this.state.writeAccess
    );
    return (
      <HasPermission shortCode={this.state.readAccess}>
        <Fragment>
          <div className='data-lab__container'>
            {hasWriteAccess && (
              <Dialog
                isShown={this.state.isDeleteDialogueShown}
                title='Delete This Data Lab'
                intent='danger'
                onCloseComplete={() => {
                  this.setState({
                    isDeleteDialogueShown: false,
                    deleteLabId: 0,
                  });
                }}
                onConfirm={async () => {
                  await this.props.handleDeleteLab(
                    this.props.projectId,
                    this.state.deleteLabId
                  );
                  this.setState({
                    isDeleteDialogueShown: false,
                    deleteLabId: 0,
                  });
                }}
                confirmLabel={'Confirm'}
              >
                <p>Are You Sure You Want to Delete This Block?</p>
              </Dialog>
            )}
            {(this.state.loading || this.props.labDataLoading) && (
              <div className='flex-justify-middle'>
                <LoaderLine />
              </div>
            )}
            {!this.state.loading && !this.props.labDataLoading && (
              <div className='data-lab__body'>
                <Pane>
                  <div className='data-lab__action'>
                    <SearchDataLab
                      handleFilter={(evt) =>
                        this.setState({ searchDataLab: evt.target.value })
                      }
                      value={this.state.searchDataLab}
                    />
                    {hasWriteAccess && (
                      <DataLabCreateButton
                        redirectUrl={`/projects/${this.props.projectId}/data-lab/create`}
                        title={'Create a Lab'}
                      />
                    )}
                  </div>
                  <div className='data-lab__header-card'>
                    <p className='data-lab__header-card-title'>TITLE</p>
                    <p className='data-lab__header-card-subtitle'>
                      DESCRIPTION
                    </p>
                    <p className='data-lab__header-card__stat-container-clicks'>
                      CLICKS
                    </p>
                    <p className='data-lab__header-card__stat-container-submitted'>
                      SUBMITTED
                    </p>
                    <p className='data-lab__header-card__stat-container-keys'>
                      KEYS
                    </p>
                    <p className='data-lab__header-card-date'>CREATED DATE</p>
                  </div>
                  {this.props.labsData.length !== 0 ? (
                    this.props.labsData
                      .filter((elem) => {
                        const reg = new RegExp(this.state.searchDataLab, 'i');
                        return reg.test(elem.title);
                      })
                      .map((elem, i) => (
                        <DataLabCard
                          key={i}
                          title={elem.title}
                          type={elem.type}
                          subTitle={elem.description}
                          keyCount={elem.key_count}
                          entryCount={elem.entry_count}
                          clickCount={elem.click_count}
                          createdAt={elem.created_at}
                          hasWriteAccess={hasWriteAccess}
                          handleKeyEdit={() =>
                            navigate(
                              `/projects/${this.props.projectId}/data-lab/edit/${elem.id}`
                            )
                          }
                          handleInfoEdit={() =>
                            navigate(
                              `/projects/${this.props.projectId}/data-lab/edit-info/${elem.id}`
                            )
                          }
                          handleDeleteLab={() => {
                            this.setState({
                              isDeleteDialogueShown: true,
                              deleteLabId: elem.id,
                            });
                          }}
                          handleClickAction={() => {
                            navigate(
                              `/projects/${this.props.projectId}/data-lab/${elem.id}`
                            );
                          }}
                        />
                      ))
                  ) : (
                    <p className='data-lab__wrapper-card-no_data'>
                      No Data Available
                    </p>
                  )}
                </Pane>
              </div>
            )}
          </div>
        </Fragment>
      </HasPermission>
    );
  }
}

const mapState = (state) => ({
  selectedProject: state.dashboard.selectedProject,
  labsData: state.dataLab.labsData,
  projects: state.dashboard.projects,
  labDataLoading: state.loading.effects.dataLab.fetchDataLabs,
  permissionList: state.dashboard.permission?.list || [],
});

const mapDispatch = (dispatch) => ({
  fetchDataLabs: (projectId) => dispatch.dataLab.fetchDataLabs({ projectId }),
  handleDeleteLab: (projectId, labId) =>
    dispatch.dataLab.handleDeleteLab({ projectId, labId }),
  setSelectedProject: (project) =>
    dispatch.dashboard.setSelectedProject(project),
  updateSelectedNav: (navId) => dispatch.dashboard.updateSelectedNav(navId),
});

const DataLabContainer = connect(mapState, mapDispatch)(DataLab);

export default DataLabContainer;
