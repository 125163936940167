import React, {useEffect} from 'react';
import {Button} from "evergreen-ui";
import copyIcon from './../../../assets/images/copy.svg';
import checkIcon from './../../../assets/images/check.svg';
import {copyClipboard} from "../../../utils";

interface Prop {
  height?: number;
  textToCopy?: any;
}

const CopyText: React.FC<Prop> = (
  {height = 26,
    textToCopy=""
  }) => {

  const [timeLeft, setTimeLeft] = React.useState<any>(null);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null)
    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);


  const handleCopy = () => {
    // alert(textToCopy);
    copyClipboard(textToCopy);
    setTimeLeft(5);
  }
  return (
    <>
      <Button
        appearance='minimal'
        disabled={timeLeft > 0}
        height={height}
        style={{padding: `${0.2 * height}px ${0.26 * height}px`}}
        onClick={()=>handleCopy()}
      >
        <img src={timeLeft > 0 ? checkIcon : copyIcon} width={height / 1.5} alt='copy'/>
      </Button>
    </>
  );
};

export default CopyText;
