import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const SignUpConfirmation = ({ linkInfo, link, linkText }) => {
  return (
    <Fragment>
      <p className='reminder-info text-center w-300 pt-20'>
        {linkInfo}&nbsp;
        <a href={link} target='_blank' rel='noopener noreferrer'>
          {linkText}
        </a>
      </p>
    </Fragment>
  );
};
SignUpConfirmation.propTypes = {
  linkInfo: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
};
export default SignUpConfirmation;
