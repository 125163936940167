import React from 'react';
import {IconButton, Button, Popover, Menu, Position} from "evergreen-ui";
import MarketPlaceTag from "./MarketPlaceTag";


interface Prop {
  icon: string;
  title: string;
  description: string;
  handleInstall?: () => void;
  status?: 'installed' | 'coming' | 'installable' | 'pending' | 'pendingPlugin';
  handleDisconnect?: () => void;
  handlePluginShow?: () => void;
  hasPluginConnected?: boolean;
  handlePluginDisconnect?: ()=> void;
}

const MarketPlaceCard: React.FC<Prop> = (
  {
    icon,
    title,
    description,
    status,
    handleInstall,
    handleDisconnect,
    handlePluginShow,
    hasPluginConnected,
    handlePluginDisconnect,
  }) => {

  return (
    <div className='marketplace-card'>
      <div className='flex flex-end'>
        {(status === 'installed' || status === "pending" || status === 'pendingPlugin') &&
        <Popover
          position={Position.BOTTOM_LEFT}
          content={
            <Menu>
              <Menu.Group>
                {!hasPluginConnected && <Menu.Item
                  icon='cloud-download'
                  onSelect={() => !!handlePluginShow && handlePluginShow()}>Get Plugin</Menu.Item>
                }
                {hasPluginConnected && <Menu.Item
                  icon='chat'
                  intent='danger'
                  onSelect={() => !!handlePluginDisconnect && handlePluginDisconnect()}>Disconnect Plugin</Menu.Item>
                }
                <Menu.Item
                  icon='link'
                  intent='danger'
                  onSelect={() => !!handleDisconnect && handleDisconnect()}>Disconnect</Menu.Item>
              </Menu.Group>
            </Menu>
          }
        >
          <IconButton icon='more' appearance='minimal'/>
        </Popover>
        }
      </div>
      <img className={`${(status !== 'installed' && status !== "pending" && status !== 'pendingPlugin') && 'mt-30px'}`} src={icon}
           alt='icon form card' width={70}/>
      <p className='marketplace-card__title'>{title}</p>
      <p className='marketplace-card__subtitle'>{description}</p>
      <br/>
      {status === 'coming' ?
        <MarketPlaceTag text='Coming Soon!' intent='info'/>
        : status === 'installed' ?
          <MarketPlaceTag text='Successfully Installed!' intent='success'/>
          : status === 'pending' ? <MarketPlaceTag text='Installation Pending!' intent='default'/>
            : status === 'pendingPlugin' ? <MarketPlaceTag text='Plugin is not installed!' intent='default'/>
              : status === 'installable' ? <Button className='alice-btn__secondary'
                                                 onClick={() => !!handleInstall && handleInstall()}>Install</Button> : ''
      }
    </div>
  );
};

MarketPlaceCard.defaultProps = {
  status: "coming",
}

export default MarketPlaceCard;
