import React from 'react';
import OrderCardProductElement from "./OrderCardProductElement";
import noDataFound from "../../../assets/images/no-data-found.svg";

interface Prop {
  products: any[];
}

const OrderCardProduct: React.FC<Prop> = ({products}) => {

  return (
    <div className='order-right-bar__order-card__product'>
      {products.length === 0 &&
      <div className='flex justify-center align-center h-100p flex-column'>
        <img src={noDataFound} alt='no data' width='80px'/>
        <p className='description'>No Products found!</p>
      </div>
      }
      {products.length > 0 && products.map((product:any,index:number)=>
        <OrderCardProductElement
          key={index}
          {...product}
          metaData={product.meta_data}
        />
      )}
    </div>
  );
};

export default OrderCardProduct;
