import React from 'react';
import {Button, Dialog, toaster, Tooltip} from "evergreen-ui";
import wooCommerceConnect from '../../../assets/icon/marketplace/woocommerce-connect.svg';
import wooCommerceConnected from '../../../assets/icon/marketplace/woocommerce-connected.svg';
import cross from '../../../assets/icon/marketplace/cross.svg';
import WooCommerceConnectCustomer from "./WooCommerceConnectCustomer";
import Modal from "../../../../../components/elements/redesign/Modal";

interface Prop {
  fetchEcommerceCustomerList: (search:string) => void;
  marketPlaceData: any;
  ecommerceCollectionReference: any;
  fetchEcommerceCustomerListLoader: boolean;
  customerList: any[];
  currentTicket: any;
  linkCustomerWithCRM: (ecommerceId:number) => Promise<boolean>;
  unlinkCustomerWithCRM: () => Promise<boolean>;
  linkLoader: boolean;
}

const RightBarEComActionBar: React.FC<Prop> = (
  {
    fetchEcommerceCustomerList,
    marketPlaceData,
    ecommerceCollectionReference,
    fetchEcommerceCustomerListLoader,
    customerList,
    currentTicket,
    linkCustomerWithCRM,
    unlinkCustomerWithCRM,
    linkLoader,
  }) => {
  const [action, setAction] = React.useState('');
  const [showDisconnectWoocommerceModal,setShowDisconnectWoocommerceModal] =  React.useState(false);
  const hasWooCommerceConnection = !!marketPlaceData && marketPlaceData?.ecommerce_type === 'woocommerce';

  return (
    <div className='customer-info__ecom-actionbar'>
      {hasWooCommerceConnection && !ecommerceCollectionReference &&
      <Tooltip content="Connect to WooCommerce">
        <Button
          height={48}
          appearance='minimal'
          onClick={() => {
            setAction('wooCommerceConnect');
            fetchEcommerceCustomerList('');
          }}
        >
          <img src={wooCommerceConnect} alt='connect'/>
        </Button>
      </Tooltip>
      }
      {hasWooCommerceConnection && !!ecommerceCollectionReference &&
      <>
        <Tooltip content="Connect to WooCommerce">
          <Button
            height={48}
            appearance='minimal' disabled>
            <img src={wooCommerceConnected} alt='connected'/>
          </Button>
        </Tooltip>
        <Tooltip content="Disconnect WooCommerce">
          <Button
            height={48}
            appearance='minimal'
            onClick={()=>setShowDisconnectWoocommerceModal(true)}
          >
            <img src={cross} alt='disconnect'/>
          </Button>
        </Tooltip>
      </>
      }

      <Dialog
        isShown={!!action}
        width={500}
        hasFooter={false}
        hasHeader={false}
        onCloseComplete={() => setAction('')}
      >
        {action === 'wooCommerceConnect' &&
        <WooCommerceConnectCustomer
          handleClose={() => setAction('')}
          fetchEcommerceCustomerList={fetchEcommerceCustomerList}
          fetchEcommerceCustomerListLoader={fetchEcommerceCustomerListLoader}
          customerList={customerList}
          currentTicket={currentTicket}
          linkCustomerWithCRM={async (ecommerceId:number)=>{
            let res = await linkCustomerWithCRM(ecommerceId);
            if(res) {
              setAction('');
              toaster.success('Connected!');
            }
          }}
          linkLoader={linkLoader}
        />}
      </Dialog>
      <Modal
        isShown={showDisconnectWoocommerceModal}
        setShown={setShowDisconnectWoocommerceModal}
        deleteTitle='Yes, disconnect!'
        onDeleteCallback={async ()=>{
         let res =  await unlinkCustomerWithCRM();
         if(res) {
           toaster.success('Disconnected!');
         }
        }}
        title='Disconnect WooCommerce Customer'
        intent='warning'
        description="You have requested to disconnect your WooCommerce customer's account from Alice. Are you sure you want to disconnect the link?" />
    </div>
  );
};

export default RightBarEComActionBar;
