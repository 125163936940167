import React from 'react';

interface props {
    fill: string;
}

const TicketIcon: React.FC<props> = ({fill}) => {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 25 25'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M12.5 0.833336L12.7481 0.399214L12.5 0.25746L12.2519 0.399214L12.5 0.833336ZM0.833336 7.5L0.585266 7.06588L0.333336 7.20984V7.5H0.833336ZM24.1667 7.5H24.6667V7.20984L24.4147 7.06588L24.1667 7.5ZM12.5 24.1667L12.3626 24.6474L12.5 24.6867L12.6374 24.6474L12.5 24.1667ZM11.6667 16.6667L11.3466 17.0508L11.7384 17.3773L12.0571 16.979L11.6667 16.6667ZM12.2519 0.399214L0.585266 7.06588L1.08141 7.93412L12.7481 1.26746L12.2519 0.399214ZM0.333336 7.5V8.69982H1.33334V7.5H0.333336ZM24.6667 8.69982V7.5H23.6667V8.69982H24.6667ZM24.4147 7.06588L12.7481 0.399214L12.2519 1.26746L23.9186 7.93412L24.4147 7.06588ZM12.6374 24.6474C19.7577 22.6131 24.6667 16.105 24.6667 8.69982H23.6667C23.6667 15.6586 19.0536 21.7742 12.3626 23.6859L12.6374 24.6474ZM0.333336 8.69982C0.333336 16.105 5.24235 22.6131 12.3626 24.6474L12.6374 23.6859C5.94637 21.7742 1.33334 15.6586 1.33334 8.69982H0.333336ZM6.34658 12.8841L11.3466 17.0508L11.9868 16.2826L6.98676 12.1159L6.34658 12.8841ZM12.0571 16.979L18.7238 8.64568L17.9429 8.02099L11.2762 16.3543L12.0571 16.979Z'
                fill={fill}
            />
        </svg>)
};
export default TicketIcon;
