/**
 * Created by shuvo on 31/3/21.
 */
import { versionNumber } from '../config';
import amplitude from 'amplitude-js';

export const initAmplitude = () => {
    let amplitudeClient = amplitude.getInstance();
    amplitudeClient.init(process.env.REACT_APP_AMPLITUDE);
    amplitudeClient.setVersionName(versionNumber);
};

export const setAmplitudeUserGroup = userGroup => {
    amplitude.getInstance().setGroup('teamId', userGroup);
};

export const setAmplitudeUserId = userId => {
    amplitude.getInstance().setUserId(userId);
};

export const setAmplitudeUserProperties = properties => {
    amplitude.getInstance().setUserProperties(properties);
};

export const sendAmplitudeData = (eventType, eventProperties) => {
    amplitude.getInstance().logEvent(eventType, eventProperties);
};
