import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SelectMenu, Button } from 'evergreen-ui';
import APIContentContainer from './APIContentContainer';

const APIReturnType = ({
  title,
  subtitle,
  returnType,
  data,
  updateSelectedAPIkey,
  hasWriteAccess,
}) => {
  const [options] = useState([
    { label: 'Text Block', value: 'text' },
    { label: 'Gallery Block', value: 'gallery' },
    { label: 'Quick Reply Block', value: 'quick_reply' },
    { label: 'Button Block', value: 'button' },
    { label: 'Image', value: 'image' },
    { label: 'Audio', value: 'audio' },
    { label: 'Video', value: 'video' },
    { label: 'None', value: '' },
  ]);

  return (
    <APIContentContainer title={title} subtitle={subtitle}>
      <div className={'mt-15px'} />
      <SelectMenu
        height={165}
        width={200}
        hasTitle={false}
        hasFilter={false}
        options={options}
        selected={data}
        onSelect={(item) => updateSelectedAPIkey('return_type', item.value)}
      >
        <Button height={36} disabled={!hasWriteAccess}>
          {options.filter((elem) => elem.value === data)[0].label ||
            'Select Data Type'}
        </Button>
      </SelectMenu>
    </APIContentContainer>
  );
};

APIReturnType.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  data: PropTypes.string,
  updateSelectedAPIkey: PropTypes.func,
  hasWriteAccess: PropTypes.bool,
};

APIReturnType.defaultProps = {
  title: 'Default',
  subtitle: 'provide a long valid description what it do',
  hasWriteAccess: false,
};
export default APIReturnType;
