import { init } from '@rematch/core';
import createRematchPersist from '@rematch/persist';
import createLoadingPlugin from '@rematch/loading';
import createEncryptor from 'redux-persist-transform-encrypt';
import { auth } from './pages/auth/models';
import { dashboard } from './pages/dashboard/models';
import { builder, block } from './pages/builder/models';
import { api } from './pages/api-fuse/models';
import { rules } from './pages/rules/models';
import { dataLab } from './pages/data-lab/models';
import { labDetails } from './pages/data-lab-details/models';
import { settings } from './pages/settings/models';
import { billing } from './pages/settings/models/billing';
import { channel } from './pages/settings/models/channel';
import { audience } from './pages/audience/models';
import { crm } from './pages/crm/models';
import { reporting } from './pages/reporting/models';
import { marketplace } from './pages/settings/models/marketplace';

const encryptor = createEncryptor({
  secretKey: process.env.REACT_APP_SECRET_KEY,
  onError: function(error) {
    console.log(error);
  },
});

const loading = createLoadingPlugin({});

const persistPlugin = createRematchPersist({
  key: 'alice',
  whitelist: [
    'auth',
    'dashboard',
    'builder',
    'block',
    'api',
    'rules',
    'dataLab',
    'labDetails',
    'settings',
    'billing',
    'channel',
    'audience',
    'crm',
    'reporting',
    'marketplace',
  ],
  throttle: 100,
  version: 1,
  transforms: [encryptor],
});

const models = {
  auth,
  dashboard,
  builder,
  block,
  api,
  rules,
  dataLab,
  labDetails,
  settings,
  billing,
  channel,
  audience,
  crm,
  reporting,
  marketplace,
};

const store = init({
  models,
  plugins: [persistPlugin, loading],
});

export default store;
