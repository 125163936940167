import React, { useState } from 'react';
import { SideSheet, Paragraph } from 'evergreen-ui';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import StartTrialFlow from './StartTrialFlow';
import CrossClockIcon from '../assets/images/cross-clock.svg';

interface Prop {
  trialRemaining: number;
  trialAvailed: boolean;
}

const TrialRemainingCard: React.FC<Prop> = ({
  trialRemaining,
  trialAvailed,
}) => {
  const [isShown, setShown] = useState(false);
  const isExpired = trialAvailed && trialRemaining === 0;

  return (
    <div className='alice-trial-card flex'>
      <Paragraph className='flex-8 mr-10px'>
        {isExpired
          ? 'Your trial has been expired!'
          : `You have ${trialRemaining} days of trial remaining!`}
        <br />
        Upgrade to avoid losing useful features.{' '}
        <a
          href='/'
          onClick={(e) => {
            e.preventDefault();
            setShown(true);
          }}
        >
          See details here
        </a>
      </Paragraph>

      {isExpired ? (
        <img src={CrossClockIcon} alt='trial expired' width={80} />
      ) : (
        <CircularProgressbar
          className='flex-2'
          value={trialRemaining}
          maxValue={14}
          strokeWidth={10}
          text={`${trialRemaining}`}
          styles={buildStyles({
            textSize: '35px',
            pathColor: '#DE350B',
            textColor: '#DE350B',
            trailColor: '#EFEFFF',
          })}
        />
      )}

      <SideSheet
        isShown={isShown}
        width={480}
        onCloseComplete={() => {
          setShown(false);
        }}
        preventBodyScrolling
      >
        <StartTrialFlow />
      </SideSheet>
    </div>
  );
};

export default TrialRemainingCard;
