import React, { useEffect } from 'react';
import { Button } from 'evergreen-ui';
import AuthFormHeader from './AuthFormHeader';
import AuthFooter from './AuthFooter';
import { authData } from '../../../content';
import emailIcon from '../assets/images/logo/email-icon.svg';
import timeoutIcon from '../assets/images/logo/timeout-icon.svg';
import PropTypes from 'prop-types';

const ResendVerificationMail = ({ isTimeout, email, handleSubmit }) => {
  const [timeLeft, setTimeLeft] = React.useState(60);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return (
    <div className='form-container'>
      <div className='form-container__content'>
        <div className='t-center'>
          {isTimeout ? (
            <img className='padlockIcon' src={timeoutIcon} alt='alice logo' />
          ) : (
            <img className='padlockIcon' src={emailIcon} alt='alice logo' />
          )}
        </div>
        {isTimeout ? (
          <AuthFormHeader
            title={authData.resendRegisterLink.timeoutTitle}
            subtitle={authData.resendRegisterLink.timeoutTitle}
          />
        ) : (
          <AuthFormHeader
            title='Verify Your Email'
            subtitle={
              'We have sent an email to ' +
              email +
              '. Please click the link to activate your account.'
            }
          />
        )}
        <br />
        <Button
          disabled={timeLeft > 0 && !isTimeout}
          onClick={() => {
            handleSubmit();
            setTimeLeft(60);
          }}
          className='auth-login-button'
          height={40}
        >
          {authData.register.resendButtonTitle}
        </Button>
        {isTimeout ? (
          ''
        ) : timeLeft > 0 ? (
          <p className='timeNotification'>
            Link is valid for {timeLeft > 0 ? `00:${timeLeft}` : 0} sec
          </p>
        ) : (
          <p className='timeNotification'>
            Please click Resend Link button to generate link again
          </p>
        )}
        <br />
        <br />
        <AuthFooter />
      </div>
    </div>
  );
};
ResendVerificationMail.propTypes = {
  isTimeout: PropTypes.bool,
  email: PropTypes.string,
  handleSubmit: PropTypes.func,
};
export default ResendVerificationMail;
