import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'evergreen-ui';
import { Link } from '@reach/router';
import MaterialInput from '../../../components/elements/MaterialInput';
import AuthFormHeader from './AuthFormHeader';
import AuthFooter from './AuthFooter';
import Alert from '../../../components/elements/redesign/Alert';

const LoginFormSection = ({
  loginFailed,
  email,
  password,
  content,
  handleChange,
  handleSubmit,
  loading,
}) => {
  return (
    <div className='form-container'>
      <div className='form-container__content'>
        <AuthFormHeader
          title={content.login.title}
          subtitle={content.login.subtitle}
        />
        <br />
        <MaterialInput
          label='Email Address'
          name='email'
          value={email}
          onKeyPress={(e) => (e.key === 'Enter' ? handleSubmit() : null)}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
        <br />
        <br />
        <div className='h-75px'>
          <MaterialInput
            label='Password'
            type='password'
            name='password'
            value={password}
            onKeyPress={(e) => (e.key === 'Enter' ? handleSubmit() : null)}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
          {loginFailed ? (
            <Alert intent='danger' text='Wrong Email or Password. Try again!' />
          ) : (
            ''
          )}
        </div>
        <Button
          isLoading={loading}
          onClick={() => handleSubmit()}
          className='auth-login-button .mt-5px'
          height={40}
        >
          {content.login.loginButtonTitle}
        </Button>

        <br />
        <br />
        <p className='additional-info text-center'>
          {content.forgetInfo.text} &nbsp;
          <Link to={content.forgetInfo.link}>
            {content.forgetInfo.linkTitle}
          </Link>
        </p>
        <AuthFooter />
      </div>
    </div>
  );
};
LoginFormSection.propTypes = {
  email: PropTypes.string,
  password: PropTypes.string,
  content: PropTypes.any,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  loginLoading: PropTypes.bool,
};
export default LoginFormSection;
