import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'evergreen-ui';
import BlockContainer from './BlockContainer';
import storyIcon from './../../pages/builder/assets/images/icons/story.svg';
import StoryBlockQuestionSegment from '../elements/StoryBlockQuestionSegment';
import InputLabel from '../elements/InputLabel';
import DropdownCombobox from '../elements/DropdownCombobox';
import { generateId } from '../../utils';

const StoryBlock = ({
  id,
  data,
  save,
  saveBlock,
  attributeList,
  apiList,
  deleteBlock,
  sequences,
  copyBlock,
  moveBlock,
  saveLoader,
  updateInfo,
  hasWriteAccess,
  entities,
  selectedSequence,
}) => {
  const handleStorySegmentDelete = (storyId) => {
    const newList = data.questions.filter(
      (question) => question.id !== storyId
    );
    updateInfo(newList, 'questions');
  };

  const handleStorySegmentEdit = (storyId, dataLocal, key) => {
    const updatedQuestionsList = data.questions.map((question) => {
      if (question.id === storyId) {
        question[key] = dataLocal;
      }
      return question;
    });
    updateInfo(updatedQuestionsList, 'questions');
  };
  const handleStorySegmentAdd = () => {
    const id =
      data.questions.length === 0
        ? 1
        : data.questions[data.questions.length - 1].id + 1;
    const updatedQuestionsList = [
      ...data.questions,
      {
        id: id,
        main_text: '',
        fail_text: '',
        primary_entity: '',
        role: '',
        is_optional: false,
        attribute: `${selectedSequence}-${id}-${generateId(6)}`,
      },
    ];
    updateInfo(updatedQuestionsList, 'questions');
  };

  return (
    <BlockContainer
      id={id}
      title='Story'
      subtitle='Story Block Allows you to Create a Conversation'
      save={save}
      isLoading={saveLoader}
      icon={storyIcon}
      handleSaveClick={() => saveBlock(id)}
      handleDelete={() => deleteBlock(id)}
      sequences={sequences}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <div>
        <div className='flex'>
          <div className='flex-1 mr-5px'>
            <InputLabel
              value={data.error_counter}
              handleChange={(value) =>
                updateInfo(!!value ? value : 0, 'error_counter')
              }
              title='Error Counter'
              tooltip='Max wrong input bot can allow'
              type='number'
            />
          </div>
          <div className='flex-1 ml-5px'>
            <DropdownCombobox
              title='Validation Failed Sequence'
              tooltip='Which Sequence Bot Should Redirect after max Count of validation finished'
              value={data.fail_sequence}
              options={sequences}
              handleChange={(value) => {
                updateInfo(value, 'fail_sequence');
                updateInfo(value.id, 'fail_sequence_id');
              }}
            />
          </div>
        </div>
        <br />
        <DropdownCombobox
          value={data.validation_api}
          title='Validation API'
          tooltip='API That It Will Validate'
          options={apiList}
          handleChange={(value) => {
            updateInfo(value, 'validation_api');
            updateInfo(value.id, 'validation_api_id');
          }}
        />
        <br />
        {!!data.questions &&
          data.questions.map((ques, i) => (
            <StoryBlockQuestionSegment
              key={i}
              length={data.questions.length}
              question={ques}
              entities={entities}
              hasWriteAccess={hasWriteAccess}
              attributeList={attributeList}
              handleStorySegmentEdit={handleStorySegmentEdit}
              onDelete={(questionParams) => {
                handleStorySegmentDelete(questionParams.id);
              }}
            />
          ))}
        <Button
          className='button-alice__gradient'
          onClick={() => handleStorySegmentAdd()}
        >
          Add A New Segment
        </Button>
      </div>
    </BlockContainer>
  );
};

StoryBlock.propTypes = {
  id: PropTypes.number.isRequired,
  save: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  saveBlock: PropTypes.func.isRequired,
  attributeList: PropTypes.arrayOf(PropTypes.string),
  apiList: PropTypes.arrayOf(PropTypes.object),
  deleteBlock: PropTypes.func.isRequired,
  moveBlock: PropTypes.func.isRequired,
  copyBlock: PropTypes.func.isRequired,
  saveLoader: PropTypes.bool.isRequired,
  hasWriteAccess: PropTypes.bool.isRequired,
};

StoryBlock.defaultState = {
  deleteBlock: (blockId, SequenceId) =>
    console.log('delete from default props', blockId, SequenceId),
};

export default StoryBlock;
