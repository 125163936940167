import React, { Component } from 'react';
import { SketchPicker } from 'react-color';
import PropTypes from 'prop-types';

class ColorPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayColorPicker: false,
    };
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  render() {
    const styles = {
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: `${this.props.color}`,
      },
      popover: {
        position: 'absolute',
        left: '-63px',
        top: '30px',
        zIndex: '2',
        borderRadius: 8,
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    };

    return (
      <div>
        <div className='color-picker-container'>
          <div style={styles.color} onClick={this.handleClick} />
          {this.state.displayColorPicker ? (
            <div style={styles.popover}>
              <div style={styles.cover} onClick={this.handleClick} />
              <SketchPicker
                color={this.props.color}
                onChange={this.props.handleChange}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

ColorPicker.propTypes = {
  color: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

ColorPicker.defaultProps = {
  color: '#007b65',
};
export default ColorPicker;
