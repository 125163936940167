import React from 'react';
import {isValidUrl} from "../../../../../utils";
import WooCommerceHeader from "./WooCommerceHeader";
import WooCommerceModalStore from "./WooCommerceModalStore";

interface Prop {
  handleClose: () => void;
  initializeMarketPlaceData: (storeURL: string) => void;
}

const WooCommerceModalContainer: React.FC<Prop> = ({handleClose, initializeMarketPlaceData}) => {

  const [storeInvalid, setStoreInvalid] = React.useState('');
  const [storeURL,setStoreURL] = React.useState('');

  const handleStoreChange  = async () => {
    if(!storeURL) {
      setStoreInvalid('Store URL Cannot Be Empty');
    }else if(!isValidUrl(storeURL)) {
      setStoreInvalid('Store Is not a valid URL');
    }else {
      initializeMarketPlaceData(storeURL);
    }
  };

  return (
    <div className='woocommerce-modal'>
      <WooCommerceHeader handleClose={handleClose} />
        <WooCommerceModalStore storeInvalid={storeInvalid} handleStoreChange={handleStoreChange} storeURL={storeURL} setStoreURL={setStoreURL} />
    </div>
  );
};

export default WooCommerceModalContainer;
