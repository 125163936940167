import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import { Position, Popover, Menu, Button, Avatar, Icon } from 'evergreen-ui';
import { connect } from 'react-redux';
import { sendAmplitudeData } from './../utilities/amplitude';


const Profile = ({
  selectedProject,
  userName,
  logout,
  avatar,
  userEmail,
  teams,
  setSelectedProject,
  fetchDashboardActivity,
  fetchPlatformList,
}) => {
  return (
    <div className='profile-button'>
      <Popover
        position={Position.BOTTOM_LEFT}
        content={({ close }) => (
          <div style={{ overflow: 'auto', maxHeight: '80vh' }}>
            <p className='profile-popover__header'>
              Switch Teams &nbsp;
              <Icon icon='refresh' size={16} />{' '}
            </p>
            <Menu>
              <Menu.Group>
                {teams.length > 0 &&
                  teams.map((team, i) => (
                    <Menu.Item
                      onSelect={() => {
                        setSelectedProject(team);
                        fetchDashboardActivity(team.id);
                        fetchPlatformList(team.id);
                        sendAmplitudeData('Changed Team', {'teamId': team.id});
                        navigate('/dashboard');
                        close();
                      }}
                      key={i}
                    >
                      <div className='flex space-between align-center ml-20px'>
                        <div className='mr-15px'>{team.name}</div>{' '}
                        <div>
                          {selectedProject.id === team.id && (
                            <Icon
                              icon={'tick-circle'}
                              color={'success'}
                              size={16}
                            />
                          )}
                        </div>
                      </div>
                    </Menu.Item>
                  ))}
                <a
                  className='no-underline'
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://docs.getalice.ai/'
                >
                  <Menu.Item icon='book'>Documentation</Menu.Item>
                </a>
                <Menu.Item
                  icon={'user'}
                  intent='danger'
                  onSelect={() => {
                    logout();
                  }}
                >
                  <div className='flex align-center'>
                    Sign Out &nbsp;
                    <Icon icon={'log-out'} size={16} />
                  </div>
                </Menu.Item>
              </Menu.Group>
            </Menu>
          </div>
        )}
      >
        <Button className='button_class nav-avatar-container'>
          <Avatar src={avatar} name={userEmail} size={35} />
          <Avatar
            isSolid
            color={'green'}
            name={selectedProject?.name || 'Unknown'}
            size={20}
            className={'dashboard-team'}
          />
        </Button>
      </Popover>
    </div>
  );
};

Profile.propTypes = {
  title: PropTypes.string,
};

const mapState = (state) => ({
  userName: state.auth.full_name,
  userEmail: state.auth.email,
  avatar: state.auth.avatar,
  selectedProject: state.dashboard.selectedProject,
  projects: state.dashboard.projects,
  loading: state.loading.effects.dashboard.fetchProject,
  sequences: state.builder.sequences,
  selectedSequence: state.builder.selectedSequence,
  teams: state.dashboard.projects,
});

const mapDispatch = (dispatch) => ({
  fetchProject: () => dispatch.dashboard.fetchProject(),
  setSelectedProject: (project) =>
    dispatch.dashboard.setSelectedProject(project),
  setSelectedProjectSettings: (project) =>
    dispatch.settings.setSelectedProject(project),
  updateSelectedNav: (navId) => dispatch.dashboard.updateSelectedNav(navId),
  fetchProjectAccess: (projectId) =>
    dispatch.settings.fetchProjectAccess(projectId),
  fetchPlatformList: (projectId) =>
    dispatch.dashboard.fetchPlatformList(projectId),
  fetchDashboardActivity: (projectId) =>
    dispatch.dashboard.fetchDashboardActivity(projectId),
  logout: () => dispatch.auth.logout(),
});

const ProfileContainer = connect(mapState, mapDispatch)(Profile);

export default ProfileContainer;
