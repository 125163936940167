import React, { useEffect } from 'react';
import {
  Position,
  Popover,
  Menu,
  IconButton,
  Icon,
  TextInputField,
  SelectMenu,
  Button,
  TagInput,
  Checkbox,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import TextCharInfo from '../../../components/elements/TextCharInfo';

const MaxKeyCharLength = 50;

const DataLabFieldCard = ({
  id,
  labId,
  name,
  type,
  placeholder,
  required,
  hidden,
  minLength,
  maxLength,
  checkIsEditable,
  savedStatus,
  options,
  handleCreate,
  isSaveLoading,
  updateEditKeyId,
  updateKeyChange,
  handleCancel,
  handleDelete,
  updateDataLabField,
}) => {
  const TypeOptions = [
    { label: 'Text', value: 'text' },
    { label: 'Text Area', value: 'textarea' },
    { label: 'Number', value: 'number' },
    { label: 'Image', value: 'image' },
    { label: 'Date', value: 'date' },
    { label: 'Datetime', value: 'datetime' },
    { label: 'Time', value: 'time' },
    { label: 'Multi Select', value: 'multiselect' },
    { label: 'Single Select', value: 'singleselect' },
  ];
  useEffect(() => {
    if (id === -1) {
      document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    }
  }, [id]);

  const fetchLabel = (value) => {
    const filteredOption = TypeOptions.filter((elem) => elem.value === value);
    if (filteredOption.length > 0) {
      return filteredOption[0].label;
    }
    return value;
  };

  return (
    <div className='data-lab__fieldcard' id={id}>
      <div className='data-lab__fieldcard-elem'>
        <div className='data-lab__fieldcard-elem-item flex-2'>
          <TextInputField
            disabled={!checkIsEditable()}
            label='Name of Key'
            placeholder='Provide Name'
            value={name}
            onChange={(e) => {
              if (e.target.value.length <= MaxKeyCharLength) {
                updateKeyChange(id, 'name', e.target.value);
              }
            }}
          />
          <div className='data-lab__fieldcard-length'>
            <TextCharInfo data={name} count={MaxKeyCharLength} />
          </div>
        </div>
        <div className='data-lab__fieldcard-elem-item'>
          <p className='data-lab__fieldcard-elem-item-label'>Select Type</p>
          <SelectMenu
            hasFilter={false}
            closeOnSelect={true}
            title='Select Key Type'
            options={TypeOptions}
            selected={type}
            onSelect={(item) => {
              updateKeyChange(id, 'type', item.value);
              if (
                item.value !== 'multiselect' ||
                item.value !== 'singleselect'
              ) {
                updateKeyChange(id, 'options', []);
              }
            }}
          >
            <Button
              disabled={!checkIsEditable()}
              className='data-lab__fieldcard-elem-item-button'
            >
              {fetchLabel(type) || 'Select name...'}&nbsp;&nbsp;
              <Icon icon='chevron-down' size={16} />
            </Button>
          </SelectMenu>
        </div>
      </div>
      <div className='data-lab__fieldcard-elem'>
        {(type === 'multiselect' || type === 'singleselect') && (
          <div className='data-lab__fieldcard-elem-item mb-20px'>
            <p className='data-lab__fieldcard-elem-item-label'>Add Options</p>
            <TagInput
              disabled={!checkIsEditable()}
              tagProps={{
                color: 'green',
                isSolid: true,
              }}
              width='100%'
              className='data-lab__fieldcard-elem-item-tagInput'
              inputProps={{ placeholder: 'Add items...' }}
              values={options}
              onChange={(values) => {
                updateKeyChange(id, 'options', values);
              }}
            />
          </div>
        )}
        <div className='data-lab__fieldcard-elem-item'>
          <TextInputField
            value={placeholder}
            disabled={!checkIsEditable()}
            label='Placeholder Text'
            placeholder='Placeholder text'
            onChange={(e) => updateKeyChange(id, 'placeholder', e.target.value)}
          />
        </div>
      </div>
      {(type === 'text' || type === 'number' || type === 'textarea') && (
        <div className='data-lab__fieldcard-elem'>
          <div className='data-lab__fieldcard-elem-item flex-2'>
            <TextInputField
              disabled={!checkIsEditable()}
              type={'number'}
              label='Min Length'
              placeholder='Set Minimum Length(optional)'
              value={minLength}
              onChange={(e) =>
                updateKeyChange(id, 'min_length', parseInt(e.target.value))
              }
            />
          </div>
          <div className='data-lab__fieldcard-elem-item flex-2'>
            <TextInputField
              disabled={!checkIsEditable()}
              type={'number'}
              label='Max Length'
              placeholder='Set Maximum Length(optional)'
              value={maxLength}
              onChange={(e) =>
                updateKeyChange(id, 'max_length', parseInt(e.target.value))
              }
            />
          </div>
        </div>
      )}
      <div className='data-lab__fieldcard-elem'>
        <div className='data-lab__fieldcard-elem-item mt--10px'>
          <Checkbox
            className='data-lab__fieldcard-elem-item-checkbox'
            label='Is This Field Required'
            checked={required}
            disabled={!checkIsEditable()}
            onChange={(e) =>
              updateKeyChange(id, 'is_required', e.target.checked)
            }
          />
        </div>
        <div className='data-lab__fieldcard-elem-item mt--10px'>
          <Checkbox
            className='data-lab__fieldcard-elem-item-checkbox'
            label='Is This Field Is Hidden?'
            checked={hidden}
            disabled={!checkIsEditable()}
            onChange={(e) => updateKeyChange(id, 'is_hidden', e.target.checked)}
          />
        </div>
      </div>
      {checkIsEditable() && (
        <div className='data-lab__fieldcard-elem action'>
          <Button
            onClick={() => {
              if (id === -1) {
                handleCreate(labId, {
                  name,
                  type,
                  placeholder,
                  is_required: required,
                  is_hidden: hidden,
                  min_length: minLength,
                  max_length: maxLength,
                  options,
                });
              } else {
                updateDataLabField(labId, id, {
                  name,
                  type,
                  placeholder,
                  is_required: required,
                  is_hidden: hidden,
                  min_length: minLength,
                  max_length: maxLength,
                  options,
                });
              }
            }}
            isLoading={isSaveLoading}
            className='button-alice__gradient'
            marginRight={10}
            iconBefore={!isSaveLoading && 'endorsed'}
          >
            {id === -1 ? 'Create' : 'Save'}
          </Button>

          <Button
            onClick={() => {
              handleCancel();
            }}
            className='button-alice__secondary'
            iconBefore={'cross'}
          >
            Cancel
          </Button>
        </div>
      )}
      <div>
        {savedStatus ? (
          <Icon
            className='saved-status'
            icon='endorsed'
            size={16}
            color='success'
          />
        ) : (
          <Icon
            className='saved-status'
            icon='error'
            size={16}
            color='danger'
          />
        )}
        <Popover
          position={Position.BOTTOM_RIGHT}
          content={({ close: popoverClose }) => (
            <Menu>
              <Menu.Group>
                <Menu.Item
                  onSelect={() => {
                    updateEditKeyId(id);
                    popoverClose();
                  }}
                  icon='edit'
                >
                  Edit
                </Menu.Item>
                <Menu.Item
                  onSelect={() => {
                    popoverClose();
                    handleDelete(labId, id);
                  }}
                  icon='trash'
                  intent='danger'
                >
                  Delete
                </Menu.Item>
              </Menu.Group>
            </Menu>
          )}
        >
          <IconButton
            appearance='minimal'
            className='menu-icon'
            icon='more'
            height={28}
          />
        </Popover>
      </div>
    </div>
  );
};

DataLabFieldCard.propTypes = {
  id: PropTypes.number,
  labId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  hidden: PropTypes.bool,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  checkIsEditable: PropTypes.func,
  savedStatus: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string),
  handleCreate: PropTypes.func,
  isSaveLoading: PropTypes.bool,
  updateEditKeyId: PropTypes.func,
  updateKeyChange: PropTypes.func,
  handleCancel: PropTypes.func,
  handleDelete: PropTypes.func,
  updateDataLabField: PropTypes.func,
};
export default DataLabFieldCard;
