import React, { useState } from 'react';
import BlockContainer from './BlockContainer';
import { Textarea } from 'evergreen-ui';
import PropTypes from 'prop-types';
import InputVariableField from '../elements/InputVariableField';
import AttributeAPIContainer from '../elements/AttributeAPIContainer';
import SelectCountryField from '../elements/SelectCountryField';
import ErrorCounterInput from '../elements/ErrorCounterInput';
import {
  updateOnChangeText,
  updateOnChangeAttribute,
  updateOnSelectAttribute,
} from './../../utils';
import phoneInputIcon from './../../pages/builder/assets/images/icons/phone.svg';
import FailTextInput from '../elements/FailTextInput';
import SequenceSelectInput from '../elements/SequenceSelectInput';
import TextCharInfo from '../elements/TextCharInfo';

const PhoneInputBlock = ({
  id,
  save,
  saveBlock,
  saveLoader,
  text,
  phoneLocale,
  attributeList,
  apiList,
  setText,
  variable,
  sequences,
  setVariable,
  deleteBlock,
  copyBlock,
  moveBlock,
  hasWriteAccess,
  errorCounter,
  updateBlockValue,
  failText,
  failedSequenceId,
  isViberPlatform,
}) => {
  const [showAttribute, setShowAttribute] = useState(false);
  const [showAttributeField, setShowAttributeField] = useState(false);
  const [showAPI, setShowAPI] = useState(false);
  const [maxCount] = useState(320);

  return (
    <BlockContainer
      id={id}
      title='Phone Variable Input'
      subtitle='Add A Phone Variable Block'
      save={save}
      isLoading={saveLoader}
      icon={phoneInputIcon}
      handleSaveClick={() => saveBlock(id)}
      handleDelete={() => deleteBlock(id)}
      sequences={sequences}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <React.Fragment>
        <AttributeAPIContainer
          attributeList={attributeList}
          showAttribute={showAttribute}
          setShowAttribute={setShowAttribute}
          apiList={apiList.filter((api) => api.return_type === 'text')}
          showAPI={showAPI}
          setShowAPI={setShowAPI}
          handleSelect={(value) =>
            updateOnSelectAttribute(
              value,
              showAttribute,
              showAPI,
              setShowAttribute,
              setShowAPI,
              setText,
              text,
              apiList
            )
          }
        >
          <Textarea
            required={true}
            grammarly={true}
            spellCheck={true}
            disabled={!hasWriteAccess}
            className='custom-input-style'
            value={text}
            onChange={(e) =>
              e.target.value.length <= maxCount &&
              updateOnChangeText(
                e.target.value,
                setShowAttribute,
                setShowAPI,
                setText
              )
            }
            placeholder='Your Text block for Phone Input'
          />
        </AttributeAPIContainer>
        <TextCharInfo data={text} count={maxCount} />
        {isViberPlatform && (
          <InputVariableField
            attributeList={attributeList}
            hasWriteAccess={hasWriteAccess}
            showAttribute={showAttributeField}
            setShowAttribute={setShowAttributeField}
            handleSelect={(value) =>
              updateOnChangeAttribute(value, setShowAttributeField, setVariable)
            }
            value={variable}
            onChange={(value) =>
              updateOnChangeAttribute(value, setShowAttributeField, setVariable)
            }
            placeHolder={'Stored variable name example: phone.element'}
          />
        )}
        {!isViberPlatform && (
          <div>
            <FailTextInput
              value={failText}
              handleChange={(value) => updateBlockValue(value, 'fail_text')}
            />
            <br />
            <br />
            <div className='flex'>
              <div className='flex-1 mr-5px'>
                <ErrorCounterInput
                  value={errorCounter}
                  handleChange={(value) =>
                    updateBlockValue(value, 'error_counter')
                  }
                />
              </div>
              <div className='flex-1 ml-5px'>
                <SequenceSelectInput
                  value={failedSequenceId}
                  options={sequences}
                  handleChange={(value) => {
                    updateBlockValue(value, 'fail_sequence');
                    updateBlockValue(value.id, 'fail_sequence_id');
                  }}
                />
              </div>
            </div>
            <br />
            <div className='flex'>
              <div className='flex-1 mr-5px'>
                <SelectCountryField
                  phoneLocale={phoneLocale}
                  handleChange={(value) =>
                    updateBlockValue(value, 'phone_locale')
                  }
                />
              </div>
              <div className='flex-1 ml-5px'>
                <InputVariableField
                  attributeList={attributeList}
                  hasWriteAccess={hasWriteAccess}
                  showAttribute={showAttributeField}
                  setShowAttribute={setShowAttributeField}
                  handleSelect={(value) =>
                    updateOnChangeAttribute(
                      value,
                      setShowAttributeField,
                      setVariable
                    )
                  }
                  value={variable}
                  onChange={(value) =>
                    updateOnChangeAttribute(
                      value,
                      setShowAttributeField,
                      setVariable
                    )
                  }
                  placeHolder={'Stored variable name example: phone.element'}
                />
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    </BlockContainer>
  );
};

PhoneInputBlock.propTypes = {
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  phoneLocale: PropTypes.array.isRequired,
  setText: PropTypes.func.isRequired,
  variable: PropTypes.string.isRequired,
  setVariable: PropTypes.func.isRequired,
  save: PropTypes.bool.isRequired,
  saveBlock: PropTypes.func.isRequired,
  attributeList: PropTypes.arrayOf(PropTypes.string),
  apiList: PropTypes.arrayOf(PropTypes.object),
  saveLoader: PropTypes.bool.isRequired,
  hasWriteAccess: PropTypes.bool,
  updateBlockValue: PropTypes.func.isRequired,
  errorCounter: PropTypes.number,
  failText: PropTypes.string,
  failedSequenceId: PropTypes.object,
  isViberPlatform: PropTypes.bool,
};

PhoneInputBlock.defaultProps = {
  setVariable: (e) => console.log(e),
  hasWriteAccess: false,
};

export default PhoneInputBlock;
