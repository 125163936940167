import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { toaster, Icon, Spinner, IconButton } from 'evergreen-ui';
import { useDropzone } from 'react-dropzone';

const FileUploadField = ({ file, handleDrop, handleClear }) => {
  const [loading, setLoading] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.pdf, .doc, .docx',
    onDrop: (files) => {
      setLoading(true);
      handleDrop(files[0]);
      setTimeout(() => setLoading(false), 1500);
    },
    onDropRejected: () => {
      toaster.danger('Failed', {
        description: 'File too large. Maximum file size is 7MB',
        duration: 1,
      });
    },
    multiple: false,
    maxSize: 7340032,
  });

  return (
    <section className='file-upload'>
      <div {...getRootProps()} className='file-upload__field'>
        {!file && !loading && (
          <Fragment>
            <input {...getInputProps()} />
            <Icon icon='document' size={24} />
            <p>Drag 'n' Drop an File</p>
          </Fragment>
        )}

        {loading && <Spinner />}

        {file && !loading && (
          <Fragment>
            <IconButton
              icon='cross'
              appearance='minimal'
              intent={'danger'}
              onClick={() => handleClear()}
            />
            <p>{file}</p>
          </Fragment>
        )}
      </div>
    </section>
  );
};

FileUploadField.propTypes = {
  file: PropTypes.string,
  handleDrop: PropTypes.func,
  handleClear: PropTypes.func,
};

export default FileUploadField;
