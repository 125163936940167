import React from 'react';
import {getPlatformInfoTitle, getUpdatedPlatformIconOutlined,allPlatformList} from "../../utils";
import {Position, Popover,Menu,Button, Icon} from "evergreen-ui";

interface props {
    channels: any[];
    currentChannel: any;
    isFullWidth: boolean;
    onChange: (channel:any) => void;
}

const ChannelDropdown: React.FC<props> = ({channels, currentChannel, isFullWidth, onChange}) => {
    return <div className='channel-dropdown__container'>
        <p className='channel-dropdown'>Channel</p>
        <div className='mt-5px'>
            <Popover
                position={Position.BOTTOM_RIGHT}
                minWidth={380}
                content={({ close }) => (
                        <Menu>
                        <Menu.Group>
                            {channels.map(
                                (channel: any, key: number) => (
                                    <Menu.Item
                                        key={key}
                                        onSelect={() => {
                                           onChange(channel);
                                           close();
                                        }}
                                    >
                                        <div className='flex align-center'>
                                            <img
                                                src={getUpdatedPlatformIconOutlined(
                                                    !!channel.type ? channel.type : 'all'
                                                )}
                                                alt={'lorem'}
                                                width={20}
                                            />
                                            &nbsp;&nbsp; {getPlatformInfoTitle(channel)}
                                        </div>
                                    </Menu.Item>
                                )
                            )}
                        </Menu.Group>
                    </Menu>
                )}
            >
                <Button
                    icon='more'
                    height={32}
                    className='custom-input-style'
                    style={isFullWidth ? {width: '100%'}: {}}
                >
                    <div className='flex align-center space-between w-100p'>
                        <div className='flex align-center'>
                            {
                                <img
                                    src={getUpdatedPlatformIconOutlined(
                                        !!currentChannel.type
                                            ? currentChannel.type
                                            : 'all'
                                    )}
                                    alt={'lorem'}
                                    width={currentChannel.type === 'all' ? 12 : 20}
                                />
                            }{' '}
                            &nbsp;&nbsp; {allPlatformList.filter((channel:any)=>channel.type === currentChannel.type)[0].title}{' '}
                            &nbsp;
                        </div>
                        <Icon icon={'caret-down'} size={16} />
                    </div>
                </Button>
            </Popover>
        </div>
    </div>
};
ChannelDropdown.defaultProps = {
    isFullWidth: false,
};

export default ChannelDropdown;
