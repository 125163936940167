import React from 'react';
import { Badge } from 'evergreen-ui';
import PropTypes from 'prop-types';

const TextCharInfo = ({ data, count }) => {
  return (
    <div className='text-info'>
      <Badge color={data.length > count ? 'red' : 'green'}>
        {count - data.length}
      </Badge>
      <span>Characters Left</span>
    </div>
  );
};

TextCharInfo.propTypes = {
  data: PropTypes.string,
  count: PropTypes.number,
};
TextCharInfo.defaultProps = {
  data: '',
  count: 0,
};

export default TextCharInfo;
