import React from 'react';
import { footerData } from '../../../content';

const AuthFooter = () => {
  return (
    <div className='footer-info'>
      <p className='flex align-center'>
        <span>&copy; {new Date().getFullYear()}</span> &nbsp;
        <span>{footerData.company}</span>&nbsp;
        <span>{footerData.text}</span>
      </p>
      <div className='footer-link'>
        <a
          href={footerData.privacy.link}
          rel='noopener noreferrer'
          target='_blank'
        >
          {footerData.privacy.title}
        </a>
        &nbsp; |&nbsp;
        <a href={footerData.tos.link} rel='noopener noreferrer' target='_blank'>
          {footerData.tos.title}
        </a>
      </div>
    </div>
  );
};
export default AuthFooter;
