import React from 'react';
import refreshIcon from "../../../assets/images/refresh.svg";
import {Button, IconButton, SearchInput, Menu, Icon, Avatar} from "evergreen-ui";
import TagRedesign from "../../../components/elements/redesign/TagRedesign";

interface Prop {
  closeSideSheet: () => void;
  currentTicket: any;
  agentGroup: any;
  agents: any;
  handleAssign: (adminId: number, type: string) => void;
  search: string;
  setSearch: (val: string) => void;
}

const AssignSideSheet: React.FC<Prop> = (
  {
    closeSideSheet,
    currentTicket,
    agentGroup,
    agents,
    handleAssign,
    search,
    setSearch
  }) => {

  return (
    <div className='assign-ticket__container'>
      <div className='assign-ticket__header'>
        <div className='assign-ticket__header-content'>
          <Button
            className='alice-btn__default'
            disabled={true}
            height={40}
            style={{paddingLeft: 10, paddingRight: 10}}>
            <img src={refreshIcon} width={20} alt={'filter-icon'}/>
          </Button>
          <div className='ml-10px mr-10px'>
            <p className='assign-ticket__header-content__title'>Assign Agent or Group</p>
            <p className='assign-ticket__header-content__subtitle'>You can assign only one agent or a group at a
              time</p>
          </div>
        </div>
        <IconButton
          appearance='minimal'
          icon='cross'
          height={40}
          onClick={() => closeSideSheet()}/>
      </div>
      <div className='assign-ticket__info'>
        <div className='assign-ticket__info-content'>
          <p className='assign-ticket__info-content-label'>Currently Assigned to:</p>
          {currentTicket.agents.map(
            (agent: any, i: number) =>
              <TagRedesign
                key={i}
                data={{value: agent.id, label: agent.full_name, avatar: agent.avatar || undefined}}
                size={12}
                isClosable={false}
                intent={"active"}
                hasAvatar={true}/>
          )
          }
          {currentTicket.groups.map(
            (group: any, i: number) =>
              <TagRedesign
                key={i}
                data={{value: group.id, label: group.name, avatar: group.image || undefined}}
                size={12}
                isClosable={false}
                intent={"active"}
                hasAvatar={true}/>
          )
          }
          {currentTicket.agents.length === 0 && currentTicket.groups.length === 0 &&
          <TagRedesign
            data={{
              value: null,
              label: "Unassigned",
            }}
            size={12}
            isClosable={false}
            intent={"active"}
            hasAvatar={false}
          />
          }

        </div>
        <div className='mt-15px'>
          <SearchInput
            className='alice-rd-search'
            height={40}
            value={search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
            placeholder='Search by agent name or group...'
            width={'100%'}
          />
        </div>
        <div className=' assign-ticket__list'>
          <p className='assign-ticket__list-title'>All Available Agents</p>
          <div className='assign-ticket__list__container'>
            <Menu.Group>
              {!!agents &&
              agents.length > 0 &&
              agents
                .filter((agent: any) => agent.admin.full_name.toLowerCase().includes(search.toLowerCase()))
                .map((data: any, i: number) => (
                  <Menu.Item
                    key={i}
                    style={{minHeight: 50}}
                    onSelect={() =>
                      handleAssign(data?.admin.id, 'agent')
                    }
                    secondaryText={
                      <div>
                        {currentTicket.agents.filter(
                          (agent: any) => agent.id === data.admin.id
                        ).length > 0 && (
                          <Icon icon={'small-tick'} size={16}/>
                        )}
                      </div>
                    }
                  >
                    <div className={'flex align-center'}>
                      <Avatar
                        src={data?.admin?.avatar}
                        name={data?.admin?.full_name}
                      />
                      &nbsp;&nbsp;{data?.admin?.full_name}
                    </div>
                  </Menu.Item>
                ))}
              {!!agents &&
              agents
                .filter((agent: any) => agent.admin.full_name.toLowerCase().includes(search.toLowerCase())).length === 0 &&
              <Menu.Item
                style={{minHeight: 50}}
              >
                <div className='flex align-center'>
                  No Agent Available
                </div>
              </Menu.Item>
              }
            </Menu.Group>
          </div>
          <br/>
          <p className='assign-ticket__list-title'>All Available Groups</p>
          <div className='assign-ticket__list__container'>
            <Menu>
              <Menu.Group>
                {!!agentGroup &&
                agentGroup.length > 0 &&
                agentGroup
                  .filter((group: any) => group.name.toLowerCase().includes(search.toLowerCase()))
                  .map((data: any, i: number) => (
                    <Menu.Item
                      key={i}
                      style={{minHeight: 50}}
                      onSelect={() => handleAssign(data?.id, 'agentgroup')}
                      secondaryText={
                        <div>
                          {!!currentTicket && currentTicket?.groups?.filter(
                            (group: any) => group.id === data.id
                          ).length > 0 && (
                            <Icon icon={'small-tick'} size={16}/>
                          )}
                        </div>
                      }
                    >
                      <div className='flex align-center'>
                        <Avatar
                          src={data?.image}
                          name={data?.name}
                        />{' '}
                        &nbsp;&nbsp;{data?.name}
                      </div>
                    </Menu.Item>
                  ))}

                {/*no groups*/}
                {!!agentGroup &&
                agentGroup
                  .filter((group: any) => group.name.toLowerCase().includes(search.toLowerCase())).length === 0 &&
                <Menu.Item
                  style={{minHeight: 50}}
                >
                  <div className={'flex align-center'}>
                    No Groups Available
                  </div>
                </Menu.Item>
                }
              </Menu.Group>
            </Menu>
          </div>
        </div>

      </div>
    </div>
  );
};

export default AssignSideSheet;
