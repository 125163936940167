import React from 'react';
import viberIcon from './../../../assets/icons/viber_logo.svg';
import { TextInputField, Button } from 'evergreen-ui';
import { connect } from 'react-redux';

interface props {
  connectChannel: (id: number, data: any) => Promise<boolean>;
  connectionLoading: boolean;
  connectableChannel: any;
  setShowConnectModal: (val: boolean) => void;
}

const Viber: React.FC<props> = ({
  connectionLoading,
  connectChannel,
  connectableChannel,
  setShowConnectModal,
}) => {
  const [publicAccount, setPublicAccount] = React.useState('');
  const [primaryToken, setPrimaryToken] = React.useState('');
  const [isInvalidAccount, setIsInvalidAccount] = React.useState(false);
  const [isInvalidPrimaryToken, setIsInvalidPrimaryToken] = React.useState(
    false
  );
  return (
    <div className='channel-settings__card-channel-create'>
      <img
        src={viberIcon}
        alt={'channel modal'}
        className='channel-settings__card-channel-create-banner'
      />
      <p className='channel-settings__card-channel-create-title'>
        Connect With Viber!
      </p>
      <p className='channel-settings__card-channel-create-description'>
        Connect your viber account, you can edit the details later. Please
        provide us the required permission to get started!
      </p>
      <TextInputField
        label='Public Account Name'
        required
        isInvalid={isInvalidAccount}
        placeholder='Valid Public Account Name '
        value={publicAccount}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setPublicAccount(e.target.value)
        }
      />
      <TextInputField
        label='Primary Token'
        required
        isInvalid={isInvalidPrimaryToken}
        placeholder='Enter primary token'
        value={primaryToken}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setPrimaryToken(e.target.value)
        }
      />

      <div className='flex justify-center mb-20px mt-30px'>
        <Button
          className='alice-connect-style_2 viber fs-20px'
          isLoading={connectionLoading}
          onClick={async () => {
            if (!!publicAccount && !!primaryToken) {
              setIsInvalidPrimaryToken(false);
              setIsInvalidAccount(false);
              const res = await connectChannel(connectableChannel.id, {
                name: publicAccount,
                primary_token: primaryToken,
              });
              if (res) {
                setShowConnectModal(false);
              }
            } else {
              if (!primaryToken) {
                setIsInvalidPrimaryToken(true);
              }
              if (!publicAccount) {
                setIsInvalidAccount(true);
              }
            }
          }}
        >
          Connect Now
        </Button>
      </div>
    </div>
  );
};

const mapState = (state: any) => ({
  connectionLoading: state.loading.effects.channel.connectChannel,
});

const mapDispatch = (dispatch: any) => ({
  connectChannel: (id: number, data: any) =>
    dispatch.channel.connectChannel({ id, data }),
});

const ViberChannelContainer = connect(mapState, mapDispatch)(Viber);

export default ViberChannelContainer;
