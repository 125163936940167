import React from 'react';
import {Tooltip, Pane, Spinner, IconButton} from 'evergreen-ui';
import {useDropzone} from 'react-dropzone'

interface props {
    title: string;
    loader: boolean;
    handleImport: (labId:string,file:any) => boolean;
    isProUser: boolean;
    labId: any;
    setShowProModal: () => void;
    fetchData: () => void;
}

const DataLabImport: React.FC<props> = ({title,loader,handleImport,isProUser,labId,setShowProModal,fetchData}) => {

        const { getRootProps, getInputProps } = useDropzone({
            accept: '.csv',
            onDrop: async (files) => {
                const formData = new FormData();
                formData.append('file', files[0], files[0]?.name);
                const res = await handleImport(labId,formData);
                if(res) {
                    fetchData();
                }
            },
            multiple: false,
        });


        return loader ? (
        <Tooltip content='Exporting...'>
            <Pane display='flex' alignItems='center' marginRight={26}>
                <Spinner size={20} />
            </Pane>
        </Tooltip>
        ) :
                isProUser ? <div {...getRootProps()}>
                        <input {...getInputProps()}/>
                        <Tooltip content={title}>
                            <IconButton
                                appearance='minimal'
                                icon='import'
                                marginRight={15}
                                iconSize={16}
                                className={''}
                            />
                        </Tooltip>
                    </div> :
                    <div>
                        <Tooltip content={title}>
                            <IconButton
                                appearance='minimal'
                                icon='import'
                                marginRight={15}
                                iconSize={16}
                                className={'pro-badge'}
                                onClick={()=>setShowProModal()}
                            />
                        </Tooltip>
                    </div>

}
;

export default DataLabImport;
