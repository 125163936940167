import React from 'react';
import {IconButton, SearchInput, Spinner, Menu, Button} from "evergreen-ui";
import noDataFound from './../../../assets/images/no-data-found.svg';
import AvatarGroup from "../../../../../components/elements/redesign/AvatarGroup";
import wooCommerce from './../../../../settings/assets/icons/marketplace/woocommerce.svg';

interface Prop {
  handleClose: () => void;
  fetchEcommerceCustomerListLoader: boolean;
  customerList: any[];
  fetchEcommerceCustomerList: (search: string) => void;
  currentTicket: any;
  linkCustomerWithCRM: (customerId: number) => void;
  linkLoader: boolean;
}

const WooCommerceConnectCustomer: React.FC<Prop> = (
  {
    handleClose,
    fetchEcommerceCustomerListLoader,
    customerList,
    fetchEcommerceCustomerList,
    currentTicket,
    linkCustomerWithCRM,
    linkLoader,
  }) => {
  const [search, setSearch] = React.useState('');
  const [selectedCustomer, setSelectedCustomer] = React.useState<any>(null);

  return (
    <div className='woocommerce-connect-customer__container'>
      <div className='flex space-between align-center mb-10px'>
        <p>Search and connect with customers</p>
        <IconButton icon='cross' className='iconButton-offFocus' appearance='minimal' onClick={() => handleClose()}/>
      </div>
      <SearchInput
        className='alice-rd-search'
        type='text'
        placeholder="Search Customer Name..."
        value={search}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSearch(e.target.value);
          if (!e.target.value) {
            fetchEcommerceCustomerList('');
          }
        }}
        onKeyPress={(e: any) => {
          if (e.key === 'Enter') {
            fetchEcommerceCustomerList(search);
          }
        }}
        height={40}
        width={'100%'}/>
      <br/>
      {!selectedCustomer &&
      <div className='woocommerce-connect-customer__data-container mt-10px'>
        {fetchEcommerceCustomerListLoader &&
        <div className='flex justify-center align-center h-100p flex-column'>
          <Spinner/>
        </div>}
        {!fetchEcommerceCustomerListLoader && customerList.length > 0 &&
        <Menu>
          <Menu.Group>
            {customerList.map((customer: any, index: number) =>
              <Menu.Item style={{padding: '20px 10px'}} onSelect={() => setSelectedCustomer(customer)} key={index}>
                <div className='flex space-between align-center'>
                  <p>{customer.email}</p>
                  {customer?.connected_customers?.length > 0 &&
                  <AvatarGroup
                    data={customer.connected_customers.map((customer: any) =>
                      ({avatar: customer.avatar, full_name: customer.full_name, id: customer.alice_customer_id})
                    )}
                  />}
                </div>
              </Menu.Item>
            )}
          </Menu.Group>
        </Menu>
        }
        {!fetchEcommerceCustomerListLoader && customerList.length === 0 &&
        <div className='flex justify-center align-center h-100p flex-column'>
          <img src={noDataFound} alt='no data' width='150px'/>
          <p className='description'>No customer found!</p>
        </div>}
      </div>
      }

      {!!selectedCustomer &&
      <div className='woocommerce-connect-customer__data-container-preview'>
        <div className='woocommerce-connect-customer__data-container-preview__header'>
          <img src={wooCommerce} width={26} alt='woocommerce' style={{marginRight: 10}}/>
          <h5>Connect with WooCommerce</h5>
        </div>
        <p className='woocommerce-connect-customer__data-container-preview__description'>
          {
            selectedCustomer?.connected_customers.length > 0 ?
              <span>This WooCommerce account, <strong>{selectedCustomer.email}</strong>, is already associated with Alice - <strong>{selectedCustomer.connected_customers.length === 1 ? selectedCustomer.connected_customers[0].full_name : selectedCustomer.connected_customers.length > 1 ? selectedCustomer.connected_customers.reduce((total: any, amount: any) => total.full_name + ", " + amount.full_name) : ''}</strong>. Do you want to continue and connect the WooCommerce account with Alice account <strong>{currentTicket.customer.full_name}</strong> ?</span>
              :
              <span>This WooCommerce account, <strong>{selectedCustomer.email}</strong>, is not associated with Alice. Do you want to continue and connect the WooCommerce account with Alice account <strong>{currentTicket.customer.full_name || 'Anonymous User'}</strong> ?</span>
          }
        </p>
        <br/><br/>
        <div className='flex'>
          <Button
            className='alice-btn__secondary'
            marginRight={10}
            isLoading={linkLoader}
            onClick={() => {
              linkCustomerWithCRM(selectedCustomer.ecommerce_account_id)
            }}>Connect Now</Button>
          <Button className='alice-btn__default' onClick={() => {
            setSelectedCustomer(null);
            setSearch('');
            handleClose();
          }}>Cancel</Button>
        </div>
      </div>
      }

    </div>
  );
}

export default WooCommerceConnectCustomer;
