import React, { Component } from 'react';
import { connect } from 'react-redux';
import PaymentHistory from '../component/billing/PaymentHistory';
import HasPermission from '../../../components/HasPermission';

class BillingHistorySettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      readAccess: 'read:settings',
      writeAccess: 'write:settings',
    };
  }

  async componentDidMount() {
    await this.props.fetchHistories();
  }

  render() {
    return (
      <HasPermission shortCode={this.state.readAccess} isSettingsView>
        <div className='billing-settings-container'>
          <p className='channel-settings__header'>Payment History</p>
          <p className='channel-settings__description'>
            All of your transaction details can be found here.
          </p>
          <br /> <br />
          {this.props.histories.length > 0 ? (
            <PaymentHistory
              data={this.props.histories}
              handleRefundRequest={this.props.requestRefund}
            />
          ) : (
            <p>No Billing Histories Created Yet</p>
          )}
        </div>
      </HasPermission>
    );
  }
}

const mapState = (state) => ({
  histories: state.billing.histories,
  permissionList: state.dashboard.permission.list || [],
});

const mapDispatch = (dispatch) => ({
  fetchHistories: () => dispatch.billing.fetchHistories(),
  requestRefund: (bill_id, reason) =>
    dispatch.billing.requestRefund({ bill_id, reason }),
});

const BillingHistorySettingsContainer = connect(
  mapState,
  mapDispatch
)(BillingHistorySettings);

export default BillingHistorySettingsContainer;
