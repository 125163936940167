import React from 'react';
import HasPermission from "../../../components/HasPermission";
import SettingsHeader from "../component/SettingsHeader";
import './../assets/scss/marketplace-style.scss';
import MarketPlaceCard from "../component/Marketplace/MarketPlaceCard";
import wooCommerce from './../assets/icons/marketplace/woocommerce.svg';
import shopify from './../assets/icons/marketplace/shopify.svg';
import magento from './../assets/icons/marketplace/magento.svg';
import bigCommerce from './../assets/icons/marketplace/bigCommerce.svg';
import {connect} from "react-redux";
import {Dialog} from "evergreen-ui";
import WooCommerceModalContainer from "../component/Marketplace/Woocommerce/WooCommerceModalContainer";
import WooCommerceConfirmModalContainer from "../component/Marketplace/Woocommerce/WooCommerceConfirmModalContainer";
import {navigate} from "@reach/router";
import WooCommerceConfirmModalSuccess from "../component/Marketplace/Woocommerce/WooCommerceConfirmModalSuccess";
import WooCommerceHeader from "../component/Marketplace/Woocommerce/WooCommerceHeader";
import WooCommerceModalPluginKey from "../component/Marketplace/Woocommerce/WooCommerceModalPluginKey";
import DisconnectModal from "../component/Marketplace/ReusableComponents/DisconnectModal";
import ProUser from "../../../components/ProUser";


interface Prop {
  marketPlaceData: any;
  fetchMarketPlaceData: (projectId: number) => void;
  projectId: number;
  initializeMarketPlaceData: (projectId: number, storeURL: string, returnURL: string) => void;
  disconnectEcommerceIntegration: (teamId: number) => void;
  location: any;
  disconnectEcommercePluginIntegration: (teamId: number) => void;
}

const MarketplaceSettings: React.FC<Prop> = (
  {
    marketPlaceData,
    fetchMarketPlaceData,
    projectId,
    initializeMarketPlaceData,
    disconnectEcommerceIntegration,
    location,
    disconnectEcommercePluginIntegration,
  }) => {

  const [ecom, setEcom] = React.useState('');
  const [showPlugin, setShowPlugin] = React.useState(false);
  const [isDisconnectModalShown, setIsDisconnectModalShown] = React.useState(false);
  const [isDisconnectPluginModalShown, setIsDisconnectPluginModalShown] = React.useState(false);


  React.useEffect(() => {
    fetchMarketPlaceData(projectId);
    // eslint-disable-next-line
  }, [])

  const isPluginAvailableToConnect = (location.search === `?success=1&user_id=${projectId}` && !!marketPlaceData && marketPlaceData.is_verified && !marketPlaceData.has_plugin_connected);
  const isPluginConnected = (location.search === `?success=1&user_id=${projectId}` && !!marketPlaceData && marketPlaceData.is_verified && marketPlaceData.has_plugin_connected);
  return (
    <HasPermission shortCode={'read:settings'} isSettingsView>
      <ProUser handleCancel={() => window.history.back()}>
        <div className='settings-card' style={{minHeight: `calc(100vh - 80px)`}}>
          <SettingsHeader title={'Integrations'} subtitle='Manage you subscription here.'/>
          <div className='marketplace__container'>
            <MarketPlaceCard
              icon={wooCommerce}
              title='WooCommerce'
              status={marketPlaceData?.id === -1 ? 'installable' : marketPlaceData.ecommerce_type === 'woocommerce' ? marketPlaceData.is_verified ? marketPlaceData.has_plugin_connected ? 'installed' : 'pendingPlugin' : 'pending' : 'coming'}
              handleInstall={() => setEcom('woocommerce')}
              handleDisconnect={() => {
                setIsDisconnectModalShown(true);
              }}
              hasPluginConnected={marketPlaceData.has_plugin_connected}
              handlePluginDisconnect={() => setIsDisconnectPluginModalShown(true)}
              handlePluginShow={() => {
                setShowPlugin(true);
              }}
              description='Integrate your WooCommerce account with Alice to enjoy the world of smart customer management! WooCommerce integration process in Alice is a piece of cake!'
            />
            <MarketPlaceCard
              icon={shopify}
              title='Shopify'
              handleInstall={() => setEcom('shopify')}
              description='Integrate your Shopify account with Alice to enjoy the world of smart customer management! WooCommerce integration process in Alice is a piece of cake!'
            />
            <MarketPlaceCard
              icon={magento}
              title='Magento'
              description='Integrate your Magento account with Alice to enjoy the world of smart customer management! WooCommerce integration process in Alice is a piece of cake!'
            />
            <MarketPlaceCard
              icon={bigCommerce}
              title='Big Commerce'
              description='Integrate your Big commerce account with Alice to enjoy the world of smart customer management! WooCommerce integration process in Alice is a piece of cake!'
            />
          </div>
          <Dialog
            isShown={!!ecom && ecom === 'woocommerce'}
            hasHeader={false}
            hasFooter={false}
            shouldCloseOnOverlayClick={false}
            onCloseComplete={() => setEcom('')}>
            {ecom === 'woocommerce' &&
            <WooCommerceModalContainer
              initializeMarketPlaceData={(storeURL: string) => initializeMarketPlaceData(projectId, storeURL, window.location.href)}
              handleClose={() => setEcom('')}/>}
          </Dialog>
          <Dialog
            isShown={isPluginAvailableToConnect}
            hasHeader={false}
            hasFooter={false}
            shouldCloseOnOverlayClick={false}
            onCloseComplete={() => {
            }}>
            <WooCommerceConfirmModalContainer
              location={location}
              marketPlaceData={marketPlaceData}
              fetchMarketPlaceData={async () => await fetchMarketPlaceData(projectId)}
            />
          </Dialog>
          <Dialog
            isShown={isPluginConnected}
            hasHeader={false}
            hasFooter={false}
            shouldCloseOnOverlayClick={false}
            onCloseComplete={() => navigate(location.pathname)}>
            <WooCommerceConfirmModalSuccess
              location={location}
              handleClose={() => navigate(location.pathname)}/>
          </Dialog>
          <Dialog
            isShown={showPlugin && !marketPlaceData.has_plugin_connected}
            hasHeader={false}
            hasFooter={false}
            shouldCloseOnOverlayClick={false}
            onCloseComplete={() => setShowPlugin(false)}>
            <div className='woocommerce-modal'>
              <WooCommerceHeader
                handleClose={() => {
                  setShowPlugin(false);
                }
                }/>
              <WooCommerceModalPluginKey
                marketPlaceData={marketPlaceData}
                handlePluginKeyCheck={async () => {
                  await fetchMarketPlaceData(projectId);
                }}
              />
            </div>
          </Dialog>
          <DisconnectModal
            isShown={isDisconnectModalShown}
            setShown={setIsDisconnectModalShown}
            intent={'warning'}
            hasConfirmText={true}
            onDeleteCallback={() => disconnectEcommerceIntegration(projectId)}
            title={'Disconnect WooCommerce'}
            description="You have requested to disconnect your WooCommerce account from Alice. Be sure that all the links between WooCommerce and Alice customers will be removed. Webchat channel will be turned off."/>
          <DisconnectModal
            isShown={isDisconnectPluginModalShown}
            setShown={setIsDisconnectPluginModalShown}
            intent={'danger'}
            hasConfirmText={false}
            onDeleteCallback={() => disconnectEcommercePluginIntegration(projectId)}
            title={'Remove Alice Plugin'}
            description="The Alice plugin in your site will be completely disconnected in 10s."/>
        </div>
      </ProUser>
    </HasPermission>
  );
};

const mapState = (state: any) => ({
  marketPlaceData: state.marketplace.marketPlaceData,
  projectId: state.dashboard.selectedProject.id,
});

const mapDispatch = (dispatch: any) => ({
  fetchMarketPlaceData: (teamId: number) => dispatch.marketplace.fetchMarketPlaceData(teamId),
  initializeMarketPlaceData: (teamId: number, storeURL: string, returnURL: string) =>
    dispatch.marketplace.initializeMarketPlaceData({teamId, storeURL, returnURL}),
  disconnectEcommerceIntegration: (teamId: number) => dispatch.marketplace.disconnectEcommerceIntegration(teamId),
  disconnectEcommercePluginIntegration: (teamId: number) => dispatch.marketplace.disconnectEcommercePluginIntegration(teamId),
});

const MarketplaceSettingsContainer = connect(
  mapState,
  mapDispatch
)(MarketplaceSettings);

export default MarketplaceSettingsContainer;
