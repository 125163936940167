import React from 'react';
import { TextInputField, Select, Icon, Button, toaster } from 'evergreen-ui';
import { isValidEmail } from './../../../../utils';

interface props {
  attributes: any;
  onClose: () => void;
  handleOnChange: (key: string, value: any, type: string) => void;
  submitMethod: () => Promise<boolean>;
}

const UserInformationSectionEditModal: React.FC<props> = ({
  attributes,
  onClose,
  handleOnChange,
  submitMethod,
}) => {
  const tz = [
    { label: '(GMT-12:00) International Date Line West', value: '-12' },
    { label: '(GMT-11:00) Midway Island, Samoa', value: '-11' },
    { label: '(GMT-10:00) Hawaii', value: '-10' },
    { label: '(GMT-09:00) Alaska', value: '-9' },
    {
      label: '(GMT-08:00) Pacific Time (US & Canada),Tijuana, Baja California',
      value: '-8',
    },
    {
      label:
        '(GMT-07:00) Arizona,Chihuahua, La Paz, Mazatlan,Mountain Time (US & Canada)',
      value: '-7',
    },
    {
      label: '(GMT-06:00) Central America,Central Time (US & Canada)',
      value: '-6',
    },
    {
      label:
        '(GMT-05:00) Bogota, Lima, Quito, Rio Branco, Indiana (East), Eastern Time (US & Canada)',
      value: '-5',
    },
    {
      label:
        '(GMT-04:00) Atlantic Time (Canada),Caracas, La Paz, Manaus, Santiago',
      value: '-4',
    },
    { label: '(GMT-03:30) Newfoundland', value: '-3.5' },
    {
      label:
        '(GMT-03:00) Brasilia,Buenos Aires, Georgetown, Greenland, Montevideo',
      value: '-3',
    },
    { label: '(GMT-02:00) Mid-Atlantic', value: '-2' },
    { label: '(GMT-01:00) Cape Verde Is. , Azores', value: '-1' },
    {
      label:
        '(GMT+00:00) Casablanca, Monrovia, Reykjavik, Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
      value: '0',
    },
    {
      label:
        '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna,Belgrade, Bratislava, Budapest, Ljubljana, Prague,Brussels, Copenhagen, Madrid, Paris, Sarajevo, Skopje, Warsaw, Zagreb, West Central Africa',
      value: '1',
    },
    {
      label:
        '(GMT+02:00) Amman, Athens, Bucharest, Istanbul, Beirut, Cairo, Harare, Pretoria,  Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius, Jerusalem, Minsk, Windhoek',
      value: '2',
    },
    {
      label:
        '(GMT+03:00) Kuwait, Riyadh, Baghdad, Moscow, St. Petersburg, Volgograd, Nairobi, Tbilisi',
      value: '3',
    },
    { label: '(GMT+03:30) Tehran', value: '3.5' },
    { label: '(GMT+04:00) Abu Dhabi, Muscat, Baku, Yerevan', value: '4' },
    { label: '(GMT+04:30) Kabul', value: '4.5' },
    {
      label: '(GMT+05:00) Yekaterinburg, Islamabad, Karachi, Tashkent',
      value: '5',
    },
    {
      label:
        '(GMT+05:30) Sri Jayawardenapura, Chennai, Kolkata, Mumbai, New Delhi',
      value: '5.5',
    },
    { label: '(GMT+05:45) Kathmandu', value: '5.75' },
    { label: '(GMT+06:00) Almaty, Novosibirsk,Astana, Dhaka', value: '6' },
    { label: '(GMT+06:30) Yangon (Rangoon)', value: '6.5' },
    { label: '(GMT+07:00) Bangkok, Hanoi, Jakarta, Krasnoyarsk', value: '7' },
    {
      label:
        '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi, Kuala Lumpur, Singapore, Irkutsk, Ulaan Bataar, Perth, Taipei',
      value: '8',
    },
    { label: '(GMT+09:00) Osaka, Sapporo, Tokyo, Seoul,Yakutsk', value: '9' },
    { label: '(GMT+09:30) Adelaide, Darwin', value: '9.5' },
    {
      label:
        '(GMT+10:00) Brisbane,Canberra, Melbourne, Sydney,Hobart,Guam, Port Moresby,Vladivostok',
      value: '10',
    },
    { label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia', value: '11' },
    {
      label: '(GMT+12:00) Auckland, Wellington, Fiji, Kamchatka, Marshall Is.',
      value: '12',
    },
    { label: "(GMT+13:00) Nuku'alofa", value: '13' },
  ];
  const [showAddAttribute, setShowAttribute] = React.useState(false);
  const [defaultKey, setDefaultKey] = React.useState('');
  const [defaultValue, setDefaultValue] = React.useState('');
  return (
    <div className='edit-user-info__modal-container'>
      <div className='flex space-between w-100p'>
        <h4>Edit Details</h4>
        <Icon icon={'cross'} style={{ cursor: 'pointer' }} onClick={onClose} size={16} />
      </div>
      <br />
      <div className='edit-user-info__content'>
        <div className='flex w-100p'>
          <TextInputField
            label='First Name'
            placeholder='Write here...'
            value={attributes.fixed.full_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleOnChange('full_name', e.target.value, 'fixed')
            }
            marginRight={10}
          />
        </div>
        <div className='flex w-100p'>
          <div className='mb-15px'>
            <p className={'label'}>Gender</p>
            <Select
              width='100%'
              className='button-alice__white-select mr-10-select'
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                handleOnChange('gender', e.target.value, 'fixed')
              }
              value={attributes.fixed?.gender}
            >
              <option value='male'>Male</option>
              <option value='female'>Female</option>
              <option value='other'>Other</option>
            </Select>
          </div>

          <TextInputField
            type={'tel'}
            label='Phone'
            placeholder='Write here...'
            value={attributes.fixed.phone || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleOnChange('phone', e.target.value, 'fixed')
            }
          />
        </div>
        <div className='flex w-100p'>
          <TextInputField
            label='Email'
            placeholder='Write here...'
            type={'email'}
            value={attributes.fixed.email || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleOnChange('email', e.target.value, 'fixed')
            }
            marginRight={10}
          />
          <TextInputField
            label='Locale'
            placeholder='Write here...'
            value={attributes.fixed.locale || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleOnChange('locale', e.target.value, 'fixed')
            }
          />
        </div>
        <div className='flex w-100p'>
          <div className='mb-15px'>
            <p className={'label'}>Timezone</p>
            <Select
              width='100%'
              className='button-alice__white-select'
              value={attributes.fixed.timezone}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                handleOnChange('timezone', parseFloat(e.target.value), 'fixed')
              }
            >
              {tz.map((timezone: any, key: number) => (
                <option key={key} value={timezone.value}>
                  {timezone.label}
                </option>
              ))}
            </Select>
          </div>
        </div>
        {Object.keys(attributes.variable).length > 0 &&
          Object.keys(attributes.variable).map(
            (elem: string, index: number) =>
              !!attributes.variable[elem] && (
                <div
                  key={index}
                  className='edit-user-info__content-dynamicadd-variable'
                >
                  <p>{elem}:</p>
                  <p>{JSON.stringify(attributes.variable[elem])}</p>
                  <Icon
                    onClick={() => {
                      handleOnChange(elem, attributes.variable[elem], 'delete');
                    }}
                    icon={'trash'}
                    color={'danger'}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              )
          )}
        <div
          className='edit-user-info__content-dynamicadd'
          onClick={() => setShowAttribute(true)}
        >
          <Icon icon={'plus'} size={16} /> Add Attribute
        </div>
        {showAddAttribute && (
          <div className='edit-user-info__content-dynamicadd-box'>
            <Icon
              onClick={() => setShowAttribute(false)}
              className='edit-user-info__content-dynamicadd-box-caret'
              icon={'chevron-up'}
            />
            <div className='flex w-100p'>
              <TextInputField
                label='Key'
                placeholder='Write here...'
                value={defaultKey}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setDefaultKey(e.target.value)
                }
                marginRight={10}
              />
              <TextInputField
                label='Value'
                placeholder='Write here...'
                value={defaultValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setDefaultValue(e.target.value)
                }
              />
            </div>
            {!!defaultValue && !!defaultKey && (
              <div className='flex w-100p space-between'>
                <Button
                  className='button-alice__gradient'
                  onClick={() => {
                    handleOnChange(defaultKey, defaultValue, 'variable');
                    setDefaultKey('');
                    setDefaultValue('');
                    setShowAttribute(false);
                  }}
                >
                  Save Changes
                </Button>
                <Button
                  className='btn-outline-danger'
                  onClick={() => {
                    setShowAttribute(false);
                    setDefaultValue('');
                    setDefaultKey('');
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        )}
        <br />
        <br />
        <div className='flex w-100p flex-end'>
          <Button
            className='alice-btn__default'
            marginRight={10}
            onClick={() => {
              setShowAttribute(false);
              setDefaultValue('');
              setDefaultKey('');
              onClose();
            }}
          >
            Cancel
          </Button>

          <Button
            className='alice-btn__secondary'
            onClick={async () => {
              const isEmailEmpty = !attributes.fixed.email;
              if (isEmailEmpty) {
                const res = await submitMethod();
                if (res) {
                  setDefaultKey('');
                  setDefaultValue('');
                  setShowAttribute(false);
                  onClose();
                } else {
                  toaster.danger('Failed to Update Information');
                }
              } else if (
                !isEmailEmpty &&
                isValidEmail(attributes.fixed.email)
              ) {
                const res = await submitMethod();
                if (res) {
                  setDefaultKey('');
                  setDefaultValue('');
                  setShowAttribute(false);
                  onClose();
                } else {
                  toaster.danger('Failed to Update Information');
                }
              } else {
                toaster.warning('Provide A Valid Email');
              }
            }}
          >
            Save Changes
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserInformationSectionEditModal;
