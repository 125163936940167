import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, navigate } from '@reach/router';
import './assets/styles/auth.scss';
import { toaster } from 'evergreen-ui';
import AccountVerifyForm from './components/AccountVerifyForm';
import { authData } from '../../content';
import logoBlack from './assets/images/logo/dark-alice-logo-full.svg';
import AuthHeader from './components/AuthHeader';

class VerifyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verifyCode: '',
      loading: false,
    };
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  handleResetVerifySubmit = () => {
    this.setState({ loading: true });
    this.props
      .registerVerify({
        code: this.state.verifyCode,
      })
      .then((success) => {
        if (success) {
          toaster.success('Account Verified! You can log in now.');
          this.setState({
            verifyCode: '',
            loading: false,
          });
          navigate(`/`);
        } else {
          this.setState({ loading: false });
        }
      });
  };

  componentDidMount() {
    const [, query] = this.props.location.search.split('?code=');
    if (query) {
      this.setState({ verifyCode: query });
    }
  }

  render() {
    const isAuthenticated = this.props.auth.access !== '';
    return isAuthenticated ? (
      <Redirect from='/' to='/dashboard' noThrow />
    ) : (
      <div className='auth-container'>
        <div className='header-container'>
          <div>
            <a href='/'>
              <img className='logo-dark' src={logoBlack} alt='alice logo' />
            </a>
          </div>
          <div>
            <AuthHeader
              linkInfo={authData.contactInfo.text}
              link={authData.contactInfo.link}
              linkText={authData.contactInfo.linkTitle}
            />
          </div>
        </div>
        <AccountVerifyForm
          handleChange={this.handleChange}
          verifyCode={this.state.verifyCode}
          loading={this.state.loading}
          handleSubmit={() => this.handleResetVerifySubmit()}
          content={authData}
        />
      </div>
    );
  }
}

const mapState = (state) => ({
  auth: state.auth,
});

const mapDispatch = (dispatch) => ({
  registerVerify: (payload) => dispatch.auth.registerVerify(payload),
});

const VerifyAccountContainer = connect(mapState, mapDispatch)(VerifyAccount);

export default VerifyAccountContainer;
