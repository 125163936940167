import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextInputField } from 'evergreen-ui';

const SetAttributeFields = ({
  variable,
  handleVariable,
  value,
  handleValue,
  hasWriteAccess,
}) => {
  return (
    <Fragment>
      <h5>Store Data to a variable</h5>
      <p>You can use it as attribute later on.</p>
      <div className='flex-container'>
        <TextInputField
          label={'Attribute'}
          disabled={!hasWriteAccess}
          placeholder={'Provide A Attribute'}
          value={variable}
          onChange={(e) => {
            handleVariable(e.target.value);
          }}
        />
        <TextInputField
          label={'Value'}
          disabled={!hasWriteAccess}
          placeholder={'Provide A Attribute Value'}
          value={value}
          onChange={(e) => {
            handleValue(e.target.value);
          }}
        />
      </div>
    </Fragment>
  );
};

SetAttributeFields.propTypes = {
  variable: PropTypes.string.isRequired,
  handleVariable: PropTypes.func,
  value: PropTypes.string.isRequired,
  handleValue: PropTypes.func,
  hasWriteAccess: PropTypes.bool,
};

SetAttributeFields.defaultProps = {
  handleVariable: () =>
    console.log(
      'Handle Variable is Not provided as Props, Check the parent component of SetAttributeCell'
    ),
  handleValue: () =>
    console.log(
      'Handle value is Not provided as Props, Check the parent component of SetAttributeCell'
    ),
  hasWriteAccess: false,
};

export default SetAttributeFields;
