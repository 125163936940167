import axios from '../../../httpClient';
import config from '../../../config';
import { toaster } from 'evergreen-ui';
import cloneDeep from 'lodash/cloneDeep';
import { initiateNotification } from '../../../utils';

let CreateTicketAudio = new Audio(
  'https://live-alice-v3.s3-ap-southeast-1.amazonaws.com/create_ticket.mp3'
);
let UpdateTicketAudio = new Audio(
  'https://live-alice-v3.s3-ap-southeast-1.amazonaws.com/update_ticket.mp3'
);

const initialState = {
  queue: [],
  limit: 20,
  offset: 0,
  ticketOrder: 'desc',
  currentTicket: null,
  total: 0,
  selectedPlatform: {
    id: null,
    name: 'ALL',
    type: 'all',
    value: 'ALL PLATFORM',
  },
  customerMeta: null,
  isExpanded: true,
  allTicketTags: [],
  allUserTags: [],
  chatData: [],
  assginableAdmins: [],
  agentGroup: [],
  ticketActions: [],
  cannedResponses: [],
  customerAttributes: null,
  tempAttributes: null,
  isAlertCreateTicket: false,
  isAlertUpdateTicket: false,
  browserNotification: false,
  msgCount: 0,
  maxSoundPerDuration: 2,
  soundDuration: 10000,
  galleryImages: [],
  galleryTotal: 0,
  eComOrderList: [],
};

export const crm = {
  state: {
    ...initialState,
  },
  reducers: {
    updateTicket(state, payload) {
      // data: [],
      //   total: res.data.total,
      //   offset: 0,
      //   myQueueCount: res.data.my_queue_count || 0,
      //   allQueueCount: res.data.all_queue_count || 0,
      document.title = 'Alice - Conversation AI Platform';
      if (!!payload?.ticketOrder) {
        return {
          ...state,
          queue: payload.data,
          currentTicket: payload.data.length > 0 ? payload.data[0] : null,
          offset: payload.offset,
          total: payload.total,
          ticketOrder: payload.ticketOrder,
        };
      } else {
        return {
          ...state,
          queue: payload.data,
          currentTicket: payload.data.length > 0 ? payload.data[0] : null,
          offset: payload.offset,
          total: payload.total,
        };
      }
    },
    updateSelectedPlatform(state, payload) {
      return { ...state, selectedPlatform: payload };
    },
    updateCustomerMetaEcomReference(state, payload) {
      let customerMetaLocal = state.customerMeta;
      customerMetaLocal['ecommerce_collection_reference'] = payload;
      return { ...state, customerMeta: customerMetaLocal };
    },
    updateExpand(state, payload) {
      return { ...state, isExpanded: payload };
    },
    updateCustomerAttributes(state, payload) {
      return {
        ...state,
        customerAttributes: payload,
        tempAttributes: JSON.parse(JSON.stringify(payload)),
      };
    },
    updateAgentGroup(state, payload) {
      return { ...state, agentGroup: payload };
    },
    updateGalleryImages(state, payload) {
      return {
        ...state,
        galleryImages: [...payload.dataSource],
        galleryTotal: payload.total,
      };
    },
    updateGalleryImagesAppend(state, payload) {
      return { ...state, galleryImages: [...state.galleryImages, ...payload] };
    },
    updateGalleryImagesUpload(state, payload) {
      return {
        ...state,
        galleryImages: [...payload, ...state.galleryImages],
        galleryTotal: state.galleryTotal + 1,
      };
    },
    updateTicketTag(state, payload) {
      const { tag, action } = payload;
      if (action === 'add') {
        document.title = 'Alice - Conversation AI Platform';
        return {
          ...state,
          currentTicket: {
            ...state.currentTicket,
            tags: [...state.currentTicket.tags, tag],
          },
        };
      } else if (action === 'remove') {
        document.title = 'Alice - Conversation AI Platform';
        return {
          ...state,
          currentTicket: {
            ...state.currentTicket,
            tags: state.currentTicket.tags.filter((e) => e.id !== tag.id),
          },
        };
      }
      return { ...state };
    },
    updateQueue(state, payload) {
      let index = state.queue.findIndex((e) => e.id === payload.ticketID);
      let updatedQueueTag = [...state.queue];
      updatedQueueTag[index] = {
        ...updatedQueueTag[index],
        tags: [...state.currentTicket.tags],
      };
      return { ...state, queue: updatedQueueTag };
    },
    updateTicketQueue(state, payload) {
      let totalLocal = state.total;
      let queueLocal = [...state.queue];
      let msgCountLocal = state.msgCount;
      if (!!payload.is_new) {
        //if not undefined we will increase the ticket total count
        totalLocal = totalLocal + 1;
        queueLocal = [payload, ...queueLocal];
        document.title = `You have ${totalLocal} Pending Tickets`;
      } else {
        //if updated we will look for the ticket
        queueLocal = [
          payload,
          ...queueLocal.filter((ticket) => ticket.id !== payload.id),
        ];
      }
      // sound update
      //we update the sound based on event type is_new;
      const shouldShowSound =
        document.visibilityState === 'hidden' ||
        state.currentTicket?.id !== payload.id;
      // this means either its is of focus or not selected any of the logic will show notification

      if (shouldShowSound || state.queue.length === 0) {
        //if new ticket play create a sound
        if (!!payload.is_new) {
          //check permission
          if (
            state.isAlertCreateTicket &&
            state.msgCount < state.maxSoundPerDuration
          ) {
            CreateTicketAudio.play();
            msgCountLocal = msgCountLocal + 1;
          }
        } else if (
          state.isAlertUpdateTicket &&
          state.msgCount < state.maxSoundPerDuration
        ) {
          //play update sound
          UpdateTicketAudio.play();
          msgCountLocal = msgCountLocal + 1;
        }
        if (
          state.browserNotification &&
          state.msgCount < state.maxSoundPerDuration
        ) {
          if (!!payload.is_new) {
            initiateNotification(
              'You have a new pending ticket',
              `${payload?.customer?.platform?.name ||
                'Anonymous Channel'} has a new ticket `
            );
          } else {
            initiateNotification(
              'You have a new message',
              `${payload?.customer?.full_name ||
                'Anonymous User'} has sent a new message`
            );
          }
        }
      }
      return {
        ...state,
        queue: queueLocal,
        total: totalLocal,
        msgCount: msgCountLocal,
      };
    },
    updateTicketAppend(state, payload) {
      //payload: ticket & offset
      // data: res.data.dataSource,
      //   offset: offset,
      if (payload.data.length > 0) {
        const firstTicketfromPayloadId = payload.data[0]?.id;
        if (
          state.queue.filter((e) => e.id === firstTicketfromPayloadId)
            .length === 0
        ) {
          //prevent entries if api called same offset twice
          return {
            ...state,
            queue: [...state.queue, ...payload.data],
            offset: payload.offset,
          };
        }
      }
      return { ...state };
    },
    updateTicketData(state, payload) {
      let updatedTickets = [...state.yourTickets];
      updatedTickets = updatedTickets.map((data) => {
        if (data.id === payload.id) {
          data.description = payload.description;
        }
        return data;
      });
      // need a better solution to this !! this is mutating old state but description needs to update on fix index
      return { ...state, yourTickets: updatedTickets };
    },
    updateStateData(state, payload) {
      //payload: key,value
      return { ...state, [payload.key]: payload.value };
    },
    updateEComOrderList(state, payload) {
      return { ...state, eComOrderList: payload };
    },
    updateCustomerMeta(state, payload) {
      return { ...state, customerMeta: payload };
    },
    updateBotAssign(state, payload) {
      return {
        ...state,
        customerMeta: { ...state.customerMeta, bot_enabled: payload },
      };
    },
    updateLeftbarTicketAssign(state, payload) {
      let queueLocal = [...state.queue];
      let currentTicketLocal = { ...state.currentTicket };

      queueLocal = queueLocal.filter((ticket) => {
        if (ticket.id === state.currentTicket.id) {
          if (!!payload) {
            ticket.agents = [payload?.admin_info];
            ticket.groups = [];
            ticket.is_replied = payload?.is_replied;
          }
        }
        if (!!payload) {
          currentTicketLocal.agents = [payload?.admin_info];
          currentTicketLocal.groups = [];
        }
        return ticket;
      });
      document.title = 'Alice - Conversation AI Platform';
      return {
        ...state,
        queue: queueLocal,
        currentTicket: currentTicketLocal,
      };
    },
    updateAssignAdmin(state, payload) {
      return { ...state, assginableAdmins: payload };
    },
    updateNotificationInfo(state, payload) {
      if (!!payload) {
        let currentAgent = payload.data.filter(
          (agent) => agent.admin.id === payload.adminId
        );
        if (currentAgent.length > 0) {
          currentAgent = currentAgent[0];
          return {
            ...state,
            isAlertCreateTicket: currentAgent.ticket_sound_on,
            isAlertUpdateTicket: currentAgent.message_sound_on,
            browserNotification: currentAgent.browser_notification_on,
          };
        }
      }
      return { ...state };
    },
    updateNotificationKey(state, payload) {
      if (!!payload) {
        return {
          ...state,
          isAlertCreateTicket: payload.ticket_sound_on,
          isAlertUpdateTicket: payload.message_sound_on,
          browserNotification: payload.browser_notification_on,
        };
      }
      return { ...state };
    },
    updateLockedStatus(state, payload) {
      let LocalData = JSON.parse(JSON.stringify(state.queue));
      let currentTicketLocal = null;
      LocalData = LocalData.map((ticket) => {
        if (ticket.id === payload.id) {
          ticket[payload.key] = payload.value;
          currentTicketLocal = ticket;
        }
        return ticket;
      });
      document.title = 'Alice - Conversation AI Platform';
      return {
        ...state,
        queue: LocalData,
        currentTicket: currentTicketLocal,
      };
    },
    updateCannedResponses(state, payload) {
      return { ...state, cannedResponses: payload };
    },
    addChatData(state, payload) {
      //check all logics here
      // payload = {event,adminId}
      let chatDataLocal = [...state.chatData];
      const { event, adminId } = payload;
      if (event.admin_id === adminId && event.source === 'admin') {
        //for admin we need to check pusher logics too
        if (event._id === null) {
          const isAvailable = state.chatData.filter(
            (chat) => chat.pusher_key === event.pusher_key
          ).length;
          if (isAvailable === 0) {
            chatDataLocal = [...chatDataLocal, event];
          }
          //else already exist  dont update
        } else {
          const isAvailable = state.chatData.filter(
            (chat) => chat.pusher_key === event.pusher_key
          ).length;
          if (isAvailable > 0) {
            chatDataLocal = state.chatData.map((chat) => {
              if (chat.pusher_key === event.pusher_key) {
                chat = event;
              }
              return chat;
            });
          } else {
            // just update pusher event
            chatDataLocal = [...chatDataLocal, event];
          }
        }
      } else {
        //customer always true for status
        chatDataLocal = [...state.chatData, payload.event];
      }
      return { ...state, chatData: chatDataLocal };
    },
    addFeedReplyData(state, payload) {
      //check all logics here
      // payload = {event,adminId}
      let chatDataLocal = { ...state.chatData };
      const reply = payload;
      if (reply.source === 'admin') {
        //for admin we need to check pusher logics too
        if (reply._id === null) {
          const isAvailable = state.chatData?.replies.filter(
            (replyLocal) => replyLocal?.pusher_key === reply?.pusher_key
          ).length;
          if (isAvailable === 0) {
            chatDataLocal = {
              ...chatDataLocal,
              replies: [...chatDataLocal.replies, reply],
            };
          }
          // else already exist  dont update
        } else {
          const isAvailable = state.chatData?.replies.filter(
            (replyLocal) =>
              !!replyLocal.pusher_key &&
              replyLocal?.pusher_key === reply?.pusher_key
          ).length;
          if (isAvailable > 0) {
            chatDataLocal.replies = state.chatData.replies.map((replyLocal) => {
              if (replyLocal.pusher_key === reply.pusher_key) {
                replyLocal = reply;
              }
              return replyLocal;
            });
          } else {
            // just update pusher event
            chatDataLocal = {
              ...state.chatData,
              replies: [...chatDataLocal.replies, payload],
            };
          }
        }
      } else {
        //customer/bot always true for status
        chatDataLocal = {
          ...state.chatData,
          replies: [...state.replies, payload.event],
        };
      }
      return { ...state, chatData: chatDataLocal };
    },
    updateCommentStatus(state, action) {
      let updatedChatData = cloneDeep(state.chatData);
      updatedChatData.parent_comment_data['status'] = action;
      return { ...state, chatData: updatedChatData };
    },
    updateReplyStatus(state, payload) {
      let updatedChatData = cloneDeep(state.chatData);
      updatedChatData.replies = updatedChatData.replies.map((reply) => {
        if (payload.action === 'edit') {
          if (reply.conversation_id === payload.commentId) {
            //update the chunk
            if (!!payload.reply && payload.reply.length > 0) {
              reply = payload.reply[0];
            }
          }
        } else {
          //rest
          if (reply._id === payload.commentId) {
            reply.status = payload.action;
          }
        }
        return reply;
      });
      return { ...state, chatData: updatedChatData };
    },
    addFeedReply(state, payload) {
      return {
        ...state,
        chatData: {
          ...state.chatData,
          replies: [...state.chatData.replies, ...payload],
        },
      };
    },
    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async fetchTickets(payload) {
      /*
      payload = {
        projectId: number;
        limit: number;
        offset: number;
        isResolved: boolean;
        search: string;
        channels: any;
        agents: any;
        tags: any;
        start: string;
        end: string;
        ticketOrder: string;

      }
       */
      try {
        const {
          projectId,
          isResolved,
          limit,
          offset,
          search,
          channels,
          agents,
          groups,
          tags,
          start,
          end,
          ticketOrder,
        } = payload;
        const res = await axios.get(`${config.crm}/${projectId}/tickets`, {
          params: {
            resolved: isResolved,
            offset: offset,
            limit: limit,
            search: search,
            channel: channels,
            agents: agents,
            groups: groups,
            tags: tags,
            start: start,
            end: end,
            order: ticketOrder,
          },
        });
        if (res.data.success) {
          // update
          dispatch.crm.updateTicket({
            data: res.data.dataSource,
            total: res.data.total,
            offset: offset,
            ticketOrder: ticketOrder,
          });
        }
      } catch (err) {
        // all
        dispatch.crm.updateTicket({
          data: [],
          total: 0,
          offset: 0,
        });
      }
    },
    async fetchTicketsString({ projectId, paramsString }) {
      paramsString = paramsString.replace('?', '');
      try {
        const res = await axios.get(
          `${config.crm}/${projectId}/tickets?${paramsString}`
        );
        if (res.data.success) {
          dispatch.crm.updateTicket({
            data: res.data.dataSource,
            total: res.data.total,
            offset: 0,
          });
        }
      } catch (err) {
        // all
        dispatch.crm.updateTicket({
          data: [],
          total: 0,
          offset: 0,
        });
      }
    },
    async fetchGalleryImages({ projectId, limit, offset }) {
      // projectId,limit, offset
      const res = await axios.get(`${config.crm}/${projectId}/images`, {
        params: {
          limit: limit,
          offset: offset,
        },
      });
      if (res.status === 200 && res.data.success) {
        if (offset === 0) {
          dispatch.crm.updateGalleryImages(res.data);
        } else {
          dispatch.crm.updateGalleryImagesAppend(res.data.dataSource);
        }
      } else {
        dispatch.crm.updateGalleryImages({ dataSource: [], total: 0 });
      }
    },
    async fetchTicketsOnScroll(payload) {
      /*
      payload = {
       projectId: number
       resolved: number || null,
       offset: offset,
       limit: limit,
       search: search,
       channel: channels,
       agents: agents,
       groups: groups,
       tags: tags,
       start: start,
       end: end,
       ticketOrder: order

      }
       */
      try {
        const {
          projectId,
          limit,
          offset,
          isResolved,
          search,
          channels,
          agents,
          groups,
          tags,
          start,
          end,
          ticketOrder,
        } = payload;
        const res = await axios.get(`${config.crm}/${projectId}/tickets`, {
          params: {
            resolved: isResolved,
            offset: offset,
            limit: limit,
            search: search,
            channels: channels,
            agents: agents,
            groups: groups,
            tags: tags,
            start: start,
            end: end,
            order: ticketOrder,
          },
        });
        if (res.data.success) {
          if (isResolved === 1) {
            //resolved
            dispatch.crm.updateTicketAppend({
              data: res.data.dataSource,
              offset: offset,
            });
          } else if (isResolved === 0) {
            dispatch.crm.updateTicketAppend({
              data: res.data.dataSource,
              offset: offset,
            });
          } else {
            dispatch.crm.updateTicketAppend({
              data: res.data.dataSource,
              offset: offset,
            });
          }
        }
      } catch (err) {
        const { isResolved } = payload;
        if (isResolved === 1) {
          //resolved
          dispatch.crm.updateTicketAppend({
            data: [],
            offset: 0,
          });
        } else if (isResolved === 0) {
          dispatch.crm.updateTicketAppend({
            data: [],
            offset: 0,
          });
        } else {
          dispatch.crm.updateTicketAppend({
            data: [],
            offset: 0,
          });
        }
      }
    },
    // async updateTickets(payload) {
    //   /*
    //   payload = {
    //     projectId,ticketId,body
    //   }
    //    */
    //   try {
    //     const res = await axios.patch(
    //       `${config.crm}/${payload.projectId}/tickets/${payload.ticketId}`,
    //       payload.body
    //     );
    //     if (res.data.success && res.status === 200) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   } catch (err) {
    //     console.log(err.response);
    //     return false;
    //   }
    // },
    async resolveConversation(payload) {
      try {
        const res = await axios.post(
          `${config.crmTicket}/${payload.ticketId}/action-resolve`,
          { status: payload.status }
        );
        if (res.data.success && res.status === 200) {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err.response);
        return false;
      }
    },
    async lockConversation(payload) {
      try {
        const res = await axios.post(
          `${config.crmTicket}/${payload.ticketId}/action-lock`,
          { status: payload.status }
        );
        if (res.data.success && res.status === 200) {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err.response);
        return false;
      }
    },
    async fetchCustomerMeta(payload) {
      /*
      payload = customerId
       */
      try {
        const res = await axios.get(`${config.customers}/${payload}/meta`);
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateCustomerMeta(res.data.data);
        } else {
          dispatch.crm.updateCustomerMeta(null);
        }
      } catch (err) {
        console.log(err.response);
        dispatch.crm.updateCustomerMeta(null);
      }
    },
    async editCustomerMeta(payload) {
      /*
      payload = {body: object,customerId: number}
       */
      try {
        const res = await axios.post(
          `${config.customers}/${payload.customerId}/meta`,
          payload.body
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateCustomerMeta(res.data.data);
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err.response);
        return false;
      }
    },
    async updateBotStatus(payload) {
      /*
      payload = {body: object,customerId: number}
       */
      try {
        const res = await axios.post(
          `${config.crmTicket}/${payload.ticketId}/action-bot`,
          { status: payload.status }
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateBotAssign(payload.status);
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err.response);
        return false;
      }
    },
    async editTicketDetails(payload, rootState) {
      /*
      payload = {body: object}
       */
      try {
        const res = await axios.patch(
          `${config.crm}/${rootState.dashboard.selectedProject.id}/tickets/${rootState.crm.currentTicket.id}`,
          payload
        );
        if (res.status === 200 && res.data.success) {
          //console.log(update description);
          dispatch.crm.updateTicketData(res.data.dataSource);
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err.response);
        return false;
      }
    },
    async fetchAllTicketTag(payload, rootState) {
      /*
      payload = {body: object}
       */
      try {
        const res = await axios.get(
          `${config.crm}/${rootState.dashboard.selectedProject.id}/ticket-tags`
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateStateData({
            key: 'allTicketTags',
            value: res.data.dataSource,
          });
          return true;
        }
        return false;
      } catch (err) {
        console.log(err.response);
        return false;
      }
    },
    async createTicketTag(payload, rootState) {
      /*
      payload = {body: object}
       */
      try {
        const res = await axios.post(
          `${config.crm}/${rootState.dashboard.selectedProject.id}/ticket-tags`,
          { name: payload.value }
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateStateData({
            key: 'allTicketTags',
            value: [...rootState.crm.allTicketTags, res.data.dataSource],
          });
          const assignTicketTagPayload = {
            body: {
              action: 'add',
              id: res.data.dataSource.id,
              name: payload.value,
            },
          };
          await this.assignTicketTag(assignTicketTagPayload);
          return true;
        }
        return false;
      } catch (err) {
        console.log(err.response);
        return false;
      }
    },
    async deleteTicketTag(payload, rootState) {
      /*
      payload = {body: object}
       */
      try {
        const res = await axios.delete(
          `${config.crm}/${rootState.dashboard.selectedProject.id}/ticket-tags/${payload}`
        );
        if (res.status === 200 && res.data.success) {
          const data = JSON.parse(
            JSON.stringify(rootState.crm.allTicketTags)
          ).filter((e) => e.id !== payload);
          toaster.success('Deleted Successfully', {
            description: 'Successfully Deleted Ticket Tag',
          });
          dispatch.crm.updateStateData({
            key: 'allTicketTags',
            value: data,
          });
          dispatch.crm.fetchCurrentTicketData();
          return true;
        }
        return false;
      } catch (err) {
        toaster.danger('Delete Failed', {
          description: 'Failed to Deleted Ticket Tag',
        });
        console.log(err.response);
        return false;
      }
    },
    async editTicketTag(payload, rootState) {
      /*
      payload = {body: object}
       */
      try {
        const res = await axios.patch(
          `${config.crm}/${rootState.dashboard.selectedProject.id}/ticket-tags/${payload.id}`,
          { name: payload.name }
        );
        if (res.status === 200 && res.data.success) {
          const data = JSON.parse(
            JSON.stringify(rootState.crm.allTicketTags)
          ).filter((e) => {
            if (e.id === payload.id) {
              e.name = payload.name;
            }
            return e;
          });
          dispatch.crm.updateStateData({
            key: 'allTicketTags',
            value: data,
          });
          toaster.success('Updated Successfully', {
            description: 'Successfully Updated Ticket Tag',
          });
          dispatch.crm.fetchCurrentTicketData();
          return true;
        }
        return false;
      } catch (err) {
        console.log(err.response);
        toaster.danger('Update Failed', {
          description: 'Failed to Up;dated Ticket Tag',
        });
        return false;
      }
    },
    async assignTicketTag(payload, rootState) {
      /*
      payload = {customerId: num, body: object
       */
      try {
        let shouldFetch = true;
        const TagAlreadyExist =
          rootState.crm.currentTicket.tags.filter(
            (e) => e.id === payload.body.id
          ).length > 0;
        if (TagAlreadyExist && payload.body.action === 'add') {
          shouldFetch = false;
        }
        if (shouldFetch) {
          const res = await axios.post(
            `${config.crmTicket}/${rootState.crm.currentTicket.id}/tag`,
            payload.body
          );
          if (res.status === 200 && res.data.success) {
            dispatch.crm.updateTicketTag({
              tag: { name: payload.body.name, id: payload.body.id },
              action: payload.body.action,
            });
            dispatch.crm.updateQueue({
              ticketID: rootState.crm.currentTicket.id,
              tag: rootState.crm.currentTicket.tags,
            });
            if (payload.body.action === 'add') {
              toaster.success('Successful', {
                description: `Tag ${payload.body.name} Assigned Successfully`,
                duration: 1,
              });
            } else {
              toaster.success('Deleted', {
                description: `Tag ${payload.body.name} Deleted Successfully`,
                duration: 1,
              });
            }
          }
        } else {
          toaster.warning('Already Exist', {
            description: `Tag ${payload.body.name} Already exist`,
            duration: 1,
          });
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async fetchCurrentTicketData(payload, rootState) {
      /*
      payload = {customerId: num, body: object
       */
      try {
        const res = await axios.get(
          `${config.crm}/${rootState.dashboard.selectedProject.id}/tickets/${rootState.crm.currentTicket.id}`
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateStateData({
            key: 'currentTicket',
            value: res.data.dataSource,
          });
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async fetchAllCustomerTag(payload, rootState) {
      /*
      payload = {customerId: num, body: object
       */
      try {
        const res = await axios.get(
          `${config.projects}/${rootState.dashboard.selectedProject.id}/customer-tags`
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateStateData({
            key: 'allUserTags',
            value: res.data.dataSource,
          });
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    // async createUserTag(payload, rootState) {
    //   /*
    //   payload = string
    //    */
    //   try {
    //     const res = await axios.post(
    //       `${config.projects}/${rootState.dashboard.selectedProject.id}/customer-tags`,
    //       { name: payload }
    //     );
    //     if (res.status === 200 && res.data.success) {
    //       dispatch.crm.updateStateData({
    //         key: 'allUserTags',
    //         value: [...rootState.crm.allUserTags, res.data.dataSource],
    //       });
    //     }
    //   } catch (err) {
    //     console.log(err.response);
    //   }
    // },
    // async deleteUserTag(payload, rootState) {
    //   /*
    //   payload = {body: object}
    //    */
    //   try {
    //     const res = await axios.delete(
    //       `${config.projects}/${rootState.dashboard.selectedProject.id}/customer-tags/${payload}`
    //     );
    //     if (res.status === 200 && res.data.success) {
    //       const data = JSON.parse(
    //         JSON.stringify(rootState.crm.allUserTags)
    //       ).filter((e) => e.id !== payload);
    //       toaster.success('Deleted Successfully', {
    //         description: 'Successfully Deleted User Tag',
    //       });
    //       dispatch.crm.updateStateData({
    //         key: 'allUserTags',
    //         value: data,
    //       });
    //       // ekhane fetch hobe customer meta
    //       return true;
    //     }
    //     return false;
    //   } catch (err) {
    //     toaster.danger('Delete Failed', {
    //       description: 'Failed to Deleted User Tag',
    //     });
    //     console.log(err.response);
    //     return false;
    //   }
    // },
    // async editUserTag(payload, rootState) {
    //   /*
    //   payload = {body: object}
    //    */
    //   try {
    //     const res = await axios.patch(
    //       `${config.projects}/${rootState.dashboard.selectedProject.id}/customer-tags/${payload.id}`,
    //       { name: payload.name }
    //     );
    //     if (res.status === 200 && res.data.success) {
    //       const data = JSON.parse(
    //         JSON.stringify(rootState.crm.allUserTags)
    //       ).filter((e) => {
    //         if (e.id === payload.id) {
    //           e.name = payload.name;
    //         }
    //         return e;
    //       });
    //       dispatch.crm.updateStateData({
    //         key: 'allUserTags',
    //         value: data,
    //       });
    //       toaster.success('Updated Successfully', {
    //         description: 'Successfully Updated User Tag',
    //       });
    //       // ekhane fetch hobe customer meta
    //       return true;
    //     }
    //     return false;
    //   } catch (err) {
    //     console.log(err.response);
    //     toaster.danger('Update Failed', {
    //       description: 'Failed to Updated User Tag',
    //     });
    //     return false;
    //   }
    // },
    // async assignUserTag(payload, rootState) {
    //   /*
    //   payload = {customerId: num, body: object
    //    */
    //   try {
    //     let shouldFetch = true;
    //     const TagAlreadyExist =
    //       rootState.crm.customerMeta.tags.filter(
    //         (e) => e.id === payload.body.id
    //       ).length > 0;
    //     if (TagAlreadyExist && payload.body.action === 'add') {
    //       shouldFetch = false;
    //     }
    //     if (shouldFetch) {
    //       const res = await axios.post(
    //         `${config.customers}/${payload.customerId}/tag`,
    //         payload.body
    //       );
    //
    //       if (res.status === 200 && res.data.success) {
    //         dispatch.crm.updateUserTag({
    //           tag: { name: payload.body.name, id: payload.body.id },
    //           action: payload.body.action,
    //         });
    //         if (payload.body.action === 'add') {
    //           toaster.success('Successful', {
    //             description: `Tag ${payload.body.name} Assigned Successfully`,
    //           });
    //         } else {
    //           toaster.success('Deleted', {
    //             description: `Tag ${payload.body.name} Deleted Successfully`,
    //           });
    //         }
    //       }
    //     } else {
    //       toaster.warning('Already Exist', {
    //         description: `Tag ${payload.body.name} Already exist`,
    //       });
    //     }
    //   } catch (err) {
    //     console.log(err.response);
    //   }
    // },

    async fetchUserInformation(customerId) {
      /*
      payload = customerId
       */
      try {
        const res = await axios.get(
          `${config.customersCrm}/${customerId}/attributes`
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateCustomerAttributes(res.data.dataSource);
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async updateUserInformationAPI(customerId, rootState) {
      /*
      payload = customerId
       */
      try {
        let tempBody = rootState.crm.tempAttributes;
        let body = {
          full_name: tempBody.fixed.full_name,
          gender: tempBody.fixed.gender,
          timezone: tempBody.fixed.timezone,
          locale: tempBody.fixed.locale,
          phone: tempBody.fixed.phone,
          email: tempBody.fixed.email,
          language: tempBody.fixed.language,
          ...tempBody.variable,
        };
        const res = await axios.post(
          `${config.customersCrm}/${customerId}/attributes`,
          body
        );
        if (res.status === 200 && res.data.success) {
          toaster.success('Updated Successfully');
          dispatch.crm.updateCustomerAttributes(res.data.dataSource);
          return true;
        } else {
          toaster.danger('Failed to Update');
          return false;
        }
      } catch (err) {
        console.log(err.response);
        return false;
      }
    },
    async fetchMessengerChat(ticketId) {
      /*
      payload = ticketId
       */
      try {
        const res = await axios.get(
          `${config.crmTicket}/${ticketId}/messenger-chat`
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateStateData({
            key: 'chatData',
            value: res.data.dataSource,
          });
          dispatch.crm.updateStateData({
            key: 'ticketActions',
            value: res.data.actions,
          });
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async fetchFeedData(ticketId) {
      /*
      payload = ticketId
       */
      try {
        const res = await axios.get(
          `${config.crmTicket}/${ticketId}/feed-thread`
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateStateData({
            key: 'chatData',
            value: res.data.dataSource,
          });
          dispatch.crm.updateStateData({
            key: 'ticketActions',
            value: res.data.actions,
          });
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async sendMessage(payload, rootState) {
      /*
      payload = message,image,action
       */
      try {
        const res = await axios.post(
          `${config.crmTicket}/${rootState.crm.currentTicket.id}/messenger-chat`,
          {
            text: payload.message,
            image: payload.image,
            action: payload.action,
          }
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crm.addChatData({
            event: res.data.dataSource,
            adminId: rootState.auth.id,
          });
          dispatch.crm.updateLockedStatus({
            key: 'is_locked',
            value: true,
            id: rootState.crm.currentTicket.id,
          });
          dispatch.crm.updateBotAssign(false);
          dispatch.crm.updateLeftbarTicketAssign(res.data.dataSource);
          return true;
        } else {
          toaster.danger('Failed', { description: 'Failed to Post Message' });
          return false;
        }
      } catch (err) {
        if (!!err && err?.response?.status !== 200) {
          if (!!err.response?.data?.error) {
            toaster.danger('Failed', {
              description: err.response.data.error || 'Failed to Send Message',
            });
          }
        }
        return false;
      }
    },
    async sendMessageFeed(payload, rootState) {
      /*
      payload = message,image,action
       */
      try {
        const res = await axios.post(
          `${config.crmTicket}/${rootState.crm.currentTicket.id}/feed-thread`,
          {
            text: payload.message,
            image: payload.image,
            action: payload.action,
          }
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crm.addFeedReply([res.data.dataSource]);
          dispatch.crm.updateLockedStatus({
            key: 'is_locked',
            value: true,
            id: rootState.crm.currentTicket.id,
          });
          dispatch.crm.updateBotAssign(false);
          dispatch.crm.updateLeftbarTicketAssign(res.data.dataSource);
          return true;
        } else {
          toaster.danger('Failed', { description: 'Failed to Post Message' });
          return false;
        }
      } catch (err) {
        console.log(err.response);
        return false;
      }
    },
    async fetchAssignableAdmins(projectId, rootState) {
      try {
        const res = await axios.get(`${config.projects}/${projectId}/access`);
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateAssignAdmin(res.data.dataSource);
          dispatch.crm.updateNotificationInfo({
            data: res.data.dataSource,
            adminId: rootState.auth.id,
          });
        } else {
          dispatch.crm.updateAssignAdmin([]);
          dispatch.crm.updateNotificationInfo(null, -1);
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async editNotificationKey(payload, rootState) {
      /*
        payload = {
          key: string,
          value:boolean,
          projectId: number

        }
       */
      try {
        const res = await axios.patch(
          `${config.projects}/${payload.projectId}/access/${rootState.auth.id}`,
          { [payload.key]: payload.value }
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateNotificationKey(res.data.dataSource);
        } else {
          toaster.danger('Failed', {
            description: 'Failed to Change Notification Settings',
            duration: 2,
          });
          return false;
        }
      } catch (err) {
        console.log(err.response);
        toaster.danger('Failed', {
          description:
            err?.response?.data?.error ||
            'Failed to Change Notification Settings',
          duration: 2,
        });
        return false;
      }
    },

    async assignAdmin(payload) {
      /*
        payload = ticketId: number, currentTicket: number
       */
      try {
        let body = { agent_id: '', group_id: '' };
        payload.agents.forEach(
          (agent) => (body['agent_id'] = agent.id.toString())
        );
        payload.groups.forEach(
          (group) => (body['group_id'] = group.id.toString())
        );
        const res = await axios.post(
          `${config.crmTicket}/${payload.id}/action-assign`,
          body
        );
        if (res.status === 200 && res.data.success) {
          toaster.success('Success', {
            description: 'Assignment successful',
          });
          return true;
        } else {
          toaster.danger('Failed', {
            description: 'Failed to Assign ticket to This Agent/Group',
          });
          return false;
        }
      } catch (err) {
        toaster.danger('Failed', {
          description:
            err?.response?.data?.error ||
            'Failed to Assign ticket to This Agent/Group',
        });
        return false;
      }
    },
    async fetchAgentGroup(projectId) {
      try {
        const res = await axios.get(`${config.projects}/${projectId}/groups`);
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateAgentGroup(res.data.dataSource);
        } else {
          dispatch.crm.updateAgentGroup([]);
        }
      } catch (err) {
        console.log(err.response);
        dispatch.crm.updateAgentGroup([]);
      }
    },

    async fetchCannedResponse(projectId) {
      try {
        const res = await axios.get(
          `${config.crm}/${projectId}/canned-responses`
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateCannedResponses(res.data.dataSource);
        } else {
          dispatch.crm.updateCannedResponses([]);
        }
      } catch (err) {
        console.log(err.response);
        dispatch.crm.updateCannedResponses([]);
      }
    },

    async createCannedResponse(payload) {
      /*
        payload = {
          title: string,
          text: string,
          forTeam:boolean,
          teamId: number;
        }
       */
      try {
        const res = await axios.post(
          `${config.crm}/${payload.teamId}/canned-responses`,
          payload
        );
        if (res.status === 200 && res.data.success) {
          toaster.success('Successful', {
            description: 'Canned Response Created',
            duration: 2,
          });
          return true;
        } else {
          toaster.danger('Failed', {
            description: 'Canned Response Failed to Create',
            duration: 2,
          });
          return false;
        }
      } catch (err) {
        console.log(err.response);
        toaster.danger('Failed', {
          description:
            err?.response?.data?.error || 'Canned Response Failed to Create',
          duration: 2,
        });
        return false;
      }
    },

    async editCannedResponse(payload) {
      /*
        payload = {
          id: number
          title: string,
          text: string,
          teamId: number | undefined,
          fot_team: boolean.
        }
       */
      try {
        const res = await axios.patch(
          `${config.crm}/${payload.teamId}/canned-responses/${payload.id}`,
          {
            title: payload.title,
            text: payload.text,
            for_team: payload.for_team,
          }
        );
        if (res.status === 200 && res.data.success) {
          toaster.success('Successful', {
            description: 'Canned Response Updated',
            duration: 2,
          });
          return true;
        } else {
          toaster.danger('Failed', {
            description: 'Canned Response Failed to Update',
            duration: 2,
          });
          return false;
        }
      } catch (err) {
        console.log(err.response);
        toaster.danger('Failed', {
          description: 'Canned Response Failed to Update',
          duration: 2,
        });
        return false;
      }
    },

    async deleteCannedResponse(payload) {
      /*
        payload = {
          id: number
          teamId: number | undefined
        }
       */
      try {
        const res = await axios.delete(
          `${config.crm}/${payload.teamId}/canned-responses/${payload.id}`
        );
        if (res.status === 200 && res.data.success) {
          toaster.success('Successful', {
            description: 'Canned Response Deleted',
            duration: 2,
          });
          return true;
        } else {
          toaster.danger('Failed', {
            description: 'Canned Response Failed to Delete',
            duration: 2,
          });
          return false;
        }
      } catch (err) {
        console.log(err.response);
        toaster.danger('Failed', {
          description: 'Canned Response Failed to Delete',
          duration: 2,
        });
        return false;
      }
    },
    async updateFeedAction(payload, rootState) {
      /*
      action = 'hide || 'delete' || 'edit',
      commentId : string;
      text: string,
       */

      try {
        let body = {
          action: payload.action,
          comment_id: payload.commentId,
        };
        if (payload.action === 'edit') {
          body = { ...body, text: payload.text };
        }
        const res = await axios.post(
          `${config.crmTicket}/${rootState.crm.currentTicket.id}/feed-thread`,
          body
        );
        if (res.status === 200 && res.data.success) {
          toaster.success(payload.action.toUpperCase(), {
            description: 'Action was Successful',
          });
          if (payload.type === 'comment') {
            dispatch.crm.updateCommentStatus(payload.action);
          } else if (payload.type === 'reply') {
            if (payload.action === 'edit') {
              dispatch.crm.updateReplyStatus({
                ...payload,
                reply: res.data.dataSource,
              });
            } else {
              dispatch.crm.updateReplyStatus(payload);
            }
          }
          return true;
        } else {
          toaster.danger('Failed', {
            description: `Failed to ${payload.action} this Request`,
          });
          return false;
        }
      } catch (err) {
        console.log(err.response);
        toaster.danger('Failed', {
          description: `Failed to ${payload.action} this Request`,
        });
        return false;
      }
    },
    async fetchCRMCustomerOrder({ teamId, customerId, orderId }) {
      try {
        let paramsLocal = { alice_customer_id: customerId };
        if (!!orderId) {
          paramsLocal = { ...paramsLocal, order_id: orderId };
        }
        const res = await axios.get(
          `${config.marketplace}/projects/${teamId}/customer-order-list`,
          {
            params: paramsLocal,
          }
        );
        if (res.data.success) {
          dispatch.crm.updateEComOrderList(res.data.dataSource);
        } else {
          dispatch.crm.updateEComOrderList([]);
        }
      } catch (err) {
        console.log(err);
        dispatch.crm.updateEComOrderList([]);
      }
    },
  }),
};
