import React, { Component, Fragment } from 'react';
import DashboardHeader from '../../components/DashboardHeader';
import inventoryIconEdit from './assets/images/inventory-icon-edit.svg';
import DataLabFieldCard from './components/DataLabFieldCard';
import { Button, Pane } from 'evergreen-ui';
import { connect } from 'react-redux';
import LoaderLine from '../../components/loaders/LoaderLine';
import NoDataAvailable from '../builder/components/NoDataAvailable';
import HasPermission from '../../components/HasPermission';
import { checkPermission } from '../../utils';

class DataLabsKeyCreator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      writeAccess: 'write:data_lab',
    };
  }

  fetchInfo = async () => {
    await this.props.fetchDataLabKeyInfo(
      this.props.projectId,
      this.props.labId
    );
    this.setState({
      loading: false,
    });
  };

  checkIsEditable = (id) => {
    return id === this.props.currentEditId;
  };

  componentDidMount() {
    const hasWritePermission = checkPermission(
      this.props.permissionList,
      this.state.writeAccess
    );
    if (hasWritePermission) {
      this.fetchInfo();
    }
  }

  render() {
    return (
      <HasPermission shortCode={this.state.writeAccess}>
        <Fragment>
          <DashboardHeader
            title={`Update ${
              this.props.currentEditingLabData !== null
                ? this.props.currentEditingLabData.title
                : ''
            }`}
            icon={inventoryIconEdit}
            subtitle={
              'Where you will update information about the keys for data lab'
            }
          />
          <Pane display='flex' justifyContent='flex-end'>
            <Button
              onClick={() => {
                this.props.updateCreateField();
              }}
              className='button-alice__gradient'
              height={36}
              iconBefore={'add'}
              marginRight={60}
              marginBottom={30}
            >
              Add a Key
            </Button>
          </Pane>
          {(this.state.loading || this.props.loading) && (
            <div className='flex-justify-middle'>
              <LoaderLine />
            </div>
          )}
          {!this.state.loading && !this.props.loading && (
            <div className='data-lab__fieldcreate'>
              {!!this.props.currentEditingLabData &&
                this.props.currentEditingLabData.lab_fields.length === 0 && (
                  <NoDataAvailable
                    message={
                      'No Key Has Been Created !! Click Top Right Button to Create one'
                    }
                    height={'400px'}
                    width={'25%'}
                  />
                )}
              {!!this.props.currentEditingLabData &&
                this.props.currentEditingLabData.lab_fields.length > 0 &&
                this.props.currentEditingLabData.lab_fields.map((elem, i) => (
                  <DataLabFieldCard
                    key={i}
                    id={elem.id}
                    labId={this.props.labId}
                    checkIsEditable={() => this.checkIsEditable(elem.id)}
                    name={elem.name}
                    type={elem.type}
                    placeholder={elem.placeholder}
                    required={elem.is_required}
                    minLength={elem.min_length}
                    maxLength={elem.max_length}
                    hidden={elem.is_hidden}
                    savedStatus={elem.is_saved}
                    options={elem.options}
                    updateEditKeyId={this.props.updateEditKeyId}
                    updateKeyChange={this.props.updateKeyChange}
                    updateDataLabField={this.props.updateDataLabField}
                    isSaveLoading={
                      this.props.saveLoading || this.props.createLoading
                    }
                    handleCancel={() => {
                      this.fetchInfo();
                      this.props.updateEditKeyId(0);
                    }}
                    handleDelete={this.props.deleteDataLabField}
                    handleCreate={this.props.createDataLabField}
                  />
                ))}
            </div>
          )}
        </Fragment>
      </HasPermission>
    );
  }
}

const mapState = (state) => ({
  currentEditingLabData: state.dataLab.currentEditingLabData,
  currentEditId: state.dataLab.currentEditId,
  loading: state.loading.effects.dataLab.fetchDataLabKeyInfo,
  saveLoading: state.loading.effects.dataLab.updateDataLabField,
  createLoading: state.loading.effects.dataLab.createDataLabField,
  permissionList: state.dashboard.permission?.list || [],
});

const mapDispatch = (dispatch) => ({
  fetchDataLabKeyInfo: (projectId, labId) =>
    dispatch.dataLab.fetchDataLabKeyInfo({ projectId, labId }),
  updateEditKeyId: (fieldId) => dispatch.dataLab.updateEditKeyId(fieldId),
  updateKeyChange: (fieldId, keyName, value) =>
    dispatch.dataLab.updateKeyChange({ fieldId, keyName, value }),
  updateDataLabField: (labId, fieldId, data) =>
    dispatch.dataLab.updateDataLabField({ labId, fieldId, data }),
  updateCreateField: () => dispatch.dataLab.updateCreateField(),
  createDataLabField: (labId, data) =>
    dispatch.dataLab.createDataLabField({ labId, data }),
  deleteDataLabField: (labId, fieldId) =>
    dispatch.dataLab.deleteDataLabField({ labId, fieldId }),
});

const DataLabsKeyCreatorContainer = connect(
  mapState,
  mapDispatch
)(DataLabsKeyCreator);

export default DataLabsKeyCreatorContainer;
