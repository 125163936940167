import React from 'react';

const PopupBasicContent = () => {
  return (
    <p className='button-popup__hint'>
      <strong>Hint: </strong>
      <span>Basic is where you don't want to do anything else.</span>
    </p>
  );
};

export default PopupBasicContent;
