import React from 'react';
import noDataFound from './../../../assets/images/noDataFound3.svg';

interface props {
    title: string;
}

const NoDataFragment: React.FC<props> = ({title}) => {
    return <div className='no-data__container'>
        <img src={noDataFound} width={'35%'} alt='no data'/>
        <p>{title}</p>
    </div>
}
;

export default NoDataFragment;
