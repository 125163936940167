import React from 'react';
import { TextInputField, Label, Textarea,Combobox } from 'evergreen-ui';
import EditModalSmallHeader from './EditModalSmallHeader';
import SliderNLP from "./SliderNLP";

interface props {
    data:any;
    updateEditModalInfo: (key:string, value: any) => void;
}

const ViberEditModal: React.FC<props> = ({data, updateEditModalInfo}) => {

  return (
    <div className='channel-edit-modal__container-platform'>
      <div className='flex flex-child-1'>
        <TextInputField
          tabIndex={1}
          label='Title'
          required
          className='rd-input-style'
          placeholder='Your Channel Title'
          inputHeight={40}
          value={data.title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateEditModalInfo('title', e.target.value)
          }
        />
        <TextInputField
          label='Account Name'
          required
          marginLeft={20}
          className='rd-input-style'
          placeholder='Your Channel Link'
          inputHeight={40}
          value={data.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateEditModalInfo('name', e.target.value)
          }
        />
      </div>
      <Label marginBottom={4} display='block'>
        Description
      </Label>
      <Textarea
        className='rd-input-style'
        placeholder='Type Here...'
        width={'48%'}
        value={data.description}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateEditModalInfo('description', e.target.value)
        }
      />
      <br />
      <br />
      <div className='primary-hr' />
      <EditModalSmallHeader
        title='NLP Settings'
        subtitle='Provide Natural Language Processing for rules training'
        link={{ url: 'https://docs.getalice.ai/', text: 'Know More' }}
      />
        <div className='flex flex-child-1'>
            <Combobox
                selectedItem={data.nlp_engine}
                items={['wit']}
                disabled={false}
                inputProps={{ disabled: true }}
                width={'100%'}
                height={40}
                className={'rd-input-style-combobox'}
                onChange={(selected) => {
                    updateEditModalInfo('nlp_engine', selected);
                }}
            />
        </div>
        <br />
        <div className='flex flex-child-1'>
            <TextInputField
                label='App ID'
                required
                className='rd-input-style'
                placeholder='Write here...'
                inputHeight={40}
                value={data.nlp_info.app_id || ''}
                onFocus={() => updateEditModalInfo('nlp_engine', 'wit')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    updateEditModalInfo('nlp_info', {
                        ...data.nlp_info,
                        app_id: e.target.value,
                    });
                }}
            />
            <TextInputField
                label='Token'
                required
                marginLeft={20}
                onFocus={() => updateEditModalInfo('nlp_engine', 'wit')}
                className='rd-input-style'
                placeholder='Write here...'
                inputHeight={40}
                value={data.nlp_info.token || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    updateEditModalInfo('nlp_info', {
                        ...data.nlp_info,
                        token: e.target.value,
                    });
                }}
            />
        </div>
        <br />
        <div className='w-100p'>
            <SliderNLP data={data} updateEditModalInfo={updateEditModalInfo}/>
        </div>
    </div>
  );
};

export default ViberEditModal;
