import React from 'react';
import { Alert } from 'evergreen-ui';
import { Link } from '@reach/router';

interface Prop {
  platformSettingsLink: string;
  facebookTestUrl: string;
  userId: string;
  selectedPlatformId: string | number;
}

const PlatformConnectAlert: React.FC<Prop> = ({
  platformSettingsLink,
  facebookTestUrl,
  userId,
  selectedPlatformId,
}) => {
  return (
    <Alert
      intent='warning'
      title='Platform is not connected yet.'
      marginBottom={32}
    >
      You do not have any platform connected. Please click{' '}
      <Link to={platformSettingsLink}>this link</Link> to connect the platform.
      And click{' '}
      <a
        href={`${facebookTestUrl}?ref=${userId}_${selectedPlatformId}`}
        target='_blank'
        rel='noopener noreferrer'
      >
        this link
      </a>{' '}
      to test the bot.
    </Alert>
  );
};

export default PlatformConnectAlert;
