import React from 'react';
import PropTypes from 'prop-types';

const SequenceMeta = ({ title, subtitle }) => {
  return (
    <div className='builder-main__sequence-meta'>
      <h5>{title}</h5>
      <p>{subtitle}</p>
    </div>
  );
};

SequenceMeta.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default SequenceMeta;
