const devUrl = 'http://localhost:8000';
const productionUrl = 'https://live-v3.getalice.ai';
const stagingUrl = 'https://v3stage-raphael.getalice.ai';
const status = 'staging';
export const apiURL =
  status === 'production'
    ? productionUrl
    : status === 'staging'
    ? stagingUrl
    : devUrl;

export const facebookTestUrl =
  status === 'production'
    ? 'https://m.me/108768877336530'
    : 'https://m.me/104980237682564';

export const env = status;
export const versionNumber = 'v1.4.0'; //for force logout change version number

const config = () => {
  return {
    login: `${apiURL}/api/accounts/login`,
    checkDuplicateEmail: `${apiURL}/api/accounts/check-duplicate-email`,
    register: `${apiURL}/api/accounts/register`,
    resendRegisterVerifyLink: `${apiURL}/api/accounts/resend-verification-mail`,
    resetRequest: `${apiURL}/api/accounts/password-reset/request`,
    resetVerify: `${apiURL}/api/accounts/password-reset/verify`,
    registerVerify: `${apiURL}/api/accounts/verify`,
    refresh: `${apiURL}/api/accounts/refresh`,
    projects: `${apiURL}/api/bots/projects`,
    info: `${apiURL}/api/accounts/info`,
    platform: `${apiURL}/api/bots/platform`,
    sequence: `${apiURL}/api/bots/sequence`,
    image: `${apiURL}/api/bots/upload/image`,
    file: `${apiURL}/api/bots/upload/document`,
    labs: `${apiURL}/api/bots/labs`,
    fbConnect: `${apiURL}/api/accounts/facebook`,
    customers: `${apiURL}/api/bots/customers`,
    customersCrm: `${apiURL}/api/crm/customers`,
    analytics: `${apiURL}/api/analytics/projects`,
    rules: `${apiURL}/api/bots/rules`,
    rule: `${apiURL}/api/bots/rule`,
    payment: `${apiURL}/api/billing/payment-method`,
    cards: `${apiURL}/api/billing/cards`,
    stripeKey: `${apiURL}/api/billing/public-key`,
    plans: `${apiURL}/api/billing/plans`,
    tiers: `${apiURL}/api/billing/tiers`,
    trial: `${apiURL}/api/billing/trial`,
    histories: `${apiURL}/api/billing/histories`,
    subscriptions: `${apiURL}/api/billing/subscriptions`,
    refund: `${apiURL}/api/billing/refund-request`,
    billingHistory: `${apiURL}/api/billing/histories`,
    crm: `${apiURL}/api/crm/projects`,
    crmTicket: `${apiURL}/api/crm/tickets`,
    rulesInfo: `${apiURL}/api/bots/rule-info`,
    marketplace: `${apiURL}/api/ecommerce`,
    facebookAppId: process.env.REACT_APP_LOGIN_APP_ID,
    token:
      status === 'production'
        ? 'KjpwH7qmDNaO7691Eb1kjyVAf8EkQHsY'
        : 'SacredToken',
    webChatSdkUrl:
      status === 'production'
        ? 'https://webchat.getalice.ai'
        : 'https://webchat-stage.getalice.ai',
  };
};

export default config();
