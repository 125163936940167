import React, { Component } from 'react';
import { connect } from 'react-redux';
import HasPermission from '../../../components/HasPermission';
import PlanCard from '../component/billing/PlanCard';

class BillingPlanSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      readAccess: 'read:settings',
      writeAccess: 'write:settings',
    };
  }

  async componentDidMount() {
    await this.props.fetchUserInfo();
    await this.props.fetchPlans();
  }

  handleSubscriptionUpdate = async () => {
    await this.props.fetchProject();
    const updatedProject = this.props.projects.filter(
      (project) => this.props.selectedProject.id === project.id
    )[0];
    this.props.updateSelectedProject(updatedProject);
  };

  render() {
    const cardInfo = this.props.userInfo ? this.props.userInfo.card_info : null;

    const selectedProject = this.props.selectedProject;
    const currentPlanType = selectedProject.subscription_plan.plan_type;

    return (
      <HasPermission shortCode={this.state.readAccess} isSettingsView>
        <div className='billing-settings-container'>
          <p className='channel-settings__header'>Subscription Packages</p>
          <p className='channel-settings__description'>
            Manage your subscription here.
          </p>
          <br /> <br />
          <div className='mr-15px'>
            {this.props.userInfo &&
              this.props.plans.length > 0 &&
              this.props.plans.map((plan) => {
                const isActive = selectedProject
                  ? selectedProject.subscription_plan?.id === plan.id
                  : false;
                return (
                  <PlanCard
                    key={plan.id}
                    planId={plan.id}
                    name={plan.name}
                    users={plan.max_mau}
                    price={plan.amount}
                    currentPlanPrice={
                      selectedProject
                        ? selectedProject.subscription_plan?.amount
                        : '0.00'
                    }
                    createSubscription={this.props.createSubscription}
                    handleSubscriptionUpdate={this.handleSubscriptionUpdate}
                    selectedProject={selectedProject}
                    date={
                      isActive
                        ? selectedProject.last_billing_date
                        : 'Not Available'
                    }
                    isActive={isActive}
                    isTrial={this.props.userInfo.trial_remaining > 0}
                    hasCard={!!cardInfo}
                  />
                );
              })}
            {this.props.userInfo && (
              <PlanCard
                key={-1}
                planId={-1}
                name={'Enterprise'}
                users={-1}
                price={-1}
                currentPlanPrice={
                  selectedProject
                    ? selectedProject.subscription_plan?.amount
                    : '0.00'
                }
                createSubscription={this.props.createSubscription}
                handleSubscriptionUpdate={this.handleSubscriptionUpdate}
                selectedProject={selectedProject}
                date={
                  currentPlanType === 'enterprise'
                    ? selectedProject.last_billing_date
                    : 'Not Available'
                }
                isActive={currentPlanType === 'enterprise'}
                isTrial={this.props.userInfo.trial_remaining > 0}
                hasCard={!!cardInfo}
              />
            )}
          </div>
        </div>
      </HasPermission>
    );
  }
}

const mapState = (state) => ({
  projects: state.dashboard.projects,
  selectedProject: state.dashboard.selectedProject,
  plans: state.billing.plans,
  userInfo: state.settings.userInfo,
  permissionList: state.dashboard.permission.list || [],
});

const mapDispatch = (dispatch) => ({
  fetchPlans: () => dispatch.billing.fetchPlans(),
  fetchProject: () => dispatch.dashboard.fetchProject(),
  updateSelectedProject: (payload) =>
    dispatch.dashboard.updateSelectedProject(payload),
  fetchUserInfo: () => dispatch.settings.fetchUserInfo(),
  createSubscription: (payload) => dispatch.billing.createSubscription(payload),
  createPaymentMethod: (payload) =>
    dispatch.billing.createPaymentMethod(payload),
  manageCard: (payload) => dispatch.billing.manageCard(payload),
});

const BillingPlanSettingsContainer = connect(
  mapState,
  mapDispatch
)(BillingPlanSettings);

export default BillingPlanSettingsContainer;
