import React from 'react';
import ChannelCard from './../component/channel/ChannelCard';
import { connect } from 'react-redux';
import { Spinner } from 'evergreen-ui';
import './../assets/scss/channel-settings.scss';
import '@reach/slider/styles.css';

interface props {
  fetchChannel: (projectId: number) => void;
  channelList: any[];
  selectedProject: any;
  loadingChannelList: boolean;
  channelInitLoading: boolean;
  channelInit: (projectId: number, body: object) => Promise<boolean>;
  disconnectChannel: (channelId: number) => Promise<boolean>;
}

const ChannelSettings: React.FC<props> = ({
  fetchChannel,
  channelList,
  selectedProject,
  loadingChannelList,
  channelInitLoading,
  channelInit,
  disconnectChannel,
}) => {
  const [isMounted, setIsMounted] = React.useState(false);
  React.useEffect(() => {
    fetchChannel(selectedProject.id);
    setIsMounted(true);
    // eslint-disable-next-line
  }, []);

  return (
    <div className='channel-settings__container'>
      <p className='channel-settings__header'>Channels Overview</p>
      <p className='channel-settings__description'>
        All channels from Support Team.
      </p>
      <br />
      {(!isMounted || loadingChannelList) && (
        <div className='full-loader'>
          <Spinner />
        </div>
      )}
      {isMounted && !loadingChannelList && (
        <>
          <ChannelCard
            type={'facebook_messenger'}
            channelList={channelList.filter(
              (channel: any) => channel.type === 'facebook_messenger'
            )}
            channelInit={(body: object) =>
              channelInit(selectedProject.id, body)
            }
            channelInitLoading={channelInitLoading}
            disconnectChannel={disconnectChannel}
          />
          <ChannelCard
            type={'facebook_feed'}
            channelList={channelList.filter(
              (channel: any) => channel.type === 'facebook_feed'
            )}
            channelInit={(body: object) =>
              channelInit(selectedProject.id, body)
            }
            channelInitLoading={channelInitLoading}
            disconnectChannel={disconnectChannel}
          />
          <ChannelCard
            type={'webchat'}
            channelList={channelList.filter(
              (channel: any) => channel.type === 'webchat'
            )}
            channelInit={(body: object) =>
              channelInit(selectedProject.id, body)
            }
            channelInitLoading={channelInitLoading}
            disconnectChannel={disconnectChannel}
          />
          <ChannelCard
            type={'app_messenger'}
            channelList={channelList.filter(
              (channel: any) => channel.type === 'app_messenger'
            )}
            channelInit={(body: object) =>
              channelInit(selectedProject.id, body)
            }
            channelInitLoading={channelInitLoading}
            disconnectChannel={disconnectChannel}
          />
            <ChannelCard
                type={'viber_messenger'}
                channelList={channelList.filter(
                    (channel: any) => channel.type === 'viber_messenger'
                )}
                channelInit={(body: object) =>
                    channelInit(selectedProject.id, body)
                }
                channelInitLoading={channelInitLoading}
                disconnectChannel={disconnectChannel}
            />
            <ChannelCard
                type={"whatsapp_messenger"}
                channelList={channelList.filter(
                    (channel: any) => channel.type === 'whatsapp_messenger'
                )}
                channelInit={(body: object) =>
                    channelInit(selectedProject.id, body)
                }
                channelInitLoading={channelInitLoading}
                disconnectChannel={disconnectChannel}
            />
        </>
      )}
    </div>
  );
};

const mapState = (state: any) => ({
  channelList: state.channel.channelList,
  selectedProject: state.dashboard.selectedProject,
  loadingChannelList: state.loading.effects.channel.fetchChannel,
  channelInitLoading: state.loading.effects.channel.channelInit,
});

const mapDispatch = (dispatch: any) => ({
  fetchChannel: (projectId: number) => dispatch.channel.fetchChannel(projectId),
  channelInit: (projectId: number, body: object) =>
    dispatch.channel.channelInit({ projectId, body }),
  disconnectChannel: (channelId: number) =>
    dispatch.channel.disconnectChannel(channelId),
});

const ChannelSettingsContainer = connect(
  mapState,
  mapDispatch
)(ChannelSettings);

export default ChannelSettingsContainer;
