import React from 'react';
import { Pane, Position, Popover, Menu, IconButton } from 'evergreen-ui';
import inventoryCreateIcon from './../assets/images/inventory-create-icon.svg';
import formIcon from './../assets/images/form-icon.svg';
import PropTypes from 'prop-types';
import { shortenNumber } from '../../../utils';
import moment from 'moment';

const DataLabCard = ({
  title,
  subTitle,
  type,
  handleKeyEdit,
  handleInfoEdit,
  handleDeleteLab,
  handleClickAction,
  keyCount,
  entryCount,
  createdAt,
  clickCount,
  hasWriteAccess,
}) => {
  return (
    <Pane className='data-lab__wrapper' display='flex'>
      {hasWriteAccess && (
        <Popover
          position={Position.BOTTOM_LEFT}
          content={
            <Menu>
              <Menu.Group>
                <Menu.Item onSelect={handleInfoEdit} icon='edit'>
                  Edit Info
                </Menu.Item>
                <Menu.Item
                  onSelect={handleKeyEdit}
                  icon='manually-entered-data'
                >
                  Edit Keys
                </Menu.Item>
                <Menu.Item
                  onSelect={handleDeleteLab}
                  icon='trash'
                  intent='danger'
                >
                  Delete
                </Menu.Item>
              </Menu.Group>
            </Menu>
          }
        >
          <IconButton
            style={{ position: 'absolute', right: 0 }}
            marginBottom={16}
            appearance='minimal'
            icon='more'
          />
        </Popover>
      )}
      <div className='data-lab__wrapper-card' onClick={handleClickAction}>
        <img
          src={type === 'form' ? formIcon : inventoryCreateIcon}
          alt='icons'
        />
        <div className='data-lab__wrapper-card-title'>
          <p>
            {title.length === 0
              ? 'No Title Available'
              : title.length > 15
              ? title.substring(0, 15) + '...'
              : title}
          </p>
        </div>
        <div className='data-lab__wrapper-card-subtitle'>
          <p>
            {subTitle.length === 0
              ? 'No Description Provided'
              : subTitle.length > 50
              ? subTitle.substring(0, 50) + '...'
              : subTitle}
          </p>
        </div>
        {type === 'form' && (
          <div className='data-lab__stat-container'>
            <div>
              {/*<p className='title'>Clicks</p>*/}
              <p className='data'>{shortenNumber(clickCount)}</p>
            </div>
            <div>
              {/*<p className='title'>Submitted</p>*/}
              <p className='data'>{shortenNumber(entryCount)}</p>
            </div>
            <div>
              {/*<p className='title'>Keys</p>*/}
              <p className='data'>{shortenNumber(keyCount)}</p>
            </div>
          </div>
        )}
        {type === 'inventory' && (
          <div className='data-lab__stat-container'>
            <div>
              {/*<p className='title'>Entries</p>*/}
              <p className='data'>{shortenNumber(entryCount)}</p>
            </div>
            <div>
              {/*<p className='title'>Submitted</p>*/}
              <p className='data'>{shortenNumber(entryCount)}</p>
            </div>
            <div>
              {/*<p className='title'>Keys</p>*/}
              <p className='data'>{shortenNumber(keyCount)}</p>
            </div>
          </div>
        )}
        <div className='data-lab__wrapper-card-date'>
          <p className='created-date'>
            {moment(createdAt * 1000).format('lll')}
          </p>
        </div>
      </div>
    </Pane>
  );
};
DataLabCard.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  handleKeyEdit: PropTypes.func,
  handleInfoEdit: PropTypes.func,
  handleDeleteLab: PropTypes.func,
  handleClickAction: PropTypes.func,
  keyCount: PropTypes.number,
  entryCount: PropTypes.number,
  clickCount: PropTypes.number,
  hasWriteAccess: PropTypes.bool,
};

DataLabCard.defaultProps = {
  keyCount: null,
  entryCount: null,
  clickCount: null,
  hasWriteAccess: false,
};

export default DataLabCard;
