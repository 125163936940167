import React, {useState} from 'react';
import {
  Popover,
  Pane,
  Tooltip,
  Dialog,
  Textarea,
  // toaster,
  Button,
  SideSheet, toaster,
} from 'evergreen-ui';
import {handleImageGalleryUpload} from '../../../utils';
// import ImageUploadField from './../../../components/ImageUploadField';
import Picker from 'emoji-picker-react';
import emojiIcon from './../assets/icon/emojiIcon.svg';
import imageIcon from './../assets/icon/imageIcon.svg';
import fixedReplyIcon from './../assets/icon/fixedReplyIcon.svg';
import notesIcon from './../assets/icon/notesIcon.svg';
import sendIcon from './../assets/icon/sendIconforBottomBar.svg';
import CannedResponse from './CannedResponse';
import CRMUploadPreview from "./Fragments/CRMUploadPreview";
import CRMImageGallery from "./Fragments/CRMImageGallery";

interface props {
  sendNote: (message: any, image: any) => void;
  showNote: boolean;
  hasUploadImage: boolean;
  sendMessage: (message: any, image: any) => void;
  handleEmojiChange: (string: any) => void;
  handleSend: () => void;
  messageSendingLoader: boolean;
  isTicketLoaded: boolean;
  hasWriteAccess: boolean;
  selectedProject: number;
  galleryImages: any[];
  updateGalleryImagesUpload: (data: any) => void;
  galleryImageLoader:boolean;
  getMoreGalleryImages: () => void;
  galleryTotal: number;
}

const CRMReplyOptions: React.FC<props> = (
  {
    sendNote,
    showNote,
    hasUploadImage,
    sendMessage,
    handleEmojiChange,
    handleSend,
    messageSendingLoader,
    isTicketLoaded,
    hasWriteAccess,
    selectedProject,
    galleryImages,
    updateGalleryImagesUpload,
    galleryImageLoader,
    getMoreGalleryImages,
    galleryTotal
  }) => {
  const [isNoteModalShown, setIsNoteModalShown] = React.useState(false);
  const [isCannedShown, setIsCannedShown] = useState(false);
  const [note, setNote] = React.useState('');
  const [isImageUploadModalShown, setIsImageUploadModalShown] = React.useState(
    false
  );
  const [previewImage, setPreviewImage] = React.useState('');
  const [previewFile, setPreviewFile] = React.useState<any>();

  return (
    <div className='reply-content'>
      <div>
        <div className='reply-content__type'>
          <Tooltip content='Add a Note'>
            <Button
              className='reply-icon-style-fix'
              disabled={!showNote || !isTicketLoaded}
              onClick={() => setIsNoteModalShown(true)}
            >
              <img src={notesIcon} width={16} alt={'note-icon'}/>
            </Button>
          </Tooltip>
          <Dialog
            isShown={isNoteModalShown}
            title='Add Note'
            onConfirm={() => {
              sendNote(note, null);
              setNote('');
              setIsNoteModalShown(false);
            }}
            confirmLabel='Save Note'
            intent={'success'}
            preventBodyScrolling
            shouldCloseOnOverlayClick={false}
          >
            <Textarea
              placeholder={'Type here...'}
              value={note}
              onChange={(e: any) => {
                setNote(e.target.value);
              }}
              className={'note-container__modal-textarea'}
              rows={6}
            />
          </Dialog>
        </div>
        <div className='reply-content__options'>
          <Popover
            content={({close}) => (
              <Pane>
                <Picker
                  onEmojiClick={(event: any, emojiElem: any) => {
                    let target: any = document.getElementById('reply-input');

                    if (target.hasOwnProperty('setRangeText')) {
                      //if setRangeText function is supported by current browser
                      target.setRangeText(emojiElem.emoji);
                      handleEmojiChange(target.value);
                    } else {
                      target.focus();
                      document.execCommand(
                        'insertText',
                        false /*no UI*/,
                        emojiElem.emoji
                      );
                      handleEmojiChange(target.value);
                    }
                    close();
                  }}
                  disableAutoFocus={true}
                  disableSkinTonePicker={true}
                  preload={true}
                />
              </Pane>
            )}
          >
            <Tooltip content='Add a Emoji'>
              <Button
                disabled={!isTicketLoaded}
                className='reply-icon-style-fix'>
                <img src={emojiIcon} width={16} alt='emoji'/>
              </Button>
            </Tooltip>
          </Popover>
          {hasUploadImage && (
            <>
              <Dialog
                width={600}
                contentContainerProps={{style: {minHeight: 'fit-content'}}}
                isShown={isImageUploadModalShown}
                hasFooter={false}
                hasHeader={false}
                intent={'success'}
                preventBodyScrolling
                shouldCloseOnOverlayClick={false}
              >
                <>
                  {!!previewImage &&
                  <CRMUploadPreview
                    handleClear={() => {
                      setPreviewImage('');
                      setPreviewFile('');
                    }}
                    handleUpload={async () =>
                      await handleImageGalleryUpload(previewFile, selectedProject,
                        (data: any) => {
                          updateGalleryImagesUpload([data]);
                          toaster.success('Uploaded Successfully');
                          setPreviewImage('');
                          setPreviewFile('');
                        })
                    }
                    preview={previewImage}
                    hasWriteAccess={hasWriteAccess}
                    closeModal={() => {
                      setIsImageUploadModalShown(false);
                      setPreviewImage('');
                      setPreviewFile('');
                    }}
                    previewFile={previewFile}
                  />}
                  {!previewImage &&
                  <CRMImageGallery
                    galleryTotal={galleryTotal}
                    hasWriteAccess={hasWriteAccess}
                    messageSendingLoader={messageSendingLoader}
                    handleDrop={(image: any) => {
                      setPreviewImage(URL.createObjectURL(image));
                      setPreviewFile(image);
                    }}
                    galleryImageLoader={galleryImageLoader}
                    sendMessage={sendMessage}
                    galleryImages={galleryImages}
                    getMoreGalleryImages={getMoreGalleryImages}
                    previewImage={previewImage}
                    closeModal={() => {
                      setIsImageUploadModalShown(false);
                      setPreviewImage('');
                      setPreviewFile('');
                    }}
                  />}
                </>
              </Dialog>
              <Tooltip content='Upload Image'>
                <Button
                  className='reply-icon-style-fix'
                  disabled={!isTicketLoaded}
                  onClick={() =>
                    setIsImageUploadModalShown(!isImageUploadModalShown)
                  }
                >
                  <img src={imageIcon} width={16} alt={'attachment-icon'}/>
                </Button>
              </Tooltip>
            </>
          )}

          <Tooltip content='Canned Response'>
            <Button
              className='reply-icon-style-fix'
              disabled={!isTicketLoaded}
              onClick={() => setIsCannedShown(true)}
            >
              <img src={fixedReplyIcon} width={16} alt='fixed-reply-icon'/>
            </Button>
          </Tooltip>

          <SideSheet
            width={800}
            isShown={isCannedShown}
            onCloseComplete={() => setIsCannedShown(false)}
          >
            <CannedResponse/>
          </SideSheet>
        </div>
      </div>
      <Button
        isLoading={messageSendingLoader}
        className='reply-content__sendButton'
        height={26}
        onClick={handleSend}
      >
        {!messageSendingLoader && (
          <span>
            <img src={sendIcon} alt='sendIcon'/>
            &nbsp;
          </span>
        )}
        <span>Send</span>
      </Button>
    </div>
  );
};

export default CRMReplyOptions;
