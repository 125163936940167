import React, { Component } from 'react';
import { connect } from 'react-redux';
import './assets/styles/auth.scss';
import { toaster } from 'evergreen-ui';
import logoBlack from './assets/images/logo/dark-alice-logo-full.svg';
import AuthHeader from './components/AuthHeader';
import { authData } from '../../content';
import AlreadyVerifiedMail from './components/AlreadyVerifiedMail';
import ResendVerificationMail from './components/ResendVerificationMail';
// import {useTimer} from '../../utils'

class ResendAccountVerificationMail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verifyCode: '',
      isTimeout: false,
      loading: false,
    };
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };
  clearAlreadyVerifiedProps = () => {
    this.props.updateAlreadyVerified(false);
  };
  handleResendVerificationLink = () => {
    this.setState({ loading: true });
    this.props
      .resendRegisterVerifyLink({ email: this.props.auth.email })
      .then((success) => {
        if (this.props.auth.already_verified) {
          console.log(success);
        } else if (success) {
          toaster.success('Verification link successfully sent to your email.');
        } else {
          this.setState({ loading: false, isTimeout: true });
        }
      });
  };

  render() {
    const isAlreadyVerified = this.props.auth.already_verified;
    return (
      <div className='auth-container'>
        <div className='header-container'>
          <div>
            <a href='/'>
              <img className='logo-dark' src={logoBlack} alt='alice logo' />
            </a>
          </div>
          <div>
            <AuthHeader
              linkInfo={authData.contactInfo.text}
              link={authData.contactInfo.link}
              linkText={authData.contactInfo.linkTitle}
            />
          </div>
        </div>
        {isAlreadyVerified ? (
          <AlreadyVerifiedMail
            clearAlreadyVerifiedProps={this.clearAlreadyVerifiedProps}
          />
        ) : (
          <ResendVerificationMail
            isTimeout={this.state.isTimeout}
            email={this.props.auth.email}
            handleSubmit={this.handleResendVerificationLink}
          />
        )}
      </div>
    );
  }
}

const mapState = (state) => ({
  auth: state.auth,
});

const mapDispatch = (dispatch) => ({
  resendRegisterVerifyLink: (payload) =>
    dispatch.auth.resendRegisterVerifyLink(payload),
  updateAlreadyVerified: (payload) =>
    dispatch.auth.updateAlreadyVerified(payload),
});

const ReSendAccountVerificationLink = connect(
  mapState,
  mapDispatch
)(ResendAccountVerificationMail);

export default ReSendAccountVerificationLink;
