import React from 'react';
import Message from '../../../components/elements/redesign/Message';
import MessageAction from '../../../components/elements/redesign/MessageAction';

const getMessage = (planState: string): any => {
  switch (planState) {
    case 'free-new_subscriber':
      return {
        title: 'Message!',
        message:
          "You are now subscribed to Alice's Free Package. To enjoy more features, you can upgrade to startup or start a trial.",
      };

    case 'free-reaching_limit':
      return {
        title: 'Warning!',
        message:
          "Your package will soon reach it's limit. Before you run out of space, upgrade to startup or start a trial.",
      };

    case 'free-nearly_reached_limit':
      return {
        title: 'Action Needed!',
        message:
          "Your package has nearly reached it's limit. Before you run out of space, upgrade to startup or start a trial.",
      };

    case 'free-reached_limit':
    case 'free-exceeded_limit':
      return {
        title: 'Action Needed!',
        message:
          "Your package has reached it's limit! Before your channels are disconnected, upgrade to startup or start a trial.",
      };

    case 'free-disconnection':
      return {
        title: 'Action Needed!',
        message: `All channels have been disconnected. Please wait until your Billing cycle is reset in the scheduled date.`,
      };

    case 'startup-new_subscriber':
      return {
        title: 'Message!',
        message:
          "You are now subscribed to Alice's Startup Package. To enjoy more features, you can upgrade to business.",
      };

    case 'startup-reaching_limit':
      return {
        title: 'Warning!',
        message:
          "Your package will soon reach it's limit. Before you run out of space, upgrade to business.",
      };

    case 'startup-nearly_reached_limit':
      return {
        title: 'Action Needed!',
        message:
          "Your package has nearly reached it's limit. Before you run out of space, upgrade to business.",
      };

    case 'startup-reached_limit':
    case 'startup-exceeded_limit':
      return {
        title: 'Action Needed!',
        message:
          "Your package has reached it's limit! Before your channels are disconnected, upgrade to business.",
      };

    case 'business-new_subscriber':
      return {
        title: 'Message!',
        message:
          "You are now subscribed to Alice's Business Package. To enjoy more features, you can upgrade to enterprise.",
      };

    case 'business-reaching_limit':
      return {
        title: 'Warning!',
        message:
          "Your package will soon reach it's limit. Before you run out of space, upgrade to enterprise.",
      };

    case 'business-nearly_reached_limit':
      return {
        title: 'Action Needed!',
        message:
          "Your package has nearly reached it's limit. Before you run out of space, upgrade to enterprise.",
      };

    case 'business-reached_limit':
    case 'business-exceeded_limit':
      return {
        title: 'Action Needed!',
        message:
          "Your package has reached it's limit! Before your channels are disconnected, upgrade to enterprise.",
      };

    case 'enterprise-new_subscriber':
      return {
        title: 'Message!',
        message: "You are now subscribed to Alice's Enterprise Package. ",
      };

    case 'enterprise-reaching_limit':
      return {
        title: 'Warning!',
        message:
          "Your package will soon reach it's limit. To enjoy Alice's service without interruption, you can contact us to recharge your credit balance!",
      };

    case 'enterprise-nearly_reached_limit':
      return {
        title: 'Action Needed!',
        message:
          "Your package has nearly reached it's limit. To enjoy Alice's service without interruption, you can contact us to recharge your credit balance!",
      };

    case 'enterprise-reached_limit':
    case 'enterprise-exceeded_limit':
      return {
        title: 'Action Needed!',
        message:
          "Your package has reached it's limit! To enjoy Alice's service without interruption, you can contact us to recharge your credit balance!",
      };

    default:
      return {
        title: 'Message!',
        message:
          "You are now subscribed to Alice's Free Package. To enjoy more features, you can upgrade to startup or start a trial.",
      };
  }
};

const getIntent = (state: string): string => {
  switch (state) {
    case 'new_subscriber':
      return 'success';

    case 'reaching_limit':
      return 'warning';

    case 'nearly_reached_limit':
    case 'reached_limit':
    case 'exceeded_limit':
    case 'disconnection':
      return 'danger';

    default:
      return 'success';
  }
};

interface Prop {
  plan: string;
  state: string;
  billingDate: string;
  isCreator: boolean;
  trialAvailed: boolean;
}

const BillingMessage: React.FC<Prop> = ({
  plan,
  state,
  isCreator,
  trialAvailed,
}) => {
  plan = plan.toLowerCase();
  state = state.toLowerCase();

  const combination = `${plan}-${state}`;

  const intent = getIntent(state);
  const content = getMessage(combination);

  return (
    <Message
      intent={intent}
      title={content.title}
      text={content.message}
      action={
        <MessageAction
          trialAvailed={trialAvailed}
          isCreator={isCreator}
          isEnterprise={plan === 'enterprise'}
        />
      }
    />
  );
};

export default BillingMessage;
