import React from 'react';

interface linkType {
    text: string;
    url: string;
}
interface props {
    title: string;
    subtitle: string;
    link?: linkType;
}

const EditModalSmallHeader: React.FC<props> = ({title,subtitle,link}) => {
    return <>
        <p className='edit-modal-small-header__title'>{title}</p>
        <p className='edit-modal-small-header__subtitle'>{subtitle} {!!link && <a href={link.url} className='fb-link' rel="noopener noreferrer" target='_blank'>{link.text}</a>}</p>
    </>
}
;

export default EditModalSmallHeader;
