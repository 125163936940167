import React from 'react';
import {
  IconButton,
  Button,
  SelectMenu,
  TextInput,
  SegmentedControl,
} from 'evergreen-ui';

const RedirectCondition = ({
  id,
  lastIndex,
  attribute,
  operator,
  value,
  next,
  handleDelete,
  handleUpdate,
  attributeList,
  hasWriteAccess,
}) => {
  return (
    <div>
      <div className='redirect-condition__card'>
        <IconButton
          className='delete-icon'
          marginBottom={16}
          onClick={() => handleDelete(id)}
          appearance='minimal'
          height={24}
          icon='cross'
        />
        <div className='redirect-condition__card-body'>
          <div>
            <p className='p-label'>Attribute:</p>
            <SelectMenu
              hasTitle={false}
              height={200}
              hasFilter={false}
              title='Select name'
              options={
                !!attribute &&
                attributeList.map((label) => ({ label, value: label }))
              }
              selected={attribute}
              onSelect={(item) => handleUpdate(id, 'attribute', item.value)}
            >
              <Button disabled={!hasWriteAccess} className='flex-2' height={32}>
                {attribute || 'Select name...'}
              </Button>
            </SelectMenu>
          </div>
          <div>
            <p className='p-label'>Logic:</p>
            <SelectMenu
              hasTitle={false}
              height={200}
              hasFilter={false}
              title='Select Operator'
              options={[
                'is',
                'is not',
                'starts with',
                'ends with',
                'contains',
                'greater than',
                'less than',
              ].map((label) => ({ label, value: label }))}
              selected={operator}
              onSelect={(item) => handleUpdate(id, 'operator', item.value)}
            >
              <Button disabled={!hasWriteAccess} className='flex-1' height={32}>
                {operator || 'Select Operator...'}
              </Button>
            </SelectMenu>
          </div>
          <div>
            <p className='p-label'>Value:</p>
            <TextInput
              value={value}
              disabled={!hasWriteAccess}
              onChange={(e) => handleUpdate(id, 'value', e.target.value)}
              placeholder='Text input placeholder...'
            />
          </div>
        </div>
      </div>
      {!lastIndex && (
        <div>
          <SegmentedControl
            className='redirect-segment'
            height={24}
            width={100}
            options={[
              { label: 'And', value: 'and' },
              { label: 'Or', value: 'or' },
            ]}
            value={next}
            disabled={!hasWriteAccess}
            onChange={(value) => handleUpdate(id, 'next', value)}
          />
        </div>
      )}
    </div>
  );
};

export default RedirectCondition;
