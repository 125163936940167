import React, { Fragment } from 'react';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';
import smallLogo from '../assets/images/inconnect.svg';
// import logo from '../assets/images/logo-white.svg';
import { IconButton, Icon, Position, Tooltip } from 'evergreen-ui';
import { checkPermission, navMenu } from '../utils';
import ProfileContainer from './Profile';
// import AudienceIcon from '../assets/images/dashboard-icon.svg';

const NavBar = ({
  navIsOpen,
  toggleNav,
  updateSelectedNav,
  navActiveId,
  orientation,
  theme,
  selectedProject,
  isProUser,
  permissionList,
  selectedPlatform,
}) => {
  return (
    <header
      className={`dashboard-navbar-${orientation} ${theme} ${
        navIsOpen ? 'is-open' : ''
      }`}
    >
      <div className='logo-container'>
        <div className={`dashboard-navbar-${orientation}__left`}>
          <Link to='/dashboard'>
            <div className='logo-container__holder'>
              <img src={smallLogo} alt='Logo' width={'20px'} />
            </div>
          </Link>
          {navIsOpen && (
            <div className='logo-container__info'>
              <p className='title'>{selectedProject && selectedProject.name}</p>
              <p className='subtitle'>Current Project</p>
            </div>
          )}
          {/*{orientation === 'vertical' && (*/}
          {/*  <IconButton*/}
          {/*    className='toggle-icon'*/}
          {/*    appearance='minimal'*/}
          {/*    icon={navIsOpen ? 'menu-closed' : 'menu-open'}*/}
          {/*    color='#484b69'*/}
          {/*    iconSize={16}*/}
          {/*    onClick={() => toggleNav()}*/}
          {/*  />*/}
          {/*)}*/}
        </div>
      </div>
      <div className={`dashboard-navbar-${orientation}__right`}>
        <Link
          className={
            navActiveId === 0 ? 'side-menu-item active' : 'side-menu-item'
          }
          onClick={() => updateSelectedNav(0)}
          to={`/dashboard`}
        >
          {!navIsOpen && (
            <Tooltip content={'Dashboard'} position={Position.RIGHT}>
              <IconButton
                className='icon'
                appearance='minimal'
                icon='control'
                color='#484b69'
                iconSize={16}
              />
            </Tooltip>
          )}

          {navIsOpen && (
            <Fragment>
              <Icon
                className='icon'
                appearance='minimal'
                icon='control'
                color='#484b69'
              />
              <p>Dashboard</p>
            </Fragment>
          )}
        </Link>
        {!!selectedProject &&
          selectedProject.id &&
          navMenu.map(
            (menu, i) =>
              checkPermission(permissionList, menu.permission.readAccess) && (
                <Link
                  key={i}
                  className={
                    navActiveId === menu.id
                      ? 'side-menu-item active'
                      : 'side-menu-item'
                  }
                  onClick={() => updateSelectedNav(menu.id)}
                  to={
                    menu.global
                      ? menu.link
                      : `/projects/${selectedProject.id}/${menu.link}`
                  }
                >
                  {!navIsOpen && (
                    <Tooltip content={menu.name} position={Position.RIGHT}>
                      <IconButton
                        className={`icon ${menu.isPro &&
                          !isProUser &&
                          'pro-badge'}`}
                        appearance='minimal'
                        icon={menu.icon}
                        color='#484b69'
                        iconSize={16}
                      />
                    </Tooltip>
                  )}

                  {/*{navIsOpen && (*/}
                  {/*<Fragment>*/}
                  {/*<Icon*/}
                  {/*className='icon'*/}
                  {/*appearance='minimal'*/}
                  {/*icon={menu.icon}*/}
                  {/*color='#484b69'*/}
                  {/*/>*/}
                  {/*<p>{menu.name}</p>*/}
                  {/*{menu.isPro && !isProUser && (*/}
                  {/*<Badge color='red' isSolid marginLeft={8}>*/}
                  {/*Pro*/}
                  {/*</Badge>*/}
                  {/*)}*/}
                  {/*</Fragment>*/}
                  {/*)}*/}
                </Link>
              )
          )}
        <ProfileContainer />
      </div>
    </header>
  );
};

NavBar.propTypes = {
  theme: PropTypes.string,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
  selectedProject: PropTypes.object,
  navIsOpen: PropTypes.bool,
  toggleNav: PropTypes.func,
  updateSelectedNav: PropTypes.func,
  navActiveId: PropTypes.number,
  isProUser: PropTypes.bool,
  selectedPlatform: PropTypes.object,
};
NavBar.defaultProps = {
  theme: 'dark',
  selectedProject: { id: null },
  navIsOpen: true,
  isProUser: false,
  toggleNav: () => {
    console.log('provide a toggleNav to your props');
  },
};

export default NavBar;
