import React from 'react';
import HeatMap from 'react-heatmap-grid';
import { Tooltip, Icon, Spinner } from 'evergreen-ui';

const UserMHeatmapGrid = ({ data, loader, tooltipInfo }) => {
  const xLabels = new Array(24).fill('h').map((_, i) => `${i + 1}`);
  const yLabels = ['Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun'];

  return (
    <div className='reporting-container__user-row-card flex-column pdb-20px'>
      <div className='reporting-title-container'>
        <span>Incoming Message Count&nbsp;</span>
        <Tooltip content={tooltipInfo}>
          <Icon color='success' icon={'info-sign'} size={16} />
        </Tooltip>
      </div>
      <br />
      <div style={{ width: `${window.innerWidth / 1.25}px` }}>
        {loader ? (
          <div className='flex align-center justify-center h-200px'>
            <Spinner />
          </div>
        ) : (
          !!data && (
            <HeatMap
              xLabels={xLabels}
              yLabels={yLabels}
              data={data}
              background={'#007b65'}
              show
              xLabelsLocation={'bottom'}
              title={(value) => `Value: ${value}`}
            />
          )
        )}
      </div>
    </div>
  );
};
export default UserMHeatmapGrid;
