import React, { Fragment, useState } from 'react';
import BlockContainer from './BlockContainer';
import { Textarea, Switch } from 'evergreen-ui';
import ButtonGroup from './../elements/ButtonGroup';
import PropTypes from 'prop-types';
import InputVariableField from '../elements/InputVariableField';
import AttributeAPIContainer from '../elements/AttributeAPIContainer';
import {
  updateOnChangeAttribute,
  updateOnChangeText,
  updateOnSelectAttribute,
} from './../../utils';
import qrIcon from './../../pages/builder/assets/images/icons/quickreply.svg';
import FetchFromAPIButtonSection from '../elements/FetchFromAPIButtonSection';
import TextCharInfo from '../elements/TextCharInfo';

const QuickReplyBlock = ({
  id,
  data,
  save,
  sequences,
  saveBlock,
  saveLoader,
  type,
  updateButtonsBlock,
  updateButtonElements,
  setVariable,
  handleInputUpdate,
  createButtonElement,
  deleteButtonElement,
  attributeList,
  apiList,
  maxButtonCount,
  deleteBlock,
  copyBlock,
  moveBlock,
  handleAPIButtonElementCreate,
  hasWriteAccess,
}) => {
  const [showAttribute, setShowAttribute] = useState(false);
  const [showAttributeField, setShowAttributeField] = useState(false);
  const [showAPI, setShowAPI] = useState(false);
  const [maxCount] = useState(620);

  const setText = (val) => handleInputUpdate(val, id, 'text');

  return (
    <BlockContainer
      id={id}
      title='Quick Reply'
      subtitle='Add A Quick Reply to Your Block '
      save={save}
      isLoading={saveLoader}
      icon={qrIcon}
      handleSaveClick={() => saveBlock(id)}
      handleDelete={() => deleteBlock(id)}
      sequences={sequences}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <React.Fragment>
        <AttributeAPIContainer
          attributeList={attributeList}
          showAttribute={showAttribute}
          setShowAttribute={setShowAttribute}
          apiList={apiList.filter((api) => api.return_type === 'text')}
          showAPI={showAPI}
          setShowAPI={setShowAPI}
          handleSelect={(value) =>
            (data.text + value).length + 2 <= maxCount &&
            updateOnSelectAttribute(
              value,
              showAttribute,
              showAPI,
              setShowAttribute,
              setShowAPI,
              setText,
              data.text,
              apiList
            )
          }
        >
          <Textarea
            required={true}
            grammarly={true}
            spellCheck={true}
            disabled={!hasWriteAccess}
            name='text-block'
            placeholder='Your text block for Quick Reply'
            value={data.text}
            onChange={(e) =>
              e.target.value.length <= maxCount &&
              updateOnChangeText(
                e.target.value,
                setShowAttribute,
                setShowAPI,
                setText
              )
            }
          />
        </AttributeAPIContainer>
        <TextCharInfo data={data.text} count={maxCount} />
        {!!data.api ? (
          <FetchFromAPIButtonSection
            hasWriteAccess={hasWriteAccess}
            blockId={id}
            apiButtonData={data.api}
            handleDelete={handleAPIButtonElementCreate}
          />
        ) : (
          <ButtonGroup
            blockId={id}
            type={type}
            sequences={sequences}
            buttonTitle={'Create a Button'}
            APIButtonTitle={'Create a API Button'}
            maxButtonCount={maxButtonCount}
            buttonList={data.buttons}
            handleDropUpdate={updateButtonElements}
            handleCreate={createButtonElement}
            handleDelete={deleteButtonElement}
            handleUpdate={updateButtonsBlock}
            allowedTypes={['Sequence', 'Basic']}
            hasAPIButton={true}
            apiButtonData={data.api}
            apiList={apiList}
            hasWriteAccess={hasWriteAccess}
            handleAPIButtonElementCreate={handleAPIButtonElementCreate}
          />
        )}
        {hasWriteAccess && (
          <Fragment>
            <h6>Want to Save the Value?</h6>
            <Switch
              marginTop={10}
              marginBottom={10}
              height={24}
              checked={data.save}
              onChange={(e) => handleInputUpdate(e.target.checked, id, 'save')}
            />
          </Fragment>
        )}
        {data.save && (
          <InputVariableField
            value={data.attribute ? data.attribute : ''}
            hasWriteAccess={hasWriteAccess}
            attributeList={attributeList}
            showAttribute={showAttributeField}
            setShowAttribute={setShowAttributeField}
            handleSelect={(value) =>
              updateOnChangeAttribute(value, setShowAttributeField, setVariable)
            }
            onChange={(value) =>
              updateOnChangeAttribute(value, setShowAttributeField, setVariable)
            }
            placeHolder={'for example: f.name'}
          />
        )}
      </React.Fragment>
    </BlockContainer>
  );
};

QuickReplyBlock.propTypes = {
  id: PropTypes.number.isRequired,
  save: PropTypes.bool.isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  saveBlock: PropTypes.func.isRequired,
  sequences: PropTypes.array.isRequired,
  updateButtonsBlock: PropTypes.func,
  createButtonElement: PropTypes.func,
  deleteButtonElement: PropTypes.func,
  maxButtonCount: PropTypes.number.isRequired,
  setVariable: PropTypes.func,
  handleInputUpdate: PropTypes.func,
  attributeList: PropTypes.arrayOf(PropTypes.string),
  apiList: PropTypes.arrayOf(PropTypes.object),
  deleteBlock: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  saveLoader: PropTypes.bool.isRequired,
  hasWriteAccess: PropTypes.bool,
};

QuickReplyBlock.defaultProps = {
  maxButtonCount: 11,
  hasWriteAccess: false,
};

export default QuickReplyBlock;
