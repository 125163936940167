import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, toaster, Spinner } from 'evergreen-ui';
import { useDropzone } from 'react-dropzone';

const GalleryUpload = ({ image, handleDrop, hasWriteAccess }) => {
  const [loading, setLoading] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (files) => {
      if (hasWriteAccess && files.length > 0) {
        setLoading(true);
        handleDrop(files[0]);
        setTimeout(() => setLoading(false), 1500);
      }
    },
    onDropRejected: () => {
      toaster.danger('Failed', {
        description: 'Image too large. Maximum image size is 5MB',
        duration: 1,
      });
    },
    multiple: false,
    maxSize: 5242880,
  });

  return (
    <section className='gallery-upload'>
      <div
        {...getRootProps()}
        tabIndex={-1}
        className='gallery-upload__container'
      >
        {!image && !loading && (
          <Fragment>
            <input {...getInputProps()} />
            <Icon icon='cloud-upload' color='#eebb4d' size={20} /> &nbsp; &nbsp;
            <p>
              <span className='primary-text'>Click Here</span>&nbsp; to Upload
              File
            </p>
          </Fragment>
        )}

        {loading && <Spinner />}
      </div>
    </section>
  );
};

GalleryUpload.propTypes = {
  image: PropTypes.string,
  handleDrop: PropTypes.func,
  hasWriteAccess: PropTypes.bool,
};
GalleryUpload.defaultProps = {
  hasWriteAccess: true,
};

export default GalleryUpload;
