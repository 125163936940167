import React, { useEffect } from 'react';
import Routes from './routes';
import ReactGA from 'react-ga';
import { versionNumber } from './config';
import { navigate } from '@reach/router';
import store from './stores';
import * as Sentry from '@sentry/react';

const App = () => {
  const forceLogout = () => {
    let oldVersion = localStorage.getItem('versionNumber');

    if (!window.location.href.includes('/public/')) {
      //if the url is not public
      if (!!oldVersion) {
        if (oldVersion !== versionNumber) {
          localStorage.setItem('versionNumber', versionNumber);
          store.dispatch.auth.logout();
          navigate('/');
        }
      } else {
        localStorage.setItem('versionNumber', versionNumber);
        store.dispatch.auth.logout();
        navigate('/');
      }
    }
  };

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TOKEN);
    forceLogout();
  }, []);

  const fallback = () => (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      An Error Occured
    </div>
  );

  return (
    <Sentry.ErrorBoundary fallback={fallback}>
      <Routes />
    </Sentry.ErrorBoundary>
  );
};

export default App;
