import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'evergreen-ui';

const IconCard = ({ name, icon, handleClick }) => {
  return (
    <Card className='block-icon-card' onClick={handleClick}>
      <img src={icon} alt='Block Icon' />
      <p>{name}</p>
    </Card>
  );
};

IconCard.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
};

export default IconCard;
