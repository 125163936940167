import React from 'react';

interface Prop {
}

const DefaultMarketPlaceIcon: React.FC<Prop> = () => {

  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.665 13.33C4.89733 13.33 3.20206 12.6278 1.95213 11.3779C0.702203 10.1279 0 8.43267 0 6.665C0 4.89733 0.702203 3.20206 1.95213 1.95213C3.20206 0.702203 4.89733 0 6.665 0C8.43267 0 10.1279 0.702203 11.3779 1.95213C12.6278 3.20206 13.33 4.89733 13.33 6.665C13.33 8.43267 12.6278 10.1279 11.3779 11.3779C10.1279 12.6278 8.43267 13.33 6.665 13.33ZM6.665 6.24844C6.44404 6.24844 6.23213 6.33621 6.07589 6.49245C5.91965 6.6487 5.83187 6.8606 5.83187 7.08156V9.16438C5.83187 9.38533 5.91965 9.59724 6.07589 9.75348C6.23213 9.90972 6.44404 9.9975 6.665 9.9975C6.88596 9.9975 7.09787 9.90972 7.25411 9.75348C7.41035 9.59724 7.49813 9.38533 7.49813 9.16438V7.08156C7.49813 6.8606 7.41035 6.6487 7.25411 6.49245C7.09787 6.33621 6.88596 6.24844 6.665 6.24844ZM6.665 5.31117C6.96882 5.31117 7.26019 5.19048 7.47502 4.97565C7.68986 4.76082 7.81055 4.46944 7.81055 4.16563C7.81055 3.86181 7.68986 3.57043 7.47502 3.3556C7.26019 3.14077 6.96882 3.02008 6.665 3.02008C6.36118 3.02008 6.06981 3.14077 5.85498 3.3556C5.64014 3.57043 5.51945 3.86181 5.51945 4.16563C5.51945 4.46944 5.64014 4.76082 5.85498 4.97565C6.06981 5.19048 6.36118 5.31117 6.665 5.31117Z" fill="#172B4D"/>
    </svg>

  );
};

export default DefaultMarketPlaceIcon;
