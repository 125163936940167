import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TextBlock from './TextBlock';
import DelayBlock from './DelayBlock';
import ButtonBlock from './ButtonBlock';
import QuickReplyBlock from './QuickReplyBlock';
import ImageBlock from './ImageBlock';
import VideoBlock from './VideoBlock';
import AudioBlock from './AudioBlock';
import RedirectBlock from './RedirectBlock';
import GalleryBlock from './GalleryBlock';
import UserInputBlock from './UserInputBlock';
import PhoneInputBlock from './PhoneInputBlock';
import EmailInputBlock from './EmailInputBlock';
import EmailSendBlock from './EmailSendBlock';
import SubscribeBlock from './SubscribeBlock';
import UnsubscribeBlock from './UnsubscribeBlock';
import LiveChatBlock from './LiveChatBlock';
import SetAttributeBlock from './SetAttributeBlock';
import LocationViberBlock from './LocationViberBlock';
import TicketBlock from './TicketBlock';
import StoryBlock from './StoryBlock';

const getBlockComponentFromType = (type, props) => {
  if (type === 'attachment' || type === 'input') {
    type = props.data.sub_type;
  }

  const {
    id,
    data,
    save,
    sequences,
    saveBlock,
    copyBlock,
    moveBlock,
    deleteBlock,
    platformType,
    saveLoader,
    hasWriteAccess,
  } = props;
  const blockProps = {
    id,
    save,
    sequences,
    saveBlock,
    copyBlock,
    moveBlock,
    deleteBlock,
    saveLoader,
    hasWriteAccess,
  };
  switch (type) {
    case 'text':
      return (
        <TextBlock
          {...blockProps}
          text={data.text}
          type={type}
          attributeList={props.attributeList}
          apiList={props.apiList}
          setText={(value) => props.updateTextBlock(value, id, 'text')}
        />
      );
    case 'delay':
      return <DelayBlock {...blockProps} />;
    case 'button':
      return (
        <ButtonBlock
          {...blockProps}
          data={data}
          type={type}
          attributeList={props.attributeList}
          labsData={props.labsData}
          apiList={props.apiList}
          createButtonElement={props.createButtonElement}
          updateButtonsBlock={props.updateButtonsBlock}
          updateButtonElements={props.updateButtonElements}
          setText={(value) => props.updateInputBlock(value, id, 'text')}
          deleteButtonElement={props.deleteButtonElement}
          handleAPIButtonElementCreate={props.handleAPIButtonElementCreate}
          platformType={platformType}
        />
      );
    case 'quick_reply':
      return (
        <QuickReplyBlock
          {...blockProps}
          data={data}
          type={type}
          attributeList={props.attributeList}
          apiList={props.apiList}
          createButtonElement={props.createButtonElement}
          updateButtonsBlock={props.updateButtonsBlock}
          updateButtonElements={props.updateButtonElements}
          setVariable={(value) =>
            props.updateInputBlock(value, id, 'attribute')
          }
          handleInputUpdate={props.updateInputBlock}
          deleteButtonElement={props.deleteButtonElement}
          handleAPIButtonElementCreate={props.handleAPIButtonElementCreate}
        />
      );
    case 'image':
      return (
        <ImageBlock
          {...blockProps}
          data={data}
          type={type}
          handleUpdate={props.updateImageBlock}
          apiList={props.apiList}
          handleAPIButtonElementCreate={props.handleAPIButtonElementCreate}
        />
      );
    case 'video':
      return (
        <VideoBlock
          {...blockProps}
          url={data.urls[0]}
          updateURLBlocks={props.updateURLBlocks}
        />
      );
    case 'audio':
      return (
        <AudioBlock
          {...blockProps}
          url={data.urls[0]}
          updateURLBlocks={props.updateURLBlocks}
        />
      );
    case 'redirect':
      return (
        <RedirectBlock
          {...blockProps}
          data={data}
          attributeList={props.attributeList}
          updateRedirectBlock={props.updateRedirectBlock}
          updateBlock={props.updateInputBlock}
        />
      );
    case 'gallery':
      return (
        <GalleryBlock
          {...blockProps}
          type={type}
          data={data.elements}
          ratio={data.image_aspect_ratio}
          platformType={platformType}
          blockData={data}
          labsData={props.labsData}
          handleCreate={props.createGalleryElement}
          handleButtonCreate={props.createGalleryButtonElement}
          handleUpdate={props.updateGalleryBlock}
          handleGalleryDropUpdate={props.updateGalleryElements}
          handleButtonDropUpdate={props.updateGalleryButtonElements}
          handleTemplateDelete={props.deleteGalleryItem}
          handleButtonDelete={props.deleteGalleryButtonElement}
          apiList={props.apiList}
          handleAPIButtonElementCreate={props.handleAPIButtonElementCreate}
          updateGalleryBlockData={props.updateGalleryBlockData}
        />
      );
    case 'basic':
    case 'number':
      return (
        <UserInputBlock
          {...blockProps}
          text={data.text}
          errorCounter={data.error_counter || null}
          failText={data.fail_text || null}
          failedSequenceId={data.fail_sequence || null}
          attributeList={props.attributeList}
          apiList={props.apiList}
          setText={(changeText) =>
            props.updateInputBlock(changeText, props.id, 'text')
          }
          inputValidation={data.sub_type}
          updateInputValidation={(validation) =>
            props.updateInputBlock(validation, props.id, 'sub_type')
          }
          updateBlockValue={(value, key) =>
            props.updateInputBlock(value, props.id, key)
          }
          variable={props.data.attribute}
          setVariable={(changeVariable) =>
            props.updateInputBlock(changeVariable, props.id, 'attribute')
          }
        />
      );
    case 'email':
      return (
        <EmailInputBlock
          {...blockProps}
          text={data.text}
          errorCounter={data.error_counter || null}
          failText={data.fail_text || null}
          failedSequenceId={data.fail_sequence || null}
          attributeList={props.attributeList}
          apiList={props.apiList}
          setText={(changeText) =>
            props.updateInputBlock(changeText, props.id, 'text')
          }
          updateBlockValue={(countryList, key) =>
            props.updateInputBlock(countryList, props.id, key)
          }
          variable={data.attribute}
          setVariable={(changeVariable) =>
            props.updateInputBlock(changeVariable, props.id, 'attribute')
          }
        />
      );
    case 'phone':
      return (
        <PhoneInputBlock
          {...blockProps}
          text={data.text}
          phoneLocale={data.phone_locale}
          errorCounter={data.error_counter || null}
          failText={data.fail_text || null}
          failedSequenceId={data.fail_sequence || null}
          isViberPlatform={platformType === 'viber_messenger'}
          attributeList={props.attributeList}
          apiList={props.apiList}
          setText={(changeText) =>
            props.updateInputBlock(changeText, props.id, 'text')
          }
          updateBlockValue={(countryList, key) =>
            props.updateInputBlock(countryList, props.id, key)
          }
          variable={props.data.attribute}
          setVariable={(changeVariable) =>
            props.updateInputBlock(changeVariable, props.id, 'attribute')
          }
        />
      );
    case 'location':
      return (
        <LocationViberBlock
          {...blockProps}
          text={data.text}
          attributeList={props.attributeList}
          apiList={props.apiList}
          setText={(changeText) =>
            props.updateInputBlock(changeText, props.id, 'text')
          }
          variable={props.data.attribute}
          setVariable={(changeVariable) =>
            props.updateInputBlock(changeVariable, props.id, 'attribute')
          }
          updateBlockValue={(value, key) =>
            props.updateInputBlock(value, props.id, key)
          }
          errorCounter={data.error_counter || null}
          failText={data.fail_text || null}
          failedSequenceId={data.fail_sequence || null}
        />
      );
    case 'send_email':
      return (
        <EmailSendBlock
          {...blockProps}
          from={data.sender}
          to={data.to}
          cc={data.cc}
          subject={data.subject}
          body={data.body}
          type={type}
          attributeList={props.attributeList}
          apiList={props.apiList}
          setFrom={(value) => props.updateEmailSendBlock(value, id, 'sender')}
          setTo={(value) => props.updateEmailSendBlock(value, id, 'to')}
          setCC={(value) => props.updateEmailSendBlock(value, id, 'cc')}
          setSubject={(value) =>
            props.updateEmailSendBlock(value, id, 'subject')
          }
          setBody={(value) => props.updateEmailSendBlock(value, id, 'body')}
        />
      );
    case 'subscribe':
      return (
        <SubscribeBlock
          {...blockProps}
          data={data}
          updateSubscribeBlockTitle={props.updateSubscribeBlockTitle}
          updateSubscribeSequenceData={props.updateSubscribeSequenceData}
          createSubscribeElement={props.createSubscribeElement}
          handleSubscribeDelete={props.deleteSubscriberSequenceData}
        />
      );

    case 'unsubscribe':
      return (
        <UnsubscribeBlock
          {...blockProps}
          data={data}
          subscriptions={props.subscriptions}
          handleUpdate={props.updateUnsubscribeBlock}
        />
      );
    case 'live_chat':
      return (
        <LiveChatBlock
          {...blockProps}
          text={data.text}
          timeout={data.timeout}
          handleTimeout={(value) =>
            props.updateInputBlock(parseInt(value), id, 'timeout')
          }
          updateButtonsBlock={props.updateLiveChatButtonBlock}
          button={data.button}
          attributeList={props.attributeList}
          apiList={props.apiList}
          setText={(value) => props.updateTextBlock(value, id, 'text')}
        />
      );
    case 'set_attribute':
      return (
        <SetAttributeBlock
          {...blockProps}
          variable={props.data.attribute}
          value={props.data.value}
          updateAttribute={(value) =>
            props.updateInputBlock(value, id, 'attribute')
          }
          updateValue={(value) => props.updateInputBlock(value, id, 'value')}
        />
      );
    case 'ticket':
      return (
        <TicketBlock
          {...blockProps}
          text={data.text}
          setText={(value) => props.updateTextBlock(value, id, 'text')}
          type={type}
          attributeList={props.attributeList}
          apiList={props.apiList}
          agentList={props.agentList}
          agentGroupList={props.agentGroupList}
          agents={data.agents}
          disabledBot={data?.disable_bot}
          updateDisabledBot={(checked) => {
            props.updateInputBlock(checked, id, 'disable_bot');
          }}
          agentIds={data.agent_ids || []}
          agentTag={data.groups}
          updateAgent={(val) => {
            props.updateInputBlock([], id, 'groups');
            props.updateInputBlock([], id, 'group_ids');
            props.updateInputBlock(
              [{ id: val.value, full_name: val.label }],
              id,
              'agents'
            );
            props.updateInputBlock([val.value], id, 'agent_ids');
          }}
          updateAgentTag={(val) => {
            props.updateInputBlock([], id, 'agents');
            props.updateInputBlock([], id, 'agent_ids');
            props.updateInputBlock(
              [{ id: val.value, name: val.label }],
              id,
              'groups'
            );
            props.updateInputBlock([val.value], id, 'group_ids');
          }}
          updateNoAgentGroup={() => {
            props.updateInputBlock([], id, 'agents');
            props.updateInputBlock([], id, 'agent_ids');
            props.updateInputBlock([], id, 'groups');
            props.updateInputBlock([], id, 'group_ids');
          }}
          priority={data.priority}
          updatePriority={(data) => {
            props.updateInputBlock(parseInt(data.value), id, 'priority');
            props.updateInputBlock(data.label, id, 'priority_str');
          }}
          resolveSequence={data.resolve_sequence}
          updateResolveSequence={(val) => {
            props.updateInputBlock(val, id, 'resolve_sequence');
            props.updateInputBlock(parseInt(val.id), id, 'resolve_sequence_id');
          }}
          note={data.note}
          updateNote={(data) => props.updateInputBlock(data, id, 'note')}
        />
      );
    case 'story':
      return (
        <StoryBlock
          {...blockProps}
          data={data}
          type={type}
          attributeList={props.attributeList}
          apiList={props.apiList}
          entities={props.entities}
          updateInfo={(data, key) => props.updateInputBlock(data, id, key)}
          selectedSequence={props.selectedSequence}
        />
      );
    default:
      return JSON.stringify(data);
  }
};

const SmartBlock = ({ type, ...props }) => {
  return <Fragment>{getBlockComponentFromType(type, props)}</Fragment>;
};

SmartBlock.propTypes = {
  type: PropTypes.string.isRequired,
};

export default SmartBlock;
