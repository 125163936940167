import React from 'react';
import './assets/styles/reporting.scss';
import {connect} from 'react-redux';
import {checkPermission} from '../../utils';
import ReactGA from 'react-ga';
import ReportingNav from './component/ReportingNav';
import {subDays} from 'date-fns';
import UserMetrics from './component/Screen/UserMetrics';
import AutomationMetrics from './component/Screen/AutomationMetrics';
import AgentMetrics from './component/Screen/AgentMetrics';

// import ConversationMetrics from './component/Screen/ConversationMetrics';

interface props {
    projects: any[];
    projectId: any;
    selectedProject: any;
    setSelectedProject: (project: any) => void;
    selectedPlatform: any;
    setSelectedPlatform: any;
    permissionList: any[];
    updateSelectedNav: (num: number) => void;
    clearState: () => void;
    currentTab: any;
    updateStateData: (key: string, value: any) => void;
    dateRange: any;
    platformData: any;
    //user matrics
    fetchUserReportData: (projectId: number, endpoint: string) => void;
    fetchUserReportDataOnTabClick: (projectId: number, endpoint: string) => void;
    fetchUserReportDataUserMetricsLineGraph: (
        projectId: number,
        endpoint: string
    ) => void;
    fetchUserReportDataDefaultAppend: (
        projectId: number,
        endpoint: string
    ) => void;
    fetchUserReportHeatmap: (projectId: number) => void;
    fetchAgentList: (teamId: number) => void;
    fetchAutomationTicketTable: (
        projectId: number,
        groupId: number | null
    ) => void;
    fetchIntentReporting: (projectId: number) => void;
    fetchConversationLog: (projectId: number, agentId: (number | null), intent: string, limit: number, offset: number) => void;
}

const Reporting: React.FC<props> = (
    {
        projects,
        projectId,
        selectedProject,
        setSelectedProject,
        selectedPlatform,
        setSelectedPlatform,
        permissionList,
        updateSelectedNav,
        currentTab,
        updateStateData,
        dateRange,
        platformData,
        clearState,
        fetchUserReportData,
        fetchUserReportHeatmap,
        fetchAgentList,
        fetchAutomationTicketTable,
        fetchUserReportDataOnTabClick,
        fetchUserReportDataUserMetricsLineGraph,
        fetchUserReportDataDefaultAppend,
        fetchConversationLog,
        fetchIntentReporting
    }) => {
    const readAccess = 'read:analytics';

    const [localDateRange, setLocalDateRange] = React.useState([
        {
            startDate: subDays(new Date(), 30),
            endDate: new Date(),
            key: 'selection',
        },
    ]);
    //User Matrices Local State
    const [currentRefIndex, setCurrentRefIndex] = React.useState({
        id: 0,
        name: 'Referral Details',
    });
    const [currentIntentIndex, setCurrentIntentIndex] = React.useState({
        id: 0,
        name: 'Intent Details',
    });

    const [defaultGroup, setDefaultGroup] = React.useState({
        id: null,
        email: '',
        avatar: null,
        full_name: 'All',
    });

    const fetchUserMetrics = () => {
        fetchUserReportData(selectedProject.id, 'get_new_customer_count');
        fetchUserReportData(selectedProject.id, 'get_interactive_customer_count');
        fetchUserReportData(
            selectedProject.id,
            'get_triggered_message_count/referral'
        );
        fetchUserReportData(
            selectedProject.id,
            'get_triggered_message_count/intent'
        );
        fetchUserReportDataUserMetricsLineGraph(
            selectedProject.id,
            'get_language_metrics'
        );
        fetchUserReportDataUserMetricsLineGraph(
            selectedProject.id,
            'get_timezone_metrics'
        );
        fetchUserReportDataUserMetricsLineGraph(
            selectedProject.id,
            'get_country_metrics'
        );

        fetchUserReportHeatmap(selectedProject.id);
        fetchUserReportDataDefaultAppend(selectedProject.id, 'get_gender_metrics');
        fetchUserReportDataDefaultAppend(
            selectedProject.id,
            'get_postback_and_text_message_count'
        );
        if (currentRefIndex.id === 0) {
            fetchUserReportDataDefaultAppend(
                selectedProject.id,
                'get_triggered_message_list/referral'
            );
        } else if (currentRefIndex.id === 1) {
            fetchUserReportDataDefaultAppend(
                selectedProject.id,
                'get_triggered_message_list/keyword'
            );
        }
        //   1
        if (currentIntentIndex.id === 0) {
            fetchUserReportDataDefaultAppend(
                selectedProject.id,
                'get_triggered_message_list/intent'
            );
        } else if (currentIntentIndex.id === 1) {
            fetchUserReportDataDefaultAppend(
                selectedProject.id,
                'get_triggered_message_list/postback'
            );
        }
    };

    const fetchAutomationMetrics = () => {
        fetchUserReportData(selectedProject.id, 'get_avg_ticket_per_user');
        fetchUserReportData(selectedProject.id, 'get_avg_ticket_per_overall_user');
        fetchUserReportData(selectedProject.id, 'get_ticket_created_count');
        fetchUserReportData(selectedProject.id, 'get_ticket_resolved_count');
        fetchUserReportDataDefaultAppend(
            selectedProject.id,
            'get_textual_message_vs_automation'
        );
        fetchUserReportDataDefaultAppend(selectedProject.id, 'get_tag_cloud');
        fetchAutomationTicketTable(selectedProject.id, defaultGroup.id);
    };

    const fetchAgentMetrics = () => {
        fetchUserReportDataUserMetricsLineGraph(
            selectedProject.id,
            'get_ticket_avg_queue_time'
        );
        fetchUserReportDataUserMetricsLineGraph(
            selectedProject.id,
            'get_ticket_avg_resolve_time'
        );
        fetchUserReportDataDefaultAppend(selectedProject.id, 'get_agent_stats');
    };

    const fetchConversationLogAPI = () => {
        fetchConversationLog(selectedProject.id, null, '', 20, 0)
    };

    const updateInformation = async () => {
        ReactGA.pageview(
            window.location.pathname + window.location.search + window.location.hash
        );
        const project = projects.filter((p) => parseInt(projectId) === p.id)[0];
        if (!!selectedProject && projectId !== selectedProject.id) {
            await setSelectedProject(project);
            if (
                project.platforms.length > 0 &&
                project.platforms.filter(
                    (platform: any) => platform.id === selectedPlatform.id
                ).length === 0
            ) {
                setSelectedPlatform(project.platforms[0]);
            }
        }
        const hasPermission = checkPermission(permissionList, readAccess);

        if (hasPermission) {
            updateSelectedNav(6);
            fetchAgentList(selectedProject.id);
            fetchIntentReporting(selectedProject.id);
        }
    };

    React.useEffect(() => {
        const hasPermission = checkPermission(permissionList, readAccess);
        if (hasPermission) {
            fetchAnalyticsData();
        }
        // eslint-disable-next-line
    }, [currentTab]);

    const fetchAnalyticsData = async () => {
        if (currentTab.id === 0) {
            fetchUserMetrics();
        } else if (currentTab.id === 1) {
            fetchAutomationMetrics();
        } else if (currentTab.id === 2) {
            fetchAgentMetrics();
        } else if (currentTab.id === 3) {
            fetchConversationLogAPI();
        }
    };

    React.useEffect(() => {
        updateInformation();
        return () => {
            clearState();
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div className='reporting-container'>
            <ReportingNav
                selectedProject={selectedProject}
                selectedPlatformLocal={platformData}
                setSelectedPlatformLocal={(val: any) =>
                    updateStateData('platformData', val)
                }
                dateRange={localDateRange}
                setDateRange={setLocalDateRange}
                finalDateRange={dateRange}
                currentTab={currentTab}
                updateStateData={updateStateData}
                fetchAnalyticsData={() => fetchAnalyticsData()}
                onDateFilter={() => {
                    updateStateData('dateRange', localDateRange);
                    setTimeout(() => {
                        fetchAnalyticsData();
                    }, 500);
                }}
            />
            {currentTab.id === 0 ? (
                <UserMetrics
                    currentRefIndex={currentRefIndex}
                    currentIntentIndex={currentIntentIndex}
                    onTabChange={(tab: any, pointer: number) => {
                        if (pointer === 0) {
                            setCurrentRefIndex(tab);
                            if (tab.id === 0) {
                                fetchUserReportDataOnTabClick(
                                    selectedProject.id,
                                    'get_triggered_message_list/referral'
                                );
                            } else if (tab.id === 1) {
                                fetchUserReportDataOnTabClick(
                                    selectedProject.id,
                                    'get_triggered_message_list/keyword'
                                );
                            }
                        } else if (pointer === 1) {
                            setCurrentIntentIndex(tab);
                            if (tab.id === 0) {
                                fetchUserReportDataOnTabClick(
                                    selectedProject.id,
                                    'get_triggered_message_list/intent'
                                );
                            } else if (tab.id === 1) {
                                fetchUserReportDataOnTabClick(
                                    selectedProject.id,
                                    'get_triggered_message_list/postback'
                                );
                            }
                        }
                    }}
                />
            ) : currentTab.id === 1 ? (
                <AutomationMetrics
                    defaultGroup={defaultGroup}
                    setDefaultGroup={setDefaultGroup}
                />
            ) : currentTab.id === 2 ? (
                <AgentMetrics/>
                // ) :
                //   currentTab.id === 3 ? (
                //   <ConversationMetrics />
            ) : <div>Not Found </div>}
        </div>
    );
};

const mapState = (state: any) => ({
    projects: state.dashboard.projects,
    selectedProject: state.dashboard.selectedProject,
    selectedPlatform: state.dashboard.selectedPlatform,
    permissionList: state.dashboard.permission?.list || [],
    currentTab: state.reporting.currentTab,
    dateRange: state.reporting.dateRange,
    platformData: state.reporting.platformData,
});

const mapDispatch = (dispatch: any) => ({
    setSelectedProject: (project: any) =>
        dispatch.dashboard.setSelectedProject(project),
    setSelectedPlatform: (platform: any) =>
        dispatch.dashboard.setSelectedPlatform(platform),
    updateSelectedNav: (navId: number) =>
        dispatch.dashboard.updateSelectedNav(navId),
    updateStateData: (key: string, value: any) =>
        dispatch.reporting.updateStateData({key, value}),
    //user matrocs
    fetchUserReportData: (projectId: number, endpoint: string) =>
        dispatch.reporting.fetchUserReportData({projectId, endpoint}),
    fetchUserReportDataUserMetricsLineGraph: (
        projectId: number,
        endpoint: string
    ) =>
        dispatch.reporting.fetchUserReportDataUserMetricsLineGraph({
            projectId,
            endpoint,
        }),
    fetchUserReportDataDefaultAppend: (projectId: number, endpoint: string) =>
        dispatch.reporting.fetchUserReportDataDefaultAppend({
            projectId,
            endpoint,
        }),
    fetchUserReportDataOnTabClick: (projectId: number, endpoint: string) =>
        dispatch.reporting.fetchUserReportDataOnTabClick({projectId, endpoint}),
    fetchUserReportHeatmap: (projectId: number) =>
        dispatch.reporting.fetchUserReportHeatmap(projectId),
    fetchAgentList: (teamId: number) => dispatch.reporting.fetchAgentList(teamId),
    fetchAutomationTicketTable: (projectId: number, groupId: number | null) =>
        dispatch.reporting.fetchAutomationTicketTable({projectId, groupId}),
    clearState: () => dispatch.reporting.clearState(),
    fetchIntentReporting: (projectId: number) => dispatch.reporting.fetchIntentReporting(projectId),
    fetchConversationLog: (projectId: number, agentId: (number | null), intent: string, limit: number, offset: number) => dispatch.reporting.fetchConversationLog({
        projectId,
        agentId,
        intent,
        limit,
        offset
    })
});

const ReportingContainer = connect(mapState, mapDispatch)(Reporting);

export default ReportingContainer;
