import React from 'react';
import { SearchInput } from 'evergreen-ui';

interface Props {
    handleFilter: (value: string) => void;
    value: string;
}

const SearchDataLab: React.FC<Props> = ({ handleFilter, value }) => {
    return (
        <div>
            <SearchInput
                placeholder='Search Datalab'
                width='100%'
                height={40}
                onChange={(value: string) => handleFilter(value)}
                value={value}
            />
        </div>
    );
};

export default SearchDataLab;
