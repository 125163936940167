import React, {Fragment} from 'react';
import ReportingRowCards from './../../component/ReportingRowCards';
import ReportingWordCloud from './../../component/ReportingWordCloud';
import ReportingLineChart from '../../component/ReportingLineChart';
import ReportingTicketTable from './../../component/ReportingTicketTable';
import {connect} from 'react-redux';

interface props {
  ticketResolvedCount: any;
  avgTicketPerUser: any;
  avgTicketPerOverallUser: any;
  ticketCreatedCount: any;
  loader: boolean;
  textualVsAutomation: any;
  ticketTagCloud: any;
  ticketListTable: any;
  agentList: any;
  defaultGroup: any;
  selectedProject: any;
  fetchAutomationTicketTable: (
    projectId: number,
    groupId: number | null
  ) => void;
  setDefaultGroup: (val: any) => void;
  defaultDataLoader: boolean;
  ticketListLoader: boolean;
  exportCustomerTicket: (projectId: number, groupId: number | null) => void;
  exportCustomerTicketLoader: boolean;
}

const AutomationMetrics: React.FC<props> = (
  {
    avgTicketPerOverallUser,
    ticketCreatedCount,
    ticketResolvedCount,
    avgTicketPerUser,
    loader,
    textualVsAutomation,
    ticketTagCloud,
    ticketListTable,
    agentList,
    defaultGroup,
    fetchAutomationTicketTable,
    selectedProject,
    setDefaultGroup,
    defaultDataLoader,
    ticketListLoader,
    exportCustomerTicket,
    exportCustomerTicketLoader,
  }) => {
  return (
    <Fragment>
      <div className='reporting-container__user-row-card'>
        <ReportingRowCards
          title={'Avg. Tickets per User'}
          tooltipInfo='Number of users who have issued a ticket during the selected timeframe.'
          data={avgTicketPerUser?.count}
          loading={loader}
          growth={avgTicketPerUser?.growth}
          growthType={avgTicketPerUser?.growthType}
          additionalGrowthText={`vs last ${avgTicketPerUser?.days_span} days`}
        />
        <ReportingRowCards
          title={'Avg. Tickets for Overall Users'}
          tooltipInfo='The average number of tickets that are created by total users.'
          data={avgTicketPerOverallUser?.count}
          loading={loader}
          growth={avgTicketPerOverallUser?.growth}
          growthType={avgTicketPerOverallUser?.growthType}
          additionalGrowthText={`vs last ${avgTicketPerOverallUser?.days_span} days`}
        />
        <ReportingRowCards
          title={'Tickets Created'}
          tooltipInfo='Number of tickets created during the selected timeframe.'
          data={ticketCreatedCount?.count}
          loading={loader}
          growth={ticketCreatedCount?.growth}
          growthType={ticketCreatedCount?.growthType}
          additionalGrowthText={`vs last ${ticketCreatedCount?.days_span} days`}
        />
        <ReportingRowCards
          title={'Tickets Resolved'}
          tooltipInfo='Number of issued tickets solved during the selected timeframe.'
          data={ticketResolvedCount?.count}
          loading={loader}
          growth={ticketResolvedCount?.growth}
          growthType={ticketResolvedCount?.growthType}
          additionalGrowthText={`vs last ${ticketResolvedCount?.days_span} days`}
        />
      </div>
      <div className='reporting-container__user-card-container'>
        <ReportingLineChart
          title={'Textual message vs Automation'}
          loader={defaultDataLoader}
          toolTipInfo='Comparison between the number of automated messages generated and the number of text messages sent by agents.'
          data={textualVsAutomation.map((elem: any, index: number) => ({
            ...elem,
            name: `Week ${index}`,
          }))}
        />
        <ReportingWordCloud
          title={'Tag Cloud'}
          loader={defaultDataLoader}
          toolTipInfo='Most used tags in the CRM. Bigger size of the word indicates higher usage.'
          data={ticketTagCloud.map((elem: any) => ({
            text: elem.tags__name,
            value: elem.count,
          }))}
        />
      </div>
      <ReportingTicketTable
        title={'Ticket Details'}
        group={agentList}
        defaultGroup={defaultGroup}
        loader={ticketListLoader}
        toolTipInfo={'Number of tickets created during the selected timeframe'}
        tableData={ticketListTable}
        exportLoader={exportCustomerTicketLoader}
        exportData={() =>
          exportCustomerTicket(selectedProject.id, defaultGroup.id)
        }
        onSelect={(agent: any) => {
          fetchAutomationTicketTable(selectedProject.id, agent.id);
          setDefaultGroup(agent);
        }}
      />
    </Fragment>
  );
};

const mapState = (state: any) => ({
  ticketResolvedCount: state.reporting.ticketResolvedCount,
  avgTicketPerUser: state.reporting.avgTicketPerUser,
  avgTicketPerOverallUser: state.reporting.avgTicketPerOverallUser,
  ticketCreatedCount: state.reporting.ticketCreatedCount,
  loader: state.loading.effects.reporting.fetchUserReportData,
  defaultDataLoader:
  state.loading.effects.reporting.fetchUserReportDataDefaultAppend,
  textualVsAutomation: state.reporting.textualVsAutomation,
  ticketTagCloud: state.reporting.ticketTagCloud,
  ticketListTable: state.reporting.ticketListTable,
  agentList: state.reporting.agentList,
  selectedProject: state.dashboard.selectedProject,
  ticketListLoader: state.loading.effects.reporting.fetchAutomationTicketTable,
  exportCustomerTicketLoader:
  state.loading.effects.reporting.exportCustomerTicket,
});

const mapDispatch = (dispatch: any) => ({
  fetchAutomationTicketTable: (projectId: number, groupId: number | null) =>
    dispatch.reporting.fetchAutomationTicketTable({projectId, groupId}),
  exportCustomerTicket: (projectId: number, groupId: number | null) =>
    dispatch.reporting.exportCustomerTicket({projectId, groupId}),
});

const AutomationMetricsContainer = connect(
  mapState,
  mapDispatch
)(AutomationMetrics);

export default AutomationMetricsContainer;
