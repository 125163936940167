import React from 'react';
import {Dialog, Button} from "evergreen-ui";
import {findAlertInfo} from "../../../utils";

interface Prop {
  isShown: boolean;
  setShown: (data: boolean) => void;
  intent: ('warning' | 'success' | 'info' | 'danger');
  title?: (string | number);
  description: string;
  onCancelCallback?: any;
  onDeleteCallback?: any;
  deleteTitle?: string;
  cancelTitle?: string;
}

const Modal: React.FC<Prop> =
  ({
     isShown,
     setShown, intent,
     title,
     description,
     onCancelCallback,
     onDeleteCallback,
     deleteTitle = "Delete",
     cancelTitle = "Cancel"
   }) => {

    return (
      <div>
        <Dialog
          isShown={isShown}
          onCloseComplete={() => setShown(false)}
          hasFooter={false}
          hasHeader={false}
          containerProps={{className: 'rd-modal-style'}}
        >
          <div className='flex  align-start-item'>
            <img src={findAlertInfo(intent).icon} width={30} alt={'modal icon'}/> &nbsp;
            <p className='rd-modal-style__title'>&nbsp;{title}</p>
          </div>
          <p className='rd-modal-style__description'>{description}</p>
          <br/>
          <div className='flex flex-end'>
            <Button className='alice-btn__minimal-black' marginRight={20} onClick={() => {
              if (!!onCancelCallback) {
                onCancelCallback();
              }
              setShown(false);
            }}>{cancelTitle}</Button>
            <Button className={`alice-btn__${intent}`} onClick={() => {
              if (!!onDeleteCallback) {
                onDeleteCallback();
                setShown(false);
              }
            }}>{deleteTitle}</Button>

          </div>
        </Dialog>
      </div>
    );
  };

export default Modal;
