import React from 'react';
import clock from './../assets/images/icons/clock.svg';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import defaultUser from './../assets/images/defaultUser.svg';

dayjs.extend(relativeTime);

interface props {
  activity: any;
}

const DashboardActivityCard: React.FC<props> = ({ activity }) => {
  return (
    <div className='dashboard__activity-card'>
      <div className='flex-5'>
          {!activity.actor.avatar &&  <img
              className='dashboard__activity-card-avatar'
              src={defaultUser}
              alt={activity.id + ''}
          />}
          {!!activity.actor.avatar &&  <img
              className='dashboard__activity-card-avatar'
              src={activity.actor.avatar}
              alt={activity.id + ''}
          />}
      </div>
      <div className='ml-10px flex-15'>
        <p className='dashboard__activity-card-texts'>
          <span>{activity.name}</span> &nbsp;{activity.text}
        </p>
        <div className='dashboard__activity-card-time'>
          <img
            src={clock}
            alt={'clock'}
            style={{ marginRight: 5 }}
            width={14}
          />{' '}
            {dayjs(activity.created_at*1000).fromNow()}
        </div>
      </div>
    </div>
  );
};

export default DashboardActivityCard;
