import React from 'react';
import { getGraphForReporting } from '../../../utils';
import {
  Tooltip as TooltipEvergreen,
  Spinner,
  Position,
  Icon,
} from 'evergreen-ui';
import { BarChart, Bar, Legend, Tooltip, XAxis } from 'recharts';
import PropTypes from 'prop-types';

const ReportingCard = ({
  title,
  data,
  additionalData,
  growth,
  growthType,
  additionalGrowthText,
  tooltipInfo,
  graphData,
  showLegend,
  color,
  loader,
  isTimebasedCard,
}) => {
  return (
    <div className='reporting-container__user-card-container-elem'>
      <div className='reporting-container__user-card-container-elem-data'>
        <div className='report-title'>
          {title}
          &nbsp;
          <TooltipEvergreen
            content={tooltipInfo || 'Not Available'}
            position={Position.TOP}
          >
            <Icon color={'success'} size={14} icon={'info-sign'} />
          </TooltipEvergreen>
        </div>
        {!loader && (
          <>
            <p
              className={
                isTimebasedCard ? 'report-data report-time-zone' : 'report-data'
              }
            >
              {data}
            </p>
            <p className='report-additional-data'>{additionalData}</p>
            <p className='report-growth-section'>
              <img
                src={
                  growthType === 'up'
                    ? getGraphForReporting('up')
                    : getGraphForReporting('down')
                }
                width='14'
                alt='growth-icon'
              />
              &nbsp;
              <span
                className={
                  growthType === 'up' ? 'success-report' : 'danger-report'
                }
              >
                {growth}
              </span>
              &nbsp;{additionalGrowthText}
            </p>
          </>
        )}
        {loader && <div> loading...</div>}
        {/*<TooltipEvergreen content={tooltipInfo}>*/}
        {/*<Button*/}
        {/*height={20}*/}
        {/*className={*/}
        {/*isTimebasedCard*/}
        {/*? 'report-additional-tooltips warning-tint'*/}
        {/*: 'report-additional-tooltips'*/}
        {/*}*/}
        {/*iconBefore={'info-sign'}*/}
        {/*>*/}
        {/*Information*/}
        {/*</Button>*/}
        {/*</TooltipEvergreen>*/}
      </div>
      <div className='reporting-container__user-card-container-elem-graph'>
        {!loader && graphData.length > 0 && (
          <BarChart width={190} height={120} data={graphData}>
            <Tooltip />
            <Bar dataKey='value' fill={color} />
            {showLegend && <Legend />}
            <XAxis dataKey='name' />
          </BarChart>
        )}
        {loader && (
          <div
            className='flex align-center justify-center'
            style={{ height: 120 }}
          >
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};
ReportingCard.propTypes = {
  title: PropTypes.string,
  data: PropTypes.any,
  additionalData: PropTypes.any,
  growth: PropTypes.string,
  growthType: PropTypes.string,
  additionalGrowthText: PropTypes.string,
  tooltipInfo: PropTypes.string,
  graphData: PropTypes.any,
  showLegend: PropTypes.bool,
  color: PropTypes.string,
  loader: PropTypes.bool,
  isTimebasedCard: PropTypes.bool,
};
export default ReportingCard;
