import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'evergreen-ui';
import { navigate } from '@reach/router';
import AuthFooter from './AuthFooter';
import { authData } from '../../../content';
import logoBlack from '../assets/images/logo/dark-alice-logo-full.svg';
import padlockIcon from '../assets/images/logo/padlock-icon.svg';
import AuthHeader from './AuthHeader';

const PasswordVerificationSuccess = () => {
  return (
    <div className='auth-container'>
      <div className='header-container'>
        <div>
          <img className='logo-dark' src={logoBlack} alt='alice logo' />
        </div>
        <div>
          <AuthHeader
            linkText={authData.contactInfo.linkTitle}
            link={authData.contactInfo.link}
            linkInfo={authData.contactInfo.text}
          />
        </div>
      </div>
      <div className='form-container'>
        <div className='form-container__content'>
          <div className='t-center'>
            <img className='padlockIcon' src={padlockIcon} alt='alice logo' />
          </div>
          <br />
          <Button
            onClick={() => navigate(`/`)}
            className='auth-login-button'
            height={40}
          >
            {authData.login.loginButtonTitle}
          </Button>
          <br />
          <br />
          <AuthFooter />
        </div>
      </div>
    </div>
  );
};
PasswordVerificationSuccess.propTypes = {
  email: PropTypes.string,
  password: PropTypes.string,
  content: PropTypes.any,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  loginLoading: PropTypes.bool,
};
export default PasswordVerificationSuccess;
