import React from 'react';
import {
  Position,
  TextInputField,
  SelectMenu,
  Button,
  Icon,
  TextInput,
  Combobox,
    IconButton,
} from 'evergreen-ui';

interface props {
    onDelete: (id: number)=> void;
    data: any;
    onDataChange: any;
    sequences: any[];
}

const FacebookMessengerPersistantMenuModal: React.FC<props> = ({onDelete, data, onDataChange,sequences}) => {
  return (
    <div className='flex flex-child-1'>
      <TextInputField
        label='Title'
        required
        className='rd-input-style'
        placeholder='Your Channel Title'
        inputHeight={40}
        value={data.title}
        onChange={(e:React.ChangeEvent<HTMLInputElement>)=> onDataChange('title', e.target.value)}
      />
     <div className='flex'>
         <div className='ml-20px flex flex-column'>
             <SelectMenu
                 hasTitle={false}
                 hasFilter={false}
                 height={66}
                 closeOnSelect={true}
                 position={Position.BOTTOM_LEFT}
                 options={[
                     { label: 'Select Sequence', value: 'sequence' },
                     { label: 'Type URL', value: 'url' },
                 ]}
                 selected={data.type}
                 onSelect={(value: any) => {
                     onDataChange('type',value.value);
                     onDataChange('value','');
                 }}
             >
                 <Button className='link-style-dropdown'>
                     {data.type === 'url' ? 'Type Url': 'Select Sequence'}
                     &nbsp;&nbsp;
                     <Icon className={'mt-5px'} icon='chevron-down' size={16} />
                 </Button>
             </SelectMenu>
             {data.type === 'url' && (
                 <TextInput
                     className='rd-input-style'
                     placeholder='Type Here...'
                     height={40}
                     width={'100%'}
                     value={data.value}
                     onChange={(e:React.ChangeEvent<HTMLInputElement>)=> onDataChange('value', e.target.value)}
                 />
             )}
             {data.type === 'sequence' && (
                 <Combobox
                     openOnFocus
                     width='100%'
                     initialSelectedItem={sequences.filter((itm:any) => itm.id === data.value)[0]}
                     items={sequences}
                     itemToString={(item) => (item ? item.title : '')}
                     onChange={(selected) => selected && onDataChange('value',selected.id)}
                     placeholder='Sequence'
                     className={'rd-input-style-combobox'}
                     height={40}
                 />
             )}
         </div>
        <IconButton icon={'trash'} intent={'danger'} onClick={()=>{onDelete(data.id)}} marginLeft={10} marginTop={23} appearance='minimal' height={40}/>
      </div>
    </div>
  );
};

export default FacebookMessengerPersistantMenuModal;
