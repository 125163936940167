import React, { useEffect, useState } from 'react';
import TicketCard from './components/TicketCard';
import { connect } from 'react-redux';
import sadEmoji from './assets/images/sademoji.svg';
import soundIcon from './assets/icon/soundIcon.svg';
import menuIcon from '../../assets/images/menuIcon.svg';
import {
  FilterIcon,
  SortAlphabeticalDescIcon,
  SortAlphabeticalIcon,
} from 'evergreen-ui';
import {
  Spinner,
  SearchInput,
  Popover,
  Position,
  Menu,
  Button,
  Pill,
  Switch,
  Icon,
  CornerDialog,
} from 'evergreen-ui';
import { getShortNumber } from '../../utils';
import LinkIcon from './assets/icon/linkIcon.svg';
import {navigate} from "@reach/router";

interface platformProps {
  id: string;
  name: string;
  type: string;
  value: string;
  title: string;
}

interface tabDetails {
  name: string;
  value: string;
}

interface props {
  selectedPlatform: platformProps;
  updateStateData: (key: any, val: any) => void;
  queue: any[];
  selectedQueue: tabDetails;
  ticketLoading: boolean;
  currentTicket: any;
  offset: number;
  limit: number;
  ticketOrder: string;
  ticketScrollLoading: boolean;
  fetchTicketsOnScroll: any;
  projectId: number;
  total: number;
  type: string;
  selectedProject: any;
  fetchUserInformation: (customerId: number) => void;
  fetchMessengerChat: (ticketId: number) => void;
  myQueueCount: null | number;
  allQueueCount: null | number;
  fetchFeedData: (ticketId: number) => void;
  channelEventEmitter: (customerId: number) => void;
  updateTicketQueue: (data: any) => void;
  pusherConnection: any;
  fetchTickets: any;
  platforms: platformProps[];
  fetchTicketCount: () => void;
  hasWriteAccess: boolean;
  isResolved: () => number;
  updateIsResolved: (data: number) => void;
  updateSearch: (value: string) => void;
  searchKeyword: string;
  onFilterClick: () => void;
  onSearch: () => void;
  fetchCustomerMeta: (customerId: number) => void;
  filterData: () => any;
  handleEnCodeData: (key: string) => string;
  handleOnFilter: (key: boolean) => boolean;
  fetchEventTicketData: (event: any) => void;
  isAlertCreateTicket: boolean;
  isAlertUpdateTicket: boolean;
  soundDuration: number;
  editNotificationKey: (projectId: number, key: string, value: boolean) => void;
  browserNotification: boolean;
  feedChannelEventEmitter: (ticketId: number) => void;
  fetchCRMCustomerOrder: (
    teamId: number,
    customerId: number,
    orderId: number | string
  ) => void;
  customerMeta: any;
  location: any;
  getTicketOrder: () => string;
}

const LeftBar: React.FC<props> = ({
  updateStateData,
  queue,
  ticketLoading,
  currentTicket,
  offset,
  limit,
  ticketOrder,
  ticketScrollLoading,
  fetchTickets,
  fetchTicketsOnScroll,
  projectId,
  total,
  fetchUserInformation,
  fetchMessengerChat,
  fetchFeedData,
  channelEventEmitter,
  pusherConnection,
  updateTicketQueue,
  hasWriteAccess,
  isResolved,
  updateIsResolved,
  updateSearch,
  searchKeyword,
  onFilterClick,
  onSearch,
  fetchCustomerMeta,
  filterData,
  handleEnCodeData,
  handleOnFilter,
  fetchEventTicketData,
  isAlertUpdateTicket,
  isAlertCreateTicket,
  soundDuration,
  editNotificationKey,
  browserNotification,
  feedChannelEventEmitter,
  fetchCRMCustomerOrder,
  customerMeta,
  location,
  getTicketOrder,
}) => {
  const [delayLoading, setDelayLoading] = useState(false);
  const [channelId, setChannelId] = useState('');
  const filterResolveMenu = [
    { label: 'Pending', value: 0 },
    { label: 'Resolved', value: 1 },
  ];
  const [isCornerDialogueShown, setIsCornerDialogueShown] = React.useState(
    false
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      updateStateData('msgCount', 0);
    }, soundDuration);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  const checkTicketQueue = (event: any) => {
    if (
      filterTicketQueue('resolved', event) &&
      filterTicketQueue('channels', event) &&
      getTicketOrder() === 'desc' &&
      (filterTicketQueue('agents', event) || filterTicketQueue('groups', event))
    ) {
      // we dont need it for startDate,endDate,tags, Note: groups and agents are in xor relation and rest in &&
      updateTicketQueue(event);

      //only ticket from no ticket fetch rest of info
      fetchEventTicketData(event);
    }
  };

  const filterTicketQueue = (key: string, event: any) => {
    const {
      channels,
      agents,
      agentSelection,
      groups,
      groupSelection,
    } = filterData();
    //we are passing function not value of state because useEffect binds this once and keeps that filterData binded to that function
    // therefore when we pass function instead of value we get return of new and updated filterData where if we used value we would have got old data that was binded to event method
    switch (key) {
      case 'resolved':
        return !isResolved();
      case 'channels':
        let flagChannel: boolean = false;
        if (filterData().channels.length === 0) {
          return true;
        } else {
          channels.forEach((ch: any) => {
            if (ch.id === event?.customer?.platform?.id) {
              flagChannel = true;
            }
          });
        }
        return flagChannel;
      case 'agents':
        let flagAgent: boolean = false;
        if (agentSelection === '') {
          //check agents list
          agents.forEach((agt: any) => {
            if (
              event.agents.length > 0 &&
              agt.admin.id === event.agents[0].id
            ) {
              flagAgent = true;
            }
          });
        } else if (agentSelection === 'all') {
          flagAgent = true;
        } else if (agentSelection === 'none') {
          flagAgent = false;
        }
        return flagAgent;
      case 'groups':
        let flagGroups: boolean = false;

        if (groupSelection === '') {
          //check groups list
          groups.forEach((grp: any) => {
            if (event.groups.length > 0 && grp.id === event.groups[0].id) {
              flagGroups = true;
            }
          });
        } else if (groupSelection === 'all') {
          flagGroups = true;
        } else if (groupSelection === 'none') {
          flagGroups = false;
        }
        return flagGroups;
      default:
        return false;
    }
  };

  useEffect(() => {
    updateStateData('msgCount', 0);
    const channelEventEmitterForTicket = () => {
      if (!!channelId) {
        pusherConnection.unsubscribe(channelId);
      }

      if (pusherConnection) {
        const ticketChannel = pusherConnection.subscribe(
          `ticket-T_${projectId}`
        );

        setChannelId(`ticket-T_${projectId}`);

        ticketChannel.bind('ticket-update', (event: any) => {
          checkTicketQueue(event);
        });
      }
    };

    channelEventEmitterForTicket();
    // eslint-disable-next-line
  }, [pusherConnection]);

  const handleScroll = (id: string) => {
    let el = document.getElementById(id);
    if (!!el) {
      if (el.scrollTop + el.offsetHeight > el.scrollHeight - 1) {
        // check if ticketLength === total

        //resolved all
        if (
          !ticketScrollLoading &&
          queue.length < total &&
          !delayLoading &&
          hasWriteAccess
        ) {
          setDelayLoading(true);
          setTimeout(async () => {
            // projectId: number,
            // isResolved: any,
            // offset: number,
            // limit: number,
            // search: string,
            // channels: string,
            // agents: string,
            // groups: string,
            // tags: string,
            // start: string,
            // end: string,

            await fetchTicketsOnScroll(
              projectId,
              isResolved(),
              offset + limit,
              limit,
              searchKeyword, //search
              handleEnCodeData('channels'), //channel
              handleEnCodeData('agents'), //agents
              handleEnCodeData('groups'), // groups
              handleEnCodeData('tags'), // tags
              handleEnCodeData('startDate'), //start
              handleEnCodeData('endDate'), //end
              handleEnCodeData('ticketOrder') //ticketOrder
            );
            setDelayLoading(false);
          }, 1000);
        }
      }
    }
  };


  const toggleSort = async () => {
    if (ticketOrder === 'desc') {
      updateStateData('ticketOrder', 'asc');
      const url = new URL(location.href);
      let params = new URLSearchParams(url.searchParams);
      params.set('order', 'asc');
      navigate(`${location.pathname}?${params.toString()}`);

    } else {
      updateStateData('ticketOrder', 'desc');
      const url = new URL(location.href);
      let params = new URLSearchParams(url.searchParams);
      params.set('order', 'desc');
      navigate(`${location.pathname}?${params.toString()}`);
    }
    setTimeout(() => {
      handleOnFilter(true);
    }, 500);
  };
  return (
    <div className='customer-list'>
      <div className='customer-list__platform-filter'>
        <div className='flex space-between align-center mt-10px'>
          <Popover
            position={Position.BOTTOM_LEFT}
            content={({ close }: any) => (
              <Menu>
                <Menu.Group>
                  {filterResolveMenu.map((elem, index: number) => (
                    <Menu.Item
                      key={index}
                      onSelect={() => {
                        updateIsResolved(elem.value);
                        close();
                      }}
                    >
                      {elem.label}
                    </Menu.Item>
                  ))}
                </Menu.Group>
              </Menu>
            )}
          >
            <Button className='alice-btn__default' height={40} marginRight={16}>
              {isResolved() === 0 ? 'Pending' : 'Resolved'}
              <Pill display='inline-flex' color='green' margin={8}>
                {getShortNumber(total)}
              </Pill>
              &nbsp; &nbsp;
              <Icon icon='caret-down' size={16} />
            </Button>
          </Popover>

          <div className='flex flex-end'>
            <Popover
              position={Position.BOTTOM}
              minWidth={250}
              content={() => (
                <Menu>
                  <Menu.Group>
                    <Menu.Item key={1}>
                      <div className='flex space-between align-center'>
                        <span>Incoming Tickets Sound</span>
                        <Switch
                          className='switch-rd'
                          hasCheckIcon={false}
                          checked={isAlertCreateTicket}
                          onChange={(e: React.ChangeEvent<any>) => {
                            editNotificationKey(
                              projectId,
                              'ticket_sound_on',
                              e.target.checked
                            );
                          }}
                        />
                      </div>
                    </Menu.Item>
                    <Menu.Item key={2}>
                      <div className='flex space-between align-center'>
                        <span>Incoming Message Sound</span>
                        <Switch
                          className='switch-rd'
                          hasCheckIcon={false}
                          checked={isAlertUpdateTicket}
                          onChange={(e: React.ChangeEvent<any>) => {
                            editNotificationKey(
                              projectId,
                              'message_sound_on',
                              e.target.checked
                            );
                          }}
                        />
                      </div>
                    </Menu.Item>
                    {//this is to check if your browser supports web Notification
                    !!window.Notification && (
                      <Menu.Item key={3}>
                        <div className='flex space-between align-center'>
                          <span>
                            Browser Notification &nbsp;
                            <a
                              href='https://docs.getalice.ai/inbox'
                              rel='noopener noreferrer'
                              target='_blank'
                            >
                              <img src={LinkIcon} alt='link' />
                            </a>
                          </span>
                          <Switch
                            className='switch-rd'
                            hasCheckIcon={false}
                            disabled={!window.Notification}
                            checked={
                              !!window.Notification &&
                              window.Notification.permission !== 'denied' &&
                              browserNotification
                            }
                            onChange={(e: React.ChangeEvent<any>) => {
                              if (!!window.Notification) {
                                if (
                                  window.Notification.permission === 'denied'
                                ) {
                                  setIsCornerDialogueShown(true);
                                } else {
                                  // ask or granted
                                  editNotificationKey(
                                    projectId,
                                    'browser_notification_on',
                                    e.target.checked
                                  );
                                }
                              }
                            }}
                          />
                        </div>
                      </Menu.Item>
                    )}
                  </Menu.Group>
                </Menu>
              )}
            >
              <Button
                className='alice-btn__default'
                height={32}
                width={36}
                marginRight={8}
                style={{ paddingLeft: 6, paddingRight: 4 }}
              >
                <img src={soundIcon} alt='soundIcon' />
              </Button>
            </Popover>
            <Popover
              position={Position.BOTTOM}
              minWidth={250}
              content={() => (
                <Menu>
                  <Menu.Group>
                    <Menu.Item
                      key={1}
                      icon={
                        ticketOrder === 'desc'
                          ? SortAlphabeticalDescIcon
                          : SortAlphabeticalIcon
                      }
                      onSelect={() => toggleSort()}
                    >
                      <div className='flex space-between align-center'>
                        <span>
                          {ticketOrder === 'desc'
                            ? 'Sort: Newest to Oldest'
                            : 'Sort: Oldest to Newest'}
                        </span>
                      </div>
                    </Menu.Item>
                    <Menu.Item
                      key={2}
                      onSelect={() => onFilterClick()}
                      icon={FilterIcon}
                    >
                      <div className='flex space-between align-center'>
                        <span>Filter</span>
                      </div>
                    </Menu.Item>
                  </Menu.Group>
                </Menu>
              )}
            >
              <Button
                className='alice-btn__default'
                height={32}
                width={36}
                style={{ paddingLeft: 8, paddingRight: 4 }}
              >
                <img src={menuIcon} width={20} alt='menuIcon' />
              </Button>
            </Popover>
          </div>
        </div>
      </div>
      <div className='customer-list__main-container'>
        <div style={{ margin: '10px 15px' }}>
          <SearchInput
            className='alice-rd-search'
            type='text'
            placeholder='Search Customer Name...'
            value={searchKeyword}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateSearch(e.target.value);
            }}
            onKeyPress={(e: any) => {
              if (e.key === 'Enter') {
                onSearch();
              }
            }}
            height={40}
            width={'100%'}
          />
        </div>
        {ticketLoading && (
          <div className='customer-list__container overflow-hidden'>
            <div className='customer-list__container-no-data'>
              <Spinner size={26} />
            </div>
          </div>
        )}
        {!ticketLoading && (
          <div
            className='customer-list__container'
            id='ticket-bar'
            onScroll={() => handleScroll('ticket-bar')}
          >
            {!!queue &&
              queue.length > 0 &&
              queue.map((ticket, i) => (
                <TicketCard
                  key={i}
                  id={ticket.id}
                  currentTicket={currentTicket}
                  customerData={ticket.customer}
                  ticketPlatform={ticket.customer.platform}
                  agents={ticket.agents}
                  groups={ticket.groups}
                  locked={ticket.is_locked}
                  isReplied={ticket.is_replied}
                  createdAt={ticket.created_at}
                  priority={ticket.priority_str}
                  handleCurrentTicket={async () => {
                    document.title = 'Alice - Conversation AI Platform';
                    fetchUserInformation(ticket.customer?.id);
                    await fetchCustomerMeta(ticket.customer?.id);
                    if (customerMeta?.ecommerce_collection_reference) {
                      fetchCRMCustomerOrder(projectId, ticket.customer?.id, '');
                    }
                    updateStateData('currentTicket', ticket);
                    const platformType = ticket.customer.platform.type;
                    const messengerTypes = [
                      'facebook_messenger',
                      'viber_messenger',
                      'webchat',
                      'app_messenger',
                      'whatsapp_messenger',
                    ];
                    if (messengerTypes.includes(platformType)) {
                      await fetchMessengerChat(ticket.id);
                      channelEventEmitter(ticket.customer.id);
                    } else if (platformType === 'facebook_feed') {
                      await fetchFeedData(ticket.id);
                      feedChannelEventEmitter(ticket.id);
                    }
                  }}
                />
              ))}
            {(!queue || queue.length === 0) && (
              <div className='customer-list__container-no-data'>
                <div className={'flex align-center'}>
                  <img src={sadEmoji} alt='sad emoji' width={16} />
                  <p>No Tickets Available</p>
                </div>
              </div>
            )}
            {(ticketScrollLoading || delayLoading) && (
              <div className='customer-list__container-loader'>
                <Spinner size={26} />
              </div>
            )}
          </div>
        )}
        {ticketLoading && (
          <div className='customer-list__container overflow-hidden'>
            <div className='customer-list__container-no-data'>
              <Spinner size={26} />
            </div>
          </div>
        )}
      </div>
      <CornerDialog
        title='Notification is Disabled'
        isShown={isCornerDialogueShown}
        onCloseComplete={() => setIsCornerDialogueShown(false)}
        hasFooter={false}
      >
        <div>
          Notification permission is disabled from your browser. To allow
          Browser Notifications, please follow the steps:
          <p>1. Go to Settings</p>
          <p>2. Go to Site Settings from the Privacy and Security section </p>
          <p>3. Find Notifications and click on Allow</p>
        </div>
      </CornerDialog>
    </div>
  );
};

const mapState = (state: any) => ({
  queue: state.crm.queue,
  selectedQueue: state.crm.selectedQueue,
  selectedPlatform: state.crm.selectedPlatform,
  ticketLoading: state.loading.effects.crm.fetchTickets,
  ticketScrollLoading: state.loading.effects.crm.fetchTicketsOnScroll,
  currentTicket: state.crm.currentTicket,
  customerMeta: state.crm.customerMeta,
  limit: state.crm.limit,
  total: state.crm.total,
  offset: state.crm.offset,
  ticketOrder: state.crm.ticketOrder,
  selectedProject: state.dashboard.selectedProject,
  myQueueCount: state.crm.myQueueCount,
  allQueueCount: state.crm.allQueueCount,
  platforms: state.dashboard.selectedProject.platforms,
  isAlertCreateTicket: state.crm.isAlertCreateTicket,
  isAlertUpdateTicket: state.crm.isAlertUpdateTicket,
  soundDuration: state.crm.soundDuration,
  browserNotification: state.crm.browserNotification,
});

const mapDispatch = (dispatch: any) => ({
  updateTicketQueue: (data: any) => dispatch.crm.updateTicketQueue(data),
  updateStateData: (key: string, value: any) =>
    dispatch.crm.updateStateData({ key, value }),
  fetchTickets: (
    projectId: number,
    isResolved: any,
    offset: number,
    limit: number,
    search: string,
    channels: string,
    agents: string,
    groups: string,
    tags: string,
    start: string,
    end: string,
    ticketOrder: string
  ) =>
    dispatch.crm.fetchTickets({
      projectId,
      isResolved,
      offset,
      limit,
      search,
      channels,
      agents,
      groups,
      tags,
      start,
      end,
      ticketOrder,
    }),
  fetchTicketsOnScroll: (
    projectId: number,
    isResolved: any,
    offset: number,
    limit: number,
    search: string,
    channels: string,
    agents: string,
    groups: string,
    tags: string,
    start: string,
    end: string,
    ticketOrder: string
  ) =>
    dispatch.crm.fetchTicketsOnScroll({
      projectId,
      isResolved,
      offset,
      limit,
      search,
      channels,
      agents,
      groups,
      tags,
      start,
      end,
      ticketOrder,
    }),
  fetchUserInformation: (customerId: number) =>
    dispatch.crm.fetchUserInformation(customerId),
  fetchCustomerMeta: (customerId: number) =>
    dispatch.crm.fetchCustomerMeta(customerId),
  fetchMessengerChat: (ticketId: number) =>
    dispatch.crm.fetchMessengerChat(ticketId),
  fetchFeedData: (ticketId: number) => dispatch.crm.fetchFeedData(ticketId),
  editNotificationKey: (projectId: number, key: string, value: boolean) =>
    dispatch.crm.editNotificationKey({
      projectId,
      key,
      value,
    }),
});

const LeftBarContainer = connect(mapState, mapDispatch)(LeftBar);
export default LeftBarContainer;
