import React, { Component } from 'react';
import { toaster, Button } from 'evergreen-ui';
import SmartInput from '../data-lab-details/components/SmartInput';
import axios from 'axios';
import config from '../../config';
import { validateInputFields, checkInputLength } from '../../utils';
import './assets/styles/style.scss';

class DataLabForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [],
      lab: null,
      button: null,
      inputs: {},
      loading: true,
      submitted: false,
      customerId: null,
      formSequence: null,
    };
  }

  fetchDataLabFields = async () => {
    const headers = {
      Authorization: `Token ${config.token}`,
    };
    try {
      const res = await axios.get(
        `${config.labs}/${this.props.labId}/submission`,
        { headers }
      );
      if (res.status === 200 && res.data.success) {
        const { lab_fields, button, ...data } = res.data.dataSource;
        this.setState({ lab: data, fields: lab_fields, button });
      } else {
        toaster.danger('Failed to Fetch Data', {
          description: `We are failed to fetch data ! Try Again Later`,
        });
      }
    } catch (err) {
      toaster.danger('Exception Error', {
        description: `There are some exception error in the request`,
      });
    }
  };

  postDataLabForm = async () => {
    const headers = {
      Authorization: `Token ${config.token}`,
    };
    const data = {
      customer_id: this.state.customerId,
      form_sequence: this.state.formSequence,
      data: this.state.inputs,
    };

    try {
      const res = await axios.post(
        `${config.labs}/${this.props.labId}/submission`,
        data,
        { headers }
      );
      if (res.status === 200 && res.data.success) {
        window.MessengerExtensions.requestCloseBrowser(
          function success() {
            // webview closed
          },
          function error(err) {
            toaster.success('Form Submitted Successfully');
          }
        );
      }
      if (res.status === 200 && res.data.success) {
        this.setState({ inputs: {} });
      } else {
        toaster.danger('Failed to Submit Data');
      }
    } catch (err) {
      toaster.danger('Exception Error', {
        description: `There are some exception error in the request`,
      });
    }
  };

  handleInputChange = (id, val) => {
    this.setState({ inputs: { ...this.state.inputs, [id]: val } });
  };

  handleFormSubmit = async () => {
    if (validateInputFields(this.state.inputs, this.state.fields)) {
      let isValid = checkInputLength(this.state.fields, this.state.inputs);
      if (isValid) {
        await this.postDataLabForm();
        this.setState({ submitted: true });
      }
    } else {
      toaster.danger('Please fill all required fields');
    }
  };

  async componentDidMount() {
    document.body.classList.add('form-body');
    await this.fetchDataLabFields();
    document.title =
      this.state?.lab?.project_name ||
      this.state?.lab?.title ||
      'Data Lab Submission';

    const params = new URLSearchParams(this.props.location.search.slice(1));

    if (params.has('customer_id')) {
      this.setState({
        customerId: parseInt(params.get('customer_id')),
      });
    }

    if (params.has('form_sequence')) {
      this.setState({
        formSequence: parseInt(params.get('form_sequence')),
      });
    }

    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/messenger.Extensions.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'Messenger');
  }

  render() {
    return (
      <div
        className='data-lab-form__container'
        style={this.state.submitted ? { background: 'white' } : null}
      >
        {this.state.lab && (
          <div className='data-lab-form__header'>
            {!!this.state.lab.logo.url && (
              <img src={this.state.lab.logo.url} alt='Logo' />
            )}
            <p className='data-lab-form__header-title'>
              {this.state.lab.title}
            </p>
          </div>
        )}
        {!this.state.submitted &&
          !!this.state.fields.length > 0 &&
          this.state.fields
            .filter((f) => !f.is_hidden)
            .map((item, i) => (
              <SmartInput
                key={i}
                {...item}
                value={this.state.inputs[item.id]}
                handleChange={(val) => this.handleInputChange(item.id, val)}
              />
            ))}
        {!this.state.submitted && this.state.button && (
          <Button
            style={{
              background: this.state.button.background_color,
              color: this.state.button.font_color,
            }}
            height={42}
            className='data-lab-form-submit'
            onClick={this.handleFormSubmit}
          >
            {this.state.button.name}
          </Button>
        )}

        {this.state.submitted && (
          <h2>Your form has been submitted successfully</h2>
        )}
      </div>
    );
  }
}

export default DataLabForm;
