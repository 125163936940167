import React from 'react';
import { Slider, SliderMarker } from '@reach/slider';

interface props {
    data: any;
    updateEditModalInfo: (key: string, value: any) => void;
}

const SliderNLP: React.FC<props> = ({data,updateEditModalInfo}) => {
    return <Slider
        min={0}
        max={100}
        className='slider-container'
        tabIndex={undefined}
        step={5}
        value={data.nlp_confidence * 100}
        onChange={(value: any) =>
            updateEditModalInfo('nlp_confidence', value / 100)
        }
    >
        {new Array(11).fill(0).map((x, index) => (
            <SliderMarker
                key={index}
                value={index * 10}
                className='slider-container__marker'
            >
                <p>{index / 10}</p>
            </SliderMarker>
        ))}
    </Slider>
}
;

export default SliderNLP;
