import React from 'react';
import { Popover, Pane, Position, Table, IconButton } from 'evergreen-ui';
import PropTypes from 'prop-types';

const AttributeAPIContainer = ({
  attributeList,
  showAttribute,
  setShowAttribute,
  apiList,
  showAPI,
  setShowAPI,
  handleSelect,
  children,
}) => {
  return (
    <Popover
      isShown={!!showAttribute || !!showAPI}
      position={Position.TOP_LEFT}
      content={() => (
        <Pane height={300} style={{ overflow: 'auto' }}>
          <div className={'attribute-container__header'}>
            {showAttribute
              ? 'Select an attribute'
              : showAPI
              ? 'Select an API'
              : 'Title'}
            <IconButton
              style={{ position: 'absolute', right: 5, color: 'inherit' }}
              onClick={() =>
                showAttribute ? setShowAttribute(false) : setShowAPI(false)
              }
              marginBottom={16}
              appearance='minimal'
              height={24}
              icon='cross'
            />
          </div>
          {showAttribute &&
            attributeList.map((intent, i) => {
              return (
                <Table.Row
                  key={i}
                  isSelectable
                  onSelect={() => handleSelect(intent)}
                >
                  <Table.TextCell>{'{{' + intent + '}}'}</Table.TextCell>
                </Table.Row>
              );
            })}
          {showAPI &&
            apiList.map((apis, i) => {
              return (
                <Table.Row
                  key={i}
                  isSelectable
                  onSelect={() => handleSelect(apis.title)}
                >
                  <Table.TextCell>{'<<' + apis.title + '>>'}</Table.TextCell>
                </Table.Row>
              );
            })}
        </Pane>
      )}
    >
      {children}
    </Popover>
  );
};

AttributeAPIContainer.propTypes = {
  attributeList: PropTypes.arrayOf(PropTypes.string).isRequired,
  showAttribute: PropTypes.bool.isRequired,
  setShowAttribute: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  children: PropTypes.object,
};

export default AttributeAPIContainer;
