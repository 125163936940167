import React from 'react';
import {SearchInput, Spinner} from "evergreen-ui";
import OrderCardRightbar from "./OrderCardRightbar";
import noDataFound from './../../../assets/images/no-data-found.svg';

interface Prop {
  eComOrderList: any[];
  loader: boolean;
  fetchCRMCustomerOrder: (search:string) => void;
}

const OrderHistoryRightbarContainer: React.FC<Prop> = ({eComOrderList, loader,fetchCRMCustomerOrder,}) => {

  const [search, setSearch] = React.useState<string>('');

  return (
    <div className='order-right-bar__container'>
      <SearchInput
        className='alice-rd-search'
        type='number'
        placeholder="Search By Order ID"
        value={search}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSearch(e.target.value);
          if(!e.target.value) {
            fetchCRMCustomerOrder('');
          }
        }}
        onKeyPress={(e: any) => {
          if (e.key === 'Enter') {
            fetchCRMCustomerOrder(search);
          }
        }}
        height={40}
        width={'100%'}/>
      {!loader && <div className='order-right-bar__order-lists'>
        {eComOrderList.length > 0 &&
        eComOrderList.map((order: any, index: number) =>
          <OrderCardRightbar
            orderId={order.order_id}
            orderLink={order?.permalink}
            isPaid={!!order?.date_paid_gmt}
            isCompleted={!!order?.data_completed_gmt}
            coupon={order?.coupon_code_info}
            status={order?.status}
            refund={order?.refund_info.toString()}
            orderDate={order?.date_created_gmt || 'Not Available'}
            totalCost={order?.total_cost || 'N/A'}
            paymentMethod={order?.payment_method || 'Not Available'}
            billingInfo={order?.billing_info || 'Not Available'}
            shippingInfo={order?.shipping_info || 'Not Available'}
            totalTax={order?.total_tax || 'N/A'}
            shippingCost={order?.total_shipping_cost || 'N/A'}
            discount={order?.total_discount || 'N/A'}
            key={index}
            shippingMethod={order?.shipping_method || 'Not Available'}
            products={order?.products || []}
          />
        )
        }
        {eComOrderList.length === 0 &&
        <div className='flex justify-center align-center h-100p flex-column mb-20px'>
          <img src={noDataFound} alt='no data' width='100px'/>
          <p className='description'>No Order found!</p>
        </div>
        }
      </div>}
      {loader && <div className='order-right-bar__order-lists'>
        <div className='w-100p h-100p mh-200px flex align-center justify-center'>
          <Spinner/>
        </div>
      </div>}
    </div>
  );
};

export default OrderHistoryRightbarContainer;
