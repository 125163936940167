import React, {ChangeEvent} from 'react';
import {
  Switch,
  Tooltip,
  Button,
  SideSheet,
  Dialog,
  Pill,
  Avatar,
  Heading,
  Icon,
} from 'evergreen-ui';
import TicketTag from "./TicketTag";
import AssignSideSheet from "./AssignSideSheet";
import {findAlertInfo} from "../../../utils";
import TagRedesign from "../../../components/elements/redesign/TagRedesign";
import refreshIcon from './../../../assets/images/refresh.svg';
import tagIcon from './../assets/icon/tagIcon.svg';

interface props {
  ticketsAvailable: boolean;
  handleAction: (body: any, type: string) => void;
  isResolved: boolean;
  currentTicket: any;
  handleBotAction: (body: any) => any;
  assignableAdmins: any;
  isBotEnabled: boolean;
  assignAdmin: (currentTicketData: any) => Promise<boolean>;
  fetchAllTicket: any;
  authId: number;
  agentGroup: any;
  updateStateData: (key: any, value: any) => void;
  resolveLoading: boolean;
}

const CRMActionBar: React.FC<props> = (
  {
    ticketsAvailable,
    handleAction,
    isResolved,
    currentTicket,
    handleBotAction,
    isBotEnabled,
    assignableAdmins,
    assignAdmin,
    fetchAllTicket,
    authId,
    agentGroup,
    updateStateData,
    resolveLoading,
  }) => {
  const [isAdminOpen, setIsAdminOpen] = React.useState(false);
  const [isTagOpen, setIsTagOpen] = React.useState(false);
  const [tempAssign, setTempAssign] = React.useState<any>(null);
  const [assignType, setAssignType] = React.useState<any>(null);
  const [isAssignModalShown, setIsAssignModalShown] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState('');

  const handleAssign = async (adminId: number, type: string) => {


    let currentTicketLocal = JSON.parse(JSON.stringify(currentTicket));

    //selected / assigned
    if (type === 'agent') {
      const findThatAgent = assignableAdmins.filter((agentElem: any) => agentElem.admin.id === adminId)[0];
      currentTicketLocal = {...currentTicketLocal, agents: [findThatAgent.admin], groups: []}
    } else if (type === 'agentgroup') {
      const findThatAgentGroup = agentGroup.filter((agentElem: any) => agentElem.id === adminId)[0];
      currentTicketLocal = {...currentTicketLocal, groups: [findThatAgentGroup], agents: []}
    }
    const res = await assignAdmin(currentTicketLocal);
    if (res) {
      updateStateData('currentTicket', currentTicketLocal);
      fetchAllTicket();
      setIsAdminOpen(false);
      setIsAssignModalShown(false);
    } else {
      setIsAssignModalShown(false);
    }
  };
  return (
    <div className='customer-chat__action-bar'>
      <>
        {ticketsAvailable && (
          <div className='customer-chat__action-bar-ticket'>
            <div className='customer-chat__action-bar-ticket-tag'>
              <div className='flex align-center'>
                {currentTicket.tags.length > 0
                && (<div className='customer-chat__action-bar-ticket-tag-name'>
                  {currentTicket.tags.length > 1 ?
                    <>
                      <TagRedesign
                        data={{
                          label: currentTicket.tags[0].name.length > 9 ? currentTicket.tags[0].name.substring(0, 9).toUpperCase() + "..." : currentTicket.tags[0].name.toUpperCase(),
                          value: currentTicket.tags[0].name
                        }}
                        size={12}
                      /> &nbsp;
                      <TagRedesign
                        data={{
                          label: currentTicket.tags[1].name.length > 9 ? currentTicket.tags[1].name.substring(0, 9).toUpperCase() + "..." : currentTicket.tags[1].name.toUpperCase(),
                          value: currentTicket.tags[1].name
                        }}
                        size={12}
                      />
                    </>
                    : <>
                      <TagRedesign
                        data={{
                          label: currentTicket.tags[0].name.length > 9 ? currentTicket.tags[0].name.substring(0, 9).toUpperCase() + "..." : currentTicket.tags[0].name.toUpperCase(),
                          value: currentTicket.tags[0].name
                        }}
                        size={12}
                      /> &nbsp;
                    </>
                  }
                </div>)
                }
                {currentTicket.tags.length > 2
                && (<>
                  <Pill
                    color='green'
                    isInteractive
                    onClick={() => setIsTagOpen(true)}
                  >
                    +{currentTicket.tags.length - 2}
                  </Pill> &nbsp;
                </>)
                }
                <Button
                  className={currentTicket.tags.length !== 0
                    ? 'alice-btn__default'
                    : 'alice-btn__default mt-5px'}
                  style={{padding: '0 5px'}}
                  marginRight={10}
                  height={26}
                  onClick={() => {
                    setIsTagOpen(true)
                  }}
                >
                  <img src={tagIcon} width={20} alt='tag icon'/>
                </Button>
              </div>
            </div>
            <Dialog
              topOffset={'200px'}
              isShown={isTagOpen}
              onCloseComplete={() => setIsTagOpen(false)}
              hasHeader={false}
              hasFooter={false}
              preventBodyScrolling={false}
            >
              <TicketTag/>
            </Dialog>
          </div>
        )}
      </>
      <div className='customer-chat__action-bar-admin-buttons'>
        {ticketsAvailable && (
          <>
            <div className='customer-chat__action-bar-admin assign-button'>
              {/*this section of logic means if ticket is not assigned or first agents id is same as current logged in user */}
              {/*they can assign to new user*/}
              <div className='flex align-center mr-15px'>
                BOT &nbsp;&nbsp;
                <Switch
                  checked={isBotEnabled || false}
                  className='switch-rd'
                  onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                    handleBotAction({bot_enabled: evt.target.checked})
                  }}
                  hasCheckIcon={false}/>
              </div>
              {(currentTicket.agents.length === 0 ||
                currentTicket.groups.length === 0 ||
                currentTicket.agents[0]?.id === authId) && (
                <>
                  {currentTicket.agents.length === 0 && currentTicket.groups.length === 0 &&
                  <Button
                    marginRight={10}
                    height={34}
                    iconBefore={isResolved ? 'unlock' : 'refresh'}
                    onClick={() => {
                      setIsAdminOpen(true);
                    }}
                    disabled={isResolved}
                    className='alice-btn__secondary-outlined'
                  >
                    {isResolved ? 'Reopen' : 'Assign'}
                  </Button>
                  }
                  {currentTicket.agents.length > 0 &&
                  <Button
                    marginRight={10}
                    height={34}
                    onClick={() => {
                      setIsAdminOpen(true);
                    }}
                    disabled={isResolved}
                    className='alice-btn__secondary-outlined'
                    style={{padding: '0 10px'}}
                  >
                    {!!currentTicket.agents[0].avatar &&
                    <><Avatar src={currentTicket.agents[0].avatar} size={16}/>&nbsp;</>
                    }
                    {!!currentTicket.agents[0].full_name &&
                    <>
                      <Heading size={300}>{currentTicket.agents[0].full_name.length > 9 ? currentTicket.agents[0].full_name.substring(0,9)+"..": currentTicket.agents[0].full_name}</Heading>
                      &nbsp;<Icon icon='chevron-down' size={16} />
                    </>
                    }
                  </Button>
                  }
                  {currentTicket.groups.length > 0 &&
                  <Button
                    marginRight={10}
                    height={34}
                    onClick={() => {
                      setIsAdminOpen(true);
                    }}
                    disabled={isResolved}
                    className='alice-btn__secondary-outlined'
                    style={{padding: '0 10px'}}
                  >
                    {!!currentTicket.groups[0].name &&
                    <><Avatar src={currentTicket.groups[0].image} name={currentTicket.groups[0].name} size={16}/>&nbsp;</>
                    }
                    {!!currentTicket.groups[0].name &&
                    <>
                      <Heading size={300}>{currentTicket.groups[0].name.length > 9 ? currentTicket.groups[0].name.substring(0,9)+"..": currentTicket.groups[0].name}</Heading>
                      &nbsp;<Icon icon='chevron-down' size={16} />
                    </>
                    }
                  </Button>
                  }
                  <SideSheet
                    isShown={isAdminOpen}
                    width={400}
                    containerProps={{
                      display: 'flex',
                      flex: '1',
                      flexDirection: 'column',
                    }}
                    onCloseComplete={() => setIsAdminOpen(false)}
                  >
                    <AssignSideSheet
                      agentGroup={agentGroup}
                      agents={assignableAdmins}
                      closeSideSheet={() => setIsAdminOpen(false)}
                      search={search}
                      setSearch={setSearch}
                      handleAssign={(adminId: number, type: string) => {
                        let isDeselected = false;
                        if (type === 'agent') {
                          isDeselected =
                            currentTicket.agents.filter(
                              (agentElem: any) => agentElem.id === adminId
                            ).length > 0;
                        } else if (type === 'agentgroup') {
                          isDeselected =
                            currentTicket.groups.filter(
                              (agentElem: any) => agentElem.id === adminId
                            ).length > 0;
                        }
                        if (!isDeselected) {
                          setTempAssign(adminId);
                          setAssignType(type);
                          setIsAssignModalShown(true);
                        }
                      }}
                      currentTicket={currentTicket}/>
                  </SideSheet>
                  <Dialog
                    isShown={isAssignModalShown}
                    hasFooter={false}
                    hasHeader={false}
                    containerProps={{className: 'rd-modal-style'}}
                    onCloseComplete={() => setIsAssignModalShown(false)}
                  >
                    <div className='assign-ticket__alertModal'>
                      <div className='flex align-start-item'>
                        <img src={findAlertInfo('warning').icon} width={30} alt={'modal icon'}/> &nbsp;
                        <p className='assign-ticket__alertModal__title'>&nbsp;About
                          To {(currentTicket.agents.length === 0 && currentTicket.groups.length === 0) ? 'Assign' : 'Reassign'} Ticket</p>
                      </div>
                      <p className='assign-ticket__alertModal__subtitle'>Are you sure you want to reassign <strong>Ticket
                        #{currentTicket.id}?</strong></p>
                      <div className='flex mt-10px'>
                        {currentTicket.agents.map(
                          (agent: any, i: number) =>
                            <TagRedesign
                              key={i}
                              data={{
                                value: agent.id,
                                label: agent.full_name,
                                avatar: agent.avatar || undefined
                              }}
                              size={12} isClosable={false} intent={"active"} hasAvatar={true}/>
                        )
                        }
                        {!!currentTicket.groups && currentTicket?.groups.map(
                          (group: any, i: number) =>
                            <TagRedesign
                              key={i}
                              data={{value: group.id, label: group.name, avatar: group.image || undefined}}
                              size={12}
                              isClosable={false}
                              intent={"active"}
                              hasAvatar={true}/>
                        )
                        }
                        {currentTicket.agents.length === 0 && currentTicket.groups.length === 0 &&
                        <TagRedesign
                          data={{
                            value: null,
                            label: "Unassigned",
                          }}
                          size={12}
                          isClosable={false}
                          intent={"active"}
                          hasAvatar={false}/>
                        }
                        &nbsp;<img src={refreshIcon} width={20} alt='exchange'/> &nbsp;
                        {assignType === 'agent' && assignableAdmins.filter((agent: any) => agent.admin.id === tempAssign).map((agent: any, i: number) =>
                          <TagRedesign
                            key={i}
                            data={{
                              value: agent.admin.id,
                              label: agent.admin.full_name,
                              avatar: agent.admin.avatar || undefined
                            }}
                            size={12}
                            isClosable={false}
                            intent={"success"}
                            hasAvatar={true}/>
                        )}
                        {assignType === 'agentgroup' && agentGroup.filter((group: any) => group.id === tempAssign).map((group: any, i: number) =>
                          <TagRedesign
                            key={i}
                            data={{value: group.id, label: group.name, avatar: group.image || undefined}}
                            size={12}
                            isClosable={false}
                            intent={"success"}
                            hasAvatar={true}/>
                        )}
                      </div>
                      <div className='flex flex-end mt-10px'>
                        <Button
                          className='alice-btn__minimal-black'
                          marginRight={15}
                          onClick={() => {
                            setIsAssignModalShown(false);
                            setAssignType(null);
                            setTempAssign(null);
                            setIsAdminOpen(false);
                          }}>Cancel</Button>
                        <Button
                          className='alice-btn__warning'
                          marginRight={20}
                          onClick={async () => {
                            await handleAssign(tempAssign, assignType);
                          }}>
                          Confirm
                        </Button>
                      </div>
                    </div>
                  </Dialog>
                </>
              )}
            </div>
            <div className='customer-chat__action-bar-buttons'>
              {!isResolved &&
              <Tooltip
                content={'Mark Conversation As Resolved'}
              >
                <Button
                  className='alice-btn__secondary'
                  marginRight={10}
                  height={34}
                  style={{padding: '0 15px'}}
                  disabled={resolveLoading}
                  onClick={() =>
                    handleAction(
                      {is_resolved: true},
                      'resolve'
                    )
                  }
                >
                  Resolve
                </Button>
              </Tooltip>
              }
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CRMActionBar;
