import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const AuthHeader = ({ linkInfo, link, linkText }) => {
  return (
    <Fragment>
      <p className='signup-info'>
        {linkInfo}&nbsp;
        <a href={link} rel='noopener noreferrer'>
          {linkText}
        </a>
      </p>
    </Fragment>
  );
};
AuthHeader.propTypes = {
  linkInfo: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
};
export default AuthHeader;
