import React, { Component } from 'react';
import Pusher from 'pusher-js';
import './assets/style/style.scss';
//components
import CRMReplyInput from './components/CRMReplyInput';
import CRMChat from './components/CRMChat';
import CRMActionBar from './components/CRMActionBar';
import HasPermission from './../../components/HasPermission';
import { connect } from 'react-redux';
import LoaderLine from '../../components/loaders/LoaderLine';
import LeftBar from './LeftBar';
import { checkPermission } from '../../utils';
import ReactGA from 'react-ga';
import RightBar from './RightBar';
import { toaster } from 'evergreen-ui';
import CRMFeedPost from './components/CRMFeedPost';
import { navigate } from '@reach/router';
import FilterLeftbar from './components/FilterLeftbar';
import moment from 'moment';
import { grantNotificationAccess } from '../../utils';

class CRM extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCustomer: 0,
      lineCount: 1,
      inputValue: '',
      maxLineCount: 10,
      readAccess: 'read:conversation',
      writeAccess: 'write:conversation',
      isLoading: true,
      pusherConnection: null,
      pusherChannelId: null,
      feedAction: 'public_reply',
      disable: false,
      isResolved: 0,
      searchKeyLeftBar: '',
      isFilterShown: false,
      filterData: {
        channels: [],
        startDate: '',
        endDate: '',
        tags: [],
        agents: [],
        groups: [],
        agentSelection: 'all',
        groupSelection: 'all',
      },
      oldFilterData: null,
      galleryLimit: 12,
      galleryOffset: 0,
    };
  }

  onKeyPress = async (e) => {
    this.setState({ disable: true });
    const { maxLineCount, inputValue } = this.state;
    const line = inputValue.split(/\r*\n/).length;
    this.setState({
      lineCount: line > maxLineCount ? maxLineCount : line,
    });
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault();
      this.setState((prevState) => ({
        inputValue: prevState.inputValue + '\n',
        lineCount:
          prevState.lineCount >= maxLineCount
            ? maxLineCount
            : prevState.lineCount + 1,
      }));
    } else if (e.key === 'Enter') {
      // block enter
      e.preventDefault();
      if (
        this.props.currentTicket.customer?.platform.type === 'facebook_feed'
      ) {
        await this.props.sendMessageFeed(
          inputValue,
          null,
          this.state.feedAction
        );
        this.setState({
          inputValue: '',
          lineCount: 1,
        });
      } else {
        if (this.checkPermission('direct_message')) {
          await this.props.sendMessage(inputValue, null, 'direct_message');
          this.setState({
            inputValue: '',
            lineCount: 1,
          });
        } else {
          toaster.danger('Failed', {
            description: "You don't have enough permission to send message",
          });
        }
      }
    }
    this.setState({ disable: false });
  };

  fetchTicketData = async (isResolved) => {
    //this function will always have offset 0 // func for non scroll event;
    this.props.updateStateData('currentTicket', null);
    this.props.updateStateData('offset', 0);
    this.props.updateStateData('chatData', []);
    await this.props.fetchAssignableAdmins(this.props.projectId);
    await this.props.fetchAgentGroup(this.props.projectId);
    const {
      fetchTickets,
      projectId,
      limit,
      fetchCustomerMeta,
      fetchUserInformation,
      fetchMessengerChat,
      fetchFeedData,
      fetchCRMCustomerOrder,
      ticketOrder,
    } = this.props;

    if (!!this.props.location.search) {
      await this.handleOnFilter(true);
    } else {
      await fetchTickets(
        projectId,
        limit,
        0,
        isResolved,
        this.state.searchKeyLeftBar, //search
        'all', //channel
        'all', //agents
        'all', // groups
        'all', // tags
        '', //start
        '', //end
        ticketOrder //ticketOrder
      );
    }
    // fetch rest of data;
    setTimeout(async () => {
      //need this timeout to store data in Queue, added in event loop;
      if (this.props.queue.length > 0) {
        await fetchCustomerMeta(this.props.queue[0].customer?.id);
        if (this.props?.customerMeta?.ecommerce_collection_reference) {
          fetchCRMCustomerOrder(
            this.props.projectId,
            this.props.queue[0].customer?.id,
            ''
          );
        }
        fetchUserInformation(this.props.queue[0].customer?.id);
        const platformType = this.props.queue[0].customer.platform.type;
        const messengerTypes = [
          'facebook_messenger',
          'viber_messenger',
          'webchat',
          'app_messenger',
          'whatsapp_messenger',
        ];
        this.setState({
          isLoading: false,
        });
        if (messengerTypes.includes(platformType)) {
          await fetchMessengerChat(this.props.queue[0]?.id);
          this.channelEventEmitter(this.props.queue[0]?.customer?.id);
        } else if (platformType === 'facebook_feed') {
          await fetchFeedData(this.props.queue[0].id);
          this.feedChannelEventEmitter(this.props.queue[0].id);
        }
      } else {
        this.setState({
          isLoading: false,
        });
      }
    }, 0);
  };

  fetchEventTicketData = (event) => {
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        if (this.props.queue.length === 1) {
          this.props.updateStateData('currentTicket', event);
          await this.props.fetchCustomerMeta(event.customer?.id);
          if (this.props?.customerMeta?.ecommerce_collection_reference) {
            this.props.fetchCRMCustomerOrder(
              this.props.projectId,
              this.props.queue[0].customer?.id,
              ''
            );
          }
          this.props.fetchUserInformation(event.customer?.id);
          const platformType = event.customer.platform.type;
          const messengerTypes = [
            'facebook_messenger',
            'viber_messenger',
            'webchat',
            'app_messenger',
            'whatsapp_messenger',
          ];
          this.setState({
            isLoading: false,
          });
          if (messengerTypes.includes(platformType)) {
            await this.props.fetchMessengerChat(this.props.queue[0]?.id);
            this.channelEventEmitter(this.props.queue[0]?.customer?.id);
          } else if (platformType === 'facebook_feed') {
            await this.props.fetchFeedData(this.props.queue[0].id);
            this.feedChannelEventEmitter(this.props.queue[0].id);
          }
        } else {
          this.setState({
            isLoading: false,
          });
        }
      }
    );
  };

  fetchProjectInfo = async () => {
    ReactGA.pageview(
      window.location.pathname + window.location.search + window.location.hash
    );
    const project = this.props.projects.filter(
      (p) => parseInt(this.props.projectId, 10) === p.id
    )[0];
    await this.props.setSelectedProject(project);

    this.props.updateSelectedNav(8);

    //first we will check if current project's permission is in loading state?
    if (this.props.permissionLoader) {
      //if true set timeout to 1s and wait for permission to load
      setTimeout(async () => {
        //permission loaded
        const hasPermission = checkPermission(
          this.props.permissionList,
          this.state.readAccess
        );

        if (hasPermission) {
          await this.fetchTicketData(this.state.isResolved);
        }
      }, 1000);
    } else {
      //permission loaded
      const hasPermission = checkPermission(
        this.props.permissionList,
        this.state.readAccess
      );

      if (hasPermission) {
        await this.fetchTicketData(this.state.isResolved);
      }
    }
  };

  connectToPusher = () => {
    // Pusher.logToConsole = true;
    const pusherConnection = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      encrypted: true,
    });
    this.setState({
      pusherConnection: pusherConnection,
    });
  };

  channelEventEmitter = (customerId) => {
    if (!!this.state.pusherChannelId) {
      this.state.pusherConnection.unsubscribe(this.state.pusherChannelId);
    }
    const channel = this.state.pusherConnection.subscribe(
      `chat-C_${customerId}`
    );
    this.setState({
      pusherChannelId: `chat-C_${customerId}`,
    });

    channel.bind('messages', (event) => {
      this.props.addChatData({ event: event, adminId: this.props.adminId });
    });
  };
  feedChannelEventEmitter = (ticketId) => {
    if (!!this.state.pusherChannelId) {
      this.state.pusherConnection.unsubscribe(this.state.pusherChannelId);
    }
    const channel = this.state.pusherConnection.subscribe(`chat-T_${ticketId}`);
    this.setState({
      pusherChannelId: `chat-T_${ticketId}`,
    });
    channel.bind('messages', (event) => {
      this.props.addFeedReplyData(event);
      // I need to process this
    });
  };

  checkPermission(key) {
    try {
      if (!!this.props.ticketActions && this.props.ticketActions.length > 0) {
        return this.props.ticketActions.filter((item) => item.action === key)[0]
          .is_allowed;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  handleEnCodeData = (key) => {
    const {
      channels,
      agentSelection,
      agents,
      groups,
      groupSelection,
      tags,
      startDate,
      endDate,
    } = this.state.filterData;
    switch (key) {
      case 'channels':
        //channels
        let channelParams = channels.length === 0 ? 'all' : '';
        channels.forEach((channel, i) => {
          if (i === channels.length - 1) {
            channelParams = channelParams + channel.id;
          } else {
            channelParams = channelParams + channel.id + ',';
          }
        });
        return channelParams;

      case 'agents':
        //agents
        let agentParams = '';
        if (!!agentSelection) {
          agentParams = agentSelection;
        }
        agents.forEach((agent, i) => {
          if (i === agents.length - 1) {
            agentParams = agentParams + agent.value;
          } else {
            agentParams = agentParams + agent.value + ',';
          }
        });

        return agentParams;

      case 'groups':
        //groups
        let groupsParams = '';
        if (!!groupSelection) {
          groupsParams = groupSelection;
        }
        groups.forEach((group, i) => {
          if (i === groups.length - 1) {
            //last index; dont add comma
            groupsParams = groupsParams + group.value;
          } else {
            groupsParams = groupsParams + group.value + ',';
          }
        });
        return groupsParams;

      case 'tags':
        //tags
        let tagParams = tags.length === 0 ? 'all' : '';
        tags.forEach((tag, i) => {
          if (i === tags.length - 1) {
            //last index; dont add comma
            tagParams = tagParams + tag.value;
          } else {
            tagParams = tagParams + tag.value + ',';
          }
        });

        return tagParams;

      case 'startDate':
        return !!startDate ? moment(startDate).format('YYYY-MM-DD') : '';
      case 'endDate':
        return !!endDate ? moment(endDate).format('YYYY-MM-DD') : '';

      case 'ticketOrder':
        let orderParams;
        if (!!this.props.ticketOrder) {
          orderParams = this.props.ticketOrder;
        }
        return orderParams;

      default:
        return;
    }
  };

  handleOnFilter = async (lookupDecode) => {
    const {
      fetchCustomerMeta,
      fetchUserInformation,
      fetchMessengerChat,
      fetchFeedData,
    } = this.props;
    if (!!this.props.location.search && lookupDecode) {
      this.decodeFilter(this.props.location.search);
    } else {
      const { startDate, endDate } = this.state.filterData;
      const search = this.state.searchKeyLeftBar;
      const isResolved = this.state.isResolved;
      const url = new URL(this.props.location.href);
      let params = new URLSearchParams(url.searchParams);

      params.set('resolved', isResolved);
      params.set('offset', '0');
      params.set('limit', this.props.limit); // this is actually limit we set offset 0 to next offset+20 is the limit
      params.set('search', search);
      params.set('channels', this.handleEnCodeData('channels'));
      params.set('agents', this.handleEnCodeData('agents'));
      if (!!startDate) {
        params.set('start', this.handleEnCodeData('startDate'));
      } else {
        params.delete('start');
      }
      if (!!endDate) {
        params.set('end', this.handleEnCodeData('endDate'));
      } else {
        params.delete('end');
      }
      params.set('groups', this.handleEnCodeData('groups'));
      params.set('tags', this.handleEnCodeData('tags'));
      params.set('order', this.handleEnCodeData('ticketOrder'));
      await this.props.fetchTicketsString(
        this.props.projectId,
        params.toString()
      );

      //fetch middle and right bar

      setTimeout(async () => {
        //need this timeout to store data in Queue, added in event loop;
        if (this.props.queue.length > 0) {
          await fetchCustomerMeta(this.props.queue[0].customer?.id);
          if (this.props?.customerMeta?.ecommerce_collection_reference) {
            this.props.fetchCRMCustomerOrder(
              this.props.projectId,
              this.props.queue[0].customer?.id,
              ''
            );
          }
          fetchUserInformation(this.props.queue[0].customer?.id);
          const platformType = this.props.queue[0].customer.platform.type;
          const messengerTypes = [
            'facebook_messenger',
            'viber_messenger',
            'webchat',
            'app_messenger',
            'whatsapp_messenger',
          ];
          this.setState({
            isLoading: false,
          });
          if (messengerTypes.includes(platformType)) {
            await fetchMessengerChat(this.props.queue[0]?.id);
            this.channelEventEmitter(this.props.queue[0]?.customer?.id);
          } else if (platformType === 'facebook_feed') {
            await fetchFeedData(this.props.queue[0].id);
            this.feedChannelEventEmitter(this.props.queue[0].id);
          }
        } else {
          this.setState({
            isLoading: false,
          });
        }
      }, 0);
      navigate(`${this.props.location.pathname}?${params.toString()}`);
    }
  };

  updateDecodePairData = (key, value) => {
    switch (key) {
      case 'resolved':
        this.setState({
          isResolved: parseInt(value, 10),
        });
        return;
      case 'offset':
        this.props.updateStateData('offset', 0);
        return;
      case 'limit':
        this.props.updateStateData('limit', 20);
        return;
      case 'order':
        this.props.updateStateData('ticketOrder', value);
        return;
      case 'search':
        this.setState({
          searchKeyLeftBar: value || '',
        });
        return;
      case 'channels':
        if (value === 'all') {
          this.setState({
            filterData: { ...this.state.filterData, channels: [] },
          });
        } else {
          let channels = [];
          value.split(',').forEach((channelFilter) => {
            !!this.props.platformList &&
              this.props.platformList.forEach((channel) => {
                if (channel.id === parseInt(channelFilter, 10)) {
                  channels = [...channels, channel];
                }
              });
          });
          this.setState({
            filterData: { ...this.state.filterData, channels: channels },
          });
        }
        return;
      case 'agents':
        let agentsData = [];
        if (value === 'all') {
          this.setState({
            filterData: {
              ...this.state.filterData,
              agents: [],
              agentSelection: 'all',
            },
          });
        } else if (value === 'none') {
          this.setState({
            filterData: {
              ...this.state.filterData,
              agents: [],
              agentSelection: 'none',
            },
          });
        } else {
          value.split(',').forEach((agentFilter) => {
            !!this.props.assignableAdmins &&
              this.props.assignableAdmins.forEach((agentsLocal) => {
                if (agentsLocal.admin.id === parseInt(agentFilter, 10)) {
                  agentsData = [
                    ...agentsData,
                    {
                      ...agentsLocal,
                      value: agentsLocal.admin.id,
                      label: agentsLocal.admin.full_name,
                    },
                  ];
                }
              });
          });
          this.setState({
            filterData: {
              ...this.state.filterData,
              agents: agentsData,
              agentSelection: '',
            },
          });
        }
        return;
      case 'groups':
        let groupsData = [];
        if (value === 'all') {
          this.setState({
            filterData: {
              ...this.state.filterData,
              groups: [],
              groupSelection: 'all',
            },
          });
        } else if (value === 'none') {
          this.setState({
            filterData: {
              ...this.state.filterData,
              groups: [],
              groupSelection: 'none',
            },
          });
        } else {
          value.split(',').forEach((groupFilter) => {
            !!this.props.agentGroup &&
              this.props.agentGroup.forEach((groupLocal) => {
                if (groupLocal.id === parseInt(groupFilter, 10)) {
                  groupsData = [
                    ...groupsData,
                    {
                      ...groupLocal,
                      value: groupLocal.id,
                      label: groupLocal.name,
                    },
                  ];
                }
              });
          });
          this.setState({
            filterData: {
              ...this.state.filterData,
              groups: groupsData,
              groupSelection: '',
            },
          });
        }
        return;
      case 'tags':
        let tagsData = [];
        if (value === 'all') {
          this.setState({
            filterData: { ...this.state.filterData, tags: [] },
          });
        } else {
          value.split(',').forEach((tagFilter) => {
            !!this.props.tags &&
              this.props.tags.forEach((tagLocal) => {
                if (tagLocal.id === parseInt(tagFilter, 10)) {
                  tagsData = [
                    ...tagsData,
                    { ...tagLocal, value: tagLocal.id, label: tagLocal.name },
                  ];
                }
              });
          });
          this.setState({
            filterData: { ...this.state.filterData, tags: tagsData },
          });
        }
        return;
      case 'start':
        this.setState({
          filterData: { ...this.state.filterData, startDate: moment(value) },
        });
        return;
      case 'end':
        this.setState({
          filterData: { ...this.state.filterData, endDate: moment(value) },
        });
        return;
      default:
        return;
    }
  };

  decodeFilter = (query) => {
    const params = new URLSearchParams(query);
    for (let pair of params.entries()) {
      this.updateDecodePairData(pair[0], pair[1]);
    }
    this.handleOnFilter(false);
  };

  componentDidMount() {
    this.connectToPusher();
    this.fetchProjectInfo();
    this.props.fetchCannedResponse(this.props.projectId);
    this.props.fetchGalleryImages(
      this.props.projectId,
      this.state.galleryLimit,
      this.state.galleryOffset
    );
    this.props.fetchAllTicketTag();
    grantNotificationAccess();
  }

  componentWillUnmount() {
    // clear connection
    if (!!this.state.pusherConnection) {
      this.state.pusherConnection.disconnect();
    }
  }

  render() {
    const { projectId } = this.props;
    return (
      <HasPermission shortCode={this.state.readAccess}>
        <div className='crm'>
          {this.state.isLoading && <LoaderLine />}
          <div className='crm-container'>
            <LeftBar
              projectId={projectId}
              location={this.props.location}
              fetchCustomerMeta={this.props.fetchCustomerMeta}
              fetchCRMCustomerOrder={this.props.fetchCRMCustomerOrder}
              channelEventEmitter={this.channelEventEmitter}
              feedChannelEventEmitter={this.feedChannelEventEmitter}
              pusherConnection={this.state.pusherConnection}
              filterData={() => this.state.filterData}
              onFilterClick={() => {
                this.setState({ isFilterShown: !this.state.isFilterShown });
              }}
              fetchEventTicketData={this.fetchEventTicketData}
              handleEnCodeData={this.handleEnCodeData}
              handleOnFilter={this.handleOnFilter}
              hasWriteAccess={() =>
                checkPermission(
                  this.props.permissionList,
                  this.state.readAccess
                )
              }
              getTicketOrder={() => this.props.ticketOrder}
              isResolved={() => this.state.isResolved}
              updateIsResolved={(data) => {
                this.setState({ isResolved: data }, () => {
                  this.handleOnFilter(false);
                });
              }}
              searchKeyword={this.state.searchKeyLeftBar}
              updateSearch={(value) => {
                this.setState(
                  {
                    searchKeyLeftBar: value,
                  },
                  () => {
                    // if value is ""
                    if (!value) {
                      this.handleOnFilter(false);
                    }
                  }
                );
              }}
              onSearch={() => {
                this.handleOnFilter(false);
              }}
            />
            <div className='customer-chat'>
              <CRMActionBar
                ticketsAvailable={!!this.props.currentTicket}
                selectedQueue={this.props.selectedQueue}
                isResolved={this.props.currentTicket?.is_resolved}
                currentTicket={this.props.currentTicket}
                assignableAdmins={this.props.assignableAdmins}
                assignAdmin={this.props.assignAdmin}
                agentGroup={this.props.agentGroup}
                fetchAllTicket={() =>
                  this.fetchTicketData(this.state.isResolved)
                }
                resolveLoading={this.props.resolveLoading}
                authId={this.props.authId}
                updateStateData={this.props.updateStateData}
                handleAction={async (body, type) => {
                  if (type === 'lock') {
                    //call lock api
                    const res = await this.props.lockConversation(
                      this.props.currentTicket.id,
                      body.is_locked
                    );
                    if (res) {
                      this.props.updateLockedStatus(
                        'is_locked',
                        !this.props.currentTicket.is_locked,
                        this.props.currentTicket.id
                      );
                      toaster.success('Success', {
                        description: `Successfully ${
                          !this.props.currentTicket.is_locked
                            ? 'Locked'
                            : 'Unlocked'
                        } this Ticket`,
                      });
                    } else {
                      toaster.danger('Failed', {
                        description: `Failed to Lock this Ticket`,
                      });
                    }
                  } else if (type === 'resolve') {
                    //resolve
                    const res = await this.props.resolveConversation(
                      this.props.currentTicket.id,
                      body.is_resolved
                    );
                    if (res) {
                      toaster.success('Success', {
                        description: `Successfully to Resolved this Ticket`,
                      });
                      await this.fetchTicketData(this.state.isResolved);
                    } else {
                      toaster.danger('Failed', {
                        description: `Failed to Resolved this Ticket`,
                      });
                    }
                  }
                }}
                isBotEnabled={this.props.customerMeta?.bot_enabled}
                handleBotAction={(body) => {
                  const res = this.props.updateBotStatus(
                    body.bot_enabled,
                    this.props.currentTicket.id
                  );
                  if (!res) {
                    toaster.danger('Failed', {
                      description: 'Failed to Change Bot Status',
                    });
                  }
                }}
              />
              {this.props.chatData?.platform_info?.type === 'facebook_feed' ? (
                <CRMFeedPost
                  lineCount={this.state.lineCount}
                  ticketsAvailable={!!this.props.currentTicket}
                  chatData={this.props.chatData}
                  loading={this.props.loadingMessage}
                  updateFeedAction={this.props.updateFeedAction}
                />
              ) : (
                <CRMChat
                  lineCount={this.state.lineCount}
                  ticketsAvailable={!!this.props.currentTicket}
                  currentTicket={this.props.currentTicket}
                  chatData={this.props.chatData}
                  loading={this.props.loadingMessage}
                  isLoading={this.state.isLoading}
                />
              )}
              <CRMReplyInput
                inputValue={this.state.inputValue}
                currentTicket={this.props.currentTicket}
                lineCount={this.state.lineCount}
                feedAction={this.state.feedAction}
                updateFeedAction={(value) =>
                  this.setState({ feedAction: value })
                }
                galleryImages={this.props.galleryImages}
                updateGalleryImagesAppend={this.props.updateGalleryImagesAppend}
                galleryTotal={this.props.galleryTotal}
                updateGalleryImagesUpload={this.props.updateGalleryImagesUpload}
                galleryImageLoader={this.props.galleryImageLoader}
                showNote={this.checkPermission('write_note')}
                hasPrivateReply={this.checkPermission('public_reply')}
                disable={this.state.disable || !this.props.currentTicket}
                selectedProject={this.props.projectId}
                getMoreGalleryImages={async () => {
                  const newOffset =
                    this.state.galleryOffset + this.state.galleryLimit;
                  await this.props.fetchGalleryImages(
                    this.props.projectId,
                    this.state.galleryLimit,
                    newOffset
                  );
                  this.setState({
                    galleryOffset: newOffset,
                  });
                }}
                sendNote={(message, image) => {
                  if (
                    this.props.currentTicket?.customer?.platform?.type ===
                    'facebook_feed'
                  ) {
                    if (this.checkPermission('write_note')) {
                      this.props.sendMessageFeed(message, image, 'write_note');
                    } else {
                      toaster.danger('Failed', {
                        description:
                          "You don't have enough permission to send message",
                      });
                    }
                  } else {
                    if (this.checkPermission('write_note')) {
                      this.props.sendMessage(message, image, 'write_note');
                    } else {
                      toaster.danger('Failed', {
                        description:
                          "You don't have enough permission to send message",
                      });
                    }
                  }
                }}
                messageSendingLoader={this.props.messageSendingLoader}
                sendMessage={async (message, image) => {
                  if (
                    this.props.currentTicket?.customer?.platform?.type ===
                    'facebook_feed'
                  ) {
                    await this.props.sendMessageFeed(
                      message,
                      image,
                      this.state.feedAction
                    );
                  } else {
                    if (this.checkPermission('direct_message')) {
                      await this.props.sendMessage(
                        message,
                        image,
                        'direct_message'
                      );
                      this.setState({
                        inputValue: '',
                        lineCount: 1,
                      });
                    } else {
                      toaster.danger('Failed', {
                        description:
                          "You don't have enough permission to send message",
                      });
                    }
                  }
                }}
                handleChange={(value) => {
                  let lineCountLocal =
                    value.split('\n')?.length || this.state.lineCount;
                  if (lineCountLocal > this.state.maxLineCount) {
                    lineCountLocal = this.state.maxLineCount;
                  }

                  this.setState({
                    inputValue: value,
                    lineCount: lineCountLocal,
                  });
                }}
                resetInput={() => {
                  this.setState({
                    inputValue: '',
                    lineCount: 1,
                  });
                }}
                onKeyPress={this.onKeyPress}
                cannedResponses={this.props.cannedResponses}
                hasWriteAccess={() =>
                  checkPermission(
                    this.props.permissionList,
                    this.state.readAccess
                  )
                }
              />
            </div>
            <RightBar projectId={projectId} />
          </div>
          <FilterLeftbar
            isFilterShown={this.state.isFilterShown}
            onFilterVisibilityChange={(visibility) => {
              this.setState({ isFilterShown: visibility });
            }}
            agentGroup={this.props.agentGroup}
            assignableAdmins={this.props.assignableAdmins}
            channelList={this.props.platformList}
            filterData={this.state.filterData}
            oldFilterData={this.state.oldFilterData}
            tags={this.props.tags}
            updateFilterData={(key, value) => {
              this.setState({
                filterData: { ...this.state.filterData, [key]: value },
              });
            }}
            updateStateData={(key, value) => {
              this.setState({
                [key]: value,
              });
            }}
            handleOnFilter={() => {
              this.handleOnFilter(false);
            }}
          />
        </div>
      </HasPermission>
    );
  }
}

const mapState = (state) => ({
  selectedQueue: state.crm.selectedQueue,
  adminId: state.auth.id,
  selectedPlatform: state.crm.selectedPlatform,
  limit: state.crm.limit,
  offset: state.crm.offset,
  ticketOrder: state.crm.ticketOrder,
  projects: state.dashboard.projects,
  permissionList: state.dashboard.permission?.list || [],
  platformList: state.dashboard.platformList,
  queue: state.crm.queue,
  cannedResponses: state.crm.cannedResponses,
  currentTicket: state.crm.currentTicket,
  chatData: state.crm.chatData,
  customerMeta: state.crm.customerMeta,
  assignableAdmins: state.crm.assginableAdmins,
  ticketActions: state.crm.ticketActions,
  permissionLoader: state.loading.effects.dashboard.fetchRolePermission,
  authId: state.auth.id,
  agentGroup: state.crm.agentGroup,
  messageSendingLoader:
    state.loading.effects.crm.sendMessage ||
    state.loading.effects.crm.sendMessageFeed,
  loadingMessage:
    state.loading.effects.crm.fetchMessengerChat ||
    state.loading.effects.crm.fetchFeedData ||
    state.loading.effects.crm.fetchTickets ||
    state.loading.effects.crm.fetchUserInformation ||
    state.loading.effects.crm.fetchCustomerMeta ||
    state.loading.effects.crm.fetchAgentGroup,
  tags: state.crm.allTicketTags,
  resolveLoading: state.loading.effects.crm.resolveConversation,
  galleryImages: state.crm.galleryImages,
  galleryTotal: state.crm.galleryTotal,
  galleryImageLoader: state.loading.effects.crm.fetchGalleryImages,
});

const mapDispatch = (dispatch) => ({
  fetchTickets: (
    projectId,
    limit,
    offset,
    isResolved,
    search,
    channels,
    agents,
    groups,
    tags,
    start,
    end,
    ticketOrder
  ) =>
    dispatch.crm.fetchTickets({
      projectId,
      limit,
      offset,
      isResolved,
      search,
      channels,
      agents,
      groups,
      tags,
      start,
      end,
      ticketOrder,
    }),
  updateStateData: (key, value) => dispatch.crm.updateStateData({ key, value }),
  setSelectedProject: (project) =>
    dispatch.dashboard.setSelectedProject(project),
  updateSelectedNav: (navId) => dispatch.dashboard.updateSelectedNav(navId),
  fetchCustomerMeta: (customerId) => dispatch.crm.fetchCustomerMeta(customerId),
  fetchCRMCustomerOrder: (teamId, customerId, orderId) =>
    dispatch.crm.fetchCRMCustomerOrder({ teamId, customerId, orderId }),
  updateExpand: (value) => dispatch.crm.updateExpand(value),
  fetchMessengerChat: (ticketId) => dispatch.crm.fetchMessengerChat(ticketId),
  fetchFeedData: (ticketId) => dispatch.crm.fetchFeedData(ticketId),
  sendMessage: (message, image, action) =>
    dispatch.crm.sendMessage({ message, image, action }),
  sendMessageFeed: (message, image, action) =>
    dispatch.crm.sendMessageFeed({ message, image, action }),
  // updateTickets: (projectId, ticketId, body) =>
  //   dispatch.crm.updateTickets({ projectId, ticketId, body }),
  resolveConversation: (ticketId, status) =>
    dispatch.crm.resolveConversation({ ticketId, status }),
  lockConversation: (ticketId, status) =>
    dispatch.crm.lockConversation({ ticketId, status }),
  updateLockedStatus: (key, value, id) =>
    dispatch.crm.updateLockedStatus({ key, value, id }),
  editCustomerMeta: (body, customerId) =>
    dispatch.crm.editCustomerMeta({ body, customerId }),
  addChatData: (payload) => dispatch.crm.addChatData(payload),
  fetchAssignableAdmins: (projectId) =>
    dispatch.crm.fetchAssignableAdmins(projectId),
  assignAdmin: (ticket) => dispatch.crm.assignAdmin(ticket),
  fetchAgentGroup: (projectId) => dispatch.crm.fetchAgentGroup(projectId),
  fetchCannedResponse: (projectId) =>
    dispatch.crm.fetchCannedResponse(projectId),
  updateBotStatus: (status, ticketId) =>
    dispatch.crm.updateBotStatus({ status, ticketId }),
  fetchUserInformation: (customerId) =>
    dispatch.crm.fetchUserInformation(customerId),
  updateFeedAction: (action, commentId, type, text) =>
    dispatch.crm.updateFeedAction({ action, commentId, type, text }),
  fetchTicketsString: (projectId, paramsString) =>
    dispatch.crm.fetchTicketsString({ projectId, paramsString }),
  fetchAllTicketTag: () => dispatch.crm.fetchAllTicketTag(),
  fetchGalleryImages: (projectId, limit, offset) =>
    dispatch.crm.fetchGalleryImages({ projectId, limit, offset }),
  updateGalleryImagesAppend: (data) =>
    dispatch.crm.updateGalleryImagesAppend(data),
  updateGalleryImagesUpload: (data) =>
    dispatch.crm.updateGalleryImagesUpload(data),
  addFeedReplyData: (data) => dispatch.crm.addFeedReplyData(data),
});

const CRMContainer = connect(mapState, mapDispatch)(CRM);
export default CRMContainer;
