import React from 'react';
import { Router } from '@reach/router';
import Dashboard from './pages/dashboard/Dashboard';
import Projects from './pages/projects/Projects';
import Builder from './pages/builder/Builder';
import Login from './pages/auth/Login';
import InvitationAccept from './pages/auth/InvitationAccept';
import APIFuse from './pages/api-fuse/APIFuse';
import DataLab from './pages/data-lab/DataLab';
import DataLabDetails from './pages/data-lab-details/DataLabDetails';
import store from './stores';
import { Provider } from 'react-redux';
import Protected from './components/Protected';
import { getPersistor } from '@rematch/persist';
import { PersistGate } from 'redux-persist/es/integration/react';
import NotFound from './pages/404/NotFound';
import DataLabCreate from './pages/data-lab/DataLabCreate';
import DataLabsKeyCreator from './pages/data-lab/DataLabsKeyCreator';
import DataLabForm from './pages/data-lab-form/DataLabForm';
import SettingsWrapper from './pages/settings/SettingsWrapper';
import ProfileSettings from './pages/settings/pages/ProfileSettings';
import TeamInfoSettings from './pages/settings/pages/TeamInfoSettings';
import TeamUserSettings from './pages/settings/pages/TeamUserSettings';
import TeamGroupSettings from './pages/settings/pages/TeamGroupSettings';
import BillingPaymentSettings from './pages/settings/pages/BillingPaymentSettings';
import BillingPlanSettings from './pages/settings/pages/BillingPlanSettings';
import BillingHistorySettings from './pages/settings/pages/BillingHistorySettings';
import Settings from './pages/settings/Settings';
import ForgetPassword from './pages/auth/ForgetPassword';
import VerifyPassword from './pages/auth/VerifyPassword';
import ReSendAccountVerificationLink from './pages/auth/ResendAccountVerificationMail';
import PasswordVerificationSuccess from './pages/auth/components/PasswordVerficationSuccess';
import VerifyAccount from './pages/auth/VerifyAccount';
import Register from './pages/auth/Register';
import Audience from './pages/audience/Audience';
import PendingProjectSettings from './pages/settings/pages/PendingProjectSettings';
import ProjectPermission from './pages/settings/pages/ProjectPerimission';
import ChannelSettings from './pages/settings/pages/ChannelSettings';
import CRM from './pages/crm/CRM';
import Reporting from './pages/reporting/Reporting';
import Welcome from './pages/onboarding/Welcome';
import MarketplaceSettings from './pages/settings/pages/MarketplaceSettings';

const Routes = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={getPersistor()}>
        <Router>
          <Login path='/' />
          <Register path='/register' />
          <ForgetPassword path='/reset' />
          <VerifyPassword path='/reset-verify' />
          <PasswordVerificationSuccess path='/reset-success' />
          <ReSendAccountVerificationLink path='/register-resend-mail' />
          <VerifyAccount path='/register-verify' />
          <InvitationAccept path='/invitation' />
          <Welcome path='/welcome' />
          <Protected path='dashboard'>
            <Projects path='/'>
              <Dashboard path='/' />
            </Projects>
          </Protected>
          <Protected path='projects'>
            <Projects path='/'>
              <Builder path=':projectId/builder' />
              <APIFuse path=':projectId/api' />
              <DataLab path=':projectId/data-lab' />
              <DataLabCreate path=':projectId/data-lab/create' />
              <DataLabCreate path=':projectId/data-lab/edit-info/:labId' />
              <DataLabsKeyCreator path=':projectId/data-lab/edit/:labId' />
              <DataLabDetails path=':projectId/data-lab/:labId' />
              <Reporting path=':projectId/reporting' />
              <Audience path=':projectId/audience' />
              <CRM path=':projectId/chat' />
              <NotFound isDashboardView default />
            </Projects>
          </Protected>
          <Protected path='settings'>
            <SettingsWrapper path='/'>
              <Settings path='/'>
                <ProfileSettings path='profile' />
                <TeamInfoSettings path='team-info' />
                <TeamUserSettings path='team-user' />
                <TeamGroupSettings path='team-group' />
                <BillingPlanSettings path='billing' />
                <BillingPaymentSettings path='payment-method' />
                <BillingHistorySettings path='payment-histories' />
                <PendingProjectSettings path='permission/pending' />
                <ChannelSettings path='channel' />
                <ProjectPermission path='permission/project' />
                <MarketplaceSettings path='marketplace' />
                <NotFound isSettingsView default />
              </Settings>
            </SettingsWrapper>
          </Protected>
          <DataLabForm path='/public/form/:labId' />
          <NotFound default />
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default Routes;
