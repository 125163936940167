/**
 * Created by shuvo on 24/3/21.
 */
import React, { Component } from 'react';
import './assets/scss/onboarding.scss';
import WelcomeSVG from './assets/svg/welcome.svg';
import { Button } from 'evergreen-ui';

class Welcome extends Component {
  render() {
    return (
      <div className='welcome-container text-center'>
        <img className='welcome-img' src={WelcomeSVG} alt='welcome screen' />
        <p className='title'>Welcome To Alice!</p>
        <p className='subtitle'>
          Before you get started, let us sort out a few things.
        </p>
        <Button className='next-button' height={50}>
          Let's Go!
        </Button>
      </div>
    );
  }
}

export default Welcome;
