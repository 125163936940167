import React from 'react';
import { Tooltip, Icon } from 'evergreen-ui';
import PropTypes from 'prop-types';

const TitleHolderBuilder = ({ title, tooltipInfo }) => {
  return (
    <div className='block-title-container'>
      <div>{title}</div>&nbsp;&nbsp;
      <Tooltip content={tooltipInfo}>
        <Icon icon={'info-sign'} size={12} color='disabled' />
      </Tooltip>
    </div>
  );
};

TitleHolderBuilder.propTypes = {
  title: PropTypes.string,
  tooltipInfo: PropTypes.string,
};
export default TitleHolderBuilder;
