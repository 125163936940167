import React from 'react';
import Datetime from "react-datetime";
import CalendarIcon from './../../../assets/images/calendarIcon.svg'
import moment from 'moment';

interface Prop {
  value:any;
  updateFilterData: (value: any) => void;
  placeholder: string;
  title: string;
  disableFutureDate?: boolean;
}

const DateSelectRd: React.FC<Prop> = ({value,updateFilterData, placeholder,title, disableFutureDate=false}) => {

  const today = moment();
  const validDisabledFuture = function( current:any ){
    return current.isBefore( today );
  };
  const validFuture = function( current:any ){
    return current ;
  };

  return (
    <div className='date-picker__redesign'>
      <p
        className='filter-label'>
        {title}
      </p>
      <Datetime
        className='date-picker__redesign-container'
        dateFormat="YYYY-MM-DD"
        timeFormat={false}
        value={value}
        onChange={(value)=> updateFilterData(value)}
        inputProps={{placeholder: placeholder}}
        isValidDate={disableFutureDate ? validDisabledFuture : validFuture}
      />
      <div style={{position: 'absolute', top: 30, right: 10}}>
        <img src={CalendarIcon} width={24} alt='calender' />
      </div>
    </div>
  );
};

export default DateSelectRd;
