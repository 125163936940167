import React, { Component } from 'react';
import { connect } from 'react-redux';
import PaymentMethod from '../component/billing/PaymentMethod';
import HasPermission from '../../../components/HasPermission';
import { checkPermission } from '../../../utils';

class BillingPaymentSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      readAccess: 'read:settings',
      writeAccess: 'write:settings',
    };
  }

  async componentDidMount() {
    await this.props.fetchUserInfo();
  }

  render() {
    const cardInfo = this.props.userInfo ? this.props.userInfo.card_info : null;
    const hasWriteAccess = checkPermission(
      this.props.permissionList,
      this.state.writeAccess
    );
    return (
      <HasPermission shortCode={this.state.readAccess} isSettingsView>
        <div className='billing-settings-container'>
          <p className='channel-settings__header'>Payment Method</p>
          <p className='channel-settings__description'>
            Manage your payment methods here.
          </p>
          <br />
          {this.props.userInfo && (
            <PaymentMethod
              handlePaymentMethod={this.props.createPaymentMethod}
              handleCardAction={this.props.manageCard}
              userEmail={this.props.userInfo.email}
              hasWriteAccess={hasWriteAccess}
              cardInfo={cardInfo}
            />
          )}
        </div>
      </HasPermission>
    );
  }
}

const mapState = (state) => ({
  selectedProject: state.dashboard.selectedProject,
  userInfo: state.settings.userInfo,
  permissionList: state.dashboard.permission.list || [],
});

const mapDispatch = (dispatch) => ({
  fetchUserInfo: () => dispatch.settings.fetchUserInfo(),
  createPaymentMethod: (payload) =>
    dispatch.billing.createPaymentMethod(payload),
  manageCard: (payload) => dispatch.billing.manageCard(payload),
});

const BillingPaymentSettingsContainer = connect(
  mapState,
  mapDispatch
)(BillingPaymentSettings);

export default BillingPaymentSettingsContainer;
