import React, { Fragment } from 'react';
import { Combobox, Button, Badge } from 'evergreen-ui';
import BlockContainer from './BlockContainer';
import PropTypes from 'prop-types';
import redirectIcon from './../../pages/builder/assets/images/icons/redirect.svg';
import './../../assets/styles/blocks/redirect.scss';
import RedirectCondition from '../elements/RedirectCondition';

const RedirectBlock = ({
  id,
  data,
  sequences,
  attributeList,
  updateBlock,
  updateRedirectBlock,
  save,
  saveBlock,
  saveLoader,
  deleteBlock,
  copyBlock,
  moveBlock,
  hasWriteAccess,
}) => {
  const handleSequenceChange = (item) => {
    const dataLocal = { sequence: item, conditions: data.conditions };
    updateRedirectBlock(dataLocal, id, 'data');
  };

  const handleDelete = (conditionId) => {
    const conditionsLocal = data.conditions.filter((e) => e.id !== conditionId);
    updateBlock(conditionsLocal, id, 'conditions');
  };

  const handleUpdate = (conditionId, key, value) => {
    let conditionsLocal = data.conditions.map((data) =>
      data.id === conditionId ? { ...data, [key]: value } : data
    );
    updateBlock(conditionsLocal, id, 'conditions');
  };

  return (
    <BlockContainer
      id={id}
      title='Redirect Block'
      subtitle='Put This Block at the end of your sequence and it can only exist in once in a sequence'
      save={save}
      isLoading={saveLoader}
      icon={redirectIcon}
      sequences={sequences}
      handleSaveClick={() => saveBlock(id)}
      handleDelete={() => deleteBlock(id)}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <Fragment>
        <Combobox
          openOnFocus
          width='100%'
          selectedItem={sequences.filter((s) => s.id === data.sequence)[0]}
          inputProps={{ disabled: !hasWriteAccess }}
          disabled={!hasWriteAccess}
          items={sequences}
          itemToString={(item) => (item ? item.title : '')}
          onChange={(selected) => selected && handleSequenceChange(selected.id)}
          placeholder='Sequence'
        />
        {!!data && !!data.conditions && data.conditions.length > 0 && (
          <Badge isSolid color='green' className='mt-15px'>
            Conditions
          </Badge>
        )}
        {!!data &&
          !!data.conditions &&
          data.conditions.map((condition, i) => (
            <RedirectCondition
              key={i}
              lastIndex={
                data.conditions.length !== 0 && data.conditions.length - 1 === i
              }
              id={condition.id}
              hasWriteAccess={hasWriteAccess}
              handleDelete={handleDelete}
              attributeList={attributeList}
              attribute={condition.attribute}
              handleUpdate={handleUpdate}
              operator={condition.operator}
              value={condition.value}
              next={condition.next}
            />
          ))}
        {hasWriteAccess && (
          <Button
            onClick={() => {
              const newCondition = {
                id:
                  !!data && data.conditions.length === 0
                    ? 1
                    : data.conditions[data.conditions.length - 1].id + 1,
                attribute: 'default',
                operator: 'is',
                value: '',
                next: 'and',
              };
              const conditionsLocal = [...data.conditions, newCondition];
              updateBlock(conditionsLocal, id, 'conditions');
            }}
            marginRight={12}
            marginTop={20}
            intent='success'
            appearance='primary'
            iconBefore='add'
          >
            Add A Condition
          </Button>
        )}
      </Fragment>
    </BlockContainer>
  );
};

RedirectBlock.propTypes = {
  id: PropTypes.number.isRequired,
  save: PropTypes.bool.isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  saveBlock: PropTypes.func.isRequired,
  updateRedirectBlock: PropTypes.func.isRequired,
  saveLoader: PropTypes.bool.isRequired,
  hasWriteAccess: PropTypes.bool,
};
RedirectBlock.defaultProps = {
  updateRedirectBlock: (e) => console.log(e),
  hasWriteAccess: false,
};

export default RedirectBlock;
